import PropTypes from 'prop-types';

const { string, number, arrayOf, oneOfType, shape } = PropTypes;

const dropdownOptionsShape = arrayOf(
  shape({
    id: oneOfType([string, number]).isRequired,
    name: string.isRequired,
  }),
);

export default dropdownOptionsShape;
