import React from 'react';
import customerBannerShape from 'react/shared/shapes/customer_banner_shape';

function CustomerBannerContent(props) {
  return (
    <div className="customer-banner-content">
      {/* Disabling since this is only internal info and coming from an internal API */}
      {/* eslint-disable react/no-danger */}
      <div
        className={props.banner.color_class}
        dangerouslySetInnerHTML={{
          __html: props.banner.markdown_content,
        }}
      />
    </div>
  );
}

CustomerBannerContent.propTypes = {
  banner: customerBannerShape.isRequired,
};

export default CustomerBannerContent;
