import PropTypes from 'prop-types';
import React from 'react';
import PersonalizedCardForm from './PersonalizedCardForm';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import bindAll from 'react/shared/utils/bind_all';

export default class CardholderPersonalizationOptions extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = { showForm: false };

    bindAll(this);
  }

  renderActionBtns() {
    return (
      <div
        className="input-group"
        style={{ marginTop: 50, display: 'flex', justifyContent: 'space-around' }}
      >
        <TrueLinkButton className="btn btn-success" onClick={this.handleKeep} variant="none">
          Keep Current Card
        </TrueLinkButton>
        <TrueLinkButton className="btn btn-default" onClick={this.handlePersonalize} variant="none">
          Order Personalized Card
        </TrueLinkButton>
      </div>
    );
  }

  cardholderName() {
    const { first_name, last_name } = this.context.cardholder;
    return `${first_name} ${last_name}`;
  }

  handleKeep() {
    window.location.href = this.fundingTabUrl();
  }

  handlePersonalize() {
    this.setState({ showForm: true });
  }

  fundingTabUrl() {
    return RailsRoutes.select_account_path({
      account_id: this.context.accountId,
      anchor: 'funding_tab_link',
      format: 'html',
    });
  }

  render() {
    return (
      <div style={{ minHeight: 500 }}>
        <h3>Order a Personalized Card</h3>
        Please select if you'd like to order a personalized card for {this.cardholderName()}. This
        is entirely optional. {this.cardholderName()} is welcome to continue using their current
        card printed with Valued Customer. You can wait and order this in the future via the ACCOUNT
        tab at any time.
        {this.state.showForm ? <PersonalizedCardForm /> : this.renderActionBtns()}
      </div>
    );
  }
}

CardholderPersonalizationOptions.contextTypes = {
  cardId: PropTypes.string,
  accountId: PropTypes.string,
  cardholder: PropTypes.object,
  address: PropTypes.object,
  toggleConfirmationModal: PropTypes.func,
};
