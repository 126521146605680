export function contextInfoFilter(notice) {
  notice.context.environment = window.environment;

  notice.context.user = window.user;

  return notice;
}

/*
  When we compile the webpack application.js, webpack jaffe.js, and
  sprockets application.js assets all of them have a unique hash digest
  suffixed to the filename like application-deadbeef1234.js. Every time we
  deploy we get a different filename and because Airbrake includes the
  filename in the backtrace Airbrake does not de-duplicate instances of
  errors correctly.

  */
export function scriptsFilter(notice) {
  notice.context.sourceMaps = {};

  const scripts = document.getElementsByTagName('script');

  scripts.forEach((script) => {
    if (script.src) {
      const scriptUrlAirbrakeSees = new window.URL(script.src);
      const publiclyAccessibleSourceMapUrl = new window.URL(scriptUrlAirbrakeSees);

      if (scriptUrlAirbrakeSees.host.match(/jaffe\.int\./)) {
        publiclyAccessibleSourceMapUrl.host = publiclyAccessibleSourceMapUrl.host.replace(
          'jaffe.int.',
          'member.',
        );
      }

      if (scriptUrlAirbrakeSees.pathname.match(/application-[a-f0-9]+\.js$/)) {
        scriptUrlAirbrakeSees.pathname = scriptUrlAirbrakeSees.pathname.replace(
          /application-[a-f0-9]+\.js$/,
          'application.js',
        );
      }

      notice.context.sourceMaps[scriptUrlAirbrakeSees.toString()] =
        `${publiclyAccessibleSourceMapUrl.toString()}.map`;
    }
  });
  return notice;
}

/*
  This filter walks the backtrace and replaces a specific
  application-deadbeef1234.js with just application.js. It also associates
  these plain application.js files with the appropriately named sourcemap
  with the digest in the filename so Airbrake can give us meaningful
  JavaScript errors. Finally it replaces the `jaffe.int.` hostname with
  a `member.` hostname in the URLs since jaffe is not publicly accessible.

  If we get custom aggregations from Airbrake, if they fix their error
  bucketing and de-duplication, or we use private source maps I think we
  can get rid of a lot of this.
  */
export function backtraceFilter(notice) {
  notice?.errors?.forEach((error) => {
    error?.backtrace?.forEach((frame) => {
      frame.file = frame.file
        .replace(/application-[a-f0-9]+\.js$/, 'application.js')
        .replace('jaffe.int.', 'member.');
    });
  });
  return notice;
}

export function extensionsFilter(notice) {
  if (
    notice?.context?.url?.includes('file:///') ||
    notice?.errors?.some(
      (error) =>
        error?.backtrace?.some(
          (frame) =>
            frame.file.includes('-extension://') ||
            frame.file.includes('munchkin.marketo') ||
            frame.file.includes('connect.facebook.net') ||
            frame.file.includes('maps.googleapis.com/maps/api'),
        ) ||
        error?.message?.includes('setIOSParameters') ||
        error?.message?.includes('PLACES_AUTOCOMPLETE: UNKNOWN_ERROR') ||
        error?.message?.includes('SymBrowser_'),
    )
  ) {
    return null;
  }
  return notice;
}

export function authenticationFilter(notice) {
  if (notice?.errors?.some((error) => error?.message?.includes('status code 401'))) {
    return null;
  }
  return notice;
}
