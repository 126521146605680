import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import * as Yup from 'yup';
import TrueLinkModal from 'react/shared/components/true_link/lab/TrueLinkModal';
import TrueLinkMaskedTextInput from 'react/shared/components/true_link/lab/form/TrueLinkMaskedTextInput';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import TrueLinkLink from 'react/shared/components/true_link/main/TrueLinkLink';
import PALETTE from 'react/shared/theme/palette';
import schema from 'react/shared/utils/schemas';

const useStyles = makeStyles({
  ssnModal: {
    '& .new-form__label': {
      width: 'auto',
      paddingRight: '0',
    },
    '& .new-form__data': {
      marginTop: '-7px',
    },
    '& .modal-content': {
      padding: '30px',
    },
    '& .modal-body': {
      border: 'none',
      padding: '0px',
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
    },
    '& .modal-header': {
      borderBottom: 'none',
      padding: '0px',
    },
    '& .MuiPaper-root': {
      width: '552px',
    },
    '& .errorForm': {
      borderColor: PALETTE.red,
      outline: 'none',
    },
  },
  submitBtn: {
    width: 'fit-content',
  },
  label: {
    color: '#000',
  },
  infoText: {
    color: PALETTE.grey3,
  },
  errorMessage: {
    padding: '18px 24px',
    borderRadius: '6px',
    border: `1px solid`,
    borderColor: PALETTE.red,
    background: PALETTE.redLight,
  },
});
export default function SsnMaskedStringShow({
  canViewSsn,
  ssnOnFile,
  onSuccess,
  taxIdNumberType,
  trustBeneficiaryId,
  trustBeneficiaryStyle,
}) {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(null);
  const [ssn, setSSN] = useState('');

  const validationSchema = Yup.object().shape({
    ssn: schema.ssn,
  });

  const initialValues = {
    ssn: ssn || '',
  };

  const getSSN = () => {
    const params = { id: trustBeneficiaryId, format: 'json' };
    axios.get(RailsRoutes.ssn_dashboard_trust_beneficiary_path(params)).then((response) => {
      setSSN(response.data.ssn);
      setIsModalOpen(true);
    });
  };

  const updateSSN = (values) => {
    const params = { ssn: values.ssn, format: 'json' };
    const routeParams = { id: trustBeneficiaryId };
    axios
      .put(RailsRoutes.update_ssn_dashboard_trust_beneficiary_path(routeParams), params)
      .then(() => {
        Truelink.flash('success', 'SSN successfully updated!');
        if (onSuccess) onSuccess();
        setIsModalOpen(false);
      })
      .catch(() => {
        Truelink.flash('error', 'There was an error trying to update the SSN');
        setIsModalOpen(false);
      });
  };

  const closeModal = () => {
    setSSN('');
    setIsModalOpen(false);
  };

  const openModal = (e) => {
    e.preventDefault();
    getSSN();
  };

  const openTaxIdModal = () => {
    setIsModalOpen(false);
    $('button#view-edit-ein').trigger('click');
  };

  const handleContinue = (values) => {
    updateSSN(values);
  };

  let labelClass = 'new-form__label';
  let dataClass = 'new-form__data';
  let spanClass = '';
  if (trustBeneficiaryStyle) {
    labelClass += ' new-form__label--bene-dashboard';
    dataClass += ' new-form__data--bene-dashboard';
    spanClass += 'hidden';
  }
  return (
    <div className="new-form-field">
      <div className={labelClass}>SSN:</div>
      <div className={dataClass}>
        {ssnOnFile && <span className={spanClass}>XXX-XX-XXXX</span>}
        {canViewSsn && (
          <button className="btn-link" id="view-edit-ssn" onClick={openModal} type="button">
            View/Edit
          </button>
        )}
      </div>
      <div className={classes.ssnModal}>
        {isModalOpen && (
          <TrueLinkModal
            handleClose={closeModal}
            iconCLoseBtn
            modalProps={{ className: classes.ssnModal, maxWidth: 'md' }}
            title={<b>SSN</b>}
          >
            <Formik
              initialValues={initialValues}
              onSubmit={handleContinue}
              validationSchema={validationSchema}
            >
              {({ handleChange, handleSubmit, setFieldTouched, values }) => (
                <>
                  <TrueLinkMaskedTextInput
                    className={
                      taxIdNumberType === 'ssn' && values.ssn?.length === 0 ? 'errorForm' : ''
                    }
                    labelText="Beneficiary's Social Security Number"
                    mask="999-99-9999"
                    name="ssn"
                    onChange={handleChange}
                    placeholder="___-__-____"
                    required
                    setFieldTouched={setFieldTouched}
                    value={values.ssn}
                  />
                  {taxIdNumberType === 'ssn' && values.ssn?.length === 0 && (
                    <div className={classes.errorMessage}>
                      The Beneficiary’s SSN is used for the Tax ID Number. Please enter a SSN or{' '}
                      <TrueLinkLink onClick={openTaxIdModal}>update the Tax ID Number</TrueLinkLink>{' '}
                      setting
                    </div>
                  )}
                  <Typography className={classes.infoText} component="div" variant="body1">
                    For security, only the last 4 digits of the SSN will be provided in the
                    Beneficiary Profile report.
                  </Typography>
                  <TrueLinkButton
                    className={classes.submitBtn}
                    disabled={taxIdNumberType === 'ssn' && values.ssn?.length === 0}
                    onClick={handleSubmit}
                    size="large"
                    type="submit"
                    variant="primary"
                  >
                    Update SSN
                  </TrueLinkButton>
                </>
              )}
            </Formik>
          </TrueLinkModal>
        )}
      </div>
    </div>
  );
}

SsnMaskedStringShow.propTypes = {
  trustBeneficiaryId: PropTypes.number.isRequired,
  canViewSsn: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func,
  taxIdNumberType: PropTypes.string,
  ssnOnFile: PropTypes.bool.isRequired,
  trustBeneficiaryStyle: PropTypes.bool,
};
