import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { noop } from 'jquery';
import PropTypes from 'prop-types';
import React from 'react';
import TransferSchedule from 'react/member/card/components/funding_tab/transfers/TransferSchedule';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import TrueLinkIcon from 'react/shared/components/true_link/main/TrueLinkIcon';
import PALETTE from 'react/shared/theme/palette';
import { currencyFormatter } from 'react/shared/utils/Money';

const useStyles = makeStyles(() => ({
  transferSuccessModal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
    marginTop: '30px',

    '& .fa-circle-check': {
      width: '32px',
      height: '32px',
      color: PALETTE.emerald,
    },
  },
  transferInfo: {
    width: '100%',
    borderTop: '1px solid #ddd',
  },
  transferSchedule: {
    width: '100%',
  },
  transferInfoRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: '1px solid #ddd',
    padding: '10px 0',
  },
  doneBtn: {
    width: '100%',
  },
}));

export default function TransferSuccess({
  transferAmount,
  bankAccountName,
  cardholderName,
  transferDate,
  transferFrequencyDescription,
  transferMemo,
  transferFrequency,
  transferSuccessData,
  onClose,
}) {
  const classes = useStyles();
  const isOneTimeTransfer = transferFrequency === 'one_time';
  const showTransferSchedule =
    transferFrequency === 'every_week' ||
    transferFrequency === 'every_month' ||
    transferFrequency === 'every_month';

  $('.modal-header').hide();

  return (
    <div className={classes.transferSuccessModal} id="transfer-success-modal">
      <TrueLinkIcon className="fa-solid" icon="circle-check" />
      <Typography variant="h3">Success</Typography>
      <Typography variant="body2">
        You’ve scheduled a transfer for {cardholderName}'s True Link Card
      </Typography>
      <div className={classes.transferInfo}>
        <div className={classes.transferInfoRow}>
          <span>Amount</span>
          <span>{currencyFormatter(transferAmount)}</span>
        </div>
        <div className={classes.transferInfoRow}>
          <span>From</span>
          <span>{bankAccountName}</span>
        </div>
        <div className={classes.transferInfoRow}>
          <span>Initiate on</span>
          <span>{isOneTimeTransfer ? transferDate : transferFrequencyDescription}</span>
        </div>
        {transferMemo && (
          <div className={classes.transferInfoRow}>
            <span>Memo</span>
            <span>{transferMemo}</span>
          </div>
        )}
      </div>
      <div className={classes.transferSchedule}>
        {showTransferSchedule && (
          <TransferSchedule onShowTransfer={noop} transfer={transferSuccessData} />
        )}
      </div>

      <TrueLinkButton className={classes.doneBtn} onClick={onClose} type="submit" variant="primary">
        Done
      </TrueLinkButton>
    </div>
  );
}

TransferSuccess.propTypes = {
  cardholderName: PropTypes.string,
  transferAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  bankAccountName: PropTypes.string,
  transferDate: PropTypes.string,
  transferMemo: PropTypes.string,
  transferFrequencyDescription: PropTypes.string,
  transferFrequency: PropTypes.string,
  transferSuccessData: PropTypes.object,
  onClose: PropTypes.func,
};
