import PropTypes from 'prop-types';
import React, { useEffect, useCallback, useState } from 'react';
import DashboardActions from 'react/member/actions/dashboard_actions';
import CategorizedBudgetStore from 'react/member/stores/CategorizedBudgetStore';
import DashboardCategoryStore from 'react/member/stores/DashboardCategoryStore';
import Actions from 'react/shared/actions/actions';
import PopUp from 'react/shared/components/popups/PopUp';

export default function CategorizedBudgetCategoryModal({
  timelineId,
  trustBeneficiary,
  onClose,
  budgetCategory,
  isMonthly,
}) {
  const [available, setAvailable] = useState(budgetCategory?.available);
  const [category, setCategory] = useState(budgetCategory?.name);
  const [categories, setCategories] = useState([]);
  const isEdit = !!budgetCategory;

  const changeCategorySuccess = useCallback(() => {
    onClose();
  }, [onClose]);

  useEffect(() => {
    DashboardCategoryStore.on('categories.fetch', setCategories);
    CategorizedBudgetStore.on('categorizedBudgets.createCategory', changeCategorySuccess);
    CategorizedBudgetStore.on('categorizedBudgets.deleteCategory', changeCategorySuccess);
    CategorizedBudgetStore.on('categorizedBudgets.updateCategory', changeCategorySuccess);

    DashboardActions.getOrFetchCategories(trustBeneficiary.organization.slug);

    return () => {
      DashboardCategoryStore.off('categories.fetch', setCategories);
      CategorizedBudgetStore.off('categorizedBudgets.createCategory', changeCategorySuccess);
      CategorizedBudgetStore.off('categorizedBudgets.deleteCategory', changeCategorySuccess);
      CategorizedBudgetStore.off('categorizedBudgets.updateCategory', changeCategorySuccess);
    };
  }, [changeCategorySuccess, trustBeneficiary.organization.slug]);

  const handleCategorySelect = (e) => {
    setCategory(e.target.value);
  };

  const handleBudgetAvailableChange = (e) => {
    setAvailable(e.target.value);
  };

  const categoryOptions = () =>
    categories.map((cat) => (
      <option key={cat} value={cat}>
        {cat}
      </option>
    ));

  const handleDelete = (e) => {
    e.preventDefault();
    Actions.deleteCategorizedBudgetCategory(budgetCategory.id);
  };

  const handleSave = (e) => {
    e.preventDefault();

    if (isEdit) {
      Actions.updateCategorizedBudgetCategory(budgetCategory.id, {
        amount: available,
        category,
      });
    } else {
      Actions.createCategorizedBudgetCategory(timelineId, {
        amount: available,
        category,
        timeIncrement: isMonthly ? 'monthly' : 'yearly',
      });
    }
  };

  return (
    <PopUp
      footer={
        <div>
          {isEdit && (
            <a className="fleft delete" href="#" onClick={handleDelete}>
              Delete
            </a>
          )}
          <div>
            <button
              className="btn btn-success"
              id="submit-category-button"
              onClick={handleSave}
              type="button"
            >
              {isEdit ? 'Save' : 'Add'} budget category
            </button>
          </div>
        </div>
      }
      header={
        <div>
          {isEdit ? 'Edit' : 'Add'} {isMonthly ? 'monthly' : 'annual'} budget category
        </div>
      }
      maxWidth="550px"
      onClose={onClose}
      openModal
    >
      <div className="budget__modal-form">
        <div className="budget__modal-input-container">
          <label htmlFor="beneficiary-person-name">Beneficiary:</label>
          <span id="beneficiary-person-name">{trustBeneficiary.person.name}</span>
        </div>
        <hr />
        <div className="budget__modal-input-container">
          <label htmlFor="budget-category-input">Category:</label>
          <select id="budget-category-input" onChange={handleCategorySelect} value={category || ''}>
            {categoryOptions()}
          </select>
        </div>
        <div className="budget__modal-input-container">
          <label className="" htmlFor="budget-available-input">
            Budget:
          </label>
          <span>$</span>
          <input
            className="budget__modal-input budget__modal-input--short"
            id="budget-available-input"
            onChange={handleBudgetAvailableChange}
            type="number"
            value={available || ''}
          />
          <span>per {isMonthly ? 'month' : 'year'}</span>
        </div>
      </div>
    </PopUp>
  );
}

CategorizedBudgetCategoryModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isMonthly: PropTypes.bool,
  trustBeneficiary: PropTypes.shape({
    id: PropTypes.number,
    organization: PropTypes.shape({
      slug: PropTypes.string,
    }).isRequired,
    person: PropTypes.shape({
      name: PropTypes.string,
    }).isRequired,
  }).isRequired,
  budgetCategory: PropTypes.shape({
    available: PropTypes.number,
    category: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  timelineId: PropTypes.number,
};
