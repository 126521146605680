import _contains from 'underscore/modules/contains';
import _each from 'underscore/modules/each';

export function extractFormData(serializedArray) {
  const newData = {};
  _each(serializedArray, (datum) => {
    if (_contains(datum.name, '.')) {
      const attrs = datum.name.split('.');
      const outer = attrs[0];
      const inner = attrs[1];
      if (newData[outer] === undefined) {
        newData[outer] = {};
      }
      if (newData[outer][inner] !== undefined) {
        if (Array.isArray(newData[outer][inner])) {
          return newData[outer][inner].push(datum.value);
        }
        return (newData[outer][inner] = [newData[outer][inner], datum.value]);
      }
      return (newData[outer][inner] = datum.value);
    } else if (datum.name.slice(-2) === '[]') {
      const attr = datum.name.slice(0, datum.name.length - 2);
      if (newData[attr] === undefined) {
        newData[attr] = [];
      }
      return newData[attr].push(datum.value);
    }
    if (newData[datum.name] === undefined) {
      return (newData[datum.name] = datum.value);
    }
    if (Array.isArray(newData[datum.name])) {
      return newData[datum.name].push(datum.value);
    }
    return (newData[datum.name] = [newData[datum.name], datum.value]);
  });
  return newData;
}
