import moment from 'moment-timezone';
const stateOptions = () => {
  const none = { value: '', label: 'None' };

  const stateOptions = Object.keys($tlf.domains.states).map((state) => ({
    value: `${state}`,
    label: `${state}`,
  }));

  stateOptions.unshift(none);

  return stateOptions;
};

const monthAbbreviatedOptions = () =>
  Object.values(moment.monthsShort()).map((month) => ({
    value: `${month}`,
    label: `${month}`,
  }));

export default { stateOptions, monthAbbreviatedOptions };
