import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import api from './api.js';
import CardStatus from './card_status/CardStatus';
import CardholderInfo from './cardholder_info/CardholderInfo';
import DashboardActions from 'react/member/actions/dashboard_actions';
import LoadingIndicator from 'react/shared/components/LoadingIndicator';
import DashboardCardStore from 'react/shared/stores/DashboardCardStore';

export default function CardAccountTabContainer({ accountId }) {
  const [cardBalance, setCardBalance] = useState(null);
  const [cardStatusDetails, setCardStatusDetails] = useState(null);
  const [backupCards, setBackupCards] = useState(null);
  const [pendingReissues, setPendingReissues] = useState(null);

  const handleCurrentCardRefresh = useCallback(() => {
    api.getCardStatusDetails(accountId).then((response) => {
      if (response?.data?.account) {
        setCardStatusDetails(response.data);
        // refresh the balance in the dashboard header, too
        DashboardActions.refreshCurrentCardStatus(accountId);
      } else {
        Truelink.flash('error', 'There was an error retrieving card status information.');
      }
    });
  }, [accountId]);

  const handlePendingBackupCardRefresh = useCallback(() => {
    api.getPendingBackupCards(accountId).then((response) => setPendingReissues(response.data));
  }, [accountId]);

  const handleBackupCardRefresh = useCallback(() => {
    api.getBackupCards(accountId).then((response) => setBackupCards(response.data));
  }, [accountId]);

  const handleRefreshAll = useCallback(() => {
    handleCurrentCardRefresh();
    handlePendingBackupCardRefresh();
    handleBackupCardRefresh();
  }, [handleCurrentCardRefresh, handlePendingBackupCardRefresh, handleBackupCardRefresh]);

  useEffect(() => handleRefreshAll(), [accountId, handleRefreshAll]);

  // borrow card balance fetched by dashboard header
  useEffect(() => {
    const setCurrentCardBalance = (accountSlug, refreshedCardStatus) => {
      if (accountSlug !== accountId) return;
      setCardBalance(refreshedCardStatus.card_balance_unformatted);
    };
    DashboardCardStore.on('cards.refreshedCurrentCardStatus', setCurrentCardBalance);
    return function cleanup() {
      DashboardCardStore.off('cards.refreshedCurrentCardStatus', setCurrentCardBalance);
    };
  }, [accountId]);

  if (cardStatusDetails === null || backupCards === null || pendingReissues === null)
    return <LoadingIndicator />;

  const { account, card, cardholder, organization } = cardStatusDetails;
  const { locale_props: localeProps } = account;
  const { card_reissues: cardReissues } = pendingReissues;

  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <CardStatus
        account={account}
        backupCards={backupCards}
        card={card}
        cardBalance={cardBalance}
        cardReissues={cardReissues}
        cardholder={cardholder}
        localeProps={localeProps}
        onBackupCardActivationSuccess={handleRefreshAll}
        onBackupCardCloseSuccess={handleBackupCardRefresh}
        onCardActivationSuccess={handleCurrentCardRefresh}
        onCardCloseSuccess={handleCurrentCardRefresh}
        onCardDeactivateReactivateSuccess={handleCurrentCardRefresh}
        onCardReissueSuccess={handleCurrentCardRefresh}
        organization={organization}
      />
      <CardholderInfo
        accountId={accountId}
        cardholder={cardholder}
        lastFourDigits={card ? card.card_last_4 : undefined}
      />
    </QueryClientProvider>
  );
}

CardAccountTabContainer.propTypes = {
  accountId: PropTypes.string.isRequired,
};
