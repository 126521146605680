import DashboardActions from 'react/member/actions/dashboard_actions';

const DashboardHoldingStore = flux.createStore({
  holdings: [],
  actions: [DashboardActions.fetchHoldings],

  fetchHoldings(client_id) {
    $.get(RailsRoutes.dashboard_client_holdings_path(client_id, { format: 'json' }), (holdings) => {
      this.holdings = holdings;
      this.emit('holdings.fetch', this.holdings);
    });
  },

  exports: {
    getHoldings() {
      return this.holdings;
    },
  },
});

export default DashboardHoldingStore;
