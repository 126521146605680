import axios from 'axios';
import _find from 'underscore/modules/find';
import DashboardActions from 'react/member/actions/dashboard_actions';

const DashboardClientStore = flux.createStore({
  currentClient: {},
  clients: [],
  clientsIdSelectOnly: [],

  actions: [
    DashboardActions.fetchClientBySlug,
    DashboardActions.fetchClientSelectIdsByOrganization,
    DashboardActions.fetchClientSelectIdsByPortfolio,
    DashboardActions.fetchClientSelectIds,
    DashboardActions.fetchClientsByOrganization,
    DashboardActions.fetchClientsByPortfolio,
    DashboardActions.fetchClients,
    DashboardActions.linkCardToClient,
    DashboardActions.createTrustBeneficiary,
  ],

  fetchClientBySlug(clientSlug) {
    axios
      .get(RailsRoutes.dashboard_client_path(clientSlug, { format: 'json' }))
      .then((clientResponse) => {
        this.currentClient = clientResponse.data;
        this.emit('client.fetch', this.currentClient);
      });
  },

  fetchClientSelectIds() {
    axios
      .get(
        RailsRoutes.dashboard_clients_path({
          format: 'json',
          id_select_only: 1,
        }),
      )
      .then((clientsResponse) => {
        this.clientsIdSelectOnly = clientsResponse.data;
        this.emit('clients.fetchSelectIds', this.clientsIdSelectOnly);
      });
  },

  fetchClientSelectIdsByPortfolio(portfolio_id) {
    axios
      .get(
        RailsRoutes.dashboard_portfolio_clients_path(portfolio_id, {
          format: 'json',
          id_select_only: 1,
        }),
      )
      .then((clientsResponse) => {
        this.clientsIdSelectOnly = clientsResponse.data;
        this.emit('clients.fetchSelectIds', this.clientsIdSelectOnly);
      });
  },

  fetchClientSelectIdsByTrust(trust_slug) {
    axios
      .get(
        RailsRoutes.dashboard_trust_clients_path(trust_slug, {
          format: 'json',
          id_select_only: 1,
        }),
      )
      .then((clientsResponse) => {
        this.clientsIdSelectOnly = clientsResponse.data;
        this.emit('clients.fetchSelectIds', this.clientsIdSelectOnly);
      });
  },

  fetchClientSelectIdsByOrganization(organization_slug) {
    axios
      .get(
        RailsRoutes.dashboard_organization_clients_path(organization_slug, {
          format: 'json',
          id_select_only: 1,
        }),
      )
      .then((clientsResponse) => {
        this.clientsIdSelectOnly = clientsResponse.data;
        this.emit('clients.fetchSelectIds', this.clientsIdSelectOnly);
      });
  },

  fetchClientsByOrganization(organization_slug, params = {}) {
    axios
      .get(
        RailsRoutes.dashboard_organization_clients_path(organization_slug, {
          format: 'json',
          ...params,
        }),
      )
      .then((clientsResponse) => {
        this.clients = clientsResponse.data;
        this.emit('clients.fetch', this.clients);
      });
  },

  fetchClientsByPortfolio(portfolio_id, params = {}) {
    axios
      .get(
        RailsRoutes.dashboard_portfolio_clients_path(portfolio_id, {
          format: 'json',
          ...params,
        }),
      )
      .then((clientsResponse) => {
        this.clients = clientsResponse.data;
        this.emit('clients.fetch', this.clients);
      });
  },

  fetchClients() {
    axios.get(RailsRoutes.dashboard_clients_path({ format: 'json' })).then((clientsResponse) => {
      this.clients = clientsResponse.data;
      this.emit('clients.fetch', this.clients);
    });
  },

  linkCardToClient(client_slug, card_id) {
    return $.ajax({
      data: { card_id },
      url: RailsRoutes.dashboard_client_link_card_path(client_slug, {
        format: 'json',
      }),
      method: 'PUT',
    }).done((response) => {
      if (response['success']) {
        Truelink.flash('success', 'This card has been linked!');
        return this.emit('client.linkCard');
      }
      if (response['error'] === 'auto_transfers') {
        return Truelink.flash(
          'error',
          'Please go to the card dashboard for this card and remove the existing recurring transfer before connecting. Questions? Contact 1 (855) 435-5140',
        );
      }
      return Truelink.flash(
        'error',
        'Error linking this card. Please contact True Link customer support at 1 (855) 435-5140!',
      );
    });
  },

  createTrustBeneficiary(data, url) {
    return $.ajax({
      url,
      type: 'POST',
      data,
      dataType: 'json',
    })
      .done((client) => {
        this.clients.push(client);
        Truelink.flash('success', 'New Trust Beneficiary has been created!');
        return this.emit('clients.add', client);
      })
      .fail((_response) =>
        Truelink.flash(
          'error',
          'Unable to create new trust beneficiary. Please contact True Link support for further assistance.',
        ),
      );
  },

  exports: {
    get(slug) {
      return _find(this.clients, (beneficiary) => beneficiary.slug === slug);
    },

    getClients() {
      return this.clients;
    },

    getNameById(slug) {
      const beneficiary = _find(this.clients, (tb) => tb.slug === slug);
      return beneficiary && beneficiary.person.name;
    },
  },
});
export default DashboardClientStore;
