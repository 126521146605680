import { ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import TextField from 'react/member/components/signups/signup_form/form_partials/fields/TextField';
import FieldLabel from 'react/member/components/signups/signup_form/form_partials/labels/field_label';

function EmailPasswordFields({ disabled, rowClass, colClass, viewport }) {
  const emailLabelContent = viewport === 'mobile' ? 'Email' : 'Email address';
  return (
    <div>
      <div className={rowClass}>
        <div className={colClass}>
          <FieldLabel labelContent={emailLabelContent} />
          <TextField
            autoComplete="off"
            dataAnalyticsKey="email"
            disabled={disabled}
            id="user_email"
            name="user.email"
            type="email"
          />
          <ErrorMessage className="error" component="label" name="user.email" />
        </div>
      </div>
      <div className={`${rowClass} password-row`}>
        <div className={colClass}>
          <FieldLabel
            afterLabel={
              <span>
                8 characters <span className="hide-on-mobile">required</span>
              </span>
            }
            htmlFor="user_password"
            labelContent="Password"
          />
          <TextField
            autoComplete="off"
            dataAnalyticsKey="password"
            disabled={disabled}
            id="user_password"
            name="user.password"
            type="password"
          />
          <ErrorMessage className="error" component="label" name="user.password" />
        </div>
      </div>
      <div className={`${rowClass} password-row`}>
        <div className={colClass}>
          <FieldLabel htmlFor="user_password_confirmation" labelContent="Confirm password" />
          <TextField
            autoComplete="off"
            dataAnalyticsKey="password_confirm"
            disabled={disabled}
            id="user_password_confirmation"
            name="user.password_confirmation"
            type="password"
          />
          <ErrorMessage className="error" component="label" name="user.password_confirmation" />
        </div>
      </div>
    </div>
  );
}

EmailPasswordFields.propTypes = {
  disabled: PropTypes.bool,
  rowClass: PropTypes.string,
  colClass: PropTypes.string,
  viewport: PropTypes.string,
};

EmailPasswordFields.defaultProps = {
  disabled: false,
  rowClass: 'card--form__row',
  colClass: 'card--form__col',
  viewport: null,
};

export default EmailPasswordFields;
