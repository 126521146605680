import makeStyles from '@mui/styles/makeStyles';
import I18n from 'i18n-js';
import PropTypes from 'prop-types';
import React from 'react';
import CardholderAddress from './address/CardholderAddress';
import PendingAddressChange from './address/PendingAddressChange';
import CardholderNickname from './nickname/CardholderNickname';
import PhoneField from './phone/PhoneField';
import { cardholderShape } from 'react/member/card/shapes/CardholderShape';
import SubSectionHeader from 'react/shared/components/SubSectionHeader';

const useStyles = makeStyles({
  cardholderTable: {
    '& td:first-of-type': {
      textAlign: 'right',
    },
  },
  balance: {
    borderTop: '1px solid #aaa',
    paddingLeft: '0',
  },
  infoIcon: {
    marginLeft: 0,
  },
});

export default function CardholderInfo({ accountId, cardholder, lastFourDigits }) {
  const classes = useStyles();
  const mobileEditRoute = RailsRoutes.dashboard_update_cardholder_path(accountId, {
    format: 'json',
  });
  return (
    <>
      <SubSectionHeader>Cardholder information</SubSectionHeader>
      <div className="account_info_section" id="cardholder_information_section">
        <table className={classes.cardholderTable}>
          <tbody>
            <tr>
              <td>Name:</td>
              <td>{cardholder.name}</td>
            </tr>
            <tr>
              <td>Account ID:</td>
              <td>
                <CardholderNickname accountId={accountId} cardholder={cardholder} />
              </td>
            </tr>
            {lastFourDigits && (
              <tr>
                <td>Last 4 digits of True Link Card:</td>
                <td>{lastFourDigits}</td>
              </tr>
            )}
            <tr>
              <td>Address:</td>
              <td>
                <CardholderAddress accountId={accountId} address={cardholder.address} />
              </td>
            </tr>
            <tr>
              <td>Phone:</td>
              <td>
                <PhoneField
                  mobile={cardholder.mobile}
                  name={cardholder.name}
                  objectType="person"
                  route={mobileEditRoute}
                />
              </td>
            </tr>
            <tr>
              <td />
              <td className={classes.balance}>
                <i className={`info-circle ${classes.infoIcon}`} />
                <em>
                  {`The cardholder can text BALANCE to ${I18n.t(
                    'corp.ch_tollfree',
                  )} to see their card's current balance.`}
                </em>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {cardholder.address_change_request && (
        <PendingAddressChange addressChangeRequest={cardholder.address_change_request} />
      )}
    </>
  );
}

CardholderInfo.propTypes = {
  accountId: PropTypes.string.isRequired,
  cardholder: cardholderShape.isRequired,
  lastFourDigits: PropTypes.string,
};
