import PropTypes from 'prop-types';
import React from 'react';
import _map from 'underscore/modules/map';
import CustomerBannerContent from 'react/shared/components/customer_banners/customer_banner_content';
import customerBannerShape from 'react/shared/shapes/customer_banner_shape';

function CustomerBannerNotifications(props) {
  if (props.banners.length === 0) return null;

  return (
    <div id="customer-banner-notifications">
      {_map(props.banners, (banner) => (
        <CustomerBannerContent banner={banner} key={banner.id} />
      ))}
    </div>
  );
}

CustomerBannerNotifications.propTypes = {
  banners: PropTypes.arrayOf(customerBannerShape).isRequired,
};

export default CustomerBannerNotifications;
