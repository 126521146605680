import PropTypes from 'prop-types';
import React from 'react';
import BackupCardStatus from './backup_card_statuses/BackupCardStatus';
import CardReissueStatus from './card_reissue_statuses/CardReissueStatus';
import CurrentCardStatus from './current_card_statuses/CurrentCardStatus';
import { accountShape } from 'react/member/card/shapes/AccountShape';
import { cardOrderShape } from 'react/member/card/shapes/CardOrderShape';
import { cardShape } from 'react/member/card/shapes/CardShape';
import { cardholderShape } from 'react/member/card/shapes/CardholderShape';
import { organizationShape } from 'react/member/card/shapes/OrganizationShape';
import SubSectionHeader from 'react/shared/components/SubSectionHeader';
import localeShape from 'react/shared/shapes/locale_shape';

export default function CardStatus({
  account,
  backupCards,
  card,
  cardBalance,
  cardholder,
  cardReissues,
  localeProps,
  onBackupCardActivationSuccess,
  onBackupCardCloseSuccess,
  onCardActivationSuccess,
  onCardCloseSuccess,
  onCardDeactivateReactivateSuccess,
  onCardReissueSuccess,
  organization,
}) {
  const pendingReissue = card?.card_reissue;
  const incompletePendingReissue =
    pendingReissue?.orderedCardStatus === 'ISSUED_INACTIVE' ||
    pendingReissue?.orderedCardStatus === null;

  return (
    <div id="card_status_section">
      <SubSectionHeader>Card status</SubSectionHeader>
      <CurrentCardStatus
        account={account}
        backupCards={backupCards}
        card={card}
        cardBalance={cardBalance}
        cardReissues={cardReissues}
        cardholder={cardholder}
        localeProps={localeProps}
        onCardActivationSuccess={onCardActivationSuccess}
        onCardCloseSuccess={onCardCloseSuccess}
        onCardDeactivateReactivateSuccess={onCardDeactivateReactivateSuccess}
        onCardReissueSuccess={onCardReissueSuccess}
        organization={organization}
      />
      {pendingReissue && incompletePendingReissue && (
        <CardReissueStatus
          account={account}
          card={card}
          cardReissue={pendingReissue}
          cardholder={cardholder}
        />
      )}
      {cardReissues.map((cardReissue) => (
        <CardReissueStatus
          account={account}
          card={card}
          cardReissue={cardReissue}
          cardholder={cardholder}
          key={cardReissue.id}
        />
      ))}
      {backupCards.map((backupCard) => (
        <BackupCardStatus
          account={account}
          card={backupCard}
          cardholder={cardholder}
          key={backupCard.slug}
          localeProps={localeProps}
          oldCardLast4={card.card_last_4}
          onBackupCardActivationSuccess={onBackupCardActivationSuccess}
          onBackupCardCloseSuccess={onBackupCardCloseSuccess}
        />
      ))}
    </div>
  );
}

CardStatus.propTypes = {
  account: accountShape.isRequired,
  backupCards: PropTypes.arrayOf(cardShape).isRequired,
  card: cardShape,
  cardBalance: PropTypes.number,
  cardholder: cardholderShape.isRequired,
  cardReissues: PropTypes.arrayOf(cardOrderShape).isRequired,
  localeProps: localeShape.isRequired,
  onBackupCardActivationSuccess: PropTypes.func.isRequired,
  onBackupCardCloseSuccess: PropTypes.func.isRequired,
  onCardActivationSuccess: PropTypes.func.isRequired,
  onCardCloseSuccess: PropTypes.func.isRequired,
  onCardDeactivateReactivateSuccess: PropTypes.func.isRequired,
  onCardReissueSuccess: PropTypes.func.isRequired,
  organization: organizationShape.isRequired,
};
