import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import DashboardActions from 'react/member/actions/dashboard_actions';
import DashboardDailyPortfolioCloseoutStore from 'react/member/stores/DashboardDailyPortfolioCloseoutStore';
import bindAll from 'react/shared/utils/bind_all';

export default class LatestCloseoutTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      closeout: {},
    };

    bindAll(this);
  }

  componentDidMount() {
    DashboardDailyPortfolioCloseoutStore.on(
      'dailyPortfolioCloseout.fetchLatest',
      this.onChangeCloseout,
    );

    DashboardActions.fetchLatestCloseout(this.context.portfolio.id);
  }

  componentWillUnmount() {
    DashboardDailyPortfolioCloseoutStore.off(
      'dailyPortfolioCloseout.fetchLatest',
      this.onChangeCloseout,
    );
  }

  onChangeCloseout(closeout) {
    this.setState({ closeout });
  }

  closeoutDate() {
    if (this.state.closeout.closeout_date) {
      return moment(this.state.closeout.closeout_date).format('MM/DD/YYYY');
    }
  }

  unitValueRow() {
    return (
      <tr>
        <th>Unit Value</th>
        <td>{this.state.closeout.unit_value_balance}</td>
        <td>{this.state.closeout.prior_day_unit_value_balance}</td>
        <td>{this.state.closeout.unit_value_balance_change}</td>
        <td>{this.state.closeout.unit_value_balance_change_percentage}</td>
      </tr>
    );
  }

  totalUnitsRow() {
    return (
      <tr>
        <th>Total Units</th>
        <td>{this.state.closeout.total_units_balance}</td>
        <td>{this.state.closeout.prior_day_total_units_balance}</td>
        <td>{this.state.closeout.total_units_balance_change}</td>
        <td>&nbsp;</td>
      </tr>
    );
  }

  totalAssetsRow() {
    return (
      <tr>
        <th>Total Assets</th>
        <td>{this.state.closeout.total_assets_balance}</td>
        <td>{this.state.closeout.prior_day_total_assets_balance}</td>
        <td>{this.state.closeout.total_assets_balance_change}</td>
        <td>&nbsp;</td>
      </tr>
    );
  }

  render() {
    return (
      <div id="portfolio-trust-summary-tab" style={{ textAlign: 'initial', marginTop: '35px' }}>
        <div className="sub_section_header">Account Summary</div>
        <table
          className="table-condensed"
          style={{
            borderColor: 'none',
            width: 940,
            height: 258,
            tableLayout: 'fixed',
            textAlign: 'left',
          }}
        >
          <thead>
            <tr>
              <th />
              <th>As of {this.closeoutDate()}</th>
              <th>Prior Day</th>
              <th>Change</th>
              <th>%</th>
            </tr>
          </thead>
          <tbody>
            {this.totalAssetsRow()}
            {this.totalUnitsRow()}
            {this.unitValueRow()}
            <tr />
          </tbody>
        </table>
      </div>
    );
  }
}

LatestCloseoutTable.contextTypes = {
  portfolio: PropTypes.object,
};
