import PropTypes from 'prop-types';
import React from 'react';

export default function HoldingRow({ holding }) {
  return (
    <tr>
      <td colSpan={2}>{holding.security}</td>
      <td />
      <td>{holding.units}</td>
      <td>{holding.unit_value}</td>
      <td>{holding.total_value}</td>
    </tr>
  );
}

HoldingRow.propTypes = {
  holding: PropTypes.object.isRequired,
};
