import PropTypes from 'prop-types';
import React from 'react';
import _isEmpty from 'underscore/modules/isEmpty';
import GenericInlineLabel from './GenericInlineLabel';
import ReactDropdown from 'react/shared/components/forms/ReactDropdown';
import GenericSubmitPopUp from 'react/shared/components/popups/GenericSubmitPopUp';
import genericSubmitPopUpShapeKeys from 'react/shared/shapes/GenericSubmitPopUpShape';
import dropdownOptionsShape from 'react/shared/shapes/dropdown_options_shape';
import { underscorize } from 'react/shared/utils/Strings';
import bindAll from 'react/shared/utils/bind_all';

export default class GenericDropdownPopUp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedValue: null,
      valid: props.includeBlankOption,
      dirty: false,
    };

    bindAll(this);
  }

  handleValidate(value) {
    return this.props.includeBlankOption || !_isEmpty(value);
  }

  handleDropdownChange({ target: { value } }) {
    this.setState({
      selectedValue: value,
      valid: this.handleValidate(value),
      dirty: true,
    });
  }

  handleValidateOnSubmit() {
    const { selectedValue } = this.state;
    const valid = this.handleValidate(selectedValue);

    if (valid) {
      this.props.onSubmit(selectedValue);
    } else {
      this.setState({ valid: false });
    }
  }

  renderDropdownWithLabel() {
    const { dropdownLabel } = this.props;

    const inputId = `generic-popup-dropdown-${underscorize(dropdownLabel)}`;

    return (
      <GenericInlineLabel inputId={inputId} labelContent={dropdownLabel}>
        <ReactDropdown
          includeBlankOption={this.props.includeBlankOption}
          inputId={inputId}
          onChange={this.handleDropdownChange}
          options={this.props.dropdownOptions}
        />
      </GenericInlineLabel>
    );
  }

  render() {
    return (
      <GenericSubmitPopUp
        {...this.props}
        disableSubmit={!this.state.valid}
        displayErrorText={!this.state.valid && this.state.dirty}
        messageNode={this.renderDropdownWithLabel()}
        onSubmit={this.handleValidateOnSubmit}
      />
    );
  }
}

GenericDropdownPopUp.propTypes = {
  ...genericSubmitPopUpShapeKeys,
  dropdownLabel: PropTypes.string.isRequired,
  dropdownOptions: dropdownOptionsShape.isRequired,
  includeBlankOption: PropTypes.bool,
};

GenericDropdownPopUp.defaultProps = {
  includeBlankOption: false,
  errorText: 'Please select a value',
};
