import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import _map from 'underscore/modules/map';
import PhoneField from 'react/member/card/components/account_tab/cardholder_info/phone/PhoneField';
import Address from 'react/member/components/common/Address';
import AccessCodeModal from 'react/member/components/dashboard/AccessCodeModal';
import MfaField from 'react/member/components/mfa/MfaField';
import TrueLinkIcon from 'react/shared/components/true_link/main/TrueLinkIcon';
import TrueLinkLink from 'react/shared/components/true_link/main/TrueLinkLink';

const useStyles = makeStyles(() => ({
  profile: {
    margin: '20px',
    '& tr:first-child > td': {
      borderTop: 'none',
    },
    '& td': {
      width: '60%',
    },
    '& td.table-cell-left': {
      width: '40%',
    },
  },
}));

export default function UserProfile({ address, administrator, users, hideUsers, orgName }) {
  const classes = useStyles();
  const [accessCodeModalOpen, setAccessCodeModalOpen] = useState(false);

  const openAccessCodeModal = useCallback(() => {
    setAccessCodeModalOpen(true);
  }, []);

  const closeAccessCodeModal = useCallback(() => {
    setAccessCodeModalOpen(false);
  }, []);

  const usersList = () => {
    const rows = _map(users, (u) => (
      <tr key={u.id}>
        <td> {u.name} </td>
        <td> {u.email} </td>
        <td> {u.disbursement_approver ? 'Approver' : 'Not approver'} </td>
      </tr>
    ));

    return (
      <table className="table" style={{ width: '100%' }}>
        <thead>
          <tr>
            <th> Name </th>
            <th> Email address </th>
            <th> Disbursement approval </th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    );
  };

  const user = administrator;
  const mobileEditRoute = RailsRoutes.api_v1_user_path({ id: user.slug, format: 'json' });
  const userList = !hideUsers ? (
    <div>
      <div className="sub_section_header"> Disbursement approval rights </div>
      {usersList()}
    </div>
  ) : undefined;
  return (
    <div className={classes.profile}>
      <TrueLinkLink
        aria-label="back-button"
        href={RailsRoutes.user_home_path()}
        id="profile-back-btn"
      >
        <TrueLinkIcon icon="chevron-left" />
        Back
      </TrueLinkLink>
      <div>
        <div className="sub_section_header"> Your account</div>
        <table className="table" style={{ width: '80%' }}>
          <tbody>
            <tr>
              <td className="table-cell-left"> Your name: </td>
              <td> {user.full_name}</td>
            </tr>
            <tr>
              <td className="table-cell-left"> Your address: </td>
              <td>
                <Address address={address} />
              </td>
            </tr>
            <tr>
              <td className="table-cell-left"> Your email address: </td>
              <td> {user.email}</td>
            </tr>
            <tr>
              <td className="table-cell-left"> Your phone number: </td>
              <td>
                <PhoneField
                  mobile={user.mobile}
                  name={user.full_name}
                  objectType="user"
                  route={mobileEditRoute}
                />
              </td>
            </tr>
            {orgName && (
              <tr>
                <td className="table-cell-left"> Your organization: </td>
                <td> {orgName}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div>
        <div className="sub_section_header"> Your security settings</div>
        <table className="table" style={{ width: '80%' }}>
          <tbody>
            <tr>
              <td className="table-cell-left"> Your password: </td>
              <td>
                {' '}
                Click <a href="/users/edit"> here </a> to change your password.{' '}
              </td>
            </tr>
            <tr>
              <td className="table-cell-left">Two-step verification:</td>
              <td>
                <MfaField
                  email={user.email}
                  enable={!user.mfa_enabled}
                  mfaDeliveryOption={user.mfa_delivery_option}
                  phoneNumber={user.mobile}
                />
              </td>
            </tr>
            <tr>
              <td className="table-cell-left"> Access code: </td>
              <td>
                <div>
                  xxxx <TrueLinkLink onClick={openAccessCodeModal}>View and Edit</TrueLinkLink>
                </div>
                <div>
                  We use Access Code to verify your identity when you call us about your True Link
                  accounts. Never share your Access Code with anyone.
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {userList}

      <div>
        <div className="sub_section_header"> Account updates</div>
        <p>
          {' '}
          Please{' '}
          <a href="/contact-us" target="_blank">
            contact us
          </a>{' '}
          to change your name, email, mailing address, or account settings.{' '}
        </p>
      </div>
      <div>
        <div className="sub_section_header"> Legal notices</div>
        <a href="/legal/terms-of-service" target="_blank">
          {' '}
          Website Terms & Conditions
        </a>
        <br />
        <a href="/legal/privacy-policy" target="_blank">
          {' '}
          Privacy Policy{' '}
        </a>
      </div>
      {accessCodeModalOpen && <AccessCodeModal onClose={closeAccessCodeModal} userId={user.slug} />}
    </div>
  );
}

UserProfile.propTypes = {
  address: PropTypes.object,
  administrator: PropTypes.object.isRequired,
  users: PropTypes.array,
  hideUsers: PropTypes.bool,
  orgName: PropTypes.string,
};
