// Note: this class manages a multi-step modal
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import _contains from 'underscore/modules/contains';
import _filter from 'underscore/modules/filter';
import _without from 'underscore/modules/without';
import MultipleForm from './MultipleForm';
import MultipleParsed from './MultipleParsed';
import DashboardActions from 'react/member/actions/dashboard_actions';
import DashboardDepositStore from 'react/member/stores/DashboardDepositStore';
import PopUp from 'react/shared/components/popups/PopUp';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import bindAll from 'react/shared/utils/bind_all';

export default class DashboardDepositMultiple extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      parsed: false,
      records: [],
      invalidRecords: [],
      selected: [],
      isSubmitting: false,
    };

    bindAll(this);
  }

  componentDidMount() {
    DashboardDepositStore.on('multipleDeposits.create', this.depositsCreated);
  }

  componentWillUnmount() {
    DashboardDepositStore.off('multipleDeposits.create', this.depositsCreated);
  }

  submitMultipleDepositsForm() {
    this._uploadForm.submitForm();
  }

  setUploadFormRef(instance) {
    this._uploadForm = instance;
  }

  resetParsed() {
    this.setState({ parsed: false });
  }

  indexParsed(r, index) {
    return { ...r, index };
  }

  handleParsed(response) {
    const validIndexed = response.validRecords.map(this.indexParsed);
    const invalidIndexed = response.invalidRecords.map(this.indexParsed);

    this.setState({
      parsed: true,
      records: validIndexed,
      invalidRecords: invalidIndexed,
      selected: validIndexed.map((r) => r.index),
      isSubmitting: false,
    });
  }

  handleParsingError(errorMessage) {
    Truelink.flash('error', errorMessage.message);
    this.setState({
      isSubmitting: false,
    });
  }

  checkRecord(e) {
    const { checked } = e.target;
    let { value } = e.target;
    let { selected } = this.state;

    value = Number.parseInt(value, 10);
    if (checked) selected.push(value);
    else selected = _without(selected, value);

    this.setState({ selected });
  }

  filterSelectedRecords() {
    return _filter(this.state.records, (r) => _contains(this.state.selected, r.index));
  }

  createMultipleDeposits() {
    this.setState({ isSubmitting: true });
    const { actions, organizationSlug } = this.props;
    actions.createMultipleDeposits(this.filterSelectedRecords(), organizationSlug);
  }

  depositsCreated() {
    window.Truelink.flash('success', 'Deposits created.');
    this.props.refreshDeposits();
    this.props.onClose();
  }

  renderForm() {
    return (
      <PopUp
        footer={
          <TrueLinkButton
            className={classNames('btn btn-success', {
              disabled: this.state.isSubmitting,
            })}
            disabled={this.state.isSubmitting}
            onClick={this.submitMultipleDepositsForm}
            variant="none"
          >
            {this.state.isSubmitting ? 'Submitting...' : 'Upload'}
          </TrueLinkButton>
        }
        header="Add multiple deposit records"
        modalOpen
        onClose={this.props.onClose}
      >
        <MultipleForm
          onError={this.handleParsingError}
          onSuccess={this.handleParsed}
          organizationSlug={this.props.organizationSlug}
          ref={this.setUploadFormRef}
        />
      </PopUp>
    );
  }

  renderParsed() {
    return (
      <PopUp
        footer={
          <div>
            <a className="pull-left" href="#" onClick={this.resetParsed}>
              Back
            </a>
            <TrueLinkButton
              className={classNames('btn btn-success pull-right', {
                disabled: this.state.isSubmitting,
              })}
              disabled={this.state.isSubmitting}
              onClick={this.createMultipleDeposits}
              variant="none"
            >
              {this.state.isSubmitting
                ? 'Submitting...'
                : `Add ${this.state.selected.length} deposit records`}
            </TrueLinkButton>
          </div>
        }
        header="Add multiple deposit records"
        modalOpen
        onClose={this.props.onClose}
      >
        <MultipleParsed
          checkRecord={this.checkRecord}
          invalidRecords={this.state.invalidRecords}
          records={this.state.records}
          resetParsed={this.resetParsed}
          selected={this.state.selected}
        />
      </PopUp>
    );
  }

  render() {
    if (this.state.parsed) {
      return this.renderParsed();
    }
    return this.renderForm();
  }
}

DashboardDepositMultiple.propTypes = {
  organizationSlug: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  refreshDeposits: PropTypes.func.isRequired,
  actions: PropTypes.object,
};

DashboardDepositMultiple.defaultProps = {
  actions: DashboardActions,
};
