import PropTypes from 'prop-types';
import React from 'react';
import _contains from 'underscore/modules/contains';
import _filter from 'underscore/modules/filter';
import _reduce from 'underscore/modules/reduce';
import bindAll from 'react/shared/utils/bind_all';

export default class PayeeDropdown extends React.Component {
  constructor(props) {
    super(props);
    bindAll(this);
  }

  disableInputs() {
    return !this.props.payeesLoaded || this.props.currentClientId === 0;
  }

  disablePayeeSelect() {
    return this.props.payees.length < 1 || this.disableInputs();
  }

  formatPayee(payee, idx) {
    return (
      <option key={`${payee.id}-${idx}`} value={payee.id}>
        {this.formatPayeeText(payee)}
      </option>
    );
  }

  formatPayeeText(payee) {
    const client_account_number_text = payee.client_account_number
      ? ` (${payee.client_account_number})`
      : '';

    const getObfuscatedBankAccountNumber = (number) =>
      number ? ` - *****${number.slice(-4)}` : '';

    const supportedMethods = payee.supported_payment_methods;
    if (supportedMethods.includes('Check') || supportedMethods.includes('External Check')) {
      const displayAddress = this.formatAddress(payee.address_attributes);
      return `${payee.name}${client_account_number_text}${displayAddress}`;
    } else if (supportedMethods.includes('EFT') || supportedMethods.includes('Wire')) {
      const obfuscated_bank_account_number = getObfuscatedBankAccountNumber(
        payee.bank_account_number,
      );
      return `${payee.name}${client_account_number_text}${obfuscated_bank_account_number}`;
    }

    return `${payee.name}${client_account_number_text}`;
  }

  formatAddress(address) {
    // Ideally this would be tighter controlled, but it is legacy
    // eslint-disable-next-line no-eq-null
    const isNull = _reduce(address, (result, value) => result && value == null, true);
    if (isNull) {
      return '';
    }

    const cleanedAddress = _reduce(
      address,
      (result, value, key) => {
        const newVal = value ? value : '';
        result[key] = newVal.trim();
        return result;
      },
      {},
    );

    const street1 = cleanedAddress.street1.length > 0 ? `${cleanedAddress.street1}, ` : '';
    const street2 = cleanedAddress.street2.length > 0 ? `${cleanedAddress.street2}, ` : '';
    const city = cleanedAddress.city.length > 0 ? `${cleanedAddress.city}, ` : '';
    const state = cleanedAddress.state.length > 0 ? `${cleanedAddress.state} ` : '';
    const zip = cleanedAddress.zip.length > 0 ? `${cleanedAddress.zip}` : '';

    return ` - ${street1}${street2}${city}${state}${zip}`;
  }

  onPayeeChosen(evt) {
    const payee = this.props.payees.find((element) => element.id == evt.target.value);
    this.props.handlePayeeChosen(payee);
  }

  payeesList() {
    const { currentPaymentMethod } = this.props;
    const filteredList = currentPaymentMethod
      ? _filter(this.props.payees, (p) =>
          _contains(p.supported_payment_methods, currentPaymentMethod),
        )
      : this.props.payees;

    filteredList.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

    return (
      <div style={{ width: '50%', display: 'inline' }}>
        {' '}
        {this.label()}
        <select
          className={this.conditionalExtraWideSelectClass()}
          disabled={this.disablePayeeSelect()}
          id="payeeId"
          name="payee_id"
          onChange={this.onPayeeChosen}
          value={this.props.selectedPayee ? this.props.selectedPayee.id : ''}
        >
          <option key="" value="">
            Select Payee
          </option>
          {filteredList.map((payee, idx) => this.formatPayee(payee, idx))}
        </select>
      </div>
    );
  }

  label() {
    if (!this.props.hideLabel) {
      return <label htmlFor="payeeId">Payee</label>;
    }
    return null;
  }

  conditionalExtraWideSelectClass() {
    return this.props.isEditing ? 'wide-payee-selection' : 'extra-wide-payee-selection';
  }

  addPayeeButton() {
    if (!this.props.canCreatePayee) return null;

    return (
      <div className="button-wrapper" style={{ width: '50%', display: 'inline', marginLeft: 15 }}>
        <button
          className="btn normal"
          disabled={this.disableInputs()}
          onClick={this.props.togglePayeeForm}
          style={this.props.btnStyle ? { margin: '10px 0px 0px -15px' } : {}}
          type="button"
        >
          <b>+</b> New Payee
        </button>
      </div>
    );
  }

  render() {
    const list = this.payeesList();
    const payeeButton = this.addPayeeButton();

    return (
      <div>
        <div style={{ width: '50%', display: 'inline' }}>{list}</div>
        {payeeButton}
      </div>
    );
  }
}

PayeeDropdown.propTypes = {
  btnStyle: PropTypes.bool,
  currentClientId: PropTypes.string,
  payees: PropTypes.array.isRequired,
  handlePayeeChosen: PropTypes.func.isRequired,
  togglePayeeForm: PropTypes.func.isRequired,
  currentPaymentMethod: PropTypes.string,
  hideLabel: PropTypes.bool,
  payeesLoaded: PropTypes.bool.isRequired,
  selectedPayee: PropTypes.shape({
    id: PropTypes.number,
  }),
  canCreatePayee: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool,
};
