import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import DashboardCardCloseRequestForm from './DashboardCardCloseRequestForm';
import { cardShape } from 'react/member/card/shapes/CardShape';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';

const useStyles = makeStyles({
  closeButton: {
    alignItems: 'center',
    display: 'flex',
    '& svg': {
      marginRight: '10px',
    },
  },
});

export default function PermanentlyCloseCardButton({ card, onCardCloseSuccess }) {
  const classes = useStyles();
  const { card_close_form } = card;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (ev) => {
    ev.preventDefault();
    setIsModalOpen(true);
  };

  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <div>
      <TrueLinkButton className={classes.closeButton} onClick={openModal} variant="primaryOutline">
        <i className="fa fa-xmark" />
        Permanently close
      </TrueLinkButton>
      {isModalOpen && (
        <DashboardCardCloseRequestForm
          addresses={card_close_form.addresses}
          bank_accounts={card_close_form.bank_accounts}
          card={card_close_form.card}
          closeModal={handleCloseModal}
          onSuccess={onCardCloseSuccess}
          reasons={card_close_form.reasons}
        />
      )}
    </div>
  );
}

PermanentlyCloseCardButton.propTypes = {
  card: cardShape.isRequired,
  onCardCloseSuccess: PropTypes.func.isRequired,
};
