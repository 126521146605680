import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import childrenShape from 'react/shared/shapes/children';
//import theme from 'react/shared/theme/Theme';
import PALETTE from 'react/shared/theme/palette';

const disabledStyles = {
  backgroundColor: PALETTE.grey3,
  border: `1px solid ${PALETTE.grey3}`,
  color: PALETTE.white,
  opacity: '100%',
  '&:hover': {
    backgroundColor: PALETTE.grey3,
    border: `1px solid ${PALETTE.grey3}`,
    color: PALETTE.white,
  },
};

const fontSizeStyles = {
  large: '20px',
  regular: '16px',
  small: '16px',
};

const paddingStyles = {
  large: '14px 18px 14px 18px',
  regular: '11px 18px 11px 18px',
  small: '8px 12px 8px 12px',
};

const useStyles = makeStyles(() => ({
  button: {
    padding: ({ size = 'regular' }) => paddingStyles[size],
    cursor: 'pointer',
    display: 'inline-block',
    verticalAlign: 'middle',
    boxShadow: 'none',
    border: 'none',
    textShadow: 'none',
    borderRadius: '4px',
    borderStyle: 'solid',
    borderWidth: '1px',
    fontSize: ({ size = 'regular' }) => fontSizeStyles[size],
    fontWeight: '400',
    lineHeight: '20.8px',
    '&:disabled': disabledStyles,
    whiteSpace: 'nowrap',
  },
  primary: {
    background: PALETTE.emerald,
    color: PALETTE.white,
    fontWeight: '600',
    '&:hover': {
      backgroundColor: PALETTE.evergreen,
      color: PALETTE.white,
    },
  },
  primaryOutline: {
    borderColor: PALETTE.emerald,
    background: 'none',
    color: PALETTE.emerald,
    fontWeight: '600',
    '&:hover': {
      backgroundColor: PALETTE.emerald,
      color: PALETTE.white,
    },
    '&:not(:hover) $loadingIcon': {
      backgroundColor: PALETTE.emerald,
    },
  },
  info: {
    backgroundColor: PALETTE.blue,
    borderColor: PALETTE.blue,
    color: PALETTE.white,
    fontWeight: '600',
    '&:hover': {
      backgroundColor: PALETTE.blueDark,
      borderColor: PALETTE.blueDark,
    },
  },
  infoOutline: {
    background: 'none',
    borderColor: PALETTE.blue,
    color: PALETTE.blue,
    fontWeight: '600',
    '&:hover': {
      backgroundColor: PALETTE.blue,
      color: PALETTE.white,
    },
    '&:not(:hover) $loadingIcon': {
      backgroundColor: PALETTE.blue,
    },
  },
  error: {
    backgroundColor: PALETTE.red,
    borderColor: PALETTE.red,
    color: PALETTE.white,
    fontWeight: '600',
    '&:hover': {
      backgroundColor: PALETTE.redDark,
      borderColor: PALETTE.redDark,
    },
  },
  errorOutline: {
    background: 'none',
    borderColor: PALETTE.red,
    color: PALETTE.red,
    fontWeight: '600',
    '&:hover': {
      backgroundColor: PALETTE.red,
      color: PALETTE.white,
    },
    '&:not(:hover) $loadingIcon': {
      backgroundColor: PALETTE.red,
    },
  },
  warning: {
    backgroundColor: PALETTE.orange,
    borderColor: PALETTE.orange,
    color: PALETTE.white,
    fontWeight: '600',
    '&:hover': {
      backgroundColor: PALETTE.orangeDark,
      borderColor: PALETTE.orangeDark,
    },
  },
  warningOutline: {
    background: 'none',
    borderColor: PALETTE.orange,
    color: PALETTE.orange,
    fontWeight: '600',
    '&:hover': {
      backgroundColor: PALETTE.orange,
      color: PALETTE.white,
    },
    '&:not(:hover) $loadingIcon': {
      backgroundColor: PALETTE.orange,
    },
  },
  neutral: {
    backgroundColor: PALETTE.grey6,
    borderColor: PALETTE.grey4,
    color: PALETTE.black,
    '&:hover': {
      backgroundColor: PALETTE.grey5,
      borderColor: PALETTE.grey5,
    },
  },
  neutralOutline: {
    backgroundColor: PALETTE.white,
    borderColor: PALETTE.grey4,
    color: PALETTE.black,
    '&:hover': {
      backgroundColor: PALETTE.grey6,
    },
    '&:not(:hover) $loadingIcon': {
      backgroundColor: PALETTE.grey6,
    },
  },
  none: {},
  '@keyframes loadingShimmer': {
    '0%': {
      opacity: 1,
    },
    '40%': {
      opacity: 0.2,
    },
    '60%': {
      opacity: 0.2,
    },
    '100%': {
      opacity: 1,
    },
  },
  loadingIcon: {
    //Use the theme transition when all components have been replaced
    //animation: `$loadingShimmer 900ms ${theme.transitions.easing.easeInOut}`,
    animation: `$loadingShimmer 900ms cubic-bezier(0.4, 0, 0.2, 1)`,
    animationIterationCount: 'infinite',
    borderRadius: '50%',
    backgroundColor: PALETTE.grey,
    display: 'inline-block',
    height: '10px',
    marginLeft: '6px',
    marginRight: '6px',
    verticalAlign: 'bottom',
    width: '10px',
    marginTop: '4px',
    marginBottom: '4px',
    '&:nth-child(2)': {
      animationDelay: '300ms',
    },
    '&:nth-child(3)': {
      animationDelay: '600ms',
    },
  },
}));

function TrueLinkButton(props, ref) {
  const { variant, className, type, children, size, loading, ...passThroughProps } = props;
  const classes = useStyles({ size });

  return (
    <button
      {...passThroughProps}
      className={classNames(classes[props.variant], props.className, {
        [classes.button]: props.variant !== 'none',
      })}
      ref={ref}
      type={
        // This is locked via prop-types, but static analysis cannot verify that.
        // eslint-disable-next-line react/button-has-type
        type || 'button'
      }
    >
      {!loading && children}
      {loading && (
        <div aria-label="button loading">
          <span className={classes.loadingIcon} />
          <span className={classes.loadingIcon} />
          <span className={classes.loadingIcon} />
        </div>
      )}
    </button>
  );
}

export const buttonSizes = ['large', 'regular', 'small'];
export const buttonVariants = [
  'error',
  'errorOutline',
  'info',
  'infoOutline',
  'none',
  'neutral',
  'neutralOutline',
  'primary',
  'primaryOutline',
  'warning',
  'warningOutline',
];

export default React.forwardRef(TrueLinkButton);

TrueLinkButton.propTypes = {
  children: childrenShape.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  form: PropTypes.string,
  reference: PropTypes.shape({ current: PropTypes.instanceOf(window.Element) }),
  id: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(buttonSizes),
  style: PropTypes.objectOf(PropTypes.string),
  type: PropTypes.oneOf(['button', 'submit']),
  title: PropTypes.string,
  variant: PropTypes.oneOf(buttonVariants).isRequired,
};
