import 'core-js/stable';
import 'regenerator-runtime/runtime';
import ReactRailsUJS from 'react_ujs';

const componentRequireContext = require.context('../', true, /^\.\/react\/(member|shared)/);

require('global_exports.js');

/* eslint-disable-next-line react-hooks/rules-of-hooks */
ReactRailsUJS.useContext(componentRequireContext);

require('i18n-translations.js.erb');
