import React from 'react';
import childrenShape from 'react/shared/shapes/children';

export default function WmDashboardNav(props) {
  return (
    <div className="grey_section_header header">
      <div className="grey_section_header--inner">{props.children}</div>
    </div>
  );
}

WmDashboardNav.propTypes = {
  children: childrenShape.isRequired,
};
