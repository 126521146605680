import PropTypes from 'prop-types';
import React from 'react';
import PopUp from 'react/shared/components/popups/PopUp';
import bindAll from 'react/shared/utils/bind_all';

export default class DashboardSelectOrganizationPopUp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    bindAll(this);
  }

  renderOrganizationRadioButtons() {
    return this.props.allOrgs.map((org) => {
      const checked = this.props.selectedOrganizationSlug === org.slug;
      return (
        <div key={org.slug}>
          <input
            checked={checked}
            name="organization_id"
            onChange={this.props.onSelect}
            type="radio"
            value={org.slug}
          />{' '}
          &nbsp;
          {org.name}
        </div>
      );
    });
  }

  modalFooter() {
    return (
      <div>
        <button className="normal btn btn-success" onClick={this.props.onSubmit} type="button">
          Next
        </button>
      </div>
    );
  }

  render() {
    const { closeModal } = this.props;

    return (
      <div>
        <PopUp
          footer={this.modalFooter()}
          header="Select an organization"
          modalOpen
          onClose={closeModal}
        >
          Select one organization to add multiple deposits
          <div style={{ marginTop: '10px', marginBottom: '10px' }}>
            <form>{this.renderOrganizationRadioButtons()}</form>
          </div>
        </PopUp>
      </div>
    );
  }
}

DashboardSelectOrganizationPopUp.propTypes = {
  onSelect: PropTypes.func.isRequired,
  selectedOrganizationSlug: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  allOrgs: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
};
