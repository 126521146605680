import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import IdvError from './IdvError';
import IdvForm from './IdvForm';
import IdvSuccess from './IdvSuccess';
import TrueLinkModal from 'react/shared/components/true_link/lab/TrueLinkModal';
import theme from 'react/shared/theme/Theme';

const useStyles = makeStyles(() => ({
  ivdModal: {
    '& .modal-body': {
      padding: '50px 30px 30px',
    },
    '& .modal-header': {
      display: 'none',
    },
    '& .MuiPaper-root': {
      width: '552px',
      [theme.breakpoints.down('sm')]: {
        margin: '0',
      },
    },
  },
}));

export default function IdvModal(props) {
  const classes = useStyles();

  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(props.idvFailed);
  const [retry, setRetry] = useState(0);

  useEffect(() => {
    if (retry > 1) {
      setShowError(true);
    }
  }, [retry]);

  const { mutate: verifyUserIdentity, isLoading } = useMutation({
    mutationFn: (values) =>
      axios
        .patch(RailsRoutes.api_v2_person_path(props.personId, { format: 'json' }), {
          data: {
            type: 'person',
            attributes: {
              ssn: values.ssn,
              dob: values.dob,
            },
          },
        })
        .then(() =>
          axios.post(RailsRoutes.verify_api_v2_person_path(props.personId, { format: 'json' })),
        ),
    onSuccess: () => {
      setShowSuccess(true);
      props.onSuccess();
    },
    onError: () => setRetry((prev) => prev + 1),
  });

  return (
    <TrueLinkModal modalProps={{ className: classes.ivdModal, maxWidth: 'md' }} title="">
      <IconButton
        aria-label="close"
        onClick={props.onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      {!showSuccess && !showError && (
        <IdvForm
          firstName={props.firstName}
          isLoading={isLoading}
          lastName={props.lastName}
          onSubmit={verifyUserIdentity}
          showRetryError={retry === 1}
        />
      )}
      {showError && (
        <IdvError firstName={props.firstName} onClose={props.onClose} setShowError={setShowError} />
      )}
      {showSuccess && <IdvSuccess onClose={props.onClose} />}
    </TrueLinkModal>
  );
}

IdvModal.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  idvFailed: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  personId: PropTypes.number,
};
