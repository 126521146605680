import PropTypes from 'prop-types';
import React from 'react';
import TrashCan from 'images/trash-can.png';
import DashboardActions from 'react/member/actions/dashboard_actions';

export default function DashboardTransactionAttachment({ wealthAccountEventId, attachment }) {
  const onDestroy = (_evt) => {
    DashboardActions.destroyWealthAccountEventAttachment(wealthAccountEventId, attachment.id);
  };

  return (
    <div key={attachment.id}>
      <a href="#" onClick={onDestroy}>
        <img alt="Delete" height={25} src={TrashCan} width={25} />
      </a>
      &nbsp;
      <a href={RailsRoutes.api_v2_attachment_path(attachment.id)}>{attachment.name}</a>
      <input name="attachment_ids[]" readOnly style={{ display: 'none ' }} value={attachment.id} />
    </div>
  );
}

DashboardTransactionAttachment.propTypes = {
  wealthAccountEventId: PropTypes.number.isRequired,
  attachment: PropTypes.object.isRequired,
};
