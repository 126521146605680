import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';
import Field from './Field';
import FieldMixin from './FieldMixin';

const NumberField = createReactClass({
  propTypes: {
    controller: PropTypes.object,
    format: PropTypes.func,
    max: PropTypes.number,
    min: PropTypes.number,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool,
    step: PropTypes.number,
    value: PropTypes.number,
  },

  mixins: [FieldMixin],

  componentDidMount() {
    this.initField();
  },

  renderShow() {
    return this.formattedValue();
  },

  renderEdit() {
    return (
      <input
        max={this.props.max}
        min={this.props.min}
        name={this.inputName()}
        onChange={this.onChange}
        onKeyUp={this.keyup}
        ref={this.inputDidMount}
        step={this.props.step || 0.01}
        style={{ margin: 0, padding: 0, paddingLeft: 9 }}
        type="number"
        value={this.value() || ''}
      />
    );
  },

  render() {
    return (
      <Field
        {...this.props}
        editable={this.editable}
        label={this.label}
        onKeyUp={this.onKeyUp}
        renderEdit={this.renderEdit}
        renderShow={this.renderShow}
      />
    );
  },
});

export default NumberField;
