import PropTypes from 'prop-types';
import React from 'react';
import DashboardTrustBeneficiaryProfileActivityNotesForm from './DashboardTrustBeneficiaryProfileActivityNotesForm';
import DashboardTrustBeneficiaryProfileActivityNotesList from './DashboardTrustBeneficiaryProfileActivityNotesList';
import DashboardActions from 'react/member/actions/dashboard_actions';
import DashboardNoteStore from 'react/member/stores/beneficiary_dashboard/DashboardNoteStore';
import ToggleSectionHeader from 'react/shared/components/ToggleSectionHeader';
import bindAll from 'react/shared/utils/bind_all';

export default class DashboardTrustBeneficiaryProfileAccountActivityNotes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      notes: [],
      currentNoteContent: '',
    };

    bindAll(this);
  }

  componentDidMount() {
    DashboardNoteStore.on('dashboardNotes.fetch', this.onChangeDashboardNotes);
    DashboardNoteStore.on('dashboardNote.add', this.onChangeDashboardNotes);
    DashboardNoteStore.on('dashboardNote.update', this.onChangeDashboardNotes);
    DashboardNoteStore.on('dashboardNote.destroy', this.onChangeDashboardNotes);

    const { trustBeneficiary } = this.props;
    DashboardActions.fetchDashboardNotes(trustBeneficiary.slug);
  }

  componentWillUnmount() {
    DashboardNoteStore.off('dashboardNotes.fetch', this.onChangeDashboardNotes);
    DashboardNoteStore.off('dashboardNote.add', this.onChangeDashboardNotes);
    DashboardNoteStore.off('dashboardNote.update', this.onChangeDashboardNotes);
    DashboardNoteStore.off('dashboardNote.destroy', this.onChangeDashboardNotes);
  }

  onChangeDashboardNotes(notes) {
    return this.setState({ notes });
  }

  renderContent() {
    const { notes } = this.state;
    const { expanded, trustBeneficiary } = this.props;
    if (!expanded) {
      return null;
    }

    return (
      <div className="toggle-section-content">
        <div className="subsection-header">
          Use this space to log time stamped account activity.
        </div>
        <DashboardTrustBeneficiaryProfileActivityNotesForm trustBeneficiary={trustBeneficiary} />
        <DashboardTrustBeneficiaryProfileActivityNotesList
          notes={notes}
          trustBeneficiary={trustBeneficiary}
        />
      </div>
    );
  }

  render() {
    const { trustBeneficiary, expanded, toggleExpanded } = this.props;
    return (
      <div
        className="toggle-section"
        id={`bene-profile-account-activity-note-section-${trustBeneficiary.slug}`}
      >
        <ToggleSectionHeader title="Account Activity Notes" {...{ expanded, toggleExpanded }} />
        {this.renderContent()}
      </div>
    );
  }
}
DashboardTrustBeneficiaryProfileAccountActivityNotes.propTypes = {
  expanded: PropTypes.bool.isRequired,
  toggleExpanded: PropTypes.func.isRequired,
  trustBeneficiary: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    trust: PropTypes.shape({
      organization: PropTypes.shape({}),
    }),
  }).isRequired,
};
