export function getDashboardAddress(administrator, organization) {
  if (organization && organization.address && organization.address.street1) {
    return organization.address;
  }
  if (
    organization &&
    organization.parentOrganizationAddress &&
    organization.parentOrganizationAddress.street1
  ) {
    return organization.parentOrganizationAddress;
  }
  if (administrator && administrator.address && administrator.address.street1) {
    return administrator.address;
  }
  return null;
}
