import I18n from 'i18n-js';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import InfoTooltip from 'react/shared/components/tooltips/InfoTooltip';
import bindAll from 'react/shared/utils/bind_all';

export default class I18nInfoTooltip extends React.Component {
  static get defaultProps() {
    return {
      tooltipKey: '',
      placement: 'right',
      iconClass: 'info-circle',
    };
  }

  constructor(props) {
    super(props);
    bindAll(this);
  }

  componentDidMount() {
    const element = $(ReactDOM.findDOMNode(this._infoRef));

    $(document).on('click', '.tooltip-close', () => {
      element.tooltip('hide');
    });
  }

  tooltipText() {
    const { tooltipKey } = this.props;
    const tooltipText = I18n.t(`tooltips.${tooltipKey}`, { defaultValue: '' });

    if (tooltipText == '') return '';

    return `
      ${tooltipText}
      <span class='tooltip-close'>×</span>
      <div align="right" class="tooltip-key">
        key: ${tooltipKey}
      </div>
    `;
  }

  setRef(instance) {
    this._infoRef = instance;
  }

  render() {
    const { placement, iconClass } = this.props;

    return (
      <InfoTooltip
        delay={'{"hide":"5000"}'}
        html
        iconClass={iconClass}
        placement={placement}
        setRef={this.setRef}
        tooltipStyle="width: 480px"
        tooltipText={this.tooltipText()}
      />
    );
  }
}

I18nInfoTooltip.propTypes = {
  tooltipKey: PropTypes.string.isRequired,
  placement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
  iconClass: PropTypes.string,
};
