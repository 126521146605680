import PropTypes from 'prop-types';
import React from 'react';

export default function StarCell({ value }) {
  return <i className={value ? 'fas fa-star' : 'far fa-star'} style={{ color: '#307fed' }} />;
}

StarCell.propTypes = {
  value: PropTypes.bool.isRequired,
};
