import PropTypes from 'prop-types';
import React from 'react';
import NavigationButtons from './navigation_buttons';
import { States } from './states';
import bindAll from 'react/shared/utils/bind_all';

export default class Trustee extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedOption: this.props.trustee,
      error: undefined,
    };

    bindAll(this);
  }

  transitionTo(ev) {
    ev.preventDefault();
    if (!this.state.selectedOption) {
      this.props.ensureOption(this, 'Please select an option.');
    } else {
      this.props.transition(States.CONSUMER.FINAL, { trustee: this.state.selectedOption });
    }
  }

  handleOptionChange(ev) {
    this.setState({ selectedOption: ev.target.value, error: undefined });
  }

  transitionBackTo(ev) {
    ev.preventDefault();
    this.props.transition(States.CONSUMER.FIRST);
  }

  render() {
    const { selectedOption, error } = this.state;

    return (
      <div>
        <div className="questionnaire-form-item questionnaire-form-item__medium">
          <form onSubmit={this.transitionTo}>
            <div className="questionnaire-form-item-content">
              <div className="questionnaire-form-item-question">
                <h2>Are you acting as a trustee for the person for whom you're ordering a card?</h2>
              </div>
              <div className="hide-on-mobile">{error && <p className="form-error">{error}</p>}</div>
              <div className="questionnaire-radio-wrapper" style={{ marginTop: 11 }}>
                <div className="questionnaire-radio questionnaire-radio--large">
                  <input
                    checked={selectedOption == 'YES'}
                    className="questionnaire-radio__input"
                    id="yes-trustee-radio"
                    onChange={this.handleOptionChange}
                    type="radio"
                    value="YES"
                  />
                  <label className="questionnaire-radio__label" htmlFor="yes-trustee-radio">
                    <span className="questionnaire-radio__checkbox-container" />
                    <span className="questionnaire-radio__text">Yes</span>
                  </label>
                </div>
                <div className="questionnaire-radio questionnaire-radio--large">
                  <input
                    checked={selectedOption == 'NO'}
                    className="questionnaire-radio__input"
                    id="no-trustee-radio"
                    onChange={this.handleOptionChange}
                    type="radio"
                    value="NO"
                  />
                  <label className="questionnaire-radio__label" htmlFor="no-trustee-radio">
                    <span className="questionnaire-radio__checkbox-container" />
                    <span className="questionnaire-radio__text">No</span>
                  </label>
                </div>
              </div>
              <div className="questionnaire-info-section">
                You can use the True Link Card whether or not you are a trust administrator. If
                you're not sure, the answer is probably no! <br />
                <br />
                We ask this question in order to provide you the information most relevant to your
                circumstances; you can always update this information later.
              </div>
            </div>
            <div className="show-on-mobile">{error && <p className="form-error">{error}</p>}</div>
            <NavigationButtons transitionBackTo={this.transitionBackTo} />
          </form>
        </div>
      </div>
    );
  }
}

Trustee.propTypes = {
  ensureOption: PropTypes.func.isRequired,
  transition: PropTypes.func.isRequired,
  trustee: PropTypes.string,
};
