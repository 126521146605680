import PropTypes from 'prop-types';
import React, { useState } from 'react';
import CategorizedBudgetCategoryModal from './CategorizedBudgetCategoryModal';
import CategorizedBudgetTimelineModal from './CategorizedBudgetTimelineModal';

export default function CategorizedBudgetOnboarding({
  trustBeneficiary,
  timelineComplete,
  monthlyComplete,
  timelineId,
  annualComplete,
}) {
  const [budgetModalOpen, setBudgetModalOpen] = useState(false);
  const [timelineModalOpen, setTimelineModalOpen] = useState(false);
  const [isModalMonthly, setIsModalMonthly] = useState(false);

  const toggleBudgetModal = (e, isMonthly) => {
    e && e.preventDefault();

    if (!timelineComplete) {
      window.Truelink.flash('error', 'Please create a budget timeline before adding categories.');
    } else {
      setBudgetModalOpen(!budgetModalOpen);
      setIsModalMonthly(isMonthly);
    }
  };

  const closeBudgetModal = () => {
    setBudgetModalOpen(false);
  };

  const toggleTimelineModal = (e) => {
    e && e.preventDefault();
    setTimelineModalOpen(!timelineModalOpen);
  };

  const closeTimelineModal = () => {
    setTimelineModalOpen(false);
  };

  const renderTimelineStep = () => {
    const timelineClass = timelineComplete
      ? 'budget__onboarding-step--complete'
      : 'budget__onboarding-step';
    if (timelineComplete) {
      return (
        <div className={timelineClass}>
          1. Set your budget timeline to tell us when the budget starts and ends
        </div>
      );
    }
    return (
      <div className={timelineClass}>
        {'1. '}
        <a href="#" onClick={(e) => toggleTimelineModal(e)}>
          Set your budget timeline
        </a>
        {' to tell us when the budget starts and ends'}
      </div>
    );
  };

  if (timelineComplete && (monthlyComplete || annualComplete)) {
    return null;
  }

  const categoriesClass =
    monthlyComplete || annualComplete
      ? 'budget__onboarding-step--complete'
      : 'budget__onboarding-step';

  return (
    <div className="budget__onboarding">
      <div className="budget__onboarding--container">
        <div className="budget__onboarding--header">Getting started with budget tracking</div>
        {renderTimelineStep()}
        <div className={categoriesClass}>
          {'2. '}
          <a href="#" onClick={(e) => toggleBudgetModal(e, true)}>
            Add monthly
          </a>
          {' and '}
          <a href="#" onClick={(e) => toggleBudgetModal(e, false)}>
            annual budget categories
          </a>
          {' to track budgets'}
        </div>
        {budgetModalOpen && (
          <CategorizedBudgetCategoryModal
            isMonthly={isModalMonthly}
            onClose={closeBudgetModal}
            timelineId={timelineId}
            trustBeneficiary={trustBeneficiary}
          />
        )}
        {timelineModalOpen && (
          <CategorizedBudgetTimelineModal
            onClose={closeTimelineModal}
            trustBeneficiary={trustBeneficiary}
          />
        )}
      </div>
    </div>
  );
}

CategorizedBudgetOnboarding.propTypes = {
  timelineComplete: PropTypes.bool,
  monthlyComplete: PropTypes.bool,
  annualComplete: PropTypes.bool,
  timelineId: PropTypes.number,
  trustBeneficiary: PropTypes.object,
};
