import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import api from 'react/member/card/components/funding_tab/api';
import TrueLinkLink from 'react/shared/components/true_link/main/TrueLinkLink';
import theme, { smallBreakpointMediaQuery } from 'react/shared/theme/Theme';
import { swallowAxiosCancelation } from 'react/shared/utils/Axios';

const useStyles = makeStyles(() => ({
  transferAgreementContainer: {
    position: 'relative',
  },
  readMoreBtn: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'inline',
    },
  },
}));

export default function AutoTransferAgreement() {
  const classes = useStyles();
  const desktop = useMediaQuery(smallBreakpointMediaQuery);

  const [agreement, setAgreement] = useState('');
  const cancelTokenSource = axios.CancelToken.source(
    'Canceling auto transfer agreement api request',
  );
  const [expanded, setExpanded] = useState(true);

  useEffect(() => {
    api
      .getAutoTransferAgreement(cancelTokenSource.token)
      .then((response) => setAgreement(response))
      .catch(swallowAxiosCancelation);

    return () => {
      cancelTokenSource;
    };
  }, [cancelTokenSource]);

  useEffect(() => {
    setExpanded(desktop);
  }, [desktop]);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div className={classes.recurringTransferAgreementContainer}>
      <span
        className="recurring-transfer-agreement-text"
        // Disabling since this is served from an internal API
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: expanded ? agreement : `${agreement.slice(0, 192)} ...`,
        }}
      />

      <TrueLinkLink
        className={classes.readMoreBtn}
        id="recurring-transfer-agreement-toggle-text-cta"
        onClick={toggleExpand}
        type="button"
      >
        {expanded ? 'Read less' : 'Read more'}
      </TrueLinkLink>
    </div>
  );
}
