import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import GoldStarsSvg from 'images/referrals/gold-stars-5.svg';
import GreenStarsSvg from 'images/referrals/green-stars-5.svg';
import TrustpilotLogoSvg from 'images/referrals/trustpilot-logo.svg';
import theme from 'react/shared/theme/Theme';
import PALETTE from 'react/shared/theme/palette';

const useStyles = makeStyles(() => ({
  section: {
    backgroundColor: PALETTE.paleMint,
    borderTop: '1px solid rgba(0,0,0,.1)',
    padding: '5rem',
    [theme.breakpoints.down('md')]: {
      padding: '5rem 3rem',
    },
  },
  container: {
    margin: '0 auto',
    maxWidth: '70rem',
  },
  header: {
    textAlign: 'center',
    marginBottom: '0.5rem',
    fontFamily: 'Tiempos headline,Times New Roman,sans-serif',
    fontSize: '2.5rem',
    fontWeight: '300',
  },
  banner: {
    textAlign: 'center',
    marginBottom: '1.2rem',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  bannerLink: {
    textDecoration: 'none !important',
  },
  bannerLinkContent: {
    color: PALETTE.black,
    display: 'flex',
  },
  imgGreenStars: {
    width: 'auto',
    height: '25px',
    padding: '0 10px',
  },
  imgLogo: {
    width: 'auto',
    height: '25px',
  },
  items: {
    marginTop: '40px',
    alignItems: 'start',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  item: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  textFooter: {
    textAlign: 'center',
    marginTop: '40px',
  },
  imgGoldStars: {
    width: '94px',
    height: '30px',
    objectFit: 'contain',
    marginBottom: '10px',
  },
}));

const reviewsContent = [
  {
    title: 'True Link has been amazing ',
    content: `The True Link card gives us independence, 
    autonomy, and security for our family member. The site
    allows for control and versatility and provides excellent
    record keeping for transactions. We are very pleased with
    the service and the staff.`,
    reviewer: '– Rosemary, Family Caregiver',
  },
  {
    title: 'I love the convenience of True Link',
    content: `The Visa card that is accepted everywhere,
     allows me to transfer funds, set limitations of where
     she can use the card, the type of transactions allowed,
     as well as being able to set transaction limits, and set
     up notifications, so I can keep track of her spending.
     The card allows my sister to have financial autonomy,
     without giving her too much freedom to get into 
     financial trouble. In my opinion, the monthly fee is 
     worth the convenience.
`,
    reviewer: '– Dawn, Representative Payee',
  },
  {
    title: 'The easiest financial service to use',
    content: `Discovering True Link has been a powerful
     tool as a co-trustee for a Special Needs beneficiary.
     I appreciate the ability to specify that this card 
     can only be used by the beneficiary for food/groceries
     only. Following the purchases online helps me oversee
     his spending without controlling it.`,
    reviewer: '– Co-Trustee of a Special Needs Trust',
  },
];
const footer = `All quotes refer to the True Link Visa Prepaid Card and the
 associated True Link Platform provided by True Link Financial, 
 Inc. and not the investment advisory services provided by 
 True Link Financial Advisors, LLC.`;

export default function ReviewsCard(props) {
  const classes = useStyles();
  return (
    <div className={classes.section} data-testid="reviewsCard">
      <div className={classes.container}>
        <div className={classes.banner}>
          {/* -- Trustpilot-Widget --  */}
          {/* -- The code below is a 3rd party code --  */}
          {/* -- Updating this code may affect the Trustpilot Widget --  */}
          <div
            className="trustpilot-widget"
            data-businessunit-id="61140503b8fbec001d303ee8"
            data-locale="en-US"
            data-style-height="28px"
            data-style-width="100%"
            data-tags="SelectedReview"
            data-template-id="5406e65db0d04a09e042d5fc"
            data-theme="light"
          >
            <a
              className={classes.bannerLink}
              href="https://www.trustpilot.com/review/truelinkfinancial.com"
              rel="noreferrer"
              target="_blank"
            >
              <div className={classes.bannerLinkContent}>
                <Typography component="span" variant="h5">
                  <b>Excellent</b>
                </Typography>
                <img alt="5 green stars" className={classes.imgGreenStars} src={GreenStarsSvg} />
                <img alt="trustpilot logo" className={classes.imgLogo} src={TrustpilotLogoSvg} />
              </div>
            </a>
          </div>
        </div>
        <Typography className={classes.header} variant={props.desktop ? 'h2' : 'h3'}>
          Why people love True Link
        </Typography>
        <Grid alignItems="center" className={classes.items} container item spacing={8}>
          {reviewsContent.map((review, index) => (
            <Grid className={classes.item} item key={index} md={4} sm={12} xs={12}>
              <Card>
                <CardContent>
                  <img alt="5 gold stars" className={classes.imgGoldStars} src={GoldStarsSvg} />
                  <Typography component="div" gutterBottom variant="h5">
                    {review.title}
                  </Typography>
                  <Typography className={classes.text} gutterBottom variant="body2">
                    {review.content}
                  </Typography>
                  <Typography color="textSecondary" variant="body2">
                    {review.reviewer}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Typography
          className={classes.textFooter}
          color="textSecondary"
          component="div"
          variant="caption"
        >
          {footer}
        </Typography>
      </div>
    </div>
  );
}

ReviewsCard.propTypes = {
  desktop: PropTypes.bool,
};
