import PropTypes from 'prop-types';
import React from 'react';
import _keys from 'underscore/modules/keys';
import _map from 'underscore/modules/map';
import SelectField from 'react/shared/components/forms/SelectField';
import StringField from 'react/shared/components/forms/StringField';
import bindAll from 'react/shared/utils/bind_all';

export default class AddressEmbed extends React.Component {
  constructor(props) {
    super(props);

    bindAll(this);
  }

  states(target) {
    const abbreviate = this.props.abbreviate;
    const options = _map(_keys($tlf.domains.states), (abbreviation) => ({
      id: abbreviation,
      name: abbreviate ? abbreviation : $tlf.domains.states[abbreviation],
    }));

    target.setState({ options });
  }

  formatState(abbreviation) {
    return $tlf.domains.states[abbreviation];
  }

  render() {
    const scope = this.props.scope;
    const prefix = scope ? `${scope}.` : '';
    return (
      <div>
        <StringField
          controller={this.props.controller}
          label="Address Line 1"
          name={`${prefix}street1`}
          optional={this.props.optional}
        />
        <StringField
          controller={this.props.controller}
          label="Address Line 2"
          name={`${prefix}street2`}
          optional
        />
        <StringField
          controller={this.props.controller}
          label="City"
          name={`${prefix}city`}
          optional={this.props.optional}
        />
        <SelectField
          allowBlank
          controller={this.props.controller}
          format={this.formatState}
          label="State"
          name={`${prefix}state`}
          optional={this.props.optional}
          options={this.states}
          style={{ width: 70 }}
        />
        <StringField
          controller={this.props.controller}
          label="ZIP Code"
          name={`${prefix}zip`}
          optional={this.props.optional}
          style={{ width: 70 }}
        />
      </div>
    );
  }
}

AddressEmbed.propTypes = {
  controller: PropTypes.object.isRequired,
  scope: PropTypes.string,
  abbreviate: PropTypes.bool,
  optional: PropTypes.bool,
};
