const Actions = flux.createActions([
  // Portfolios
  'fetchPortfolio',
  'updatePortfolio',

  // PortfolioCapitalChanges
  'fetchPortfolioCapitalChanges',
  'previewBookCapitalChangesEvent',
  'createBookCapitalChangesEvent',

  // HoldingCapitalChanges
  'fetchHoldingCapitalChanges',

  // Daily Portfolio Closeouts
  'fetchPortfolioCloseouts',
  'fetchLatestPortfolioCloseout',
  'updateCloseout',
  'fetchCloseout',

  // Credits and Debits
  'fetchPortfolioDebits',

  // Positions
  'fetchPositions',
  'fetchWealthAccountPositions',
  'fetchPortfolioPositions',
  'fetchTrustPositions',

  // TrustBeneficiaries
  'fetchTrustBeneficiariesByPortfolio',

  // Holdings
  'fetchHoldings',
  'fetchPortfolioHoldings',

  // Holding Events
  'fetchHoldingEvent',
  'fetchTrustHoldingEvents',
  'fetchPortfolioHoldingEvents',
  'fetchCloseoutHoldingEvents',
  'updateHoldingEvent',
  'destroyHoldingEvent',

  // In-Product Ads
  'setInProductAds',
  'setInProductAdTargetCustomerTypes',
  'setInProductAdTargetPositions',
  'createInProductAd',
  'updateInProductAd',
  'deleteInProductAd',
  'insertInProductAdIntoStore',
  'removeInProductAdFromStore',

  // Wealth Account Events
  'fetchPortfolioWealthAccountEvents',

  // FeeSchedules
  'fetchFeeSchedules',
  'fetchDetailedFeeSchedule',
  'fetchImplsForFeeSchedule',
  'updateNextChargeDatesForFeeSchedule',
  'downloadCSVsForFeeSchedule',
  'approveAllForFeeSchedule',
  'unapproveAllForFeeSchedule',
  'waiveAllForFeeSchedule',
  'unwaiveAllForFeeSchedule',
  'activateFeeSchedule',
  'deactivateFeeSchedule',
  'chargeAllFeesForFeeSchedule',
  'fetchEligiblePayersForFeeSchedule',
  'attachPayerToFeeSchedule',

  // FeeScheduleImpls
  'fetchDetailedFeeScheduleImpl',
  'updateNextChargeDateForFeeScheduleImpl',
  'calculateFeeForFeeScheduleImpl',
  'approveFeeScheduleImpl',
  'unapproveFeeScheduleImpl',
  'waiveFeeScheduleImpl',
  'unwaiveFeeScheduleImpl',
  'activateFeeScheduleImpl',
  'deactivateFeeScheduleImpl',
  'fetchFeesForFeeScheduleImpl',

  // People
  'fetchPerson',
  'updatePerson',

  // Trust Attachment
  'fetchTrustAttachments',
  'destroyTrustAttachment',

  // Dispute Cases
  'fetchDisputeCaseAssignees',
  'fetchDisputeCaseStatuses',

  // Calendar
  'fetchBankHolidays',

  // Categorized Budgets
  'fetchCategorizedBudgets',
  'createCategorizedBudgetCategory',
  'deleteCategorizedBudgetCategory',
  'updateCategorizedBudgetCategory',

  // Categorized Budget Timelines
  'createBudgetTimeline',
  'updateBudgetTimeline',

  // DisbursementAchTemplates
  'fetchDisbursementAchTemplates',
  'deleteDisbursementAchTemplate',
  'updateDisbursementAchTemplate',

  // Check Configurations
  'saveCheckConfiguration',
  'deleteCheckConfiguration',

  // Disbursement categories
  'createDisbursementCategory',
  'deleteDisbursementCategories',

  // Connections
  'fetchConnections',
  'fetchRelationshipTypes',
  'createConnection',
  'updateConnection',
  'updateInvitedConnection',
  'connectConnection',
  'removeConnection',
]);

export default Actions;
