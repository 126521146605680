import Typography from '@mui/material/Typography';
import React from 'react';
import TrueLinkLink from './TrueLinkLink';

export default {
  title: 'Standard/Link',
  component: TrueLinkLink,
  argTypes: {
    children: {
      control: 'text',
      table: {
        category: 'Contents',
      },
    },
    className: {
      control: 'text',
      table: {
        category: 'Style',
      },
    },
    href: {
      control: 'text',
      table: {
        category: 'Contents',
      },
    },
    onClick: {
      name: 'On Click Handler',
      description: 'Handler for click events',
      table: {
        category: 'Events',
      },
    },
    target: {
      control: 'text',
      table: {
        category: 'Contents',
      },
    },
  },
};

function Template(args) {
  return (
    <Typography>
      <TrueLinkLink {...args}>{args.children}</TrueLinkLink>
    </Typography>
  );
}

export const AsLink = Template.bind({});
AsLink.args = {
  children: 'Click here',
  href: '#',
  target: '_self',
};

export const AsButton = Template.bind({});
AsButton.args = {
  children: 'Click here',
  href: undefined,
};
export const AsPrimaryButton = Template.bind({});
AsPrimaryButton.args = {
  children: 'Click here',
  href: undefined,
  className: 'primary',
};

export const AsPrimaryOutlineButton = Template.bind({});
AsPrimaryOutlineButton.args = {
  children: 'Click here',
  href: undefined,
  className: 'primaryOutline',
};
