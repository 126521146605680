import PropTypes from 'prop-types';

export const recurringDisbursementShape = PropTypes.shape({
  additional_approver: PropTypes.object,
  approver: PropTypes.object,
  beneficiary: PropTypes.shape({
    organization: PropTypes.shape({
      slug: PropTypes.string,
    }).isRequired,
    three_month_info: PropTypes.object,
  }).isRequired,
  can_be_canceled: PropTypes.bool,
  can_be_updated: PropTypes.bool,
  check_memo_line_2: PropTypes.string,
  denial_reason: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.number.isRequired,
  payee_disposition: PropTypes.object,
  payment_method: PropTypes.string,
  proposer: PropTypes.object,
  requester: PropTypes.object,
  senior_approver: PropTypes.object,
  start_date: PropTypes.string,
  status: PropTypes.string,
});
