import axios from 'axios';
import PropTypes from 'prop-types';
import React from 'react';
import UniqueAssetsForm from './UniqueAssetsForm';
import UniqueAssetsRow from './UniqueAssetsRow';
import UniqueAssetsView from './UniqueAssetsView';
import DashboardActions from 'react/member/actions/dashboard_actions';
import userRoleShape from 'react/member/shapes/UserRoleShape';
import DashboardUniqueAssetStore from 'react/member/stores/DashboardUniqueAssetStore';
import TlAreYouSureModal from 'react/shared/components/TlAreYouSureModal';
import TrueLinkModal from 'react/shared/components/true_link/lab/TrueLinkModal';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import { asMoney } from 'react/shared/utils/Money';
import bindAll from 'react/shared/utils/bind_all';

export default class UniqueAssetsTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      assets: [],
      openModalAsset: this.getNewAsset(),
      openModal: false,
      openDestroyModal: false,
      openViewModal: false,
    };

    bindAll(this);
  }

  componentDidMount() {
    const isAddAssets = window.sessionStorage.getItem('add-asset');
    if (isAddAssets) {
      this.setState({ openModalAsset: this.getNewAsset(), openModal: true });
      window.sessionStorage.removeItem('add-asset');
    }
    DashboardUniqueAssetStore.on('UniqueAssets.fetch', this.onChangeAssets);

    DashboardActions.fetchUniqueAssets(this.props.clientId || this.props.client.id);
  }

  componentWillUnmount() {
    DashboardUniqueAssetStore.off('UniqueAssets.fetch', this.onChangeAssets);
    window.sessionStorage.removeItem('add-asset');
  }

  getNewAsset() {
    return {
      name: '',
      asset_type: '',
      value: 0,
      purchase_value: 0,
      owned_by_trust: true,
    };
  }

  onChangeAssets(assets) {
    this.setState({ assets });
  }

  showModal(_e) {
    this.setState({
      openModalAsset: this.getNewAsset(),
      openModal: true,
    });
  }

  showModalWithAsset(e, asset) {
    this.setState({
      openModalAsset: asset,
      openModal: true,
    });
  }

  showViewModal(e, asset) {
    this.setState({
      openViewModalAsset: asset,
      openViewModal: true,
    });
  }

  closeViewModal() {
    this.setState({
      openViewModal: false,
    });
  }

  closeModal() {
    this.setState({
      openModalAsset: this.getNewAsset(),
      openModal: false,
    });
  }

  showDestroyModal() {
    this.setState({
      openModal: false,
      openDestroyModal: true,
    });
  }

  closeDestroyModal() {
    this.setState({
      openDestroyModal: false,
    });
  }

  submitSuccessCallback() {
    this.closeModal();
    this.closeDestroyModal();
    return DashboardActions.fetchUniqueAssets(this.props.clientId || this.props.client.id);
  }

  sendDestroyRequest() {
    let path;
    const data = { unique_asset: this.state.openModalAsset };
    if (this.state.openModalAsset.id) {
      path = RailsRoutes.dashboard_client_unique_asset_path(
        this.props.clientId || this.props.client.id,
        this.state.openModalAsset.id,
        { format: 'json' },
      );
      this.ajaxCall(
        path,
        data,
        'Asset was successfully deleted.',
        this.submitSuccessCallback,
        'DELETE',
      );
    }
  }

  ajaxCall(url, data, successMessage, callback, method) {
    Truelink.flash('info', 'Sending...', false);
    axios
      .request({
        url,
        method,
        data,
        dataType: 'json',
      })
      .then(() => {
        Truelink.flashClear();
        if (successMessage) {
          Truelink.flash('success', successMessage, false);
        }
        return callback && callback();
      });
  }

  addUniqueAssetButton() {
    if (this.props.userRoles.viewOnly || this.props.userRoles.restrictedView) {
      return null;
    }

    return (
      <a
        className="btn btn-link"
        onClick={this.showModal}
        onKeyUp={this.showModal}
        role="link"
        tabIndex="0"
      >
        + Add Unique Asset
      </a>
    );
  }

  renderTotal() {
    const total = this.state.assets.reduce(
      (total, asset) => total + Number(asset.current_market_value),
      0,
    );
    return (
      <tfoot className="bold">
        <tr>
          <td colSpan="2">Total Unique Assets </td>
          <td />
          <td />
          <td />
          <td>{asMoney(total)}</td>
          <td />
          <td />
        </tr>
      </tfoot>
    );
  }

  render() {
    const isEditing = this.state.openModalAsset && this.state.openModalAsset.id;

    return (
      <div id="unique-assets-table">
        <div className="sub_section_header first" style={{ marginBottom: 0 }}>
          Unique Assets
          <div className="sub_section_header__right">
            {this.addUniqueAssetButton()}
            {this.state.openModal && (
              <TrueLinkModal
                handleClose={this.closeModal}
                title={isEditing ? 'Edit Asset' : 'Add Asset'}
              >
                <div className="tl-form-edit">
                  <div className="flex">
                    <UniqueAssetsForm
                      client={this.props.client}
                      clientId={this.props.clientId}
                      openModalAsset={this.state.openModalAsset}
                      showAdvanceOption={this.props.showAdvanceOption}
                      success={this.submitSuccessCallback}
                    />
                  </div>

                  <div className="tl-form-actions-row">
                    {isEditing && (
                      <button
                        className="btn btn-link delete"
                        onClick={this.showDestroyModal}
                        style={{ marginTop: 'auto' }}
                        type="button"
                      >
                        Delete
                      </button>
                    )}
                    <TrueLinkButton
                      className="btn btn-success"
                      form="issue_check_form"
                      type="submit"
                      variant="none"
                    >
                      {isEditing ? 'Update Asset' : 'Add Asset'}
                    </TrueLinkButton>
                  </div>
                </div>
              </TrueLinkModal>
            )}
            {this.state.openViewModal && (
              <TrueLinkModal handleClose={this.closeViewModal} title="View asset">
                <UniqueAssetsView openViewModalAsset={this.state.openViewModalAsset} />
              </TrueLinkModal>
            )}
            {this.state.openDestroyModal && (
              <TlAreYouSureModal
                buttonClass="btn-danger"
                handleClose={this.closeDestroyModal}
                headerText="Delete asset"
                messageText="Are you sure you want to delete this Asset?"
                onSubmit={this.sendDestroyRequest}
                submitText="Yes, delete"
              />
            )}
          </div>
        </div>
        <table className="table" style={{ tableLayout: 'fixed' }}>
          <thead>
            <tr>
              <th colSpan={2}>Name</th>
              <th>Type</th>
              <th>Owned by Trust</th>
              <th>Cost Basis</th>
              <th>Market Value</th>
              <th>Last Updated</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {this.state.assets.map((asset) => (
              <UniqueAssetsRow
                asset={asset}
                key={asset.id}
                showModal={this.showModalWithAsset}
                showViewModal={this.showViewModal}
                userRoles={this.props.userRoles}
              />
            ))}
          </tbody>
          {this.renderTotal()}
        </table>
        <br />
      </div>
    );
  }
}

UniqueAssetsTable.propTypes = {
  clientId: PropTypes.number,
  client: PropTypes.shape({ id: PropTypes.number }),
  showAdvanceOption: PropTypes.bool,
  userRoles: userRoleShape.isRequired,
};
