import PropTypes from 'prop-types';
import React from 'react';
import BeneficiarySignupLogin from './BeneficiarySignupLogin';
import BeneficiarySignupPersonal from './BeneficiarySignupPersonal';
import bindAll from 'react/shared/utils/bind_all';

export default class BeneficiarySignup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loginCompleted: false,
    };

    bindAll(this);
  }

  completeLogin() {
    // this is going to set the state so that the login details are "frozen",
    // the personal details are shown, and the user can't go back (i.e., the user
    // object has been created.)
    this.setState({ loginCompleted: true });
  }

  completePersonal() {
    // For now, this will redirect to log in. In the future, we may decide to conditionally continue
    // to another section.
    window.location.href = RailsRoutes.root_path();
  }

  render() {
    return (
      <div>
        <div
          className="grey_section_header header"
          id="signup_header"
          style={{ marginBottom: '20px' }}
        >
          <div aria-label="header" className="grey_section_header--inner clearfix b_s_h__hed">
            Create a login
          </div>
        </div>
        <div className="widescreen-inner-container">
          <div aria-label="subheader" className="info-box info-box--with-icon info-box--card">
            <p>Complete this form to create a login for the True Link online portal</p>
          </div>
          <BeneficiarySignupLogin
            completeLogin={this.completeLogin}
            email={this.props.email}
            resourceSlug={this.props.resourceSlug}
            tbis={this.props.tbis}
          />
          <BeneficiarySignupPersonal
            {...this.props}
            completePersonal={this.completePersonal}
            started={this.state.loginCompleted}
          />
        </div>
      </div>
    );
  }
}
BeneficiarySignup.propTypes = {
  tbis: PropTypes.string,
  resourceSlug: PropTypes.string,
  email: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  mobile: PropTypes.string,
  alternatePhone: PropTypes.string,
};

BeneficiarySignup.defaultProps = {
  email: '',
  firstName: '',
  lastName: '',
  mobile: '',
  alternatePhone: '',
};
