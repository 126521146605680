/* global Plaid */
import PropTypes from 'prop-types';
import React from 'react';

export default class PlaidComponent extends React.Component {
  async componentDidMount() {
    if (this.props.mockPublicToken) {
      this.onLinkSuccess(this.props.mockPublicToken, {
        institution: {
          institution_id: '1',
          name: 'Wells Fargo',
        },
      });

      return;
    }

    /*
     * Documentation of the Plaid link available at https://plaid.com/docs/quickstart/
     * This currently relies on a script file from Plaid having been included on the page
     * to create the `Plaid` global.
     * Currently uses https://cdn.plaid.com/link/v2/stable/link-initialize.js
     */
    const plaid = Plaid.create({
      clientName: 'True Link Financial',
      env: $tlf.plaid.env,
      token: await this.fetchLinkToken(),
      product: ['auth'],
      onSuccess: (public_token, metadata) => this.onLinkSuccess(public_token, metadata),
      onExit: (_error, _metadata) => this.onLinkExit(),
    });

    plaid.open();
  }

  onLinkSuccess(public_token, metadata) {
    $.ajax({
      url: '/signups/create_plaid_authorization',
      type: 'POST',
      data: {
        public_token,
        institution_id: metadata.institution.institution_id,
        institution_name: metadata.institution.name,
      },
    }).done((data) => this.props.onLinkSuccess(data));
  }

  async fetchLinkToken() {
    const response = await $.ajax({
      url: '/signups/create_plaid_link_token',
      type: 'POST',
    });
    return response['token'];
  }

  onLinkExit() {
    this.props.onLinkExit();
  }

  render() {
    return <div />;
  }
}

PlaidComponent.propTypes = {
  mockPublicToken: PropTypes.string,
  onLinkExit: PropTypes.func.isRequired,
  onLinkSuccess: PropTypes.func.isRequired,
};
