import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import RequestCashRaiseModal from './request_cash_raise_modal/RequestCashRaiseModal';
import DashboardCard from 'react/member/components/dashboard/clients/dashboard_client/overview/dashboard_card/DashboardCard';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import TrueLinkTooltip from 'react/shared/components/true_link/main/TrueLinkTooltip';
import theme from 'react/shared/theme/Theme';
import PALETTE from 'react/shared/theme/palette';
import { asMoney } from 'react/shared/utils/Money';

const useStyles = makeStyles(() => ({
  spacer: {
    flexGrow: 1,
  },
  assetLabel: {
    fontSize: '1.25rem',
    color: PALETTE.grey2,
  },
  tooltipLink: {
    borderBottom: `1px dashed ${PALETTE.grey3}`,
  },
  table: {
    marginBottom: theme.spacing(4),
  },
  totalAmount: {
    paddingRight: 0,
  },
  disbursementButton: {
    marginRight: theme.spacing(2),
  },
  tableCell: {
    padding: theme.spacing(3),
    paddingLeft: 0,
  },
  tableCellAmount: {
    paddingRight: 0,
  },
  investmentAccountTitle: {
    fontSize: '1.25rem',
  },
  divider: {
    marginTop: '-6px',
  },
}));

export default function ManagedAssets({ beneficiaryId, beneficiarySlug, totalTableState }) {
  const classes = useStyles();
  const [requestCashRaisedModalOpen, setRequestCashRaisedModalOpen] = useState(false);
  const closeRaiseCashModal = () => setRequestCashRaisedModalOpen(false);
  const openRaiseCashModal = () => setRequestCashRaisedModalOpen(true);

  const handleViewLink = () =>
    (window.location.href =
      RailsRoutes.dashboard_client_show_investment_details_path(beneficiarySlug));

  const handleDisbursement = () => {
    window.sessionStorage.setItem('DisbursementTabsWrapper - request disbursement open', 'true');
    window.sessionStorage.setItem('RequestDisbursementsRouteTracking', 'ManageAssetsButton');
    window.location.href = '#client-disbursements-tab';
  };

  const total =
    totalTableState?.availableCashSum?.amount +
    totalTableState?.approvedDisbursementsSum?.amount +
    totalTableState?.processedDisbursementsSum?.amount +
    totalTableState?.investmentSum?.amount;
  const approvedAndProcessedDisbursements =
    totalTableState?.approvedDisbursementsSum?.amount +
    totalTableState?.processedDisbursementsSum?.amount;
  const availableCash = totalTableState?.availableCashSum?.amount;
  const securities = totalTableState?.investmentSum?.amount;

  return (
    <DashboardCard
      content={
        <>
          <Divider className={classes.divider} />
          <Table aria-label="list of managed assets" className={classes.table}>
            <TableBody>
              <TableRow>
                <TableCell className={classes.tableCell} component="th" scope="row">
                  <TrueLinkTooltip
                    tooltipContent={
                      <Typography>
                        Investments minus your cash balance - so only marketable securities, such as
                        equities and fixed income.
                      </Typography>
                    }
                  >
                    <Typography
                      className={`${classes.assetLabel} ${classes.tooltipLink}`}
                      display="inline"
                    >
                      Securities
                    </Typography>
                  </TrueLinkTooltip>
                </TableCell>
                <TableCell align="right" className={classes.tableCellAmount}>
                  <Typography>{asMoney(securities)}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableCell} component="th" scope="row">
                  <TrueLinkTooltip
                    tooltipContent={
                      <Typography>
                        Your current cash balance minus approved and processed disbursements.
                      </Typography>
                    }
                  >
                    <Typography
                      className={`${classes.assetLabel} ${classes.tooltipLink}`}
                      display="inline"
                    >
                      Available Cash
                    </Typography>
                  </TrueLinkTooltip>
                </TableCell>
                <TableCell align="right" className={classes.tableCellAmount}>
                  <Typography>{asMoney(availableCash)}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableCell} component="th" scope="row">
                  <TrueLinkTooltip
                    tooltipContent={
                      <Typography>
                        These have been approved/processed but the funds have not left the account
                        yet.
                      </Typography>
                    }
                  >
                    <Typography
                      className={`${classes.assetLabel} ${classes.tooltipLink}`}
                      display="inline"
                    >
                      In-Progress Disbursements
                    </Typography>
                  </TrueLinkTooltip>
                </TableCell>
                <TableCell align="right" className={classes.tableCellAmount}>
                  <Typography>{asMoney(approvedAndProcessedDisbursements)}</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Grid alignItems="center" container direction="row" justifyContent="flex-start">
            {totalTableState?.canHaveDisbursementRequested && (
              <TrueLinkButton
                className={classes.disbursementButton}
                onClick={handleDisbursement}
                size="small"
                variant="primaryOutline"
              >
                Request Disbursement
              </TrueLinkButton>
            )}
            {totalTableState?.canHaveCashRaiseRequested && (
              <>
                <TrueLinkButton onClick={openRaiseCashModal} size="small" variant="primaryOutline">
                  Request Cash Raise
                </TrueLinkButton>
                {requestCashRaisedModalOpen && (
                  <RequestCashRaiseModal
                    availableCash={availableCash}
                    beneficiaryId={beneficiaryId}
                    investments={securities}
                    onClose={closeRaiseCashModal}
                  />
                )}
              </>
            )}
            <span className={classes.spacer} />
            <Typography className={classes.totalAmount}>
              Total:{'  '}
              {asMoney(total)}
            </Typography>
          </Grid>
        </>
      }
      icon="chart-column fa-solid"
      onViewLink={handleViewLink}
      title={
        <Typography className={classes.investmentAccountTitle} component="span" variant="h1">
          Investment Account
        </Typography>
      }
      viewLinkAriaLabel="Investment Account View Link"
    />
  );
}

ManagedAssets.propTypes = {
  beneficiaryId: PropTypes.number.isRequired,
  beneficiarySlug: PropTypes.string.isRequired,
  totalTableState: PropTypes.object,
};
