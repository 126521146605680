import PropTypes from 'prop-types';

const { string, number, shape } = PropTypes;

export const trustStatementRecipientShape = shape({
  id: number,
  email: string,
  mobile: string,
  address: shape({
    street1: string,
    street2: string,
    city: string,
    state: string,
    zip: string,
  }).isRequired,
});

export default trustStatementRecipientShape;
