import { possessiverize } from 'react/shared/utils/Strings';
import { MODULES, PSEUDO_MODULES, USER_TYPES } from 'react/signups/card/recipes/constants';

// For form fields that need to start with a blank value rather
// than a false value, so the form won't default to "no" being pre-selected
export const booleanToYesNo = (value) => {
  if (value === true) return 'yes';
  if (value === false) return 'no';
  return '';
};

export const yesNoToBoolean = (value) => {
  if (value === 'yes') return true;
  if (value === 'no') return false;
  return null;
};

export const oneLineAddress = (addressObj) => {
  if (!addressObj) return '';

  const line1 = addressObj.street1;
  const line2 = addressObj.street2;

  if (!line1) return '';

  const { city, state, zip } = addressObj;
  return `${line1}${line2 ? ` ${line2}` : ''}, ${city} ${state} ${zip}`;
};

export const matchingAddresses = (addressA, addressB) => {
  if (!addressA || !addressB) return false;
  if (!addressA.street1 || !addressB.street1) return false;
  if (addressA.street1 === '' || addressB.street1 === '') return false;

  return oneLineAddress(addressA) === oneLineAddress(addressB);
};

export const potentialTrustee = (cardFor) => {
  const matchesAny = (memo, next) =>
    memo || ['elder', 'disability', 'mental-health-challenges', 'other'].includes(next);

  return cardFor.reduce(matchesAny, false);
};

export const makeModuleDisplayNameList = (cardholderFirstName, userType) =>
  ({
    [USER_TYPES.FAMILY]: [
      'Your profile',
      `${possessiverize(cardholderFirstName)} profile`,
      'Finalize order',
    ],
    [USER_TYPES.CLIENT]: ['Create an account', 'Your profile', 'Card ordering'],
    [USER_TYPES.MYSELF]: ['Your profile', 'Finalize order'],
  })[userType];

export const getCurrentModuleDisplayName = (
  currentModule,
  userType,
  cardholderFirstName = 'Cardholder',
) => {
  const moduleDisplayNames =
    {
      [USER_TYPES.FAMILY]: {
        [MODULES.SURVEY]: '',
        [MODULES.CREDENTIALS]: 'Your profile',
        [MODULES.ADMIN]: 'Your profile',
        [PSEUDO_MODULES.CARDHOLDER_PROFILE]: `${possessiverize(cardholderFirstName)} profile`,
        [MODULES.CARD_ORDER]: 'Finalize order',
        [MODULES.SHIPPING]: 'Finalize order',
        [MODULES.IDV]: 'Finalize order',
        [MODULES.TRUST]: 'Finalize order',
        [MODULES.REVIEW]: 'Finalize order',
      },
      [USER_TYPES.CLIENT]: {
        [MODULES.SURVEY]: '',
        [MODULES.CREDENTIALS]: 'Create an account',
        [MODULES.ADMIN]: 'Your profile',
        [MODULES.IDV]: 'Your profile',
        [PSEUDO_MODULES.CARDHOLDER_PROFILE]: 'Card ordering',
        [MODULES.CARD_ORDER]: 'Card ordering',
        [MODULES.SHIPPING]: 'Card ordering',
        [MODULES.TRUST]: 'Card ordering',
        [MODULES.REVIEW]: 'Card ordering',
      },
      [USER_TYPES.MYSELF]: {
        [MODULES.SURVEY]: '',
        [MODULES.CREDENTIALS]: 'Your profile',
        [MODULES.ADMIN]: 'Your profile',
        [MODULES.CARD_ORDER]: 'Your profile',
        [MODULES.IDV]: 'Your profile',
        [MODULES.REVIEW]: 'Finalize order',
      },
    }[userType] || {};
  return moduleDisplayNames[currentModule];
};

export const convertDate = (date) => {
  const datePattern = /^(\d{4})-(\d{2})-(\d{2})$/;
  const match = datePattern.exec(date);
  if (match) {
    const [, year, month, day] = match;
    return `${month}/${day}/${year}`;
  }
  return date; // assume the date is already in proper format if not YYYY-MM-DD
};

export const getCardName = (firstName, lastName) => {
  const nameLengthLimit = 23;
  let cardName;
  if (`${firstName} ${lastName}`.length > nameLengthLimit) {
    // This is a temporary cardName which the user can review in the final step
    // It defaults to the first initial + last name, with the last name sliced if
    // it is still too long
    cardName = `${firstName[0]} ${lastName}`.slice(0, 23);
  }

  return cardName;
};
