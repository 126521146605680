Object.defineProperty(Array.prototype, 'unique', {
    enumerable: false,
    value: function() {
      var unique = [];
      for (var i = 0; i < this.length; i++) {
          if (unique.indexOf(this[i]) == -1) {
              unique.push(this[i]);
          }
      }
      return unique;
    }
});

// external links open in new tabs, per fincen guidance
$(document).ready(function () {
  $("a").each(function (idx, elem) {
    var split_link = elem.href.split('/');
    // don't mess with links to '#" or to mailto: s or whatever
    if (split_link.length < 3 || split_link[0].indexOf('http') !== 0)
      return;
    // check if they match in the last or second-last positions
    // e.g. from signup.truelinkfinancial.com to www.truelinkfinancial.com
    var link_host = split_link[2].split('.').reverse();
    var document_host = document.location.href.split("/")[2].split('.').reverse();
    var same_domain = link_host[0] === document_host[0] && (document_host[1] ? link_host[1] === document_host[1] : true);
    // if not same-domain, make it open in a new window
    if (!same_domain)
      elem.target = '_blank';
  });
});

// datepickers need to work
$(document).ready(function () {
  $('.datepicker').datepicker({
    changeYear: true,
    changeMonth: true,
    dateFormat: 'yy-mm-dd',
    minDate: "-100Y",
    maxDate: "+1Y",
    yearRange: "1900:2100"
  });

  $('.business_datepicker').datepicker("option", {
    beforeShowDay: $.datepicker.noWeekends,
    minDate: "-1Y",
    maxDate: "+1Y"
  });

  $('.docs_datepicker').datepicker("option", {
    minDate: "-5Y",
    maxDate: "+60Y"
  });

});

// from https://cdn.jsdelivr.net/npm/jquery-validation@1.19.1/dist/additional-methods.js
// modified to work with the way we are masking our fields
$.validator.addMethod("phone", function(phone_number, element) {
  phone_number = phone_number.replace(/[\s-]+/g, "");
  return this.optional(element) || phone_number.length > 9 && phone_number.match(/[0-9]{10}/);
}, "Please specify a valid phone number" );
