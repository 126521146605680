import PropTypes from 'prop-types';
import React from 'react';
import CardDetails from 'react/member/card/components/account_tab/card_status/CardDetails';
import { CARD_STATUSES } from 'react/member/utils/CardStatus';
import { programNameFromType } from 'react/shared/utils/account_program_type';

export default function PreRelease({ programType, status }) {
  const cardType = programNameFromType(programType);
  const note = `Thank you for requesting a new ${cardType} Card! Your card order is being processed by our team.`;

  return <CardDetails cardLast4={null} expiryDate={null} note={note} status={status} />;
}

PreRelease.propTypes = {
  programType: PropTypes.oneOf(['en', 'stable', 'able']).isRequired,
  status: PropTypes.oneOf([CARD_STATUSES.PRE_RELEASE_ADDITIONAL_CARD_ORDER_STATUS]).isRequired,
};
