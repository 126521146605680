import PropTypes from 'prop-types';
import React from 'react';
import { asMoney } from 'react/shared/utils/Money';

function PrintChecksAmountCell({ value }) {
  return <p>{asMoney(Number(value))}</p>;
}

export default PrintChecksAmountCell;

PrintChecksAmountCell.propTypes = {
  value: PropTypes.string.isRequired,
};
