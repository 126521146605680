import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import ProfileSection from './ProfileSection';
import Address from 'react/member/components/common/Address';
import { prettyPhone } from 'react/member/utils/Formatting';
import TrueLinkLink from 'react/shared/components/true_link/main/TrueLinkLink';
import addressShape from 'react/shared/shapes/address_shape';
import theme from 'react/shared/theme/Theme';
import PALETTE from 'react/shared/theme/palette';

const useStyles = makeStyles(() => ({
  card: {
    boxShadow: 'none',
    border: `1px solid ${PALETTE.grey4}`,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(3),
    fontSize: '1.25rem',
  },
  spacer: {
    flexGrow: 1,
  },
  icon: {
    color: PALETTE.emerald,
    marginRight: theme.spacing(3),
  },
  footer: {
    // borderTop: `1px solid ${PALETTE.grey4}`,
    padding: 0,
  },
  cardContent: {
    padding: theme.spacing(4),
    paddingTop: 0,
    paddingBottom: 0,
  },
  cardHeaderContainer: {
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(3),
  },
  cardHeaderGrid: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  link: {
    padding: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    width: '100%',
    height: '100%',
    textAlign: 'center',
  },
  regularFontSize: {
    fontSize: '1.25rem',
  },
  dividerMargin: {
    marginRight: '24px',
    marginLeft: '24px',
  },
}));

export default function Profile({ firstName, dateOfBirth, phone, age, address }) {
  const classes = useStyles();

  const handleViewMore = useCallback(() => {
    window.location.href = '#client-notes-tab';
  }, []);

  return (
    <Card className={classes.card}>
      <CardHeader
        className={classes.cardHeaderContainer}
        title={
          <Grid className={classes.cardHeaderGrid} container>
            <Typography className={classes.regularFontSize} component="span" variant="h3">
              {`${firstName}’s Profile`}
            </Typography>
            <span className={classes.spacer} />
          </Grid>
        }
      />
      <CardContent className={classes.cardContent}>
        <>
          <ProfileSection className={classes.regularFontSize} label="Date of Birth">
            {moment(dateOfBirth).format('MM/DD/YYYY')}
          </ProfileSection>
          {age && (
            <ProfileSection className={classes.regularFontSize} label="Age">
              {age}
            </ProfileSection>
          )}
          {phone && (
            <ProfileSection className={classes.regularFontSize} label="Phone Number">
              {prettyPhone(phone)}
            </ProfileSection>
          )}
          <ProfileSection label="Address">
            <Address address={address} className={classes.regularFontSize} />
          </ProfileSection>
        </>
      </CardContent>
      <Divider className={classes.dividerMargin} variant="middle" />
      <CardActions className={classes.footer}>
        <TrueLinkLink className={classes.link} onClick={handleViewMore}>
          <Typography>View more</Typography>
        </TrueLinkLink>
      </CardActions>
    </Card>
  );
}

Profile.propTypes = {
  firstName: PropTypes.string,
  dateOfBirth: PropTypes.string,
  age: PropTypes.number,
  phone: PropTypes.string,
  address: addressShape,
};
