import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import UpdateMobileModal from './UpdateMobileModal';
import { prettyPhone } from 'react/member/utils/Formatting';
import TrueLinkLink from 'react/shared/components/true_link/main/TrueLinkLink';

const useStyles = makeStyles({
  phoneNumber: {
    paddingRight: '15px',
  },
});

export default function PhoneField({ mobile, name, objectType, route }) {
  const classes = useStyles();
  const [phoneNumber, setPhoneNumber] = useState(mobile);
  const [isModalOpen, setModalOpen] = useState(false);

  const handleToggleModal = () => {
    setModalOpen(!isModalOpen);
  };
  return (
    <>
      <div>
        <span className={classNames('mobile', { [classes.phoneNumber]: phoneNumber })}>
          {phoneNumber && prettyPhone(phoneNumber)}
        </span>
        <TrueLinkLink onClick={handleToggleModal}>
          {phoneNumber ? 'Edit' : 'Add phone number'}
        </TrueLinkLink>
      </div>
      {isModalOpen && (
        <UpdateMobileModal
          mobile={phoneNumber}
          name={name}
          objectType={objectType}
          route={route}
          setPhoneNumber={setPhoneNumber}
          toggleModal={handleToggleModal}
        />
      )}
    </>
  );
}

PhoneField.propTypes = {
  mobile: PropTypes.string,
  name: PropTypes.string.isRequired,
  objectType: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
};
