import PropTypes from 'prop-types';
import React from 'react';

export default function AbleStableFundingInfo({ ableProgram, cardOpen, pseudoDda, stableProgram }) {
  const programText = (
    <>
      {ableProgram && (
        <p>
          To fund your card, sign into your ABLE Account at{' '}
          <a href="https://sumday.com/login" rel="noopener noreferrer" target="_blank">
            www.sumday.com/login
          </a>{' '}
          and initiate a withdrawal directly to your prepaid card.
        </p>
      )}

      {stableProgram && (
        <>
          <p>
            When you login to your STABLE account at www.stableaccount.com to fund the STABLE card,
            you’ll see the funding last 4 ID of {pseudoDda?.slice(-4)}.
          </p>
          <br />
          <p>This is your funding number for funding this STABLE card.</p>
        </>
      )}
    </>
  );

  return (
    <>
      <div className="sub_section_header">Funding information</div>
      <div className="account_info_section">
        {cardOpen ? (
          programText
        ) : (
          <p>Once your order has been processed, funding information will appear here.</p>
        )}
      </div>
    </>
  );
}

AbleStableFundingInfo.propTypes = {
  ableProgram: PropTypes.bool,
  stableProgram: PropTypes.bool,
  cardOpen: PropTypes.bool,
  pseudoDda: PropTypes.string,
};
