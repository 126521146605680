import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import InfoTooltip from './InfoTooltip';
import childrenShape from 'react/shared/shapes/children';
import bindAll from 'react/shared/utils/bind_all';

export default class InfoTooltipWithLink extends React.Component {
  constructor(props) {
    super(props);
    bindAll(this);
  }

  componentDidMount() {
    const $element = $(ReactDOM.findDOMNode(this._infoRef));
    const { childSelector, iconSelector, linkSelector, wrapperSelector } =
      this.getTooltipSelectors();

    $(document).on('mouseleave', wrapperSelector, () => $element.tooltip('hide'));
    $(document).on('mouseenter', iconSelector, () => $element.tooltip('show'));
    $(document).on('mouseenter', childSelector, () => $element.tooltip('show'));
    $(document).on('click', iconSelector, () => $element.tooltip('show'));
    if (this.props.tooltipLinkCallback) {
      $(document).on('click', linkSelector, (event) => {
        event.preventDefault();
        $element.tooltip('hide');
        this.props.tooltipLinkCallback();
      });
    }
    if (this.props.clickHide) {
      $(window).on('click', () => $element.tooltip('hide'));
    }
  }

  componentWillUnmount() {
    const { childSelector, iconSelector, linkSelector, wrapperSelector } =
      this.getTooltipSelectors();

    $(document).off('mouseleave', wrapperSelector);
    $(document).off('mouseenter', iconSelector);
    $(document).off('mouseleave', childSelector);
    $(document).off('click', iconSelector);
    $(document).off('click', linkSelector);
  }

  getWrapperId() {
    return `info-tooltip-with-link-${this.props.id}`;
  }

  getTooltipSelectors() {
    const childSelector = `#${this.props.childId}`;
    const iconSelector = `i#${this.props.id}`;
    const linkSelector = `#${this.props.tooltipLinkId}`;
    const wrapperSelector = `#${this.getWrapperId()}:has(${iconSelector})`;

    return { childSelector, iconSelector, linkSelector, wrapperSelector };
  }

  setRef(instance) {
    this._infoRef = instance;
  }

  render() {
    const { tooltipTextWithLink } = this.props;

    return (
      <span id={this.getWrapperId()}>
        <InfoTooltip
          {...this.props}
          html
          setRef={this.setRef}
          tooltipText={tooltipTextWithLink}
          trigger="manual"
        />
      </span>
    );
  }
}

InfoTooltipWithLink.propTypes = {
  childId: PropTypes.string,
  children: childrenShape,
  clickHide: PropTypes.bool,
  id: PropTypes.string.isRequired,
  tooltipTextWithLink: PropTypes.string.isRequired,
  tooltipLinkId: PropTypes.string.isRequired,
  tooltipLinkCallback: PropTypes.func,
};
