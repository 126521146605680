import axios from 'axios';
import _extend from 'underscore/modules/extend';
import DashboardActions from 'react/member/actions/dashboard_actions';

const DashboardDraftDisbursementStore = flux.createStore({
  draftDisbursements: [],
  actions: [
    DashboardActions.fetchDraftDisbursementById,
    DashboardActions.deleteDraftDisbursement,
    DashboardActions.updateDraftDisbursement,
    DashboardActions.fetchDraftDisbursementsByOrganization,
    DashboardActions.fetchDraftDisbursements,
  ],

  updateDraftDisbursement(disbursementID, data, eventOpts = {}) {
    const url = RailsRoutes.dashboard_draft_disbursement_path(disbursementID, {
      format: 'json',
    });
    return $.ajax({
      type: 'PATCH',
      url,
      data,
      success: (disbursement) => {
        this.emit('draftDisbursement.update', disbursement, eventOpts);
      },
      fail: (_disbursement) => {
        Truelink.flash('error', 'There was a problem updating this disbursement.');
      },
      complete: (_disbursement) => {
        eventOpts.onRequestCompleted && eventOpts.onRequestCompleted();
      },
    });
  },

  deleteDraftDisbursement(disbursementID) {
    const url = RailsRoutes.dashboard_draft_disbursement_path(disbursementID, {
      format: 'json',
    });
    axios.delete(url).then((deleteResponse) => {
      this.emit('draftDisbursement.delete', deleteResponse.data);
    });
  },

  fetchDraftDisbursementById(disbursementId) {
    const url = RailsRoutes.dashboard_draft_disbursement_path(disbursementId, {
      format: 'json',
    });
    axios.get(url).then((response) => {
      this.emit('draftDisbursement.fetch', response.data);
    });
  },

  fetchDraftDisbursementsByOrganization(organizationID, params = {}) {
    const reqParams = _extend({ format: 'json' }, params);
    return $.get(
      RailsRoutes.dashboard_organization_draft_disbursements_path(organizationID, reqParams),
      (draftDisbursements) => {
        this.draftDisbursements = draftDisbursements;
      },
    );
  },

  fetchDraftDisbursements(params = {}) {
    const reqParams = _extend({ format: 'json' }, params);
    return $.get(
      RailsRoutes.dashboard_draft_disbursements_path(reqParams),
      (draftDisbursements) => {
        this.draftDisbursements = draftDisbursements;
      },
    );
  },

  exports: {
    getDraftDisbursements() {
      return this.draftDisbursements;
    },
  },
});

export default DashboardDraftDisbursementStore;
