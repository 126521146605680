import DashboardActions from 'react/member/actions/dashboard_actions';

export default flux.createStore({
  uniqueAssets: [],
  actions: [DashboardActions.fetchUniqueAssets],
  fetchUniqueAssets(client_id) {
    return $.get(
      RailsRoutes.dashboard_client_unique_assets_path(client_id, {
        format: 'json',
      }),
      (function (_this) {
        return function (uniqueAssets) {
          _this.uniqueAssets = uniqueAssets;
          return _this.emit('UniqueAssets.fetch', _this.uniqueAssets);
        };
      })(this),
    );
  },
});
