import PropTypes from 'prop-types';
import React from 'react';
import _contains from 'underscore/modules/contains';
import _isArray from 'underscore/modules/isArray';
import _map from 'underscore/modules/map';
import _reduce from 'underscore/modules/reduce';
import ConfirmationPage from 'react/member/components/dashboard/ConfirmationPage';
import DashboardPayeeStore from 'react/member/stores/DashboardPayeeStore';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import DashboardClientStore from 'react/shared/stores/DashboardClientStore';
import { asMoney } from 'react/shared/utils/Money';
import bindAll from 'react/shared/utils/bind_all';

export default class DashboardBudgetItemConfirmationPage extends React.Component {
  constructor(props) {
    super(props);

    bindAll(this);
  }

  isDisplayed() {
    let prop;
    if (this.props.formSubmitted) {
      prop = 'initial';
    } else {
      prop = 'none';
    }
    return { display: prop };
  }

  // Returns a renderer-friendly version of the disbursement category information
  getCategories() {
    const data = this.props.formData;
    if (_isArray(data.amounts)) {
      return _map(data.amounts, (amount, idx) => ({
        amount: asMoney(amount),
        name: data.categories[idx],
        memo: data.memos[idx],
      }));
    }
    return [
      {
        amount: asMoney(data.amounts),
        name: data.categories,
        memo: data.memos,
      },
    ];
  }

  // Returns a renderer-friendly version of the disbursement category information
  getTotal() {
    const data = this.props.formData;
    const total = _isArray(data.amounts)
      ? _reduce(data.amounts, (total, amount) => total + Number(amount), 0)
      : Number(data.amounts);
    return asMoney(total);
  }

  confirmationInputsByPaymentMethod(data) {
    let payee;
    const inputs = [];
    if (_contains(['Check', 'External Check', 'EFT'], data.payment_method)) {
      payee = DashboardPayeeStore.get(Number(data.payee_id));
      const payeeName = {
        labelName: 'Payee',
        inputName: 'name',
        store_attribute: true,
        object: payee,
      };
      const clientAccountNumber = {
        labelName: 'Memo',
        inputName: 'client_account_number',
        store_attribute: true,
        object: payee,
      };
      inputs.push(payeeName, clientAccountNumber);
    } else if (data.payment_method === 'Wire') {
      payee = DashboardPayeeStore.get(Number(data.payee_id));
      inputs.push({
        labelName: 'Payee',
        inputName: 'name',
        store_attribute: true,
        object: payee,
      });
    }
    return inputs;
  }

  renderCategories() {
    const categories = this.getCategories();
    const total = this.getTotal();

    const renderedCategories = _map(categories, (category, idx) =>
      this.renderCategory(category, idx),
    );

    return (
      <div className="disbursement-conf-container">
        <table className="table table-striped">
          <tbody>{renderedCategories}</tbody>
          <tfoot>
            <tr>
              <td>TOTAL:</td>
              <td>{total}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    );
  }

  renderCategory(category, number) {
    const key = `category_${number}`;
    return (
      <tr className="form-group" key={key}>
        <td className="budgetItem_confirmation_label" style={{ width: 350 }}>
          {category.name}
        </td>
        <td className="budgetItem_confirmation_input">{category.amount}</td>
      </tr>
    );
  }

  render() {
    let inputs = [];
    const header = 'Confirm Disbursement';
    const data = this.props.formData;
    const client = DashboardClientStore.get(this.props.clientSlug);
    inputs = inputs.concat(this.confirmationInputsByPaymentMethod(data));

    const clientName = {
      labelName: 'Beneficiary',
      inputName: 'person.name',
      store_attribute: true,
      object: client,
    };
    const paymentMethod = {
      labelName: 'Payment Method',
      inputName: 'payment_method',
    };
    const budgetItemType = {
      labelName: 'Budget Type',
      inputName: 'budget_item_type',
    };
    // All the above in an array
    let commonInputs = [clientName];

    commonInputs = commonInputs.concat([paymentMethod, budgetItemType]);
    const categories = this.renderCategories();
    inputs = inputs.concat(commonInputs);
    if (_contains(['Check', 'External Check'], data.payment_method)) {
      inputs.push({ labelName: 'Check Memo Line', inputName: 'check_memo' });
    }
    if (data.payment_method === 'External Check') {
      inputs.push({
        labelName: 'Disposition',
        inputName: 'payee_disposition',
      });
    }
    if (data.payment_method === 'Check') {
      inputs.push({
        labelName: 'Postage Type',
        inputName: 'postage_code',
        type: 'postage_code',
      });
    }
    if (data.merchant) {
      inputs.push({ labelName: 'Merchant', inputName: 'merchant' });
    }
    if (data.budget_item_type === 'recurring') {
      inputs.push({
        labelName: 'Recurring Type',
        inputName: 'recurring_type',
        type: 'recurring_event',
      });
      inputs.push({
        labelName: 'Start Date',
        inputName: 'start_date',
        type: 'date',
      });
      inputs.push({
        labelName: 'End Date',
        inputName: 'end_date',
        type: 'date',
      });
      switch (data.recurring_type) {
        case 'every_week':
          inputs.push({
            labelName: 'Day of Week',
            inputName: 'day_of_week',
            type: 'date',
          });
          break;
        case 'every_month':
          inputs.push({
            labelName: 'Day of Month',
            inputName: 'day_of_month',
            type: 'date',
          });
          break;
        case 'twice_monthly':
          inputs.push({
            labelName: 'Day of Month',
            inputName: 'day_of_month',
            type: 'date',
          });
          inputs.push({
            labelName: 'Second Day of Month',
            inputName: 'day_of_month_2',
            type: 'date',
          });
          break;
      }
    } else {
      inputs.push({
        labelName: 'Process Date',
        inputName: 'delivery_date',
        type: 'date',
      });
    }
    inputs.push({
      labelName: 'Attachments',
      inputName: 'attachment[file]',
      type: 'attachment',
    });

    return (
      <div className="edit_budgetItem_form">
        <div className="sub_section_header">{header}</div>
        <div className="disbursement-conf-container">
          <ConfirmationPage
            formData={data}
            formSubmitted={this.props.formSubmitted}
            inputs={inputs}
            model="budgetItem"
            organizationSlug={this.props.organizationSlug}
          />
        </div>
        <div className="sub_section_header">Categories</div>
        <div className="disbursement-conf-container">
          {categories}
          <div>
            <div className="button-group">
              <TrueLinkButton
                disabled={this.props.disabled}
                onClick={this.props.confirmNewBudgetItem}
                variant="primary"
              >
                Create
              </TrueLinkButton>
              <TrueLinkButton
                onClick={this.props.editBudgetItem}
                style={{ marginLeft: '20px' }}
                variant="primaryOutline"
              >
                Edit
              </TrueLinkButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DashboardBudgetItemConfirmationPage.propTypes = {
  clientSlug: PropTypes.string,
  confirmNewBudgetItem: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  editBudgetItem: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  formSubmitted: PropTypes.bool.isRequired,
  organizationSlug: PropTypes.string.isRequired,
};
