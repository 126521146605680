import React from 'react';
import TrueLinkIcon from './TrueLinkIcon';
import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/regular';

export default {
  title: 'Standard/Icon',
  component: TrueLinkIcon,
  argTypes: {
    icon: {
      control: 'text',
      table: {
        category: 'Contents',
      },
    },
    className: {
      control: 'text',
      table: {
        category: 'Style',
      },
    },
    style: {
      table: {
        category: 'Style',
      },
    },
    onClick: {
      name: 'On Click Handler',
      description: 'Handler for click events',
      table: {
        category: 'Events',
      },
    },
    ariaLabel: {
      control: 'text',
      table: {
        category: 'Style',
      },
    },
  },
};

function Template(args) {
  return (
    <div>
      <TrueLinkIcon {...args} />
    </div>
  );
}

export const Standard = Template.bind({});
Standard.args = {
  icon: 'exclamation-triangle',
  onClick: null,
};

export const Clickable = Template.bind({});
Clickable.args = {
  icon: 'x',
  onClick: () => {
    // This is demonstration of behavior
  },
};
