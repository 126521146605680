import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import _range from 'underscore/modules/range';
import AutoTransferAgreement from './AutoTransferAgreement';
import DashboardActions from 'react/member/actions/dashboard_actions';
import { autoTransferShape } from 'react/member/card/shapes/AutoTransferShape';
import { bankAccountShape, bankAccountShapeLite } from 'react/member/card/shapes/BankAccountShape';
import { transferShape } from 'react/member/card/shapes/TransferShape';
import LoadingIndicator from 'react/shared/components/LoadingIndicator';
import PopUp from 'react/shared/components/popups/PopUp';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import TrueLinkIcon from 'react/shared/components/true_link/main/TrueLinkIcon';
import theme, { smallBreakpointMediaQuery } from 'react/shared/theme/Theme';
import { currencyFormatter } from 'react/shared/utils/Money';
import { ordinalize } from 'react/shared/utils/Numbers';
import { extractFormData } from 'react/shared/utils/form_serialization';

const useStyles = makeStyles(() => ({
  recurringTransferContainer: {
    fontSize: '16px',
    [theme.breakpoints.down('sm')]: {
      '& br': {
        display: 'none',
      },
      fontSize: '18px',
      '& .form-break': {
        display: 'none',
      },
      '& .form-label': {},
      '& .modal-footer': {
        border: 'none',
        '& .button-wrapper': {
          width: '100%',

          '& button': {
            width: '100%',
            padding: '10px',
            marginBottom: '10px',
          },
        },
      },
    },
    '& .flex-check': {
      display: 'flex',
      flexDirection: 'row',

      '& .select-currency': {
        width: '140px',
        margin: '0px 8px',
      },
      '& .select-day': {
        width: '75px',
        margin: '0px 8px',
      },

      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        height: 'auto',
        '& .select-currency': {
          width: '100%',
          margin: '10px 0 20px 0',
        },
        '& .select-day': {
          width: '140px',
          margin: '0',
        },
        '& .select-wrapper': {
          margin: '10px 0 20px 0',
        },
        '& .connector': {
          margin: '0px 8px',
        },
      },
    },

    '& .input-prefix': {
      marginRight: '5px',
      fontSize: '16px',
      [theme.breakpoints.down('sm')]: {
        position: 'absolute',
        left: '10px',
        top: '11px',
      },
    },
  },
  footerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',

    [theme.breakpoints.down('sm')]: {
      display: 'inline',
    },
  },
  formRow: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '10px',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  formRowData: {
    marginLeft: '120px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0',
    },
  },
  formContainer: {
    '& .form-group': {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        '& .new-form__label': {
          fontWeight: 'normal',
          marginBottom: '0',
        },
        '& .new-form__data': {
          position: 'relative',
          width: '100%',
          marginBottom: '5px',
          '& input[type="number"]': {
            width: '100% !important',
            height: '44px',
          },
          '& input[type="number"].currency': {
            paddingLeft: '30px',
          },
        },
      },
    },
  },

  transferSchedule: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      margin: '20px',
    },
  },

  thresholdInputWrapper: {
    marginLeft: '5px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0',
      marginTop: '10px',
      position: 'relative',
      '& input[type="number"].currency': {
        paddingLeft: '30px',
      },
      '& .input-prefix': {
        top: '0',
      },
    },
  },
}));

export default function AutoTransferUpsertModal({
  autoTransfer,
  bankAccount,
  edit,
  recurringTransferInstance,
  onClose,
  onBack,
  onCreateSuccess,
  onUpdateSuccess,
  onDelete,
}) {
  const classes = useStyles();
  const mobile = !useMediaQuery(smallBreakpointMediaQuery);

  const [amountError, setAmountError] = useState(false);
  const [amountErrorMessage, setAmountErrorMessage] = useState('');
  const [dayOfMonth1, setDayOfMonth1] = useState('1');
  const [dayOfMonth2, setDayOfMonth2] = useState('15');
  const [dayOfMonth, setDayOfMonth] = useState('1');
  const [dayOfWeek, setDayOfWeek] = useState('1');
  const [frequencyError, setFrequencyError] = useState(false);
  const [frequencyErrorMessage, setFrequencyErrorMessage] = useState('');
  const [recurringType, setRecurringType] = useState(
    autoTransfer ? autoTransfer.recurringType : '',
  );
  const [showSchedule, setShowSchedule] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [thresholdError, setThresholdError] = useState(false);
  const [thresholdErrorMessage, setThresholdErrorMessage] = useState('');
  const [twiceMonthlyError, setTwiceMonthlyError] = useState(false);
  const [twiceMonthlyErrorMessage, setTwiceMonthlyErrorMessage] = useState('');
  const [transferSchedule, setTransferSchedule] = useState(null);

  const parseData = () => {
    const form = $('#auto-transfer-form');
    const data = extractFormData(form.serializeArray());
    // Safari and IE will leave a trailing decimal, which we can fix by parsing the amount as a number
    data['amount'] = Number(data['amount']).toString();

    return data;
  };

  const validateAmount = (value, fieldName) => {
    const amount = Number(value);
    const splitted = String(value).split('.');
    const decimal = splitted[1];

    let amountErrorMessage;
    // eslint-disable-next-line unicorn/prefer-number-properties
    if (isNaN(amount)) {
      amountErrorMessage = 'Not a valid number';
    } else if (amount <= 0) {
      amountErrorMessage = 'Cannot be zero or empty';
    } else if (amount > 5000) {
      amountErrorMessage = 'Please enter a value less than or equal to 5000';
    } else if (decimal && decimal.length > 2) {
      amountErrorMessage = 'Only 2 decimal places are allowed';
    } else if (['', '.'].includes(decimal)) {
      amountErrorMessage = 'Please enter a valid number.';
    }

    if (fieldName === 'amount') {
      setAmountError(!!amountErrorMessage);
      setAmountErrorMessage(amountErrorMessage);
    }
    if (fieldName === 'threshold') {
      setThresholdError(!!amountErrorMessage);
      setThresholdErrorMessage(amountErrorMessage);
    }

    return !amountErrorMessage;
  };

  const validAmount = (value) => validateAmount(value, 'amount');
  const validThresholdAmount = (value) => validateAmount(value, 'threshold');

  const validateNotSameDay = () => {
    if (dayOfMonth1 === dayOfMonth2) {
      setTwiceMonthlyError(true);
      setTwiceMonthlyErrorMessage('Twice-monthly transfers must be on different days');
      return false;
    }
    setTwiceMonthlyError(false);
    setTwiceMonthlyErrorMessage('');
    return true;
  };

  const validSecondaryOption = (data) => {
    if (!recurringType) {
      setFrequencyError(true);
      setFrequencyErrorMessage('Must select a frequency');
      return false;
    }
    switch (recurringType) {
      case 'every_week':
        return dayOfWeek.length;
      case 'every_month':
        return dayOfMonth.length;
      case 'twice_monthly':
        return dayOfMonth1.length && dayOfMonth2.length && validateNotSameDay();
      case 'when_funds_drop_below':
        return validThresholdAmount(data['threshold_amount']);
      default:
        return false;
    }
  };

  const createAutoTransfer = (data) => {
    data['auto_transfer']['bank_account_id'] = bankAccount.id;
    DashboardActions.createAutoTransfer(data, (autoTransfer) => {
      onClose();
      onCreateSuccess(autoTransfer);
    });
  };

  const updateAutoTransfer = (data) => {
    DashboardActions.updateAutoTransfer(autoTransfer.id, data, (response) => {
      onUpdateSuccess(response);
    });
  };

  const updateAutoTransferRecurrence = (data) => {
    const { id } = recurringTransferInstance;
    const recurrenceData = { transfer: { id, amount: data.auto_transfer.amount } };

    DashboardActions.updateTransfer(id, recurrenceData, onClose);
  };

  const submitForm = (data) => {
    setSubmitting(true);
    if (edit) {
      if (recurringTransferInstance) {
        updateAutoTransferRecurrence(data);
      } else {
        updateAutoTransfer(data);
      }
    } else {
      createAutoTransfer(data);
    }
  };

  const validate = (ev) => {
    ev.preventDefault();
    const data = parseData();
    if (validAmount(data['amount']) && validSecondaryOption(data)) {
      submitForm({ auto_transfer: data });
    }
  };

  const deleteAutoTransfer = () => {
    onDelete();
    onClose();
  };

  const listDayNames = () =>
    _range(5).map((n, i) => (
      <option key={i} value={i + 1}>
        {moment.weekdays(n + 1)}
      </option>
    ));

  const daysOfMonthOptions = () =>
    // Range is exclusive of the final item, so we go to 32
    _range(1, 32).map((day, i) => (
      <option key={i} value={day}>
        {ordinalize(day)}
      </option>
    ));

  const onChangeAmount = () => {
    setShowSchedule(false);
    setTransferSchedule(false);
  };

  const onChangeRecurringType = (ev) => {
    setRecurringType(ev.target.value);
    setShowSchedule(false);
    setTransferSchedule(false);
  };

  const onChangeDayOfWeek = (ev) => {
    setDayOfWeek(ev.target.value);
    setShowSchedule(false);
    setTransferSchedule(false);
  };

  const onChangeDayOfMonth = (ev) => {
    setDayOfMonth(ev.target.value);
    setShowSchedule(false);
    setTransferSchedule(false);
  };

  const onChangeDayOfMonthOne = (ev) => {
    setDayOfMonth1(ev.target.value);
    setShowSchedule(false);
    setTransferSchedule(false);
  };

  const onChangeDayOfMonthTwo = (ev) => {
    setDayOfMonth2(ev.target.value);
    setShowSchedule(false);
    setTransferSchedule(false);
  };

  const onChangeThresholdAmount = () => {
    setShowSchedule(false);
    setTransferSchedule(false);
  };

  const amount = () => {
    const transfer = recurringTransferInstance || '';
    const error = amountError && <p style={{ color: '#d93302' }}>{amountErrorMessage}</p>;
    return (
      <div className="form-group">
        <div className="new-form__label nowrap">
          <label htmlFor="transfer_amount">Amount to transfer: </label>
        </div>
        <div className="new-form__data">
          <span className="input-prefix">$</span>
          <input
            className="currency"
            defaultValue={transfer?.amount || autoTransfer?.amount}
            id="transfer_amount"
            max="5000"
            maxLength="7"
            min="5"
            name="amount"
            onChange={onChangeAmount}
            placeholder="0.00"
            size="7"
            style={{ width: 90 }}
            type="number"
          />
          {error}
        </div>
      </div>
    );
  };

  const dateDrivenFrequencyInputs = (autoTransfer, recurringType) => {
    const twiceMonthlyErrorText = twiceMonthlyError && (
      <p style={{ color: '#d93302' }}>{twiceMonthlyErrorMessage}</p>
    );

    return (
      <>
        <div>
          <label className="every_week flex-check" htmlFor="recurring_type_every_week">
            <div>
              <input
                defaultChecked={recurringType == 'every_week'}
                id="recurring_type_every_week"
                name="recurring_type"
                onChange={onChangeRecurringType}
                type="radio"
                value="every_week"
              />
              Every week on {mobile && recurringType != 'every_week' && <span>...</span>}
            </div>
            <select
              className="select-currency"
              defaultValue={autoTransfer?.dayOfWeek}
              disabled={recurringType != 'every_week'}
              name="day_of_week"
              onChange={onChangeDayOfWeek}
              style={
                mobile && recurringType != 'every_week' ? { display: 'none' } : { display: 'flex' }
              }
            >
              {listDayNames()}
            </select>
          </label>
        </div>
        <br />

        <div>
          <label className="every_month flex-check" htmlFor="recurring_type_every_month">
            <div>
              <input
                defaultChecked={recurringType == 'every_month'}
                id="recurring_type_every_month"
                name="recurring_type"
                onChange={onChangeRecurringType}
                type="radio"
                value="every_month"
              />
              Monthly on the {mobile && recurringType != 'every_month' && <span>...</span>}
            </div>
            <div
              className="select-wrapper"
              style={
                mobile && recurringType != 'every_month' ? { display: 'none' } : { display: 'flex' }
              }
            >
              <select
                className="select-day"
                defaultValue={autoTransfer?.dayOfMonth}
                disabled={recurringType != 'every_month'}
                name="day_of_month"
                onChange={onChangeDayOfMonth}
              >
                {daysOfMonthOptions()}
              </select>
              <span className="connector">of every month</span>
            </div>
          </label>
        </div>
        <br />

        <div>
          <label className="twice_monthly flex-check" htmlFor="recurring_type_twice_monthly">
            <div>
              <input
                defaultChecked={recurringType == 'twice_monthly'}
                id="recurring_type_twice_monthly"
                name="recurring_type"
                onChange={onChangeRecurringType}
                type="radio"
                value="twice_monthly"
              />
              Twice every month on the
              {mobile && recurringType != 'twice_monthly' && <span> ...</span>}
            </div>
            <div
              className="select-wrapper"
              style={
                mobile && recurringType != 'twice_monthly'
                  ? { display: 'none' }
                  : { display: 'flex' }
              }
            >
              <select
                className="select-day"
                defaultValue={autoTransfer?.dayOfMonth || 1}
                disabled={recurringType != 'twice_monthly'}
                name="day_of_month"
                onChange={onChangeDayOfMonthOne}
              >
                {daysOfMonthOptions()}
              </select>
              <span className="connector">and</span>
              <select
                className="select-day"
                defaultValue={autoTransfer?.dayOfMonth2 || 15}
                disabled={recurringType != 'twice_monthly'}
                name="day_of_month_2"
                onChange={onChangeDayOfMonthTwo}
              >
                {daysOfMonthOptions()}
              </select>
              {twiceMonthlyErrorText}
            </div>
          </label>
        </div>
      </>
    );
  };

  const balanceDrivenFrequencyInputs = (autoTransfer, recurringType) => {
    const thresholdErrorText = thresholdError && (
      <p style={{ color: '#d93302' }}>{thresholdErrorMessage}</p>
    );
    const frequencyErrorText = frequencyError && (
      <p style={{ color: '#d93302' }}>{frequencyErrorMessage}</p>
    );

    return (
      <>
        <div>
          <label className="threshold flex-check" htmlFor="recurring_type_when_funds_drop_below">
            <div>
              <input
                defaultChecked={recurringType == 'when_funds_drop_below'}
                id="recurring_type_when_funds_drop_below"
                name="recurring_type"
                onChange={onChangeRecurringType}
                type="radio"
                value="when_funds_drop_below"
              />
              Whenever the card balance drops below
              {mobile && recurringType != 'when_funds_drop_below' && <span> ...</span>}
            </div>
            <div
              className={classes.thresholdInputWrapper}
              style={
                mobile && recurringType != 'when_funds_drop_below'
                  ? { display: 'none' }
                  : { display: 'block' }
              }
            >
              <span className="input-prefix">$</span>
              <input
                className="currency"
                defaultValue={autoTransfer?.thresholdAmount}
                disabled={recurringType != 'when_funds_drop_below'}
                max="5000"
                maxLength="7"
                min="5"
                name="threshold_amount"
                onChange={onChangeThresholdAmount}
                placeholder="0.00"
                size="5"
                style={mobile ? { width: '100%' } : { width: 90 }}
                type="number"
              />
              {thresholdErrorText}
            </div>
          </label>
        </div>
        {frequencyErrorText}
      </>
    );
  };

  const renderAccountInfo = () => {
    let accountInfo = bankAccount.accountNumber;
    if (bankAccount.nickname) {
      accountInfo = `${bankAccount.nickname} - ${accountInfo}`;
    }

    return (
      <div className={classes.formRow}>
        <span>Account: </span>
        <span className={classes.formRowData}>{accountInfo}</span>
      </div>
    );
  };

  const frequencyInputs = () => {
    const balanceDriven = autoTransfer && autoTransfer.recurringType == 'when_funds_drop_below';

    if (!autoTransfer) {
      return (
        <>
          {dateDrivenFrequencyInputs(autoTransfer, recurringType)}
          <br />
          {balanceDrivenFrequencyInputs(autoTransfer, recurringType)}
        </>
      );
    }

    return balanceDriven
      ? balanceDrivenFrequencyInputs(autoTransfer, recurringType)
      : dateDrivenFrequencyInputs(autoTransfer, recurringType);
  };

  const frequency = () => (
    <div className="form-group">
      <div className="new-form__label nowrap">
        <label htmlFor="recurring_type">Frequency:</label>
      </div>
      <div className="new-form__data">
        <div className="radio-group">{frequencyInputs()}</div>
      </div>
    </div>
  );

  const headerText = () => {
    if (!edit) {
      return 'Schedule recurring transfer';
    }

    if (recurringTransferInstance) {
      return `Edit recurring transfer for only the ${moment(recurringTransferInstance.date).format(
        'MMMM Do',
      )} instance`;
    }

    return 'Edit recurring transfers';
  };

  const footer = () => {
    const deleteButton = edit && !recurringTransferInstance && !submitting && (
      <TrueLinkButton
        className="btn btn-link"
        id="delete-recurring-transfer-cta"
        onClick={deleteAutoTransfer}
        variant="none"
      >
        Delete this recurring transfer
      </TrueLinkButton>
    );
    const backButton = onBack && (
      <TrueLinkButton className="btn btn-link" onClick={onBack} variant="none">
        <TrueLinkIcon icon="angle-left" /> Back
      </TrueLinkButton>
    );
    const submitButton = (
      <TrueLinkButton
        className="btn normal"
        disabled={submitting}
        id="submit-recurring-transfer-cta"
        loading={submitting}
        onClick={validate}
        variant="none"
      >
        Submit
      </TrueLinkButton>
    );
    return (
      <div className={classes.footerWrapper}>
        <div style={{ float: 'left' }}>{backButton}</div>
        <div className="button-wrapper">
          {deleteButton}
          {submitButton}
        </div>
      </div>
    );
  };

  const fetchTransferSchedule = () => {
    const autoTransfer = parseData();

    // don't fetch transfer schedule for invalid transfers
    if (!validAmount(autoTransfer['amount']) || !validSecondaryOption(autoTransfer)) {
      setShowSchedule(false);
      return;
    }

    // don't POST an id (which will be present when editing an existing transfer)
    delete autoTransfer.id;

    axios
      .post(RailsRoutes.schedule_dashboard_auto_transfers_path({ format: 'json' }), {
        auto_transfer: autoTransfer,
      })
      .then((response) => {
        const transferSchedule = {
          amount: response.data.amount,
          next_5_dates: response.data.next_5_dates.map((date) => ({
            adjusted: date.adjusted_date,
            unadjusted: date.unadjusted_date,
          })),
        };
        setTransferSchedule(transferSchedule);
      });
  };

  const toggleTransferSchedule = (e) => {
    e.preventDefault();

    if (showSchedule) {
      setShowSchedule(false);
    } else {
      setShowSchedule(true);
      fetchTransferSchedule();
    }
  };

  const transferScheduleBody = () => {
    const schedule = transferSchedule;

    if (!schedule) {
      return <LoadingIndicator containerStyle={{ width: 'auto' }} />;
    }

    return (
      <div className="transfer_schedule_container">
        <table className="transfer_schedule_table">
          <tbody>
            {schedule.next_5_dates.map((date, index) => (
              <tr key={index}>
                <td>{moment(date.adjusted).format('dddd, MMMM Do, YYYY')}</td>
                <td>{currencyFormatter(schedule.amount, { minimumFractionDigits: 0 })}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const renderTransferSchedule = () => {
    if (recurringTransferInstance || recurringType == 'when_funds_drop_below') {
      return;
    }

    const arrowClass = showSchedule ? 'angle-up' : 'angle-down';
    const scheduleText = showSchedule ? 'Hide' : 'Show';

    return (
      <div className={classes.transferSchedule}>
        <hr className="form-break" />
        <TrueLinkButton
          className="btn btn-link"
          key={`icon-${arrowClass}`}
          onClick={toggleTransferSchedule}
          style={{ padding: 0 }}
          variant="none"
        >
          {scheduleText} transfer schedule
          <TrueLinkIcon icon={arrowClass} style={{ marginLeft: '5px' }} />
        </TrueLinkButton>

        {showSchedule && transferScheduleBody()}
      </div>
    );
  };

  const body = () => {
    const id = autoTransfer && <input name="id" type="hidden" value={autoTransfer.id} />;
    return (
      <div style={{ fontSize: 16 }}>
        {renderAccountInfo()}
        <hr className="form-break" />

        <div>
          <form className={classes.formContainer} id="auto-transfer-form">
            {amount()}
            {!recurringTransferInstance && frequency()}
            {id}
          </form>
        </div>

        {renderTransferSchedule()}

        <hr className="form-break" />
        <AutoTransferAgreement />
      </div>
    );
  };

  return (
    <div className={classes.recurringTransferContainer}>
      <PopUp
        bannerMessage="Please correct the form fields highlighted below and submit again"
        footer={footer()}
        header={<div>{headerText()}</div>}
        maxWidth="750px"
        modalOpen
        onClose={onClose}
        showBanner={amountError || thresholdError || twiceMonthlyError}
      >
        {body()}
      </PopUp>
    </div>
  );
}

AutoTransferUpsertModal.propTypes = {
  autoTransfer: autoTransferShape,
  bankAccount: PropTypes.oneOfType([bankAccountShape, bankAccountShapeLite]).isRequired,
  edit: PropTypes.bool.isRequired,
  onBack: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  onCreateSuccess: PropTypes.func,
  onUpdateSuccess: PropTypes.func,
  recurringTransferInstance: transferShape,
};

AutoTransferUpsertModal.defaultProps = {
  autoTransfer: null,
  onCreateSuccess: () => {
    // Do nothing by default
  },
  onDelete: () => {
    // Do nothing by default
  },
  onUpdateSuccess: () => {
    // Do nothing by default
  },
  recurringTransferInstance: null,
};
