import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import accountAndRouting from 'images/account-and-routing.jpg';
import DashboardActions from 'react/member/actions/dashboard_actions';
import { dashboardAccountShape } from 'react/member/card/shapes/AccountShape';
import { routingNumberValid } from 'react/member/utils/RoutingNumberValid';
import PopUp from 'react/shared/components/popups/PopUp';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import theme from 'react/shared/theme/Theme';
import { extractFormData } from 'react/shared/utils/form_serialization';

const useStyles = makeStyles(() => ({
  modalContainer: {
    fontSize: '16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
      '& .form-break': {
        display: 'none',
      },
      '& .modal-footer': {
        border: 'none',

        '& button': {
          width: '100%',
          padding: '10px',
          marginBottom: '10px',
        },
      },
    },
    [theme.breakpoints.down('md')]: {
      '& .form-column-divider': {
        display: 'none',
      },
    },
  },
  checkImage: {
    width: '370px',
    height: '208px',
    display: 'block',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      width: '420px',
      height: 'auto',
    },
  },

  formContainer: {
    '& .form-group': {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        '& .new-form__label': {
          fontWeight: 'normal',
          marginBottom: '0',
        },
        '& .new-form__data': {
          position: 'relative',
          width: '100%',
          marginBottom: '5px',

          '& input': {
            width: '100% !important',
            height: '44px',
          },
        },
      },
    },
  },

  inputPrefix: {
    marginRight: '5px',
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      left: '10px',
      top: '11px',
    },
  },
}));
export default function ConnectBankAccountModal({ account, onCancel }) {
  const [bankName, setBankName] = useState('');
  const [routingNumberError, setRoutingNumberError] = useState(false);
  const [bankAccountNumberError, setBankAccountNumberError] = useState(false);

  const classes = useStyles();

  const resetModal = () => {
    setBankName('');
    setRoutingNumberError(false);
    setBankAccountNumberError(false);
  };

  const onCancelHandler = () => {
    resetModal();
    onCancel();
  };

  const connectBankAccount = (data) => {
    DashboardActions.connectBankAccount(account.id, data);
  };

  const loadBankName = (e) => {
    if (e.target.value.length < 9) {
      if (bankName.length) {
        setBankName('');
      }
      return;
    }
    const routingNumber = e.target.value;
    const params = { format: 'json', routing_number: routingNumber };
    $.get(RailsRoutes.bank_name_for_routing_dashboard_bank_accounts_path(params), {}).done(
      (res) => {
        setBankName(res.bank_name);
      },
    );
  };

  const validRoutingNumber = (value) => {
    if (routingNumberValid(value)) {
      setRoutingNumberError(false);
      return true;
    }

    setRoutingNumberError(true);
    return false;
  };

  const validBankAccountNumber = (value) => {
    if (value.length < 4 || value.length > 17 || /\D/.test(value)) {
      setBankAccountNumberError(true);
      return false;
    }
    setBankAccountNumberError(false);
    return true;
  };

  const validate = (ev) => {
    ev.preventDefault();
    const form = $('#connect-bank-account-form');
    const data = extractFormData(form.serializeArray());
    const validRouting = validRoutingNumber(data['routing_number']);
    const validBank = validBankAccountNumber(data['account_number']);
    if (validRouting && validBank) {
      connectBankAccount({ bank_account: data });
    }
  };

  const bankRoutingNumber = () => {
    const error = routingNumberError ? (
      <p style={{ color: '#d93302' }}>Invalid routing number</p>
    ) : null;
    const bankNameLabel = bankName ? (
      <label className="bankhint dynhint" htmlFor="bank_name">
        <span id="bank_name">{bankName}</span>
      </label>
    ) : null;
    return (
      <div className="form-group hide-arrows">
        <div className="new-form__label nowrap">
          <label htmlFor="bank_account_routing_number">Routing number:</label>
        </div>
        <div className="new-form__data">
          <input
            id="bank_account_routing_number"
            maxLength="9"
            name="routing_number"
            onChange={loadBankName}
            type="number"
            width="120"
          />
          {bankNameLabel}
          {error}
        </div>
      </div>
    );
  };

  const bankAccountNumber = () => {
    const error = bankAccountNumberError ? (
      <p style={{ color: '#d93302' }}>Invalid account number</p>
    ) : null;
    return (
      <div className="form-group hide-arrows">
        <div className="new-form__label nowrap">
          <label htmlFor="bank_account_account_number">Account number:</label>
        </div>
        <div className="new-form__data">
          <input
            id="bank_account_account_number"
            maxLength="17"
            name="account_number"
            type="number"
            width="120"
          />
          {error}
        </div>
      </div>
    );
  };

  const accountNickname = () => (
    <div className="form-group">
      <div className="new-form__label nowrap">
        <label htmlFor="bank_account_nickname">Account nickname:</label>
      </div>
      <div className="new-form__data">
        <input
          id="bank_account_nickname"
          name="nickname"
          placeholder="Optional"
          type="text"
          width="120"
        />
      </div>
    </div>
  );

  const header = () => <div style={{ textAlign: 'left' }}>Enter bank account info</div>;

  const footer = () => (
    <TrueLinkButton
      className="btn normal"
      id="connect-bank-account-submit-cta"
      onClick={validate}
      variant="none"
    >
      Submit
    </TrueLinkButton>
  );

  const body = () => (
    <div style={{ fontSize: 16 }}>
      <div>
        <form className={classes.formContainer} id="connect-bank-account-form">
          <div className="form-column form-group-left">
            {bankRoutingNumber()}
            {bankAccountNumber()}
            {accountNickname()}
            <input name="bank_account_holder" type="hidden" value="the_cardholder" />
          </div>
          <div
            className="form-column-divider"
            style={{
              position: 'absolute',
              borderLeft: '1px solid #ddd',
              height: 280,
              right: 405,
              bottom: 0,
              top: 0,
            }}
          />
          <div className="form-column form-group-right">
            <div style={{ margin: '0px auto 10px auto', textAlign: 'center', fontSize: '14px' }}>
              Where to find your routing and account numbers
            </div>
            <img
              alt="Your bank routing and account numbers can be found on the bottom of each check. The first set of numbers is your bank routing number, the second set is your account number, and the third set is the check number."
              className={classes.checkImage}
              src={accountAndRouting}
            />
          </div>
        </form>
      </div>
    </div>
  );

  return (
    <div className={classes.modalContainer}>
      <PopUp
        bannerMessage="Please correct the form fields highlighted below and submit again"
        cancel={onCancelHandler}
        footer={footer()}
        header={header()}
        modalOpen
        onClose={resetModal}
        showBanner={routingNumberError || bankAccountNumberError}
      >
        {body()}
      </PopUp>
    </div>
  );
}

ConnectBankAccountModal.propTypes = {
  account: dashboardAccountShape.isRequired,
  onCancel: PropTypes.func.isRequired,
};
