import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import _contains from 'underscore/modules/contains';
import _filter from 'underscore/modules/filter';
import AttachmentUploadPopUp from './AttachmentUploadPopUp';
import BrowseableFiles from './BrowseableFiles';
import GenericDatePickerPopUp from './GenericDatePickerPopUp';
import GenericDropdownPopUp from './GenericDropdownPopUp';
import GenericInlineLabel from './GenericInlineLabel';
import GenericSubSectionHeaderLayout from './GenericSubSectionHeaderLayout';
import GenericTextInputPopUp from './GenericTextInputPopUp';
import {
  attachableShape,
  attachableTypeShape,
  attachmentCategoryShape,
  fileTypeShape,
} from 'react/member/shapes/attachments_shapes';
import GenericSubmitPopUp from 'react/shared/components/popups/GenericSubmitPopUp';
import { capitalize } from 'react/shared/utils/Strings';
import bindAll from 'react/shared/utils/bind_all';

export default class AttachmentsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filesByFolder: [],
      folders: [],
      loading: true,
      refreshing: false,
      showAddFolderModal: false,
      showUploadFileModal: false,
      folderToRename: null,
      folderToDelete: null,
      fileToUpdate: null,
      fileToDelete: null,
    };

    bindAll(this);
  }

  componentDidMount() {
    const { attachable, category, store, fileType } = this.props;

    store.on('folder.create', this.handleToggleAddFolderModal);
    store.on('folder.update', this.handleToggleRenameFolderModal);
    store.on('folder.delete', this.handleToggleDeleteFolderModal);
    store.on(`${fileType}sAndFolders.fetch`, this.refreshFilesAndFolders);
    store.on(`${fileType}.upload`, this.handleCloseUploadFileModal);
    store.on(`${fileType}.update`, this.handleToggleUpdateFileModal);
    store.on(`${fileType}.destroy`, this.handleToggleDeleteFileModal);
    store.on(`${fileType}.error`, this.handleRefreshError);

    this.props.fetchFilesAndFoldersAction(attachable.slug, category);
  }

  componentWillUnmount() {
    const { store, fileType } = this.props;
    store.off('folder.create', this.handleToggleAddFolderModal);
    store.off('folder.update', this.handleToggleRenameFolderModal);
    store.off('folder.delete', this.handleToggleDeleteFolderModal);
    store.off(`${fileType}sAndFolders.fetch`, this.refreshFilesAndFolders);
    store.off(`${fileType}.upload`, this.handleCloseUploadFileModal);
    store.off(`${fileType}.update`, this.handleToggleUpdateFileModal);
    store.off(`${fileType}.destroy`, this.handleToggleDeleteFileModal);
    store.off(`${fileType}.error`, this.handleRefreshError);
  }

  buildDownloadUrl(file) {
    return RailsRoutes.api_v2_attachment_path(file.id);
  }

  refreshFilesAndFolders() {
    this.setState({
      filesByFolder: this.props.store.getFilesByFolder(),
      folders: this.props.store.getFolders(),
      loading: false,
      refreshing: false,
    });
  }

  handleRefreshError() {
    this.setState({ refreshing: false });
  }

  handleToggleAddFolderModal() {
    this.setState((prevState) => ({ showAddFolderModal: !prevState.showAddFolderModal }));
  }

  handleCloseAddFolderModal() {
    this.setState({ showAddFolderModal: false });
  }

  handleShowUploadFileModal() {
    this.setState({ showUploadFileModal: true });
  }

  handleCloseUploadFileModal() {
    this.setState({ showUploadFileModal: false });
  }

  handleToggleRenameFolderModal(folder = null) {
    this.setState({ folderToRename: folder });
  }

  handleToggleDeleteFolderModal(folder = null) {
    this.setState({ folderToDelete: folder });
  }

  handleToggleUpdateFileModal(file = null) {
    this.setState({ fileToUpdate: file });
  }

  handleToggleDeleteFileModal(file = null) {
    this.setState({ fileToDelete: file });
  }

  requestCreateFolder(inputValue) {
    const { attachable, attachableType, createFolderAction } = this.props;
    if (attachableType === 'Account') return;

    this.setState({ refreshing: true });
    createFolderAction(attachable.slug, attachable.id, inputValue);
  }

  requestRenameFolder(inputValue) {
    const { attachable, attachableType, renameFolderAction } = this.props;
    if (attachableType === 'Account') return;

    this.setState({ refreshing: true });
    renameFolderAction(attachable.slug, this.state.folderToRename, inputValue);
  }

  requestDestroyFolder() {
    const { attachable, attachableType, category, destroyFolderAction } = this.props;
    if (attachableType === 'Account') return;

    this.setState({ refreshing: true });
    destroyFolderAction(attachable.slug, this.state.folderToDelete, category);
  }

  requestUploadFile(ref) {
    this.props.store.dropzoneSubmit(ref);
  }

  requestRefreshOnUploadSuccess() {
    const { attachable, category, dropzoneSuccessAction } = this.props;

    this.setState({ refreshing: true });
    dropzoneSuccessAction(attachable.slug, category);
  }

  requestUpdateFile(newValue) {
    const { attachable, updateFileAction } = this.props;

    this.setState({ refreshing: true });
    updateFileAction(attachable.slug, this.state.fileToUpdate, newValue);
  }

  requestDestroyFile() {
    const { attachable, destroyFileAction } = this.props;

    this.setState({ refreshing: true });
    destroyFileAction(attachable.slug, this.state.fileToDelete.id);
  }

  renderAddFolderPopUp() {
    if (!this.state.showAddFolderModal) return null;
    if (this.props.attachableType === 'Account') return null;

    return (
      <GenericTextInputPopUp
        errorText="Please enter a folder name"
        headerText="Add folder"
        inputLabel="Folder name"
        onClose={this.handleCloseAddFolderModal}
        onSubmit={this.requestCreateFolder}
        submitText="Add folder"
      />
    );
  }

  renderRenameFolderPopUp() {
    if (!this.state.folderToRename) return null;
    if (this.props.attachableType === 'Account') return null;

    return (
      <GenericTextInputPopUp
        errorText="Please enter a folder name"
        headerText="Rename folder"
        inputLabel="New folder name"
        onClose={this.handleToggleRenameFolderModal}
        onSubmit={this.requestRenameFolder}
        subheaderNode={
          <GenericInlineLabel labelContent="Current folder name" spacer>
            {this.state.folderToRename.name}
          </GenericInlineLabel>
        }
        submitText="Rename folder"
      />
    );
  }

  renderDeleteFolderPopUp() {
    if (!this.state.folderToDelete) return null;
    if (this.props.attachableType === 'Account') return null;

    return (
      <GenericSubmitPopUp
        headerText="Delete folder"
        messageText="Are you sure you want to delete this folder?"
        onClose={this.handleToggleDeleteFolderModal}
        onSubmit={this.requestDestroyFolder}
        subheaderNode={
          <GenericInlineLabel labelContent="Folder" spacer>
            {this.state.folderToDelete.name}
          </GenericInlineLabel>
        }
        submitText="Yes, delete folder"
        warningText={`Be careful, this will delete all ${this.props.fileType}s in this folder.`}
      />
    );
  }

  renderUploadFilePopUp() {
    if (!this.state.showUploadFileModal) return null;

    return (
      <AttachmentUploadPopUp
        attachable={this.props.attachable}
        attachableType={this.props.attachableType}
        category={this.props.category}
        fileType={this.props.fileType}
        folders={this.state.folders}
        headerText={`Upload ${this.props.fileType}`}
        onClose={this.handleCloseUploadFileModal}
        onSubmit={this.requestUploadFile}
        onSuccess={this.requestRefreshOnUploadSuccess}
      />
    );
  }

  selectUpdateFilePopUp() {
    return {
      document: this.renderMoveDocumentPopUp,
      receipt: this.renderChangeReceiptDatePopUp,
    }[this.props.fileType]();
  }

  renderMoveDocumentPopUp() {
    if (!this.state.fileToUpdate) return null;

    return (
      <GenericDropdownPopUp
        dropdownLabel="Folder"
        dropdownOptions={this.state.folders}
        headerText="Move document to folder"
        includeBlankOption
        onClose={this.handleToggleUpdateFileModal}
        onSubmit={this.requestUpdateFile}
        subheaderNode={
          <GenericInlineLabel labelContent="Current folder name" spacer>
            {this.state.fileToUpdate.folder.name}
          </GenericInlineLabel>
        }
        submitText="Move document to folder"
      />
    );
  }

  renderChangeReceiptDatePopUp() {
    const { fileToUpdate } = this.state;

    if (!fileToUpdate) return null;

    const currentContentDate = fileToUpdate.content_date
      ? moment(fileToUpdate.content_date, 'YYYY-MM-DD')
      : null;
    const trueLinkBirthday = moment('2013-01-01', 'YYYY-MM-DD');
    const today = moment();

    return (
      <GenericDatePickerPopUp
        datePickerLabel="Receipt date"
        headerText="Update receipt date"
        initialDate={currentContentDate}
        maxDate={today}
        minDate={trueLinkBirthday}
        onClose={this.handleToggleUpdateFileModal}
        onSubmit={this.requestUpdateFile}
        submitText="Update"
      />
    );
  }

  renderDeleteFilePopUp() {
    if (!this.state.fileToDelete) return null;

    const { fileType } = this.props;

    return (
      <GenericSubmitPopUp
        headerText={`Delete ${fileType}`}
        messageText={`Are you sure you want to delete this ${fileType}?`}
        onClose={this.handleToggleDeleteFileModal}
        onSubmit={this.requestDestroyFile}
        subheaderNode={
          <GenericInlineLabel labelContent={capitalize(fileType)} spacer>
            {this.state.fileToDelete.name}
          </GenericInlineLabel>
        }
        submitText={`Yes, delete ${fileType}`}
      />
    );
  }

  renderDisplayHeader() {
    return (
      <GenericSubSectionHeaderLayout
        loading={this.state.refreshing}
        title={`${capitalize(this.props.fileType)}s`}
      />
    );
  }

  renderActionHeader() {
    const headerActionItems = _filter(
      [
        {
          displayText: `Upload ${this.props.fileType}`,
          icon: 'upload',
          action: this.handleShowUploadFileModal,
          fileTypes: ['document', 'receipt'],
        },
        {
          displayText: 'Add folder',
          icon: 'plus',
          action: this.handleToggleAddFolderModal,
          fileTypes: ['document'],
        },
      ],
      (item) => _contains(item.fileTypes, this.props.fileType),
    );

    const subtitle =
      this.props.fileType === 'receipt'
        ? 'Upload receipts for purchases made with your True Link Card.  You can include these receipts when downloading monthly statements.'
        : null;

    return (
      <GenericSubSectionHeaderLayout
        actionItems={headerActionItems}
        loading={this.state.refreshing}
        subtitle={subtitle}
        title={`${capitalize(this.props.fileType)}s`}
      />
    );
  }

  render() {
    return (
      <div>
        {this.props.canEdit ? this.renderActionHeader() : this.renderDisplayHeader()}
        <BrowseableFiles
          buildDownloadUrl={this.buildDownloadUrl}
          canEdit={this.props.canEdit}
          fileType={this.props.fileType}
          filesByFolder={this.state.filesByFolder}
          loading={this.state.loading}
          onDeleteFile={this.handleToggleDeleteFileModal}
          onDeleteFolder={this.handleToggleDeleteFolderModal}
          onRenameFolder={this.handleToggleRenameFolderModal}
          onUpdateFile={this.handleToggleUpdateFileModal}
        />
        {this.renderAddFolderPopUp()}
        {this.renderRenameFolderPopUp()}
        {this.renderDeleteFolderPopUp()}
        {this.renderUploadFilePopUp()}
        {this.selectUpdateFilePopUp()}
        {this.renderDeleteFilePopUp()}
      </div>
    );
  }
}

AttachmentsContainer.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  fileType: fileTypeShape.isRequired,
  attachableType: attachableTypeShape.isRequired,
  attachable: attachableShape.isRequired,
  category: attachmentCategoryShape,
  store: PropTypes.shape({
    on: PropTypes.func.isRequired,
    off: PropTypes.func.isRequired,
    getFilesByFolder: PropTypes.func.isRequired,
    getFolders: PropTypes.func.isRequired,
    dropzoneSubmit: PropTypes.func.isRequired,
  }).isRequired,
  fetchFilesAndFoldersAction: PropTypes.func.isRequired,
  updateFileAction: PropTypes.func.isRequired,
  destroyFileAction: PropTypes.func.isRequired,
  dropzoneSuccessAction: PropTypes.func.isRequired,
  createFolderAction: PropTypes.func,
  renameFolderAction: PropTypes.func,
  destroyFolderAction: PropTypes.func,
};

AttachmentsContainer.defaultProps = {
  category: null,
  createFolderAction: () => {
    // Do nothing if the store is empty
  },
  renameFolderAction: () => {
    // Do nothing if the store is empty
  },
  destroyFolderAction: () => {
    // Do nothing if the store is empty
  },
};
