import React from 'react';
import TrueLinkAccordion from './TrueLinkAccordion';
import TrueLinkButton from './TrueLinkButton';

export default {
  title: 'Standard/Accordion',
  component: TrueLinkAccordion,
  argTypes: {
    header: {
      control: 'text',
      table: {
        category: 'Contents',
      },
    },
    subText: {
      control: 'text',
      table: {
        category: 'Contents',
      },
    },
    icon: {
      control: 'text',
      table: {
        category: 'Contents',
      },
    },
    navLinkUrl: {
      control: 'text',
      table: {
        category: 'Contents',
      },
    },
    navLinkText: {
      control: 'text',
      table: {
        category: 'Contents',
      },
    },
    className: {
      control: 'text',
      table: {
        category: 'Style',
      },
    },
  },
};

function Template(args) {
  const subText = (
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus exsit
      amet blandit leo lobortis eget
    </p>
  );
  const navLink = (
    <a href="test" rel="noreferrer" target="_blank">
      click me
    </a>
  );
  return (
    <>
      <div style={{ textAlign: 'center' }}>
        <h2 style={{ padding: '40px 0' }}> Stand Alone Accordion Group</h2>
        <TrueLinkAccordion {...args}>
          {subText}
          {navLink}
          {subText}
          {navLink}
        </TrueLinkAccordion>
        <TrueLinkAccordion {...args}>
          {subText}
          {subText}
          {navLink}
        </TrueLinkAccordion>
        <TrueLinkAccordion {...args}>
          {subText}
          {navLink}
        </TrueLinkAccordion>
        <TrueLinkAccordion {...args}>
          {subText}
          {navLink}
          {navLink}
        </TrueLinkAccordion>
      </div>

      <div style={{ textAlign: 'center' }}>
        <h2 style={{ padding: '50px 0 0' }}> Accordion in a Card</h2>
        <div
          style={{
            margin: ' 50px auto 50px',
            padding: '30px',
            textAlign: 'center',
            width: '400px',
            border: '1px solid #ccc',
          }}
        >
          <h4 style={{ color: '#0D875C', fontSize: '28px' }}>
            The True Link Prepaid Visa Card is built to work with Special Needs Trusts
          </h4>
          <div style={{ paddingBottom: '50px' }}>
            <TrueLinkAccordion {...args}>
              {subText}
              {navLink}
              {subText}
              {navLink}
            </TrueLinkAccordion>
            <TrueLinkAccordion {...args}>
              {subText}
              {subText}
              {navLink}
            </TrueLinkAccordion>
            <TrueLinkAccordion {...args}>
              {subText}
              {navLink}
            </TrueLinkAccordion>
            <TrueLinkAccordion {...args}>
              {subText}
              {navLink}
              {navLink}
            </TrueLinkAccordion>
          </div>
          <TrueLinkButton size="regular" variant="primary">
            Click Me
          </TrueLinkButton>
        </div>
      </div>
    </>
  );
}

export const Standard = Template.bind({});
Standard.args = {
  header: 'Standard Accordion',
  icon: 'check',
};

export const NoIcon = Template.bind({});
NoIcon.args = {
  header: 'Accordion without header icon',
};

export const NoLink = Template.bind({});
NoLink.args = {
  header: 'Accordion without nav link',
  icon: 'list',
};
