import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ReactCodeInput from 'react-code-input';
import PALETTE from 'react/shared/theme/palette';

const useStyles = makeStyles({
  numericCodeInput: {
    // Remove the increment/decrement arrows from number input fields.
    // See: https://www.w3schools.com/howto/howto_css_hide_arrow_number.asp
    // Chrome, Safari, Edge, Opera
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },

    // Remove the increment/decrement arrows from number input fields.
    // See: https://www.w3schools.com/howto/howto_css_hide_arrow_number.asp
    // Firefox
    '& input[type="number"]': {
      '-moz-appearance': 'textfield',
    },
  },
});

export default function TrueLinkNumericCode({ fields, onChange, value, isValid }) {
  const classes = useStyles();

  return (
    <ReactCodeInput
      className={classNames(classes.numericCodeInput)}
      fields={fields}
      inputMode="numeric"
      inputStyle={{
        fontFamily: 'monospace',
        MozAppearance: 'textfield',
        borderRadius: '6px',
        border: '1px solid',
        boxShadow: '0px 0px 10px 0px rgba(0,0,0,.10)',
        margin: '4px',
        paddingLeft: '8px',
        paddingRight: 0,
        width: '36px',
        height: '42px',
        fontSize: '32px',
        boxSizing: 'border-box',
      }}
      inputStyleInvalid={{
        fontFamily: 'monospace',
        MozAppearance: 'textfield',
        borderRadius: '6px',
        border: `1px solid ${PALETTE.red}`,
        boxShadow: '0px 0px 10px 0px rgba(0,0,0,.10)',
        margin: '4px',
        paddingLeft: '8px',
        paddingRight: 0,
        width: '36px',
        height: '42px',
        fontSize: '32px',
        boxSizing: 'border-box',
        color: PALETTE.red,
      }}
      isValid={isValid}
      onChange={onChange}
      type="number"
      value={value}
    />
  );
}

TrueLinkNumericCode.propTypes = {
  fields: PropTypes.number.isRequired,
  isValid: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

TrueLinkNumericCode.defaultProps = {
  value: '',
  isValid: true,
};
