import PropTypes from 'prop-types';
import React from 'react';
import AccountNumberInput from './AccountNumberInput';
import AddressInputs from './AddressInputs';
import PhoneDob from './PhoneDob';
import { requiredAddressShape } from './Shapes';
import NameInputsWithLimit from 'react/member/components/common/NameInputsWithLimit';
import programNameShape from 'react/shared/shapes/account_program_name_shape';

export default function BeneCardOrderSection({
  administratorAddress,
  onAddressInputChange,
  programName,
}) {
  return (
    <div>
      <div className="card--form__section">
        <input name="administrator[is_alr]" type="hidden" value="no" />
      </div>

      <div className="card--form__section">
        <NameInputsWithLimit
          inputNamePrefix="administrator"
          labelPrefix="Your"
          lengthLimit={23}
          warningFullNameOwnerPrefix="cardholder"
        />
        <AccountNumberInput programName={programName} />
      </div>

      <div className="card--form__section">
        <AddressInputs
          address={administratorAddress}
          labelPrefix="Your"
          namePrefix="administrator"
          onInputChange={onAddressInputChange}
        />
        <PhoneDob labelPrefix="" />
      </div>
    </div>
  );
}

BeneCardOrderSection.propTypes = {
  administratorAddress: requiredAddressShape.isRequired,
  programName: programNameShape.isRequired,
  onAddressInputChange: PropTypes.func.isRequired,
};
