export const TRANSFER_STATUSES = ['Completed', 'In progress'];

export const TRANSFER_STATES = [
  'proposed',
  'requested',
  'prepared',
  'prepared_admin_credit',
  'admin_credited',
  'submitted',
  'cancelled',
  'completed',
];

export const TRANSFER_TYPES = ['One-time transfer', 'Recurring transfer'];
