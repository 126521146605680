import PropTypes from 'prop-types';
import React from 'react';
import _map from 'underscore/modules/map';
import PlaidComponent from './PlaidComponent';
import DashboardActions from 'react/member/actions/dashboard_actions';
import PopUp from 'react/shared/components/popups/PopUp';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import bindAll from 'react/shared/utils/bind_all';

export default class ConnectPlaidBankAccountModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      plaidAccounts: [],
      accountSelectError: false,
    };

    bindAll(this);
  }

  onCancel() {
    this.resetModal();
    this.props.onCancel();
  }

  resetModal() {
    this.setState({ plaidAccounts: [] });
  }

  onPlaidLinkSucess(data) {
    this.setState({ plaidAccounts: data.accounts });
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = $('#connect-plaid-form');
    const data = form.serializeArray();
    if (data.length > 0) {
      this.setState({ accountSelectError: false });
      DashboardActions.createPlaidBankAccount(data);
    } else {
      this.setState({ accountSelectError: true });
    }
  }

  header() {
    return <div style={{ textAlign: 'left' }}>Select an account to use for this transfer</div>;
  }

  footer() {
    return (
      <TrueLinkButton
        className="btn normal"
        id="plaid-modal-submit-button"
        onClick={this.submitForm}
        variant="none"
      >
        Use this account
      </TrueLinkButton>
    );
  }

  body() {
    const { plaidAccounts, accountSelectError } = this.state;

    const accounts = _map(plaidAccounts, this.renderAccount);

    return (
      <div>
        <form className="account-select-body modal-body horizontal" id="connect-plaid-form">
          <div>
            {accounts}
            {accountSelectError && (
              <div className="alert alert-error select-bank-account-error">
                Please select an account to proceed.
              </div>
            )}
          </div>
        </form>
      </div>
    );
  }

  renderAccount(account) {
    return (
      <div className="questionnaire-radio__plaid width-100" key={account.account_id}>
        <input
          className="questionnaire-radio__input__plaid"
          id={account.account_id}
          name="plaid_account_id"
          type="radio"
          value={account.account_id}
        />
        <label className="questionnaire-radio__label__plaid block" htmlFor={account.account_id}>
          <span className="questionnaire-radio__checkbox-container__plaid" />
          <span>{account.name}</span>
          <span className="fright">Account number:&nbsp;{account.number}</span>
        </label>
      </div>
    );
  }

  render() {
    const activeComponent =
      this.state.plaidAccounts.length === 0 ? (
        <PlaidComponent
          mockPublicToken={this.props.mockPublicToken}
          onLinkExit={this.onCancel}
          onLinkSuccess={this.onPlaidLinkSucess}
        />
      ) : (
        <PopUp
          cancel={this.onCancel}
          footer={this.footer()}
          header={this.header()}
          modalOpen
          onClose={this.resetModal}
        >
          {this.body()}
        </PopUp>
      );

    return <div id="connect-plaid-bank-account-modal">{activeComponent}</div>;
  }
}

ConnectPlaidBankAccountModal.propTypes = {
  mockPublicToken: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
};

ConnectPlaidBankAccountModal.defaultProps = {
  mockPublicToken: null,
};
