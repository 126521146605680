import axios from 'axios';
import _each from 'underscore/modules/each';
import DashboardActions from 'react/member/actions/dashboard_actions';

const DashboardDepositStore = flux.createStore({
  deposits: [],
  depositsById: {},
  actions: [
    DashboardActions.fetchClientDeposits,
    DashboardActions.fetchPendingClientDeposits,
    DashboardActions.fetchPortfolioDeposits,
    DashboardActions.fetchPendingPortfolioDeposits,
    DashboardActions.fetchOrganizationDeposits,
    DashboardActions.fetchPendingOrganizationDeposits,
    DashboardActions.fetchDeposits,
    DashboardActions.fetchPendingDeposits,
    DashboardActions.fetchRecurringClientDeposits,
    DashboardActions.fetchApprovedRecurringClientDeposits,
    DashboardActions.fetchRecurringOrganizationDeposits,
    DashboardActions.fetchRecurringPortfolioDeposits,
    DashboardActions.fetchApprovedRecurringPortfolioDeposits,
    DashboardActions.fetchApprovedRecurringOrganizationDeposits,
    DashboardActions.fetchRecurringDeposits,
    DashboardActions.fetchApprovedRecurringDeposits,
    DashboardActions.openDepositCancelModal,
    DashboardActions.cancelOneTimeDeposit,
    DashboardActions.createMultipleDeposits,
  ],

  fetchClientDeposits(clientId, statusFilter) {
    return $.get(
      RailsRoutes.dashboard_client_deposits_path(clientId, {
        status_filter: statusFilter,
        format: 'json',
      }),
      (deposits) => {
        this.deposits = deposits;
        _each(this.deposits, (deposit) => (this.depositsById[deposit.id] = deposit));
        return this.emit(`${statusFilter}OneTimeDeposits.fetch`, this.deposits);
      },
    );
  },

  fetchPendingClientDeposits(clientId) {
    return this.fetchClientDeposits(clientId, 'pending');
  },

  fetchPortfolioDeposits(portfolioId, statusFilter) {
    return $.get(
      RailsRoutes.dashboard_portfolio_deposits_path(portfolioId, {
        status_filter: statusFilter,
        format: 'json',
      }),
      (deposits) => {
        this.deposits = deposits;
        _each(this.deposits, (deposit) => (this.depositsById[deposit.id] = deposit));
        return this.emit(`${statusFilter}OneTimeDeposits.fetch`, this.deposits);
      },
    );
  },

  fetchPendingPortfolioDeposits(portfolioId) {
    return this.fetchPortfolioDeposits(portfolioId, 'pending');
  },

  fetchOrganizationDeposits(organizationId, statusFilter) {
    return $.get(
      RailsRoutes.dashboard_organization_deposits_path(organizationId, {
        status_filter: statusFilter,
        format: 'json',
      }),
      (deposits) => {
        this.deposits = deposits;
        _each(this.deposits, (deposit) => (this.depositsById[deposit.id] = deposit));
        return this.emit(`${statusFilter}OneTimeDeposits.fetch`, this.deposits);
      },
    );
  },

  fetchPendingOrganizationDeposits(organizationId) {
    return this.fetchOrganizationDeposits(organizationId, 'pending');
  },

  fetchDeposits(statusFilter) {
    return $.get(
      RailsRoutes.dashboard_deposits_path({
        status_filter: statusFilter,
        format: 'json',
      }),
      (deposits) => {
        this.deposits = deposits;
        _each(this.deposits, (deposit) => (this.depositsById[deposit.id] = deposit));
        return this.emit(`${statusFilter}OneTimeDeposits.fetch`, this.deposits);
      },
    );
  },

  fetchPendingDeposits() {
    return this.fetchDeposits('pending');
  },

  fetchRecurringClientDeposits(clientId, statusFilter) {
    return $.get(
      RailsRoutes.dashboard_client_recurring_deposits_path(clientId, {
        status_filter: statusFilter,
        format: 'json',
      }),
      (deposits) => {
        this.deposits = deposits;
        _each(this.deposits, (deposit) => (this.depositsById[deposit.id] = deposit));
        return this.emit(`${statusFilter}RecurringDeposits.fetch`, this.deposits);
      },
    );
  },

  fetchApprovedRecurringClientDeposits(clientId) {
    return this.fetchRecurringClientDeposits(clientId, 'approved');
  },

  fetchRecurringPortfolioDeposits(portfolioId, statusFilter) {
    return $.get(
      RailsRoutes.dashboard_portfolio_recurring_deposits_path(portfolioId, {
        status_filter: statusFilter,
        format: 'json',
      }),
      (deposits) => {
        this.deposits = deposits;
        _each(this.deposits, (deposit) => (this.depositsById[deposit.id] = deposit));
        return this.emit(`${statusFilter}RecurringDeposits.fetch`, this.deposits);
      },
    );
  },

  fetchApprovedRecurringPortfolioDeposits(portfolioId) {
    return this.fetchRecurringPortfolioDeposits(portfolioId, 'approved');
  },

  fetchRecurringOrganizationDeposits(organizationId, statusFilter) {
    return $.get(
      RailsRoutes.dashboard_organization_recurring_deposits_path(organizationId, {
        status_filter: statusFilter,
        format: 'json',
      }),
      (deposits) => {
        this.deposits = deposits;
        _each(this.deposits, (deposit) => (this.depositsById[deposit.id] = deposit));
        return this.emit(`${statusFilter}RecurringDeposits.fetch`, this.deposits);
      },
    );
  },

  fetchApprovedRecurringOrganizationDeposits(organizationId) {
    return this.fetchRecurringOrganizationDeposits(organizationId, 'approved');
  },

  fetchRecurringDeposits(statusFilter) {
    return $.get(
      RailsRoutes.dashboard_recurring_deposits_path({
        status_filter: statusFilter,
        format: 'json',
      }),
      (deposits) => {
        this.deposits = deposits;
        _each(this.deposits, (deposit) => (this.depositsById[deposit.id] = deposit));
        return this.emit(`${statusFilter}RecurringDeposits.fetch`, this.deposits);
      },
    );
  },

  fetchApprovedRecurringDeposits() {
    return this.fetchRecurringDeposits('approved');
  },

  openDepositCancelModal(id, budgetItemType, trustBeneId) {
    this.emit('depositCancelModal.open', id, budgetItemType, trustBeneId);
  },

  cancelOneTimeDeposit(id, trustBeneId, reason) {
    const url = RailsRoutes.dashboard_deposit_cancel_path(id, { format: 'json' });
    const deposit = {
      trust_beneficiary_id: trustBeneId,
      cancellation_reason: reason,
    };
    return $.ajax({
      url,
      type: 'PUT',
      data: { deposit },
      success: (deposit) => {
        Truelink.flash('notice', 'Deposit cancelled!');
        this.emit('deposit.update', deposit);
        this.emit('depositCancelModal.close');
      },
    });
  },

  createMultipleDeposits(deposits, organizationId) {
    const url = organizationId
      ? RailsRoutes.dashboard_organization_multiple_deposits_path(organizationId, {
          format: 'json',
        })
      : RailsRoutes.multiple_deposits_path({ format: 'json' });

    axios.post(url, { records: deposits }).then((createMultipleDepositsResponse) => {
      this.emit('multipleDeposits.create', createMultipleDepositsResponse.data);
    });
  },
});

export default DashboardDepositStore;
