import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import bindAll from 'react/shared/utils/bind_all';

export default class CustomCheckbox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasFocus: false,
    };

    bindAll(this);
  }

  componentDidMount() {
    if (this.props.setupHover) {
      this.props.setupHover();
      $(`#checkbox-overlay-${this.props.name}`).tooltip({
        tooltipClass: `checkbox-overlay-${this.props.name}-tooltip`,
      });
    }
  }

  // Returns the actual checkbox element that this CustomCheckbox is a wrapper around
  getUnderlyingInput() {
    return this.refs.input;
  }

  handleFocus() {
    this.setState({ hasFocus: true });
  }

  handleBlur() {
    this.setState({ hasFocus: false });
  }

  render() {
    const style = { position: 'absolute', height: 20, width: 20 };
    const id = `checkbox-overlay-${this.props.id || this.props.name}`;
    const classes = classNames('custom-checkbox', {
      'custom-checkbox--checked': !!this.props.checked,
      'custom-checkbox--unchecked': !this.props.checked,
      'custom-checkbox--focus': this.state.hasFocus,
      'custom-checkbox--disabled': this.props.disabled,
      'custom-checkbox--with-tooltip': this.props.setupHover && this.props.disabled,
    });

    const { handleHover, setupHover, toolTipTitle, ...rest } = this.props;
    const inputProps = { ...rest, id: undefined };

    // the overlay allows a tooltip to be added to a disabled checkbox
    return (
      <div className={classes}>
        <div
          id={id}
          onFocus={handleHover}
          onMouseOver={handleHover}
          style={style}
          title={toolTipTitle}
        />

        <input
          className="custom-checkbox-screenreader-only"
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          ref="input"
          type="checkbox"
          {...inputProps}
        />
      </div>
    );
  }
}

CustomCheckbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  value: PropTypes.string,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  setupHover: PropTypes.func,
  handleHover: PropTypes.func,
  toolTipTitle: PropTypes.string,
};
