import Typography from '@mui/material/Typography';
import React from 'react';
import TrueLinkTooltip from './TrueLinkTooltip';

export default {
  title: 'Standard/Tooltip',
  component: TrueLinkTooltip,
  argTypes: {
    children: {
      control: 'text',
      table: {
        category: 'Contents',
      },
    },
    tooltipContent: {
      control: 'text',
      table: {
        category: 'Contents',
      },
    },
    className: {
      control: 'text',
      table: {
        category: 'Style',
      },
    },
  },
};

function Template(args) {
  return (
    <TrueLinkTooltip {...args} tooltipContent={<Typography>{args.tooltipContent}</Typography>}>
      <Typography display="inline">{args.children}</Typography>
    </TrueLinkTooltip>
  );
}

export const OnText = Template.bind({});
OnText.args = {
  tooltipContent:
    'If you are a trustee or guardian AND a family member, please select “a family member or loved one”. A “client of mine” is reserved for purely professional relationships.',
  children: 'Text with tooltip',
};

export const OnTextWidth600 = Template.bind({});
OnTextWidth600.args = {
  tooltipContent:
    'If you are a trustee or guardian AND a family member, please select “a family member or loved one”. A “client of mine” is reserved for purely professional relationships.',
  children: 'Text with tooltip, width 600px',
  maxWidth: '600px',
};
