const BeneficiaryDashboardActions = flux.createActions([
  // Payees
  'fetchPayees',
  'createPayee',

  // Disbursements
  'createDisbursement',

  // Attachments
  'destroyBudgetItemAttachment',

  // Signup
  'createSignupUser',
  'createSignupPersonDetails',
]);

export default BeneficiaryDashboardActions;
