import PropTypes from 'prop-types';
import React from 'react';
import SignupFlowProgressDisplay from './signup_flow_progress_display';

function SignupFlowMobileHeader({ progressStep, stepTitle, stepText, afterHeaderNode }) {
  return (
    <div className="sign-up-flow__header--mobile">
      <SignupFlowProgressDisplay progressStep={progressStep} />
      <h4 className="sign-up-flow__header--mobile-title">{stepTitle}</h4>
      <p className="sign-up-flow__header--mobile-text">{stepText}</p>
      {afterHeaderNode && (
        <div className="sign-up-flow__header--mobile--after">{afterHeaderNode}</div>
      )}
    </div>
  );
}

SignupFlowMobileHeader.propTypes = {
  progressStep: PropTypes.oneOf([1, 2, 3]).isRequired,
  stepTitle: PropTypes.string.isRequired,
  stepText: PropTypes.string,
  afterHeaderNode: PropTypes.node,
};

SignupFlowMobileHeader.defaultProps = {
  afterHeaderNode: null,
};

export default SignupFlowMobileHeader;
