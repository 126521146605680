import { ErrorMessage, FastField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import SubTextField from './SubTextField';
import NameFields from 'react/member/components/signups/signup_form/form_partials/fields/NameFields';

function NameFieldsWithLimit({
  entity,
  labelPrefix,
  lengthLimit,
  nameExceedsLengthLimit,
  prefill,
  rowClass,
  colClass,
  dataAnalyticsPrefix,
}) {
  const resetCardName = (newValue, fieldName, form) => {
    let fullName = '';
    if (fieldName === `${entity}.first_name`) {
      fullName = `${newValue} ${form.values[entity].last_name}`;
    } else if (fieldName === `${entity}.last_name`) {
      fullName = `${form.values[entity].first_name} ${newValue}`;
    }

    if (fullName.length <= lengthLimit) {
      form.setFieldValue(`${entity}.card_name`, '');
    }
  };

  return (
    <div>
      <NameFields
        colClass={colClass}
        dataAnalyticsPrefix={dataAnalyticsPrefix}
        entity={entity}
        labelPrefix={labelPrefix}
        onChangeCallback={resetCardName}
        prefill={prefill}
        rowClass={rowClass}
      />
      {nameExceedsLengthLimit && (
        <div className={`${rowClass}`}>
          <div className={`${colClass} show-on-mobile`}>
            <SubTextField
              colClass="card--form__col card--form__col--no-pad-mobile"
              fieldLabelProps={{
                labelContent: 'Name to be printed on card',
                htmlFor: `${entity}_card_name`,
              }}
              id={`${entity}_card_name`}
              name={`${entity}.card_name`}
              rowClass="card--form__row card--form__row--no-pad-mobile"
            />
          </div>
          <div className={`${colClass} card--form__card-name-box hide-on-mobile`}>
            <p>
              We are only able to print {lengthLimit} total characters on the name line of our
              cards. Please enter a variant of the {entity}'s full name that is {lengthLimit}{' '}
              characters or fewer.
            </p>
            <hr style={{ borderBottom: '1px solid #ccc' }} />
            <label htmlFor={`${entity}_card_name`}>
              Name to be printed on card
              <FastField name={`${entity}.card_name`}>
                {({ field, form }) => {
                  const fieldValueLength = field.value ? field.value.length : 0;
                  return (
                    <div className="width-70">
                      <input
                        id={`${entity}_card_name`}
                        name={field.name}
                        onBlur={(_event) => form.setFieldTouched(field.name, true)}
                        onChange={(event) => {
                          form.setFieldValue(field.name, event.currentTarget.value);
                        }}
                        type="text"
                        value={field.value}
                      />
                      <span
                        className={fieldValueLength > lengthLimit ? 'italic red' : 'italic'}
                        id={`${entity}-character-count`}
                        style={{ width: '25%', position: 'absolute', right: 0, bottom: 12 }}
                      >
                        {`${fieldValueLength} characters`}
                      </span>
                    </div>
                  );
                }}
              </FastField>
            </label>
            <ErrorMessage className="error" component="label" name={`${entity}.card_name`} />
          </div>
        </div>
      )}
    </div>
  );
}

NameFieldsWithLimit.propTypes = {
  dataAnalyticsPrefix: PropTypes.string,
  entity: PropTypes.oneOf(['cardholder']).isRequired,
  lengthLimit: PropTypes.number.isRequired,
  nameExceedsLengthLimit: PropTypes.bool.isRequired,
  labelPrefix: PropTypes.string,
  prefill: PropTypes.bool,
  rowClass: PropTypes.string,
  colClass: PropTypes.string,
};

NameFieldsWithLimit.defaultProps = {
  labelPrefix: null,
  prefill: false,
  rowClass: 'card--form__row',
  colClass: 'card--form__col',
};

export default NameFieldsWithLimit;
