import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import AgingAdultsImage from 'images/referrals/aging-adults.png';
import FamilyTrusteeImage from 'images/referrals/family-trustees.png';
import InRecoveryImage from 'images/referrals/in-recovery.png';
import PeopleWithDisabilitiesImage from 'images/referrals/people-with-disabilities.png';
import theme from 'react/shared/theme/Theme';

const useStyles = makeStyles(() => ({
  section: {
    borderTop: '1px solid rgba(0,0,0,.1)',
    padding: '5rem',
    [theme.breakpoints.down('md')]: {
      padding: '5rem 2rem',
    },
  },
  container: {
    margin: '0 auto',
    maxWidth: '70rem',
  },
  items: {
    marginTop: '20px',
  },
  item: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      flexWrap: 'nowrap',
      textAlign: 'center',
      maxWidth: '530px',
    },
  },
  img: {
    width: '110px',
    height: '110px',
    objectFit: 'contain',
    marginBottom: '1rem',
    borderRadius: '10px',
    [theme.breakpoints.down('md')]: {
      marginBottom: '-1rem',
    },
  },
}));

const cardsContent = [
  {
    image: AgingAdultsImage,
    altText: 'aging adults image',
    title: 'Aging Adults',
    content: 'Set spending rules to help protect your loved ones from getting taken advantage of',
  },
  {
    image: PeopleWithDisabilitiesImage,
    altText: 'people with disabilities image',
    title: 'People with Disabilities',
    content:
      'Help safeguard their independence and protect benefits eligibility by allowing or blocking certain purchases',
  },
  {
    image: FamilyTrusteeImage,
    altText: 'family trustee image',
    title: 'Family Trustees',
    content:
      'Disburse funds from a Special Needs Trust according to trust requirements and help protect benefits eligibility',
  },
  {
    image: InRecoveryImage,
    altText: 'in recovery image',
    title: 'In Recovery',
    content:
      'Customize curfew settings, budgets, and spending rules to help support loved ones on the path to recovery',
  },
];

export default function FamilyCard() {
  const classes = useStyles();
  return (
    <div className={classes.section} data-testid="familyCard">
      <div className={classes.container}>
        <Grid className={classes.items} container justifyContent="center" spacing={10}>
          {cardsContent.map((item, index) => (
            <Grid className={classes.item} container item key={index} md={6} sm={12} spacing={6}>
              <Grid item>
                <img alt={item.altText} className={classes.img} src={item.image} />
              </Grid>
              <Grid item sm xs={12}>
                <Typography component="div" gutterBottom variant="h5">
                  {item.title}
                </Typography>
                <Typography variant="body1">{item.content}</Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
}
