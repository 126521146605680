import PropTypes from 'prop-types';
import React from 'react';
import {
  addError,
  removeError,
  checkBlank,
  checkErrored,
  clearBlankError,
  getPayeeErrorInitialState,
  listErrors,
  updateParentErrorState,
} from 'react/jaffe/components/forms/PayeeErrors';
import bindAll from 'react/shared/utils/bind_all';

export default class PayeeBankRoutingNumberInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = getPayeeErrorInitialState.bind(this)();

    bindAll(this);
  }

  componentDidUpdate(prevProps, prevState) {
    checkErrored.bind(this)();
    if (prevState.errored !== this.state.errored || !this.state.canUpdateParentErrorState) {
      return updateParentErrorState.bind(this)();
    }
  }

  routingNumberValid(value) {
    let retVal = true;
    if (!this.routingNumLengthValid(value) || /\D/.test(value)) {
      retVal = false;
    }
    let sum = 0;
    let multi = 3;
    const len = value.length;
    let i = 0;
    while (i < len) {
      sum += Number.parseInt(value[i], 10) * multi;
      switch (multi) {
        //cycles between 3 7 and 1 as digit multiplier
        case 3:
          multi = 7;
          break;
        case 7:
          multi = 1;
          break;
        case 1:
          multi = 3;
          break;
      }
      i++;
    }
    if (sum % 10 !== 0 || sum === 0) {
      retVal = false;
    }
    return retVal;
  }

  routingNumLengthValid(value) {
    return value.length === 9;
  }

  checkRoutingNumber(ev) {
    if (this.routingNumberValid(ev.target.value)) {
      return removeError.bind(this)('invalidRoutingNumber');
    }
    return addError.bind(this)({ invalidRoutingNumber: this.props.errorText.invalidRoutingNumber });
  }

  validate(ev) {
    checkBlank.bind(this)(ev);
    return this.checkRoutingNumber(ev);
  }

  render() {
    let inputClassName = 'new-form__data ';
    if (this.state.errored) {
      inputClassName += 'validate-error';
    }

    return (
      <div>
        <div className="new-form__label">
          <label htmlFor={this.props.type}>{this.props.label}</label>
        </div>
        <div className={inputClassName}>
          <input
            className="hide-arrows"
            defaultValue={this.props.defaultValue}
            id={this.props.type}
            maxLength={this.props.maxLength}
            name={this.props.name}
            onBlur={this.validate}
            onChange={clearBlankError.bind(this)}
            type="number"
            value={this.props.value}
          />
          <ul className="new-form__error">{listErrors.bind(this)()}</ul>
        </div>
      </div>
    );
  }
}

PayeeBankRoutingNumberInput.propTypes = {
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  maxLength: PropTypes.number,
  type: PropTypes.string.isRequired,
  errorText: PropTypes.object,
  // These are a hack we needed for removing a mix-in. These props are inspected by a Store that in bound to this component.
  // eslint-disable-next-line react/no-unused-prop-types
  parentError: PropTypes.object,
  // eslint-disable-next-line react/no-unused-prop-types
  addError: PropTypes.func,
  // eslint-disable-next-line react/no-unused-prop-types
  removeError: PropTypes.func,
};

PayeeBankRoutingNumberInput.defaultProps = {
  errorText: {
    cannotBeBlank: ' cannot be blank. ',
    invalidRoutingNumber: 'Please enter a valid routing number',
  },
  maxLength: 50,
};
