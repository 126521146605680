import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import AlertList from 'react/shared/card/components/alerts_tab/AlertList';

export default function CardAlertsTabContainer({ accountId, canEdit }) {
  const classes = classNames({ 'read-only-disabled': !canEdit });

  return (
    <div className={classes} id="alerts">
      <AlertList accountId={accountId} />
    </div>
  );
}

CardAlertsTabContainer.propTypes = {
  accountId: PropTypes.string.isRequired,
  canEdit: PropTypes.bool.isRequired,
};
