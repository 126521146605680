import PropTypes from 'prop-types';
import React from 'react';
import ConfirmationPage from 'react/member/components/dashboard/ConfirmationPage';
import bindAll from 'react/shared/utils/bind_all';

export default class PayeeConfirmationPage extends React.Component {
  static get propTypes() {
    return {
      formData: PropTypes.object.isRequired,
      formSubmitted: PropTypes.bool,
    };
  }

  constructor(props) {
    super(props);
    bindAll(this);
  }

  isDisplayed() {
    let prop;
    if (this.props.formSubmitted === true) {
      prop = 'initial';
    } else {
      prop = 'none';
    }
    return { display: prop };
  }

  render() {
    let inputs;
    const data = this.props.formData;
    if (!data.address_attributes) {
      data.address_attributes = {};
    }
    const name = { labelName: 'Payee Name', inputName: 'name' };
    const needs1099Form = {
      labelName: 'Needs 1099 Form',
      inputName: 'needs1099Form',
      type: 'needs_1099_form',
    };
    const paymentMethod = { labelName: 'Payment Type', inputName: 'payment_method' };
    const doingBusinessAs = { labelName: 'Doing Business As', inputName: 'doingBusinessAs' };
    const taxIdNumber = { labelName: 'Tax Id Number', inputName: 'taxIdNumber' };

    switch (data.payment_method) {
      case 'Check': {
        const addressFormat = ['street1', 'street2', 'city', 'state', 'zip'];
        const address = {
          labelName: 'Address',
          inputName: 'address_attributes',
          addressFormat,
          type: 'address',
        };

        const clientAccountNumber = { labelName: 'Memo', inputName: 'clientAccountNumber' };
        inputs = [name, needs1099Form, address, paymentMethod, clientAccountNumber];
        break;
      }
      case 'External Check': {
        const addressFormat = ['street1', 'street2', 'city', 'state', 'zip'];
        const address = {
          labelName: 'Address',
          inputName: 'address_attributes',
          addressFormat,
          type: 'address',
        };

        const clientAccountNumber = { labelName: 'Memo', inputName: 'clientAccountNumber' };
        const disposition = { labelName: 'Disposition', inputName: 'disposition' };
        const special_instructions = {
          labelName: 'Special Instructions',
          inputName: 'specialInstructions',
        };
        inputs = [
          name,
          needs1099Form,
          address,
          paymentMethod,
          clientAccountNumber,
          disposition,
          special_instructions,
        ];

        break;
      }
      case 'EFT': {
        const routing_number = { labelName: 'Routing Number', inputName: 'bankRoutingNumber' };
        const bank_account_number = {
          labelName: 'Account Number',
          inputName: 'bankAccountNumber',
        };
        const clientAccountNumber = { labelName: 'Memo', inputName: 'clientAccountNumber' };
        inputs = [
          name,
          needs1099Form,
          paymentMethod,
          routing_number,
          bank_account_number,
          clientAccountNumber,
        ];
        break;
      }
      case 'Wire': {
        const routing_number = {
          labelName: 'Receiving Bank Routing Number',
          inputName: 'bankRoutingNumber',
        };
        const bank_account_number = {
          labelName: 'Receiving Bank Account Number',
          inputName: 'bankAccountNumber',
        };
        const addressFormat = ['street1', 'street2', 'city', 'state', 'zip'];
        const address = {
          labelName: 'Address',
          inputName: 'address_attributes',
          addressFormat,
          type: 'address',
        };
        const swift_code = { labelName: 'SWIFT Code', inputName: 'swiftCode' };
        const special_instructions = {
          labelName: 'Wire Instructions',
          inputName: 'specialInstructions',
        };
        inputs = [
          name,
          needs1099Form,
          paymentMethod,
          routing_number,
          bank_account_number,
          address,
          swift_code,
          special_instructions,
        ];
        break;
      }
      default: {
        const clientAccountNumber = { labelName: 'Memo', inputName: 'clientAccountNumber' };
        inputs = [name, needs1099Form, paymentMethod, clientAccountNumber];
      }
    }

    if (this.props.formData.needs1099Form) {
      const taxFields = [doingBusinessAs, taxIdNumber];
      inputs?.splice(2, 0, ...taxFields);
    }

    return (
      <div style={this.isDisplayed()}>
        <ConfirmationPage
          formData={data}
          formSubmitted={this.props.formSubmitted}
          inputs={inputs}
          model="payee"
        />
      </div>
    );
  }
}
