import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import TrueLinkBanner from 'react/shared/components/true_link/lab/TrueLinkBanner';
import TrueLinkMaskedTextInput from 'react/shared/components/true_link/lab/form/TrueLinkMaskedTextInput';
import TrueLinkTooltip from 'react/shared/components/true_link/main/TrueLinkTooltip';
import TrueLinkRadios from 'react/shared/components/true_link/main/form/TrueLinkRadios';
import TrueLinkSelect from 'react/shared/components/true_link/main/form/TrueLinkSelect';
import TrueLinkTextInput from 'react/shared/components/true_link/main/form/TrueLinkTextInput';
import paymentMethodOptionsShape from 'react/shared/shapes/payment_method_options_shape';
import PALETTE from 'react/shared/theme/palette';
import TlFieldTransformers from 'react/shared/utils/tl_field_transformers';
const { formatPhoneNumber } = TlFieldTransformers;

const useStyles = makeStyles({
  cardPaymentForm: {
    '& .MuiFormGroup-root': {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      gap: '10px',
    },
    '& .MuiFormControlLabel-label.MuiTypography-body1': {
      fontSize: '18px !important',
    },
    '& label': {
      border: 'none',
    },
    '& accent': {
      color: '#0077CC',
    },
    '& input[type="radio"].card--form__radio__input': {
      marginTop: '0',
      marginRight: '5px',
    },
    '& input[type="text"].other-input': {
      margin: '2px 0 0',
      width: '100%',
      height: '34px',
      padding: '4px 7px 4px 8px',
    },
    '& input[type="number"]': {
      border: 'none',
      margin: '0',

      '&:focus': {
        boxShadow: 'none',
      },
    },
  },

  title: {
    fontSize: '20px',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  formSection: {
    paddingTop: '10px',
    marginBottom: '20px',
  },
  error: {
    color: PALETTE.red,
    marginTop: '-15px',
    paddingBottom: '15px',
  },
  showPhoneInput: {
    visibility: 'visible',
    height: 'auto',
    display: 'flex',
    alignItems: 'start',
    gap: '10px',
    marginTop: '10px',
  },
  hidePhoneInput: {
    visibility: 'hidden',
    height: '0',
  },
});

export default function CardPaymentFormFields({
  allowAutomatedPayment,
  regularShipping,
  expeditedShipping,
  cardholderMobile,
  adminMobile,
  shippingFee,
  allowManualAutomatedPayment,
  waiveFeeField,
  setFieldTouched,
  setFieldValue,
  handleChange,
  paymentMethods,
  values,
}) {
  const classes = useStyles();

  const [cardholderMobileNumber, setCardholderMobileNumber] = useState(cardholderMobile);
  const availablePaymentMethods = paymentMethods?.available_payment_methods || [];
  const availableBankAccounts = paymentMethods?.available_bank_accounts || [];
  const helpUrl = 'https://help.truelinkfinancial.com/article/339-transfer-funds-to-a-card';

  const tooltipContent = (
    <Typography variant="body2">
      The fee covers the cost of expedited printing and shipping
    </Typography>
  );

  const shippingMethodOptions = [
    {
      id: 'expedite_shipping_no',
      label: `Standard (Free) Arrives in ${regularShipping} business days`,
      value: 'no',
    },
    {
      id: 'expedite_shipping_yes',
      label: (
        <TrueLinkTooltip placement={'top'} tooltipContent={tooltipContent}>
          <span
            data-testid="expedite_charge_shipping_yes"
            id={
              allowAutomatedPayment
                ? 'expedite_automated_charge_shipping_yes'
                : 'expedite_manual_charge_shipping_yes'
            }
          >
            Expedited <b className="link-color">$({shippingFee ? shippingFee : '30.00'})</b> Arrives
            in {expeditedShipping} business days
          </span>
        </TrueLinkTooltip>
      ),
      value: 'yes',
    },
  ];

  const waiveFeeReasonOptions = [
    {
      id: 'expiring_card',
      label: 'Expiring card',
      value: 'expiring',
    },
    {
      id: `compromised_card`,
      label: 'Compromised Card',
      value: 'compromised',
    },
    {
      id: `shipment_error`,
      label: `Card shipment error/card did not arrive`,
      value: 'shipment_error',
    },
    {
      id: 'courtesy',
      label: 'Courtesy to customer',
      value: 'courtesy',
    },
    {
      id: 'default',
      label: `Waived by default`,
      value: 'default',
    },
    {
      id: 'other',
      label: 'Other',
      value: 'other',
    },
  ];

  let paymentMethodOptions = [
    {
      id: 'shipping_payment_method_card',
      label: 'Card',
      value: 'card',
    },
    {
      id: 'shipping_payment_method_ach',
      label: 'ACH',
      value: 'ach',
    },
  ];

  paymentMethodOptions = paymentMethodOptions.filter(
    (option) => option.value == 'card' || availablePaymentMethods.includes(option.value),
  );

  let mobileNumberOptions = [
    {
      id: 'primary_admin_phone_number',
      label: `Card primary admin phone number: ${formatPhoneNumber(adminMobile)}`,
      value: 'admin_phone_number',
    },
    {
      id: 'cardholder_phone_number',
      label: `Cardholder phone number: ${formatPhoneNumber(cardholderMobileNumber)}`,
      value: 'cardholder_phone_number',
    },
    {
      id: 'other_phone_number',
      label: 'Other',
      value: 'other',
    },
  ];

  const cardPaymentBanner = (
    <>
      The fee will be removed from the card balance when the card order is processed. This may
      result in the card balance going negative, which is ok! Simply schedule a transfer to cover
      the charge. For more information about funding{' '}
      <a href={helpUrl} rel="noreferrer" target="_blank">
        click here
      </a>
      .
    </>
  );

  const cardPaymentHelperText = <TrueLinkBanner text={cardPaymentBanner} variant="info" />;

  const handleOpen = () => {
    const phoneNumber = $('input[name="cardholder[mobile]"]').val();
    if (phoneNumber) {
      setCardholderMobileNumber(phoneNumber);
    }
  };

  const handleMobileNumberChange = (e, handleChange) => {
    handleChange(e);
    let phoneNumber = '';
    if (e.target.value == 'admin_phone_number') {
      phoneNumber = adminMobile;
    }
    if (e.target.value == 'cardholder_phone_number') {
      phoneNumber = cardholderMobileNumber;
    }
    handleChange({
      target: {
        name: 'card_recipient_phone',
        value: phoneNumber,
      },
    });
  };

  availableBankAccounts.forEach((account) => (account.value = account.id));

  if (!cardholderMobileNumber) {
    mobileNumberOptions = mobileNumberOptions.filter(
      (option) => option.id !== 'cardholder_phone_number',
    );
  }

  const bankAccountSelection = (
    <div className={classes.formSection}>
      <div className={classes.title}>Bank Account</div>
      <TrueLinkSelect
        id="bank-account"
        label={'Select a bank account'}
        name="expedited_shipping_card_fee[bank_account_id]"
        onChange={handleChange}
        options={availableBankAccounts}
        placeholder={'Select a bank account'}
        required
        setFieldTouched={setFieldTouched}
        value={values.expedited_shipping_card_fee['bank_account_id']}
      />
    </div>
  );

  return (
    <div className={classes.cardPaymentForm}>
      <div className={classes.formSection}>
        <div className={classes.title}>Shipping method</div>
        <TrueLinkRadios
          ariaLabel="Which shipping method would you prefer"
          name="expedite_shipping"
          onChange={handleChange}
          options={shippingMethodOptions}
          size="small"
          value={values.expedite_shipping}
        />
      </div>
      {values.expedite_shipping === 'yes' && (
        <>
          {waiveFeeField && !allowAutomatedPayment && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={!values.expedite_charged}
                  color={'primary'}
                  id="expedite_charged_checkbox"
                  name={'expedite_charged'}
                  onChange={() => setFieldValue('expedite_charged', !values.expedite_charged)}
                  size="small"
                  value={!values.expedite_charged}
                />
              }
              label="Waive fee"
            />
          )}

          {waiveFeeField && allowAutomatedPayment && (
            <>
              {allowManualAutomatedPayment && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.manual_expedite_charged}
                      color={'primary'}
                      name="manual_expedite_charged"
                      onChange={handleChange}
                      size="small"
                      value={values.manual_expedite_charged}
                    />
                  }
                  label="Charge fee manually (Create ticket for Ops)"
                />
              )}
              {!values.manual_expedite_charged && (
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={!values.expedite_charged}
                            color={'primary'}
                            name={'expedite_charged'}
                            onChange={() =>
                              setFieldValue('expedite_charged', !values.expedite_charged)
                            }
                            size="small"
                            value={!values.expedite_charged}
                          />
                        }
                        label="Waive fee"
                      />
                    </Grid>
                    {!values.expedite_charged && (
                      <Grid container item spacing={2} xs={9}>
                        <Grid container item spacing={2}>
                          <Grid item xs={4}>
                            <p style={{ marginTop: '10px' }}>Waive reason (required)</p>
                          </Grid>
                          <Grid item xs={8}>
                            <TrueLinkSelect
                              id="waived-reason"
                              label={'Select reason'}
                              name="expedited_shipping_card_fee[waived_reason]"
                              onChange={handleChange}
                              options={waiveFeeReasonOptions}
                              placeholder={'Select reason'}
                              required
                              setFieldTouched={setFieldTouched}
                              value={values.expedited_shipping_card_fee['waived_reason']}
                            />
                          </Grid>
                        </Grid>
                        {values.expedited_shipping_card_fee['waived_reason'] == 'other' && (
                          <Grid container item spacing={2}>
                            <Grid item xs={4}>
                              <p style={{ marginTop: '10px' }}>Description:</p>
                            </Grid>
                            <Grid item xs={8}>
                              <TrueLinkTextInput
                                className="other-input"
                                id="waived_description"
                                name="expedited_shipping_card_fee[waived_description]"
                                onChange={handleChange}
                                required={
                                  values.expedited_shipping_card_fee['waived_reason'] === 'other'
                                }
                                setFieldTouched={setFieldTouched}
                                value={values.expedited_shipping_card_fee['waived_description']}
                              />
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    )}
                  </Grid>
                  {values.expedite_charged && (
                    <div className={classes.formSection}>
                      <div className={classes.title}>Fee Amount</div>
                      <div style={{ width: '90px' }}>
                        <TrueLinkTextInput
                          disabled={!values.expedite_charged}
                          id="overwritte-fee"
                          inputProps={{
                            maxLength: 5,
                          }}
                          name="expedited_shipping_card_fee[amount]"
                          onChange={handleChange}
                          placeholder="0.00"
                          setFieldTouched={setFieldTouched}
                          startAdornment={<span style={{ paddingLeft: '5px' }}>$</span>}
                          type="number"
                          value={values.expedited_shipping_card_fee['amount']}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          )}
          {allowAutomatedPayment && !values.manual_expedite_charged && values.expedite_charged && (
            <div className={classes.formSection}>
              <div className={classes.title}>Payment method</div>
              <TrueLinkRadios
                ariaLabel="Which payment method would you prefer"
                name="expedited_shipping_card_fee[payment_method]"
                onChange={handleChange}
                options={paymentMethodOptions}
                size="small"
                value={values.expedited_shipping_card_fee['payment_method']}
              />
              {values.expedited_shipping_card_fee['payment_method'] === 'card' &&
                cardPaymentHelperText}
              {values.expedited_shipping_card_fee['payment_method'] === 'ach' &&
                bankAccountSelection}
            </div>
          )}

          <div className={classes.formSection}>
            <div className={classes.title}>Recipients mobile number</div>
            <p>Required for expedited shipping to ensure the courier can contact them</p>
            <TrueLinkSelect
              id="mobile-number"
              label={'Select recipient’s phone number'}
              name="phone_type"
              onChange={(e) => handleMobileNumberChange(e, handleChange)}
              onOpen={handleOpen}
              options={mobileNumberOptions}
              placeholder={'Select recipient’s phone number'}
              required
              setFieldTouched={setFieldTouched}
              value={values.phone_type}
            />

            <section
              className={
                values.phone_type === 'other' ? classes.showPhoneInput : classes.hidePhoneInput
              }
            >
              <p style={{ marginTop: '5px' }}>Phone number</p>
              <TrueLinkMaskedTextInput
                className="other-input"
                mask="(999) 999-9999"
                name="card_recipient_phone"
                onChange={handleChange}
                placeholder="(xxx) xxx-xxxx"
                required={values.expedite_shipping === 'yes' && values.phone_type === 'other'}
                setFieldTouched={setFieldTouched}
                value={values.card_recipient_phone}
              />
            </section>
          </div>
        </>
      )}
    </div>
  );
}

CardPaymentFormFields.propTypes = {
  regularShipping: PropTypes.any,
  expeditedShipping: PropTypes.any,
  shippingFee: PropTypes.any,
  cardholderMobile: PropTypes.any,
  adminMobile: PropTypes.any,
  allowAutomatedPayment: PropTypes.bool,
  waiveFeeField: PropTypes.bool,
  allowManualAutomatedPayment: PropTypes.bool,
  handleChange: PropTypes.func,
  setFieldTouched: PropTypes.func,
  setFieldValue: PropTypes.func,
  values: PropTypes.object,
  paymentMethods: paymentMethodOptionsShape,
};
