import PropTypes from 'prop-types';
import React from 'react';
import FormSelectField from 'react/shared/components/forms/form_select_field';

export default class FormSelectSearchableField extends React.Component {
  static get propTypes() {
    return {
      field: PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }),
      form: PropTypes.shape({
        setFieldValue: PropTypes.func,
      }),
      // eslint-disable-next-line react/no-unused-prop-types
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
        }),
      ),
      reactSelectProps: PropTypes.object,
    };
  }

  render() {
    const reactSelectProps = {
      reactSelectProps: {
        className: 'tl-select tl-select-searchable',
        isSearchable: true,
        placeholder: 'Search...',
        ...this.props.reactSelectProps,
      },
    };
    return <FormSelectField {...{ ...this.props, ...reactSelectProps }} />;
  }
}
