import PropTypes from 'prop-types';
import React from 'react';

export default function QuestionnaireRadioOption({ labelText, inputProps }) {
  return (
    <div className="questionnaire-radio__able questionnaire-radio--large__able questionnaire-radio--full-width__able">
      <input className="questionnaire-radio__input__able" type="radio" {...inputProps} />
      <label
        className="questionnaire-radio__label__able"
        htmlFor={inputProps.id}
        style={{ width: '550px' }}
      >
        <span className="questionnaire-radio__checkbox-container__able" />
        {labelText}
      </label>
    </div>
  );
}

QuestionnaireRadioOption.propTypes = {
  inputProps: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    value: PropTypes.string,
  }),
  labelText: PropTypes.string.isRequired,
};
