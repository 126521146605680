import PropTypes from 'prop-types';
import React from 'react';
import CardAccountTabContainer from 'react/member/card/components/account_tab/CardAccountTabContainer';
import FamilyFunderDischarge from 'react/member/card/components/account_tab/family_funding/FamilyFunderDischarge';
import FamilyFunders from 'react/member/card/components/account_tab/family_funding/FamilyFunders';
import AbleStableFundingInfo from 'react/member/card/components/account_tab/funding_info/AbleStableFundingInfo';
import DirectDepositInfo from 'react/member/card/components/account_tab/funding_info/DirectDepositInfo';
import FeeSetting from 'react/member/card/components/account_tab/funding_info/FeeSetting';

export default function AccountInfoTabContainer({
  ableProgram,
  accountFeeSettings,
  accountId,
  bankAccountNumber,
  cardActivated,
  cardBalance,
  cardholderName,
  cardLast4,
  cardOpen,
  cardPresent,
  cardProgramPresent,
  dischargeFamilyFunderAllowed,
  familyFunders,
  familyFundingAllowed,
  filteredBankAccounts,
  isNextStepProgram,
  nonDeletedCardExists,
  notAbleActProgram,
  pseudoDda,
  routingNumber,
  stableProgram,
  subOrgId,
}) {
  return (
    <div id="account_settings">
      {familyFundingAllowed && (
        <>
          <FamilyFunders cardholderName={cardholderName} />
          {dischargeFamilyFunderAllowed && (
            <FamilyFunderDischarge
              cardLast4={cardLast4}
              cardholderName={cardholderName}
              familyFunders={familyFunders}
              subOrgId={subOrgId}
            />
          )}
        </>
      )}
      <CardAccountTabContainer accountId={accountId} />
      {notAbleActProgram && (
        <>
          <DirectDepositInfo
            bankAccountNumber={bankAccountNumber}
            cardActivated={cardActivated}
            cardProgramPresent={cardProgramPresent}
            cardholderName={cardholderName}
            isNextStepProgram={isNextStepProgram}
            nonDeletedCardExists={nonDeletedCardExists}
            routingNumber={routingNumber}
          />
          <FeeSetting
            accountFeeSettings={accountFeeSettings}
            cardBalance={cardBalance}
            cardPresent={cardPresent}
            filteredBankAccounts={filteredBankAccounts}
          />
        </>
      )}

      {(ableProgram || stableProgram) && (
        <AbleStableFundingInfo
          ableProgram={ableProgram}
          cardOpen={cardOpen}
          pseudoDda={pseudoDda}
          stableProgram={stableProgram}
        />
      )}
    </div>
  );
}

AccountInfoTabContainer.propTypes = {
  ableProgram: PropTypes.bool,
  accountFeeSettings: PropTypes.any,
  accountId: PropTypes.string.isRequired,
  bankAccountNumber: PropTypes.string,
  cardActivated: PropTypes.bool,
  cardBalance: PropTypes.string,
  cardholderName: PropTypes.string,
  cardLast4: PropTypes.string,
  cardOpen: PropTypes.bool,
  cardPresent: PropTypes.bool,
  cardProgramPresent: PropTypes.bool,
  dischargeFamilyFunderAllowed: PropTypes.bool,
  familyFunders: PropTypes.array,
  familyFundingAllowed: PropTypes.bool,
  filteredBankAccounts: PropTypes.any,
  isNextStepProgram: PropTypes.bool,
  nonDeletedCardExists: PropTypes.bool,
  notAbleActProgram: PropTypes.bool,
  pseudoDda: PropTypes.string,
  routingNumber: PropTypes.string,
  stableProgram: PropTypes.bool,
  subOrgId: PropTypes.number,
};
