import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles({
  cellWrapper: {
    display: 'flex',
  },
  cellPrimaryArea: {
    flexGrow: 1,
  },
});

export default function EditableCell({
  value: { key, memo: initialValue, updateWealthAccountEvent },
  row: { index },
}) {
  const [value, setValue] = useState(initialValue);
  const [editing, setEditing] = useState(false);
  const saveButton = React.useRef();

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const handleCancel = (event) => {
    if (event.relatedTarget === saveButton.current) {
      return;
    }

    setEditing(false);
    setValue(initialValue);
  };

  const handleSubmit = () => {
    setEditing(false);
    updateWealthAccountEvent(index, key, value);
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const classes = useStyles();

  if (editing) {
    return (
      <span className={classes.cellWrapper}>
        <input
          aria-label="Editable field"
          className={classes.cellPrimaryArea}
          maxLength={255}
          onBlur={handleCancel}
          onChange={onChange}
          value={value}
        />
        <button className="btn btn-link" onClick={handleSubmit} ref={saveButton} type="submit">
          Save
        </button>
      </span>
    );
  }
  return (
    <span className={classes.cellWrapper}>
      <span className={classes.cellPrimaryArea}>{value}</span>
      <button className="btn btn-link" onClick={() => setEditing(true)} type="button">
        Edit
      </button>
    </span>
  );
}

EditableCell.propTypes = {
  value: PropTypes.shape({
    key: PropTypes.string.isRequired,
    memo: PropTypes.string,
    updateWealthAccountEvent: PropTypes.func.isRequired,
  }).isRequired,
  row: PropTypes.shape({ index: PropTypes.number.isRequired }).isRequired,
};
