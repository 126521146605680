import PropTypes from 'prop-types';
import React from 'react';
import { isCash } from './SecurityUtils';
import { asMoney } from 'react/shared/utils/Money';

export default function SecurityRow({ position }) {
  if (isCash(position)) {
    return (
      <tr>
        <td style={{ paddingLeft: 0 }}>INVESTMENT CASH</td>
        <td />
        <td />
        <td className="table-cell-right" />
        <td className="table-cell-right">{asMoney(position?.total_value ?? '0.00')}</td>
        <td className="table-cell-right" />
        <td className="table-cell-right" />
      </tr>
    );
  } else if (position.name === 'Total Assets') {
    return (
      <tr>
        <td style={{ paddingLeft: 0 }}>{position.name}</td>
        <td />
        <td />
        <td className="table-cell-right" />
        <td className="table-cell-right">{asMoney(position?.total_value ?? '0.00')}</td>
        <td className="table-cell-right" data-testid="unrealized-gain-loss">
          {asMoney(position?.unrealized_gain_loss ?? '0.00')}
        </td>
        <td className="table-cell-right" data-testid="cost-basis">
          {asMoney(position?.cost_basis ?? '0.00')}
        </td>
      </tr>
    );
  }
  return (
    <tr>
      <td style={{ paddingLeft: 0 }}>{position.name}</td>
      <td>{position.symbol}</td>
      <td>{position.units}</td>
      <td className="table-cell-right">{asMoney(position?.unit_value ?? '0.00')}</td>
      <td className="table-cell-right">{asMoney(position?.total_value ?? '0.00')}</td>
      <td className="table-cell-right" data-testid="unrealized-gain-loss">
        {asMoney(position?.unrealized_gain_loss ?? '0.00')}
      </td>
      <td className="table-cell-right" data-testid="cost-basis">
        {asMoney(position?.cost_basis ?? '0.00')}
      </td>
    </tr>
  );
}

SecurityRow.propTypes = {
  position: PropTypes.shape({
    cost_basis: PropTypes.string,
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    position_type: PropTypes.string,
    symbol: PropTypes.string,
    total_value: PropTypes.string,
    unit_value: PropTypes.string,
    units: PropTypes.string,
    unrealized_gain_loss: PropTypes.string,
    value_currency: PropTypes.string,
    wealth_account_id: PropTypes.number,
    wealth_account_name: PropTypes.string,
  }).isRequired,
};
