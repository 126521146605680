import PropTypes from 'prop-types';
import React from 'react';
import NavigationButtons from './navigation_buttons';
import { States } from './states';
import bindAll from 'react/shared/utils/bind_all';

export default class Personal extends React.Component {
  constructor(props) {
    super(props);

    const { cardFor, cardForOther } = this.props;
    this.state = {
      selectedOption: cardFor,
      textOption: cardForOther,
      error: undefined,
      textOptionError: undefined,
    };

    bindAll(this);
  }

  transitionTo(ev) {
    ev.preventDefault();
    const { selectedOption, textOption } = this.state;

    if (!selectedOption) {
      this.props.ensureOption(this, 'Please select an option.');
    } else if (selectedOption == 'other' && !textOption) {
      this.setState({ textOptionError: 'This field is required.' });
    } else {
      this.props.transition(States.CONSUMER.SECOND, {
        cardFor: selectedOption,
        cardForOther: textOption,
      });
    }
  }

  transitionBackTo(ev) {
    ev.preventDefault();
    this.props.transition(States.INIT);
  }

  handleOptionChange(ev) {
    const { target } = ev;

    if (target.value !== 'other') {
      this.setState({
        selectedOption: target.value,
        textOption: '',
        error: undefined,
        textOptionError: undefined,
      });
    } else {
      this.setState({ selectedOption: target.value, error: undefined });
    }
  }

  handleTextChange(ev) {
    this.setState({ textOption: ev.target.value, textOptionError: undefined });
  }

  renderRadioButton(selectedOption, label, text) {
    const id = `${label}-radio`;

    return (
      <div className="questionnaire-radio">
        <input
          checked={selectedOption == label}
          className="questionnaire-radio__input"
          id={id}
          onChange={this.handleOptionChange}
          type="radio"
          value={label}
        />
        <label className="questionnaire-radio__label" htmlFor={id}>
          <span className="questionnaire-radio__checkbox-container" />
          <span className="questionnaire-radio__text">{text}</span>
        </label>
      </div>
    );
  }

  render() {
    const { selectedOption, textOption, error, textOptionError } = this.state;

    return (
      <div>
        <div className="questionnaire-form-item questionnaire-form-item__small">
          <form onSubmit={this.transitionTo}>
            <div className="questionnaire-form-item-content">
              <div className="questionnaire-form-item-question">
                <h2>Who will be using the card?</h2>
              </div>
              <div className="hide-on-mobile">{error && <p className="form-error">{error}</p>}</div>
              <div className="questionnaire-radio-wrapper questionnaire-radio-button-list--personal">
                {this.renderRadioButton(selectedOption, 'elder', 'An elder')}
                {this.renderRadioButton(selectedOption, 'disability', 'A person with disabilities')}
                {this.renderRadioButton(selectedOption, 'recovery', 'A person in recovery')}
                {this.renderRadioButton(selectedOption, 'risk', 'A person who is at risk')}
                {this.renderRadioButton(selectedOption, 'myself', 'Myself')}

                <div className="questionnaire-radio">
                  <input
                    checked={selectedOption == 'other'}
                    className="questionnaire-radio__input"
                    id="other-radio"
                    onChange={this.handleOptionChange}
                    type="radio"
                    value="other"
                  />
                  <label className="questionnaire-radio__label" htmlFor="other-radio">
                    <span className="questionnaire-radio__checkbox-container" />
                    <span className="questionnaire-radio__text">Other:</span>
                    <br />
                    <input
                      className="questionnaire-radio__label-input"
                      id="other-text"
                      onChange={this.handleTextChange}
                      type="text"
                      value={textOption}
                    />
                    {textOptionError && <p className="form-error">{textOptionError}</p>}
                  </label>
                </div>
              </div>
            </div>
            <div className="show-on-mobile">{error && <p className="form-error">{error}</p>}</div>
            <NavigationButtons transitionBackTo={this.transitionBackTo} />
          </form>
        </div>
      </div>
    );
  }
}

Personal.propTypes = {
  cardFor: PropTypes.string,
  cardForOther: PropTypes.string,
  ensureOption: PropTypes.func.isRequired,
  transition: PropTypes.func.isRequired,
};
