import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import childrenShape from 'react/shared/shapes/children';
import bindAll from 'react/shared/utils/bind_all';

export default class InfoTooltip extends React.Component {
  constructor(props) {
    super(props);
    bindAll(this);
  }

  componentDidMount() {
    const element = $(ReactDOM.findDOMNode(this._infoRef));
    element.tooltip();

    if (this.props.showOnInit) {
      element.tooltip('show');
    }
  }

  noopOnClick(e) {
    if (this.props.allowClickThrough) return;
    e.preventDefault();
  }

  setRef(instance) {
    this._infoRef = instance;
    this.props.setRef && this.props.setRef(instance);
  }

  template() {
    const { tooltipStyle, tooltipClass, template } = this.props;
    if (template) return template;
    if (!tooltipStyle && !tooltipClass) return;

    return `
      <div class="tooltip ${tooltipClass}" role="tooltip" style="${tooltipStyle}">
        <div class="tooltip-arrow"></div>
        <div class="tooltip-inner"></div>
      </div>
    `;
  }

  render() {
    // prettier-ignore
    const { id, children, html, delay, containerClass, iconClass, placement, tooltipText, trigger, color, fontSize } = this.props;

    if (!tooltipText) return null;

    const passDownProps = {
      id,
      ref: this.setRef,
      onClick: this.noopOnClick,
      href: '#',
      role: 'tooltip',
      'aria-label': 'tooltip',
      'data-html': html,
      'data-toggle': 'tooltip',
      'data-placement': placement,
      'data-original-title': tooltipText,
      'data-template': this.template(),
      'data-delay': delay,
      'data-trigger': trigger,
    };

    if (children) {
      return (
        <span {...passDownProps} className={containerClass}>
          {children}
        </span>
      );
    }
    return (
      <i
        {...passDownProps}
        className={iconClass}
        style={{ color: `${color}`, fontSize: `${fontSize}` }}
      />
    );
  }
}

InfoTooltip.propTypes = {
  tooltipText: PropTypes.string.isRequired,
  id: PropTypes.string,
  placement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
  containerClass: PropTypes.string,
  iconClass: PropTypes.string,
  html: PropTypes.bool,
  template: PropTypes.string,
  tooltipClass: PropTypes.string, // will be overridden by template if present
  tooltipStyle: PropTypes.string, // will be overridden by template if present
  children: childrenShape,
  showOnInit: PropTypes.bool,
  setRef: PropTypes.func,
  allowClickThrough: PropTypes.bool,
  delay: PropTypes.string,
  color: PropTypes.string,
  fontSize: PropTypes.string,
  trigger: PropTypes.oneOf(['hover focus', 'hover', 'focus', 'click', 'manual']),
};

InfoTooltip.defaultProps = {
  placement: 'top',
  iconClass: 'info-circle',
  html: false,
  allowClickThrough: false,
  trigger: 'hover focus',
};
