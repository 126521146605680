import PropTypes from 'prop-types';
import React from 'react';
import ConnectionStore from 'react/member/stores/ConnectionStore';
import Actions from 'react/shared/actions/actions';
import bindAll from 'react/shared/utils/bind_all';

export default class DashboardTrustBeneficiaryProfileConnectionRelationship extends React.Component {
  constructor(props) {
    super(props);

    this.state = { relationships: [] };

    bindAll(this);
  }

  componentDidMount() {
    Actions.fetchRelationshipTypes(this.props.trustBeneficiarySlug);
    ConnectionStore.on('connections.fetchRelationshipTypes', this.connectionTypesFetched);
  }

  componentWillUnmount() {
    ConnectionStore.off('connections.fetchRelationshipTypes', this.connectionTypesFetched);
  }

  connectionTypesFetched(fetchedRelationships) {
    let relationships = [''];
    if (this.props.type === 'socialRelationship') {
      relationships = relationships.concat(fetchedRelationships.social);
    } else {
      relationships = relationships.concat(fetchedRelationships.professional);
    }

    this.setState({ relationships });
  }

  render() {
    const { type, id, handleChange, value, disabled } = this.props;

    const name =
      type === 'socialRelationship'
        ? 'connection_relationship.social'
        : 'connection_relationship.professional';
    return (
      <select
        disabled={disabled}
        id={id}
        name={name}
        onChange={(e) => handleChange(e)}
        value={value}
      >
        {this.state.relationships.map((rel) => {
          const label = rel === '' ? '[none]' : rel;
          return (
            <option key={rel} value={rel}>
              {label}
            </option>
          );
        })}
      </select>
    );
  }
}

DashboardTrustBeneficiaryProfileConnectionRelationship.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.string,
  trustBeneficiarySlug: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool,
};
