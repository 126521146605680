import PropTypes from 'prop-types';
import React from 'react';
import tlFieldTransformers from 'react/shared/utils/tl_field_transformers';

export default function WmBalances({ balanceInfo }) {
  return (
    <div className="trust-balance-wrapper">
      <div className="trust-balance-wrapper__top">
        <span className="trust-balance-label">EFFECTIVE BALANCE:</span>{' '}
        {tlFieldTransformers.formatMoney(balanceInfo.effective_balance)}
      </div>
      <div className="trust-balance-wrapper__bottom">
        <span className="trust-balance-label">TOTAL BALANCE:</span>{' '}
        {tlFieldTransformers.formatMoney(balanceInfo.current_balance)}
      </div>
    </div>
  );
}

WmBalances.propTypes = {
  balanceInfo: PropTypes.object.isRequired,
};
