import PropTypes from 'prop-types';
import React, { useEffect, useState, useCallback } from 'react';
import TransfersTable from 'react/member/card/components/funding_tab/transfers_table/TransfersTable';
import TransfersTableHeader from 'react/member/card/components/funding_tab/transfers_table/TransfersTableHeader';
import TransferOptionsContainer from 'react/member/card/components/funding_tab/transfers_table/action_cells/transfer_options/TransferOptionsContainer';
import { dashboardAccountShape } from 'react/member/card/shapes/AccountShape';
import DashboardTransferStore from 'react/member/stores/DashboardTransferStore';

export default function TransfersInTheNextSevenDaysSection({
  account,
  cardLast4,
  cardStatus,
  isViewOnly,
}) {
  const [transfers, setTransfers] = useState([]);

  const makeOptionsMenu = useCallback(
    (transfer) => {
      const {
        cardholderName,
        effectivelyFullyFunded,
        customerInstantFunding,
        fullyFunded,
        fundingType,
      } = account.meta;
      return (
        <TransferOptionsContainer
          cardLast4={cardLast4}
          cardStatus={cardStatus}
          cardholderName={cardholderName}
          emergencyFundingEnabled={customerInstantFunding}
          fundingType={fundingType}
          isEffectivelyFullyFunded={effectivelyFullyFunded}
          isFullyFunded={fullyFunded}
          isViewOnly={isViewOnly}
          transfer={transfer}
        />
      );
    },
    [account, cardLast4, cardStatus, isViewOnly],
  );

  useEffect(() => {
    const handleSetTransfers = () => {
      setTransfers(DashboardTransferStore.transfersInTheNextSevenDays());
    };
    DashboardTransferStore.on('transfers.scheduled.fetch', handleSetTransfers);
    return function cleanup() {
      DashboardTransferStore.off('transfers.scheduled.fetch', handleSetTransfers);
    };
  }, []);

  return (
    <div>
      <TransfersTableHeader
        account={account}
        enableFundsAvailableModal
        title="Transfers in the next 7 days"
      />
      <TransfersTable
        id="transfers_in_the_next_seven_days_table"
        isViewOnly={isViewOnly}
        items={transfers}
        renderActionCell={makeOptionsMenu}
        zeroStateText="You have no transfers scheduled in the next 7 days"
      />
    </div>
  );
}

TransfersInTheNextSevenDaysSection.propTypes = {
  account: dashboardAccountShape.isRequired,
  cardLast4: PropTypes.string,
  cardStatus: PropTypes.string,
  isViewOnly: PropTypes.bool.isRequired,
};
