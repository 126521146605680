import React from 'react';

export default function DirectDepositInfoBanner(_props) {
  const handleClick = () => $('button#account_info_tab_link').trigger('click');
  return (
    <div className="info-box" id="direct-deposit-info-banner">
      Would you like to fund this card with direct deposit? Click{' '}
      <a href="#account_info_tab_link" onClick={handleClick}>
        here
      </a>{' '}
      for direct deposit information
    </div>
  );
}
