import Cookies from 'js-cookie';
import React from 'react';
import _contains from 'underscore/modules/contains';
import _extend from 'underscore/modules/extend';
import Duties from './duties';
import Personal from './personal';
import Profession from './profession';
import QuestionnaireEntry from './questionnaire_entry';
import { States } from './states';
import Trustee from './trustee';
import SignupFlowMobileTemplate from 'react/member/components/signups/signup_flow_mobile_template';
import bindAll from 'react/shared/utils/bind_all';

export default class SignUpQuestionnaire extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      current: States.INIT,
      orgType: undefined,
      cardFor: undefined,
      cardForOther: '',
      trustee: undefined,
      profession: undefined,
      otherProfession: '',
      duties: [],
      otherDuty: '',
    };

    bindAll(this);
  }

  transition(to, payload) {
    const newState = _extend({ current: to }, payload);

    const shouldMergeAndTransition =
      this.completedQuestionnaire(newState.current) ||
      this.nonTrusteeOption(newState.cardFor) ||
      this.specificProfession(newState.profession);

    if (shouldMergeAndTransition) {
      this.mergeAndTransition(newState);
    } else {
      this.setState(newState);
      window.scrollTo(0, 0);
    }
  }

  specificProfession(profession) {
    return profession && !_contains(['unknown-profession'], profession);
  }

  completedQuestionnaire(current) {
    return _contains([States.CONSUMER.FINAL, States.PROFESSIONAL.FINAL], current);
  }

  nonTrusteeOption(cardFor) {
    return _contains(['recovery', 'myself'], cardFor);
  }

  mergeAndTransition(state) {
    const userHistoryAndState = this.mergeUserHistoryAndState(state);
    this.transitionToCreateAcount(userHistoryAndState);
  }

  ensureOption(context, errorMsg) {
    context.setState({ error: errorMsg });
  }

  mergeUserHistoryAndState(userState) {
    const mergedState = _extend(this.state, userState);
    switch (mergedState.current) {
      case States.CONSUMER.FINAL:
        return _extend(mergedState, { current: States.CONSUMER.SECOND });
      case States.CONSUMER.SECOND:
        return _extend(mergedState, { current: States.CONSUMER.FIRST });
      case States.PROFESSIONAL.FINAL:
        return _extend(mergedState, { current: States.PROFESSIONAL.SECOND });
      case States.PROFESSIONAL.SECOND:
        return _extend(mergedState, { current: States.PROFESSIONAL.FIRST });
      default:
        return mergedState;
    }
  }

  sendToCookies(state) {
    Cookies.set('questionnaire_data', JSON.stringify(state));
  }

  navigateTo(url) {
    window.location.href = url;
  }

  transitionToCreateAcount(finalState) {
    this.sendToCookies(finalState);
    this.navigateTo('/signups/create_account');
  }

  componentToRender() {
    const props = {
      transition: this.transition,
      ensureOption: this.ensureOption,
      ...this.state,
    };

    switch (this.state.current) {
      case States.INIT:
        return <QuestionnaireEntry {...props} />;
      case States.CONSUMER.FIRST:
        return <Personal {...props} />;
      case States.CONSUMER.SECOND:
        return <Trustee {...props} />;
      case States.PROFESSIONAL.FIRST:
        return <Profession {...props} />;
      case States.PROFESSIONAL.SECOND:
        return <Duties {...props} />;
    }
  }

  getStepText() {
    switch (this.state.current) {
      case States.INIT:
        return 'To best serve you, we need to learn a few things about you and your needs.';
      case States.PROFESSIONAL.FIRST:
        return `
          We know that many of our customers act in more than one professional capacity.
          Don't worry - we'll ask more about your role in the next step.
        `;
    }
  }

  render() {
    return (
      <div>
        <div className="questionnaire-header">
          <h1>Sign up for the True Link Visa&reg; Prepaid Card</h1>
        </div>
        <div className="widescreen-inner-container">
          <div className="questionnaire-container overflow-visible">
            <SignupFlowMobileTemplate
              progressStep={1}
              stepText={this.getStepText()}
              stepTitle="Questions About You"
            >
              {this.componentToRender()}
            </SignupFlowMobileTemplate>
          </div>
        </div>
      </div>
    );
  }
}
