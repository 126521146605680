import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import TrueLinkLink from 'react/shared/components/true_link/main/TrueLinkLink';
import TrueLinkCheckboxes from 'react/shared/components/true_link/main/form/TrueLinkCheckboxes';
import theme from 'react/shared/theme/Theme';
import ErrorText from 'react/signups/card/common/ErrorText';

const useStyles = makeStyles({
  agreementCheckbox: {
    '& label': {
      border: 0,
      alignItems: 'start',
      '& p': {
        paddingTop: '5px',
      },
    },
  },
  actionsBtns: {
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  submitBtn: {
    width: 'fit-content',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
});

export default function CardActivationAgreementForm({
  isLoading,
  onSubmit,
  onClose,
  setFieldValue,
  values,
}) {
  const classes = useStyles();
  const handleAgreementCheck = (event) => {
    if (event.target.checked) {
      setFieldValue('agreements', values.agreements.concat(event.target.name));
    } else {
      setFieldValue(
        'agreements',
        values.agreements.filter((name) => name !== event.target.name),
      );
    }
  };

  const agreementOptions = [
    {
      label: (
        <p>
          I have read and agree to the{' '}
          <TrueLinkLink href="/legal/esign-consent" rel="noreferrer" target="_blank">
            E-Sign Policy
          </TrueLinkLink>
          , which provides that True Link is an online service and that you will receive all account
          notices and information electronically via your primary email address.
        </p>
      ),
      value: 'esignAgreement',
      id: 'esign-agreement',
    },
    {
      label: (
        <p>
          I agree to the{' '}
          <TrueLinkLink href="/legal/master-services-agreement" rel="noreferrer" target="_blank">
            Cardholder Agreement and Protection Service Agreement
          </TrueLinkLink>{' '}
          associated with this card.
        </p>
      ),
      value: 'cardholderAgreement',
      id: 'cardholder-agreement',
    },
  ];

  return (
    <>
      <div className={classes.agreementCheckbox}>
        <TrueLinkCheckboxes
          ariaLabel="Sign required agreements"
          name="agreements"
          onChange={handleAgreementCheck}
          options={agreementOptions}
          required
          value={values.agreements}
        />
      </div>
      <ErrorText name="agreements" />
      <div className={classes.actionsBtns}>
        <TrueLinkButton
          className={classes.submitBtn}
          loading={isLoading}
          onClick={onSubmit}
          variant="primary"
        >
          Activate card
        </TrueLinkButton>
        <TrueLinkButton className={classes.submitBtn} onClick={onClose} variant="error">
          Nevermind
        </TrueLinkButton>
      </div>{' '}
    </>
  );
}

CardActivationAgreementForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  values: PropTypes.shape({
    agreements: PropTypes.array.isRequired,
  }),
};
