import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import TrashCan from 'images/trash-can.png';
import DashboardActions from 'react/member/actions/dashboard_actions';
import AreYouSurePopUp from 'react/shared/components/popups/AreYouSurePopUp';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import TrueLinkLink from 'react/shared/components/true_link/main/TrueLinkLink';
import bindAll from 'react/shared/utils/bind_all';

export default class CardTransactionAttachmentRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
    bindAll(this);
  }

  closeDestructionModal() {
    this.setState({ showModal: false });
  }

  showDestructionModal() {
    this.setState({ showModal: true });
  }

  onDestroy(_evt) {
    DashboardActions.destroyTransactionAttachment(
      this.props.attachment.id,
      this.props.cardTransactionId,
    );
    this.closeDestructionModal();
  }

  render() {
    const { attachment, canEdit } = this.props;

    return (
      <div className="attachment" key={attachment.id}>
        <div className="attachment__text">{attachment.attributes?.name}</div>
        <div className="attachment__meta flex flex--space-between">
          {!this.props.isCardholderView && (
            <>
              <div>
                <TrueLinkLink href={RailsRoutes.api_v2_attachment_path(attachment.id)}>
                  Download
                </TrueLinkLink>
                &nbsp;&nbsp;&bull;&nbsp;&nbsp;Uploaded{' '}
                {moment(attachment.attributes?.createdAt).format('M/D/YY h:mm A')}
                &nbsp;&nbsp;&bull;&nbsp;&nbsp;
                {attachment.attributes?.uploader}
              </div>
              {canEdit && (
                <TrueLinkButton
                  onClick={this.showDestructionModal}
                  style={{ border: 0, background: 'none' }}
                  variant="none"
                >
                  <img alt="Delete receipt" height={23} src={TrashCan} width={23} />
                </TrueLinkButton>
              )}
            </>
          )}
          {this.props.isCardholderView && (
            <div>Uploaded {moment(attachment.attributes?.createdAt).format('M/D/YY h:mm A')}</div>
          )}
        </div>
        <input
          name="attachment_ids[]"
          readOnly
          style={{ display: 'none ' }}
          value={attachment.id}
        />
        <AreYouSurePopUp
          messageText={'Are you sure you want to delete this receipt?'}
          onClose={this.closeDestructionModal}
          onSubmit={this.onDestroy}
          openModal={this.state.showModal}
          submitText={'Yes, delete'}
        />
      </div>
    );
  }
}

CardTransactionAttachmentRow.propTypes = {
  attachment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    attributes: PropTypes.shape({
      createdAt: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      uploader: PropTypes.string.isRequired,
    }),
  }).isRequired,
  canEdit: PropTypes.bool.isRequired,
  cardTransactionId: PropTypes.string.isRequired,
  isCardholderView: PropTypes.bool,
};
