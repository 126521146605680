import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useCallback, useState } from 'react';
import DashboardActions from 'react/member/actions/dashboard_actions';
import LoadingIndicator from 'react/shared/components/LoadingIndicator';
import DashboardCardStore from 'react/shared/stores/DashboardCardStore';
import theme from 'react/shared/theme/Theme';

const useStyles = makeStyles(() => ({
  status: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      alignItems: 'center',

      '& .card-balance-wrapper__left': {
        position: 'relative !important',
        textAlign: 'center',
        marginBottom: '10px',
      },
    },
  },
}));
export default function DashboardCardStatus(props) {
  const classes = useStyles();
  const [cardBalance, setCardBalance] = useState(null);
  const [cardBalanceUnformatted, setCardBalanceUnformatted] = useState(null);
  const [cardStatus, setCardStatus] = useState(props.status);

  const setCurrentCardStatus = useCallback(
    (accountId, refreshedCardStatus) => {
      if (accountId != props.accountId) return;

      setCardStatus(refreshedCardStatus.card_status);
      setCardBalance(refreshedCardStatus.card_balance);
      setCardBalanceUnformatted(refreshedCardStatus.card_balance_unformatted);
    },
    [props.accountId],
  );

  useEffect(() => {
    DashboardCardStore.on('cards.refreshedCurrentCardStatus', setCurrentCardStatus);
    DashboardActions.refreshCurrentCardStatus(props.accountId);

    return function cleanup() {
      DashboardCardStore.off('cards.refreshedCurrentCardStatus', setCurrentCardStatus);
    };
  }, [props.accountId, setCurrentCardStatus]);

  const orderInProgress = () => cardStatus === 'Card has been ordered';

  const shouldShowBalance = () =>
    cardStatus === 'Active' || (cardBalanceUnformatted > 0 && cardStatus === 'Not activated');

  const renderCardBalance = () => {
    if (cardBalance) return cardBalance;

    return (
      <LoadingIndicator
        containerStyle={{
          display: 'inline',
          margin: 8,
          verticalAlign: 'middle',
        }}
      />
    );
  };

  const { last4Digits } = props;

  const wrapperClasses = classNames('card-balance-wrapper', classes.status, {
    'card-balance-wrapper--status': orderInProgress(),
  });
  return (
    <div className={wrapperClasses}>
      <div className="card-balance-wrapper__left">
        <div className="card-balance-wrapper__left__top">
          {shouldShowBalance() ? 'BALANCE' : 'STATUS'}
        </div>
        {last4Digits && (
          <div className="card-balance-wrapper__left__bottom">card ending in {last4Digits}</div>
        )}
      </div>
      <div className="card-balance-wrapper__right">
        {shouldShowBalance() ? renderCardBalance() : cardStatus}
      </div>
    </div>
  );
}

DashboardCardStatus.propTypes = {
  accountId: PropTypes.string.isRequired,
  last4Digits: PropTypes.string,
  status: PropTypes.string.isRequired,
};
