import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import React from 'react';
import DisbursementRequestForm from './DisbursementRequestForm';
import Disbursements from './Disbursements';
import userRoleShape from 'react/member/shapes/UserRoleShape';
import BeneficiaryDashboardDisbursementsStore from 'react/member/stores/beneficiary_dashboard/beneficiary_dashboard_disbursements_store';
import PopUp from 'react/shared/components/popups/PopUp';
import bindAll from 'react/shared/utils/bind_all';

export default class BeneficiaryDashboardDisbursementsTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDisbursementModal: false,
    };

    bindAll(this);
  }

  componentDidMount() {
    BeneficiaryDashboardDisbursementsStore.on('disbursements.create', this.handleModalClose);
  }

  componentWillUnmount() {
    BeneficiaryDashboardDisbursementsStore.off('disbursements.create', this.handleModalClose);
  }

  showDisbursementForm() {
    this.setState({ showDisbursementModal: true });
  }

  handleModalClose() {
    this.setState({ showDisbursementModal: false });
  }

  renderDisbursementModal() {
    const { trustBeneficiary, userRoles } = this.props;
    return (
      <PopUp
        header="Request Disbursement"
        hideFooter
        modalBodyStyle={{ padding: 0 }}
        modalOpen
        onClose={this.handleModalClose}
      >
        <DisbursementRequestForm trustBeneficiary={trustBeneficiary} userRoles={userRoles} />
      </PopUp>
    );
  }

  render() {
    const { allowDisbursementRequest, trustBeneficiary } = this.props;
    const { showDisbursementModal } = this.state;
    const queryClient = new QueryClient();

    return (
      <div>
        <QueryClientProvider client={queryClient}>
          {allowDisbursementRequest && (
            <button className="btn btn-success" onClick={this.showDisbursementForm} type="button">
              Request Disbursement
            </button>
          )}
          {showDisbursementModal && this.renderDisbursementModal()}
          <Disbursements clientId={trustBeneficiary.slug} />
        </QueryClientProvider>
      </div>
    );
  }
}

BeneficiaryDashboardDisbursementsTab.propTypes = {
  trustBeneficiary: PropTypes.object.isRequired,
  allowDisbursementRequest: PropTypes.bool.isRequired,
  userRoles: userRoleShape.isRequired,
};
