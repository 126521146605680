import PropTypes from 'prop-types';
import React from 'react';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';

export default function HeaderExpandButton({ allExpanded, collapseAll, expandAll }) {
  const [label, onClick] = allExpanded
    ? ['Collapse Sections', collapseAll]
    : ['Expand Sections', expandAll];

  return (
    <TrueLinkButton
      onClick={onClick}
      style={{
        width: 200,
      }}
      variant="primary"
    >
      {label}
    </TrueLinkButton>
  );
}

HeaderExpandButton.propTypes = {
  allExpanded: PropTypes.bool.isRequired,
  expandAll: PropTypes.func.isRequired,
  collapseAll: PropTypes.func.isRequired,
};
