import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import { Formik } from 'formik';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import TransferFundsFormFields from './TransferFundsFormFields';
import TransferSuccess from './TransferSuccess';
import DashboardActions from 'react/member/actions/dashboard_actions';
import api from 'react/member/card/components/funding_tab/api';
import { autoTransferShape } from 'react/member/card/shapes/AutoTransferShape';
import { bankAccountShapeLite } from 'react/member/card/shapes/BankAccountShape';
import { transferShape } from 'react/member/card/shapes/TransferShape';
import DashboardBankAccountStore from 'react/member/stores/DashboardBankAccountStore';
import Actions from 'react/shared/actions/actions';
import LoadingIndicator from 'react/shared/components/LoadingIndicator';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import HolidayCalendarStore from 'react/shared/stores/HolidayCalendarStore';
import { swallowAxiosCancelation } from 'react/shared/utils/Axios';

const useStyles = makeStyles(() => ({
  transferFundsForm: {
    position: 'relative',
  },
  submitBtn: {
    width: '100%',
  },
  deleteBtn: {
    width: '100%',
    marginTop: '10px',
  },
  loadingIndicator: {
    margin: '100px auto',
    '& .loading-container': {
      width: '100%',
    },
  },
  actionButtons: {
    position: 'sticky',
    bottom: '0px',
    width: '100%',
    paddingTop: '40px',
    paddingBottom: '10px',
    background: `linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 100%)`,
  },
}));

export default function TransferFundsForm({
  bankAccount,
  cardholderName,
  cardStatus,
  editInstance,
  emergencyFundingEnabled,
  fundingType,
  isEffectivelyFullyFunded,
  onClose,
  onSuccess,
  onDelete,
  transfer,
}) {
  const classes = useStyles();
  const [bankAccountValue, setBankAccountValue] = useState(bankAccount);
  const [transferAmountValue, setTransferAmountValue] = useState(transfer?.amount || '');
  const [transferFrequencyValue, setTransferFrequencyValue] = useState(
    transfer?.recurringType || '',
  );
  const [bomorrow, setBomorrow] = useState('');
  const [transferFrequencyDescription, setTransferFrequencyDescription] = useState('');
  const [transferStartDay, setTransferStartDay] = useState(transfer?.startDate || null);
  const [transferDateValue, setTransferDateValue] = useState(transfer?.dateUnformatted || '');
  const [transferDayOfWeek, setTransferDayOfWeek] = useState(transfer?.dayOfWeek || '1');
  const [transferMemoValue, setTransferMemoValue] = useState(transfer?.memo || '');
  const [transferSuccess, setTransferSuccess] = useState(false);
  const [transferSuccessData, setTransferSuccessData] = useState(null);
  const [showMemoField, setShowMemoField] = useState(!!transfer?.memo);
  const [soonestTransferDate, setSoonestTransferDate] = useState(null);
  const [transferAdjusted, setTransferAdjusted] = useState(false);
  const [transferAdjustedReason, setTransferAdjustedReason] = useState('');
  const [loadingHolidays, setLoadingHolidays] = useState(true);
  const [loadingSoonestTransferDates, setLoadingSoonestTransferDates] = useState(true);
  const [availableOnDate, setAvailableOnDate] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [transferDatePlus1BDay, setTransferDatePlus1BDay] = useState('');
  const [transferDatePlus5BDay, setTransferDatePlus5BDay] = useState('');
  const cancelTokenSource = axios.CancelToken.source();

  const isEdit = !!transfer?.id;
  const isRecurringTransfer =
    (transfer?.recurringType && transfer.recurringType !== 'one_time') || editInstance;
  const deleteOneTimeTransferText = 'Delete this transfer';
  const deleteRecurringTransferText = editInstance
    ? 'Skip this instance'
    : 'Delete this recurring transfer series';
  const deleteTransferLabel = isRecurringTransfer
    ? deleteRecurringTransferText
    : deleteOneTimeTransferText;

  const availableBankAccounts = DashboardBankAccountStore.getBankAccounts();
  const verifiedBankAccounts = availableBankAccounts.filter((account) => account.isVerified);

  async function validDateSelected(value) {
    if (transfer && isRecurringTransfer) {
      return true;
    }
    const day = moment(value).day();
    const soonestTransferDateStartDay = moment(soonestTransferDate)
      .startOf('day')
      .format('YYYY-MM-DD');
    let dateErrorMessage;
    const holidays = HolidayCalendarStore.getBankHolidays();
    const isHoliday = holidays ? holidays[value] : true;
    let dateBeforeSoonestTransferDate;

    if (value && soonestTransferDate) {
      dateBeforeSoonestTransferDate = moment(value).isBefore(soonestTransferDateStartDay);
    }

    if (!value || Number.isNaN(Date.parse(value))) {
      dateErrorMessage = 'Must be a valid date';
    } else if (value && value.length !== 10) {
      dateErrorMessage = 'Must be a valid date';
    } else if (dateBeforeSoonestTransferDate) {
      dateErrorMessage = `Transfer date must be ${soonestTransferDateStartDay} or later.`;
    } else if (day === 0 || day === 6 || isHoliday) {
      dateErrorMessage = 'Transfer cannot be scheduled on a bank holiday or weekend';
    }

    if (dateErrorMessage) {
      throw new Yup.ValidationError(dateErrorMessage, value, 'transfer_date');
    } else {
      return true;
    }
  }

  const fetchSoonestTransferDate = () => {
    api
      .getSoonestTransferDate(cancelTokenSource.token)
      .then(({ data }) => {
        setSoonestTransferDate(data.boday);
        setTransferDatePlus1BDay(data.bomorrow);
        setTransferDatePlus5BDay(data.fifth_business_day);
        const formattedDate = moment(data.bomorrow).format('MMMM DD YYYY');
        setAvailableOnDate(formattedDate);
        setLoadingSoonestTransferDates(false);
      })
      .catch(swallowAxiosCancelation);
  };

  const updateAvailableOnDate = (date) => {
    api
      .getBomorrow(date, cancelTokenSource.token)
      .then(({ data }) => {
        const formattedDate = moment(data.bomorrow).format('MMMM DD YYYY');
        setAvailableOnDate(formattedDate);
        setTransferDatePlus1BDay(data.bomorrow);
        setTransferDatePlus5BDay(data.fifth_business_day);
        setLoadingSoonestTransferDates(false);
      })
      .catch(swallowAxiosCancelation);
  };

  const updateStartingDate = (transfer) => {
    const data = {
      amount: transfer.transfer_amount || transfer.amount || 1,
      day_of_week: transfer.transfer_day_of_week || transfer.dayOfWeek,
      day_of_month: transfer.transfer_day_of_month || transfer.dayOfMonth,
      day_of_month_2: transfer.transfer_day_of_month_2 || transfer.dayOfMonth2,
      recurring_type: transfer.transfer_frequency || transfer.recurringType,
    };

    axios
      .post(RailsRoutes.schedule_dashboard_auto_transfers_path({ format: 'json' }), {
        auto_transfer: data,
      })
      .then((response) => {
        setBomorrow(response.data.bomorrow);
        setTransferStartDay(response.data.start_date);
        setTransferDatePlus1BDay(response.data.business_days_from_start[1]);
        setTransferDatePlus5BDay(response.data.business_days_from_start[5]);
        const firstTransferDate = response.data.next_5_dates[0];
        const transferIsAdjusted =
          firstTransferDate.adjusted_date != firstTransferDate.unadjusted_date;
        setTransferAdjusted(transferIsAdjusted);
        if (transferIsAdjusted) {
          const dateToCheck = moment(firstTransferDate.unadjusted_date);
          const isWeekend = dateToCheck.day() === 0 || dateToCheck.day() === 6;
          setTransferAdjustedReason(isWeekend ? 'weekend' : 'holiday');
        }
        setLoadingSoonestTransferDates(false);
      });
  };

  const onDateSelected = (date) => {
    if (date) {
      updateAvailableOnDate(date);
    }
  };

  const finishLoading = () => {
    setLoadingHolidays(false);
  };

  useEffect(() => {
    Actions.fetchBankHolidays();
    HolidayCalendarStore.on('bankHolidays.fetch', finishLoading);
    if (transfer) {
      if (transfer.date) {
        //one time transfer
        onDateSelected(transfer.date);
      } else if (transfer.recurringType !== 'when_funds_drop_below') {
        //recurring transfer
        updateStartingDate(transfer);
      } else if (transfer.recurringType == 'when_funds_drop_below') {
        setLoadingSoonestTransferDates(false);
      }
    } else {
      fetchSoonestTransferDate();
    }

    return () => {
      HolidayCalendarStore.off('bankHolidays.fetch', finishLoading);
      cancelTokenSource.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validationSchema = Yup.object().shape({
    bank_account_id: Yup.string().required('field required'),
    transfer_amount: Yup.number()
      .positive('Amount must be positive')
      .lessThan(5000.01, 'Please enter a value less than or equal to 5000')
      .test('maxDigitsAfterDecimal', 'Only 2 decimal places are allowed', (number) =>
        /^(undefined|\d+(\.\d{1,2})?)$/.test(number),
      )
      .required('Cannot be zero or empty'),
    transfer_frequency: Yup.string().required('Field required'),
    transfer_date: Yup.string().test('validDateSelected', 'Invalid date', validDateSelected),
    transfer_memo: Yup.string().max(255, 'Max length 255'),
    transfer_threshold_amount: Yup.number().when('transfer_frequency', {
      is: 'when_funds_drop_below',
      then: () =>
        Yup.number()
          .positive('Amount must be positive')
          .lessThan(5000.01, 'Please enter a value less than or equal to 5000')
          .test('maxDigitsAfterDecimal', 'Only 2 decimal places are allowed', (number) =>
            /^(undefined|\d+(\.\d{1,2})?)$/.test(number),
          )
          .required('Cannot be zero or empty'),
      otherwise: () => Yup.string().nullable(),
    }),
  });

  const initialValues = {
    bank_account_id: bankAccount.id || '',
    transfer_amount: transferAmountValue || '',
    transfer_frequency: transferFrequencyValue || 'one_time',
    transfer_date: transferDateValue || moment(soonestTransferDate).format('YYYY-MM-DD'),
    transfer_memo: transferMemoValue || '',
    transfer_day_of_week: transferDayOfWeek,
    transfer_day_of_month: transfer?.dayOfMonth || '1',
    transfer_day_of_month_2: transfer?.dayOfMonth2 || '15',
    transfer_threshold_amount: transfer?.thresholdAmount || '',
  };

  const handleSuccessTransfer = (data) => {
    if (data) {
      onSuccess(data);
      setTransferFrequencyDescription(data.description);
      setTransferSuccessData(data);
      setTransferSuccess(true);
    } else {
      onClose();
    }
  };

  const createTransfer = (values) => {
    const data = {
      amount: values.transfer_amount,
      date: values.transfer_date,
      memo: values.transfer_memo,
    };

    if (isEdit) {
      DashboardActions.updateTransfer(transfer.id, { transfer: data }, (response) => {
        onSuccess(response);
      });
    } else {
      DashboardActions.createTransfer(values.bank_account_id, data, handleSuccessTransfer);
    }
  };

  const createAutoTransfer = (values) => {
    const data = {
      auto_transfer: {
        amount: values.transfer_amount,
        day_of_week: values.transfer_day_of_week,
        day_of_month: values.transfer_day_of_month,
        day_of_month_2: values.transfer_day_of_month_2,
        recurring_type: values.transfer_frequency,
        threshold_amount: values.transfer_threshold_amount,
      },
    };

    if (isEdit) {
      DashboardActions.updateAutoTransfer(transfer.id, data, (response) => {
        onSuccess(response);
      });
    } else {
      data.auto_transfer.bank_account_id = values.bank_account_id;
      DashboardActions.createAutoTransfer(data, (data) => handleSuccessTransfer(data));
    }
  };

  const handleContinue = (formikValues) => {
    const bankAccount = availableBankAccounts.find((ba) => ba.id === formikValues.bank_account_id);
    setBankAccountValue(bankAccount);
    setTransferAmountValue(formikValues.transfer_amount);
    setTransferDateValue(formikValues.transfer_date);
    setTransferMemoValue(formikValues.transfer_memo);
    setTransferDayOfWeek(formikValues.transfer_day_of_week);
    setTransferFrequencyValue(formikValues.transfer_frequency);
    setSubmitting(true);
    formikValues.transfer_frequency === 'one_time'
      ? createTransfer(formikValues)
      : createAutoTransfer(formikValues);
  };

  const deleteTransfer = () => {
    if (transfer.recurringType || editInstance) {
      onDelete();
      onClose();
    } else {
      DashboardActions.deleteTransfer(transfer.id, onClose);
    }
  };

  const form = (
    <Formik
      initialValues={initialValues}
      onSubmit={handleContinue}
      validationSchema={validationSchema}
    >
      {({ handleChange, handleSubmit, setFieldTouched, setFieldValue, values }) => (
        <>
          <TransferFundsFormFields
            availableOnDate={availableOnDate}
            bomorrow={bomorrow}
            cardStatus={cardStatus}
            editInstance={editInstance}
            emergencyFundingEnabled={emergencyFundingEnabled}
            fundingType={fundingType}
            handleChange={handleChange}
            isEdit={isEdit}
            isEffectivelyFullyFunded={isEffectivelyFullyFunded}
            onDateSelected={onDateSelected}
            onFrequencyUpdated={updateStartingDate}
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            setShowMemoField={setShowMemoField}
            showMemoField={showMemoField}
            transferAdjusted={transferAdjusted}
            transferAdjustedReason={transferAdjustedReason}
            transferDatePlus1BDay={transferDatePlus1BDay}
            transferDatePlus5BDay={transferDatePlus5BDay}
            transferStartDay={transferStartDay}
            values={values}
            verifiedBankAccounts={verifiedBankAccounts}
          />
          <div className={classes.actionButtons}>
            <TrueLinkButton
              className={classes.submitBtn}
              disabled={submitting}
              id="submit-transfer-cta"
              onClick={handleSubmit}
              variant="primary"
            >
              Submit
            </TrueLinkButton>
            {transfer && (
              <TrueLinkButton
                className={classes.deleteBtn}
                id="delete-transfer-cta"
                onClick={deleteTransfer}
                variant="error"
              >
                {deleteTransferLabel}
              </TrueLinkButton>
            )}
          </div>
        </>
      )}
    </Formik>
  );
  const success = (
    <TransferSuccess
      bankAccount={bankAccountValue}
      bankAccountName={`${bankAccountValue.nickname}${' '}${bankAccountValue.accountNumber}`}
      cardholderName={cardholderName}
      onClose={onClose}
      transferAmount={transferAmountValue}
      transferDate={transferDateValue}
      transferFrequency={transferFrequencyValue}
      transferFrequencyDescription={transferFrequencyDescription}
      transferMemo={transferMemoValue}
      transferSuccessData={transferSuccessData}
    />
  );

  if (loadingHolidays || loadingSoonestTransferDates) {
    return (
      <div className={classes.loadingIndicator}>
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <div className={classes.transferFundsForm}>
      {!transferSuccess && form}
      {transferSuccess && success}
    </div>
  );
}

TransferFundsForm.propTypes = {
  bankAccount: bankAccountShapeLite,
  cardholderName: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  onDelete: PropTypes.func,
  editInstance: PropTypes.bool,
  isEffectivelyFullyFunded: PropTypes.bool,
  transfer: PropTypes.oneOfType([transferShape, autoTransferShape, PropTypes.object]),
  emergencyFundingEnabled: PropTypes.bool,
  fundingType: PropTypes.string,
  cardStatus: PropTypes.string,
};
