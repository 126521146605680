import axios from 'axios';
import { getPortfoliosByOrg } from 'react/member/components/dashboard/portfolios/api';
import { getTrustBeneficiariesByOrg } from 'react/member/components/dashboard/trust_beneficiaries/api';
import { getTrustsByOrg } from 'react/member/components/dashboard/trusts/api';
import { AXIOS_XHR_CONFIG } from 'react/shared/utils/Axios';

export function fetchDropdownDataCollections(orgSlug) {
  const getTrusts = getTrustsByOrg(orgSlug);
  const getPortfolios = getPortfoliosByOrg(orgSlug);
  const getBenes = getTrustBeneficiariesByOrg(orgSlug);

  return axios.all([getTrusts, getPortfolios, getBenes]).then(
    axios.spread((...responses) => ({
      trusts: responses[0].data.data,
      portfolios: responses[1].data,
      benes: responses[2].data.data,
    })),
  );
}

export function fetchReportsList({ originType, originId }) {
  const reportsListUrl = RailsRoutes.api_v1_reports_path({
    origin_type: originType,
    origin_id: originId,
  });
  return axios.get(reportsListUrl, AXIOS_XHR_CONFIG);
}
