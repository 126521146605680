import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import TrueLinkIcon from 'react/shared/components/true_link/main/TrueLinkIcon';

const useStyles = makeStyles({
  actionMenuButton: {
    '& svg': { marginLeft: '7px' },
  },
  actionMenuContainer: {
    position: 'relative',
  },
  actionMenuExpanded: {
    position: 'absolute',
    top: '30px',
    right: '0px',
    zIndex: 1000,
    float: 'left',
    minWidth: '10rem',
    padding: '.5rem 0',
    margin: '.125rem 0 0',
    fontSize: '1rem',
    color: 'black',
    textAlign: 'left',
    listStyle: 'none',
    backgroundColor: '#fff',
    '-webkit-background-clip': 'padding-box',
    backgroundClip: 'padding-box',
    border: '1px solid rgba(0,0,0,.15)',
    borderRadius: '.25rem',
  },
  actionMenuItem: {
    display: 'block',
    width: '100%',
    padding: '3px 1.5rem',
    clear: 'both',
    fontWeight: 400,
    color: 'black',
    textAlign: 'inherit',
    whiteSpace: 'nowrap',
    background: '0 0',
    border: 0,
  },
});

export default function ActionMenuDropdown({ menuItems, menuLabel = 'Edit' }) {
  const classes = useStyles();
  const [showExpandedMenu, setExpandedMenu] = useState(false);

  const toggleMenu = (event) => {
    event.stopPropagation();
    setExpandedMenu((prevExpanded) => !prevExpanded);
  };

  const closeMenu = () => {
    setExpandedMenu(false);
  };

  useEffect(() => {
    if (!showExpandedMenu) return;

    document.addEventListener('click', closeMenu);
    return function cleanup() {
      document.removeEventListener('click', closeMenu);
    };
  }, [showExpandedMenu]);

  return (
    <div className={classes.actionMenuContainer}>
      <div className={classes.actionMenuButton}>
        <TrueLinkButton
          className="btn btn-link"
          key={showExpandedMenu}
          onClick={toggleMenu}
          variant="none"
        >
          {menuLabel}
          <TrueLinkIcon icon={showExpandedMenu ? 'chevron-up' : 'chevron-down'} />
        </TrueLinkButton>
      </div>
      {showExpandedMenu && (
        <div className={classes.actionMenuExpanded}>
          {menuItems.map(({ displayText, action, color }) => (
            <div className={classes.actionMenuItem} key={`menu-item-${displayText}`}>
              <TrueLinkButton
                className="btn btn-link"
                onClick={action}
                style={{ color: `${color}` }}
                variant="none"
              >
                {displayText}
              </TrueLinkButton>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

ActionMenuDropdown.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      displayText: PropTypes.string.isRequired,
      action: PropTypes.func.isRequired,
      color: PropTypes.string,
    }),
  ).isRequired,
  menuLabel: PropTypes.string,
};
