import PropTypes from 'prop-types';

const { shape, string } = PropTypes;

const addressShape = shape({
  street1: string,
  street2: string,
  city: string,
  state: string,
  zip: string,
});

export default addressShape;
