import PropTypes from 'prop-types';
import React from 'react';
import PopUp from 'react/shared/components/popups/PopUp';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import bindAll from 'react/shared/utils/bind_all';

export default class UserTabContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
    };

    bindAll(this);
  }

  toggleModal() {
    this.setState((prevState) => ({
      isModalOpen: !prevState.isModalOpen,
    }));
  }

  closeModal() {
    this.setState({ isModalOpen: false });
  }

  renderPopUp() {
    return (
      <PopUp
        dismissBtnText="Close"
        header="User access levels"
        hideFooter
        maxWidth="640px"
        modalBodyStyle={{ fontWeight: 500, padding: '15px 20px' }}
        onClose={this.closeModal}
        openModal={this.state.isModalOpen}
      >
        <div id="user_access_levels">
          <div className="level">
            <span className="name">Owner:</span>
            <span className="description">
              Access to all features on the True Link Card user portal and allowed to add users
            </span>
          </div>
          <div className="level">
            <span className="name">Full access:</span>
            <span className="description">
              Access to all features on the True Link Card user portal
            </span>
          </div>
          <div className="level">
            <span className="name">Partial access:</span>
            <span className="description">
              Not able to fund True Link Cards or order additional cards, but able to access all
              other features on the True Link Card user portal
            </span>
          </div>
          <div className="level">
            <span className="name">Read-only access:</span>
            <span className="description">
              Able to view cardholder information, but not able to take any action or make any edits
            </span>
          </div>
        </div>
      </PopUp>
    );
  }

  renderUsersTable() {
    const { users } = this.props;
    return (
      <table className="table" id="usersTable">
        <thead>
          <tr>
            <th>First name</th>
            <th>Last name</th>
            <th>Email</th>
            <th>Access level</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.email}>
              <td>{user.firstName}</td>
              <td>{user.lastName}</td>
              <td>{user.email}</td>
              <td>{user.accessLevel}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  render() {
    const { isOwner, owner, supportEmailAddress, supportPhoneNumber } = this.props;

    return (
      <div>
        <div className="sub_section_header" style={{ margin: 0 }}>
          Users
        </div>
        {this.renderUsersTable()}
        <br />
        <div className="info-box">
          A user's access level determines what data they can see and what actions they can take.
          <br />
          <TrueLinkButton
            className="btn-link"
            onClick={this.toggleModal}
            style={{ padding: 0 }}
            variant="none"
          >
            Click here
          </TrueLinkButton>{' '}
          to learn about each of the access levels
        </div>
        <div className="info-box">
          Need to add another user or change a user's access level?
          <br />
          {isOwner ? 'Contact ' : `Contact ${owner.full_name} and have them contact `}
          support at <a href={`mailto:${supportEmailAddress}`}>{supportEmailAddress}</a> or by
          calling {supportPhoneNumber}
        </div>
        {this.renderPopUp()}
      </div>
    );
  }
}
UserTabContainer.propTypes = {
  isOwner: PropTypes.bool.isRequired,
  owner: PropTypes.object,
  supportEmailAddress: PropTypes.string.isRequired,
  supportPhoneNumber: PropTypes.string.isRequired,
  users: PropTypes.array.isRequired,
};
