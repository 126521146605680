import PropTypes from 'prop-types';
import _values from 'underscore/modules/values';
import { cardOrderShape } from './CardOrderShape';
import { CARD_ORDER_TYPES } from 'react/shared/utils/CardOrderType';

const { bool, number, string, shape, arrayOf, oneOf, object } = PropTypes;

export const cardShape = shape({
  card_close_form: shape({
    addresses: arrayOf(
      shape({
        address: string,
        id: number,
        type: string,
      }),
    ),
    bank_accounts: arrayOf(object),
    card: object,
    reasons: arrayOf(string),
  }),
  card_last_4: string,
  card_last_4_deprecating: string,
  card_order: cardOrderShape,
  card_reissue: cardOrderShape,
  card_reissue_type: oneOf(_values(CARD_ORDER_TYPES)),
  expiry_date: string,
  has_been_activated: bool,
  has_open_card_close_request: bool,
  is_active: bool,
  is_breached: bool,
  is_deprecated_card: bool,
  is_reissued_card: bool,
  name: string,
  slug: string.isRequired,
  shipment_tracking_number: string,
  status: string,
});

export const CardInfoV2Shape = shape({
  type: PropTypes.string.isRequired,
  id: PropTypes.string,
  attributes: PropTypes.shape({
    balance: PropTypes.shape({
      amount: number,
      currency: string,
      timestamp: string,
    }),
    breach: PropTypes.shape({ closeAtDate: string }),
    cardReference: string,
    expiryDate: string,
    lastFour: string,
    status: string,
  }).isRequired,
});

const issuedInactiveCardShape = shape({
  estimated_arrival_date: string,
  expiry_date: string,
  last_4_digits: string,
  slug: string.isRequired,
});

export const issuedInactiveCardsShape = arrayOf(issuedInactiveCardShape);
