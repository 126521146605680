import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import PrintExternalChecksTable from 'react/member/components/dashboard/print_checks/PrintExternalChecksTable';
import DatePicker from 'react/shared/components/DatePicker';
import SubSectionHeader from 'react/shared/components/SubSectionHeader';
import PALETTE from 'react/shared/theme/palette';

const useStyles = makeStyles({
  downloadChecksButton: {
    fontWeight: 600,
    padding: '.7em .9em',
    borderRadius: '4px',
    fontSize: '16px',
    lineHeight: '18px',
    borderStyle: 'solid',
    background: PALETTE.emerald,
    color: PALETTE.white,
    cursor: 'pointer',
    display: 'inline-block',
    verticalAlign: 'middle',
    boxShadow: 'none',
    border: 'none',
    textShadow: 'none',
    borderWidth: '1px',
    '&:hover': {
      backgroundColor: PALETTE.evergreen,
      color: PALETTE.white,
    },
  },
});

export default function DashboardCheckPrinting({ orgSlug }) {
  const tomorrowsDate = useMemo(() => moment().add(1, 'day').toDate(), []);
  const classes = useStyles();

  const dateRangeForDownload = (startDate, endDate) => {
    const params = {
      start_date: startDate,
      end_date: endDate,
    };

    let route;
    if (orgSlug) {
      route = RailsRoutes.download_range_dashboard_organization_external_checks_path(
        orgSlug,
        params,
      );
    } else {
      route = RailsRoutes.download_range_dashboard_external_checks_path(params);
    }

    window.open(route);
  };

  return (
    <div>
      <SubSectionHeader>Request checks</SubSectionHeader>
      <div style={{ marginBottom: 40 }}>
        <DatePicker
          action="Download Checks"
          buttonType={classes.downloadChecksButton}
          endDate={tomorrowsDate}
          infoText="Please select the date range for the checks you’d like to print. All checks on or between the selected date range will be submitted for processing."
          onSubmit={dateRangeForDownload}
          startDate={new Date()}
        />
      </div>
      <SubSectionHeader noMarginBottom style={{ borderBottom: 0 }}>
        Checks due to be printed
      </SubSectionHeader>
      <PrintExternalChecksTable future={false} orgSlug={orgSlug} />
      <SubSectionHeader noMarginBottom style={{ borderBottom: 0 }}>
        Checks with future due date
      </SubSectionHeader>
      <PrintExternalChecksTable future orgSlug={orgSlug} />
    </div>
  );
}

DashboardCheckPrinting.propTypes = {
  orgSlug: PropTypes.string,
};
