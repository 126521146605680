import PropTypes from 'prop-types';
import React from 'react';
import _isEmpty from 'underscore/modules/isEmpty';
import AdditionalEmail from './AdditionalEmail';
import LoadingIndicator from 'react/shared/components/LoadingIndicator';
import SavedMessage from 'react/shared/components/SavedMessage';
import ReactDropdown from 'react/shared/components/forms/ReactDropdown';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import DashboardRulesetStore from 'react/shared/stores/DashboardRulesetStore';
import bindAll from 'react/shared/utils/bind_all';

export default class AdditionalEmailsList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newEmail: '',
      inFlight: false,
    };

    bindAll(this);
  }

  componentDidMount() {
    DashboardRulesetStore.on('account.additionalEmails.updated', this.onSaveSuccess);
    DashboardRulesetStore.on('account.additionalEmails.error', this.onSaveFailure);
  }

  componentWillUnmount() {
    DashboardRulesetStore.off('account.additionalEmails.updated', this.onSaveSuccess);
    DashboardRulesetStore.off('account.additionalEmails.error', this.onSaveFailure);
  }

  onSaveSuccess() {
    this.refs.saved.saved();
    this.refs.dropdown.resetChosen();
    this.setState({ newEmail: '', inFlight: false });
  }

  onSaveFailure() {
    this.refs.dropdown.resetChosen();
    this.setState({ newEmail: '', inFlight: false });
  }

  handleSelectEmail(ev) {
    this.setState({ newEmail: ev.target.value });
  }

  handleChosenSearchEnter(ev) {
    this.setState({ newEmail: ev.target.value }, () => {
      this.handleAddAdditionalEmail(ev);
    });
  }

  handleAddAdditionalEmail(ev) {
    ev.preventDefault();
    if (_isEmpty(this.state.newEmail)) return;

    this.setState({ inFlight: true });
    this.props.onAddAdditionalEmail(this.state.newEmail);
  }

  renderEmptyListWarning() {
    return (
      <p className="red margin-bottom-10">
        Please uncheck the box to the left of this message or add at least one email address.
      </p>
    );
  }

  renderCurrentAdditionalEmailRows() {
    const { additionalEmails } = this.props;

    if (_isEmpty(additionalEmails)) return null;

    const rows = additionalEmails.map((email) => (
      <AdditionalEmail
        email={email}
        key={email}
        onRemoveAdditionalEmail={this.props.onRemoveAdditionalEmail}
      />
    ));

    return <ul>{rows}</ul>;
  }

  renderCurrentAdditionalEmails() {
    const { additionalEmails, notificationPrefs } = this.props;

    const warn = additionalEmails.length === 0 && notificationPrefs['email_receiver_list'] == 'on';

    return warn ? this.renderEmptyListWarning() : this.renderCurrentAdditionalEmailRows();
  }

  buildOptions() {
    return this.props.allOrgAdminEmails.map((email) => ({
      id: email,
      name: email,
    }));
  }

  renderAddAdditionalEmailDropdown() {
    const options = this.buildOptions();

    const chosenSettings = {
      no_results_text: 'Press the "enter" key to add new email',
      inherit_select_classes: true,
      width: '350px',
      placeholder_text_single: 'Enter or search for an email address...',
    };

    return (
      <form
        className="alerts-tab__edit-panel--inline-section"
        onSubmit={this.handleAddAdditionalEmail}
      >
        <label htmlFor="new_additional_email">
          <span className="hidden">Search Email</span>
          <ReactDropdown
            chosenSettings={chosenSettings}
            inputId="new_additional_email"
            onChange={this.handleSelectEmail}
            onChosenSearchEnter={this.handleChosenSearchEnter}
            options={options}
            placeholder={'Enter or search for an email address...'}
            ref="dropdown"
            useChosen
          />
        </label>
        <TrueLinkButton
          disabled={_isEmpty(this.state.newEmail) || this.state.inFlight}
          style={{ marginLeft: 10 }}
          type="submit"
          variant="primary"
        >
          Save
        </TrueLinkButton>
        {this.state.inFlight ? (
          <LoadingIndicator containerStyle={{ textAlign: 'left', marginLeft: '10px' }} />
        ) : null}
        <SavedMessage ref="saved" />
      </form>
    );
  }

  render() {
    return (
      <div className="alerts-tab__edit-panel" id="additional-emails-list">
        {this.renderCurrentAdditionalEmails()}
        {this.renderAddAdditionalEmailDropdown()}
      </div>
    );
  }
}

AdditionalEmailsList.propTypes = {
  additionalEmails: PropTypes.arrayOf(PropTypes.string).isRequired,
  allOrgAdminEmails: PropTypes.arrayOf(PropTypes.string).isRequired,
  notificationPrefs: PropTypes.object.isRequired,
  onAddAdditionalEmail: PropTypes.func.isRequired,
  onRemoveAdditionalEmail: PropTypes.func.isRequired,
};
