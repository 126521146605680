import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import _extend from 'underscore/modules/extend';
import _map from 'underscore/modules/map';
import _reduce from 'underscore/modules/reduce';
import _sortBy from 'underscore/modules/sortBy';
import DepositPopUp from 'react/member/components/dashboard/deposits/DepositPopUp';
import { isRestrictedViewUser } from 'react/shared/utils/Authorization';
import bindAll from 'react/shared/utils/bind_all';
import tlFieldTransformers from 'react/shared/utils/tl_field_transformers';

export default class DashboardClientEffectiveBalance extends React.Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = this.getStateWithNewState({ months: 3 });

    bindAll(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.activateTooltips();
    // Ideally this would be tighter controlled, but it is legacy
    // eslint-disable-next-line no-eq-null
    if (this.props.client.id == null) {
      return;
    }
    const path = RailsRoutes.dashboard_client_effective_balance_path(this.props.client.id, {
      format: 'json',
    });
    $.ajax({
      url: path,
      type: 'GET',
      success: this.setInitialState,
    });
  }

  componentDidUpdate() {
    this.activateTooltips();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getStateWithNewState(newState) {
    const state = _extend({}, this.state, newState);
    state.today = moment().endOf('day');
    state.future = state.today.clone().add(state.months, 'months');
    state.show_disbursements_table = false;
    // Ideally this would be tighter controlled, but it is legacy
    // eslint-disable-next-line no-eq-null
    if (state.current_balance != null && state.future_disbursements != null) {
      state.current_balance = Number(state.current_balance);
      state.disbursements = state.future_disbursements.filter((d) =>
        moment(d.date).isBefore(state.future),
      );
      state.effective_balance = _reduce(
        state.disbursements,
        (sum, d) => sum - Number(d.amount),
        state.current_balance,
      );
    }
    return state;
  }

  setStateWithNewState(newState) {
    this.setState(this.getStateWithNewState(newState));
  }

  setInitialState(newState) {
    if (this._isMounted) {
      this.setState(this.getStateWithNewState(newState));
    }
  }

  activateTooltips() {
    $('[data-toggle="tooltip"]').tooltip();
  }

  handleActionButtonClick(num) {
    this.setStateWithNewState({ months: num });
  }

  toggleDisbursementsTable() {
    this.setState({ show_disbursements_table: !this.state.show_disbursements_table });
  }

  renderActionButtons() {
    return [1, 3, 6, 12].map((num) => {
      let className = 'month-button';
      if (this.state.months === num) {
        className += ' month-button--active';
      }
      const callback = () => this.handleActionButtonClick(num);
      return (
        <span
          className={className}
          key={num}
          onClick={callback}
          onKeyUp={callback}
          role="button"
          tabIndex={0}
        >
          {num}m
        </span>
      );
    });
  }

  renderDisbursementsTableButton() {
    // Ideally this would be tighter controlled, but it is legacy
    // eslint-disable-next-line no-eq-null
    if ((this.state.disbursements != null ? this.state.disbursements.length : undefined) > 0) {
      const bool = this.state.show_disbursements_table;
      let text = bool ? 'Hide' : 'Show';
      const className = bool
        ? 'btn btn-link angle-down angle-down--open'
        : 'btn btn-link angle-down';
      text += ` disbursements included in the Effective Balance (${
        // Ideally this would be tighter controlled, but it is legacy
        // eslint-disable-next-line no-eq-null
        this.state.disbursements != null ? this.state.disbursements.length : undefined
      })`;
      return (
        <div className="effective-balance-show-disbursements">
          <button className={className} onClick={this.toggleDisbursementsTable} type="button">
            {text}
          </button>
          <div
            className="fright disclaimer-tooltip"
            data-original-title="The Effective Balance reflects the current sub-account balance minus all the distributions within 3 months. The effective balance does not reflect:
          1. Future deposits
          2. Market fluctuations
          3. Interest earned
          4. Dividends received"
            data-placement="left"
            data-toggle="tooltip"
            href="#"
          >
            Disclaimer
          </div>
        </div>
      );
    }
    return <div className="effective-balance-show-disbursements">No disbursements to show</div>;
  }

  renderDisbursementsTable() {
    if (!this.state.show_disbursements_table) {
      return null;
    }
    // Ideally this would be tighter controlled, but it is legacy
    // eslint-disable-next-line no-eq-null
    if (!((this.state.disbursements != null ? this.state.disbursements.length : undefined) > 0)) {
      return null;
    }

    const disbursements = _sortBy(this.state.disbursements, (d) => moment(d.date));
    const rows = _map(disbursements, (d, idx) => (
      <tr key={idx}>
        <td>{moment(d.date).format('MM/DD/YYYY')}</td>
        <td>{d.payee}</td>
        <td>{d.type}</td>
        <td style={{ textAlign: 'right' }}>{tlFieldTransformers.formatMoney(d.amount)}</td>
      </tr>
    ));

    return (
      <div className="effective-balance-disbursements-list">
        <table className="table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Payee</th>
              <th>Disbursement Type</th>
              <th style={{ textAlign: 'right' }}>Amount</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    );
  }

  renderDepositWorkflowButton() {
    if (this.props.depositWorkflow && !isRestrictedViewUser()) {
      return (
        <a onClick={this.openModal} onKeyUp={this.openModal} role="button" tabIndex={0}>
          + Add new deposit
        </a>
      );
    }
  }

  renderDepositPopUp() {
    if (this.state.modalOpen) {
      return (
        <DepositPopUp
          client={this.props.client}
          closeModal={this.closeModal}
          modalOpen={this.state.modalOpen}
        />
      );
    }
  }

  closeModal() {
    this.setState({ modalOpen: false });
  }

  openModal() {
    this.setState({ modalOpen: true });
  }

  render() {
    return (
      <div className="container" hidden={!this.state.current_balance}>
        <div
          style={{
            paddingBottom: 11,
            marginBottom: 14,
            marginTop: 5,
          }}
        >
          <div style={{ paddingBottom: 14 }}>
            <div style={{ fontSize: 22, display: 'inline-block', marginRight: 20 }}>
              Account balance:
            </div>
            <div style={{ fontSize: 22, display: 'inline-block' }}>
              {tlFieldTransformers.formatMoney(this.state.current_balance)}
            </div>

            <div style={{ fontSize: 22, float: 'right' }}>{this.renderDepositWorkflowButton()}</div>
          </div>

          <div>
            <div style={{ fontSize: 22, display: 'inline-block', marginRight: 20 }}>
              Effective balance:
            </div>
            <div style={{ fontSize: 22, display: 'inline-block' }}>
              {tlFieldTransformers.formatMoney(this.state.effective_balance)}
            </div>
          </div>
        </div>

        {this.renderDisbursementsTableButton()}
        {this.renderDisbursementsTable()}
        {this.renderDepositPopUp()}
      </div>
    );
  }
}

DashboardClientEffectiveBalance.propTypes = {
  client: PropTypes.object.isRequired,
  depositWorkflow: PropTypes.bool,
};
