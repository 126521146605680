import PropTypes from 'prop-types';
import React from 'react';
import { isCash } from './SecurityUtils';
import { asMoney } from 'react/shared/utils/Money';

export default function DeprecatedPositionsRow({ position }) {
  if (isCash(position)) {
    return (
      <tr>
        <td style={{ width: 400 }}>INVESTMENT CASH</td>
        <td />
        <td />
        <td className="table-cell-right" />
        <td className="table-cell-right">{asMoney(Number(position.total_value))}</td>
        <td className="table-cell-right" />
        <td className="table-cell-right" />
      </tr>
    );
  } else if (position.name === 'Total Assets') {
    return (
      <tr>
        <td style={{ width: 400 }}>{position.name}</td>
        <td />
        <td />
        <td className="table-cell-right" />
        <td className="table-cell-right">{asMoney(Number(position.total_value))}</td>
        <td className="table-cell-right">{asMoney(Number(position.cost_basis))}</td>
        <td className="table-cell-right">{asMoney(Number(position.unrealized_gain_loss))}</td>
      </tr>
    );
  }
  return (
    <tr>
      <td style={{ width: 400 }}>{position.name}</td>
      <td style={{ width: 95 }}>{position.symbol}</td>
      <td style={{ width: 95 }}>{position.units}</td>
      <td className="table-cell-right" style={{ width: 75 }}>
        {asMoney(Number(position.unit_value))}
      </td>
      <td className="table-cell-right" style={{ width: 100 }}>
        {asMoney(Number(position.total_value))}
      </td>
      <td className="table-cell-right" style={{ width: 100 }}>
        {asMoney(Number(position.cost_basis))}
      </td>
      <td className="table-cell-right">{asMoney(Number(position.unrealized_gain_loss))}</td>
    </tr>
  );
}

DeprecatedPositionsRow.propTypes = {
  position: PropTypes.shape({
    cost_basis: PropTypes.string,
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    position_type: PropTypes.string,
    symbol: PropTypes.string,
    total_value: PropTypes.string,
    unit_value: PropTypes.string,
    units: PropTypes.string,
    unrealized_gain_loss: PropTypes.string,
    value_currency: PropTypes.string,
    valued_at: PropTypes.string,
    wealth_account_id: PropTypes.number,
    wealth_account_name: PropTypes.string,
  }).isRequired,
};
