import classNames from 'classnames';
import { Form, Field, ErrorMessage, Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import * as Yup from 'yup';
import DashboardActions from 'react/member/actions/dashboard_actions';
import FormSelectSearchableField from 'react/shared/components/forms/form_select_searchable_field';
import TrueLinkModal from 'react/shared/components/true_link/lab/TrueLinkModal';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import bindAll from 'react/shared/utils/bind_all';
import tlFieldOptions from 'react/shared/utils/tl_field_options';

export default class DashboardOneTimePayeeUpdateForm extends React.Component {
  constructor(props) {
    super(props);
    bindAll(this);
  }

  initialValues() {
    const { disbursement } = this.props;

    return {
      payee_street1: disbursement.payee_street1 || '',
      payee_street2: disbursement.payee_street2 || '',
      payee_city: disbursement.payee_city || '',
      payee_state: disbursement.payee_state || '',
      payee_zip: disbursement.payee_zip || '',
      payee_client_account_number: disbursement.payee_client_account_number || '',
    };
  }

  validationSchema() {
    return Yup.object().shape({
      payee_street1: Yup.string()
        .required('Address Line 1 is required')
        .max(30, 'Address Line 1 must be at most 30 characters'),
      payee_street2: Yup.string()
        .nullable()
        .max(30, 'Address Line 2 must be at most 30 characters'),
      payee_city: Yup.string().required('City is required'),
      payee_state: Yup.string().max(30).required('State is required'),
      payee_zip: Yup.string()
        .required('Zip is required')
        .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, 'Format ##### or #####-#### Accepted'),
      payee_client_account_number: Yup.string()
        .required('Account number is required')
        .max(48, 'Memo must be at most 48 characters'),
    });
  }

  onSubmit(values, { setSubmitting }) {
    const { disbursement, closeModal } = this.props;
    const onRequestCompleted = () => {
      setSubmitting(false);
      closeModal();
    };

    values.override_payee_fields = true;
    values.only_update_payee = true;

    DashboardActions.updateDisbursement(
      disbursement.id,
      { disbursement: values },
      { onRequestCompleted },
    );
  }

  renderForm({ isSubmitting, isValid }) {
    return (
      <Form>
        <div className="tl-form-edit align-left">
          <p>Updating this address does not change the payee record</p>

          <div className="tl-fields-row">
            <div className="tl-field span3">
              <label className="tl-field-label" htmlFor="payee_street1">
                Address Line 1
              </label>

              <div className="tl-field-data">
                <Field name="payee_street1" type="text" />
              </div>

              <ErrorMessage name="payee_street1">
                {(msg) => <div className="tl-field-error">{msg}</div>}
              </ErrorMessage>
            </div>
          </div>

          <div className="tl-fields-row">
            <div className="tl-field span3">
              <label className="tl-field-label" htmlFor="payee_street2">
                Address Line 2
              </label>

              <div className="tl-field-data">
                <Field name="payee_street2" type="text" />
              </div>

              <ErrorMessage name="payee_street2">
                {(msg) => <div className="tl-field-error">{msg}</div>}
              </ErrorMessage>
            </div>
          </div>

          <div className="tl-fields-row">
            <div className="tl-field span3">
              <label className="tl-field-label" htmlFor="payee_city">
                City
              </label>

              <div className="tl-field-data">
                <Field name="payee_city" type="text" />
              </div>

              <ErrorMessage name="payee_city">
                {(msg) => <div className="tl-field-error">{msg}</div>}
              </ErrorMessage>
            </div>
          </div>

          <div className="tl-fields-row">
            <div className="tl-field span2">
              <label className="tl-field-label" htmlFor="payee_state">
                State
              </label>

              <div className="tl-field-data" id="bene-payee-state-dropdown">
                <Field
                  component={FormSelectSearchableField}
                  name="payee_state"
                  options={tlFieldOptions.stateOptions()}
                />
              </div>

              <ErrorMessage name="payee_state">
                {(msg) => <div className="tl-field-error">{msg}</div>}
              </ErrorMessage>
            </div>
          </div>

          <div className="tl-fields-row">
            <div className="tl-field span3">
              <label className="tl-field-label" htmlFor="payee_zip">
                Zip
              </label>

              <div className="tl-field-data">
                <Field name="payee_zip" type="text" />
              </div>

              <ErrorMessage name="payee_zip">
                {(msg) => <div className="tl-field-error">{msg}</div>}
              </ErrorMessage>
            </div>
          </div>

          <div className="tl-fields-row">
            <div className="tl-field span3">
              <label className="tl-field-label" htmlFor="payee_client_account_number">
                Memo
              </label>

              <div className="tl-field-data">
                <Field name="payee_client_account_number" type="text" />
              </div>

              <ErrorMessage name="payee_client_account_number">
                {(msg) => <div className="tl-field-error">{msg}</div>}
              </ErrorMessage>
            </div>
          </div>

          <div className="tl-fields-row">
            <div className="new-form__callout" style={{ marginTop: 10 }}>
              This text will appear on the 'Memo' line of every check sent to this payee. You will
              be able to add additional text to the 'Memo' line when you create specific
              disbursements as well.
            </div>
          </div>

          <div className="tl-form-actions-row">
            <TrueLinkButton
              className={classNames('btn btn-success', {
                disabled: isSubmitting || !isValid,
              })}
              disabled={isSubmitting || !isValid}
              type="submit"
              variant="none"
            >
              Update
            </TrueLinkButton>
          </div>
        </div>
      </Form>
    );
  }

  render() {
    const { closeModal } = this.props;
    return (
      <TrueLinkModal handleClose={closeModal} title={'Update Payee'}>
        <Formik
          component={this.renderForm}
          initialValues={this.initialValues()}
          onSubmit={this.onSubmit}
          validationSchema={this.validationSchema()}
        />
      </TrueLinkModal>
    );
  }
}

DashboardOneTimePayeeUpdateForm.propTypes = {
  closeModal: PropTypes.func,
  disbursement: PropTypes.shape({
    id: PropTypes.number,
    payee_street1: PropTypes.string,
    payee_street2: PropTypes.string,
    payee_city: PropTypes.string,
    payee_state: PropTypes.string,
    payee_zip: PropTypes.string,
    payee_client_account_number: PropTypes.string,
  }).isRequired,
};
