import I18n from 'i18n-js';
import PropTypes from 'prop-types';
import React from 'react';
import Overdue from './display/backup_card/Overdue';
import Released from './display/backup_card/Released';
import Section from 'react/member/card/components/account_tab/card_status/Section';
import CardStatusActions from 'react/member/card/components/account_tab/card_status/actions/CardStatusActions';
import { accountShape } from 'react/member/card/shapes/AccountShape';
import { cardShape } from 'react/member/card/shapes/CardShape';
import { cardholderShape } from 'react/member/card/shapes/CardholderShape';
import { OVERDUE_STATUS } from 'react/member/shapes/estimated_arrival_shape';
import { expeditedShipping } from 'react/member/utils/CardOrder';
import { CARD_STATUSES } from 'react/member/utils/CardStatus';
import localeShape from 'react/shared/shapes/locale_shape';

export default function BackupCardStatus({
  account,
  card,
  cardholder,
  localeProps,
  oldCardLast4,
  onBackupCardActivationSuccess,
  onBackupCardCloseSuccess,
}) {
  const { card_name: cardName, fbo_person_name: cardFboName, program_type: programType } = account;
  const {
    card_last_4: cardLast4,
    card_order: cardOrder,
    expiry_date: expiryDate,
    is_breached: cardBreached,
    shipment_tracking_number: cardShipmentTrackingNumber,
  } = card;
  const { estimatedArrival, shippingAddress } = cardOrder;

  const estimatedArrivalDate = estimatedArrival.arrivalDate;
  const estimatedArrivalStatus = estimatedArrival.status;
  const expedited = expeditedShipping(estimatedArrival.range);
  const supportPhone = I18n.t('corp.ad_tollfree_numeric', { locale: programType });

  const displayProps = {
    cardBreached,
    cardFboName,
    cardLast4,
    cardName,
    cardShipmentTrackingNumber,
    estimatedArrivalDate,
    expedited,
    expiryDate,
    oldCardLast4,
    shippingAddress,
  };

  const actionProps = {
    account,
    card,
    cardholder,
    localeProps,
    onCardActivationSuccess: onBackupCardActivationSuccess,
    onCardCloseSuccess: onBackupCardCloseSuccess,
  };

  let status;
  switch (estimatedArrivalStatus) {
    case OVERDUE_STATUS:
      status = CARD_STATUSES.OVERDUE_RELEASED_BACKUP_CARD_ORDER_STATUS;
      return (
        <Section footer={<CardStatusActions {...actionProps} status={status} />}>
          <Overdue {...displayProps} status={status} supportPhone={supportPhone} />
        </Section>
      );
    default:
      status = CARD_STATUSES.RELEASED_BACKUP_CARD_ORDER_STATUS;
      return (
        <Section footer={<CardStatusActions {...actionProps} status={status} />}>
          <Released {...displayProps} status={status} />
        </Section>
      );
  }
}

BackupCardStatus.propTypes = {
  account: accountShape.isRequired,
  card: cardShape.isRequired,
  cardholder: cardholderShape.isRequired,
  localeProps: localeShape.isRequired,
  oldCardLast4: PropTypes.string.isRequired,
  onBackupCardActivationSuccess: PropTypes.func.isRequired,
  onBackupCardCloseSuccess: PropTypes.func.isRequired,
};
