import PropTypes from 'prop-types';
import React from 'react';
import _mapObject from 'underscore/modules/mapObject';
import DashboardDeposits from './DashboardDeposits';
import DashboardRecurringDeposits from './DashboardRecurringDeposits';
import DashboardSelectOrganizationPopUp from './DashboardSelectOrganizationPopUp';
import DashboardDepositMultiple from './deposit_multiple/DashboardDepositMultiple';
import DashboardActions from 'react/member/actions/dashboard_actions';
import DepositPopUp from 'react/member/components/dashboard/deposits/DepositPopUp';
import userRoleShape from 'react/member/shapes/UserRoleShape';
import SubSectionHeader from 'react/shared/components/SubSectionHeader';
import TabContent from 'react/shared/components/TabContent';
import TabNavigation from 'react/shared/components/TabNavigation';
import DashboardClientStore from 'react/shared/stores/DashboardClientStore';
import bindAll from 'react/shared/utils/bind_all';

export default class DashboardDepositsTabContent extends React.Component {
  constructor(props) {
    super(props);

    const objectScope = function (props) {
      if (props.client) return 'client';
      else if (props.portfolio) return 'portfolio';
      else if (props.organization) return 'organization';
    };

    const columnListDefs = function (props) {
      const defaultDefs = {
        pending: ['expectedDeliveryDate', 'paymentMethod', 'expectedAmount', 'paymentId', 'edit'],
        recurring: ['frequency', 'paymentMethod', 'expectedAmount', 'paymentId', 'edit'],
        cleared: ['deliveryDate', 'amount', 'paymentMethod', 'depositType', 'paymentId', 'edit'],
        cancelled: [
          'deliveryDate',
          'paymentMethod',
          'expectedAmount',
          'paymentId',
          'cancelDate',
          'edit',
        ],
      };

      switch (objectScope(props)) {
        case 'client':
          return defaultDefs;
        default:
          return _mapObject(defaultDefs, (columnList, _key) => {
            columnList.unshift('trustBeneficiary');
            return columnList;
          });
      }
    };
    this.state = {
      depositNotificationModalOpen: false,
      depositMultipleModalOpen: false,
      objectScope: objectScope(props),
      columnListDefs: columnListDefs(props),
      pendingTableChangeItr: 0,
      clientList: [],
      clientListLoading: true,
      organizationSlug: this.props.allOrgs ? this.props.allOrgs[0].slug : null,
    };

    bindAll(this);
  }

  componentDidMount() {
    DashboardClientStore.on('clients.fetchSelectIds', this.onFetchClients);

    if (this.props.portfolio) {
      DashboardActions.fetchClientSelectIdsByPortfolio(this.props.portfolio.id);
    } else if (this.props.organization) {
      DashboardActions.fetchClientSelectIdsByOrganization(this.props.organization.slug);
    } else if (!this.props.client) {
      DashboardActions.fetchClientSelectIds();
    }
  }

  componentWillUnmount() {
    DashboardClientStore.off('clients.fetchSelectIds', this.onFetchClients);
  }

  tabList() {
    const tabSpecs = {
      'deposits-pending': {
        id: 'deposits-pending',
        title: 'Pending',
        className: 'btn btn-secondary padded-tabs',
      },
      'deposits-cleared': {
        id: 'deposits-cleared',
        title: 'Cleared',
        className: 'btn btn-secondary padded-tabs',
      },
      'deposits-cancelled': {
        id: 'deposits-cancelled',
        title: 'Cancelled',
        className: 'btn btn-secondary padded-tabs',
      },
    };
    return this.props.tabList.map((tabName) => tabSpecs[tabName]);
  }

  tabContentList() {
    const tabContent = {
      'deposits-pending': (
        <TabContent id="deposits-pending" key="deposits-pending-content">
          <SubSectionHeader>Pending one-time deposit records</SubSectionHeader>
          <DashboardDeposits
            client={this.props.client}
            clientList={this.state.clientList}
            columnList={this.state.columnListDefs.pending}
            defaultSort={{
              key: 'deliveryDate',
              direction: 'desc',
            }}
            key={`deposits-pending-${this.state.pendingTableChangeItr}`}
            organization={this.props.organization}
            portfolio={this.props.portfolio}
            refreshDeposits={this.refreshDeposits}
            statusFilter="pending"
            userRoles={this.props.userRoles}
          />
          <SubSectionHeader>Recurring deposit records</SubSectionHeader>
          <DashboardRecurringDeposits
            client={this.props.client}
            clientList={this.state.clientList}
            columnList={this.state.columnListDefs.recurring}
            key={`recurring-deposits-approved-${this.state.pendingTableChangeItr}`}
            organization={this.props.organization}
            portfolio={this.props.portfolio}
            refreshDeposits={this.refreshDeposits}
            statusFilter="approved"
            userRoles={this.props.userRoles}
          />
        </TabContent>
      ),
      'deposits-cleared': (
        <TabContent id="deposits-cleared" key="deposits-cleared-content">
          <SubSectionHeader>Cleared deposit records</SubSectionHeader>
          <DashboardDeposits
            client={this.props.client}
            clientList={this.state.clientList}
            columnList={this.state.columnListDefs.cleared}
            defaultSort={{
              key: 'deliveryDate',
              direction: 'desc',
            }}
            key="deposits-cleared"
            organization={this.props.organization}
            portfolio={this.props.portfolio}
            statusFilter="cleared"
            userRoles={this.props.userRoles}
          />
        </TabContent>
      ),
      'deposits-cancelled': (
        <TabContent id="deposits-cancelled" key="deposits-cancelled-content">
          <SubSectionHeader>Cancelled one-time deposit records</SubSectionHeader>
          <DashboardDeposits
            client={this.props.client}
            clientList={this.state.clientList}
            columnList={this.state.columnListDefs.cancelled}
            defaultSort={{
              key: 'deliveryDate',
              direction: 'desc',
            }}
            key="deposits-cancelled"
            organization={this.props.organization}
            portfolio={this.props.portfolio}
            statusFilter="cancelled"
            userRoles={this.props.userRoles}
          />
        </TabContent>
      ),
    };
    return this.props.tabList.map((tabName) => tabContent[tabName]);
  }

  onFetchClients(clients) {
    this.setState({
      clientList: clients,
      clientListLoading: false,
    });
  }

  refreshDeposits() {
    this.setState({
      pendingTableChangeItr: this.state.pendingTableChangeItr + 1,
    });
  }

  toggleAddDepositNotificationForm() {
    this.setState({
      depositNotificationModalOpen: !this.state.depositNotificationModalOpen,
    });
  }

  toggleDepositMultipleModal() {
    this.setState((prevState) => ({
      depositMultipleModalOpen: !prevState.depositMultipleModalOpen,
    }));
  }

  closeDepositMultipleModal() {
    this.setState({ depositMultipleModalOpen: false });
  }

  toggleSelectOrganizationModal() {
    this.setState((prevState) => ({
      selectOrganizationModalOpen: !prevState.selectOrganizationModalOpen,
    }));
  }

  moveToDepositMultipleModal() {
    this.toggleSelectOrganizationModal();
    this.toggleDepositMultipleModal();
  }

  onOrganizationSelect(e) {
    this.setState({
      organizationSlug: e.target.value,
    });
  }

  checkForMultipleOrganizations() {
    if (this.props.allOrgs) {
      this.toggleSelectOrganizationModal();
    } else {
      this.toggleDepositMultipleModal();
    }
  }

  renderSelectOrganizationPopUp() {
    return (
      <div>
        {this.state.selectOrganizationModalOpen && (
          <DashboardSelectOrganizationPopUp
            allOrgs={this.props.allOrgs}
            closeModal={this.toggleSelectOrganizationModal}
            onSelect={this.onOrganizationSelect}
            onSubmit={this.moveToDepositMultipleModal}
            selectedOrganizationSlug={this.state.organizationSlug}
          />
        )}
      </div>
    );
  }

  renderDepositPopUp() {
    if (this.state.depositNotificationModalOpen) {
      const objOpts = {};
      if (this.state.objectScope == 'client') objOpts['client'] = this.props.client;
      else if (this.state.objectScope == 'portfolio') objOpts['portfolio'] = this.props.portfolio;
      else if (this.state.objectScope == 'organization')
        objOpts['organization'] = this.props.organization;

      return (
        <DepositPopUp
          clientListLoading={this.state.clientListLoading}
          closeModal={this.toggleAddDepositNotificationForm}
          modalOpen
          refreshDeposits={this.refreshDeposits}
          trustBeneficiaries={this.state.clientList}
          {...objOpts}
        />
      );
    }
  }

  multiDepositSupported() {
    if (this.state.objectScope == 'client') return false;
    return this.props.showMultiDepositsButton;
  }

  renderDepositMultiplePopUp() {
    let organizationSlug;
    const objectScope = this.state.objectScope;

    if (this.props.allOrgs) {
      organizationSlug = this.state.organizationSlug;
    } else
      switch (objectScope) {
        case 'client':
          organizationSlug = this.props.client.organization.slug;
          break;
        case 'portfolio':
          organizationSlug = this.props.portfolio.organization.slug;
          break;
        case 'organization':
          organizationSlug = this.props.organization.slug;
          break;
        // No default
      }

    if (this.multiDepositSupported() && this.state.depositMultipleModalOpen) {
      return (
        <DashboardDepositMultiple
          onClose={this.closeDepositMultipleModal}
          organizationSlug={organizationSlug}
          refreshDeposits={this.refreshDeposits}
        />
      );
    }
  }

  renderTabContent() {
    if (this.props.tabList.length > 1) {
      return (
        <TabNavigation classOptions="btn-group" tabs={this.tabList()}>
          {this.tabContentList()}
        </TabNavigation>
      );
    }
    return <div>{this.tabContentList()}</div>;
  }

  render() {
    return (
      <div>
        {this.renderDepositPopUp()}
        {this.renderSelectOrganizationPopUp()}
        {this.renderDepositMultiplePopUp()}
        <div>
          <div className="pull-right" style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {this.multiDepositSupported() && (
              <button
                className="btn btn-success"
                onClick={this.checkForMultipleOrganizations}
                type="button"
              >
                Add multiple deposit records
              </button>
            )}
            <button
              className="btn btn-success"
              onClick={this.toggleAddDepositNotificationForm}
              style={{ maxHeight: '35px', marginLeft: '10px' }}
              type="button"
            >
              Add deposit record
            </button>
          </div>
          {this.renderTabContent()}
        </div>
      </div>
    );
  }
}

DashboardDepositsTabContent.propTypes = {
  client: PropTypes.object,
  portfolio: PropTypes.object,
  organization: PropTypes.object,
  showMultiDepositsButton: PropTypes.bool,
  tabList: PropTypes.array,
  allOrgs: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  userRoles: userRoleShape.isRequired,
};

DashboardDepositsTabContent.defaultProps = {
  tabList: ['deposits-pending', 'deposits-cleared', 'deposits-cancelled'],
};
