// This came from trying to find a way to avoid the [controller] mixin.
// We should avoid .bind() when possible
/* eslint-disable no-invalid-this */
import React from 'react';
import _extend from 'underscore/modules/extend';
import _has from 'underscore/modules/has';
import _isEmpty from 'underscore/modules/isEmpty';
import _keys from 'underscore/modules/keys';
import _map from 'underscore/modules/map';
import _omit from 'underscore/modules/omit';
import _values from 'underscore/modules/values';

export function getPayeeErrorInitialState(initial) {
  return _extend(
    {
      errored: false,
      errors: {},
      canUpdateParentErrorState:
        _has(this.props, 'addError') &&
        _has(this.props, 'removeError') &&
        _has(this.props, 'parentError'),
    },
    initial || {},
  );
}

export function addError(error) {
  this.setState({ errored: true });
  return this.setState({ errors: _extend(this.state.errors, error) });
}

export function removeError(errorKey) {
  const errors = _omit(this.state.errors, errorKey);
  return this.setState({ errors });
}

export function safeRemoveError(parentError) {
  if (this.props.removeError) {
    return this.props.removeError(parentError);
  }
}

export function safeAddError(parentError) {
  if (this.props.addError) {
    return this.props.addError(parentError);
  }
}

export function checkErrored() {
  if (this.state.errored) {
    if (_isEmpty(this.state.errors)) {
      return this.setState({ errored: false });
    }
  }
}

/**
 * If the form input is required, the length of the value is valid (i.e. the function
 * returns true) as long as it's equal to or between the min or max length. If it's
 * not required, zero is also a valid length for the input value and returns true.
 * @param {string} value Text input from user for element, this.props.name.
 * @param {number} minLength Defined minimum for element.
 * @param {number} maxLength Defined maximum for element.
 * @returns {boolean}
 */
export function inputLengthValid(value, minLength, maxLength) {
  if (this.props.requiredToSubmit) return value.length >= minLength && value.length <= maxLength;

  return value.length === 0 || (value.length >= minLength && value.length <= maxLength);
}

export function checkInputLength(ev, minLength, maxLength, attr_name) {
  if (inputLengthValid.bind(this)(ev.target.value, minLength, maxLength)) {
    removeError.bind(this)('invalidLength');
  } else {
    addError.bind(this)({
      invalidLength: `${attr_name} must be between ${minLength} and ${maxLength} characters`,
    });
  }
}

export function updateParentErrorState() {
  if (this.state.errored) return safeAddError.bind(this)(this.props.parentError);

  return safeRemoveError.bind(this)(_keys(this.props.parentError));
}

export function listErrors() {
  return _map(_values(this.state.errors, (errorText) => <li>{errorText}</li>));
}

/**
 * This check adds the cannotBeBlank error message to the component's errors array if
 * the text input value of the given component is blank (i.e. its length is zero). If
 * the text input is not required, the check is skipped. Not every component using
 * this method supports the required boolean prop, so to support backwards compatibility,
 * this function assumes that if this method was being called for an input prior to the
 * addition of the boolean prop, that's because that input is required, or at least is
 * meant to complete this check.
 * @param  {Event} ev jQuery event triggered in the DOM.
 * @param {boolean} required Denotes whether the text input is required (default: true)
 *  to submit the form.
 * @returns {*}
 */
export function checkBlank(ev, requiredToSubmit = true) {
  if (!requiredToSubmit) return;

  if (ev.target.value.trim().length === 0 && this.state.canUpdateParentErrorState) {
    const errorMessage = this.props.label + this.props.errorText.cannotBeBlank;
    return addError.bind(this)({ cannotBeBlank: errorMessage });
  }
}

export function clearBlankError() {
  return removeError.bind(this)('cannotBeBlank');
}
