import PropTypes from 'prop-types';
import React from 'react';
import HeaderExpandButton from './HeaderExpandButton';
import { trustBeneficiaryShape } from 'react/member/components/dashboard/trust_beneficiaries/profile/core_info/CoreInfoShape';
import InfoTooltip from 'react/shared/components/tooltips/InfoTooltip';
import TlFieldTransformers from 'react/shared/utils/tl_field_transformers';

export default function Header({
  trustBeneficiary,
  canEdit,
  onEditClick,
  allExpanded,
  collapseAll,
  expandAll,
}) {
  const trustName = () => TlFieldTransformers.truncateWithEllipses(trustBeneficiary.trust.name);

  const portfolioName = () => {
    const { activePortfolio } = trustBeneficiary;
    if (!(activePortfolio && activePortfolio.name)) return 'N/A';
    return TlFieldTransformers.truncateWithEllipses(activePortfolio.name);
  };

  const accountStatus = () => {
    if (trustBeneficiary.deceasedIndicator) {
      return `${trustBeneficiary.status} (Deceased)`;
    }

    return trustBeneficiary.status;
  };

  return (
    <div>
      <div className="toggle-section-header toggle-section-info-header expanded">
        <div className="info-header-main header-main">
          <div className="info-header-main-title">
            <h3>{trustBeneficiary.person.name}</h3>
          </div>
          <div className="info-header-main-content">
            <div className="info-column">
              <div className="info-item">
                <span className="header-label">Trust: </span>
                <span className="header-data">{trustName()}</span>
              </div>
              <div className="info-item">
                <span className="header-label">Portfolio: </span>
                <span className="header-data">{portfolioName()}</span>
              </div>
              <div className="info-item">
                <span className="header-label">Account Status: </span>
                <span className="header-data">{accountStatus()}</span>
              </div>
            </div>
            <div className="info-column bene-ids">
              <div className="info-item">
                <InfoTooltip placement="left" tooltipText="True Link ID" />
                <span className="header-label padding-5l">TID: </span>
                <span className="header-data">
                  {trustBeneficiary.person.trustBeneficiaryInternalId}
                </span>
              </div>
              <div className="info-item">
                <InfoTooltip placement="left" tooltipText="External ID" />
                <span className="header-label padding-5l">EID: </span>
                <span className="header-data">{trustBeneficiary.externalId}</span>
              </div>
              <div className="info-item">
                <InfoTooltip placement="left" tooltipText="Beneficiary ID" />
                <span className="header-label padding-5l">BID: </span>
                <span className="header-data">{trustBeneficiary.beneficiaryId}</span>
              </div>
            </div>
          </div>
          <div className="info-header-main-actions">
            <HeaderExpandButton
              allExpanded={allExpanded}
              collapseAll={collapseAll}
              expandAll={expandAll}
            />
          </div>
        </div>
        {canEdit ? (
          <div className="header-secondary">
            <div className="action-items">
              <button
                className="action-item btn btn-link edit-link"
                onClick={onEditClick}
                type="button"
              >
                <i className="fa fa-edit" />
                <span className="padding-10l">Edit Demographic Information</span>
              </button>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

Header.propTypes = {
  allExpanded: PropTypes.bool.isRequired,
  expandAll: PropTypes.func.isRequired,
  collapseAll: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
  trustBeneficiary: trustBeneficiaryShape.isRequired,
  onEditClick: PropTypes.func.isRequired,
};
