import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles({
  root: {
    textTransform: 'none',
  },
});

export default function TablePageButton({ disabled, onClick, value, ...passThroughProps }) {
  const classes = useStyles();
  return (
    <Button
      disabled={!!disabled}
      onClick={onClick}
      {...passThroughProps}
      className={classNames(classes.root, passThroughProps.className)}
    >
      {value}
    </Button>
  );
}

TablePageButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};
