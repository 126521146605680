import { ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import _isEmpty from 'underscore/modules/isEmpty';
import TextField from 'react/member/components/signups/signup_form/form_partials/fields/TextField';
import FieldLabel from 'react/member/components/signups/signup_form/form_partials/labels/field_label';

function DobField({ entity, labelPrefix, prefill, rowClass, colClass, dataAnalyticsPrefix }) {
  const dataAnalyticsKey = (fieldName) =>
    _isEmpty(dataAnalyticsPrefix) ? fieldName : `${dataAnalyticsPrefix}_${fieldName}`;

  return (
    <div className={rowClass}>
      <div className={colClass}>
        <FieldLabel
          afterLabel="mm-dd-yyyy"
          htmlFor={`${entity}_dob`}
          labelContent={labelPrefix ? `${labelPrefix} date of birth` : 'Date of birth'}
        />
        <TextField
          dataAnalyticsKey={dataAnalyticsKey('dob')}
          fast={!prefill}
          id={`${entity}_dob`}
          mask="99-99-9999"
          name={`${entity}.dob`}
          readOnly={prefill}
        />
        <ErrorMessage className="error" component="label" name={`${entity}.dob`} />
      </div>
    </div>
  );
}

DobField.propTypes = {
  entity: PropTypes.oneOf(['administrator', 'cardholder']).isRequired,
  labelPrefix: PropTypes.string,
  prefill: PropTypes.bool,
  rowClass: PropTypes.string,
  colClass: PropTypes.string,
  dataAnalyticsPrefix: PropTypes.string,
};

DobField.defaultProps = {
  labelPrefix: null,
  prefill: false,
  rowClass: 'card--form__row',
  colClass: 'card--form__col',
  dataAnalyticsPrefix: null,
};

export default DobField;
