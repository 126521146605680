import PropTypes from 'prop-types';
import React from 'react';
import DashboardActions from 'react/member/actions/dashboard_actions';
import AttachmentsContainer from 'react/member/components/attachments/drop_zone_uploader/AttachmentsContainer';
import AccountReceiptStore from 'react/member/stores/AccountReceiptStore';

export default function AccountReceipts({ accountId, canEdit }) {
  const actions = DashboardActions;
  const attachable = { id: accountId, slug: accountId };

  return (
    <AttachmentsContainer
      attachable={attachable}
      attachableType="Account"
      canEdit={canEdit}
      category={null}
      destroyFileAction={actions.V2destroyAttachment}
      dropzoneSuccessAction={actions.dropzoneAccountReceiptUploadSuccess}
      fetchFilesAndFoldersAction={actions.fetchAccountReceiptsAndFolders}
      fileType="receipt"
      store={AccountReceiptStore}
      updateFileAction={actions.updateAccountReceipt}
    />
  );
}

AccountReceipts.propTypes = {
  accountId: PropTypes.string.isRequired,
  canEdit: PropTypes.bool.isRequired,
};
