import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ReportDateRange from 'react/shared/card/components/reports_tab/ReportDateRange';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import TlFieldValidators from 'react/shared/utils/tl_field_validators';

const { dateRegExp } = TlFieldValidators;

const useStyles = makeStyles({
  wrapper: {
    marginTop: '20px',
    '& button': {
      marginRight: '5px',
    },
  },
});

export default function BlockedChargesReport(props) {
  const classes = useStyles();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const makeRoute = (params) => RailsRoutes.blocked_charges_report_path(props.accountId, params);

  const validDateFormats = () => {
    const valid = dateRegExp.test(startDate) && dateRegExp.test(endDate);
    if (!valid) {
      Truelink.flash('error', 'Invalid start or end date', true, true);
    }
    return valid;
  };

  const handleOpen = (event) => {
    event.preventDefault();
    if (!validDateFormats()) return;

    const route = makeRoute({ start_date: startDate, end_date: endDate });
    window.open(route);
  };

  const handleDownload = (event) => {
    event.preventDefault();
    if (!validDateFormats()) return;

    const route = makeRoute({ start_date: startDate, end_date: endDate, download: true });
    window.location.href = route;
  };

  return (
    <div className={classes.wrapper}>
      <ReportDateRange
        endDate={endDate}
        onSetEndDate={setEndDate}
        onSetStartDate={setStartDate}
        reportName="blocked_charges_report"
        startDate={startDate}
      />
      <TrueLinkButton onClick={handleOpen} variant="primary">
        Open
      </TrueLinkButton>
      <TrueLinkButton onClick={handleDownload} variant="primary">
        Download as Spreadsheet
      </TrueLinkButton>
    </div>
  );
}

BlockedChargesReport.propTypes = {
  accountId: PropTypes.string.isRequired,
};
