import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';
import ReactInputMask from 'react-input-mask';
import Field from './Field';
import FieldMixin from './FieldMixin';

const MaskedStringField = createReactClass({
  propTypes: {
    controller: PropTypes.object,
    editable: PropTypes.bool,
    label: PropTypes.string,
    model: PropTypes.string,
    name: PropTypes.string,
    object: PropTypes.object,
    inputDidMount: PropTypes.func,
    value: PropTypes.node,
    format: PropTypes.func,
    optional: PropTypes.bool,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    mask: PropTypes.string.isRequired,
  },

  mixins: [FieldMixin],

  renderShow() {
    return <span>{this.formattedValue()}</span>;
  },

  renderEdit() {
    return (
      <ReactInputMask
        mask={this.props.mask}
        name={this.inputName()}
        onChange={this.onChange}
        onKeyUp={this.keyup}
        placeholder={this.props.placeholder}
        ref={this.inputDidMount}
        required={this.props.required}
        type="text"
        value={this.value() || ''}
      />
    );
  },

  render() {
    return (
      <Field
        {...this.props}
        editable={this.editable}
        label={this.label}
        onKeyUp={this.onKeyUp}
        renderEdit={this.renderEdit}
        renderShow={this.renderShow}
      />
    );
  },
});

export default MaskedStringField;
