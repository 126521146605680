import PropTypes from 'prop-types';
import React from 'react';
import _extend from 'underscore/modules/extend';
import _isEmpty from 'underscore/modules/isEmpty';
import _omit from 'underscore/modules/omit';
import PayeeConfirmationPage from './PayeeConfirmationPage';
import DashboardActions from 'react/member/actions/dashboard_actions';
import userRoleShape from 'react/member/shapes/UserRoleShape';
import DashboardPayeeForm from 'react/shared/components/dashboard/payee/DashboardPayeeForm';
import AreYouSurePopUp from 'react/shared/components/popups/AreYouSurePopUp';
import PopUp from 'react/shared/components/popups/PopUp';
import { isRestrictedViewUser } from 'react/shared/utils/Authorization';
import bindAll from 'react/shared/utils/bind_all';
import { extractFormData } from 'react/shared/utils/form_serialization';

export default class DashboardPayee extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.initialState();

    bindAll(this);
  }

  componentDidUpdate() {
    return this.checkErroredState();
  }

  initialState() {
    const formData = this.buildFormData();
    return {
      formData,
      serializedData: [],
      paymentMethod: formData.payment_method || this.props.paymentMethod,
      payeeEdited: false,
      showConfirmationPage: !!this.props.startOnConfirmationPage,
      errors: {},
      errored: false,
      bannerMessage: 'Please correct the form fields highlighted below and submit again. Thanks!',
      showBanner: false,
      showDestructionModal: false,
    };
  }

  buildFormData() {
    let data;
    if (this.props.initialPayee) {
      data = this.props.initialPayee.attributes;
      data.payment_method = this.props.initialPayee.attributes.supportedPaymentMethods[0];
    } else {
      data = {
        type: 'payee',
        attributes: {
          paymentMethod: this.props.paymentMethod,
        },
        relationships: {
          address: {
            data: {
              attributes: {},
            },
          },
        },
      };
    }

    return data;
  }

  handlePaymentMethod(ev) {
    this.setState({ paymentMethod: ev.target.value });
  }

  checkMissing(data) {
    let missingRequiredFields = false;
    const address = data.address_attributes;
    switch (data.payment_method) {
      case 'EFT': {
        if (!data.bankRoutingNumber || !data.clientAccountNumber || !data.bankAccountNumber) {
          missingRequiredFields = true;
        }

        break;
      }
      case 'Check': {
        if (this.missingAddress(address)) {
          missingRequiredFields = true;
        }

        break;
      }
      case 'Wire': {
        if (!data.bankAccountNumber || !data.bankRoutingNumber || this.missingAddress(address)) {
          missingRequiredFields = true;
        }

        break;
      }
      // No default
    }
    return missingRequiredFields;
  }

  missingAddress(address) {
    return !address.street1 || !address.city || !address.state || !address.zip;
  }

  addToErrorState(error) {
    const errors = _extend(this.state.errors, error);
    this.setState({ errors });
  }

  removeFromErrorState(errorKey) {
    const errors = _omit(this.state.errors, errorKey);
    this.setState({ errors });
  }

  checkErroredState() {
    if (_isEmpty(this.state.errors) && this.state.errored) {
      this.setState({ errored: false });
      if (this.state.showBanner) {
        this.setState({ bannerMessage: 'All errors have been fixed!' });
      }
    } else if (!_isEmpty(this.state.errors) && !this.state.errored) {
      this.setState({ errored: true });
      if (this.state.showBanner) {
        this.setState({
          bannerMessage: 'Please correct the form fields below and submit again.',
        });
      }
    }
  }

  extract(serializedArray) {
    const newData = extractFormData(serializedArray);
    if (this.checkMissing(newData)) {
      this.requestMissingData();
    } else if (!this.state.errored) {
      delete newData.confirm_bank_account_number;
      delete newData.confirm_bank_routing_number;
      this.setState({
        formData: newData,
        showConfirmationPage: true,
        payeeEdited: true,
        serializedData: serializedArray,
        showBanner: false,
      });
    }
  }

  requestMissingData() {
    this.setState({
      showBanner: true,
      bannerMessage: 'Please fill out the required fields',
    });
  }

  handleSubmit(ev) {
    if (ev) {
      ev.preventDefault();
    }

    if (this.state.errored) {
      this.setState({ showBanner: true });
    } else {
      const form = this.props.initialPayee ? $('#edit_payee_form') : $('#new_payee_form');
      const data = form.serializeArray();
      this.extract(data);
    }
  }

  editPayee(ev) {
    if (ev) {
      ev.preventDefault();
    }
    this.setState({ showConfirmationPage: false });
  }

  confirmNewPayee(ev) {
    if (ev) {
      ev.preventDefault();
    }
    const data = this.state.formData;
    data.trust_beneficiary_slug = this.props.clientSlug;
    if (this.props.initialPayee) {
      DashboardActions.updateBeneficiaryPayee(data);
    } else {
      this.props.createPayeeAction(data);
    }

    this.props.onClose(ev);
    this.setState(this.initialState());
  }

  isEditForm() {
    return this.props.formType === 'edit';
  }

  isRestrictedViewUserEditing() {
    return isRestrictedViewUser() && this.isEditForm();
  }

  shouldShowConfirmationPage() {
    return this.state.showConfirmationPage || this.isRestrictedViewUserEditing();
  }

  onClose(ev) {
    ev.preventDefault();
    this.props.onClose(ev);
    this.setState(this.initialState());
  }

  showDestructionModal() {
    this.setState({ showDestructionModal: true });
  }

  closeDestructionModal() {
    this.setState({ showDestructionModal: false });
  }

  buttonBar() {
    if (this.shouldShowConfirmationPage()) {
      const editButton =
        !this.isRestrictedViewUserEditing() && !this.props.userRoles.viewOnly ? (
          <button className="normal btn btn-alert" onClick={this.editPayee} type="button">
            Edit
          </button>
        ) : undefined;

      const deleteButton =
        this.isEditForm() && !isRestrictedViewUser() && !this.props.userRoles.viewOnly ? (
          <button className="btn normal" onClick={this.showDestructionModal} type="button">
            Delete
          </button>
        ) : undefined;

      const submitButton = (() => {
        if (this.isEditForm()) {
          if (this.state.payeeEdited && !isRestrictedViewUser()) {
            return (
              <button className="btn btn-success" onClick={this.confirmNewPayee} type="submit">
                Save
              </button>
            );
          }
        } else {
          return (
            <button className="btn btn-success" onClick={this.confirmNewPayee} type="submit">
              Create
            </button>
          );
        }
      })();

      return (
        <div className="flex" style={{ justifyContent: 'space-between' }}>
          <button
            className="btn btn-default cancel"
            id="cancel_reissue"
            onClick={this.onClose}
            type="button"
          >
            Cancel
          </button>
          {editButton}
          {deleteButton}
          {submitButton}
        </div>
      );
    }
    return (
      <div className="flex" style={{ justifyContent: 'space-between' }}>
        <button
          className="btn btn-default cancel"
          id="cancel_reissue"
          onClick={this.onClose}
          type="button"
        >
          Cancel
        </button>
        <button
          className="btn btn-success pull-left"
          disabled={!this.state.paymentMethod}
          onClick={this.handleSubmit}
          type="submit"
        >
          Next
        </button>
      </div>
    );
  }

  destroy(ev) {
    ev.preventDefault && ev.preventDefault();
    DashboardActions.destroyPayee(this.props.initialPayee.id);
    this.closeDestructionModal();
  }

  payeeConfirmationPage() {
    const formData = this.isRestrictedViewUserEditing()
      ? this.buildFormData()
      : this.state.formData;

    return (
      <PayeeConfirmationPage
        formData={formData}
        formSubmitted={this.shouldShowConfirmationPage()}
      />
    );
  }

  payeeForm() {
    if (this.props.initialPayee) {
      return (
        <DashboardPayeeForm
          addError={this.addToErrorState}
          clientSlug={this.props.clientSlug}
          displayedPaymentMethods={this.props.displayedPaymentMethods}
          formData={this.state.formData}
          formType="edit"
          handlePaymentMethod={this.handlePaymentMethod}
          initialPayee={this.props.initialPayee}
          needs1099Form={this.props.initialPayee.needs_1099_form}
          paymentMethod={this.state.paymentMethod}
          removeError={this.removeFromErrorState}
          updateParentErrorState={this.updateErrorState}
        />
      );
    }
    return (
      <DashboardPayeeForm
        addError={this.addToErrorState}
        clientSlug={this.props.clientSlug}
        displayedPaymentMethods={this.props.displayedPaymentMethods}
        formData={this.state.formData}
        formType="new"
        handlePaymentMethod={this.handlePaymentMethod}
        needs1099Form={false}
        paymentMethod={this.state.paymentMethod}
        removeError={this.removeFromErrorState}
      />
    );
  }

  updateErrorState(errored) {
    this.setState({ errored });
  }

  getHeaderText() {
    if (!this.props.initialPayee) {
      return 'New Payee';
    }
    if (isRestrictedViewUser() || this.props.startOnConfirmationPage) {
      return 'View Payee';
    }
    return 'Edit Payee';
  }

  render() {
    return (
      <PopUp
        bannerMessage={this.state.bannerMessage}
        footer={this.buttonBar()}
        header={this.getHeaderText()}
        maxWidth="640px"
        openModal
        showBanner={this.state.showBanner}
      >
        {this.shouldShowConfirmationPage() ? this.payeeConfirmationPage() : this.payeeForm()}
        {this.state.showDestructionModal && (
          <AreYouSurePopUp
            messageText={'Are you sure you want to delete this payee?'}
            onClose={this.closeDestructionModal}
            onSubmit={this.destroy}
            openModal={this.state.showDestructionModal}
            submitText={'Yes, delete'}
          />
        )}
      </PopUp>
    );
  }
}

DashboardPayee.propTypes = {
  clientSlug: PropTypes.string,
  formType: PropTypes.oneOf(['new', 'edit']),
  onClose: PropTypes.func.isRequired,
  initialPayee: PropTypes.object,
  displayedPaymentMethods: PropTypes.array,
  paymentMethod: PropTypes.string,
  startOnConfirmationPage: PropTypes.bool,
  createPayeeAction: PropTypes.func,
  userRoles: userRoleShape.isRequired,
};

DashboardPayee.defaultProps = {
  createPayeeAction: DashboardActions.addBeneficiaryPayee,
};
