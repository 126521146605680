/* istanbul ignore file */
import PropTypes from 'prop-types';
import React from 'react';

export default function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
      value={filterValue || ''}
    />
  );
}

DefaultColumnFilter.propTypes = {
  column: PropTypes.shape({
    filterValue: PropTypes.array,
    preFilteredRows: PropTypes.array,
    setFilter: PropTypes.func,
    id: PropTypes.string,
  }),
};
