const mockApprovedDisbursements = [
  {
    deliveryDate: '12/27/2022',
    id: 'dis_319b8536-ea4b-4623-bf80-3e2210602628',
    attributes: {
      id: 'dis_319b8536-ea4b-4623-bf80-3e2210602628',
      amount: {
        amount: 12,
        currency: 'USD',
      },
      clearedDate: null,
      deliveryDate: '2022-12-27',
      paymentMethod: 'Check',
      status: 'Approved',
      requestorDescription: null,
      payeeName: 'Test Payee',
      payeeDescription: 'Payee Description',
      postageCode: 1,
      postageType: 'USPS First Class',
      checkNum: null,
      checkMemoLine: '',
      payeeMemo: '',
      payeeSpecialInstructions: null,
      payeeAddress: {
        street1: 'Test address 1',
        street2: '',
        city: 'Grah Rah',
        state: 'MI',
        zip: '49503-1234',
        addressType: null,
      },
      decisions: [],
      disbursementCategoryAmounts: [
        {
          attributes: { amount: { amount: '10.00' }, category: 'Medical', notes: 'mock notes' },
          id: '1',
        },
      ],
    },
    meta: {
      canAddAttachment: true,
    },
    relationships: {
      decisions: [],
      disbursementCategoryAmounts: {
        data: [
          {
            id: '123789',
            type: 'disbursement_category_amount',
          },
        ],
      },
      sourceBankAccount: null,
    },
  },
];

export default mockApprovedDisbursements;
