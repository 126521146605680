import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';
import _contains from 'underscore/modules/contains';
import _union from 'underscore/modules/union';
import _without from 'underscore/modules/without';
import Field from './Field';
import FieldMixin from './FieldMixin';

const OptionsField = createReactClass({
  propTypes: {
    options: PropTypes.array,
  },

  mixins: [FieldMixin],

  getInitialState() {
    return {
      selected: [],
    };
  },

  componentDidMount() {
    this.initField();
  },

  getPaymentMethodLabel(paymentMethodKey) {
    return $tlf.domains.paymentMethodsMap[paymentMethodKey];
  },

  renderShow() {
    const selected = this.value() || [];
    return selected.map(this.getPaymentMethodLabel).join(', ');
  },

  renderEdit() {
    const selected = this.value();
    const inputName = `${this.inputName()}[]`;
    const onCheck = this.onCheck;
    const checks = this.props.options.map((option) => (
      // eslint-disable-next-line jsx-a11y/label-has-associated-control
      <label key={option}>
        <input
          data-checkbox-style="multiple"
          defaultChecked={_contains(selected, option)}
          name={inputName}
          onChange={onCheck}
          type="checkbox"
          value={option}
        />
        {$tlf.domains.paymentMethodsMap[option]}
      </label>
    ));

    return <div style={{ marginLeft: 25 }}>{checks}</div>;
  },

  onCheck(evt) {
    const c = evt.target;
    const selected = this.value();
    let newSelected;
    if (c.checked) {
      newSelected = _union(selected, [c.value]);
    } else {
      newSelected = _without(selected, c.value);
    }

    return this.change(newSelected);
  },

  render() {
    return (
      <Field
        {...this.props}
        editable={this.editable}
        label={this.label}
        renderEdit={this.renderEdit}
        renderShow={this.renderShow}
      />
    );
  },
});

OptionsField.propTypes = {
  options: PropTypes.array,
};

export default OptionsField;
