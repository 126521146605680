import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import TrueLinkModal from 'react/shared/components/true_link/lab/TrueLinkModal';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';

export default function TLAreYouSureModal({
  messageText,
  buttonClass,
  handleClose,
  headerText,
  onSubmit,
  submitText,
}) {
  const [isSubmitting, updateSubmitting] = useState(false);

  const onSubmitPressed = (ev) => {
    ev.preventDefault();
    updateSubmitting(true);
    onSubmit();
  };

  return (
    <TrueLinkModal handleClose={handleClose} submitText={submitText} title={headerText}>
      <div>
        {messageText}
        <form onSubmit={onSubmitPressed}>
          <div className="tl-form-actions-row">
            <TrueLinkButton
              className={classNames('btn margin-top-10', buttonClass, {
                disabled: isSubmitting,
              })}
              disabled={isSubmitting}
              type="submit"
              variant="none"
            >
              {isSubmitting ? 'Updating...' : submitText}
            </TrueLinkButton>
          </div>
        </form>
      </div>
    </TrueLinkModal>
  );
}

TLAreYouSureModal.propTypes = {
  buttonClass: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  headerText: PropTypes.string.isRequired,
  messageText: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitText: PropTypes.string.isRequired,
};
