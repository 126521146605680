import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import ActionMenuDropdown from 'react/member/components/common/ActionMenuDropdown';
import { fileShape, fileTypeShape } from 'react/member/shapes/attachments_shapes';
import TrueLinkChip from 'react/shared/components/true_link/main/TrueLinkChip';

const CARD_TRANSACTION = 'CardTransaction';

export default function FileRow({
  canEdit,
  file,
  fileType,
  downloadUrl,
  onUpdateFile,
  onDeleteFile,
}) {
  const formatDate = useCallback((date, includeTime = false) => {
    if (!date) return 'none';

    return moment(date).format(`M/D/YYYY${includeTime ? ' h:mm A' : ''}`);
  }, []);

  const getFileDetails = useCallback(() => {
    const { created_at, content_date, uploader } = file;

    return fileType === 'receipt'
      ? `Receipt date: ${formatDate(content_date)} - Uploaded by: ${uploader}`
      : `${formatDate(created_at, true)} - ${uploader}`;
  }, [file, fileType, formatDate]);

  return (
    <div className="browseable-files__row--file" id={`file-row-${file.id}`}>
      <span>
        <div className="file-title">
          {file.attachable_type === CARD_TRANSACTION && (
            <div>
              <TrueLinkChip
                className="transaction-label"
                color="none"
                label="TRANSACTION"
                size="small"
              />
            </div>
          )}
          <div className="file-name">{file.name}</div>
        </div>
        <div className="file-details">{getFileDetails()}</div>
      </span>
      <span className="row-actions">
        <a data-document-name={file.name} href={downloadUrl} rel="noreferrer" target="_blank">
          Download
        </a>
        {canEdit && (
          <span style={{ marginLeft: '30px' }}>
            <ActionMenuDropdown
              menuItems={[
                {
                  displayText: {
                    document: 'Move document to folder',
                    receipt: 'Change receipt date',
                  }[fileType],
                  action: () => onUpdateFile(file),
                  hide: file.attachable_type === 'CardTransaction',
                },
                {
                  displayText: `Delete ${fileType}`,
                  action: () => onDeleteFile(file),
                  hide: false,
                },
              ].filter((item) => !item.hide)}
            />
          </span>
        )}
      </span>
    </div>
  );
}

FileRow.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  file: fileShape.isRequired,
  fileType: fileTypeShape.isRequired,
  downloadUrl: PropTypes.string.isRequired,
  onUpdateFile: PropTypes.func.isRequired,
  onDeleteFile: PropTypes.func.isRequired,
};
