import moment from 'moment-timezone';
import numeral from 'numeral';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import _contains from 'underscore/modules/contains';
import _each from 'underscore/modules/each';
import _map from 'underscore/modules/map';
import _range from 'underscore/modules/range';
import _reduce from 'underscore/modules/reduce';
import DashboardBudgetItemAttachmentStore from 'react/member/stores/DashboardBudgetItemAttachmentStore';
import { fetchOrg } from 'react/shared/apis/organization_api';
import { getPostageTypeDisplayValue } from 'react/shared/utils/Postal';

export default function ConfirmationPage(props) {
  const [disbursementApprovalRequired, setDisbursementApprovalRequired] = useState(null);

  const fetchOrgMetadata = async (orgSlug) => {
    try {
      const response = await fetchOrg(orgSlug, {
        analysisFields: 'disbursementApprovalRequired',
      });
      setDisbursementApprovalRequired(response.data.data.meta.disbursementApprovalRequired);
    } catch (error) {
      // eslint-disable-next-line no-console
      window.console.error(error);
    }
  };

  useEffect(() => {
    if (props.model === 'budgetItem' && props.organizationSlug) {
      fetchOrgMetadata(props.organizationSlug);
    }
  }, [props.organizationSlug, props.model]);

  const getValue = (object, inputName) => {
    if (_contains(inputName, '.')) {
      const attrs = inputName.split('.');
      let target = object[attrs.shift()];
      _each(attrs, (attr) => (target = target[attr]));
      return target;
    }
    return object && object[inputName];
  };

  const displayDate = (dateString) => {
    if (!dateString.includes('/')) {
      return moment(dateString, 'YYYY-MM-DD').format('dddd, MMMM Do YYYY');
    }
    return moment(dateString, 'MM/DD/YYYY').format('dddd, MMMM Do YYYY');
  };

  const formatInput = (input, i) => {
    let output;
    const data = props.formData;
    const { model } = props;
    if (input.type) {
      let date;
      switch (input.type) {
        case 'money': {
          let amount = Number(data[input.inputName]);
          if (input.negative === true) {
            amount = -amount;
          }
          amount = numeral(amount).format('$0,0.00');
          output = (
            <tr className="form-group" key={i}>
              <td className={`${model}_confirmation_label`} style={{ width: 350 }}>
                {input.labelName}
              </td>
              <td className={`${model}_confirmation_input`}>{amount}</td>
            </tr>
          );
          break;
        }
        case 'date': {
          if (input.inputName.slice(-5) === '_date') {
            const approvalOrCreation = disbursementApprovalRequired ? 'approval' : 'creation';
            if (data[input.inputName]) {
              date = displayDate(data[input.inputName]);
            } else if (props.organizationSlug && disbursementApprovalRequired === null) {
              // Don't display the field while it's still loading to prevent a flash of the default value
              date = '';
            } else if (props.formData.payment_method !== 'Card') {
              date = `Upon ${approvalOrCreation}`;
            } else {
              date = `Immediately upon ${approvalOrCreation}`;
            }
            output = (
              <tr className="form-group" key={i}>
                <td className={`${model}_confirmation_label`} style={{ width: 350 }}>
                  {input.labelName}
                </td>
                <td className={`${model}_confirmation_input`}>{date}</td>
              </tr>
            );
          } else if (input.inputName.indexOf('day_of') === 0) {
            const day = Number(data[input.inputName]);
            if (input.inputName.includes('week')) {
              date = moment().day(day).format('dddd');
            } else {
              date = moment().date(day).format('Do');
            }
            output = (
              <tr className="form-group" key={i}>
                <td className={`${model}_confirmation_label`} style={{ width: 350 }}>
                  {input.labelName}
                </td>
                <td className={`${model}_confirmation_input`}>{date}</td>
              </tr>
            );
          }
          break;
        }
        case 'checkbox': {
          const displayVal = _contains(
            [true, 'true', 'on'],
            // Ideally this would be tighter controlled, but it is legacy
            // eslint-disable-next-line no-eq-null
            data[input.inputName] != null ? data[input.inputName] : { Yes: 'No' },
          );
          output = (
            <tr className={`form-group ${input.inputName}_checkbox_row`} key={i}>
              <td className={`${model}_confirmation_label`}>{input.labelName}</td>
              <td className={`${model}_confirmation_input`}>{displayVal}</td>
            </tr>
          );
          break;
        }
        case 'recurring_event': {
          const recurringType = data.recurring_type;
          const frequency = Number.parseInt(data.frequency, 10);
          const every = frequency === 1 ? 'Every ' : `Every ${frequency} `;
          const explanation = (() => {
            switch (recurringType) {
              case 'every_n_weeks': {
                let weekdays = _range(5).map((n) => moment.weekdays(n + 1));
                // this is a hack that should be removed when ConfirmationPage and DashboardBudgetItemForm get refactored to functional/non-jquery components
                // this adds an element to the beginning of the array because data.day_of_week's index starts at 1, so without this Monday becomes Tuesday etc...
                weekdays = ['', ...weekdays];
                const result = `${
                  every + pluralize('week', Number.parseInt(data.frequency, 10))
                } on ${weekdays[data.day_of_week]}`;

                return result;
              }
              case 'every_n_months': {
                return `${
                  every + pluralize('month', Number.parseInt(data.frequency, 10))
                } on the ${numeral(data.day_of_month).format('0o')}`;
              }
              case 'twice_monthly_every_n_months': {
                return `${
                  every + pluralize('month', Number.parseInt(data.frequency, 10))
                } on the ${numeral(data.day_of_month).format('0o')} and ${numeral(
                  data.day_of_month_2,
                ).format('0o')}`;
              }
            }
          })();
          output = (
            <tr className="form-group" key={i}>
              <td className={`${model}_confirmation_label`} style={{ width: 350 }}>
                {input.labelName}
              </td>
              <td className={`${model}_confirmation_input`}>{explanation}</td>
            </tr>
          );
          break;
        }
        case 'address': {
          const format = input.addressFormat;
          const line1 = (
            <span>
              {data.address_attributes[format.shift()]} <br />
            </span>
          );
          const line2 = (() => {
            if (data.address_attributes[format[0]]) {
              return (
                <span>
                  {data.address_attributes[format.shift()]} <br />
                </span>
              );
            }
            format.shift();
            return null;
          })();
          const city = data.address_attributes[format.shift()];
          const state = data.address_attributes[format.shift()];
          let line3 = state ? `${city}, ${state}` : '';
          if (format[0]) {
            line3 += ` ${data.address_attributes[format.shift()]}`;
          }
          output = (
            <tr key={i}>
              <td className={`${model}_confirmation_label`} style={{ width: 350 }}>
                {input.labelName}
              </td>
              <td className={`${model}_confirmation_input`}>
                {line1}
                {line2}
                {line3}
              </td>
            </tr>
          );
          break;
        }
        case 'attachment': {
          const attachmentKeys = Object.keys(props.formData).filter((key) =>
            /attachment_ids/.test(key),
          );
          const attachmentIds = _reduce(
            attachmentKeys,
            (memo, key) => memo.concat(props.formData[key]),
            [],
          ).map(Number);
          const attachments =
            DashboardBudgetItemAttachmentStore.getCachedAttachments(attachmentIds);

          const attachmentList = _map(attachments, (attachment) => {
            const smartpayablesInsert =
              attachment.smartpayables_insert || attachment.smartpayablesInsert ? (
                <span className="italic" style={{ paddingLeft: 40 }}>
                  Document included as an insert
                </span>
              ) : undefined;
            return (
              <p key={attachment.id}>
                <a href={RailsRoutes.api_v2_attachment_path(attachment.id)}>{attachment.name}</a>{' '}
                {smartpayablesInsert}
              </p>
            );
          });
          output = (
            <tr className="form-group" key={i}>
              <td className={`${model}_confirmation_label`} style={{ width: 350 }}>
                {input.labelName}
              </td>
              <td className={`${model}_confirmation_input`}>{attachmentList}</td>
            </tr>
          );
          break;
        }
        case 'postage_code': {
          if (data.postage_code) {
            output = (
              <tr className="form-group" key={i}>
                <td className={`${model}_confirmation_label`} style={{ width: 350 }}>
                  {input.labelName}
                </td>
                <td className={`${model}_confirmation_input`}>
                  {getPostageTypeDisplayValue(data.postage_code)}
                </td>
              </tr>
            );
          }
          break;
        }
        case 'needs_1099_form': {
          const data1099 = data.needs_1099_form || data.needs1099Form;
          if (data1099) {
            const needs_1099_form = _contains([true, 'true', 'on'], data1099) ? 'Yes' : 'No';
            output = (
              <tr className="form-group" key={i}>
                <td className={`${model}_confirmation_label`} style={{ width: 350 }}>
                  {input.labelName}
                </td>
                <td className={`${model}_confirmation_input`}>{needs_1099_form}</td>
              </tr>
            );
          }
          break;
        }
        default: {
          output = (
            <tr className="form-group" key={i}>
              <td className={`${model}_confirmation_label`} style={{ width: 350 }}>
                {input.labelName}
              </td>
              <td className={`${model}_confirmation_input`}>{data[input.inputName]}</td>
            </tr>
          );
        }
      }
    } else if (input.store_attribute) {
      const { object } = input;
      const value = getValue(object, input.inputName);
      output = (
        <tr className="form-group" key={i}>
          <td className={`${model + input.inputName}_confirmation_label`} style={{ width: 350 }}>
            {input.labelName}
          </td>
          <td className={`${model + input.inputName}_confirmation_label`}>{value}</td>
        </tr>
      );
    } else if (input.inputName === 'payment_method') {
      output = (
        <tr className="form-group" key={i}>
          <td className={`${model}_confirmation_label`} style={{ width: 350 }}>
            {input.labelName}
          </td>
          <td className={`${model}_confirmation_input`}>
            {$tlf.domains.paymentMethodsMap[data[input.inputName]]}
          </td>
        </tr>
      );
    } else {
      output = (
        <tr className="form-group" key={i}>
          <td className={`${model}_confirmation_label`} style={{ width: 350 }}>
            {input.labelName}
          </td>
          <td className={`${model}_confirmation_input`}>{data[input.inputName]}</td>
        </tr>
      );
    }
    return output;
  };

  const inputsList = () => {
    if (props.formSubmitted) {
      return _map(props.inputs, formatInput);
    }
  };

  return (
    <table className="table table-striped">
      <tbody>{inputsList()}</tbody>
    </table>
  );
}

ConfirmationPage.propTypes = {
  formData: PropTypes.object.isRequired,
  formSubmitted: PropTypes.bool.isRequired,
  inputs: PropTypes.arrayOf(PropTypes.object).isRequired,
  model: PropTypes.string.isRequired,
  organizationSlug: PropTypes.string,
};
