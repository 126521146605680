import PropTypes from 'prop-types';
import React from 'react';
import CardholderPersonalizationOptions from './CardholderPersonalizationOptions';
import { DashboardCardContext } from 'react/member/card/components/onboarding_widget/DashboardCardContext';
import { isAbleActProgramAccount } from 'react/shared/utils/account_program_type';
import bindAll from 'react/shared/utils/bind_all';

export default class BulkCardOrderConfirmationBox extends React.Component {
  constructor(props, context) {
    super(props, context);
    if (props.cardContext) {
      BulkCardOrderConfirmationBox.contextType = DashboardCardContext;
    } else {
      BulkCardOrderConfirmationBox.contextTypes = {
        accountId: PropTypes.string,
        cardholder: PropTypes.object,
        bulkCardOrder: PropTypes.bool,
        programName: PropTypes.string,
      };
    }

    bindAll(this);
  }

  cardholderName() {
    const { first_name, last_name } = this.context.cardholder;
    return `${first_name} ${last_name}`;
  }

  fundingAndSpendingMonitorDetails() {
    if (!isAbleActProgramAccount(this.context.programName)) {
      return ` Please proceed to ${this.cardholderName()}'s
      dashboard to fund the card and configure Spending Settings.`;
    }
  }

  render() {
    return (
      <div>
        Success! This card is now activated and listed on your dashboard under{' '}
        {this.cardholderName()}.{this.fundingAndSpendingMonitorDetails()}
        {this.context.bulkCardOrder && <CardholderPersonalizationOptions />}
      </div>
    );
  }
}

BulkCardOrderConfirmationBox.propTypes = {
  cardContext: PropTypes.bool,
};
