import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import AccountReceiptStore from 'react/member/stores/AccountReceiptStore';
import SubSectionHeader from 'react/shared/components/SubSectionHeader';
import ReactDropdown from 'react/shared/components/forms/ReactDropdown';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import bindAll from 'react/shared/utils/bind_all';

export default class MonthlyStatements extends React.Component {
  constructor(props) {
    super(props);

    const mostRecentMonth = props.monthlyStatementDates[0];

    this.state = {
      selectedMonth: mostRecentMonth,
      ...this.calculateReceiptState(mostRecentMonth, false),
    };

    bindAll(this);
  }

  componentDidMount() {
    AccountReceiptStore.on('receiptsAndFolders.fetch', this.refreshReceipts);
  }

  componentWillUnmount() {
    AccountReceiptStore.on('receiptsAndFolders.fetch', this.refreshReceipts);
  }

  refreshReceipts() {
    if (!this.props.showIncludeReceiptsCheckbox) return null;

    this.setState(({ selectedMonth, includeReceipts }) =>
      this.calculateReceiptState(selectedMonth, includeReceipts),
    );
  }

  calculateReceiptState(month, includeReceipts) {
    if (!this.props.showIncludeReceiptsCheckbox || !month) {
      return { enableReceipts: false, includeReceipts: false };
    }

    const enabled = AccountReceiptStore.getReceiptCountByMonth(month.date) > 0;

    return {
      enableReceipts: enabled,
      includeReceipts: enabled ? includeReceipts : false,
    };
  }

  hasMonthlyStatementDates() {
    return this.props.monthlyStatementDates && this.props.monthlyStatementDates.length > 0;
  }

  route(...params) {
    return this.props.isCardholder
      ? RailsRoutes.cardholder_official_statement_path(...params)
      : RailsRoutes.official_statement_path(...params);
  }

  handleChange({ target: { selectedIndex } }) {
    const selectedMonth = this.props.monthlyStatementDates[selectedIndex];

    this.setState((prevState) => ({
      selectedMonth,
      ...this.calculateReceiptState(selectedMonth, prevState.includeReceipts),
    }));
  }

  onSubmit({ format = 'pdf', download }) {
    const {
      includeReceipts,
      selectedMonth: { card_slug, date: month },
    } = this.state;

    const routeParams = { month, format, download };
    if (includeReceipts) routeParams.include_receipts = true;

    const route = this.route(card_slug, routeParams);

    if (download) {
      window.location.href = route;
    } else {
      window.open(route);
    }
  }

  renderOptions() {
    if (this.hasMonthlyStatementDates()) {
      return this.props.monthlyStatementDates.map((monthlyStatementDate) => {
        const { date, last_4_digits } = monthlyStatementDate;
        const formattedDate = moment(date).format('MMMM YYYY');
        return {
          id: `${date}-${last_4_digits}`,
          name: `${formattedDate} (${last_4_digits})`,
        };
      });
    }
    return [
      {
        id: 'N/A',
        name: 'No Monthly Statements',
      },
    ];
  }

  updateIncludeReceipts() {
    if (!this.state.enableReceipts) return;

    this.setState((prevState) => ({
      includeReceipts: !prevState.includeReceipts,
    }));
  }

  renderIncludeReceiptsCheckbox() {
    if (!this.props.showIncludeReceiptsCheckbox) return;

    const disabled = !this.state.enableReceipts;

    const classList = ['new-form__checkbox'];
    if (disabled) classList.push('disabled');

    return (
      <label className={classList.join(' ')} htmlFor="include_receipts" style={{ marginTop: 10 }}>
        <input
          checked={this.state.includeReceipts}
          disabled={disabled}
          id="include_receipts"
          name="include_receipts"
          onChange={this.updateIncludeReceipts}
          type="checkbox"
        />{' '}
        Include receipts in statement export
      </label>
    );
  }

  render() {
    const options = this.renderOptions();
    const disableAll = !this.hasMonthlyStatementDates();
    const disableOpen = this.state.includeReceipts;

    return (
      <div id="monthly_statements_section">
        <SubSectionHeader id="monthly_statements">Monthly Statements</SubSectionHeader>
        <ReactDropdown
          disabled={disableAll}
          onChange={this.handleChange}
          options={options}
          useChosen
        />
        <div className="flex">{this.renderIncludeReceiptsCheckbox()}</div>
        <br />
        <div>
          <TrueLinkButton
            disabled={disableOpen || disableAll}
            onClick={this.onSubmit}
            style={{ marginRight: '5px' }}
            variant="primary"
          >
            Open
          </TrueLinkButton>
          <TrueLinkButton
            disabled={disableAll}
            onClick={() => this.onSubmit({ format: 'csv', download: true })}
            style={{ marginRight: '5px' }}
            variant="primary"
          >
            Download as Spreadsheet
          </TrueLinkButton>
          <TrueLinkButton
            disabled={disableAll}
            onClick={() => this.onSubmit({ download: true })}
            style={{ marginRight: '5px' }}
            variant="primary"
          >
            Download as PDF
          </TrueLinkButton>
        </div>
      </div>
    );
  }
}

MonthlyStatements.propTypes = {
  monthlyStatementDates: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      card_slug: PropTypes.string.isRequired,
      last_4_digits: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isCardholder: PropTypes.bool.isRequired,
  showIncludeReceiptsCheckbox: PropTypes.bool,
};
