import PropTypes from 'prop-types';
import React from 'react';
import CardholderTransactionRow from './CardholderTransactionRow';

export default function CardholderTransactionList({ nightlyPosts, additionalClassNames }) {
  return (
    <div className={`account_info_section ${additionalClassNames}`}>
      <p style={{ fontWeight: 'bold' }}>List of Finalized Recent Transactions</p>
      {nightlyPosts.length > 0 ? (
        <table id="recent_transactions_table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Description</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {nightlyPosts.map((nightlyPost) => (
              <CardholderTransactionRow key={nightlyPost.id} nightlyPost={nightlyPost} />
            ))}
          </tbody>
        </table>
      ) : (
        <p style={{ fontWeight: '600', fontStyle: 'italic' }}>No recent transactions to display</p>
      )}
    </div>
  );
}

CardholderTransactionList.propTypes = {
  additionalClassNames: PropTypes.string.isRequired,
  nightlyPosts: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      attributes: PropTypes.shape({
        amount: PropTypes.number.isRequired,
        date: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        description2: PropTypes.string.isRequired,
        locationOrPhone: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  ).isRequired,
};
