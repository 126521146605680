import PropTypes from 'prop-types';
import React from 'react';
import ClientSelectAutocomplete from './ClientSelectAutocomplete';
import bindAll from 'react/shared/utils/bind_all';

export default class ClientSelectNav extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    bindAll(this);
  }

  componentDidUpdate() {
    return $('#client-select-nav').trigger('chosen:updated');
  }

  redirectToClient(ev) {
    const clientSlug = $(ev.target).find('option:selected').val();

    const targetUrl = this.props.redirectToClientURLFunc(clientSlug);

    return (window.location.href = targetUrl);
  }

  addChosen() {
    return $('#client-select-nav').chosen().change(this.redirectToClient);
  }

  clientOptions() {
    const { selected } = this.props;
    const options = selected ? [] : [<option key="blank" />];

    const clientOptions = this.props.clients.map((client) => {
      if (client.status === 'Inactive') {
        return (
          <option key={client.slug} style={{ color: '#999999' }} value={client.slug}>
            {' '}
            {client.person.name} - INACTIVE
          </option>
        );
      }
      return (
        <option key={client.slug} value={client.slug}>
          {' '}
          {client.person.name}
        </option>
      );
    });
    return options.concat(clientOptions);
  }

  render() {
    let containerClass = 'client-select-nav-container';
    if (this.props.isLoading) {
      containerClass += ' js-loading';
    }
    // Need to add an extra character so that after the select options load, Chosen doesn't
    // think we've selected the default value and make the text gray-colored. OH CHOSEN.
    const placeholder = this.props.selected
      ? `${this.props.selected.person.name} `
      : 'Jump to client...';

    const selectedValue = this.props.selected ? this.props.selected.slug : '';
    return (
      <div className={containerClass}>
        {this.props.autocompleteSelectEnabled === true ? (
          <ClientSelectAutocomplete
            organization={this.props.organization}
            redirectToClientURLFunc={this.props.redirectToClientURLFunc}
            selected={this.props.selected}
          />
        ) : (
          <select
            data-placeholder={placeholder}
            id="client-select-nav"
            onChange={this.redirectToClient}
            ref={this.addChosen}
            style={{ width: 220 }}
            value={selectedValue}
          >
            {this.clientOptions()}
          </select>
        )}
      </div>
    );
  }
}

ClientSelectNav.propTypes = {
  autocompleteSelectEnabled: PropTypes.bool,
  clients: PropTypes.array.isRequired,
  selected: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    person: PropTypes.shape({ name: PropTypes.string }).isRequired,
  }),
  isLoading: PropTypes.bool,
  organization: PropTypes.object,
  redirectToClientURLFunc: PropTypes.func.isRequired,
};
