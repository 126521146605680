import axios from 'axios';
import BeneficiaryDashboardActions from 'react/member/actions/beneficiary_dashboard_actions';

const BeneficiaryDashboardDisbursementsStore = flux.createStore({
  actions: [BeneficiaryDashboardActions.createDisbursement],

  createDisbursement(clientId, data, onRequestCompleted) {
    const url = RailsRoutes.client_disbursements_path(clientId, { format: 'json' });
    axios
      .post(url, { disbursement: data })
      .then((response) => {
        Truelink.flash('success', 'New disbursement created successfully');
        this.emit('disbursements.create', response.data);
      })
      .catch(() => {
        this.emit('disbursements.create.error');
      })
      .finally(onRequestCompleted);
  },
});

export default BeneficiaryDashboardDisbursementsStore;
