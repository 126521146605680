import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import DisbursementCategoryAmountAttachment from './DisbursementCategoryAmountAttachment';
import AttachmentDropZone from 'react/member/components/attachments/drop_zone_uploader/AttachmentDropZone';
import useDisbursementShowQuery from 'react/member/components/dashboard/clients/hooks/useDisbursementShowQuery';
import LoadingIndicator from 'react/shared/components/LoadingIndicator';
import InfoTooltip from 'react/shared/components/tooltips/InfoTooltip';

const useStyles = makeStyles(() => ({
  table: {
    borderBottom: '1px solid #ddd',
  },
}));
export default function DisbursementCategoryAmountAttachments({
  allowInsert,
  canAddAttachments,
  checkInsertAttachmentId,
  disbursementCategoryAmount,
  disbursementId,
}) {
  const [isLoading, setIsLoading] = useState(false);
  let attachments = [];
  const classes = useStyles();

  if (disbursementCategoryAmount.relationships?.attachments) {
    attachments = disbursementCategoryAmount.relationships.attachments.data;
  }
  const showEmptyView = !canAddAttachments && attachments.length === 0;
  const attachmentsList = attachments.map((attachment) => (
    <DisbursementCategoryAmountAttachment
      attachment={attachment}
      isCheckInsert={attachment.id === checkInsertAttachmentId}
      key={`dca-attachment-${attachment.id}`}
    />
  ));

  const emptyView = (
    <tr>
      <td className="italic" colSpan={3}>
        No attachments
      </td>
    </tr>
  );

  const insertTooltipTitle =
    'Documents included as an insert are mailed with your check disbursement. Only one document can be inserted and check inserts must be in PDF format to be included.';
  const insertColumnHeader = 'Included as insert';
  const tableClass = !allowInsert ? 'table hide-second-column' : 'table';

  // We don't need to fetch the disbursement on render, but we need a reference to it
  // to refetch on destroy success
  const useDisbursementFetch = useDisbursementShowQuery({
    disbursementId,
  });

  const onBeginUpload = () => {
    setIsLoading(true);
  };

  const onUploadSuccess = () => {
    useDisbursementFetch.refetch().then(() => {
      setIsLoading(false);
    });
  };

  const attachableIdentifier = {
    id: disbursementCategoryAmount.id,
    type: 'DisbursementCategoryAmount',
  };

  return (
    <table className={`${tableClass} ${classes.table}`}>
      <thead>
        <tr>
          <th className="padding-top-0">File name</th>
          <th className="padding-top-0">
            {insertColumnHeader} <InfoTooltip placement="right" tooltipText={insertTooltipTitle} />
          </th>
          <th className="align-right padding-top-0" />
        </tr>
      </thead>
      <tbody>
        {showEmptyView ? emptyView : attachmentsList}
        {canAddAttachments && (
          <tr>
            <td colSpan="3">
              {isLoading && <LoadingIndicator spinnerClass="margin-bottom-10" />}
              <AttachmentDropZone
                attachable={attachableIdentifier}
                onBeginUpload={onBeginUpload}
                onSuccess={onUploadSuccess}
              />
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

DisbursementCategoryAmountAttachments.propTypes = {
  allowInsert: PropTypes.bool.isRequired,
  disbursementId: PropTypes.string.isRequired,
  disbursementCategoryAmount: PropTypes.object.isRequired,
  canAddAttachments: PropTypes.bool.isRequired,
  checkInsertAttachmentId: PropTypes.string,
};
