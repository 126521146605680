import axios from 'axios';
import DashboardActions from 'react/member/actions/dashboard_actions';
import ListItemStore from 'react/shared/utils/list_item_store';

const DashboardTrustStatementRecipientsStore = flux.createStore({
  trustStatementRecipients: new ListItemStore(),

  actions: [
    DashboardActions.fetchTrustStatementRecipients,
    DashboardActions.createTrustStatementRecipient,
    DashboardActions.updateTrustStatementRecipient,
    DashboardActions.destroyTrustStatementRecipient,
  ],

  fetchTrustStatementRecipients(trustBeneficiarySlug, onRequestCompleted) {
    axios
      .get(RailsRoutes.dashboard_client_trust_statement_recipients_path(trustBeneficiarySlug))
      .then((trustStatementRecipientsResponse) => {
        const updatedItems = this.trustStatementRecipients.addItems(
          trustBeneficiarySlug,
          trustStatementRecipientsResponse.data,
        );
        this.emit(`trustStatementRecipients.fetch.${trustBeneficiarySlug}`, updatedItems);
        this.emit(`trustStatementRecipients.updated.${trustBeneficiarySlug}`, updatedItems);
      })
      .finally(onRequestCompleted);
  },

  createTrustStatementRecipient(trustBeneficiarySlug, trustStatementRecipient, onRequestCompleted) {
    axios
      .post(RailsRoutes.dashboard_client_trust_statement_recipients_path(trustBeneficiarySlug), {
        trust_statement_recipient: trustStatementRecipient,
      })
      .then((trustStatementRecipientResponse) => {
        const updatedItems = this.trustStatementRecipients.addItems(trustBeneficiarySlug, [
          trustStatementRecipientResponse.data,
        ]);
        this.emit(
          `trustStatementRecipients.create.${trustBeneficiarySlug}`,
          trustStatementRecipientResponse.data,
        );
        this.emit(`trustStatementRecipients.updated.${trustBeneficiarySlug}`, updatedItems);
      })
      .catch((trustStatementRecipientResponse) => {
        this.emit(
          `trustStatementRecipients.create.${trustBeneficiarySlug}.error`,
          trustStatementRecipientResponse.data,
        );
      })
      .finally(onRequestCompleted);
  },

  updateTrustStatementRecipient(trustBeneficiarySlug, trustStatementRecipient, onRequestCompleted) {
    axios
      .patch(
        RailsRoutes.dashboard_client_trust_statement_recipient_path(
          trustBeneficiarySlug,
          trustStatementRecipient.id,
        ),
        { trust_statement_recipient: trustStatementRecipient },
      )
      .then((trustStatementRecipientResponse) => {
        const updatedItems = this.trustStatementRecipients.updateItem(
          trustBeneficiarySlug,
          trustStatementRecipientResponse.data,
        );
        this.emit(
          `trustStatementRecipients.update.${trustBeneficiarySlug}`,
          trustStatementRecipientResponse.data,
        );
        this.emit(`trustStatementRecipients.updated.${trustBeneficiarySlug}`, updatedItems);
      })
      .catch((trustStatementRecipientResponse) => {
        this.emit(
          `trustStatementRecipients.update.${trustBeneficiarySlug}.error`,
          trustStatementRecipientResponse.data,
        );
      })
      .finally(onRequestCompleted);
  },

  destroyTrustStatementRecipient(
    trustBeneficiarySlug,
    trustStatementRecipient,
    onRequestCompleted,
  ) {
    axios
      .delete(
        RailsRoutes.dashboard_client_trust_statement_recipient_path(
          trustBeneficiarySlug,
          trustStatementRecipient.id,
        ),
      )
      .then((trustStatementRecipientResponse) => {
        const updatedItems = this.trustStatementRecipients.removeItem(
          trustBeneficiarySlug,
          trustStatementRecipientResponse.data,
        );
        this.emit(
          `trustStatementRecipients.destroy.${trustBeneficiarySlug}`,
          trustStatementRecipientResponse.data,
        );
        this.emit(`trustStatementRecipients.updated.${trustBeneficiarySlug}`, updatedItems);
      })
      .catch((trustStatementRecipientResponse) => {
        this.emit(
          `trustStatementRecipients.destroy.${trustBeneficiarySlug}.error`,
          trustStatementRecipientResponse.data,
        );
      })
      .finally(onRequestCompleted);
  },
});

export default DashboardTrustStatementRecipientsStore;
