import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import CheckMarkSvg from 'images/checkmark-black-and-green.svg';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import theme from 'react/shared/theme/Theme';

const useStyles = makeStyles(() => ({
  idvSuccessModal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
  },
  checkMarkSvg: {
    width: '72px',
  },
  submitBtn: {
    width: 'fit-content',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));

export default function IdvSuccess({ onClose }) {
  const classes = useStyles();

  return (
    <form className={classes.idvSuccessModal} id="idv-form-success">
      <img alt="check mark" className={classes.checkMarkSvg} src={CheckMarkSvg} />
      <div style={{ textAlign: 'center' }}>
        <Typography variant="h4">Success!</Typography>
        <Typography variant="body1">That’s all we need for now.</Typography>
      </div>
      <TrueLinkButton
        className={classes.submitBtn}
        onClick={onClose}
        type="submit"
        variant="primary"
      >
        Done
      </TrueLinkButton>
    </form>
  );
}

IdvSuccess.propTypes = {
  onClose: PropTypes.func,
};
