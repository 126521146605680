import PropTypes from 'prop-types';
import React from 'react';
import _where from 'underscore/modules/where';
import DashboardActions from 'react/member/actions/dashboard_actions';
import DashboardBalances from 'react/member/components/dashboard/DashboardBalances';
import DeprecatedPositions from 'react/member/components/dashboard/clients/dashboard_client/securities/DeprecatedPositions';
import UniqueAssetsTable from 'react/member/components/dashboard/clients/dashboard_client/unique_assets/UniqueAssetsTable';
import DepositPopUp from 'react/member/components/dashboard/deposits/DepositPopUp';
import DeprecatedDashboardWealthAccountEventsIndex from 'react/member/components/dashboard/wealth_account_events/DeprecatedDashboardWealthAccountEventsIndex';
import userRoleShape from 'react/member/shapes/UserRoleShape';
import DashboardPositionStore from 'react/member/stores/DashboardPositionStore';
import DashboardWealthAccountEventStore from 'react/member/stores/DashboardWealthAccountEventStore';
import SubSectionHeader from 'react/shared/components/SubSectionHeader';
import TabContent from 'react/shared/components/TabContent';
import TabNavigation from 'react/shared/components/TabNavigation';
import DashboardClientStore from 'react/shared/stores/DashboardClientStore';
import bindAll from 'react/shared/utils/bind_all';

// Note: this component is now only used for clients who belong to individual trusts.
export default class DeprecatedWealthAccountPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      positions: [],
      transactions: [],
    };

    bindAll(this);
  }

  componentDidMount() {
    DashboardPositionStore.on('positions.fetchByClient', this.onChangePositions);
    DashboardClientStore.on('client.fetchTransactions', this.onChangeTransactions);

    DashboardWealthAccountEventStore.on('wealthAccountEvents.fetch', this.onChangeTransactions);
    DashboardWealthAccountEventStore.on(
      'wealthAccountEvent.update',
      this.refreshUpcomingTransactions,
    );

    const { slug } = this.props.client;
    DashboardActions.fetchPositionsByClient(slug);
    DashboardActions.fetchWealthAccountEvents(slug);
  }

  componentWillUnmount() {
    DashboardPositionStore.off('positions.fetchByClient', this.onChangePositions);

    DashboardWealthAccountEventStore.off('wealthAccountEvents.fetch', this.onChangeTransactions);
    DashboardWealthAccountEventStore.off(
      'wealthAccountEvent.update',
      this.refreshUpcomingTransactions,
    );
  }

  refreshUpcomingTransactions() {
    const { slug } = this.props.client;
    DashboardActions.fetchWealthAccountEvents(slug);
  }

  onChangePositions() {
    this.setState({ positions: DashboardPositionStore.getPositions() });
  }

  onChangeTransactions() {
    this.setState({
      transactions: DashboardWealthAccountEventStore.getWealthAccountEvents(),
    });
  }

  balanceSummary() {
    const { balances } = this.props.client;
    return (
      <div style={{ marginTop: '35px' }}>
        <SubSectionHeader>Account Summary</SubSectionHeader>
        <DashboardBalances
          balances={balances[0]}
          customClass="table table-condensed"
          customStyle={{ width: '50%', fontSize: '16px', borderTop: 0 }}
          showNonLiquidAssets={false}
        />
      </div>
    );
  }

  renderDepositPopUp() {
    if (this.state.modalOpen) {
      return (
        <DepositPopUp
          client={this.props.client}
          closeModal={this.closeModal}
          modalOpen={this.state.modalOpen}
        />
      );
    }
  }

  tabList() {
    return [
      {
        id: 'account-summary',
        title: 'Account Summary',
        className: 'btn btn-secondary padded-tabs',
      },
      {
        id: 'investment-account',
        title: 'Investment Account',
        className: 'btn btn-secondary padded-tabs',
      },
    ];
  }

  closeModal() {
    this.setState({ modalOpen: false });
  }

  openModal() {
    this.setState({ modalOpen: true });
  }

  render() {
    const {
      client: {
        slug,
        person: { name },
      },
      investmentAccountId,
    } = this.props;
    const { positions, transactions } = this.state;
    const investmentTransactions = _where(transactions, { use: 'investment' });

    return (
      <div>
        <TabNavigation
          classOptions="btn-group"
          customHeaderStyle={{ textAlign: 'center' }}
          tabs={this.tabList()}
        >
          <TabContent id="account-summary-table">
            {this.balanceSummary()}
            <UniqueAssetsTable client={this.props.client} userRoles={this.props.userRoles} />
            <DeprecatedPositions investmentAccountId={investmentAccountId} positions={positions} />
            {this.renderDepositPopUp()}
          </TabContent>
          <TabContent id="investment-account-table">
            <DeprecatedDashboardWealthAccountEventsIndex
              clientId={slug}
              clientName={name}
              transactions={investmentTransactions}
            />
          </TabContent>
        </TabNavigation>
      </div>
    );
  }
}

DeprecatedWealthAccountPage.propTypes = {
  client: PropTypes.object.isRequired,
  investmentAccountId: PropTypes.number,
  userRoles: userRoleShape.isRequired,
};
