import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import StyledLabel from 'react/shared/components/forms/StyledLabel';
import PopUp from 'react/shared/components/popups/PopUp';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import TrueLinkChip from 'react/shared/components/true_link/main/TrueLinkChip';
import TrueLinkLink from 'react/shared/components/true_link/main/TrueLinkLink';
import { capitalize } from 'react/shared/utils/Strings';
import bindAll from 'react/shared/utils/bind_all';

export default class FamilyFunderInvite extends React.Component {
  constructor(props) {
    super(props);

    this.state = { invite: this.props.invite, deleteModalOpen: false };

    bindAll(this);
  }

  formatPhoneNumber(numberString) {
    const cleaned = numberString.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return null;
  }

  handleResendEmail(ev) {
    ev.preventDefault();
    const { invite } = this.state;

    $.post({
      url: RailsRoutes.dashboard_resend_family_funding_invite_path(),
      data: {
        family_funder_invite_id: invite.id,
      },
    })
      .done((data) => {
        this.setState({ invite: data.family_funder_invite });
        Truelink.flash(
          'success',
          'Thank you! Your email has been sent and your requested family funder can now create a login to fund this True Link Card.',
          false,
          true,
        );
      })
      .fail(() => {
        Truelink.flash('error', 'An error occured while trying to create an invite.', true, false);
      });
  }

  handleOpenDeleteModal(ev) {
    ev.preventDefault();
    this.setState({ deleteModalOpen: true });
  }

  handleCloseDeleteModal(ev) {
    ev.preventDefault();
    this.setState({ deleteModalOpen: false });
  }

  renderDeleteBody() {
    const { invite } = this.state;
    return (
      <div>
        <StyledLabel labelContent="Name:">{`${invite.first_name} ${invite.last_name}`}</StyledLabel>
        <StyledLabel labelContent="Email:">{invite.email}</StyledLabel>
        <hr />
        <p>
          Are you sure you want to cancel this invitation? The family funder will no longer be able
          to sign up to fund this card.
        </p>
      </div>
    );
  }

  handleDeleteFamilyFunder(ev) {
    ev.preventDefault();
    const { invite } = this.state;

    $.ajax({
      url: RailsRoutes.dashboard_delete_family_funding_invite_path(),
      method: 'DELETE',
      data: {
        family_funder_invite_id: invite.id,
      },
    })
      .done(() => {
        this.props.onInviteDelete(invite);
        Truelink.flash('success', 'Invite has been successfully deleted!', false, true);
      })
      .fail(() => {
        Truelink.flash('error', 'An error occured while trying to delete an invite.', true, false);
      });
  }

  render() {
    const { invite, deleteModalOpen } = this.state;

    let invitationStatus;
    if (invite.status === 'pending') {
      const updatedAt = moment(invite.updated_at).format('M/D/YY, h:mm a');
      invitationStatus = `Email invitation sent ${updatedAt}`;
    } else {
      const updatedAt = moment(invite.updated_at).format('M/D/YY');
      invitationStatus = `Email invitation accepted ${updatedAt}`;
    }

    return (
      <div className="ff-invite-wrapper">
        <table className="invite-table" style={{ tableLayout: 'fixed' }}>
          <tbody>
            <tr>
              <td className="table-label" style={{ width: 130 }}>
                Name:{' '}
              </td>
              <td className="table-data" style={{ width: 250 }}>
                {`${invite.first_name} ${invite.last_name}`}
              </td>
              <td className="table-label" style={{ width: 130 }}>
                Email address:{' '}
              </td>
              <td className="table-data" rowSpan={2} style={{ width: 'auto' }}>
                {invite.email}
                <div
                  className="inline-block nowrap"
                  style={{
                    borderTop: '1px solid #ddd',
                    paddingTop: 2,
                    marginTop: 3,
                    fontStyle: 'italic',
                  }}
                >
                  {invitationStatus}
                </div>
              </td>
              <td style={{ textAlign: 'right', padding: 0 }}>
                <TrueLinkChip
                  color={invite.status == 'pending' ? 'warning' : 'primary'}
                  label={capitalize(invite.status)}
                  size="small"
                  styles={{ margin: 0 }}
                />
              </td>
            </tr>
            <tr>
              <td className="table-label">Mobile phone: </td>
              <td className="table-data">{this.formatPhoneNumber(invite.mobile_phone) || '-'}</td>
              <td />
              <td />
            </tr>
            <tr>
              <td className="table-label">Home Phone: </td>
              <td className="table-data">{this.formatPhoneNumber(invite.home_phone) || '-'}</td>
              <td />
              <td className="table-data" style={{ whiteSpace: 'nowrap' }}>
                {invite.status === 'pending' && (
                  <div>
                    <TrueLinkLink onClick={this.handleResendEmail}>Resend email</TrueLinkLink>
                    {' • '}
                    <TrueLinkLink onClick={this.handleOpenDeleteModal}>
                      Cancel invitation
                    </TrueLinkLink>
                  </div>
                )}
              </td>
              <td />
            </tr>
          </tbody>
        </table>
        {deleteModalOpen && (
          <PopUp
            footer={
              <TrueLinkButton onClick={this.handleDeleteFamilyFunder} variant="primary">
                Remove Family Funder
              </TrueLinkButton>
            }
            header="Remove Family Funder"
            maxWidth="525px"
            modalOpen
            onClose={this.handleCloseDeleteModal}
          >
            {this.renderDeleteBody()}
          </PopUp>
        )}
      </div>
    );
  }
}

FamilyFunderInvite.propTypes = {
  invite: PropTypes.object.isRequired,
  onInviteDelete: PropTypes.func.isRequired,
};
