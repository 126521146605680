import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import childrenShape from 'react/shared/shapes/children';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: '18px',
  },
}));

export default function ProfileSection({ label, children }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography color="textSecondary">{label}</Typography>
      <Typography component="div">{children}</Typography>
    </div>
  );
}

ProfileSection.propTypes = {
  children: childrenShape.isRequired,
  label: PropTypes.string.isRequired,
};
