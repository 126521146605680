import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import ErrorText from 'react/signups/card/common/ErrorText';
import addressShape from 'react/signups/card/shapes/AddressShape';

const useStyles = makeStyles({
  label: {
    color: '#000',
  },
  select: {
    height: 'auto',
    width: 'auto',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '12px',
  },
});

export default function StateDropdown({ onChange, setFieldTouched, values }) {
  const classes = useStyles();

  return (
    <FormControl>
      <FormLabel className={classes.label} htmlFor="state">
        <Typography variant="body1">State</Typography>
      </FormLabel>
      <select
        className={classes.select}
        data-testid="state-dropdown"
        id="state"
        name="address.state"
        onBlur={() => setFieldTouched('state', true)}
        onChange={onChange}
        required
        value={values.address.state}
      >
        <option key="no-data" value="" />
        {Object.keys($tlf.domains.states).map((state) => (
          <option key={state} value={state}>
            {state}
          </option>
        ))}
      </select>
      <ErrorText name="address.state" />
    </FormControl>
  );
}

StateDropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  values: addressShape.isRequired,
};
