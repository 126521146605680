import moment from 'moment-timezone';
import HolidayCalendarStore from 'react/shared/stores/HolidayCalendarStore';

// This method relies on HolidayCalendarStore having fetched holidays
// Actions.fetchBankHolidays() must be triggered for this to properly handle holidays
//
// The date parameter should be a string formatted as "YYYY-MM-DD"
export function validBusinessDate(date, format = 'YYYY-MM-DD') {
  const holidays = HolidayCalendarStore.getBankHolidays();

  const momentDate = moment.tz(date, format, moment.tz.guess());

  // isoWeekday returns 1 - 7 with 1 == Monday
  const isWeekday = momentDate.isoWeekday() < 6;

  const isHoliday = holidays && holidays[momentDate.format('YYYY-MM-DD')];

  return isWeekday && !isHoliday;
}

// The date parameter should be a string formatted as "YYYY-MM-DD"
export function nextBusinessDay(date) {
  if (validBusinessDate(date)) {
    return date;
  }

  let modifiedDate = date;

  // We assume that there will never be more than 5 weekend/holiday days in a row
  let i = 0;
  while (i < 5) {
    const momentDate = moment.tz(modifiedDate, moment.tz.guess());
    const nextMomentDate = momentDate.add(1, 'day');
    modifiedDate = nextMomentDate.format('YYYY-MM-DD');

    if (validBusinessDate(modifiedDate)) {
      return modifiedDate;
    }
    i++;
  }
}

export function validateAfterToday(date, format = 'YYYY-MM-DD') {
  const endOfToday = moment().endOf('day');
  const targetDate = moment.tz(date, format, moment.tz.guess());
  return targetDate.isAfter(endOfToday);
}

export function isBusinessDayAfterToday(date, format = 'YYYY-MM-DD') {
  return validBusinessDate(date, format) && validateAfterToday(date, format);
}
