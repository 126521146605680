import {
  OVERDUE_STATUS,
  PRE_RELEASE_STATUS,
  RELEASED_STATUS,
} from 'react/member/shapes/estimated_arrival_shape';
import { CARD_ORDER_TYPES } from 'react/shared/utils/CardOrderType';

export const FIRST_CARD = 'first_card_order';
export const REISSUE_A_ACCOUNT = 'reissue_a_account';
export const REISSUE_C_LEGACY_OLD_ACCOUNT = 'reissue_c_legacy_old_account';
export const REISSUE_C_LEGACY_NEW_ACCOUNT = 'reissue_c_legacy_new_account';
export const REISSUE_C_ACCOUNT = 'reissue_c_account';
export const ADDITIONAL_CARD = 'additional_card';

export const cardOrderType = (account, card) => {
  const { REISSUE_A: A, REISSUE_C_LEGACY: C_LEGACY, REISSUE_C: C } = CARD_ORDER_TYPES;

  if (account.first_card_order) return FIRST_CARD;

  if (card) {
    const { card_order, card_reissue } = card;
    if (card_reissue) {
      if (card_reissue.type === C_LEGACY) {
        return REISSUE_C_LEGACY_OLD_ACCOUNT;
      }
      if (card_reissue.type === A && card_reissue.status === 'pending_release') {
        return REISSUE_A_ACCOUNT;
      }
    }
    // This should be required on card at some point, at which point we wouldn't need this if
    if (card_order) {
      switch (card_order.type) {
        case A:
          return REISSUE_A_ACCOUNT;
        case C_LEGACY:
          return REISSUE_C_LEGACY_NEW_ACCOUNT;
        case C:
          return REISSUE_C_ACCOUNT;
      }
    }
  }

  return ADDITIONAL_CARD;
};

export const expeditedShipping = (range) => Array.isArray(range) && range[0] === 3;

export const isPreReleaseCardOrder = (account, card) =>
  account.estimated_arrival.status === PRE_RELEASE_STATUS || !card;

export const isReleasedCardOrder = (account, card) =>
  account.estimated_arrival.status === RELEASED_STATUS && !!card;

export const isOverduePreReleaseCardOrder = (account, card) =>
  account.estimated_arrival.status === OVERDUE_STATUS && !card;

export const isOverdueReleasedCardOrder = (account, card) =>
  account.estimated_arrival.status === OVERDUE_STATUS && !!card;
