import React from 'react';

export default function FamilyFundingAdminInfoBanner(_props) {
  const handleClick = () => $('button#account_info_tab_link').trigger('click');
  return (
    <div className="info-box" id="family-funding-admin-info-banner">
      Would you like a family member or loved one to fund this card? Add family funders to this
      account{' '}
      <a href="#account_info_tab_link" onClick={handleClick}>
        on the Account tab
      </a>
    </div>
  );
}
