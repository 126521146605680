import PropTypes from 'prop-types';
import React from 'react';
import FieldDefaultLayout from './FieldDefaultLayout';
import bindAll from 'react/shared/utils/bind_all';

export default class Field extends React.Component {
  constructor(props) {
    super(props);

    bindAll(this);
  }

  layout() {
    return FieldDefaultLayout;
  }

  render() {
    const layout = this.layout();
    return layout(
      this.props.editable,
      this.props.label,
      this.props.renderShow,
      this.props.renderEdit,
      this.props.helperText,
      this.props.helperTextType,
      this.props.optional,
    );
  }
}

Field.propTypes = {
  editable: PropTypes.func,
  helperText: PropTypes.string,
  helperTextType: PropTypes.string,
  label: PropTypes.func,
  optional: PropTypes.bool,
  renderShow: PropTypes.func,
  renderEdit: PropTypes.func,
};
