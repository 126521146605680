import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import SocialIcon from './SocialIcon';

const useStyles = makeStyles({
  iconGroup: {
    display: 'inline-block',
    marginBottom: '8px',
    padding: '5px 12px 5px',
    border: '1px solid #fff',
    width: 'fit-content',
    paddingRight: '2px',
    paddingLeft: '2px',
    borderStyle: 'none',
    textAlign: 'center',
  },
});

export default function SocialIcons() {
  const classes = useStyles();
  return (
    <div className={classes.iconGroup}>
      <SocialIcon
        icon="facebook-square fab"
        link="https://www.facebook.com/truelinkfin"
        title="Facebook"
      />
      <SocialIcon
        icon="linkedin fab"
        link="https://www.linkedin.com/company/true-link-financial"
        title="Linked In"
      />
      <SocialIcon
        icon="twitter-square fab"
        link="https://twitter.com/TrueLinkFin"
        title="Twitter"
      />
    </div>
  );
}
