import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import childrenShape from 'react/shared/shapes/children';

export default function CardText({
  children,
  className,
  component,
  gutterBottom = true,
  variant = 'body1',
}) {
  return (
    <Typography
      className={className}
      component={component}
      gutterBottom={gutterBottom}
      variant={variant}
    >
      {children}
    </Typography>
  );
}

CardText.propTypes = {
  children: childrenShape.isRequired,
  className: PropTypes.string,
  component: PropTypes.string,
  gutterBottom: PropTypes.bool,
  variant: PropTypes.string,
};
