import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import BeneficiaryAccountBalance from './BeneficiaryAccountBalance';
import BeneficiaryStatements from './BeneficiaryStatements';
import BeneficiarySubAccountReportsSection from './BeneficiarySubAccountReportsSection';
import ReportGenerationModal from 'react/member/components/dashboard/reports/ReportGenerationModal';

function BeneficiaryDashboardStatementsTab({
  accountBalance,
  quarterlyStatementDates,
  showCustomTimePeriodStatements,
  trustBeneficiary,
}) {
  const [reportDownloading, setReportDownloading] = useState(false);
  const [reportError, setReportError] = useState(false);
  const [reportGenerationRequest, setReportGenerationRequest] = useState(null);

  const checkReportStatus = useCallback(
    (millisecondsElapsed = 0) => {
      const TWENTY_MINUTES = 20 * 60 * 1000;

      const timerStart = Date.now();
      const url = RailsRoutes.api_v1_report_generation_request_path(reportGenerationRequest.id);

      axios
        .get(url)
        .then(({ data: { data } }) => {
          const timerEnd = Date.now();
          const timerElapsed = timerEnd - timerStart + millisecondsElapsed;

          if (data.attributes.status == 'completed') {
            setReportDownloading(false);
            window.location.assign(
              RailsRoutes.report_api_v1_report_generation_request_path(data.id),
            );
          } else if (data.attributes.status === 'fail' || timerElapsed > TWENTY_MINUTES) {
            setReportError(true);
          } else {
            window.setTimeout(() => checkReportStatus(timerElapsed), 1000);
          }
        })
        .catch(() => {
          setReportError(true);
        });
    },
    [reportGenerationRequest],
  );

  const onSubmitReportRequest = (params) => {
    const url = RailsRoutes.api_v1_report_generation_requests_path();

    setReportDownloading(true);

    axios
      .post(url, params)
      .then((response) => {
        setReportGenerationRequest(response.data.data);
      })
      .catch(() => {
        setReportError(true);
      });
  };

  const closeReportModal = () => {
    setReportDownloading(false);
  };

  useEffect(() => {
    if (reportGenerationRequest) {
      checkReportStatus();
    }
  }, [checkReportStatus, reportGenerationRequest]);

  return (
    <div>
      {accountBalance != 0 && accountBalance && (
        <BeneficiaryAccountBalance
          accountBalance={accountBalance}
          trustName={trustBeneficiary.trust.name}
        />
      )}
      <BeneficiaryStatements
        onSubmitReportRequest={onSubmitReportRequest}
        quarterlyStatementDates={quarterlyStatementDates}
        trustBeneficiary={trustBeneficiary}
      />
      <br />
      {showCustomTimePeriodStatements && trustBeneficiary.latest_closeout_date && (
        <BeneficiarySubAccountReportsSection
          client={trustBeneficiary}
          onSubmitReportRequest={onSubmitReportRequest}
        />
      )}
      <ReportGenerationModal
        modalOpen={reportDownloading}
        onClose={closeReportModal}
        reportError={reportError}
      />
    </div>
  );
}

BeneficiaryDashboardStatementsTab.propTypes = {
  accountBalance: PropTypes.number,
  quarterlyStatementDates: PropTypes.array,
  showCustomTimePeriodStatements: PropTypes.bool,
  trustBeneficiary: PropTypes.object.isRequired,
};

export default BeneficiaryDashboardStatementsTab;
