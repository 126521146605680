import PropTypes from 'prop-types';
import React from 'react';
import ReactInputMask from 'react-input-mask';
import _map from 'underscore/modules/map';
import addressShape from 'react/shared/shapes/address_shape';

export default function AddressInputs(props) {
  const address = props.address || {};
  const addressLabel = props.labelPrefix ? `${props.labelPrefix} home address` : 'Home address';
  const namePrefix = props.namePrefix ? `${props.namePrefix}[address]` : 'address';
  const idPrefix = props.namePrefix ? `${props.namePrefix}_` : '';
  const readonly = props.readonly || false;
  const handleInputChange = props.onInputChange;

  return (
    <div>
      <div className="card--form__row">
        <div className="card--form__col width-100">
          <label htmlFor={`${idPrefix}address_street1`}>
            {addressLabel}
            <span className="card--form__input-addition">No P.O. boxes please</span>
            <input
              className="required"
              id={`${idPrefix}address_street1`}
              name={`${namePrefix}[street1]`}
              onChange={handleInputChange}
              readOnly={readonly}
              type="text"
              value={address.street1}
            />
          </label>
        </div>
      </div>
      <div className="card--form__row">
        <div className="card--form__col width-100">
          <label htmlFor={`${idPrefix}address_street2`}>
            Apartment or unit number
            <input
              id={`${idPrefix}address_street2`}
              name={`${namePrefix}[street2]`}
              onChange={handleInputChange}
              placeholder=""
              readOnly={readonly}
              type="text"
              value={address.street2}
            />
          </label>
        </div>
      </div>
      <div className="card--form__row">
        <div className="card--form__col" style={{ width: '40%' }}>
          <label htmlFor={`${idPrefix}address_city`}>
            City
            <input
              className="required"
              id={`${idPrefix}address_city`}
              name={`${namePrefix}[city]`}
              onChange={handleInputChange}
              readOnly={readonly}
              type="text"
              value={address.city}
            />
          </label>
        </div>
        <div className="card--form__col" style={{ width: '40%' }}>
          <label htmlFor={`${idPrefix}address_state`}>
            State
            <select
              className="select-box required"
              id={`${idPrefix}address_state`}
              name={`${namePrefix}[state]`}
              onChange={handleInputChange}
              readOnly={readonly}
              value={address.state}
            >
              <option />
              {_map($tlf.domains.states, (state, abbr) => (
                <option key={abbr} value={abbr}>
                  {state}
                </option>
              ))}
            </select>
          </label>
        </div>
        <div className="card--form__col" style={{ width: '18%' }}>
          <label htmlFor={`${idPrefix}address_zip`}>
            Zip
            <ReactInputMask
              id={`${idPrefix}address_zip`}
              mask="99999"
              name={`${namePrefix}[zip]`}
              onChange={handleInputChange}
              readOnly={readonly}
              required
              type="text"
              value={address.zip}
            />
          </label>
        </div>
      </div>
    </div>
  );
}

AddressInputs.propTypes = {
  address: addressShape,
  labelPrefix: PropTypes.string,
  namePrefix: PropTypes.string,
  onInputChange: PropTypes.func,
  readonly: PropTypes.bool,
};
