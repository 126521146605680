import PropTypes from 'prop-types';
import React from 'react';
import _isEmpty from 'underscore/modules/isEmpty';
import AdditionalEmailsList from './AdditionalEmailsList';
import EditUserMobile from './EditUserMobile';
import EmailPreferences from './EmailPreferences';
import CheckboxLine from 'react/shared/card/components/CheckboxLine';
import bindAll from 'react/shared/utils/bind_all';

export default class AlertPreferences extends React.Component {
  static get propTypes() {
    const { string, arrayOf, object, func, shape } = PropTypes;
    return {
      additionalEmails: arrayOf(string).isRequired,
      allOrgAdminEmails: arrayOf(string).isRequired,
      notificationPrefs: object.isRequired,
      contactInfo: shape({
        email: string,
        extra_emails: string,
        mobile: string,
      }),
      onAddAdditionalEmail: func.isRequired,
      onRemoveAdditionalEmail: func.isRequired,
      onUpdatePrefs: func.isRequired,
      onUpdateMobile: func.isRequired,
    };
  }

  constructor(props) {
    super(props);

    bindAll(this);
  }

  contactMobileMissing() {
    return !_isEmpty(this.props.contactInfo) && !this.props.contactInfo.mobile;
  }

  setupToolTip() {
    if (this.contactMobileMissing()) {
      $('#checkbox-overlay-alert-checkbox-sms').tooltip();
    }
  }

  showToolTip() {
    if (this.contactMobileMissing()) {
      $('#checkbox-overlay-alert-checkbox-sms').tooltip('show');
    } else {
      $('#checkbox-overlay-alert-checkbox-sms').tooltip('destroy');
    }
  }

  renderMobileForm() {
    if (this.contactMobileMissing()) {
      return <EditUserMobile onUpdateMobile={this.props.onUpdateMobile} />;
    }
  }

  renderAdditionalEmailsList() {
    return (
      <AdditionalEmailsList
        additionalEmails={this.props.additionalEmails}
        allOrgAdminEmails={this.props.allOrgAdminEmails}
        notificationPrefs={this.props.notificationPrefs}
        onAddAdditionalEmail={this.props.onAddAdditionalEmail}
        onRemoveAdditionalEmail={this.props.onRemoveAdditionalEmail}
      />
    );
  }

  renderEmailPreferences() {
    const prefs = this.props.notificationPrefs;

    const hideEmailPreferences =
      prefs['email_admin'] !== 'on' &&
      prefs['email_org'] !== 'on' &&
      prefs['email_receiver_list'] !== 'on';

    if (hideEmailPreferences) return null;

    return <EmailPreferences onUpdatePrefs={this.props.onUpdatePrefs} prefs={prefs} />;
  }

  render() {
    const { contactInfo, notificationPrefs: prefs } = this.props;

    const mobile = contactInfo.mobile || 'no phone number';
    const email = contactInfo.email || 'no primary email';
    const extra_emails = contactInfo.extra_emails;

    return (
      <div className="alert-checkbox-container">
        <CheckboxLine
          checkboxClass="sms-preferences-checkbox"
          disabled={mobile === 'no phone number'}
          handleHover={this.showToolTip}
          hr={false}
          id="alert-checkbox-sms"
          isChecked={prefs['sms'] == 'on'}
          name="sms"
          setupHover={this.setupToolTip}
          text1="By text message: "
          text2={`Send alerts to primary mobile phone number: ${mobile}`}
          toolTipTitle="Please add your mobile number"
          updateChecked={this.props.onUpdatePrefs}
          updateUnChecked={this.props.onUpdatePrefs}
        />
        {this.renderMobileForm()}
        <hr />
        <CheckboxLine
          id="alert-checkbox-email-primary"
          isChecked={prefs['email_admin'] == 'on'}
          name="email_admin"
          text1="By email: "
          text2={`Send alerts to the primary email address: ${email}`}
          updateChecked={this.props.onUpdatePrefs}
          updateUnChecked={this.props.onUpdatePrefs}
        />
        <CheckboxLine
          hidden={!extra_emails}
          id="alert-checkbox-email-org"
          isChecked={prefs['email_org'] == 'on'}
          name="email_org"
          text1="By email: "
          text2={`Send alerts to these additional email addresses: ${extra_emails}`}
          updateChecked={this.props.onUpdatePrefs}
          updateUnChecked={this.props.onUpdatePrefs}
        />
        <CheckboxLine
          hr={false}
          id="alert-checkbox-email-receiver-list"
          isChecked={prefs['email_receiver_list'] == 'on'}
          name="email_receiver_list"
          text1="By email: "
          text2={'Send alerts to the following additional emails: '}
          updateChecked={this.props.onUpdatePrefs}
          updateUnChecked={this.props.onUpdatePrefs}
        />
        {this.renderAdditionalEmailsList()}
        <hr />
        {this.renderEmailPreferences()}
      </div>
    );
  }
}
