import PropTypes from 'prop-types';
import React from 'react';
import _findWhere from 'underscore/modules/findWhere';
import _map from 'underscore/modules/map';

export default class ClientDropdown extends React.Component {
  static get propTypes() {
    return {
      clientSlug: PropTypes.string,
      clients: PropTypes.array.isRequired,
      onChangeCurrentClient: PropTypes.func,
      disabled: PropTypes.bool,
      hideLabel: PropTypes.bool,
    };
  }

  formatClient(tb) {
    return (
      <option data-name={tb.person.name} key={tb.slug} value={tb.slug}>
        {tb.person.name}
      </option>
    );
  }

  clientsList() {
    const list = _map(this.props.clients, (tb) => this.formatClient(tb));

    if (this.props.disabled) {
      if (this.props.clients.length > 0) {
        const name = _findWhere(this.props.clients, { slug: this.props.clientSlug });
        return <span>{name.person.name}</span>;
      }
      return null;
    }

    return (
      <select
        id="clientSlug"
        onChange={this.props.onChangeCurrentClient}
        value={this.props.clientSlug || ''}
      >
        <option key="_blank" value="blank">
          Select Beneficiary
        </option>
        {list}
      </select>
    );
  }

  label() {
    if (!this.props.hideLabel) {
      return (
        <span className="client-dropdown__label">
          <label htmlFor="clientSlug">Client</label>
        </span>
      );
    }
    return null;
  }

  render() {
    const list = this.clientsList();
    return (
      <div className="client-dropdown">
        {this.label()}
        {list}
      </div>
    );
  }
}
