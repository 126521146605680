import PropTypes from 'prop-types';
import React from 'react';
import ReactInputMask from 'react-input-mask';
import LoadingIndicator from 'react/shared/components/LoadingIndicator';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import DashboardRulesetStore from 'react/shared/stores/DashboardRulesetStore';
import bindAll from 'react/shared/utils/bind_all';

export default class EditUserMobile extends React.Component {
  static get propTypes() {
    return {
      onUpdateMobile: PropTypes.func.isRequired,
    };
  }

  constructor(props) {
    super(props);

    this.state = {
      mobile: '',
      inFlight: false,
    };

    bindAll(this);
  }

  componentDidMount() {
    DashboardRulesetStore.on('person.mobile.updated.error', this.onResponse);
  }

  componentWillUnmount() {
    DashboardRulesetStore.off('person.mobile.updated.error', this.onResponse);
  }

  onResponse() {
    this.setState({ inFlight: false });
  }

  handleInputChange(ev) {
    this.setState({ mobile: ev.target.value });
  }

  handleUpdateMobile(ev) {
    ev.preventDefault();

    this.setState({ inFlight: true });
    this.props.onUpdateMobile(this.state.mobile);
  }

  render() {
    return (
      <div className="alerts-tab__edit-panel">
        <div>Add a phone number:</div>
        <form
          className="alerts-tab__edit-panel--inline-section"
          id="edit_user_mobile_form"
          onSubmit={this.handleUpdateMobile}
        >
          <ReactInputMask
            id="mobile"
            mask="999-999-9999"
            name="person[mobile]"
            onChange={this.handleInputChange}
            required
            type="text"
            value={this.state.mobile}
          />
          &nbsp;&nbsp;
          <TrueLinkButton type="submit" variant="primary">
            Save
          </TrueLinkButton>
          {this.state.inFlight ? (
            <LoadingIndicator
              containerStyle={{
                textAlign: 'left',
                marginLeft: '10px',
                height: 34,
              }}
            />
          ) : null}
        </form>
      </div>
    );
  }
}
