import axios from 'axios';

export const fetchAccessCodeValidationForUser = async (userId, accessCode) => {
  if (!/^\d{0,4}$/.test(accessCode)) {
    return 'bad_format';
  }
  if (accessCode.length < 4) {
    return 'not_validated';
  }

  const res = await axios.get(
    RailsRoutes.api_v2_access_codes_validate_path({ userId, code: accessCode }),
  );
  return res.data.meta.valid ? 'valid' : 'invalid';
};
