import { FastField, Field } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

function CheckboxField({ id, name, fast, disabled }) {
  const FieldComponent = fast ? FastField : Field;

  return (
    <FieldComponent name={name}>
      {({ field, form }) => (
        <input
          checked={field.value}
          disabled={disabled}
          id={id}
          name={field.name}
          onChange={() => {
            form.setFieldValue(field.name, !field.value);
          }}
          type="checkbox"
          value={field.value}
        />
      )}
    </FieldComponent>
  );
}

CheckboxField.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  fast: PropTypes.bool,
};

CheckboxField.defaultProps = {
  fast: true,
};

export default CheckboxField;
