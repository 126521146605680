import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import DetailLink from 'react/shared/card/components/DetailLink';
import Toggle from 'react/shared/card/components/Toggle';
import SavedMessage from 'react/shared/components/SavedMessage';
import theme from 'react/shared/theme/Theme';

const useStyles = makeStyles({
  [theme.breakpoints.down('md')]: {
    toggleLineFlex: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',

      '& .toggle_label': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        gap: '10px',
      },
      '& .detail_link': {
        marginLeft: '0px',
      },
    },
    toggleLine: {
      '& .detail_link': {
        marginLeft: '124px',
      },
    },

    toggleTextFlex: {
      margin: '10px 0',
      display: 'inline-flex',
    },
    toggleTextLine: {
      margin: '10px 0',
      width: '50%',
      display: 'inline-flex',
    },
  },
});
export default function ToggleLine(props) {
  const classes = useStyles();
  const selfRef = useRef();

  const handleChange = (e) => {
    if (e.target.checked) {
      props.checkedCallback && props.checkedCallback();
      props.updateChecked(props.rules, selfRef.current);
    } else {
      props.updateUnChecked(props.rules, selfRef.current, props.uncheckedCallback);
    }
  };

  const id = props.name || `toggle_${props.text.replace(/[^a-z0-9]/gi, '_')}`;
  return (
    <div className={props.flex ? classes.toggleLineFlex : classes.toggleLine} ref={selfRef}>
      <label
        className="toggle_label"
        data-original-title={props.hoverText}
        data-placement="right"
        data-toggle="tooltip"
        htmlFor={id}
      >
        <Toggle
          checked={props.is_checked}
          disabled={props.disabled}
          id={id}
          name={props.name}
          onChange={handleChange}
        />
        <span className={props.flex ? classes.toggleTextFlex : classes.toggleTextLine}>
          {props.text}
        </span>
      </label>
      <DetailLink detailText={props.detailText} />
      <SavedMessage />
      <hr className={props.hr ? '' : 'hidden'} />
    </div>
  );
}

ToggleLine.propTypes = {
  // Required properties
  updateChecked: PropTypes.func.isRequired,
  updateUnChecked: PropTypes.func.isRequired,
  is_checked: PropTypes.bool.isRequired,
  rules: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,

  // Optional properties
  disabled: PropTypes.bool,
  flex: PropTypes.bool,
  hr: PropTypes.bool,
  checkedCallback: PropTypes.func,
  uncheckedCallback: PropTypes.func,
  detailText: PropTypes.string,
  hoverText: PropTypes.string,
  name: PropTypes.string,
};

ToggleLine.defaultProps = {
  hr: true,
  disabled: false,
};
