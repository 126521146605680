import PropTypes from 'prop-types';

export const trustBeneficiaryShape = PropTypes.shape({
  slug: PropTypes.string.isRequired,
  id: PropTypes.number,
  status: PropTypes.string,
  deceasedIndicator: PropTypes.bool,
  externalId: PropTypes.string,
  beneficiaryId: PropTypes.string,
  welcomeLetterSignedAt: PropTypes.string,
  settlement: PropTypes.bool,
  disallowDisbursementRequests: PropTypes.bool,
  person: PropTypes.shape({
    trustBeneficiaryInternalId: PropTypes.string,
    dob: PropTypes.string,
    gender: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  activePortfolio: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  soleTrustee: PropTypes.bool,
  trust: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  investmentDirective: PropTypes.bool,
  overdrawAllowed: PropTypes.bool,
});
