import React from 'react';
import Profile from './Profile';

export default {
  title: 'Invest/Dashboard/Profile',
  component: Profile,
  argTypes: {
    firstName: {
      control: 'text',
      table: {
        category: 'Contents',
      },
    },
    age: {
      control: 'number',
      table: {
        category: 'Contents',
      },
    },
    dateOfBirth: {
      control: 'text',
      table: {
        category: 'Contents',
      },
    },
    phone: {
      control: 'text',
      table: {
        category: 'Contents',
      },
    },
    address: {
      control: 'object',
      table: {
        category: 'Contents',
      },
    },
  },
};

function Template(args) {
  return <Profile {...args} />;
}

export const Standard = Template.bind({});
Standard.args = {
  firstName: 'Allison',
  dateOfBirth: '1959-11-19',
  phone: '7404275000',
  age: 62,
  address: {
    city: 'Gambier',
    state: 'OH',
    zip: '43022',
    street1: '106 College Park Dr',
    street2: null,
  },
};
