import axios from 'axios';
import DashboardActions from 'react/member/actions/dashboard_actions';

const DashboardBeneGovernmentBenefitsStore = flux.createStore({
  governmentBenefits: {},

  actions: [
    DashboardActions.fetchBeneGovernmentBenefits,
    DashboardActions.updateBeneGovernmentBenefits,
  ],

  fetchBeneGovernmentBenefits(beneSlug) {
    this.governmentBenefits[beneSlug] = this.governmentBenefits[beneSlug] || {};
    const benefits = this.governmentBenefits[beneSlug];

    if (benefits['data']) {
      this.emit(`governmentBenefits.fetch.${beneSlug}`, benefits['data']);
    } else if (!benefits['fetching']) {
      benefits['fetching'] = true;
      this.requestBeneGovernmentBenefits(beneSlug);
    }
  },

  requestBeneGovernmentBenefits(beneSlug) {
    axios
      .get(
        RailsRoutes.dashboard_trust_beneficiary_government_benefits_path(beneSlug, {
          format: 'json',
        }),
      )
      .then((response) => {
        this.updateStoreData(beneSlug, response.data);
        this.emit(
          `governmentBenefits.fetch.${beneSlug}`,
          this.governmentBenefits[beneSlug]['data'],
        );
        this.governmentBenefits[beneSlug]['fetching'] = false;
      });
  },

  updateBeneGovernmentBenefits(beneSlug, params, onRequestCompleted) {
    axios
      .put(
        RailsRoutes.batch_update_dashboard_trust_beneficiary_government_benefits_path(beneSlug, {
          format: 'json',
        }),
        params,
      )
      .then((response) => {
        this.updateStoreData(beneSlug, response.data);
        Truelink.flash('success', 'Government benefits updated!');
        this.emit(
          `governmentBenefits.update.${beneSlug}`,
          this.governmentBenefits[beneSlug]['data'],
        );
      })
      .catch(() => {
        this.emit(`governmentBenefits.update.${beneSlug}.error`);
      })
      .finally(onRequestCompleted);
  },

  updateStoreData(beneSlug, newData) {
    this.governmentBenefits[beneSlug] = this.governmentBenefits[beneSlug] || {};
    this.governmentBenefits[beneSlug]['data'] = newData;
  },
});

export default DashboardBeneGovernmentBenefitsStore;
