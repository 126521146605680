import PropTypes from 'prop-types';
import React from 'react';
import ReactInputMask from 'react-input-mask';
import PopUp from 'react/shared/components/popups/PopUp';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import bindAll from 'react/shared/utils/bind_all';

export default class UpdateMobileModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mobile: props.mobile,
    };
    this.fieldName = `[${props.objectType}]mobile`;

    bindAll(this);
  }

  onFieldChange(e) {
    this.setState({
      mobile: e.target.value,
    });
  }

  async onSubmit() {
    const form = $('#mobile-modal');
    form.validate({
      rules: {
        [this.fieldName]: {
          phone: true,
        },
      },
    });

    if (!form.valid()) {
      Truelink.flash(
        'error',
        "You haven't provided all the information we need yet. Please provide the missing information.",
      );
      return;
    }

    try {
      const route = this.props.route;
      await $.ajax({ url: route, type: 'PUT', data: $(form).serializeArray() });

      Truelink.flash('success', 'Your phone number has been saved.');
      this.props.setPhoneNumber(this.state.mobile.replace(/-/g, ''));
      this.props.toggleModal();
    } catch (e) {
      Truelink.flash('error', 'There was an error submitting your phone number. Please try again.');
    }
    window.setTimeout(Truelink.flashClear, 3000);
  }

  renderForm() {
    return (
      <form className="new-form new-form--compact" id="mobile-modal">
        <div className="new-form-field new-form-field--medium">
          <div className="new-form__label">Name:</div>
          <div className="new-form__data">{this.props.name}</div>
        </div>
        <div className="form-break" />
        <div className="new-form-field new-form-field--medium">
          <div className="new-form__label">Phone number:</div>
          <div className="new-form__data">
            <ReactInputMask
              defaultValue={this.state.mobile}
              id="modal-mobile-field"
              mask="999-999-9999"
              name={this.fieldName}
              onChange={this.onFieldChange}
              required
              type="text"
            />
          </div>
        </div>
      </form>
    );
  }

  render() {
    return (
      <PopUp
        bodyHeight="48vh"
        footer={
          <TrueLinkButton onClick={this.onSubmit} variant="primary">
            Save
          </TrueLinkButton>
        }
        header="Add phone number"
        id="mobile_modal"
        maxHeight="300px"
        maxWidth="560px"
        onClose={this.props.toggleModal}
        openModal
      >
        {this.renderForm()}
      </PopUp>
    );
  }
}

UpdateMobileModal.propTypes = {
  name: PropTypes.string,
  objectType: PropTypes.string.isRequired,
  mobile: PropTypes.string,
  route: PropTypes.string,
  setPhoneNumber: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
};
