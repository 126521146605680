import PropTypes from 'prop-types';

export const fieldLabelKeys = {
  labelContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  labelClass: PropTypes.string,
  labelContainerClass: PropTypes.string,
  afterLabel: PropTypes.node,
  afterLabelClass: PropTypes.string,
  optional: PropTypes.bool,
  htmlFor: PropTypes.string,
};

const fieldLabelShape = PropTypes.shape(fieldLabelKeys);

export default fieldLabelShape;
