import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';
import Field from './Field';
import FieldMixin from './FieldMixin';

const ConstantField = createReactClass({
  propTypes: {
    controller: PropTypes.object.isRequired,
    label: PropTypes.string,
    model: PropTypes.string,
    name: PropTypes.string,
    object: PropTypes.object,
    inputDidMount: PropTypes.func,
    value: PropTypes.node,
    format: PropTypes.func,
  },

  mixins: [FieldMixin],

  componentDidMount() {
    this.initField();
  },

  inputDidMount(c) {
    if (this.props.inputDidMount != undefined) {
      this.props.inputDidMount(c);
    }
  },

  renderShow() {
    return <div style={{ maxWidth: 300 }}>{this.formattedValue()}</div>;
  },

  render() {
    return (
      <Field
        {...this.props}
        editable={this.editable}
        label={this.label}
        onKeyUp={this.onKeyUp}
        renderEdit={this.renderShow}
        renderShow={this.renderShow}
      />
    );
  },
});

export default ConstantField;
