import Card from '@mui/material/Card';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import React from 'react';
import PeterWall from './images/peter-wall.png';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import PALETTE from 'react/shared/theme/palette';
import sendEvent from 'react/shared/utils/google_analytics';

const useStyles = makeStyles({
  investmentTabContent: {
    display: 'flex',
    alignItems: 'center',
    padding: '2em 0',
  },
  column: {
    flex: 1,
  },
  listHeader: {
    fontWeight: 400,
    fontSize: '30px',
    marginBottom: '1em',
  },
  listItem: {
    display: 'flex',
    marginBottom: '1em',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  listIcon: {
    marginRight: '1em',
    marginTop: '0.4em', // to offset the line height on text
    color: PALETTE.emerald,
  },
  footer: {
    color: PALETTE.grey3,
    borderTop: `solid ${PALETTE.grey4} 1px`,
    paddingTop: '1em',
    marginTop: '1em',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1em',
    maxWidth: '85%',
    margin: '0 auto',
  },
  cardImage: {
    borderRadius: '50%',
  },
  cardHeader: {
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '28px',
    margin: 0,
  },
  cardSubHeader: {
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '24px',
    color: PALETTE.evergreen,
    margin: 0,
    marginBottom: '1em',
  },
  cardText: {
    textAlign: 'center',
    fontSize: '16px',
    lineHeight: '22px',
    color: PALETTE.grey2,
    marginBottom: '1em',
  },
  contactButton: {
    marginBottom: '1em',
  },
  address: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  marginBottom: {
    marginBottom: '1em',
  },
});

export default function InvestmentsTabContainer() {
  const classes = useStyles();
  const iconClasses = classNames('fa fa-check', classes.listIcon);
  const handleClick = () => {
    sendEvent('button_click', {
      campaign: 'Investment Tab Marketing',
      page: 'Investments Tab Page',
      reason: '"Schedule an appointment" button clicked',
      component: 'InvestmentsTabContainer',
    });
    window.open('https://calendly.com/peter-wall-true-link/30min');
  };

  return (
    <>
      <div className={classes.investmentTabContent}>
        <div className={classes.column}>
          <h1 className={classes.listHeader}>
            True Link offers integrated investment management* and banking solutions
          </h1>
          <div className={classes.listItem}>
            <i className={iconClasses} />
            <p>
              <b>Personalized investment strategies</b> factor in time horizons, spending needs, tax
              situations, risk tolerance, and more
            </p>
          </div>
          <div className={classes.listItem}>
            <i className={iconClasses} />
            <p>
              Our team has <b>nationally recognized expertise</b> in trust administration and
              investing for people with disabilities
            </p>
          </div>
          <div className={classes.listItem}>
            <i className={iconClasses} />
            <p>
              <b>Seamlessly manage your clients’ disbursements</b> in our trust administration
              platform via the True Link Visa® Prepaid Card, EFT, wire, or automated checking
            </p>
          </div>
          <div className={classes.listItem}>
            <i className={iconClasses} />
            <p>
              <b>No account minimums!</b> We believe everyone deserves these services
            </p>
          </div>
        </div>

        <div className={classes.column}>
          <Card className={classes.cardContainer} elevation={3}>
            <img
              alt="Peter J Wall headshot"
              className={classNames(classes.marginBottom, classes.cardImage)}
              src={PeterWall}
            />
            <h1 className={classes.cardHeader}>Talk with one of our experts</h1>
            <h2 className={classes.cardSubHeader}>Peter J. Wall, Fiduciary Services</h2>
            <p className={classes.cardText}>
              Widely recognized for his expertise in special needs investments, trust
              administration, and planning, Peter has 18+ years of experience at nationally
              chartered banks.
            </p>
            <TrueLinkButton
              className={classes.contactButton}
              onClick={handleClick}
              size="large"
              variant="primary"
            >
              Schedule an appointment
            </TrueLinkButton>
            <address className={classes.address}>
              <a className={classes.marginBottom} href="mailto:investments@truelinkfinancial.com">
                investments@truelinkfinancial.com
              </a>
              <a href="tel:+18553547921">+1-855-354-7921</a>
            </address>
          </Card>
        </div>
      </div>

      <p className={classes.footer}>
        *Investment Management Services are provided through True Link Financial Advisors, LLC, see
        footer for additional information.
      </p>
    </>
  );
}
