import PropTypes from 'prop-types';
import React from 'react';
import CardDetails from 'react/member/card/components/account_tab/card_status/CardDetails';
import { CARD_STATUSES } from 'react/member/utils/CardStatus';

export default function PreRelease({
  cardType,
  estimatedArrivalRange,
  formattedArrivalDate,
  oldCardLast4,
  status,
}) {
  const note = `A ${cardType} Card with a new 16-digit number has been requested. Activating this card will close the current card ending in ${oldCardLast4} and transfer the balance to this card.

  Cards typically arrive within ${estimatedArrivalRange[0]} to ${estimatedArrivalRange[1]} business days. Your card is estimated to arrive at your preferred address by ${formattedArrivalDate}.`;

  return <CardDetails cardLast4={null} expiryDate={null} note={note} status={status} />;
}

PreRelease.propTypes = {
  cardType: PropTypes.string.isRequired,
  estimatedArrivalRange: PropTypes.array.isRequired,
  formattedArrivalDate: PropTypes.string.isRequired,
  oldCardLast4: PropTypes.string.isRequired,
  status: PropTypes.oneOf([CARD_STATUSES.PRE_RELEASE_BACKUP_CARD_ORDER_STATUS]).isRequired,
};
