import PropTypes from 'prop-types';
import React from 'react';
import QuestionnaireRadioOption from './QuestionnaireRadioOption';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import bindAll from 'react/shared/utils/bind_all';

export default class AbleActProgramsOwnerTypeQuestionnaire extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ownerType: '',
    };

    bindAll(this);
  }

  setOwnerType(ev) {
    Truelink.flashClear();
    this.setState({ ownerType: ev.target.value });
  }

  submitForm(ev) {
    ev.preventDefault();
    const { ownerType } = this.state;

    if (ownerType === '') {
      Truelink.flash(
        'error',
        'Please select one of the options provided before pressing continue.',
      );
      return;
    }

    this.props.handleQuestionnaireChoice(ownerType);
  }

  render() {
    const { programName, programNameWithArticle } = this.props;

    const alrLabelText = `I am an Authorized Legal Representative (ALR) for ${programNameWithArticle} Account beneficiary`;
    const beneLabelText = `I am ${programNameWithArticle} Account beneficiary who is managing my own account`;

    return (
      <div>
        <div className="questionnaire-header__able">
          <h1>Welcome to {programName} Visa&reg; Prepaid Card Enrollment!</h1>
        </div>
        <div className="widescreen-inner-container">
          <div className="questionnaire-container__able">
            <div className="questionnaire-intro-text__able">
              <h2>
                If you have not requested a card for your {programName} Account, you can do so now.
              </h2>
            </div>
            <div className="questionnaire-form-item__able" style={{ width: '720px' }}>
              <h2>Please select the option that describes you and hit continue.</h2>
              <form id="owner-type-form" onSubmit={this.submitForm}>
                <div className="questionnaire-radio-wrapper__able">
                  <QuestionnaireRadioOption
                    inputProps={{
                      name: 'owner_type',
                      id: 'owner_type_alr',
                      value: 'alr',
                      onChange: this.setOwnerType,
                    }}
                    labelText={alrLabelText}
                  />
                  <QuestionnaireRadioOption
                    inputProps={{
                      name: 'owner_type',
                      id: 'owner_type_beneficiary',
                      value: 'beneficiary',
                      onChange: this.setOwnerType,
                    }}
                    labelText={beneLabelText}
                  />
                  <p
                    style={{
                      width: '570px',
                      margin: '0 auto',
                      marginTop: '30px',
                      fontSize: '18px',
                    }}
                  >
                    If you are a beneficiary with an Authorized Legal Representative (ALR), please
                    contact your ALR to order your {programName} Account card.
                  </p>
                </div>
                <div className="questionnaire-item-footer__able">
                  <TrueLinkButton size="large" type="submit" variant="primary">
                    <span>Continue&nbsp;&nbsp;</span>
                    <span
                      style={{
                        fontSize: '30px',
                        position: 'relative',
                        top: '1px',
                      }}
                    >
                      ›
                    </span>
                  </TrueLinkButton>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AbleActProgramsOwnerTypeQuestionnaire.propTypes = {
  handleQuestionnaireChoice: PropTypes.func.isRequired,
  programName: PropTypes.string.isRequired,
  programNameWithArticle: PropTypes.string.isRequired,
};
