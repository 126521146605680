import React, { useState } from 'react';
import AddressInputs from './AddressInputs';

export default function ShippingAddressInputs() {
  const [shippingDestination, setShippingDestination] = useState('');

  const handleShippingDestination = (evt) => {
    setShippingDestination(evt.target.value);
  };

  return (
    <div className="card--form__section">
      <div className="card--form__row">
        <div className="card--form__col width-100">
          <p>
            Where would you like this card to be shipped? (Please select one)
            <br />
            Cards may not be shipped to a P.O. Box. Please provide a street address.
          </p>
        </div>
      </div>
      <div className="card--form__row">
        <div className="card--form__col width-100">
          <div className="card--form__radio-wrapper">
            <label htmlFor="shipping_destination_office">
              <input
                id="shipping_destination_office"
                name="shipping_destination"
                onChange={handleShippingDestination}
                required
                type="radio"
                value="office"
              />{' '}
              My Home Address
            </label>
          </div>
        </div>
      </div>
      <div className="card--form__row">
        <div className="card--form__col width-100">
          <div className="card--form__radio-wrapper">
            <label className="radio_label" htmlFor="shipping_destination_cardholder_home">
              <input
                id="shipping_destination_cardholder_home"
                name="shipping_destination"
                onChange={handleShippingDestination}
                required
                type="radio"
                value="cardholder_home"
              />{' '}
              Beneficiary's Home Address
            </label>
          </div>
        </div>
      </div>
      <div className="card--form__row">
        <div className="card--form__col width-100">
          <div className="card--form__radio-wrapper">
            <label className="radio_label" htmlFor="shipping_destination_other">
              <input
                id="shipping_destination_other"
                name="shipping_destination"
                onChange={handleShippingDestination}
                required
                type="radio"
                value="other"
              />{' '}
              Other
            </label>
          </div>
        </div>
      </div>
      {shippingDestination === 'other' && (
        <AddressInputs labelPrefix="Additional" namePrefix="other" />
      )}
    </div>
  );
}
