import PropTypes from 'prop-types';
import React from 'react';
import DashboardCardActivationForm from 'react/shared/card/components/DashboardCardActivationForm';
import BulkCardOrderConfirmationBox from 'react/shared/card/components/bulk_card_ordering/BulkCardOrderConfirmationBox';
import PopUp from 'react/shared/components/popups/PopUp';
import addressShape from 'react/shared/shapes/address_shape';
import localeShape from 'react/shared/shapes/locale_shape';
import bindAll from 'react/shared/utils/bind_all';

export default class VccActivation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cardholder: {
        nickname: props.cardholderNickname,
      },
      address: {},
      activateCardModalOpen: true,
      personalizeCardModalOpen: false,
    };

    bindAll(this);
  }

  getChildContext() {
    const {
      address,
      cardholder,
      cardholderAddressAsString,
      officeAddressStringAsString,
      cardholderMobile,
      adminMobile,
      regularShipping,
      expeditedShipping,
      shippingFee,
      allowAutomatedPayment,
      paymentMethodOptions,
    } = this.state;
    const { accountSlug, cardSlug, localeProps } = this.props;
    const { msaLink, programName, supportEmail, supportADTollfree, supportCHTollfree } =
      localeProps;

    return {
      accountId: accountSlug,
      address,
      bulkCardOrder: true,
      cardId: cardSlug,
      cardholder,

      cardholderAddressAsString,
      officeAddressStringAsString,
      cardholderMobile,
      adminMobile,
      regularShipping,
      expeditedShipping,
      shippingFee,
      allowAutomatedPayment,
      paymentMethodOptions,

      onChangeCardholderAttr: this.handleChange('cardholder'),
      onChangeAddressAttr: this.handleChange('address'),
      msaLink,
      programName,
      supportEmail,
      supportADTollfree,
      supportCHTollfree,
      secondaryDocumentation: false,
      toggleConfirmationModal: this.handleClose,
    };
  }

  handleChange(key) {
    return (ev) => {
      ev.persist();
      this.setState((prevState) => ({
        [key]: { ...prevState[key], [ev.target.id]: ev.target.value },
      }));
    };
  }

  handleClose() {
    this.props.onRefresh();
    this.props.onClose();
  }

  handleSuccess(resp) {
    this.setState(
      {
        activateCardModalOpen: false,
        personalizeCardModalOpen: true,
        cardholderAddressAsString: resp.cardholder_address,
        officeAddressStringAsString: resp.office_address,
        cardholderMobile: resp.cardholder_mobile,
        adminMobile: resp.admin_mobile,
        regularShipping: resp.regular_shipping,
        expeditedShipping: resp.expedited_shipping,
        shippingFee: resp.shipping_fee,
        allowAutomatedPayment: resp.allow_automated_payment,
        paymentMethodOptions: resp.payment_method_options_for_card_fee,
      },
      this.props.onSuccess,
    );
  }

  render() {
    return (
      <>
        {this.state.activateCardModalOpen && (
          <DashboardCardActivationForm
            onClose={this.props.onClose}
            onSuccess={this.handleSuccess}
          />
        )}
        {this.state.personalizeCardModalOpen && (
          <PopUp
            dismissBtnText="Close"
            header="Success!"
            maxWidth="500px"
            modalOpen
            onClose={this.handleClose}
          >
            <BulkCardOrderConfirmationBox />
          </PopUp>
        )}
      </>
    );
  }
}

VccActivation.propTypes = {
  accountSlug: PropTypes.string.isRequired,
  cardSlug: PropTypes.string,
  cardholderNickname: PropTypes.string,
  localeProps: localeShape.isRequired,
  onClose: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

VccActivation.defaultProps = {
  cardSlug: null,
};

VccActivation.childContextTypes = {
  accountId: PropTypes.string,
  address: addressShape,
  bulkCardOrder: PropTypes.bool,
  cardId: PropTypes.string,
  cardholder: PropTypes.object,
  cardholderAddressAsString: PropTypes.string,
  officeAddressStringAsString: PropTypes.string,
  cardholderMobile: PropTypes.string,
  adminMobile: PropTypes.string,
  regularShipping: PropTypes.string,
  expeditedShipping: PropTypes.string,
  shippingFee: PropTypes.string,
  allowAutomatedPayment: PropTypes.bool,
  paymentMethodOptions: PropTypes.object,
  onChangeCardholderAttr: PropTypes.func,
  onChangeAddressAttr: PropTypes.func,
  msaLink: PropTypes.string,
  programName: PropTypes.string,
  supportEmail: PropTypes.string,
  supportADTollfree: PropTypes.string,
  supportCHTollfree: PropTypes.string,
  secondaryDocumentation: PropTypes.bool,
  toggleConfirmationModal: PropTypes.func,
};
