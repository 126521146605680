import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import IndividualTrustBalanceCell from './IndividualTrustBalanceCell';
import IndividualTrustNameCell from './IndividualTrustNameCell';
import TrueLinkTable from 'react/shared/components/true_link/main/table/TrueLinkTable';

const useStyles = makeStyles({
  table: {
    border: 0,
    '& th:last-child span': {
      justifyContent: 'end',
    },
    '& td:first-child': {
      display: 'flex',
      alignItems: 'baseline',
    },
    '& td:last-child': {
      textAlign: 'right',
    },
  },
});

function IndividualTrustTable({ trusts }) {
  const classes = useStyles();
  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: IndividualTrustNameCell,
    },
    {
      Header: 'Balance',
      accessor: 'balances',
      Cell: IndividualTrustBalanceCell,
    },
  ];

  const memoizedData = useMemo(() => trusts, [trusts]);

  return (
    <TrueLinkTable className={classes.table} columns={columns} data={memoizedData} paginated />
  );
}

IndividualTrustTable.propTypes = {
  trusts: PropTypes.array.isRequired,
};

export default IndividualTrustTable;
