import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';
import Field from './Field';
import FieldMixin from './FieldMixin';

const StringField = createReactClass({
  propTypes: {
    controller: PropTypes.object,
    editable: PropTypes.bool,
    label: PropTypes.string,
    model: PropTypes.string,
    name: PropTypes.string,
    object: PropTypes.object,
    style: PropTypes.object,
    inputDidMount: PropTypes.func,
    value: PropTypes.node,
    format: PropTypes.func,
    optional: PropTypes.bool,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
  },

  mixins: [FieldMixin],

  componentDidMount() {
    this.initField();
  },

  inputDidMount(c) {
    if (this.props.inputDidMount != undefined) {
      this.props.inputDidMount(c);
    }
  },

  renderShow() {
    return <span>{this.formattedValue()}</span>;
  },

  renderEdit() {
    const style = this.props.style || {};
    return (
      <input
        name={this.inputName()}
        onChange={this.onChange}
        onKeyUp={this.keyup}
        placeholder={this.props.placeholder}
        ref={this.inputDidMount}
        required={this.props.required}
        style={style}
        type="text"
        value={this.value() || ''}
      />
    );
  },

  render() {
    return (
      <Field
        {...this.props}
        editable={this.editable}
        label={this.label}
        onKeyUp={this.onKeyUp}
        renderEdit={this.renderEdit}
        renderShow={this.renderShow}
      />
    );
  },
});

export default StringField;
