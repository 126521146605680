import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import DashboardTrustBeneficiaryProfileGovernmentBenefitsForm from './DashboardTrustBeneficiaryProfileGovernmentBenefitsForm';
import DashboardActions from 'react/member/actions/dashboard_actions';
import DashboardBeneGovernmentBenefitsStore from 'react/member/stores/DashboardBeneGovernmentBenefitsStore';
import ToggleSectionHeader from 'react/shared/components/ToggleSectionHeader';
import TrueLinkModal from 'react/shared/components/true_link/lab/TrueLinkModal';
import TlFieldTransformers from 'react/shared/utils/tl_field_transformers';

export default function DashboardTrustBeneficiaryProfileGovernmentBenefits(props) {
  const [editing, setEditing] = useState(false);
  const [governmentBenefits, setGovernmentBenefits] = useState([]);
  const [governmentBenefitNotes, setGovernmentBenefitNotes] = useState([]);
  const [loading, setLoading] = useState(true);

  const { canEdit, expanded, toggleExpanded, trustBeneficiarySlug } = props;

  const onFetchData = (governmentBenefits) => {
    setGovernmentBenefits(governmentBenefits.trust_beneficiary.government_benefits);
    setGovernmentBenefitNotes(governmentBenefits.trust_beneficiary.government_benefit_notes);
    setLoading(false);
    setEditing(false);
  };

  useEffect(() => {
    DashboardBeneGovernmentBenefitsStore.on(
      `governmentBenefits.update.${trustBeneficiarySlug}`,
      onFetchData,
    );
    DashboardBeneGovernmentBenefitsStore.on(
      `governmentBenefits.fetch.${trustBeneficiarySlug}`,
      onFetchData,
    );
    DashboardActions.fetchBeneGovernmentBenefits(trustBeneficiarySlug);

    return () => {
      DashboardBeneGovernmentBenefitsStore.off(
        `governmentBenefits.update.${trustBeneficiarySlug}`,
        onFetchData,
      );
      DashboardBeneGovernmentBenefitsStore.off(
        `governmentBenefits.fetch.${trustBeneficiarySlug}`,
        onFetchData,
      );
    };
  }, [trustBeneficiarySlug]);

  const renderEditModal = () => {
    if (!editing || loading) return;

    return (
      <TrueLinkModal handleClose={() => setEditing(false)} title={'Update Government Benefits'}>
        <DashboardTrustBeneficiaryProfileGovernmentBenefitsForm
          governmentBenefitNotes={governmentBenefitNotes}
          governmentBenefits={governmentBenefits}
          trustBeneficiarySlug={trustBeneficiarySlug}
        />
      </TrueLinkModal>
    );
  };

  const renderHeader = () => (
    <ToggleSectionHeader title="Government Benefits" {...{ expanded, toggleExpanded }}>
      {canEdit ? (
        <div className="action-item">
          <button className="edit-link btn btn-link" onClick={() => setEditing(true)} type="button">
            <i className="fa fa-edit" />
            <span className="padding-5l">Edit</span>
          </button>
        </div>
      ) : null}
    </ToggleSectionHeader>
  );

  const renderGovernmentBenefits = () => {
    const { toYesNo } = TlFieldTransformers;
    return governmentBenefits.map((benefit) => (
      <li className="w-col-4 inline-block" key={benefit.governmentBenefitId}>
        <strong>{benefit.name}:</strong>
        <span className="padding-10l">{toYesNo(benefit.beneHasBenefit)}</span>
        {benefit.amount ? <span className="padding-10l">{benefit.amount}</span> : null}
      </li>
    ));
  };

  const renderContent = () => {
    if (!expanded) return;
    return (
      <div className="toggle-section-content">
        <div className="info-column block">
          <ul className="unstyled">{renderGovernmentBenefits()}</ul>
          <strong>Notes:</strong>
          <span className="padding-10l">{governmentBenefitNotes}</span>
        </div>
      </div>
    );
  };

  if (governmentBenefits.length === 0) {
    return null;
  }

  return (
    <div className="toggle-section" id="bene-profile-govt-benefits-section">
      {renderHeader()}
      {renderEditModal()}
      {renderContent()}
    </div>
  );
}

DashboardTrustBeneficiaryProfileGovernmentBenefits.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  trustBeneficiarySlug: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired,
  toggleExpanded: PropTypes.func.isRequired,
};
