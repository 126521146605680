(function(){
"use strict";

  function validateContactUs() {
    var validatorOptions = {};
    return $('#contact_us_form').validate(validatorOptions);
  }

  function initContactUs() {
    // Handle click of submit button
    $('#contact_us_submit_button').on('click', function () {
      // Disable the button to prevent multiple submits
      $('#contact_us_submit_button').attr("disabled", true);

      // Run validations
      var validator = validateContactUs();
      if (validator && !validator.form()) {
        // Re-enable the submit button to allow submit after validation is fixed
        $('#contact_us_submit_button').removeAttr("disabled");
        return;
      }

      // ajax call to web_forms_controller
      $.ajax( {
        url :  "email_form_submit",
        type: "POST",
        data: $('#contact_us_form').serializeArray()
      })
      .then( function(data, textStatus, jqXHR) {
        Truelink.flash('success', "Success!");
        window.setTimeout(function () {
          // performing a 2s wait
          document.location.href = '/contact_us/message_received';
        }, 2000);
      });
    });
  }

  $(document).ready(initContactUs);

})();
