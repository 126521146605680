import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import childrenShape from 'react/shared/shapes/children';
import theme from 'react/shared/theme/Theme';

const useStyles = makeStyles({
  bannerWrap: {
    width: '940px',
    padding: '1em 0',
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '1em',
    },
  },
  warningIcon: {
    fontSize: '1.5em',
    marginRight: '0.5em',
    color: '#fc824e',
  },
  warning: {
    backgroundColor: '#fff1eb',
  },
  wrap: {
    borderBottom: 'none',
    borderColor: '#506b83',
  },
});

export default function Banner({ variant, children }) {
  const classes = useStyles();

  return (
    <div className={`${classes.wrap} ${classes[variant]}`}>
      <div className={classes.bannerWrap}>
        {variant === 'warning' && (
          <i className={`fa fa-triangle-exclamation ${classes.warningIcon}`} />
        )}
        {children}
      </div>
    </div>
  );
}

Banner.propTypes = {
  children: childrenShape.isRequired,
  variant: PropTypes.oneOf(['warning']).isRequired,
};
