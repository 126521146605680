(function () {
  function initUnblockFraudLink() {
    $('#unblock_fraud_link').click(function (e) {
      e.preventDefault();
      var $alertDiv = $(document.createElement('div'));
      var $closeBtn = $(document.createElement('button'));
      $closeBtn.addClass('close').attr('data-dismiss', 'alert').text('x');
      $alertDiv.addClass('alert').append($closeBtn);

      var path = $(this).attr('href') + '.json';
      var data = {
        account_id: $(this).data('slug'),
      };
      $.post(path, data, function (response) {
        if (response.success) {
          $('.alert').remove();
          $('.cardholder_name.pull-right').text('');
          $alertDiv
            .addClass('alert-success')
            .append('Your account will be reactivated momentarily!');
        } else {
          var email_link = $(document.createElement('a'))
            .attr({
              href: '/contact',
              target: '_blank',
            })
            .text('contact us');
          $alertDiv
            .addClass('alert-danger')
            .append('This card could not be automatically re-activated. Please ')
            .append(email_link)
            .append(' to resolve this issue');
        }
        $('.dashboard-header').after($alertDiv);
      });
    });
  }

  function init() {
    if ($('.cardholder-home').length === 0) return;
    initUnblockFraudLink();
  }

  $(document).ready(init);
})();
