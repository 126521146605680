import I18n from 'i18n-js';
import PropTypes from 'prop-types';
import React from 'react';

function SignupFlowMobileFooter({ afterFooterNode }) {
  return (
    <div className="sign-up-flow__footer--mobile">
      <p>
        If you have any questions, please contact us at
        <br />
        <a href={`mailto:${I18n.t('notifications.emails.noreply')}`}>
          {` ${I18n.t('notifications.emails.noreply')} `}
        </a>
        or
        <a href={`tel:${I18n.t('corp.ad_tollfree_numeric')}`}>
          {` ${I18n.t('corp.ad_tollfree_numeric')}`}
        </a>
      </p>
      {afterFooterNode && (
        <div className="sign-up-flow__footer--mobile--after">{afterFooterNode}</div>
      )}
    </div>
  );
}

SignupFlowMobileFooter.propTypes = {
  afterFooterNode: PropTypes.node,
};

SignupFlowMobileFooter.defaultProps = {
  afterFooterNode: null,
};

export default SignupFlowMobileFooter;
