import axios from 'axios';
import classNames from 'classnames';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import * as Yup from 'yup';
import DashboardActions from 'react/member/actions/dashboard_actions';
import FormSelectSearchableField from 'react/shared/components/forms/form_select_searchable_field';
import TrueLinkModal from 'react/shared/components/true_link/lab/TrueLinkModal';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import DashboardClientStore from 'react/shared/stores/DashboardClientStore';
import bindAll from 'react/shared/utils/bind_all';

export default class DashboardNewPortalUserForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newPortalUserModal: false,
      loadingClients: true,
    };

    bindAll(this);
  }

  componentDidMount() {
    if (this.props.orgSlug) {
      DashboardActions.fetchClientSelectIdsByOrganization(this.props.orgSlug);
    } else {
      DashboardActions.fetchClientSelectIds();
    }
    DashboardClientStore.on('clients.fetchSelectIds', this.onFetchClients);
  }

  componentWillUnmount() {
    DashboardClientStore.off('clients.fetchSelectIds', this.onFetchClients);
  }

  initialValues() {
    return {
      trustBeneficiaryId: '',
      firstName: '',
      lastName: '',
      email: '',
      allowRequestDisbursements: '',
      resendInvitation: '',
    };
  }

  validationSchema() {
    return Yup.object().shape({
      trustBeneficiaryId: Yup.string().required('Trust Beneficiary is Required'),
      firstName: Yup.string().required('User First Name is Required'),
      lastName: Yup.string().required('User Last Name is Required'),
      email: Yup.string().email('Invalid format').required('User Email is Required'),
    });
  }

  onFetchClients(clients) {
    this.setState({
      clientList: clients,
      loadingClients: false,
    });
  }

  setNewUserModalTrue() {
    this.setState({
      newPortalUserModal: true,
    });
  }

  setNewUserModalFalse() {
    this.setState({
      newPortalUserModal: false,
    });
  }

  renderNewPortalUserButton() {
    return (
      <button
        className="btn btn-link btn-link btn-link--add nopadding"
        onClick={this.setNewUserModalTrue}
        type="button"
      >
        Invite New Portal User
      </button>
    );
  }

  clientOptions() {
    const clientOptions = this.state.clientList.map((client) => {
      if (client.status === 'Inactive') {
        return {
          value: client.slug,
          label: `${client.person.name} - INACTIVE`,
        };
      }
      return {
        value: client.slug,
        label: client.person.name,
      };
    });

    return clientOptions;
  }

  async onSubmit(values) {
    const trust_beneficiary_id = values.trustBeneficiaryId;

    const url = RailsRoutes.dashboard_trust_beneficiary_send_new_portal_user_request_path(
      trust_beneficiary_id,
      {
        format: 'json',
      },
    );

    const data = {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      request_disbursements: values.allowRequestDisbursements,
      resend_invitation: values.resendInvitation,
    };

    return axios
      .post(url, data)
      .then(() => {
        Truelink.flash('success', 'Invite Processed');
        this.setNewUserModalFalse();
      })
      .catch(() => {
        Truelink.flash('error', 'There was a problem sending the Invite.');
      });
  }

  renderNewPortalUserModal() {
    const { newPortalUserModal } = this.state;
    if (!newPortalUserModal) {
      return;
    }

    return (
      <TrueLinkModal handleClose={this.setNewUserModalFalse} title={'Invite New Portal User'}>
        <Formik
          initialValues={this.initialValues()}
          onSubmit={this.onSubmit}
          validationSchema={this.validationSchema()}
        >
          {({ isSubmitting, isValid }) => (
            <Form>
              <div className="tl-form-edit align-left">
                <div className="tl-fields-row">
                  <div className="tl-field span3">
                    <label className="tl-field-label" htmlFor="trustBeneficiaryId">
                      Trust Beneficiary
                    </label>
                    <div className="tl-field-data">
                      <Field
                        component={FormSelectSearchableField}
                        name="trustBeneficiaryId"
                        options={this.clientOptions()}
                      />
                    </div>
                    <ErrorMessage name="trustBeneficiaryId">
                      {(msg) => <div className="tl-field-error">{msg}</div>}
                    </ErrorMessage>
                  </div>
                </div>
                <div className="tl-fields-row">
                  <div className="tl-field w-col-6">
                    <label className="tl-field-label" htmlFor="firstName">
                      User First Name
                    </label>

                    <div className="tl-field-data">
                      <Field name="firstName" type="text" />
                    </div>

                    <ErrorMessage name="firstName">
                      {(msg) => <div className="tl-field-error">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="tl-field w-col-6">
                    <label className="tl-field-label" htmlFor="lastName">
                      User Last Name
                    </label>

                    <div className="tl-field-data">
                      <Field name="lastName" type="text" />
                    </div>

                    <ErrorMessage name="lastName">
                      {(msg) => <div className="tl-field-error">{msg}</div>}
                    </ErrorMessage>
                  </div>
                </div>
                <div className="tl-fields-row">
                  <div className="tl-field w-col-12">
                    <label className="tl-field-label" htmlFor="email">
                      User Email
                    </label>

                    <div className="tl-field-data">
                      <Field name="email" type="text" />
                    </div>

                    <ErrorMessage name="email">
                      {(msg) => <div className="tl-field-error">{msg}</div>}
                    </ErrorMessage>
                  </div>
                </div>

                <div className="tl-fields-row">
                  <div className="tl-field">
                    <label className="tl-field-label" htmlFor="allowRequestDisbursements">
                      <span className="tl-checkbox-input">
                        <Field
                          component="input"
                          id="allowRequestDisbursements"
                          name="allowRequestDisbursements"
                          type="checkbox"
                        />
                      </span>
                      Allow user to request disbursements
                    </label>
                  </div>
                </div>

                <div className="tl-fields-row">
                  <div className="tl-field">
                    <label className="tl-field-label" htmlFor="resendInvitation">
                      <span className="tl-checkbox-input">
                        <Field
                          component="input"
                          id="resendInvitation"
                          name="resendInvitation"
                          type="checkbox"
                        />
                      </span>
                      Resend user invitation
                    </label>
                  </div>
                </div>

                <div className="tl-form-actions-row">
                  <TrueLinkButton
                    className={classNames('btn btn-success', {
                      disabled: isSubmitting || !isValid,
                    })}
                    disabled={isSubmitting || !isValid}
                    type="submit"
                    variant="none"
                  >
                    Update
                  </TrueLinkButton>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </TrueLinkModal>
    );
  }

  render() {
    if (this.state.loadingClients) {
      return null;
    }

    return (
      <div>
        {this.renderNewPortalUserButton()}
        {this.renderNewPortalUserModal()}
      </div>
    );
  }
}

DashboardNewPortalUserForm.propTypes = {
  orgSlug: PropTypes.string,
};
