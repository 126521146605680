import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';
import _isNull from 'underscore/modules/isNull';
import Field from './Field';
import FieldMixin from './FieldMixin';
import { capitalizeWords, humanize } from 'react/shared/utils/Strings';

const RadioBoolField = createReactClass({
  propTypes: {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  },

  mixins: [FieldMixin],

  getDefaultProps() {
    return {
      format: (value) => {
        if (value == true) {
          return 'YES';
        }
        if (value == false) {
          return 'NO';
        }
        return value && capitalizeWords(humanize(value));
      },
      style: { width: 50 },
      allowFalse: true,
    };
  },

  componentDidMount() {
    this.initField();
  },

  renderShow() {
    return <span>{this.formattedValue()}</span>;
  },

  renderEdit() {
    return [true, false].map((bool) => {
      let label;
      if (bool) {
        label = 'Yes';
      } else {
        label = 'No';
      }
      const key = `radioInput${this.inputName()}${label}`;
      const defaultChecked = !_isNull(this.value()) && this.value() == bool;

      return (
        // eslint-disable-next-line jsx-a11y/label-has-associated-control
        <label key={key}>
          <input
            data-checkbox-style="single"
            defaultChecked={defaultChecked}
            name={this.inputName()}
            onChange={this.onCheck}
            type="radio"
            value={bool}
          />{' '}
          {this.format()(label)}
        </label>
      );
    });
  },

  onCheck(evt) {
    this.change(evt.target.value == 'true');
  },

  render() {
    return (
      <Field
        {...this.props}
        editable={this.editable}
        label={this.label}
        renderEdit={this.renderEdit}
        renderShow={this.renderShow}
      />
    );
  },
});

RadioBoolField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default RadioBoolField;
