import PropTypes from 'prop-types';
import React from 'react';
import PopUp from 'react/shared/components/popups/PopUp';

export default function DisbursementApprovalModal({ disbursement, onClose }) {
  const footer = (
    <button className="btn btn-success" onClick={onClose} type="button">
      Close
    </button>
  );
  const body = disbursement.can_be_approved ? (
    <span>
      You are authorized to approve this disbursement. Please click "Approve" on the following page
      to complete the request.
    </span>
  ) : (
    <span>
      This disbursement has been requested. You will see it as pending on the next page. Once an
      authorized member of your organization approves the disbursement, it will be scheduled
      according to the process date.
    </span>
  );

  return (
    <PopUp
      footer={footer}
      header="Your request is pending"
      hideDismissButton
      maxWidth="700px"
      onClose={onClose}
      openModal={disbursement != undefined}
    >
      {body}
    </PopUp>
  );
}

DisbursementApprovalModal.propTypes = {
  disbursement: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};
