import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import CategorizedBudgetTimelineModal from './CategorizedBudgetTimelineModal';
import { ordinalize } from 'react/shared/utils/Numbers';

export default function CategorizedBudgetTimeline({
  id,
  renew,
  start,
  monthlyStart,
  trustBeneficiary,
}) {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleTimelineModal = useCallback(
    (e) => {
      e && e.preventDefault();
      setModalOpen(!modalOpen);
    },
    [modalOpen],
  );

  const closeTimelineModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  const formatDate = useCallback((date) => (date ? date : '-'), []);

  return (
    <div className="budget__timeline">
      <div>
        Budgets start:
        <span className="budget__timeline--date">{formatDate(start)}</span>
      </div>
      <div>
        Budgets renewal:
        <span className="budget__timeline--date">{formatDate(renew)}</span>
      </div>
      <div className="budget__timeline--monthly-start">
        {monthlyStart &&
          start &&
          renew &&
          `Monthly budgets start on the ${ordinalize(monthlyStart)} of the month`}
      </div>
      <div>
        <a href="#" onClick={toggleTimelineModal}>
          {monthlyStart && start && renew ? 'Edit' : 'Set budget timeline'}
        </a>
      </div>
      {modalOpen && (
        <CategorizedBudgetTimelineModal
          budgetTimeline={{
            id,
            startDate: start,
            renewalDate: renew,
          }}
          onClose={closeTimelineModal}
          trustBeneficiary={trustBeneficiary}
        />
      )}
    </div>
  );
}

CategorizedBudgetTimeline.propTypes = {
  id: PropTypes.string,
  start: PropTypes.string,
  renew: PropTypes.string,
  monthlyStart: PropTypes.string,
  trustBeneficiary: PropTypes.shape({
    id: PropTypes.number,
    person: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
};
