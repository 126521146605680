import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import React from 'react';
import DashboardCardActivationForm from 'react/shared/card/components/DashboardCardActivationForm';
import BulkCardOrderConfirmationBox from 'react/shared/card/components/bulk_card_ordering/BulkCardOrderConfirmationBox';
import PopUp from 'react/shared/components/popups/PopUp';
import localeShape, { localeShapeKeys } from 'react/shared/shapes/locale_shape';
import bindAll from 'react/shared/utils/bind_all';

export default class AccountListActivation extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initialState();

    bindAll(this);
  }

  getChildContext() {
    return {
      toggleModal: this.toggleModal,
      toggleConfirmationModal: this.toggleConfirmationModal,
      bulkCardOrder: this.state.bulkCardOrder,
      accountId: this.state.accountId,
      onChangeCardholderAttr: this.onChangeCardholderAttr,
      onChangeAddressAttr: this.onChangeAddressAttr,

      cardholder: this.state.cardholder,
      address: this.state.address,

      cardholderAddressAsString: this.state.cardholderAddressAsString,
      officeAddressStringAsString: this.state.officeAddressStringAsString,
      cardholderMobile: this.state.cardholderMobile,
      adminMobile: this.state.adminMobile,
      regularShipping: this.state.regularShipping,
      expeditedShipping: this.state.expeditedShipping,
      shippingFee: this.state.shippingFee,
      allowAutomatedPayment: this.state.allowAutomatedPayment,
      paymentMethodOptions: this.state.paymentMethodOptions,

      msaLink: this.state.msaLink,
      programName: this.state.programName,
      supportEmail: this.state.supportEmail,
      supportADTollfree: this.state.supportADTollfree,
      supportCHTollfree: this.state.supportCHTollfree,
    };
  }

  componentDidMount() {
    $('#account_list').on('click', 'a.account_list_activation', this.toggleModal);
  }

  componentWillUnmount() {
    $('#account_list').off('click', 'a.account_list_activation', this.toggleModal);
  }

  resetState() {
    this.setState(this.initialState());
  }

  initialState() {
    return {
      modalOpen: false,
      confirmationModalOpen: false,
      bulkCardOrder: false,
      attempts: 0,
      cardholder: {
        first_name: '',
        last_name: '',
        dob: '',
        card_last_4: '',
        ssn: '',
      },
      address: {
        street1: '',
        street2: '',
        city: '',
        state: '',
        zip: '',
      },
      accountId: '',
      cardholderAddressAsString: '',
      officeAddressStringAsString: '',
      cardholderMobile: '',
      adminMobile: '',
      regularShipping: '',
      expeditedShipping: '',
      shippingFee: '',
      allowAutomatedPayment: false,
      paymentMethodOptions: {
        available_bank_accounts: [],
        available_payment_methods: [],
        preferred_bank_account: '',
        preferred_payment_method: '',
      },
    };
  }

  onChangeCardholderAttr(ev) {
    const attr = ev.target.id;
    const { state } = this;
    state.cardholder[attr] = ev.target.value;
    this.setState(state);
  }

  onChangeAddressAttr(ev) {
    const { target } = ev;
    const attr = target.id;
    this.setState(({ address }) => {
      address[attr] = target.value;
      return { address };
    });
  }

  confirmationPopupProps() {
    return {
      header: 'Success!',
      onClose: this.closeConfirmationModal,
      modalOpen: this.state.confirmationModalOpen,
      maxWidth: '500px',
      dismissBtnText: 'Close',
    };
  }

  toggleModal(ev) {
    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
    }

    const tag = $(ev?.target);
    const accountId = tag?.data('account-id');
    const bulkCardOrder = tag?.data('bulk-card-order') === true;
    const reIssueACard = tag?.data('card_order_type') !== 'original card';
    //New funding flow for all non VCC card or VCC Reissue A card
    if (accountId && (!bulkCardOrder || (bulkCardOrder && reIssueACard))) {
      window.location.href = RailsRoutes.select_account_path(accountId);
      Cookies.set('activate_card_modal', true, { expires: 1 / 1440 }); // 1 minute is 1/1440 of a day
    } else {
      this.setState(({ modalOpen }) => {
        const state = { modalOpen: !modalOpen };
        if (!ev) return state;

        if (state['modalOpen']) {
          const nickname = tag.data('cardholder-nickname');
          const programType = tag.data('program-type');
          if (programType) {
            state['msaLink'] = this.context.localeProps[programType].msaLink;
            state['programName'] = this.context.localeProps[programType].programName;
            state['supportEmail'] = this.context.localeProps[programType].supportEmail;
            state['supportADTollfree'] = this.context.localeProps[programType].supportADTollfree;
            state['supportCHTollfree'] = this.context.localeProps[programType].supportCHTollfree;
          }
          if (bulkCardOrder) {
            state['cardholder'] = { nickname };
          } else {
            const firstName = tag.data('cardholder-first-name');
            const lastName = tag.data('cardholder-last-name');
            const address = tag.data('cardholder-address');
            const mobile = tag.data('cardholder-mobile');
            if (address) {
              state['address'] = address;
            }
            state['cardholder'] = {
              first_name: firstName,
              last_name: lastName,
              mobile,
              nickname,
            };
          }
        }
        if (accountId) {
          state['accountId'] = accountId;
        }
        state['bulkCardOrder'] = bulkCardOrder;

        return state;
      });
    }
  }

  toggleConfirmationModal() {
    if (this.state.confirmationModalOpen) {
      this.setState(this.initialState());
    } else {
      this.setState((prevState) => ({
        ...prevState,
        confirmationModalOpen: true,
      }));
    }
  }

  closeConfirmationModal() {
    this.setState({ confirmationModalOpen: false });
  }

  updateActivateLink() {
    $(`a[data-account-id="${this.state.accountId}"]`).replaceWith('$0.00');
  }

  onSuccess(resp) {
    $('#spinner, #overlay').hide();
    Truelink.flashClear();
    this.setState((prevState) => ({
      ...prevState,
      accountId: resp.account_id,
      cardholderAddressAsString: resp.cardholder_address,
      officeAddressStringAsString: resp.office_address,
      cardholderMobile: resp.cardholder_mobile,
      adminMobile: resp.admin_mobile,
      regularShipping: resp.regular_shipping,
      expeditedShipping: resp.expedited_shipping,
      shippingFee: resp.shipping_fee,
      allowAutomatedPayment: resp.allow_automated_payment,
      paymentMethodOptions: resp.payment_method_options_for_card_fee,
    }));

    this.updateActivateLink();
    this.toggleConfirmationModal();
    this.toggleModal();
  }

  render() {
    return (
      <div>
        {this.state.modalOpen && (
          <DashboardCardActivationForm onClose={this.resetState} onSuccess={this.onSuccess} />
        )}
        <PopUp {...this.confirmationPopupProps()}>
          <BulkCardOrderConfirmationBox />
        </PopUp>
      </div>
    );
  }
}

AccountListActivation.childContextTypes = {
  toggleModal: PropTypes.func,
  toggleConfirmationModal: PropTypes.func,
  bulkCardOrder: PropTypes.bool,
  accountId: PropTypes.string,
  onChangeCardholderAttr: PropTypes.func,
  onChangeAddressAttr: PropTypes.func,

  cardholder: PropTypes.object,
  address: PropTypes.object,

  cardholderAddressAsString: PropTypes.string,
  officeAddressStringAsString: PropTypes.string,
  cardholderMobile: PropTypes.string,
  adminMobile: PropTypes.string,
  regularShipping: PropTypes.string,
  expeditedShipping: PropTypes.string,
  shippingFee: PropTypes.string,
  allowAutomatedPayment: PropTypes.bool,
  paymentMethodOptions: PropTypes.object,
  ...localeShapeKeys,
};

AccountListActivation.contextTypes = {
  localeProps: localeShape,
};
