import React, { useMemo, useState } from 'react';
import TrueLinkRadiosWithDropdowns from './TrueLinkRadiosWithDropdowns';

export default {
  title: 'Forms/Lab/Radios With Dropdowns',
  component: TrueLinkRadiosWithDropdowns,
  argTypes: {
    onRadioChange: { action: 'clicked' },
    onDropdownChange: { action: 'selected' },
  },
};

function Template(args) {
  const [value, setValue] = useState('');
  useMemo(() => {
    setValue(args.value);
  }, [args.value]);
  const handleChange = (e) => {
    setValue(e.target.value);
    args.onRadioChange(e);
  };

  return <TrueLinkRadiosWithDropdowns {...args} onRadioChange={handleChange} value={value} />;
}

export const RadiosWithoutDropdowns = Template.bind({});
RadiosWithoutDropdowns.args = {
  options: [
    { value: 'choice1', label: 'Choice 1' },
    { value: 'choice2', label: 'Choice 2' },
    { value: 'choice3', label: 'Choice 3' },
  ],
  ariaLabel: 'Radios',
  name: 'radio-selection',
  value: 'choice3',
};

export const RadiosWithDropdowns = Template.bind({});
RadiosWithDropdowns.args = {
  options: [
    { value: 'easy', label: 'Easy' },
    { value: 'hard', label: 'Hard' },
    {
      value: 'other',
      label: 'Other',
      collection: [
        { id: '1', label: 'Medium', type: 'choice' },
        { id: '2', label: 'Easyish', type: 'choice' },
        { id: '3', label: 'Hardish', type: 'choice' },
        { id: '4', label: 'Impossible', type: 'choice' },
      ],
    },
  ],
  ariaLabel: 'Radios',
  name: 'radio-selection',
  value: 'other',
};

export const RadiosWithChips = Template.bind({});
RadiosWithChips.args = {
  options: [
    { value: 'choice1', label: 'Choice 1', chipLabel: "Here's a chip" },
    { value: 'choice2', label: 'Choice 2' },
    { value: 'choice3', label: 'Choice 3', chipLabel: '!' },
  ],
  ariaLabel: 'Radios',
  name: 'radio-selection',
  value: '',
};

export const RadiosWithOneDisabled = Template.bind({});
RadiosWithOneDisabled.args = {
  options: [
    { value: 'choice1', label: 'Choice 1', disabled: true },
    { value: 'choice2', label: 'Choice 2' },
    { value: 'choice3', label: 'Choice 3' },
  ],
  ariaLabel: 'Radios',
  name: 'radio-selection',
  value: '',
};
