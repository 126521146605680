import PropTypes from 'prop-types';
import React from 'react';
import CardDetails from 'react/member/card/components/account_tab/card_status/CardDetails';
import { CARD_STATUSES } from 'react/member/utils/CardStatus';
import { possessiverize } from 'react/shared/utils/Strings';
import { programNameFromType } from 'react/shared/utils/account_program_type';

export default function Replaced({
  cardBalance,
  cardBreached,
  cardExpiryDate,
  cardLast4,
  cardholderName,
  programType,
  status,
}) {
  const name = possessiverize(cardholderName);
  const cardType = programNameFromType(programType);
  const note = `Replacement card requested. ${name} current ${cardType} Card ending in ${cardLast4} is now inactive and cannot be used. A new ${cardType} Card with a new 16-digit number has been ordered.`;
  return (
    <CardDetails
      cardBalance={cardBalance}
      cardBreached={cardBreached}
      cardLast4={cardLast4}
      expiryDate={cardExpiryDate}
      note={note}
      status={status}
    />
  );
}

Replaced.propTypes = {
  cardBalance: PropTypes.number,
  cardBreached: PropTypes.bool,
  cardExpiryDate: PropTypes.string,
  cardLast4: PropTypes.string.isRequired,
  cardholderName: PropTypes.string,
  programType: PropTypes.oneOf(['en', 'stable', 'able']).isRequired,
  status: PropTypes.oneOf([CARD_STATUSES.REPLACED_CARD_STATUS]).isRequired,
};

Replaced.defaultProps = {
  cardholderName: 'The cardholder',
};
