import DashboardActions from 'react/member/actions/dashboard_actions';

const DashboardRecurringDepositStore = flux.createStore({
  actions: [DashboardActions.cancelRecurringDeposit],

  cancelRecurringDeposit(id, trustBeneId, reason) {
    const url = RailsRoutes.dashboard_recurring_deposit_cancel_path(id, { format: 'json' });
    const recurringDeposit = {
      trust_beneficiary_id: trustBeneId,
      cancellation_reason: reason,
    };
    return $.ajax({
      url,
      type: 'PUT',
      data: { recurringDeposit },
      success: (recurringDeposit) => {
        Truelink.flash('notice', 'Recurring deposit cancelled!');
        this.emit('recurringDeposit.update', recurringDeposit);
        this.emit('depositCancelModal.close');
      },
    });
  },
});

export default DashboardRecurringDepositStore;
