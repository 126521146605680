import axios from 'axios';
import _find from 'underscore/modules/find';
import DashboardActions from 'react/member/actions/dashboard_actions';
import { AXIOS_XHR_CONFIG } from 'react/shared/utils/Axios';

const DashboardWealthAccountEventStore = flux.createStore({
  wealthAccountEvents: [],
  actions: [
    DashboardActions.fetchWealthAccountEventsByOrganization,
    DashboardActions.fetchWealthAccountEventsByTrust,
    DashboardActions.fetchWealthAccountEvents,
    DashboardActions.updateWealthAccountEvent,
    DashboardActions.fetchWealthAccountEventsByPortfolio,
  ],

  fetchWealthAccountEventsByOrganization(organizationSlug) {
    $.get(
      RailsRoutes.dashboard_organization_wealth_account_events_path(organizationSlug, {
        format: 'json',
      }),
      (wealthAccountEvents) => {
        this.wealthAccountEvents = wealthAccountEvents;
        this.emit('wealthAccountEvents.fetch');
      },
    );
  },

  fetchWealthAccountEventsByTrust(trustSlug) {
    $.get(
      RailsRoutes.dashboard_trust_wealth_account_events_path(trustSlug, {
        format: 'json',
      }),
      (wealthAccountEvents) => {
        this.wealthAccountEvents = wealthAccountEvents;
        this.emit('wealthAccountEvents.fetch');
      },
    );
  },

  fetchWealthAccountEventsByPortfolio(portfolioId) {
    $.get(
      RailsRoutes.dashboard_portfolio_wealth_account_events_path(portfolioId, {
        format: 'json',
      }),
      (wealthAccountEvents) => {
        this.wealthAccountEvents = wealthAccountEvents;
        this.emit('wealthAccountEvents.fetch', this.wealthAccountEvents);
      },
    );
  },

  fetchWealthAccountEvents(clientSlug) {
    axios
      .get(
        RailsRoutes.dashboard_client_wealth_account_events_path(clientSlug, 'all', 'none', {
          format: 'json',
        }),
        AXIOS_XHR_CONFIG,
      )
      .then(({ data: wealthAccountEvents }) => {
        this.wealthAccountEvents = wealthAccountEvents;
        this.emit('wealthAccountEvents.fetch');
      })
      .catch(() => {
        // Do nothing other than let the interceptor handle it
      });
  },

  updateWealthAccountEvent(data, name) {
    const { id } = data;
    const dashboard_organization_id = data.organization_id;
    delete data.organization_id;
    delete data.id;
    $.ajax({
      url: RailsRoutes.dashboard_organization_wealth_account_event_path(
        dashboard_organization_id,
        id,
        {
          format: 'json',
        },
      ),
      data: { wealth_account_event: data },
      method: 'PUT',
    })
      .done(() => {
        this.emit('wealthAccountEvent.update');
        const updateName = name ? `${name} ` : '';
        Truelink.flash('success', `Transaction ${updateName}updated!`);
      })
      .fail(() => {
        Truelink.flash('error', 'Unable to update transaction! Please contact True Link support.');
      });
  },

  exports: {
    get(slug) {
      return _find(this.wealthAccountEvents, (waEvent) => waEvent.slug === slug);
    },

    getWealthAccountEvents() {
      return this.wealthAccountEvents;
    },
  },
});

export default DashboardWealthAccountEventStore;
