import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import LoadingIndicator from 'react/shared/components/LoadingIndicator';
import SubSectionHeader from 'react/shared/components/SubSectionHeader';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import TrueLinkIcon from 'react/shared/components/true_link/main/TrueLinkIcon';

const useStyles = makeStyles({
  icon: {
    marginRight: '5px',
  },
});

export default function GenericSubSectionHeaderLayout({
  title,
  subtitle,
  loading,
  actionItems = [],
}) {
  const classes = useStyles();
  return (
    <div className="generic-subsection-header-layout">
      <SubSectionHeader noMarginBottom>
        <div className="generic-subsection-header-layout__title-wrap">
          <span className="generic-subsection-header-layout__title">
            {title}
            {loading ? (
              <LoadingIndicator containerStyle={{ width: '30px', marginLeft: '10px' }} />
            ) : null}
          </span>
          <span>
            {actionItems.map(({ displayText, icon, action }, i) => (
              <span
                className="generic-subsection-header-layout__action-item"
                key={`action-item-${i}`}
              >
                <TrueLinkButton className="btn btn-link" onClick={action} variant="none">
                  {icon ? <TrueLinkIcon className={classes.icon} icon={icon} /> : null}
                  {displayText}
                </TrueLinkButton>
              </span>
            ))}
          </span>
        </div>
      </SubSectionHeader>
      {subtitle && (
        <div className="generic-subsection-header-layout__subtitle-wrap">
          <p>{subtitle}</p>
        </div>
      )}
    </div>
  );
}

GenericSubSectionHeaderLayout.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  loading: PropTypes.bool,
  actionItems: PropTypes.arrayOf(
    PropTypes.shape({
      displayText: PropTypes.string.isRequired,
      icon: PropTypes.string,
      action: PropTypes.func.isRequired,
    }),
  ),
};
