import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import TrueLinkTooltip from 'react/shared/components/true_link/main/TrueLinkTooltip';

const useStyles = makeStyles(() => ({
  content: {
    fontSize: '12px',
    lineHeight: '18px',
  },
}));
function DetailLink(props) {
  const classes = useStyles();

  if (!props.detailText) return <noscript />;

  return (
    <TrueLinkTooltip
      id="details-tooltip"
      placement="right"
      tooltipContent={<span className={classes.content}>{props.detailText}</span>}
    >
      <a className="detail_link">Detail</a>
    </TrueLinkTooltip>
  );
}

DetailLink.propTypes = {
  detailText: PropTypes.string,
};

export default DetailLink;
