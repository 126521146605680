import PropTypes from 'prop-types';
import React from 'react';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';

export default function ButtonCell({ value }) {
  return (
    <TrueLinkButton onClick={value.onClick} variant="primary">
      {value.title}
    </TrueLinkButton>
  );
}

ButtonCell.propTypes = {
  value: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};
