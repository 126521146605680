import PropTypes from 'prop-types';
import React from 'react';
import LoadingIndicator from 'react/shared/components/LoadingIndicator';
import { paginationShapeKeys } from 'react/shared/shapes/pagination_shape';
import bindAll from 'react/shared/utils/bind_all';
/*
 * In the short term, this component is intended for situations where DataTables cannot be used. It's intended
 * to mimic the look and feel of DataTables, while not being responsible for the XHR request.
 */

export default class Pagination extends React.Component {
  constructor(props) {
    super(props);

    bindAll(this);
  }

  summary() {
    const { page, pageSize, itemsTotal: total } = this.props;
    const start = (page - 1) * pageSize + 1;
    const end = Math.min(pageSize * page, total);

    if (total > 0) {
      return (
        <div className="span5">
          <div className="dataTables_info paginationSummary">
            {`Showing ${start} to ${end} of ${total} ${this.itemsLabel()}`}
          </div>
        </div>
      );
    }
  }

  changeHandler(e) {
    e.preventDefault();
    const { onPageChange, pageSize } = this.props;
    onPageChange(Number(e.target.dataset.page), pageSize);
  }

  itemsLabel() {
    const { itemsName } = this.props;
    return itemsName ? itemsName : 'items';
  }

  buildVisiblePageLinkArray(left, right) {
    const range = [];
    for (let i = left; i <= right; i++) {
      range.push(i);
    }
    return range;
  }

  pages() {
    const { itemsTotal: total, pageSize } = this.props;
    return Math.ceil((total || 1) / pageSize);
  }

  linkRange() {
    const { page } = this.props;
    const pages = this.pages();

    if (pages <= 5) {
      return this.buildVisiblePageLinkArray(1, pages);
    } else if (page < 3) {
      return [1, 2, 3, 4, 5];
    } else if (page + 2 > pages) {
      return this.buildVisiblePageLinkArray(pages - 5, pages);
    }
    return this.buildVisiblePageLinkArray(page - 2, page + 2);
  }

  generateLinks() {
    const { page, loading } = this.props;

    return this.linkRange().map((i) => {
      const linkActive = page === i;
      return (
        <li className={linkActive ? 'active' : undefined} key={i}>
          <span style={{ paddingLeft: 0, paddingRight: 0 }}>
            <button
              className="btn btn-link"
              data-page={i}
              disabled={linkActive || loading}
              onClick={this.changeHandler}
              type="button"
            >
              {i}
            </button>
          </span>
        </li>
      );
    });
  }

  renderButton(pageNumber, text, disabled) {
    const { loading } = this.props;
    return (
      <li className={disabled ? 'disabled' : undefined} key={`${pageNumber}_${text}`}>
        <span style={{ paddingLeft: 0, paddingRight: 0 }}>
          <button
            className="btn btn-link"
            data-page={pageNumber}
            disabled={disabled || loading}
            onClick={this.changeHandler}
            type="button"
          >
            {text}
          </button>
        </span>
      </li>
    );
  }

  render() {
    const { page, loading } = this.props;
    const pages = this.pages();

    const previous = page - 1 > 0 ? page - 1 : 1;
    const next = page + 1 <= pages ? page + 1 : page;
    const firstPreviousDisabled = page === 1;
    const nextLastDisabled = page === pages;

    return (
      <div className="row-fluid flex-section">
        {this.summary()}
        <div className="span7">
          <div className="dataTables_paginate paging_bootstrap pagination">
            {loading && <LoadingIndicator containerStyle={{ float: 'left', width: 'auto' }} />}
            <ul>
              {this.renderButton('1', 'First', firstPreviousDisabled)}
              {this.renderButton(previous, 'Previous', firstPreviousDisabled)}
              {this.generateLinks()}
              {this.renderButton(next, 'Next', nextLastDisabled)}
              {this.renderButton(pages, 'Last', nextLastDisabled)}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

Pagination.propTypes = {
  ...paginationShapeKeys,
  pageSize: PropTypes.number.isRequired,
};
