import PropTypes from 'prop-types';
import React from 'react';
import {
  checkBlank,
  clearBlankError,
  checkErrored,
  getPayeeErrorInitialState,
  listErrors,
  updateParentErrorState,
} from 'react/jaffe/components/forms/PayeeErrors';
import bindAll from 'react/shared/utils/bind_all';

export default class PayeeTextInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = getPayeeErrorInitialState.bind(this)();

    bindAll(this);
  }

  componentDidUpdate(prevProps, prevState) {
    checkErrored.bind(this)();
    if (prevState.errored !== this.state.errored || !this.state.canUpdateParentErrorState) {
      return updateParentErrorState.bind(this)();
    }
  }

  error() {
    return <ul className="new-form__error">{listErrors.bind(this)()}</ul>;
  }

  helperText() {
    return <span style={{ marginLeft: '10px' }}>{this.props.helperText}</span>;
  }

  render() {
    let inputClassName = 'new-form__data ';
    if (this.state.errored) {
      inputClassName += 'validate-error';
    }
    const helperText = this.props.helperText ? this.helperText() : undefined;

    return (
      <div>
        <div className="new-form__label">
          <label htmlFor={this.props.type}>{this.props.label}</label>
        </div>
        <div className={inputClassName}>
          <input
            defaultValue={this.props.defaultValue}
            id={this.props.type}
            maxLength={this.props.maxLength}
            name={this.props.name}
            onBlur={checkBlank.bind(this)}
            onChange={clearBlankError.bind(this)}
            type="text"
            value={this.props.value}
          />
          {helperText}
          {this.error()}
        </div>
      </div>
    );
  }
}

PayeeTextInput.propTypes = {
  defaultValue: PropTypes.string,
  helperText: PropTypes.string,
  label: PropTypes.string,
  maxLength: PropTypes.number,
  name: PropTypes.string,
  type: PropTypes.string.isRequired,
  value: PropTypes.string,
  // These are a hack we needed for removing a mix-in. These props are inspected by a Store that in bound to this component.
  // eslint-disable-next-line react/no-unused-prop-types
  addError: PropTypes.func,
  // eslint-disable-next-line react/no-unused-prop-types
  parentError: PropTypes.object,
  // eslint-disable-next-line react/no-unused-prop-types
  removeError: PropTypes.func,
};

PayeeTextInput.defaultProps = {
  errorText: {
    cannotBeBlank: ' cannot be blank. ',
  },
  maxLength: 40,
  required: true,
};
