import { ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import _map from 'underscore/modules/map';
import SelectField from './fields/select_field';
import CardProgramsTooltip from './tooltips/CardProgramsTooltip';
import FieldLabel from 'react/member/components/signups/signup_form/form_partials/labels/field_label';

function CardProgramSelect({ allowedCardPrograms, onChangeCallback }) {
  const options = _map(allowedCardPrograms, (displayName, programNumber) => ({
    id: programNumber,
    name: displayName,
  }));
  const rowClass = 'card--form__row';
  const colClass = 'card--form__col';

  return (
    <div>
      <div className={rowClass}>
        <div className={colClass}>
          <FieldLabel
            afterLabel={<CardProgramsTooltip allowedCardPrograms={allowedCardPrograms} />}
            htmlFor="card_program_select"
            labelContent={
              <span>
                Which type of card is best for this cardholder’s needs?
                <div style={{ marginTop: '0.5em' }}>A card…</div>
              </span>
            }
          />
          <SelectField
            className="width-100"
            id="card_program_select"
            includeBlankOption
            name="account.tl_card_program"
            onChangeCallback={onChangeCallback}
            options={options}
          />
          <ErrorMessage className="error" component="label" name="account.tl_card_program" />
        </div>
      </div>
      <div className={rowClass}>
        <div className={colClass} style={{ width: 'fit-content' }}>
          <i className="info-circle" />
        </div>
        <div className={colClass} style={{ width: 'fit-content' }}>
          <i>You can pick different types of cards for other cardholders later on.</i>
        </div>
      </div>
    </div>
  );
}

CardProgramSelect.propTypes = {
  allowedCardPrograms: PropTypes.objectOf(PropTypes.string).isRequired,
  onChangeCallback: PropTypes.func.isRequired,
};

export default CardProgramSelect;
