import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import TrueLinkIcon from 'react/shared/components/true_link/main/TrueLinkIcon';
import { grey2, grey3, grey4 } from 'react/shared/theme/palette';

const useStyles = makeStyles({
  root: {
    color: grey2,
    borderBottom: `1px solid ${grey4}`,
    borderTop: `1px solid ${grey4}`,
  },
  headerContent: {
    fontWeight: 400,
    fontSize: '18px',
    padding: '10px 15px',
    display: 'table-cell',
  },
  sortIconWrapper: {
    marginLeft: '10px',
    color: grey3,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  sortIcon: {
    verticalAlign: 'text-top',
  },
  selectColumn: {
    width: '1px',
    whiteSpace: 'nowrap',
  },
});

export default function TableHeader({ headerGroup, selectable, sortable }) {
  const classes = useStyles();

  // TODO: Refactor CARD-2909
  const withSubheaders = headerGroup.headers.reduce(
    (accumulator, column) => accumulator || !!column.Subheader,
    false,
  );
  let subheaderGroupProps;
  if (withSubheaders) {
    const originalProps = headerGroup.getHeaderGroupProps();
    subheaderGroupProps = {
      ...originalProps,
      key: `${originalProps.key}-subheader`,
    };
  }

  const getSortIcon = (column) => {
    if (!column.isSorted) {
      return 'sort';
    }
    if (column.isSortedDesc) {
      return 'sort-down';
    }
    return 'sort-up';
  };
  return (
    <>
      <tr {...headerGroup.getHeaderGroupProps()} className={classes.root}>
        {headerGroup.headers.map((column, colIdx) => {
          const isSelectColumn = selectable && colIdx === 0;

          return (
            <th
              key={`col-${colIdx}`}
              {...column.getHeaderProps(column.getSortByToggleProps())}
              className={classNames(classes.headerContent, {
                [classes.selectColumn]: isSelectColumn,
              })}
              {...column.getHeaderProps({
                style: { minWidth: column.minWidth, width: column.width },
              })}
            >
              <span style={{ display: 'flex' }}>
                {(!sortable || column.disableSort) && column.render('Header')}
                {sortable && !column.disableSort && (
                  <>
                    {column.render('Header')}
                    {!isSelectColumn && (
                      <span className={classes.sortIconWrapper} key={getSortIcon(column)}>
                        <TrueLinkIcon className={classes.sortIcon} icon={getSortIcon(column)} />
                      </span>
                    )}
                  </>
                )}
              </span>
            </th>
          );
        })}
      </tr>
      {withSubheaders && (
        <tr {...subheaderGroupProps}>
          {headerGroup.headers.map((column, colIdx) => (
            <td className={classes.headerContent} key={`col-${colIdx}-subheader`}>
              <div>{column.Subheader && column.render('Subheader')}</div>
            </td>
          ))}
        </tr>
      )}
    </>
  );
}

TableHeader.propTypes = {
  selectable: PropTypes.bool.isRequired,
  sortable: PropTypes.bool.isRequired,
  headerGroup: PropTypes.shape({
    getHeaderGroupProps: PropTypes.func.isRequired,
    headers: PropTypes.arrayOf(
      PropTypes.shape({
        Header: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.node]),
        Subheader: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
        id: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
};
