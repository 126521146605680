import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import TrueLinkIconAndTitle from 'react/shared/components/svg/TrueLinkIconAndTitle';
import TrueLinkLink from 'react/shared/components/true_link/main/TrueLinkLink';
import theme from 'react/shared/theme/Theme';

const useStyles = makeStyles(() => ({
  link: {
    cursor: 'pointer',
  },
  logo: {
    height: '32px',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '40%',
    [theme.breakpoints.up('md')]: {
      width: 'auto',
    },
  },
}));

export default function Logo({ onClick }) {
  const classes = useStyles();
  const handleClick = () => {
    window.location.href = 'https://www.truelinkfinancial.com';
  };

  return (
    <TrueLinkLink
      ariaLabel="link surrounding logo"
      className={classes.link}
      onClick={onClick || handleClick}
    >
      <TrueLinkIconAndTitle aria-label="logo" className={classes.logo} />
    </TrueLinkLink>
  );
}

Logo.propTypes = {
  onClick: PropTypes.func,
};
