import PropTypes from 'prop-types';
import React from 'react';

function GenericTextInput(props) {
  return <input {...props} type="text" />;
}

GenericTextInput.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  id: PropTypes.string,
  maxLength: PropTypes.string,
  min: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  style: PropTypes.object,
  value: PropTypes.string,
};

export default GenericTextInput;
