import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ConnectBankAccountModal from './ConnectBankAccountModal';
import { dashboardAccountShape } from 'react/member/card/shapes/AccountShape';
import ConnectPlaidBankAccountModal from 'react/member/components/plaid/ConnectPlaidBankAccountModal';
import DashboardBankAccountStore from 'react/member/stores/DashboardBankAccountStore';
import PopUp from 'react/shared/components/popups/PopUp';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import theme from 'react/shared/theme/Theme';

const useStyles = makeStyles(() => ({
  linkBankAccountModal: {
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      '& .btn': {
        width: '100%',
      },
    },
  },
}));

export default function LinkBankAccountModal({ account, mockPublicToken, modalOpen, onClose }) {
  const classes = useStyles();
  const [initialModalVisible, setInitialModalVisible] = useState(false);
  const [connectPlaidBankAccountModalOpen, setConnectPlaidBankAccountModalOpen] = useState(false);
  const [connectBankAccountModalOpen, setConnectBankAccountModalOpen] = useState(false);

  const closeAllModals = () => {
    onClose();
    setInitialModalVisible(false);
    setConnectPlaidBankAccountModalOpen(false);
    setConnectBankAccountModalOpen(false);
  };

  useEffect(() => {
    DashboardBankAccountStore.on('bankAccount.create', closeAllModals);
    return () => {
      DashboardBankAccountStore.off('bankAccount.create', closeAllModals);
    };
  });

  useEffect(() => {
    setInitialModalVisible(modalOpen);
  }, [modalOpen]);

  const openPlaidBankAccountModal = (ev) => {
    ev.preventDefault();
    setConnectPlaidBankAccountModalOpen(true);
    setInitialModalVisible(false);
  };

  const openConnectBankAccountModal = (ev) => {
    ev.preventDefault();
    setConnectBankAccountModalOpen(true);
    setInitialModalVisible(false);
  };

  const onChildCanceled = () => {
    setConnectPlaidBankAccountModalOpen(false);
    setConnectBankAccountModalOpen(false);
    setInitialModalVisible(true);
  };

  const header = () => <div style={{ textAlign: 'left' }}>Link a new bank account</div>;

  const body = () => (
    <div className={classes.linkBankAccountModal}>
      <h5>Choose how you would like to link your bank account</h5>
      <div className="form-wrapper">
        <div className="form-column form-group-left">
          <p>Log in to your bank's online system</p>
          <p className="italic">Instant</p>
          <TrueLinkButton
            className="btn normal"
            id="open-plaid-bank-account-cta"
            onClick={openPlaidBankAccountModal}
            variant="none"
          >
            Log in to my bank
          </TrueLinkButton>
        </div>

        <div className="form-column form-group-right">
          <p>Small deposits sent to your account</p>
          <p className="italic">2-4 business days</p>
          <TrueLinkButton
            className="btn normal"
            id="open-connect-bank-account-cta"
            onClick={openConnectBankAccountModal}
            variant="none"
          >
            Enter my account info
          </TrueLinkButton>
        </div>
      </div>
    </div>
  );

  const renderActiveComponent = () => {
    if (initialModalVisible) {
      return (
        <PopUp
          header={header()}
          hideFooter
          modalOpen={initialModalVisible}
          onClose={closeAllModals}
        >
          {body()}
        </PopUp>
      );
    } else if (connectBankAccountModalOpen) {
      return <ConnectBankAccountModal account={account} onCancel={onChildCanceled} />;
    } else if (connectPlaidBankAccountModalOpen) {
      return (
        <ConnectPlaidBankAccountModal
          mockPublicToken={mockPublicToken}
          onCancel={onChildCanceled}
        />
      );
    }
    return <div />;
  };

  return <div>{renderActiveComponent()}</div>;
}

LinkBankAccountModal.propTypes = {
  account: dashboardAccountShape.isRequired,
  mockPublicToken: PropTypes.string,
  modalOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};
