import React from 'react';
import TableLoadingRow from './TableLoadingRow';

export default function TableLoading() {
  return (
    <>
      <TableLoadingRow />
      <TableLoadingRow />
      <TableLoadingRow />
    </>
  );
}
