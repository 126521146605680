import PropTypes from 'prop-types';
import React, { useState } from 'react';
import _map from 'underscore/modules/map';
import _omit from 'underscore/modules/omit';
import FileRow from './FileRow';
import FolderRow from './FolderRow';
import {
  browseableFilesAndFoldersShape,
  fileTypeShape,
} from 'react/member/shapes/attachments_shapes';
import LoadingIndicator from 'react/shared/components/LoadingIndicator';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';

export default function BrowseableFiles({
  buildDownloadUrl,
  canEdit,
  fileType,
  filesByFolder,
  loading,
  onDeleteFile,
  onDeleteFolder,
  onRenameFolder,
  onUpdateFile,
}) {
  const [expandedFolderIds, setExpandedFolderIds] = useState({});

  const handleExpandFolder = (folderId) => {
    setExpandedFolderIds({ ...expandedFolderIds, [folderId]: true });
  };

  const handleCollapseFolder = (folderId) => {
    setExpandedFolderIds(_omit(expandedFolderIds, folderId));
  };

  const renderFileRows = (files, { inFolder }) =>
    _map(files, (file) => {
      const downloadUrl = buildDownloadUrl(file);

      const classes = `browseable-files__row--file-container ${inFolder ? ' in-folder' : ''}`;

      return (
        <div className={classes} key={file.id}>
          <FileRow
            canEdit={canEdit}
            downloadUrl={downloadUrl}
            file={file}
            fileType={fileType}
            onDeleteFile={onDeleteFile}
            onUpdateFile={onUpdateFile}
          />
        </div>
      );
    });

  const renderFolderRow = (folder, files) => {
    const expanded = expandedFolderIds[folder.id] || false;

    return (
      <div className="browseable-files__row--folder-container" key={folder.id}>
        <FolderRow
          canEdit={canEdit}
          expanded={expanded}
          fileType={fileType}
          files={files}
          folder={folder}
          onCollapse={handleCollapseFolder}
          onDeleteFolder={onDeleteFolder}
          onExpand={handleExpandFolder}
          onRenameFolder={onRenameFolder}
        />
        {expanded ? renderFileRows(files, { inFolder: true }) : null}
      </div>
    );
  };

  if (loading) {
    return <LoadingIndicator containerStyle={{ marginTop: 10 }} />;
  }

  if (!filesByFolder.length) {
    return (
      <div className="browseable-files__zero-state">
        <TrueLinkButton className="btn no-files" disabled variant="none">
          {`No ${fileType}s`}
        </TrueLinkButton>
      </div>
    );
  }

  return (
    <div className="browseable-files__container">
      {filesByFolder.map(({ folder, files }) =>
        folder ? renderFolderRow(folder, files) : renderFileRows(files, { inFolder: false }),
      )}
    </div>
  );
}

BrowseableFiles.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  filesByFolder: browseableFilesAndFoldersShape.isRequired,
  fileType: fileTypeShape.isRequired,
  buildDownloadUrl: PropTypes.func.isRequired,
  onRenameFolder: PropTypes.func.isRequired,
  onDeleteFolder: PropTypes.func.isRequired,
  onUpdateFile: PropTypes.func.isRequired,
  onDeleteFile: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};
