export const oneLineAddress = (addressObj) => {
  if (!addressObj) return '';

  const line1 = addressObj.street1;
  const line2 = addressObj.street2;

  if (!line1) return '';

  const { city, state, zip } = addressObj;
  return `${line1}${line2 ? ` ${line2}` : ''}, ${city} ${state} ${zip}`;
};

export const matchingAddresses = (addressA, addressB) => {
  if (!addressA || !addressB) return false;
  if (!addressA.street1 || !addressB.street1) return false;
  if (addressA.street1 === '' || addressB.street1 === '') return false;

  return oneLineAddress(addressA) === oneLineAddress(addressB);
};
