import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { transferShape } from 'react/member/card/shapes/TransferShape';
import { isEmergencyCreditable, isReady } from 'react/member/utils/Transfer';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';

const useStyles = makeStyles({
  statusLabel: {
    backgroundColor: '#D4EFDB',
    borderRadius: '4px',
    color: '#27AE60',
    flex: '0 0 auto',
    fontSize: '12px',
    fontWeight: 700,
    padding: '0 15px',
  },
});

export default function InProgressLabel({ onEmergencyFund, transfer }) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.statusLabel}>IN PROGRESS</div>
      {isEmergencyCreditable(transfer) && isReady(transfer) && onEmergencyFund && (
        <TrueLinkButton className="btn btn-link" onClick={onEmergencyFund} variant="none">
          Emergency fund
        </TrueLinkButton>
      )}
    </>
  );
}

InProgressLabel.propTypes = {
  onEmergencyFund: PropTypes.func,
  transfer: transferShape.isRequired,
};

InProgressLabel.defaultProps = {
  onEmergencyFund: null,
};
