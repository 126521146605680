import Processing from 'images/processing.gif';

(function () {
  'use strict';

  // Uses the errors returned from rails validations and traverses form adding them in
  function showServerErrors(serverErrors) {
    function traverseHelper(path, errors) {
      for (var name in errors) {
        var value = errors[name];
        var newPath = path + '[' + name + ']';
        if (Array.isArray(value)) showSingleError(newPath, value);
        else if ($.isPlainObject(value)) traverseHelper(newPath, value);
        else showSingleError(newPath, [value]);
      }
    }

    function showSingleError(path, value) {
      var $f = $("[name='" + path + "']");
      var errStr;

      if (value.length === 0) return;
      else if (value.length === 1) errStr = value;
      else errStr = value.join(';');
      var $error = $('<label for="' + $f.prop('id') + '" class="error">').text(errStr);
      $('label[for=' + $f.prop('id') + '].error').remove(); // remove duplicate error messages
      Truelink.formErrorPlacement($error, $f);
    }
    Truelink.clearFormErrors();
    for (var name in serverErrors) {
      var value = serverErrors[name];
      if (Array.isArray(value)) showSingleError(name, value);
      else if ($.isPlainObject(value)) traverseHelper(name, value);
      else showSingleError(name, [value]);
    }
  }

  function initExpediteShippingModal() {
    $(document).on('click', '#expedite_manual_charge_shipping_yes', function () {
      var title = 'You have selected expedited shipping';
      var message =
        'There is a $30 fee for expedited shipping. \
    If there’s no bank account associated with this card, the card will be charged directly. \
    If you have linked a bank account to this card, the bank account will be charged. \
    Please contact us at support@truelinkfinancial.com if you’d like a different account to be charged.';
      window.displayModal(title, message, null, 'Okay', '');
    });
  }

  function initAddressRadios() {
    $('input[name=shipping_destination]').change(function () {
      var $this = $(this);
      switch ($this.val()) {
        case 'cardholder_home':
          if ($('#office_address_form').is(':visible') || $('#other_address_form').is(':visible')) {
            $('#office_address_form').slideUp();
            $('#other_address_form').slideUp();
            $('#home_address_message').slideUp();
          }
          break;
        case 'office':
          if ($('#other_address_form').is(':visible')) {
            $('#office_address_form').show();
            $('#other_address_form').hide();
          } else {
            $('#office_address_form, #home_address_message').slideDown();
            $('#other_address_form').hide();
          }
          break;
        case 'other':
          if ($('#office_address_form').is(':visible')) {
            $('#office_address_form').hide();
            $('#other_address_form').show();
          } else {
            $('#office_address_form').hide();
            $('#other_address_form, #home_address_message').slideDown();
          }
          break;
      }
    });
  }

  // Handles submit button clicks
  function initSubmitButton() {
    var url, params, user_slug, account_slug, stripped, message;

    function submitCardForm(jaffe_ish_server) {
      $(this).attr('disabled', 'disabled');
      $('#additional-card-submit-btn').prop('disabled', true)
      var src = 'src="' + Processing + '"';
      // so only one processing gif appended if user needs to click submit more than once
      if ($('img[' + src + ']').length < 1) {
        $('#additional-card-form').append(
          '<img ' + src + ' id="processing-gif" width="33px" height="33px" />',
        );
        $('#processing-gif').css('margin-left', '10px');
      }

      url = $('#additional-card-form').attr('action');
      params = $('#additional-card-form').serializeArray();

      // This is needed because the serializeArray() takes the value of the checkbox,
      // and not the value that we assign to the value attribute (value={!values.expedite_charged}).
      // Ideally, we should have a param that matches the value (eg fee_waived)
      // This is not an issue in other form where we have a submit method in the form.
      // TODO: Move the form to a react component or rename the field
      const checkboxValue = $('input[name="expedite_charged"]').val() == 'true';
      if (jaffe_ish_server && $('#expedite_shipping_yes input[type="radio"]').prop('checked')) {
        const expediteChargedIndex = params.findIndex((item) => item.name === 'expedite_charged');
        if (expediteChargedIndex !== -1) {
          params[expediteChargedIndex].value = !checkboxValue;
        } else {
          params.push({ name: 'expedite_charged', value: !checkboxValue });
        }
      }

      const contryOfCitizenshipIndex = params.findIndex(
        (item) => item.name === 'countryOfCitizenship',
      );
      if (contryOfCitizenshipIndex !== -1) {
        params.push({
          name: 'cardholder[country_of_citizenship]',
          value: params[contryOfCitizenshipIndex].value,
        });
        params.splice(contryOfCitizenshipIndex, 1);
      }

      const nonResidentAlienIndex = params.findIndex((item) => item.name === 'nonResidentAlien');
      if (nonResidentAlienIndex !== -1) {
        params.push({
          name: 'cardholder[non_resident_alien]',
          value:
            params[nonResidentAlienIndex].value === 'yes'
              ? true
              : params[nonResidentAlienIndex].value === 'no' ? false : undefined,
        });
        params.splice(nonResidentAlienIndex, 1);
      }

      $.ajax({
        url: url,
        type: 'POST',
        data: params,
      })
        .fail(function (jqXHR, textStatus, errorThrown) {
          $('#additional-card-submit-btn').prop('disabled', false)
          switch (jqXHR.status) {
            case 406:
              showServerErrors(jqXHR.responseJSON.errors);
              break;
            default:
              Truelink.flash('error', 'Unexpected network error');
              break;
          }
        })
        .done(function (data, textStatus, jqXHR) {
          document.location.href = jqXHR.responseJSON.redirect;
        })
        .always(function () {
          $('#processing-gif').remove();
        });
    }

    window.submitCardForm = submitCardForm;

    $.validator.addMethod(
      'regex',
      function (value, element, regexp) {
        return this.optional(element) || regexp.test(value);
      },
      'Phone number must have 10 digits.',
    );

    var custom_validation = {
      rules: {
        'cardholder[first_name]': { charsOnly: true, maxlength: 24 },
        'cardholder[last_name]': { charsOnly: true, maxlength: 24 },
        'cardholder[card_name]': { charsOnly: true, maxlength: 23 },
        'cardholder[address][street1]': { requireNonPOBox: true },
        'cardholder[address][street2]': { requireNonPOBox: true },
        'cardholder[dob]': { validDate: true },
        // FBO
        'for_benefit_of[first_name]': { charsOnly: true, maxlength: 24 },
        'for_benefit_of[last_name]': { charsOnly: true, maxlength: 24 },
        'for_benefit_of[dob]': { validDate: true },
        'for_benefit_of[card_name]': { charsOnly: true, maxlength: 19 },
        // Beneficiary
        'beneficiary[first_name]': { charsOnly: true, maxlength: 24 },
        'beneficiary[last_name]': { charsOnly: true, maxlength: 24 },
        'beneficiary[card_name]': { charsOnly: true, maxlength: 19 },
      },
    };

    function isOfficeAddressEmpty() {
      var empty = false;
      empty |= !$('input[name="office_address[street1]"]').val();
      empty |= !$('input[name="office_address[city]"]').val();
      empty |= !$('select[name="office_address[state]"]').val();
      empty |= !$('input[name="office_address[zip]"]').val();
      return empty;
    }

    function stripAddress(address) {
      if (address === null || address === undefined) {
        return '';
      } else {
        return address
          .toLowerCase()
          .replace(/\W/g, '')
          .replace('suite', '')
          .replace('floor', '')
          .replace('room', '')
          .replace('unit', '')
          .replace('bldg', '')
          .replace('dept', '')
          .replace('apt', '')
          .replace('ste', '')
          .replace('fl', '')
          .replace('rm', '');
      }
    }

    // Handle submit button
    $(document).on('click', '#additional-card-submit-btn', function (event) {
      Truelink.flashClear();
      var jaffe_ish_server = $(event.target).data('is-admin');
      if (
        $('input[name="shipping_destination"]:checked').val() == 'office' &&
        isOfficeAddressEmpty()
      ) {
        var message =
          'No address is currently saved for your office. ' +
          'Please select "Other" and then enter your desired shipping address. ' +
          'If you would like us to save this address moving forward, ' +
          'please send us an email at <em>support@truelinkfinancial.com</em>.';
        window.displayModal('Warning', message, null, 'Okay', '');
      } else {
        var $form = $(this).closest('form');
        $form.validate(custom_validation);
        if (!$form.valid()) {
          Truelink.flash(
            'error',
            "You haven't provided all the information we need for this card order just yet. Please provide the missing information.",
          );
          return; // Disables continue button if validations fail
        }
        var countryOfCitizenshipValue = $('input[name="countryOfCitizenship"]').val();
        var nonResidentAlienValue = $('input[name="nonResidentAlien"]:checked').val();
        if (
          countryOfCitizenshipValue &&
          countryOfCitizenshipValue != 'US' &&
          !nonResidentAlienValue
        ) {
          Truelink.flash('error', "You haven't provided non-resident alien status.");
          return; // Disables continue button if validations fail
        }

        // Check if cardholder home address matches the office address, present modal if so
        var home_address = stripAddress($('#cardholder_address_street1').val());
        var office_address = stripAddress($('#office_address_street1').val());

        if (home_address === office_address) {
          message =
            'You have entered your office address as the Cardholder’s home address.' +
            ' We need the cardholder’s home address for Identity Verification purposes.' +
            ' Click Continue to order the card anyway, otherwise click Cancel to go back.';
          window.displayModal('Warning', message, submitCardForm);
        } else {
          submitCardForm(jaffe_ish_server);
        }
      }
    });
  }

  function initFeeWarningModal() {
    $('#fee_type').change(function () {
      if ($(this).is(':checked'))
        alert("Attention! This will set a fee type - don't forget to change the program in MCP!");
    });
  }

  function handleCardholderName() {
    $('#cardholder_first_name, #cardholder_last_name').change(function () {
      var first_name = $('#cardholder_first_name').val();
      var last_name = $('#cardholder_last_name').val();
      $('#cardholder_name').val(first_name + ' ' + last_name);
    });
  }

  // Main
  function init() {
    if ($('#card-section').length === 0) return; // Return if not on add additional card page
    handleCardholderName();
    initFeeWarningModal();
    initSubmitButton();
    initAddressRadios();
    initExpediteShippingModal();
  }

  $(document).ready(init);
})();
