import PropTypes from 'prop-types';
import React from 'react';
import CardDetails from 'react/member/card/components/account_tab/card_status/CardDetails';
import { CARD_STATUSES } from 'react/member/utils/CardStatus';

export default function PreRelease({ contactEmail, status }) {
  const note = `We will contact you at ${contactEmail} if we have any questions about your application which might impact the estimated arrival date of your first card.`;

  return <CardDetails cardLast4={null} expiryDate={null} note={note} status={status} />;
}

PreRelease.propTypes = {
  contactEmail: PropTypes.string.isRequired,
  status: PropTypes.oneOf([CARD_STATUSES.PRE_RELEASE_FIRST_CARD_ORDER_STATUS]).isRequired,
};
