import PropTypes from 'prop-types';
import React from 'react';
import InfoTooltip from 'react/shared/components/tooltips/InfoTooltip';

export default class PostageType extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedPostage: this.props.selectedPostage || '1',
      levels: {
        first_class: '1',
        priority: '3',
        overnight: '8',
      },
      inputName: this.props.inputName || 'postage_code',
    };

    this.handleOptionChange = this.handleOptionChange.bind(this);
  }

  handleOptionChange(e) {
    this.setState({
      selectedPostage: e.target.value,
    });

    if (this.props.codeCallback) {
      this.props.codeCallback(e);
    }
  }

  render() {
    const { selectedPostage, levels, inputName } = this.state;

    return (
      <div>
        <div className="new-form__label">
          <label htmlFor="postage_code">Shipping</label>
        </div>
        <div className="new-form__data">
          <div className="radio-group inline-block">
            <label htmlFor="first_class_delivery">
              <input
                checked={selectedPostage === levels.first_class}
                id="first_class_delivery"
                name={inputName}
                onChange={this.handleOptionChange}
                type="radio"
                value="1"
              />
              Free shipping via USPS First Class - Free
              <InfoTooltip
                placement="top"
                tooltipText="Please allow 1 business day for processing and 5-7 business days for shipping."
              />
            </label>
            <label htmlFor="priority_with_delivery_confirmation">
              <input
                checked={selectedPostage === levels.priority}
                id="priority_with_delivery_confirmation"
                name={inputName}
                onChange={this.handleOptionChange}
                type="radio"
                value="3"
              />
              USPS Priority + Delivery Confirmation - $12
              <InfoTooltip
                placement="top"
                tooltipText="Please allow 1 business day for processing and 2-3 business days for shipping."
              />
            </label>
            <label htmlFor="overnight_delivery">
              <input
                checked={selectedPostage === levels.overnight}
                id="overnight_delivery"
                name={inputName}
                onChange={this.handleOptionChange}
                type="radio"
                value="8"
              />
              USPS Priority Mail Express - $35
              <InfoTooltip
                placement="top"
                tooltipText="Please allow 1 business day for processing and 1 business day for shipping."
              />
            </label>
          </div>
        </div>
      </div>
    );
  }
}

PostageType.propTypes = {
  codeCallback: PropTypes.func,
  inputName: PropTypes.string,
  selectedPostage: PropTypes.string,
};
