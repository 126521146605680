import PropTypes from 'prop-types';
import React from 'react';
import DashboardIndividualClient from './DashboardIndividualClient';
import DashboardPooledClient from './DashboardPooledClient';

export default function DashboardClient(props) {
  return (
    <>
      {props.clientType === 'pooled' && <DashboardPooledClient {...props} />}
      {props.clientType === 'individual' && <DashboardIndividualClient {...props} />}
    </>
  );
}

DashboardClient.propTypes = {
  clientType: PropTypes.string,
};
