import PropTypes from 'prop-types';
import React from 'react';
import _keys from 'underscore/modules/keys';
import _map from 'underscore/modules/map';
import _omit from 'underscore/modules/omit';
import _values from 'underscore/modules/values';

export default class StateDropdown extends React.Component {
  formatState(state) {
    return (
      <option id={state} key={state} value={state}>
        {state}
      </option>
    );
  }

  getStateOptions() {
    const { states } = $tlf.domains;
    const options = this.props.abbreviated ? _keys(states) : _values(states);

    return _map(options, (state) => this.formatState(state));
  }

  initialSelection() {
    const emptyOption = <option value=""> N/A </option>;
    const emptyOptionDisabled = (
      <option disabled value="">
        Select One
      </option>
    );

    return this.props.allowNone ? emptyOption : emptyOptionDisabled;
  }

  render() {
    const propsToRender = _omit(this.props, 'allowNone', 'abbreviated', 'dataAnalyticsKey');
    return (
      <select
        data-analytics-key={this.props.dataAnalyticsKey}
        defaultValue={this.props.defaultValue}
        id={this.props.id || 'state_dropdown'}
        name="address_attributes.state"
        style={{ width: this.props.width || '114px' }}
        {...propsToRender}
      >
        {this.initialSelection()}
        {this.getStateOptions()}
      </select>
    );
  }
}

StateDropdown.propTypes = {
  id: PropTypes.string,
  defaultValue: PropTypes.string,
  abbreviated: PropTypes.bool,
  allowNone: PropTypes.bool,
  required: PropTypes.bool,
  className: PropTypes.string,
  dataAnalyticsKey: PropTypes.string,
  width: PropTypes.string,
};

StateDropdown.defaultProps = {
  abbreviated: true,
  allowNone: false,
  required: false,
  dataAnalyticsKey: undefined,
};
