import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import CheckMarkSvg from 'images/checkmark-black-and-green.svg';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import TrueLinkTextInput from 'react/shared/components/true_link/main/form/TrueLinkTextInput';
import theme from 'react/shared/theme/Theme';
import PALETTE from 'react/shared/theme/palette';

const useStyles = makeStyles(() => ({
  idvSuccessModal: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  infoSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    padding: '20px',
    backgroundColor: PALETTE.blueLight,
  },
  infoSectionText: {
    paddingBottom: '20px',
  },
  checkMarkSvg: {
    width: '72px',
  },
  submitBtn: {
    width: 'fit-content',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));

export default function CardActivationSuccess({ firstName, last4digits, nickname, onSubmit }) {
  const classes = useStyles();

  const initialValues = {
    nickname: '',
  };

  return (
    <form className={classes.idvSuccessModal} id="idv-form-success">
      <img alt="check mark" className={classes.checkMarkSvg} src={CheckMarkSvg} />

      <Typography variant="h4">
        Success! {firstName ? ` ${firstName}'s` : ' '} card ending in ...{last4digits} is activated
        and ready to use
      </Typography>
      <div className={classes.infoSection}>
        <Typography variant="h5">Optional: Instructions for setting a PIN</Typography>
        <Typography variant="body2">
          <span>
            Setting a PIN for a True Link card is optional. In most cases, the cardholder can
            request that the merchant processes their transaction as credit, which allows them to
            insert or swipe their card without needing a PIN. Regardless if a PIN is set, the card
            still follows the spending rules and only allows funds to be spent that are loaded on
            the card by you, the admin.
          </span>
          <br />
          <br />
          <span>
            If you would like to set a PIN, please call our administrator support line at
            877-264-8783 and press 2.
          </span>
          <br />
          <br />
          <span>
            If you would prefer that your cardholder sets their own PIN, they can always call our
            cardholder support team at 1-800-299-7646 and press 4.
          </span>
        </Typography>
      </div>

      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ handleChange, handleSubmit, setFieldTouched, values }) => (
          <>
            {!nickname && (
              <div className={classes.infoSection}>
                <Typography variant="h5">Optional: Add an account ID or nickname</Typography>
                <Typography variant="body2">
                  <span>
                    Some administrators use an account ID to differentiate cards when they manage
                    multiple of them.
                  </span>
                  <br />
                </Typography>
                <TrueLinkTextInput
                  name="nickname"
                  onChange={handleChange}
                  placeholder="XXXX"
                  required
                  setFieldTouched={setFieldTouched}
                  value={values.nickname}
                />
              </div>
            )}

            <TrueLinkButton
              className={classes.submitBtn}
              onClick={handleSubmit}
              type="submit"
              variant="primary"
            >
              Done
            </TrueLinkButton>
          </>
        )}
      </Formik>
    </form>
  );
}

CardActivationSuccess.propTypes = {
  firstName: PropTypes.string,
  last4digits: PropTypes.string,
  nickname: PropTypes.string,
  onSubmit: PropTypes.func,
};
