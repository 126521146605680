import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import _contains from 'underscore/modules/contains';
import _map from 'underscore/modules/map';
import TransactionAttachment from './TransactionAttachment';
import Processing from 'images/processing.gif';
import DashboardActions from 'react/member/actions/dashboard_actions';
import DashboardWealthAccountEventAttachmentStore from 'react/member/stores/DashboardWealthAccountEventAttachmentStore';
import bindAll from 'react/shared/utils/bind_all';

export default class TransactionAttachments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      attachments: [],
      showSpinner: false,
    };

    bindAll(this);
  }

  componentDidMount() {
    DashboardWealthAccountEventAttachmentStore.on('attachments.fetch', this.onChangeAttachments);
    DashboardWealthAccountEventAttachmentStore.on('attachment.upload', this.onUpload);

    DashboardActions.fetchWealthAccountEventAttachments(this.props.wealthAccountEventId);
  }

  componentWillUnmount() {
    DashboardWealthAccountEventAttachmentStore.off('attachments.fetch', this.onChangeAttachments);
    DashboardWealthAccountEventAttachmentStore.off('attachment.upload', this.onUpload);
  }

  allowedExtensionsMessage() {
    return `Note: Please use these file types: ${$tlf.domains.allowedExtensions.join(', ')}`;
  }

  onChangeAttachments() {
    this.setState({ attachments: DashboardWealthAccountEventAttachmentStore.getAttachments() });
  }

  upload(evt) {
    Truelink.flashClear();
    const wealthAccountEventId = this.props.wealthAccountEventId;
    const fileInput = evt.target;
    if (fileInput.value != '') {
      const fileName = fileInput.files[0].name;
      const ext = fileName.slice(fileName.lastIndexOf('.') + 1);
      if (_contains($tlf.domains.allowedExtensions, ext)) {
        this.setState({ showSpinner: true });
        DashboardWealthAccountEventAttachmentStore.uploadAttachment(
          wealthAccountEventId,
          fileInput,
        );
      } else {
        Truelink.flash('error', this.allowedExtensionsMessage());
      }
    }
  }

  onUpload() {
    this.setState({ showSpinner: false });
  }

  renderInputAndSpinner() {
    const upload = this.upload;
    let spinnerStyle;
    if (this.state.showSpinner) {
      spinnerStyle = {};
    } else {
      spinnerStyle = { display: 'none' };
    }
    const onClick = (evt) => {
      evt.preventDefault();
      ReactDOM.findDOMNode(this.refs.fileInput).click();
    };

    return (
      <div style={{ marginBottom: 14 }}>
        <button
          className="btn btn-small normal transaction_detail"
          onClick={onClick}
          style={{ marginBottom: '12px' }}
          type="button"
        >
          Attach Documentation{' '}
        </button>
        &nbsp;
        <input
          name="attachment[file]"
          onChange={upload}
          ref="fileInput"
          style={{ display: 'none' }}
          type="file"
        />
        <img
          alt="Processing..."
          height={20}
          ref="spinner"
          src={Processing}
          style={spinnerStyle}
          width={20}
        />
      </div>
    );
  }

  renderAttachments() {
    const wealthAccountEventId = this.props.wealthAccountEventId;
    const attachments = this.state.attachments;
    let links;
    if (attachments.length == 0) {
      links = <i>No attachments</i>;
    } else {
      links = _map(attachments, (attachment) => (
        <TransactionAttachment
          attachment={attachment}
          key={attachment.id}
          wealthAccountEventId={wealthAccountEventId}
        />
      ));
    }

    return <div>{links}</div>;
  }

  render() {
    // we do this so that we always have an array of attachments
    const blankInput = (
      <input name="attachment_ids[]" readOnly style={{ display: 'none' }} value="" />
    );
    return (
      <div>
        {blankInput}
        {this.renderInputAndSpinner()}
        {this.renderAttachments()}
      </div>
    );
  }
}

TransactionAttachments.propTypes = {
  wealthAccountEventId: PropTypes.number.isRequired,
};
