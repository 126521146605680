import { ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import CardPaymentFormFields from './CardPaymentFormFields';
import theme from 'react/shared/theme/Theme';

export default function CardPaymentForm({
  adminMobile,
  allowAutomatedPayment,
  cardholderMobile,
  expeditedShipping,
  isParentOrg,
  paymentMethods,
  regularShipping,
  shippingFee,
  waiveFeeField,
}) {
  const [adminMobileValue, setAdminMobileValue] = useState(adminMobile);
  const [automatedPaymentValue, setAllowAutomatedPaymentValue] = useState(allowAutomatedPayment);
  const [expeditedShippingValue, setExpeditedShippingValue] = useState(expeditedShipping);
  const [regularShippingValue, setRegularShippingValue] = useState(regularShipping);
  const [shippingFeeValue, setShippingFeeValue] = useState(shippingFee);
  const [paymentMethodsValue, setPaymentMethodsValue] = useState(paymentMethods);
  const [subOrgId, setSubOrgId] = useState(null);

  const validationSchema = Yup.object().shape({
    expedite_shipping: Yup.string().oneOf(['yes', 'no']).required(),
    expedited_shipping_card_fee: Yup.object().shape({
      payment_method: Yup.string(),
      bank_account_id: Yup.string().when('payment_method', {
        is: 'ach',
        then: () => Yup.string().required('field required'),
        otherwise: () => Yup.string().nullable(),
      }),
    }),
    phone_type: Yup.string().when(['expedite_shipping'], {
      is: (expediteShipping) => expediteShipping === 'yes' || expediteShipping === true,
      then: () => Yup.string().required('field required'),
      otherwise: () => Yup.string(),
    }),
    card_recipient_phone: Yup.string().when(['expedite_shipping', 'phone_type'], {
      is: (expediteShipping, phoneType) =>
        (expediteShipping === 'yes' || expediteShipping === true) && phoneType === 'other',
      then: () => Yup.string().required('field required'),
      otherwise: () => Yup.string(),
    }),
  });

  const initialValues = {
    expedited_shipping_card_fee: {
      payment_method:
        paymentMethodsValue?.available_bank_accounts?.length > 0 &&
        paymentMethodsValue?.preferred_payment_method
          ? paymentMethodsValue?.preferred_payment_method
          : 'card',
      bank_account_id: paymentMethodsValue?.preferred_bank_account?.id || '',
      waived_reason: '',
      waived_description: '',
      amount: shippingFeeValue,
    },
    address: {},
    expedite_shipping: 'no',
    phone_type: '',
    card_recipient_phone: '',
    expedite_charged: true,
  };

  useEffect(() => {
    const handleSubOrgChange = (ev) => {
      const selectedOption = ev.target.options[ev.target.selectedIndex];
      const id = selectedOption.dataset.subOrgId;
      setSubOrgId(id);
    };
    if (isParentOrg) {
      const selectSubOrg = document.getElementById('select_sub_org');
      selectSubOrg.addEventListener('change', handleSubOrgChange);

      return () => {
        selectSubOrg.removeEventListener('change', handleSubOrgChange);
      };
    }
  }, [isParentOrg]);

  useEffect(() => {
    if (subOrgId) {
      const url = RailsRoutes.additional_card_shipping_settings_path({ format: 'json' });
      axios.get(url, { params: { organization_id: subOrgId } }).then(({ data }) => {
        const settings = data.orgs_shipping_settings;
        setAdminMobileValue(settings.admin_phone);
        setAllowAutomatedPaymentValue(settings.allow_automated_expedited);
        setExpeditedShippingValue(settings.expedited_shipping_time_days);
        setRegularShippingValue(settings.regular_shipping_time_days);
        setPaymentMethodsValue(settings.expedited_shipping_fee_payment_method_options);
        setShippingFeeValue(settings.expedited_shipping_fee_amount);
      });
    }
  }, [subOrgId]);

  return (
    <ThemeProvider theme={theme}>
      <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema}>
        {({ handleChange, setFieldTouched, setFieldValue, values }) => (
          <CardPaymentFormFields
            adminMobile={adminMobileValue}
            allowAutomatedPayment={automatedPaymentValue}
            cardholderMobile={cardholderMobile}
            expeditedShipping={expeditedShippingValue}
            handleChange={handleChange}
            paymentMethods={paymentMethodsValue}
            regularShipping={regularShippingValue}
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            shippingFee={shippingFeeValue}
            values={values}
            waiveFeeField={waiveFeeField}
          />
        )}
      </Formik>
    </ThemeProvider>
  );
}

CardPaymentForm.propTypes = {
  regularShipping: PropTypes.any,
  expeditedShipping: PropTypes.any,
  shippingFee: PropTypes.any,
  cardholderMobile: PropTypes.any,
  adminMobile: PropTypes.any,
  allowAutomatedPayment: PropTypes.bool,
  paymentMethods: PropTypes.object,
  waiveFeeField: PropTypes.bool,
  isParentOrg: PropTypes.bool,
};
