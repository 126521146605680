import axios from 'axios';
import { AXIOS_XHR_CONFIG } from 'react/shared/utils/Axios';

export default {
  createRaiseCashRequest(beneficiaryId, amount, notes) {
    if (!amount) {
      return Promise.resolve();
    }

    return axios.post(
      RailsRoutes.invest_api_v1_trust_beneficiary_cash_raise_requests_path(beneficiaryId, {
        format: 'json',
      }),
      {
        cash_raise_request: {
          amount,
          notes,
        },
      },
      AXIOS_XHR_CONFIG,
    );
  },
};
