import PropTypes from 'prop-types';
import React from 'react';
import AreYouSurePopUp from 'react/shared/components/popups/AreYouSurePopUp';

export default function ClientDisconnectModal(props) {
  const message =
    'This option should be used in the event a beneficiary is changing card accounts, or the card was inadvertently attached to this beneficiary.';

  return (
    <AreYouSurePopUp
      messageText={message}
      onClose={props.onClose}
      onSubmit={props.onSubmit}
      openModal
      submitText="Continue"
    />
  );
}

ClientDisconnectModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
