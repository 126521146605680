import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import DisbursementCategoryAmountAttachments from 'react/member/components/dashboard/disbursements/filter_table/disbursements_category_amounts/DisbursementCategoryAmountAttachments';
import { asMoney } from 'react/shared/utils/Money';

const useStyles = makeStyles(() => ({
  newFormField: {
    minHeight: '31px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
  },
  newFormLabel: {
    width: '220px',
    lineHeight: '1.2em',
    paddingTop: '9px',
    marginBottom: '10px',
    fontWeight: 'bold',
  },
  newFormData: {
    width: 'calc(100% - 220px)',
    fontSize: '18px',
    lineHeight: '1.2em',
  },
}));

export default function DisbursementCategoryAmounts({
  allowInsert,
  canAddAttachments,
  checkInsertAttachmentId,
  disbursementCategoryAmounts,
  disbursementId,
}) {
  const classes = useStyles();
  return (
    disbursementCategoryAmounts && (
      <>
        {disbursementCategoryAmounts.map((category) => (
          <tr key={category.id}>
            <td colSpan={8}>
              <div style={{ display: 'flex' }}>
                <div style={{ width: '100%' }}>
                  <Box className={classes.newFormField}>
                    <Box className={classes.newFormLabel}>Amount:</Box>{' '}
                    <Box className={classes.newFormData}>
                      {asMoney(category.attributes.amount.amount)}
                    </Box>
                  </Box>
                  <Box className={classes.newFormField}>
                    <Box className={classes.newFormLabel}>Category:</Box>{' '}
                    <Box className={classes.newFormData}>{category.attributes.category}</Box>
                  </Box>
                  <Box
                    borderBottom={'1px solid lightgrey'}
                    className={classes.newFormField}
                    mb={'16px'}
                    pb={'24px'}
                  >
                    <Box className={classes.newFormLabel}>Internal Notes:</Box>{' '}
                    <Box className={classes.newFormData}>
                      {category.attributes.notes ? (
                        category.attributes.notes
                      ) : (
                        <Box style={{ fontStyle: 'italic' }}>No note</Box>
                      )}
                    </Box>
                  </Box>
                  <DisbursementCategoryAmountAttachments
                    allowInsert={allowInsert}
                    canAddAttachments={canAddAttachments}
                    checkInsertAttachmentId={checkInsertAttachmentId}
                    disbursementCategoryAmount={category}
                    disbursementId={disbursementId}
                  />
                </div>
              </div>
            </td>
          </tr>
        ))}
      </>
    )
  );
}

DisbursementCategoryAmounts.propTypes = {
  allowInsert: PropTypes.bool,
  canAddAttachments: PropTypes.bool,
  checkInsertAttachmentId: PropTypes.string,
  disbursementCategoryAmounts: PropTypes.array,
  disbursementId: PropTypes.string.isRequired,
};
