import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import Actions from 'react/shared/actions/actions';
import HolidayCalendarStore from 'react/shared/stores/HolidayCalendarStore';
import bindAll from 'react/shared/utils/bind_all';

export default class DatePickerField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      bankHolidays: [],
    };

    bindAll(this);
  }

  componentDidMount() {
    HolidayCalendarStore.on('bankHolidays.fetch', this.setHolidays);
    // Triggering fetch will cause the fetch event to emit whether holidays are loaded already or not
    Actions.fetchBankHolidays();
  }

  componentWillUnmount() {
    HolidayCalendarStore.off('bankHolidays.fetch', this.setHolidays);
  }

  inputProps() {
    return {
      onBlur: this.onInputBlur,
      ...this.props.inputProps,
    };
  }

  setHolidays() {
    this.setState({ bankHolidays: HolidayCalendarStore.getBankHolidays() });
    this.initializeDatePicker();
  }

  initializeDatePicker() {
    const { defaultDate, minDate, maxDate, dateFormat, excludeWeekendsAndHolidays } = this.props;

    const options = {
      defaultDate,
      minDate,
      maxDate,
      dateFormat,
      onSelect: this.onDateSelect,
    };

    if (excludeWeekendsAndHolidays) {
      options.beforeShowDay = this.rejectWeekendsAndHolidays;
    }

    this._datepicker = $(ReactDOM.findDOMNode(this._datepicker_input)).datepicker(options);
  }

  onDateSelect(date, picker) {
    const { handleDateSelect } = this.props;
    if (typeof handleDateSelect == 'function') {
      handleDateSelect(date, picker);
    }
  }

  onInputBlur(ev) {
    const date = ev.target.value;
    this.onDateSelect(date, { input: this._datepicker });
  }

  rejectWeekendsAndHolidays(day) {
    const { bankHolidays } = this.state;

    const [showDay, cssClass, tooltip] = $.datepicker.noWeekends(day);
    if (showDay) {
      return [!bankHolidays[moment(day).format('YYYY-MM-DD')], ''];
    }
    return [showDay, cssClass, tooltip];
  }

  setInputRef(input) {
    this._datepicker_input = input;
    this.initializeDatePicker();
  }

  render() {
    return (
      <div className="datepicker-wrapper">
        <input className="datepicker" ref={this.setInputRef} type="text" {...this.inputProps()} />
      </div>
    );
  }
}

DatePickerField.propTypes = {
  defaultDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number]),
  minDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number]),
  maxDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number]),
  dateFormat: PropTypes.string,
  excludeWeekendsAndHolidays: PropTypes.bool,
  handleDateSelect: PropTypes.func,
  inputProps: PropTypes.object,
};

DatePickerField.defaultProps = {
  dateFormat: 'yy-mm-dd',
  inputProps: {},
};
