import PropTypes from 'prop-types';
import React from 'react';

export default class NavigationButtons extends React.Component {
  static get propTypes() {
    return {
      transitionBackTo: PropTypes.func,
    };
  }

  renderBackButton() {
    if (!this.props.transitionBackTo) return;

    const clickProps = {
      onClick: this.props.transitionBackTo,
      onKeyDown: this.props.transitionBackTo,
    };

    return (
      <div>
        <a
          {...clickProps}
          className="questionnaire-item-footer-back hide-on-mobile"
          id="navigate-back-link"
        >
          Back
        </a>
        <button
          {...clickProps}
          className="questionnaire-item-footer-back btn-new nav-btn--flat btn-new--large show-on-mobile"
          id="navigate-back-btn"
          type="button"
        >
          Back
        </button>
      </div>
    );
  }

  render() {
    return (
      <div className="questionnaire-item-footer">
        <button className="btn-new btn-new--green btn-new--large hide-on-mobile" type="submit">
          Next&nbsp;&nbsp;&nbsp;<span className="btn-new__angle-right">&#8250;</span>
        </button>
        <button className="btn-new nav-btn--flat btn-new--large show-on-mobile" type="submit">
          Continue
        </button>
        {this.renderBackButton()}
      </div>
    );
  }
}
