import _find from 'underscore/modules/find';
import DashboardActions from 'react/member/actions/dashboard_actions';

const DashboardPayeeStore = flux.createStore({
  payees: [],
  actions: [
    DashboardActions.addBeneficiaryPayee,
    DashboardActions.fetchPayees,
    DashboardActions.updateBeneficiaryPayee,
    DashboardActions.destroyPayee,
  ],

  destroyPayee(payeeId) {
    $.ajax({
      method: 'DELETE',
      url: RailsRoutes.dashboard_payee_path(payeeId, { format: 'json' }),
      success: () => {
        Truelink.flash('success', 'Payee deleted!');
        this.emit('payee.destroy');
      },
    });
  },

  formatPayeeData(data) {
    return {
      data: {
        type: 'payee',
        id: data.id,
        attributes: {
          name: data.name,
          doingBusinessAs: data.doingBusinessAs,
          needs1099Form: data.needs1099Form || false,
          taxIdNumber: data.taxIdNumber,
          paymentMethod: data.payment_method,
          clientAccountNumber: data.clientAccountNumber,
          memo: data.clientAccountNumber,
          trustBeneficiarySlug: data.trustBeneficiarySlug,
          disposition: data.disposition,
          specialInstructions: data.specialInstructions,
          bankAccountNumber: data.bankAccountNumber,
          bankRoutingNumber: data.bankRoutingNumber,
          swiftCode: data.swiftCode,
        },
        relationships: {
          address: {
            data: {
              type: 'address',
              attributes: {
                ...data.address_attributes,
              },
            },
          },
        },
      },
    };
  },

  addBeneficiaryPayee(data) {
    const payeeData = this.formatPayeeData(data);

    $.post({
      url: `${RailsRoutes.api_v2_trust_beneficiary_payees_path(
        data.trustBeneficiaryId,
      )}?include=address`,
      data: payeeData,
      dataType: 'json',
    })
      .done((payee) => {
        const newPayee = {
          ...payee.data.attributes,
          id: Number(payee.data.id),
          supported_payment_methods: payee.data.attributes.supportedPaymentMethods,
          disposition: payee.data.attributes.disposition,
          client_account_number: payee.data.attributes.memo,
          address_attributes: payee.included?.[0]?.attributes,
        };
        this.payees.push(newPayee);
        Truelink.flash(
          'success',
          'New Payee created! You may continue with your disbursement request.',
        );
        this.emit('payees.add', newPayee);
      })
      .fail(() => {
        Truelink.flash(
          'error',
          'Unable to create New Payee. Please contact True Link support for further assistance.',
        );
      });
  },

  updateBeneficiaryPayee(data) {
    const payeeData = this.formatPayeeData(data);

    $.ajax({
      method: 'PATCH',
      url: RailsRoutes.api_v2_payee_path(data.id, { format: 'json' }),
      data: payeeData,
      success: (payee) => {
        Truelink.flash('success', `Payee ${payee.data.attributes.name} updated!`);
        this.emit('payees.update');
      },
      errors: () => {
        Truelink.flash(
          'error',
          'Unable to update payee. Please contact True Link support for further assistance.',
        );
      },
    });
  },

  fetchPayees(client_id) {
    this.payees = [];
    this.emit('payees.clear', this.payees, false);
    $.get(RailsRoutes.dashboard_client_payees_path(client_id, { format: 'json' }), (payees) => {
      this.payees = payees;
      this.emit('payees.fetch', this.payees, true);
    });
  },

  exports: {
    get(id) {
      return _find(this.payees, (payee) => payee.id === id);
    },

    getPayees() {
      return this.payees;
    },

    getNameById(id) {
      const payee = _find(this.payees, (payee) => payee.id === id);
      return payee && payee.name;
    },
  },
});

export default DashboardPayeeStore;
