import React from 'react';
import TemporaryMessage from './TemporaryMessage';

export default class SavedMessage extends React.Component {
  saved() {
    this.refs.temporaryMessage.show();
  }

  reset() {
    this.refs.temporaryMessage.reset();
  }

  render() {
    return <TemporaryMessage className="saved" message="Saved!" ref="temporaryMessage" />;
  }
}
