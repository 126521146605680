import { useQuery } from '@tanstack/react-query';
import { fetchDisbursementsShow } from 'react/member/components/dashboard/disbursements/filter_table/utils/disbursements_v2_api';

export default function useDisbursementShowQuery({ disbursementId }) {
  const result = useQuery(
    ['disbursement', disbursementId],
    () => fetchDisbursementsShow(disbursementId),
    {
      keepPreviousData: true,
    },
  );

  return result;
}
