import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { prettyPhone } from 'react/member/utils/Formatting';
import TrueLinkLink from 'react/shared/components/true_link/main/TrueLinkLink';
import TrueLinkNumericCode from 'react/shared/components/true_link/main/form/TrueLinkNumericCode';
import PALETTE from 'react/shared/theme/palette';

const useStyles = makeStyles({
  error: {
    '& span': {
      'padding-bottom': '10px',
    },

    '& p, & input': {
      color: PALETTE.red,
    },

    '& input': {
      'border-color': PALETTE.red,
    },
  },
});

export default function MfaSetupWizardStep2({
  email,
  mfaDeliveryOption,
  phoneNumber,
  sendMfaCode,
  setCode,
  showError,
}) {
  const classes = useStyles();
  return (
    <div className={classNames({ [classes.error]: showError })}>
      <span>
        We sent a verification code to{' '}
        {mfaDeliveryOption == 'email' ? email : phoneNumber && prettyPhone(phoneNumber)}.
        <div>
          <TrueLinkLink ariaLabel="Resend code" onClick={sendMfaCode}>
            Resend code
          </TrueLinkLink>
        </div>
      </span>
      <TrueLinkNumericCode fields={6} isValid={!showError} onChange={setCode} />
      {showError && <p>You have entered the wrong code.</p>}
    </div>
  );
}

MfaSetupWizardStep2.propTypes = {
  email: PropTypes.string,
  mfaDeliveryOption: PropTypes.string,
  phoneNumber: PropTypes.string.isRequired,
  sendMfaCode: PropTypes.func.isRequired,
  setCode: PropTypes.func.isRequired,
  showError: PropTypes.bool,
};
