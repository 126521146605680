import PropTypes from 'prop-types';
import React from 'react';
import CancelledLabel from './CancelledLabel';
import EmergencyFundedLabel from './EmergencyFundedLabel';
import InProgressLabel from './InProgressLabel';
import SkippedLabel from './SkippedLabel';
import { transferShape } from 'react/member/card/shapes/TransferShape';
import { isCancelled, isPrepared, isSkipped, isEmergencyFunded } from 'react/member/utils/Transfer';

export default function TransferStatusLabel({ onEmergencyFund, onUnskip, transfer }) {
  if (isEmergencyFunded(transfer)) {
    return <EmergencyFundedLabel />;
  }
  if (isSkipped(transfer)) {
    return <SkippedLabel onUnskip={onUnskip} transfer={transfer} />;
  }
  if (isCancelled(transfer)) {
    return <CancelledLabel />;
  }
  if (isPrepared(transfer)) {
    return <InProgressLabel onEmergencyFund={onEmergencyFund} transfer={transfer} />;
  }
  return null;
}

TransferStatusLabel.propTypes = {
  onEmergencyFund: PropTypes.func,
  onUnskip: PropTypes.func,
  transfer: transferShape.isRequired,
};

TransferStatusLabel.defaultProps = {
  onEmergencyFund: null,
  onUnskip: null,
};
