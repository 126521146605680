import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import DatePicker from './DatePicker';
import DashboardActions from 'react/member/actions/dashboard_actions';
import RecurringDisbursementStore from 'react/member/stores/RecurringDisbursementStore';
import PopUp from 'react/shared/components/popups/PopUp';
import bindAll from 'react/shared/utils/bind_all';

export default class CancelModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cancelAsap: true,
      date: null,
      submitting: false,
    };

    bindAll(this);
  }

  componentDidMount() {
    RecurringDisbursementStore.on(
      'recurringDisbursement.cancellationRequest',
      this.onSubmitSuccess,
    );
    RecurringDisbursementStore.on(
      'recurringDisbursement.cancellationRequest.error',
      this.onSubmitError,
    );
  }

  componentWillUnmount() {
    RecurringDisbursementStore.off(
      'recurringDisbursement.cancellationRequest',
      this.onSubmitSuccess,
    );
    RecurringDisbursementStore.off(
      'recurringDisbursement.cancellationRequest.error',
      this.onSubmitError,
    );
  }

  handleDateSelect(date) {
    this.setState({
      date,
    });
  }

  handleSave(e) {
    e.preventDefault();
    if (!this.state.submitting) {
      this.submit();
    }
  }

  submit() {
    const { recurringDisbursement } = this.props;
    DashboardActions.cancelRecurringDisbursement(recurringDisbursement.id, this.state.date);
    this.setState({ submitting: true });
  }

  onSubmitSuccess() {
    Truelink.flash('success', 'Requested cancellation for recurring disbursement');
    this.setState({ submitting: false });
    this.props.onClose();
  }

  onSubmitError() {
    this.setState({ submitting: false });
  }

  cancelAsapChecked() {
    this.setState({ cancelAsap: true });
  }

  cancelOnDateChecked() {
    this.setState({ cancelAsap: false });
  }

  renderSaveButton() {
    let buttonClass = 'btn btn-success';
    if (this.state.submitting) {
      buttonClass += ' disabled';
    }
    return (
      <div>
        <button className={buttonClass} id="submit-button" onClick={this.handleSave} type="button">
          Request Cancellation
        </button>
      </div>
    );
  }

  render() {
    const { cancelAsap } = this.state;
    return (
      <PopUp
        footer={this.renderSaveButton()}
        header={<div>Cancel Recurring Disbursement</div>}
        maxWidth="550px"
        onClose={this.props.onClose}
        openModal
      >
        <div className="form">
          <div className="form-group input-container">
            <input
              checked={cancelAsap}
              id="asap"
              name="cancel-type"
              onChange={this.cancelAsapChecked}
              style={{ marginRight: 10 }}
              type="radio"
              value="asap"
            />
            <label htmlFor="asap">Cancel ASAP</label>
          </div>
          <div className="form-group input-container">
            <input
              checked={!cancelAsap}
              id="onDate"
              name="cancel-type"
              onChange={this.cancelOnDateChecked}
              style={{ marginRight: 10 }}
              type="radio"
              value="onDate"
            />
            <label htmlFor="onDate">Cancel on specific date</label>
          </div>
          <div className={classNames('input-container', { hidden: cancelAsap })}>
            <label htmlFor="timeline-date" id="" style={{ marginRight: 10 }}>
              Last Disbursement Date
            </label>
            <DatePicker
              date={this.state.date}
              onDateSelect={this.handleDateSelect}
              recurringDisbursement={this.props.recurringDisbursement}
            />
          </div>
        </div>
      </PopUp>
    );
  }
}

CancelModal.propTypes = {
  onClose: PropTypes.func,
  recurringDisbursement: PropTypes.shape({
    id: PropTypes.number,
    date: PropTypes.string,
    day_of_month: PropTypes.number,
    day_of_month_2: PropTypes.number,
    day_of_week: PropTypes.number,
    frequency: PropTypes.number,
    recurring_type: PropTypes.string,
  }).isRequired,
};
