import { Formik } from 'formik';
import React from 'react';
import TrueLinkSelect from './TrueLinkSelect';

export default {
  title: 'Forms/Select',
  component: TrueLinkSelect,
  argTypes: {
    onChange: { action: 'clicked' },
  },
};

function Template(args) {
  return (
    <Formik initialValues={args.initialValue}>
      {({ handleChange, values }) => (
        <>
          <TrueLinkSelect
            {...args}
            id="id"
            name="selectChoice"
            onChange={handleChange}
            placeholder="selectOption"
            value={values.selectChoice}
          />
        </>
      )}
    </Formik>
  );
}

export const Preselected = Template.bind({});
Preselected.args = {
  initialValue: { selectChoice: 'choice1' },
  options: [
    { value: 'choice1', label: 'Choice 11' },
    { value: 'choice2', label: 'Choice 21' },
    { value: 'choice3', label: 'Choice 31' },
  ],
};

export const Unselected = Template.bind({});
Unselected.args = {
  initialValue: { selectChoice: '' },
  options: [
    { value: 'vanilla', label: 'Vanilla' },
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
  ],
};
