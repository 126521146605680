import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import DatePicker from 'react/shared/components/DatePicker';
import SubSectionHeader from 'react/shared/components/SubSectionHeader';
export default function BeneficiarySubAccountReportsSection({ client, onSubmitReportRequest }) {
  const onSubmitSubAccountReportRequest = (startDate, endDate) => {
    const params = {
      report_type: 'Dashboard::GenerateSubAccountStatements',
      trust_beneficiary_id: client.id,
      start_date: moment(startDate).format('YYYY-MM-DD'),
      end_date: moment(endDate).format('YYYY-MM-DD'),
    };
    onSubmitReportRequest(params);
  };

  const subAccountReports = () => {
    if (client.closeout_date_range_across_portfolios) {
      const first_closeout = moment(client.closeout_date_range_across_portfolios.first_closeout);
      const last_closeout = moment(client.closeout_date_range_across_portfolios.last_closeout);
      const last_closeout_beginning_of_quarter = moment(
        client.closeout_date_range_across_portfolios.last_closeout_beginning_of_quarter,
      );
      const infoText =
        'Choose a date range to view sub-account activity, regardless of portfolio, for that timeframe. Only dates that have had a closeout are available.';
      return (
        <div id="custom-time-period-report">
          <DatePicker
            action="Open"
            endDate={last_closeout}
            infoText={infoText}
            maxDate={last_closeout.toDate()}
            minDate={first_closeout.toDate()}
            onSubmit={onSubmitSubAccountReportRequest}
            startDate={last_closeout_beginning_of_quarter}
          />
        </div>
      );
    }
  };

  return (
    <div>
      <SubSectionHeader>Sub-Account Reports</SubSectionHeader>
      {subAccountReports()}
    </div>
  );
}

BeneficiarySubAccountReportsSection.propTypes = {
  client: PropTypes.object.isRequired,
  onSubmitReportRequest: PropTypes.func.isRequired,
};
