import PropTypes from 'prop-types';
import React from 'react';
import StyledLabel from 'react/shared/components/forms/StyledLabel';
import childrenShape from 'react/shared/shapes/children';

export default class GenericInlineLabel extends React.Component {
  renderSpacer() {
    if (!this.props.spacer) return null;

    return <div className="generic-inline-label--spacer" />;
  }

  renderOptional() {
    if (!this.props.optional) return null;

    return <p className="generic-inline-label--optional">Optional</p>;
  }

  renderSemanticLabel() {
    return (
      <div>
        <StyledLabel
          inputId={this.props.inputId}
          labelContent={`${this.props.labelContent}:`}
          styles={{
            container: 'generic-inline-label--container',
            label: 'generic-inline-label--label',
            data: 'generic-inline-label--data',
          }}
        >
          {this.props.children}
        </StyledLabel>
        {this.renderSpacer()}
        {this.renderOptional()}
      </div>
    );
  }

  renderCosmeticLabel() {
    return (
      <div>
        <div className="generic-inline-label--container">
          <span className="generic-inline-label--label">{`${this.props.labelContent}:`}</span>
          <span className="generic-inline-label--data">{this.props.children}</span>
        </div>
        {this.renderSpacer()}
        {this.renderOptional()}
      </div>
    );
  }

  render() {
    if (this.props.inputId) {
      return this.renderSemanticLabel();
    }

    return this.renderCosmeticLabel();
  }
}

GenericInlineLabel.propTypes = {
  children: childrenShape,
  labelContent: PropTypes.string.isRequired,
  inputId: PropTypes.string,
  spacer: PropTypes.bool,
  optional: PropTypes.bool,
};

GenericInlineLabel.defaultProps = {
  inputId: null,
  spacer: false,
  optional: false,
};
