import PropTypes from 'prop-types';
import React from 'react';
import DashboardTrustBeneficiaryProfileTrustStatementRecipientsDestroyForm from './DashboardTrustBeneficiaryProfileTrustStatementRecipientsDestroyForm';
import DashboardTrustBeneficiaryProfileTrustStatementRecipientsEditForm from './DashboardTrustBeneficiaryProfileTrustStatementRecipientsEditForm';
import DashboardActions from 'react/member/actions/dashboard_actions';
import BasicTableBuilder from 'react/member/components/dashboard/misc/BasicTableBuilder';
import DashboardTrustStatementRecipientsStore from 'react/member/stores/DashboardTrustStatementRecipientsStore';
import ToggleSectionHeader from 'react/shared/components/ToggleSectionHeader';
import TrueLinkModal from 'react/shared/components/true_link/lab/TrueLinkModal';
import bindAll from 'react/shared/utils/bind_all';
import tlFieldTransformers from 'react/shared/utils/tl_field_transformers';

export default class DashboardTrustBeneficiaryProfileTrustStatementRecipients extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      addingItem: false,
      editingItem: null,
      destroyingItem: null,
      trustStatementRecipients: [],
      isLoading: true,
    };

    bindAll(this);
  }

  componentDidMount() {
    const trustBeneficiarySlug = this.props.trustBeneficiary.slug;
    this.fetchedTrustStatementRecipients = (trustStatementRecipients) =>
      this.setState({ trustStatementRecipients, isLoading: false });

    this.resetCreatingItem = () => this.setState({ addingItem: false });
    this.resetUpdatingItem = () => this.setState({ editingItem: null });
    this.resetDestroyingItem = () => this.setState({ destroyingItem: null });

    DashboardTrustStatementRecipientsStore.on(
      `trustStatementRecipients.fetch.${trustBeneficiarySlug}`,
      this.fetchedTrustStatementRecipients,
    );
    DashboardTrustStatementRecipientsStore.on(
      `trustStatementRecipients.updated.${trustBeneficiarySlug}`,
      this.fetchedTrustStatementRecipients,
    );
    DashboardTrustStatementRecipientsStore.on(
      `trustStatementRecipients.create.${trustBeneficiarySlug}`,
      this.resetCreatingItem,
    );
    DashboardTrustStatementRecipientsStore.on(
      `trustStatementRecipients.update.${trustBeneficiarySlug}`,
      this.resetUpdatingItem,
    );
    DashboardTrustStatementRecipientsStore.on(
      `trustStatementRecipients.destroy.${trustBeneficiarySlug}`,
      this.resetDestroyingItem,
    );

    DashboardActions.fetchTrustStatementRecipients(this.props.trustBeneficiary.slug);
  }

  componentWillUnmount() {
    const trustBeneficiarySlug = this.props.trustBeneficiary.slug;
    DashboardTrustStatementRecipientsStore.off(
      `trustStatementRecipients.fetch.${trustBeneficiarySlug}`,
      this.fetchedTrustStatementRecipients,
    );
    DashboardTrustStatementRecipientsStore.off(
      `trustStatementRecipients.updated.${trustBeneficiarySlug}`,
      this.fetchedTrustStatementRecipients,
    );
    DashboardTrustStatementRecipientsStore.off(
      `trustStatementRecipients.create.${trustBeneficiarySlug}`,
      this.resetCreatingItem,
    );
    DashboardTrustStatementRecipientsStore.off(
      `trustStatementRecipients.update.${trustBeneficiarySlug}`,
      this.resetUpdatingItem,
    );
    DashboardTrustStatementRecipientsStore.off(
      `trustStatementRecipients.destroy.${trustBeneficiarySlug}`,
      this.resetDestroyingItem,
    );
  }

  renderAddTrustStatementRecipientModal() {
    const { addingItem, editingItem } = this.state;
    if (!addingItem && !editingItem) return;

    const modalProps = {
      title: editingItem ? 'Edit Statement Recipient' : 'Add Statement Recipient',
      handleClose: () => this.setState({ addingItem: false, editingItem: null }),
    };

    return (
      <TrueLinkModal {...modalProps}>
        <DashboardTrustBeneficiaryProfileTrustStatementRecipientsEditForm
          trustBeneficiary={this.props.trustBeneficiary}
          {...(editingItem ? { trustStatementRecipient: editingItem } : {})}
        />
      </TrueLinkModal>
    );
  }

  renderContentTable() {
    const { truncateWithEllipses, formatPhoneNumber } = tlFieldTransformers;
    const tableFieldConfig = {
      name: {
        title: 'Name',
        value: (trustStatementRecipient) => <p>{trustStatementRecipient.name}</p>,
      },
      email: {
        title: 'Email',
        thAttributes: { style: { width: '200px' } },
        value: (trustStatementRecipient) => (
          <p>
            {trustStatementRecipient.email
              ? truncateWithEllipses(trustStatementRecipient.email, 25)
              : ''}
          </p>
        ),
      },
      phone: {
        title: 'Phone',
        value: (trustStatementRecipient) => (
          <p>{formatPhoneNumber(trustStatementRecipient.mobile)}</p>
        ),
      },
      address: {
        title: 'Address',
        value: (trustStatementRecipient) => <p>{trustStatementRecipient.address.street1}</p>,
      },
      city: {
        title: 'City',
        value: (trustStatementRecipient) => <p>{trustStatementRecipient.address.city}</p>,
      },
      state: {
        title: 'State',
        thAttributes: { style: { width: '50px' } },
        value: (trustStatementRecipient) => <p>{trustStatementRecipient.address.state}</p>,
      },
      zip: {
        title: 'Zip',
        thAttributes: { style: { width: '60px' } },
        value: (trustStatementRecipient) => <p>{trustStatementRecipient.address.zip}</p>,
      },
      actionItems: {
        title: '',
        thAttributes: { style: { width: '60px' } },
        value: (trustStatementRecipient) => {
          if (!this.props.canEdit) return null;
          return (
            <div className="action-items">
              <button
                className="action-item btn btn-link edit-item"
                onClick={() => this.setState({ editingItem: trustStatementRecipient })}
                type="button"
              >
                <i className="fa fa-edit" />
              </button>
              <button
                className="action-item btn btn-link danger delete-item"
                onClick={() => this.setState({ destroyingItem: trustStatementRecipient })}
                type="button"
              >
                <i className="fa fa-trash" />
              </button>
            </div>
          );
        },
      },
    };

    return (
      <div className="content-table">
        <BasicTableBuilder
          columnList={['name', 'email', 'phone', 'address', 'city', 'state', 'zip', 'actionItems']}
          itemTypeKey={'trust-statement-recipients'}
          tableFieldConfig={tableFieldConfig}
          tableItems={this.state.trustStatementRecipients}
        />
      </div>
    );
  }

  render() {
    const { canEdit, expanded, toggleExpanded } = this.props;
    return (
      <div className="toggle-section trust-statement-recipients">
        {this.renderAddTrustStatementRecipientModal()}
        {this.state.destroyingItem && (
          <TrueLinkModal
            handleClose={this.resetDestroyingItem}
            title={'Remove Statement Recipient'}
          >
            <DashboardTrustBeneficiaryProfileTrustStatementRecipientsDestroyForm
              trustBeneficiary={this.props.trustBeneficiary}
              trustStatementRecipient={this.state.destroyingItem}
            />
          </TrueLinkModal>
        )}
        <ToggleSectionHeader
          itemCount={this.state.trustStatementRecipients.length}
          title="Statement Recipients"
          {...{ expanded, toggleExpanded }}
        >
          {canEdit ? (
            <div className="action-item">
              <button
                className="edit-link btn btn-link"
                onClick={() => this.setState({ addingItem: true })}
                type="button"
              >
                <i className="fa fa-plus-circle" />
                <span className="padding-5l">Add Statement Recipient</span>
              </button>
            </div>
          ) : null}
        </ToggleSectionHeader>
        {this.props.expanded && (
          <div className="toggle-section-content full-width">
            {this.renderContentTable()}
            {!this.state.trustStatementRecipients.length && (
              <div className="default-content-message">
                <p>No statement recipients attached to this beneficiary.</p>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

DashboardTrustBeneficiaryProfileTrustStatementRecipients.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  trustBeneficiary: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
  expanded: PropTypes.bool.isRequired,
  toggleExpanded: PropTypes.func.isRequired,
};
