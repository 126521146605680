import PropTypes from 'prop-types';
import React from 'react';
import _omit from 'underscore/modules/omit';
import childrenShape from 'react/shared/shapes/children';

export default class Form extends React.Component {
  controller() {
    return this.props.controller;
  }

  action() {
    if (this.props.action) {
      return this.props.action;
    } else if (this.controller()) {
      return this.controller().url();
    }
  }

  method() {
    if (this.props.method) {
      return this.props.method;
    } else if (this.controller()) {
      return this.controller().method();
    }
  }

  data() {
    return $(this.refs.form).serialize();
  }

  formId() {
    if (this.props.formId) {
      return this.props.formId;
    } else if (this.controller() && this.controller().id()) {
      return `${this.controller().model()}_${this.controller().id()}`;
    }
  }

  save(cb) {
    $.ajax({ url: this.action(), data: this.data(), type: this.method(), dataType: 'json' }).done(
      cb,
    );
  }

  render() {
    const onSubmit = (evt) => {
      evt.preventDefault();
      evt.stopPropagation();
    };

    return (
      <div>
        <form
          {..._omit(this.props, 'controller', 'formId')}
          action={this.action()}
          id={this.formId()}
          method={this.method()}
          onSubmit={onSubmit}
          ref="form"
        >
          {this.props.children}
        </form>
      </div>
    );
  }
}

Form.propTypes = {
  action: PropTypes.string,
  children: childrenShape.isRequired,
  method: PropTypes.string,
  controller: PropTypes.object,
  formId: PropTypes.string,
};
