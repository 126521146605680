import axios from 'axios';
import { AXIOS_XHR_CONFIG } from 'react/shared/utils/Axios';

export default {
  getAccount(accountSlug) {
    return axios.get(
      RailsRoutes.dashboard_account_path(accountSlug, { format: 'json' }),
      AXIOS_XHR_CONFIG,
    );
  },
  getStatements(accountSlug) {
    return axios.get(
      RailsRoutes.dashboard_account_statements_to_show_path(accountSlug, { format: 'json' }),
      AXIOS_XHR_CONFIG,
    );
  },
  postDetailedTransactionReport(accountSlug, data) {
    return axios.post(
      RailsRoutes.detailed_transaction_report_path(accountSlug, { format: 'json' }),
      data,
      AXIOS_XHR_CONFIG,
    );
  },
};
