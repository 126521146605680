import { ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import _map from 'underscore/modules/map';
import RadioGroup from 'react/member/components/signups/signup_form/form_partials/fields/RadioGroup';
import FieldLabel from 'react/member/components/signups/signup_form/form_partials/labels/field_label';
import fieldLabelShape from 'react/shared/shapes/field_label_shape';

function CardProgramRadios({ allowedCardPrograms, fieldLabelProps, onChangeCallback }) {
  const options = _map(allowedCardPrograms, (displayName, programNumber) => ({
    id: programNumber,
    value: programNumber,
    labelContent: displayName,
    onChangeCallback,
  }));

  return (
    <div className="card--form__row card--form__row--no-pad-mobile">
      <div className="card--form__col card--form__col--no-pad-mobile">
        <FieldLabel
          htmlFor="card_program_radios"
          labelContent="Card Program"
          {...fieldLabelProps}
        />
        <RadioGroup id="card_program_radios" name="account.tl_card_program" options={options} />
        <ErrorMessage className="error" component="label" name="account.tl_card_program" />
      </div>
    </div>
  );
}

CardProgramRadios.propTypes = {
  allowedCardPrograms: PropTypes.objectOf(PropTypes.string).isRequired,
  fieldLabelProps: fieldLabelShape.isRequired,
  onChangeCallback: PropTypes.func.isRequired,
};

export default CardProgramRadios;
