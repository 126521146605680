import PropTypes from 'prop-types';

const { shape, array, oneOf, string } = PropTypes;

export const PRE_RELEASE_STATUS = 'pre-release';
export const RELEASED_STATUS = 'released';
export const OVERDUE_STATUS = 'overdue';

export const estimatedArrivalShape = shape({
  arrival_date: string,
  range: array.isRequired,
  status: oneOf([PRE_RELEASE_STATUS, RELEASED_STATUS, OVERDUE_STATUS]),
});

export const estimatedArrivalShapeCamelized = shape({
  arrivalDate: string.isRequired,
  range: array.isRequired,
  status: oneOf([PRE_RELEASE_STATUS, RELEASED_STATUS, OVERDUE_STATUS]),
});
