import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import FundsAvailableModal from './FundsAvailableModal';
import { dashboardAccountShape } from 'react/member/card/shapes/AccountShape';
import TrueLinkLink from 'react/shared/components/true_link/main/TrueLinkLink';
import theme from 'react/shared/theme/Theme';

const useStyles = makeStyles(() => ({
  subSectionHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: '1px solid #aaa',
    lineHeight: '42px',
    marginTop: '15px',
    fontSize: '26px',
    fontWeight: 'normal',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      lineHeight: '25px',
      gap: '10px',
      paddingBottom: '5px',
    },
  },

  subSectionHeaderRight: {
    fontSize: '19px',
  },
}));

export default function TransfersTableHeader({
  account,
  enableFundsAvailableModal = false,
  title,
}) {
  const classes = useStyles();
  const [showFundsAvailableModal, setFundsAvailableModal] = useState(false);

  const handleOpenFundsAvailableModal = () => setFundsAvailableModal(true);
  const handleCloseFundsAvailableModal = () => setFundsAvailableModal(false);

  return (
    <>
      <div className={classes.subSectionHeader}>
        {title}
        {enableFundsAvailableModal && (
          <div className={classes.subSectionHeaderRight}>
            <TrueLinkLink onClick={handleOpenFundsAvailableModal}>
              When will the funds be available?
            </TrueLinkLink>
          </div>
        )}
      </div>
      {showFundsAvailableModal && (
        <FundsAvailableModal account={account} onClose={handleCloseFundsAvailableModal} />
      )}
    </>
  );
}

TransfersTableHeader.propTypes = {
  account: dashboardAccountShape,
  enableFundsAvailableModal: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};
