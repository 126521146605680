import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles({
  wrapper: {
    marginTop: '25px',
    fontSize: '16px',
    lineHeight: '20px',
  },
  label: {
    marginBottom: '5px',
    opacity: '50%',
  },
  row: {
    borderTop: '1px solid #00000010',
    display: 'flex',
    padding: '10px 0',
  },
  rowLabel: {
    width: '200px',
  },
});

export default function CardShipment({
  cardFboName,
  cardName,
  cardShipmentTrackingNumber,
  expedited,
  shippingAddress,
}) {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.label}>Order & Shipping information</div>
      <div>
        <div className={classes.row}>
          <span className={classes.rowLabel}>Name on card</span>
          <span>
            <div>{cardName}</div>
            {cardFboName && <div>{`FBO ${cardFboName}`}</div>}
          </span>
        </div>
        <div className={classes.row}>
          <span className={classes.rowLabel}>Shipping address</span>
          <span>{shippingAddress ? shippingAddress : 'No address on file'}</span>
        </div>
        <div className={classes.row}>
          <span className={classes.rowLabel}>Shipping method</span>
          <span>
            <div>{expedited ? 'Express shipping' : 'Standard shipping'}</div>
            <div>
              {expedited && <div>{`Tracking number: ${cardShipmentTrackingNumber || 'none'}`}</div>}
            </div>
          </span>
        </div>
      </div>
    </div>
  );
}

CardShipment.propTypes = {
  cardFboName: PropTypes.string,
  cardName: PropTypes.string.isRequired,
  cardShipmentTrackingNumber: PropTypes.string,
  expedited: PropTypes.bool.isRequired,
  shippingAddress: PropTypes.string,
};
