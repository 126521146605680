import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import TransferIconCell from 'react/member/card/components/funding_tab/transfers_table/TransferIconCell';
import { autoTransferShape } from 'react/member/card/shapes/AutoTransferShape';
import { transferShape } from 'react/member/card/shapes/TransferShape';

const useStyles = makeStyles({
  small: {
    fontSize: '17px',
  },
  grey: {
    opacity: '50%',
  },
  both: {
    fontSize: '17px',
    opacity: '50%',
  },
  memo: {
    fontSize: '17px',
    opacity: '50%',
  },
  cell: {
    display: 'flex',
    marginLeft: '-20px',
  },
  icon: {
    width: '20px',
    marginRight: '5px',
  },
});

export default function TransferDescriptionLite({ item, alternateDate }) {
  const classes = useStyles();
  const isAutoTransfer = item.recurringType;
  const isRecurringTransfer = item.autoTransferId;
  const date = alternateDate || item.dateUnformatted;
  const formattedDate = moment(date).format('dddd, MMMM D');

  return (
    <div className={classes.cell}>
      <div className={classes.icon}>
        {(isAutoTransfer || isRecurringTransfer) && (
          <TransferIconCell className={'fa-sync'} color={'grey'} tooltipOn={false} />
        )}
      </div>
      {isAutoTransfer ? (
        <div>
          {item.description} for ${item.amount}
        </div>
      ) : (
        <div>
          <div>
            {formattedDate} for ${item.amount}
          </div>
          {item.memo && (
            <div className={classes.memo}>
              <b>Memo: </b>
              {item.memo}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

TransferDescriptionLite.propTypes = {
  item: PropTypes.oneOfType([autoTransferShape, transferShape]).isRequired,
  alternateDate: PropTypes.string,
};
