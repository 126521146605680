import { ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import TextField from 'react/member/components/signups/signup_form/form_partials/fields/TextField';
import FieldLabel from 'react/member/components/signups/signup_form/form_partials/labels/field_label';

function NameField({ entity, labelPrefix, rowClass, colClass, dataAnalyticsKey }) {
  return (
    <div className={rowClass}>
      <div className={colClass}>
        <FieldLabel
          htmlFor={`${entity}_name`}
          labelContent={labelPrefix ? `${labelPrefix} name` : 'Name'}
        />
        <TextField
          dataAnalyticsKey={dataAnalyticsKey}
          id={`${entity}_name`}
          name={`${entity}.name`}
        />
        <ErrorMessage className="error" component="label" name={`${entity}.name`} />
      </div>
    </div>
  );
}

NameField.propTypes = {
  entity: PropTypes.oneOf(['organization']).isRequired,
  labelPrefix: PropTypes.string,
  rowClass: PropTypes.string,
  colClass: PropTypes.string,
  dataAnalyticsKey: PropTypes.string,
};

NameField.defaultProps = {
  labelPrefix: null,
  rowClass: 'card--form__row',
  colClass: 'card--form__col',
};

export default NameField;
