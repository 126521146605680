import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import DetailedTransactionReport from './DetailedTransactionReport';
import SubSectionHeader from 'react/shared/components/SubSectionHeader';

const useStyles = makeStyles({
  wrapper: {
    marginTop: '40px',
    '& p': {
      margin: '0 0 6px 0',
    },
  },
});

export default function DetailedTransactionReportSection(props) {
  const classes = useStyles();

  return (
    <div className={classes.wrapper} id="detailed_transaction_report">
      <SubSectionHeader>Detailed Transaction Report</SubSectionHeader>
      <div>
        <p>
          Request a report of transactions with additional details including notes, receipts, and
          QDE selection for this card.
        </p>
        <p>The report will be emailed to you in 1-2 business days.</p>
        <DetailedTransactionReport accountId={props.accountId} />
      </div>
    </div>
  );
}

DetailedTransactionReportSection.propTypes = {
  accountId: PropTypes.string.isRequired,
};
