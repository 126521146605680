import PropTypes from 'prop-types';
import React from 'react';

export default function TablePageCount({
  className,
  pageIndex,
  pageSize,
  pageCount,
  totalRecords,
  rowLength,
}) {
  if (!totalRecords) {
    return (
      <div className={className}>{!totalRecords && `Page ${pageIndex + 1} of ${pageCount}`}</div>
    );
  }

  const maxRecordsOnPage = (pageIndex + 1) * pageSize;
  return (
    <div className={className}>
      {`${pageIndex * pageSize + 1} - ${
        maxRecordsOnPage > rowLength ? rowLength : maxRecordsOnPage
      } of ${rowLength}`}
    </div>
  );
}

TablePageCount.propTypes = {
  className: PropTypes.string,
  pageIndex: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  totalRecords: PropTypes.number,
  rowLength: PropTypes.number,
};
