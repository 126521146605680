import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import DashboardActions from 'react/member/actions/dashboard_actions';
import { bankAccountShape } from 'react/member/card/shapes/BankAccountShape';
import DashboardBankAccountStore from 'react/member/stores/DashboardBankAccountStore';
import PopUp from 'react/shared/components/popups/PopUp';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';

export default function VerifyBankAccountModal({ bankAccount, onClose }) {
  const [amountError1, setAmountError1] = useState(false);
  const [amountErrorMessage1, setAmountErrorMessage1] = useState('');
  const [amountError2, setAmountError2] = useState(false);
  const [amountErrorMessage2, setAmountErrorMessage2] = useState('');

  useEffect(() => {
    DashboardBankAccountStore.on('bankAccount.verify', onClose);

    return () => {
      DashboardBankAccountStore.off('bankAccount.verify', onClose);
    };
  });

  const validAmount = (value, oneOrTwo) => {
    if (value.length === 0 || Number(value) <= 0) {
      if (oneOrTwo === 'one') {
        setAmountError1(true);
        setAmountErrorMessage1('Cannot be zero or empty');
      } else {
        setAmountError2(true);
        setAmountErrorMessage2('Cannot be zero or empty');
      }
      return false;
    } else if (Number(value) >= 1) {
      if (oneOrTwo === 'one') {
        setAmountError1(true);
        setAmountErrorMessage1('Please enter a value less than or equal to 0.99.');
      } else {
        setAmountError2(true);
        setAmountErrorMessage2('Please enter a value less than or equal to 0.99.');
      }
      return false;
    }
    if (oneOrTwo === 'one') {
      setAmountError1(false);
      setAmountErrorMessage1('');
      setAmountError2(false);
      setAmountErrorMessage2('');
    } else {
      setAmountError2(false);
      setAmountErrorMessage2('');
    }
    return true;
  };

  const submitForm = (data) => {
    DashboardActions.verifyBankAccount(bankAccount.id, data);
  };

  const validate = (ev) => {
    ev.preventDefault();
    const form = $('#verify-bank-account-form');
    const data = form.serializeArray();
    const validOne = validAmount(data[0].value, 'one');
    const validTwo = validAmount(data[1].value, 'two');
    if (validOne && validTwo) {
      const params = {
        verification_amount_1: Math.round(data[0].value * 100),
        verification_amount_2: Math.round(data[1].value * 100),
      };
      submitForm(params);
    }
  };

  const depositAmount = (number) => {
    if (![1, 2].includes(number)) return;

    const speltNumber = number == 1 ? 'one' : 'two';

    let error;
    if (number == 1) {
      error = amountError1 && <p style={{ color: '#d93302' }}>{amountErrorMessage1}</p>;
    }
    if (number == 2) {
      error = amountError2 && <p style={{ color: '#d93302' }}>{amountErrorMessage2}</p>;
    }

    return (
      <div className="form-group">
        <div className="new-form__label nowrap">
          <label htmlFor={`deposit_amount_${speltNumber}`}>Deposit amount {number}: $</label>
        </div>
        <div className="new-form__data">
          <input
            id={`deposit_amount_${speltNumber}`}
            max=".99"
            maxLength="4"
            min=".01"
            name={`verification_amount_${number}`}
            placeholder="0.00"
            size="5"
            step=".01"
            style={{ width: 'auto' }}
            type="number"
          />
          {error}
        </div>
      </div>
    );
  };

  const header = () => <div style={{ textAlign: 'left' }}>Verify bank account</div>;

  const footer = () => (
    <TrueLinkButton className="btn normal" onClick={validate} variant="none">
      Submit
    </TrueLinkButton>
  );

  const body = () => (
    <div style={{ fontSize: 16 }}>
      <p>
        Enter the amounts of the two small deposits below. This will verify the account and allow
        you to transfer funds onto your True Link Card. If you have any questions, please contact us
        at 1 (877) 264-8783.
      </p>
      <hr />
      <form id="verify-bank-account-form">
        {depositAmount(1)}
        {depositAmount(2)}
      </form>
    </div>
  );

  return (
    <PopUp
      bannerMessage="Please correct the form fields highlighted below and submit again"
      footer={footer()}
      header={header()}
      maxWidth="550px"
      modalOpen
      onClose={onClose}
      showBanner={amountError1 || amountError2}
    >
      {body()}
    </PopUp>
  );
}

VerifyBankAccountModal.propTypes = {
  bankAccount: bankAccountShape.isRequired,
  onClose: PropTypes.func.isRequired,
};
