import PropTypes from 'prop-types';
import addressShape from 'react/shared/shapes/address_shape';

export const administratorShape = PropTypes.shape({
  address: addressShape,
  full_name: PropTypes.string,
  id: PropTypes.string,
  email: PropTypes.string,
  mobile: PropTypes.string,
});
