import PropTypes from 'prop-types';
import React from 'react';
import Datatable from 'react/shared/components/Datatable';
import SubSectionHeader from 'react/shared/components/SubSectionHeader';

export default function DashboardHoldingEventIndex(props) {
  const isPortfolioView = () => !!props.portfolio;

  const holdingEventsRoute = () => {
    let id, route;
    if (isPortfolioView()) {
      ({ id } = props.portfolio);
      route = RailsRoutes.dashboard_portfolio_holding_events_path;
    } else {
      id = props.client.slug;
      route = RailsRoutes.dashboard_client_holding_events_path;
    }

    return route(id, { format: 'json' });
  };

  const clientColumns = () => {
    if (isPortfolioView()) {
      return [
        <th className="js-datatable-col-client" key="datatable-client">
          Client
        </th>,
      ];
    }
    return [];
  };
  const columns = () =>
    [
      <th className="js-datatable-col-date" key="datatable-date">
        Date
      </th>,
    ]
      .concat(clientColumns())
      .concat([
        <th className="js-datatable-col-transaction-type" key="datatable-transaction-type">
          Transaction Type
        </th>,
        <th className="js-datatable-col-units" key="datatable-units">
          Units
        </th>,
        <th className="js-datatable-col-unit-value" key="datatable-unit-value">
          Unit Value
        </th>,
        <th className="js-datatable-col-total-value" key="datatable-total-value">
          Total Value
        </th>,
      ]);

  const columnDefs = () => [
    {
      targets: ['js-datatable-col-date'],
      data: 'date',
    },
    {
      targets: ['js-datatable-col-client'],
      data: 'client_name',
    },
    {
      targets: ['js-datatable-col-transaction-type'],
      orderable: false,
      data: 'description',
      className: 'limited-width',
    },
    {
      targets: ['js-datatable-col-units'],
      data: 'units',
    },
    {
      targets: ['js-datatable-col-unit-value'],
      data: 'unit_value',
    },
    {
      targets: ['js-datatable-col-total-value'],
      data: 'total_value',
    },
  ];

  const handleRender = (table) => (
    <div>
      <SubSectionHeader>Unit Transactions</SubSectionHeader>

      {table}
    </div>
  );

  // Since we currently only support searching on the client name,
  // it is pointless to show the search box on a client page.
  return (
    <Datatable
      columnDefs={columnDefs()}
      columns={columns()}
      handleRender={handleRender}
      initialSort={[[0, 'desc']]}
      pageLength={isPortfolioView() ? 50 : 25}
      route={holdingEventsRoute()}
      shouldShowSearchBox={isPortfolioView()}
    />
  );
}

DashboardHoldingEventIndex.propTypes = {
  client: PropTypes.object,
  portfolio: PropTypes.object,
};
