import Typography from '@mui/material/Typography';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import * as Yup from 'yup';
import CardPaymentFormFields from 'react/shared/card/components/CardPaymentFormFields';
import PopUp from 'react/shared/components/popups/PopUp';
import StateDropdown from 'react/shared/components/state_dropdown';
import CountryOfCitizenship from 'react/shared/components/true_link/lab/form/country_of_citizenship/CountryOfCitizenship';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import TrueLinkRadios from 'react/shared/components/true_link/main/form/TrueLinkRadios';
import addressShape from 'react/shared/shapes/address_shape';
import schema from 'react/shared/utils/schemas';
import ErrorText from 'react/signups/card/common/ErrorText';
import { yesNoToBoolean } from 'react/signups/card/utils';

export default function ReportLostOrStolenModal({
  accountSlug,
  allowAutomatedPayment,
  cardholderAddress,
  adminMobile,
  cardholderMobile,
  cardholderName,
  cardholderCountryOfCitizenship,
  disableSelectingLastKnownAddress,
  onClose,
  onSuccess,
  organizationAddress,
  otherShippingDestinationDefault,
  paymentMethods,
  shippingAddress,
  shippingFee,
  shippingSettings,
}) {
  const [possiblePoBox, setPossiblePoBox] = useState(false);
  const countryOfCitizenshipLabel = 'Cardholder Country of Citizenship';
  const nonResidentAlienLabel = `Is the Cardholder a non-resident alien?`;
  const currentAddressOption = {
    id: 'ship_to_current_shipping',
    label: (
      <>
        <Typography variant="body2">Last known shipping address</Typography>
        <Typography style={{ color: '#757575' }} variant="body2">
          Ship To: {shippingAddress}
        </Typography>
      </>
    ),
    value: 'current_shipping_address',
  };

  const initShippingDestinationOptions = [
    {
      id: 'ship_to_home',
      label: (
        <>
          <Typography variant="body2">Cardholder home</Typography>
          <Typography style={{ color: '#757575' }} variant="body2">
            Ship To: {cardholderAddress}
          </Typography>
        </>
      ),
      value: 'cardholder_home',
    },
    {
      id: 'ship_to_office',
      label: (
        <>
          <Typography variant="body2">Office</Typography>
          <Typography style={{ color: '#757575' }} variant="body2">
            Ship To: {organizationAddress}
          </Typography>
        </>
      ),
      value: 'office',
    },
    {
      id: 'ship_to_other',
      label: (
        <>
          <Typography variant="body2">Other</Typography>
          <Typography style={{ color: '#757575' }} variant="body2" />
        </>
      ),
      value: 'other',
    },
  ];

  const shippingDestinationOptions =
    shippingAddress && !disableSelectingLastKnownAddress
      ? [
          ...initShippingDestinationOptions.slice(0, 2),
          currentAddressOption,
          ...initShippingDestinationOptions.slice(2),
        ]
      : initShippingDestinationOptions;

  const name = () => cardholderName || 'the Cardholder';

  const poBoxWarning = () => {
    if (possiblePoBox) {
      return 'The address you requested looks like a PO Box. Unfortunately we are prohibited from shipping cards to PO Boxes. If this address is not a PO Box, please press submit. If it is a PO Box, please press cancel and request a new address. If you continue with this request using a PO Box address, the card order will be delayed and a new shipping address will be requested.';
    }
  };

  const checkAddresses = (event) => {
    const $target = $(event.target);
    const street1 = $target
      .closest('form')
      .find("input[name='card_reissue[address_attributes][street1]']")
      .val();
    const street2 = $target
      .closest('form')
      .find("input[name='card_reissue[address_attributes][street2]']")
      .val();
    const regexPoBox = new RegExp($tlf.domains.poBoxRegex, 'gmi');

    if (regexPoBox.test(street1) || regexPoBox.test(street2)) {
      setPossiblePoBox(true);
    } else {
      setPossiblePoBox(false);
    }
  };

  const header = () => (
    <div style={{ textAlign: 'left' }}>
      <span>To close card and order a replacement,</span>
      <br />
      <span> select an option below.</span>
    </div>
  );

  const subheader = () => (
    <span>
      By clicking REQUEST REISSUE, you agree to permanently close {name()}'s current card and order
      a new card.
    </span>
  );

  const otherShippingDestinationInputs = (formik) => {
    const values = formik.values;
    if (values.shipping_destination === 'other') {
      return (
        <div>
          <div className="flex">
            <div>
              <label htmlFor="card_reissue_address_street1">Street</label>
              <input
                disabled={values.shipping_destination != 'other'}
                id="card_reissue_address_street1"
                name="address_attributes[street1]"
                onBlur={checkAddresses}
                onChange={formik.handleChange}
                style={{ height: 37 }}
                type="text"
                value={values.address_attributes.street1}
              />
              <ErrorText name="address_attributes[street1]" />
            </div>
            <div style={{ marginLeft: 10 }}>
              <label htmlFor="card_reissue_address_street2">Apt or unit number</label>
              <input
                disabled={values.shipping_destination != 'other'}
                id="card_reissue_address_street2"
                name="address_attributes[street2]"
                onBlur={checkAddresses}
                onChange={formik.handleChange}
                style={{ height: 37 }}
                type="text"
                value={values.address_attributes.street2}
              />
            </div>
          </div>
          <div className="flex">
            <span style={{ color: 'red' }}> {poBoxWarning()} </span>
          </div>
          <div className="flex">
            <div>
              <label htmlFor="card_reissue_address_city">City</label>
              <input
                id="card_reissue_address_city"
                name="address_attributes[city]"
                onChange={formik.handleChange}
                style={{ height: 37 }}
                type="text"
                value={values.address_attributes.city}
              />
              <ErrorText name="address_attributes[city]" />
            </div>
            <div style={{ marginLeft: 10 }}>
              <label htmlFor="payee_state">State</label>
              <StateDropdown
                id="payee_state"
                name="address_attributes[state]"
                onChange={formik.handleChange}
                value={values.address_attributes.state}
              />
              <ErrorText name="address_attributes[state]" />
            </div>
            <div style={{ marginLeft: 10 }}>
              <label htmlFor="card_reissue_address_zip">Zip</label>
              <input
                id="card_reissue_address_zip"
                maxLength="5"
                name="address_attributes[zip]"
                onChange={formik.handleChange}
                placeholder="Zip code"
                style={{ width: 70, height: 37 }}
                type="text"
                value={values.address_attributes.zip}
              />
              <ErrorText name="address_attributes[zip]" />
            </div>
          </div>
          <br />
        </div>
      );
    }
  };

  const requestCardReissue = (formikValues) => {
    const filteredData = {};
    const isExpeditedShipping = formikValues.expedite_shipping === 'yes';

    for (const key in formikValues) {
      if (
        key !== 'phone_type' &&
        key !== 'countryOfCitizenship' &&
        key !== 'nonResidentAlien' &&
        formikValues[key]
      ) {
        filteredData[key] = formikValues[key];
      }
    }

    if (filteredData['expedite_shipping'] === 'no') {
      delete filteredData['card_recipient_phone'];
    }

    delete filteredData['expedite_charged'];
    const cardholder = {
      country_of_citizenship: formikValues.countryOfCitizenship,
      non_resident_alien: yesNoToBoolean(formikValues.nonResidentAlien),
    };

    const { expedited_shipping_card_fee, ...reissue_fields } = filteredData;
    const data = {
      cardholder,
      ...(allowAutomatedPayment && isExpeditedShipping ? { expedited_shipping_card_fee } : {}),
      card_reissue: {
        ...reissue_fields,
      },
    };
    const params = { account_id: accountSlug, format: 'json' };

    $.post(RailsRoutes.reissue_card_dashboard_cards_path(params), data)
      .done((data) => {
        const shippingInfo = data.shipping_information;
        onClose();
        onSuccess();
        Truelink.flash(
          'success',
          `Thank you for requesting a True Link Card for ${name()}. The current True Link Card is now inactive and cannot be used. A new True Link Card has been ordered and will arrive at the selected address in ${
            shippingInfo.range[0]
          } to ${shippingInfo.range[1]} business days.`,
        );
      })
      .fail((error) => {
        let output = '';
        error.responseJSON.forEach((message) => {
          output += ` ${message} \n`;
        });
        Truelink.flash('error', output);
      });
  };

  const validationSchema = Yup.object().shape({
    shipping_destination: Yup.string()
      .label(
        `You must select where to send ${name()}'s True Link Card before requesting a reissue.`,
      )
      .required(),
    countryOfCitizenship: !cardholderCountryOfCitizenship
      ? schema.countryOfCitizenship.required()
      : schema.countryOfCitizenship,
    nonResidentAlien: Yup.string().when(['countryOfCitizenship'], {
      is: (countryOfCitizenship) => countryOfCitizenship !== 'US',
      then: () => schema.nonResidentAlien.required(),
      otherwise: () => schema.nonResidentAlien.nullable(),
    }),
    expedite_shipping: Yup.string().oneOf(['yes', 'no']).required(),
    expedited_shipping_card_fee: Yup.object().shape({
      payment_method: Yup.string(),
      bank_account_id: Yup.string().when('payment_method', {
        is: 'ach',
        then: () => Yup.string().required('field required'),
        otherwise: () => Yup.string().nullable(),
      }),
    }),
    phone_type: Yup.string().when(['expedite_shipping'], {
      is: (expediteShipping) => expediteShipping === 'yes' || expediteShipping === true,
      then: () => Yup.string().required('field required'),
      otherwise: () => Yup.string(),
    }),
    card_recipient_phone: Yup.string().when(['expedite_shipping', 'phone_type'], {
      is: (expediteShipping, phoneType) =>
        (expediteShipping === 'yes' || expediteShipping === true) && phoneType === 'other',
      then: () => Yup.string().required('field required'),
      otherwise: () => Yup.string(),
    }),
    address_attributes: Yup.object().when(['shipping_destination'], {
      is: (shipping_destination) => shipping_destination == 'other',
      then: () => schema.address,
      otherwise: () => Yup.object(),
    }),
  });

  const getAddress = (address) => ({
    street1: address?.street1 || '',
    street2: address?.street2 || '',
    city: address?.city || '',
    state: address?.state || '',
    zip: address?.zip || '',
  });

  const initialValues = {
    shipping_destination: '',
    countryOfCitizenship: cardholderCountryOfCitizenship || '',
    nonResidentAlien: '',
    expedited_shipping_card_fee: {
      payment_method:
        paymentMethods?.available_bank_accounts?.length > 0 &&
        paymentMethods?.preferred_payment_method
          ? paymentMethods?.preferred_payment_method
          : 'card',
      bank_account_id: paymentMethods?.preferred_bank_account?.id || '',
    },
    expedite_shipping: 'no',
    expedite_charged: true,
    phone_type: '',
    card_recipient_phone: '',
    address_attributes: getAddress(otherShippingDestinationDefault || {}),
  };

  const renderForm = (formik) => (
    <div style={{ fontSize: 16 }}>
      <span>{subheader()}</span>
      <br />
      <Form className="card_reissue_form" id="card-reissue-form">
        <br />
        <b style={{ fontSize: 20 }}>Please select where to send {name()}'s new True Link Card:</b>
        <br />
        <TrueLinkRadios
          ariaLabel="Which shipping address would you prefer"
          name="shipping_destination"
          noBorder
          onChange={formik.handleChange}
          options={shippingDestinationOptions}
          size="small"
          value={formik.values.shipping_destination}
        />
        <ErrorText name="shipping_destination" />
        <div style={{ marginLeft: 24 }}>{otherShippingDestinationInputs(formik)}</div>
        <br />
        <CardPaymentFormFields
          adminMobile={adminMobile}
          allowAutomatedPayment={allowAutomatedPayment}
          cardholderMobile={cardholderMobile}
          expeditedShipping={shippingSettings.expedited}
          handleChange={formik.handleChange}
          paymentMethods={paymentMethods}
          regularShipping={shippingSettings.regular}
          setFieldTouched={formik.setFieldTouched}
          setFieldValue={formik.setFieldValue}
          shippingFee={shippingFee}
          values={formik.values}
        />
        {!cardholderCountryOfCitizenship && (
          <CountryOfCitizenship
            boldLabel
            compactUI
            countryOfCitizenshipLabel={countryOfCitizenshipLabel}
            nonResidentAlienLabel={nonResidentAlienLabel}
            onChange={formik.handleChange}
            setFieldTouched={formik.setFieldTouched}
            values={formik.values}
          />
        )}
      </Form>
    </div>
  );

  const footer = (formik) => (
    <TrueLinkButton className="btn normal" onClick={formik.handleSubmit} variant="none">
      REQUEST REISSUE
    </TrueLinkButton>
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={requestCardReissue}
      validationSchema={validationSchema}
    >
      {(formik) => (
        <PopUp
          bodyHeight="48vh"
          footer={footer(formik)}
          header={header()}
          maxHeight="800px"
          maxWidth="800px"
          onClose={onClose}
          openModal
        >
          {renderForm(formik)}
        </PopUp>
      )}
    </Formik>
  );
}

ReportLostOrStolenModal.propTypes = {
  accountSlug: PropTypes.string.isRequired,
  allowAutomatedPayment: PropTypes.bool.isRequired,
  cardholderAddress: PropTypes.string,
  adminMobile: PropTypes.string,
  cardholderMobile: PropTypes.string,
  cardholderCountryOfCitizenship: PropTypes.string,
  cardholderName: PropTypes.string,
  disableSelectingLastKnownAddress: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  organizationAddress: PropTypes.string.isRequired,
  otherShippingDestinationDefault: addressShape,
  paymentMethods: PropTypes.object,
  shippingAddress: PropTypes.string.isRequired,
  shippingFee: PropTypes.string,
  shippingSettings: PropTypes.object,
};
