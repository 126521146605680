import _isArray from 'underscore/modules/isArray';
import _isEmpty from 'underscore/modules/isEmpty';
import _isString from 'underscore/modules/isString';
import _mapObject from 'underscore/modules/mapObject';
import _reduce from 'underscore/modules/reduce';
import DashboardActions from 'react/member/actions/dashboard_actions';
import tlFieldTransformers from 'react/shared/utils/tl_field_transformers';

const DashboardBudgetItemStore = flux.createStore({
  actions: [DashboardActions.createBudgetItem, DashboardActions.checkOverdraw],

  createBudgetItem(data, url) {
    return $.ajax({
      url,
      type: 'POST',
      data,
      dataType: 'json',
    })
      .done((budgetItem) => {
        this.emit('budgetItem.create', budgetItem);
      })
      .fail((response) => {
        this.emit('budgetItem.create.fail', response);
        // Do not flash a message here, the message will show from the API
      });
  },

  checkOverdraw(data, threeMonthInfo, id, canOverdraw) {
    let cash_balance;
    // Ideally this would be tighter controlled, but it is legacy
    // eslint-disable-next-line no-eq-null
    const nullSafeThreeMonthInfo = threeMonthInfo == null ? {} : threeMonthInfo;
    const overdrawError = {};
    if (!nullSafeThreeMonthInfo) {
      return overdrawError;
    }
    const type = data.budget_item_type;
    const value = _isString(data.amounts)
      ? Number.parseFloat(data.amounts)
      : Number.parseFloat(
          _reduce(data.amounts, (sum, amt) => sum + Number.parseFloat(amt), 0).toFixed(2),
        );
    const current_balance = Number.parseFloat(
      Number.parseFloat(nullSafeThreeMonthInfo.current_balance).toFixed(2),
    );
    const effective_balance = Number.parseFloat(
      Number.parseFloat(nullSafeThreeMonthInfo.effective_balance).toFixed(2),
    );
    if (nullSafeThreeMonthInfo.cash_balance) {
      cash_balance = Number.parseFloat(
        Number.parseFloat(nullSafeThreeMonthInfo.cash_balance).toFixed(2),
      );
    }

    if (type === 'one_time') {
      if (cash_balance) {
        if (value > cash_balance) {
          overdrawError.text = `This disbursement will overdraw the cash available in this account by ${tlFieldTransformers.formatMoney(
            cash_balance - value,
          )} and can not be submitted.`;
          overdrawError.urgent = true;
        }
      } else {
        if (canOverdraw) {
          if (value > current_balance) {
            overdrawError.text = `WARNING: Submitting this disbursement will overdraw the account balance by  ${tlFieldTransformers.formatMoney(
              current_balance - value,
            )}.`;
          } else if (value > effective_balance) {
            overdrawError.text = `This disbursement will overdraw the account's effective balance by ${tlFieldTransformers.formatMoney(
              effective_balance - value,
            )}`;
          }

          overdrawError.urgent = false;
          return this.emit('budgetItem.overdrawChecked', data, overdrawError);
        }

        if (value > current_balance) {
          overdrawError.text = `This disbursement will overdraw the account's balance by ${tlFieldTransformers.formatMoney(
            current_balance - value,
          )} and can not be submitted.`;
          overdrawError.urgent = true;
        } else if (value > effective_balance) {
          overdrawError.text = `This disbursement will overdraw the account's effective balance by ${tlFieldTransformers.formatMoney(
            effective_balance - value,
          )}`;
          overdrawError.urgent = false;
        }
      }
      return this.emit('budgetItem.overdrawChecked', data, overdrawError);
    } else if (type === 'recurring') {
      if (canOverdraw) {
        if (value > current_balance) {
          overdrawError.text = `WARNING: Submitting this disbursement will overdraw the account balance by ${tlFieldTransformers.formatMoney(
            current_balance - value,
          )}.`;
        } else if (value > effective_balance) {
          overdrawError.text = `This disbursement will overdraw the account's effective balance by ${tlFieldTransformers.formatMoney(
            effective_balance - value,
          )}`;
        }

        overdrawError.urgent = false;
        return this.emit('budgetItem.overdrawChecked', data, overdrawError);
      }
      // this code will only run if the case that !canOverdraw
      if (value > current_balance) {
        overdrawError.text = `The first disbursement will overdraw this account's balance by ${tlFieldTransformers.formatMoney(
          current_balance - value,
        )} and can not be submitted.`;
        overdrawError.urgent = true;
      }
      if (_isEmpty(overdrawError)) {
        return $.ajax({
          url: RailsRoutes.dashboard_client_calculate_draft_recurring_disbursement_total_path(id),
          data: this._flattenArrays(data),
          type: 'GET',
        }).done((response) => {
          if (response.total > effective_balance) {
            overdrawError.text = `This disbursement will overdraw this account's 3-month effective balance by ${tlFieldTransformers.formatMoney(
              effective_balance - response.total,
            )}`;
          }
          return this.emit('budgetItem.overdrawChecked', data, overdrawError);
        });
      }

      this.emit('budgetItem.overdrawChecked', data, overdrawError);
    }
  },

  // arrays in the params cause this specific endpoint to choke, so send them over in a string
  _flattenArrays(data) {
    return _mapObject(data, (item) => {
      if (_isArray(item)) {
        return item.toString();
      }
      return item;
    });
  },
});

export default DashboardBudgetItemStore;
