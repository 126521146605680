import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import UpdateCardholderAddressModal from './UpdateCardholderAddressModal';
import TrueLinkLink from 'react/shared/components/true_link/main/TrueLinkLink';
import addressShape from 'react/shared/shapes/address_shape';

const useStyles = makeStyles({
  edit: {
    paddingLeft: '15px',
  },
});

export default function CardholderAddress({ accountId, address }) {
  const classes = useStyles();
  const [isModalOpen, setModalOpen] = useState(false);

  const handleToggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  if (!address || !address.street1 || !address.city) {
    return <p>no address on file</p>;
  }

  return (
    <>
      <div>
        {`${address.street1} `}
        <span className={classes.edit}>
          <TrueLinkLink onClick={handleToggleModal}>Edit</TrueLinkLink>
          {isModalOpen && (
            <UpdateCardholderAddressModal accountId={accountId} toggleModal={handleToggleModal} />
          )}
        </span>
      </div>
      {address.street2 && <div>{address.street2}</div>}
      <div>{`${address.city}, ${address.state} ${address.zip}`}</div>
    </>
  );
}

CardholderAddress.propTypes = {
  accountId: PropTypes.string.isRequired,
  address: addressShape,
};
