import Chip from '@mui/material/Chip';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import TrueLinkIcon from 'react/shared/components/true_link/main/TrueLinkIcon';
import PALETTE from 'react/shared/theme/palette';

const useStyles = makeStyles({
  chip: {
    boxShadow: 'none',
    border: 'none',
    borderRadius: '6px',
    fontSize: '14px',
    fontWeight: '600',
    margin: '0 10px',
  },
  arrowIcon: {
    display: 'inline-block',
    paddingLeft: '4px',
  },
  primary: {
    background: PALETTE.emeraldLight,
    color: PALETTE.evergreen,
    '&:focus': {
      color: PALETTE.evergreen,
      'text-decoration': 'inherit',
      background: PALETTE.emeraldLight,
    },
    '&:hover': {
      background: PALETTE.emeraldLight,
      color: PALETTE.evergreen,
      'text-decoration': 'inherit',
    },
    '&:active': {
      'box-shadow': 'none',
    },
  },
  warning: {
    background: PALETTE.orangeLight,
    color: PALETTE.orange,
    '&:focus': {
      color: PALETTE.orange,
      'text-decoration': 'inherit',
      background: PALETTE.orangeLight,
    },
    '&:hover': {
      background: PALETTE.orangeLight,
      color: PALETTE.orange,
      'text-decoration': 'inherit',
    },
    '&:active': {
      'box-shadow': 'none',
    },
  },
  none: {},
});

export default function TrueLinkChip({ color, className, clickable, label, ...passThroughProps }) {
  const classes = useStyles();

  return (
    <Chip
      {...passThroughProps}
      className={classNames(classes[color], className, {
        [classes.chip]: color !== 'none',
        [classes.clickable]: clickable,
      })}
      clickable={clickable}
      label={
        <span>
          {label}
          {clickable && <TrueLinkIcon className={classes.arrowIcon} icon="long-arrow-alt-right" />}
        </span>
      }
    />
  );
}

export const sizeTypes = ['medium', 'small'];
export const colorTypes = ['primary', 'warning', 'none'];

TrueLinkChip.propTypes = {
  className: PropTypes.string,
  clickable: PropTypes.bool,
  label: PropTypes.string,
  size: PropTypes.oneOf(sizeTypes),
  color: PropTypes.oneOf(colorTypes).isRequired,
};
