import { createTheme } from '@mui/material/styles';
import PALETTE from 'react/shared/theme/palette';

const spacing = (value) => value * 6;
export const mediumBreakpointMediaQuery = '(min-width:900px)';
export const smallBreakpointMediaQuery = '(min-width:600px)';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      cd: 700,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    background: {
      default: PALETTE.white,
    },
    error: {
      main: PALETTE.red,
    },
    primary: {
      main: PALETTE.emerald,
      dark: PALETTE.evergreen,
    },
  },
  shape: {
    borderRadius: 6,
  },
  spacing,
  typography: {
    fontFamily: 'Calibre, Open Sans, Arial',
    fontSize: 16,
    h1: {
      fontWeight: 600,
      fontSize: '2.75rem', // 44px
      lineHeight: 1.2,
      letterSpacing: 0,
    },
    h2: {
      fontWeight: 600,
      fontSize: '2.25rem', // 36px
      lineHeight: 1.2,
      letterSpacing: 0,
    },
    h3: {
      fontWeight: 600,
      fontSize: '1.625rem', // 26px
      lineHeight: 1.3,
    },
    h4: {
      fontWeight: 600,
      fontSize: '1.5rem', // 24px
      lineHeight: 1.3,
    },
    h5: {
      fontWeight: 600,
      fontSize: '1.25rem', // 20px
      lineHeight: 1.3,
    },
    body1: {
      fontSize: '1.25rem', // $large 20px
      lineHeight: 1.3,
    },
    body2: {
      fontSize: '1.125rem', // $regular 18px
      lineHeight: 1.3,
    },
    caption: {
      fontSize: '1rem', // $small 16px
      lineHeight: 1.4,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          [`@media screen and ${mediumBreakpointMediaQuery}`]: {
            backgroundColor: '#F3F3F2',
          },
          fontSize: '1.125rem',
          fontFamily: 'Calibre, Open Sans, Arial',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        gutterBottom: {
          marginBottom: spacing(2),
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          border: `1px solid ${PALETTE.emerald}`,
          borderRadius: '4px',
          marginLeft: 0,
          marginRight: 0,
          marginBottom: spacing(2),
          '& .Mui-disabled': {
            '& .MuiSvgIcon-root': {
              fill: PALETTE.grey4,
            },
          },
          '&.no-border': {
            border: '0',
          },
        },
        label: {
          marginBottom: 0,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fill: PALETTE.emerald,
          '&.MuiSvgIcon-fontSizeSmall': {
            fontSize: '18px',
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          border: `1px solid ${PALETTE.grey4}`,
          borderRadius: spacing(1),
          '&:focus-within': {
            border: `1px solid ${PALETTE.emerald}`,
          },
          '& input[type="text"]': {
            border: 0,
            borderRadius: spacing(1),
            marginBottom: 0,
            '&:focus': {
              boxShadow: 'none',
            },
          },
          '& input[type="password"]': {
            border: 0,
            borderRadius: spacing(1),
            boxShadow: 'none',
            marginBottom: 0,
            '&:focus': {
              boxShadow: 'none',
            },
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          backgroundColor: PALETTE.white,
          '& input[type="text"]': {
            border: 0,
            borderRadius: spacing(1),
            marginBottom: 0,
            boxShadow: 'none',
            '&:focus': {
              boxShadow: 'none',
            },
          },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        positionEnd: {
          marginRight: '16px',
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          padding: '24px',
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        root: {
          '& .MuiStepConnector-line': {
            borderLeftStyle: 'dashed',
          },
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&.Mui-completed': {
            fill: PALETTE.evergreen,
          },
        },
      },
    },
  },
});

export default theme;
