import React from 'react';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';

export default function ViewButton(row) {
  return (
    <TrueLinkButton
      className="js-view-details"
      onClick={() => {
        row.toggleRowExpanded();
      }}
      size="small"
      variant="neutral"
    >
      {row.isExpanded ? 'Close' : 'View'}
    </TrueLinkButton>
  );
}
