import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import { asMoney } from 'react/shared/utils/Money';
import bindAll from 'react/shared/utils/bind_all';

const tableID = 'beneficiary-deposits-this-quarter-table';

export default class BeneficiaryDepositsThisQuarter extends React.Component {
  constructor(props) {
    super(props);

    bindAll(this);
  }

  componentDidMount() {
    $.extend($.fn.DataTable.ext.type.order, {
      'num-fmt-pre'(a) {
        const numberA = Number.parseInt(a.replace(/[^0-9+-]/g, ''), 10);
        return Number.isNaN(numberA) ? Number.MAX_VALUE : numberA;
      },
      'num-fmt-asc'(a, b) {
        if (a < b) {
          return -1;
        } else if (a > b) {
          return 1;
        }
        return 0;
      },
      'num-fmt-desc'(a, b) {
        if (a < b) {
          return 1;
        } else if (a > b) {
          return -1;
        }
        return 0;
      },

      'date-fmt-pre'(a) {
        return new Date(a);
      },
      'date-fmt-asc'(a, b) {
        if (a < b) {
          return -1;
        } else if (a > b) {
          return 1;
        }
        return 0;
      },
      'date-fmt-desc'(a, b) {
        if (a < b) {
          return 1;
        } else if (a > b) {
          return -1;
        }
        return 0;
      },
    });
    return $(`#${tableID}`).DataTable({
      order: [[0, 'desc']],
      columnDefs: [
        { targets: [2], type: 'num-fmt' },
        { targets: [0], type: 'date-fmt' },
      ],
    });
  }

  renderDepositTotal() {
    const total = this.props.deposits.reduce((adder, deposit) => adder + Number(deposit.amount), 0);
    return <span>Total: {asMoney(total)}</span>;
  }

  renderDepositRows() {
    return this.props.deposits.map((deposit) => (
      <tr key={deposit.id}>
        <td>{moment(deposit.delivery_date).format('L')}</td>
        <td>{deposit.description}</td>
        <td style={{ textAlign: 'right' }}>{asMoney(Number(deposit.amount))}</td>
      </tr>
    ));
  }

  render() {
    return (
      <div>
        <div className="sub_section_header">
          <div style={{ float: 'left' }}>Deposits this quarter</div>
          <div style={{ float: 'right' }}>{this.renderDepositTotal()}</div>
          <div style={{ clear: 'both' }} />
        </div>
        <table id={tableID}>
          <thead>
            <tr>
              <th style={{ textAlign: 'left' }}>Process Date</th>
              <th style={{ textAlign: 'left' }}>Description</th>
              <th style={{ textAlign: 'right' }}>Amount&nbsp;&nbsp;&nbsp;</th>
            </tr>
          </thead>
          <tbody>{this.renderDepositRows()}</tbody>
        </table>
      </div>
    );
  }
}

BeneficiaryDepositsThisQuarter.propTypes = {
  deposits: PropTypes.array.isRequired,
};
