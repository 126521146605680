import React from 'react';
import TrueLinkBanner from './TrueLinkBanner';
import GiftSvg from 'images/referrals/gift.svg';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';

export default {
  title: 'Standard/Lab/Banner',
  component: TrueLinkBanner,
  argTypes: {
    dismissable: {
      control: 'boolean',
      table: {
        category: 'Contents',
      },
    },
    icon: {
      control: 'text',
      table: {
        category: 'Contents',
      },
    },
    title: {
      control: 'text',
      table: {
        category: 'Contents',
      },
    },
    text: {
      control: 'text',
      table: {
        category: 'Contents',
      },
    },
    callToAction: {
      table: {
        category: 'Contents',
      },
    },
    className: {
      control: 'text',
      table: {
        category: 'Style',
      },
    },
  },
};

function Template(args) {
  return (
    <div>
      <TrueLinkBanner {...args} />
    </div>
  );
}

export const Neutral = Template.bind({});
Neutral.args = {
  text: 'The most minimal Information banner, is text in a colored background',
  variant: 'neutral',
};

export const Information = Template.bind({});
Information.args = {
  text: 'The most minimal Information banner, is text in a colored background',
  variant: 'info',
};

export const Warning = Template.bind({});
Warning.args = {
  text: 'The most minimal Information banner, is text in a colored background',
  variant: 'warning',
};

export const Error = Template.bind({});
Error.args = {
  text: 'The most minimal Information banner, is text in a colored background',
  variant: 'error',
};

export const Icon = Template.bind({});
Icon.args = {
  icon: 'triangle-exclamation',
  text: 'Sometimes we show icons',
  variant: 'warning',
};

export const Dismissable = Template.bind({});
Dismissable.args = {
  title: 'Dismiss me',
  text: 'This has some text showing and the ability to get rid of it. This is often available for temporary messages that should appear sometimes but are not needing interaction before they have to be resolved. Usually it will be for informational purposes.',
  variant: 'error',
  dismissable: true,
};

export const SVG = Template.bind({});
SVG.args = {
  title: 'Share True Link and receive 3 months of monthly fees on us*',
  text: `Do you know someone who could benefit from True Link? We'll waive* 3 months of True Link’s monthly fees for both of you when they activate their True Link Visa® Prepaid Card.`,
  svg: GiftSvg,
  callToAction: (
    <div>
      <TrueLinkButton variant="primary">Share now</TrueLinkButton>
    </div>
  ),
  variant: 'promo',
};
export const AllTheThingsAtOnce = Template.bind({});
AllTheThingsAtOnce.args = {
  title: 'Dismiss me',
  text: 'This has some text showing and the ability to get rid of it. This is often available for temporary messages that should appear sometimes but are not needing interaction before they have to be resolved. Usually it will be for informational purposes.',
  icon: 'circle-xmark',
  callToAction: (
    <div>
      <TrueLinkButton style={{ margin: '6px' }} variant="primaryOutline">
        Other
      </TrueLinkButton>
      <TrueLinkButton variant="primary">Primary</TrueLinkButton>
    </div>
  ),
  variant: 'error',
  dismissable: true,
};

export const InfoWithButton = Template.bind({});
InfoWithButton.args = {
  title: 'Info with button',
  text: 'Please note that buttons within banners should have a size prop of small. ',
  callToAction: (
    <TrueLinkButton size="small" variant="infoOutline">
      Primary
    </TrueLinkButton>
  ),
  variant: 'info',
};

export const TextWithLink = Template.bind({});
TextWithLink.args = {
  title: 'Text with link',
  text: (
    <>
      This is a banner with link <a href="">click here</a>.
    </>
  ),
  callToAction: (
    <TrueLinkButton size="small" variant="infoOutline">
      Primary
    </TrueLinkButton>
  ),
  variant: 'info',
};
