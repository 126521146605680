import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import TrueLinkIcon from 'react/shared/components/true_link/main/TrueLinkIcon';
import theme, { mediumBreakpointMediaQuery } from 'react/shared/theme/Theme';
import PALETTE from 'react/shared/theme/palette';

const useStyles = makeStyles(() => ({
  prevPage: {
    background: 'transparent',
    border: 'none',
    color: PALETTE.emerald,
    marginBottom: theme.spacing(1),
    minHeight: '23px',
    opacity: '100%',
    position: 'absolute',
    top: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      color: 'inherit',
      float: 'left',
      opacity: '65%',
      position: 'static',
    },
  },
  prevPageNoMobile: {
    background: 'transparent',
    border: 'none',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    color: 'inherit',
    opacity: '65%',
    position: 'static',
    marginBottom: theme.spacing(1),
  },
  chevron: {
    fontSize: '1.5rem',
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      fontSize: '0.75rem',
      marginTop: 0,
      marginRight: theme.spacing(1),
    },
  },
  chevronNoMobile: {
    fontSize: '0.75rem',
    marginTop: 0,
    marginRight: theme.spacing(1),
  },
}));

export default function PreviousPageButton({ desktopOnly, label = 'Back', onBack }) {
  const classes = useStyles();
  const desktop = useMediaQuery(mediumBreakpointMediaQuery);

  return (
    <TrueLinkButton
      aria-label={label}
      className={desktopOnly ? classes.prevPageNoMobile : classes.prevPage}
      onClick={onBack}
      variant="none"
    >
      <TrueLinkIcon
        className={desktopOnly ? classes.chevronNoMobile : classes.chevron}
        icon="chevron-left"
      />
      {(desktopOnly || desktop) && label}
    </TrueLinkButton>
  );
}

PreviousPageButton.propTypes = {
  desktopOnly: PropTypes.bool,
  label: PropTypes.string,
  onBack: PropTypes.func,
};

PreviousPageButton.defaultProps = {
  desktopOnly: false,
};
