import PropTypes from 'prop-types';
import React from 'react';
import AccountNumberInput from './AccountNumberInput';
import AddressInputs from './AddressInputs';
import BeneAddressInputs from './BeneAddressInputs';
import PhoneDob from './PhoneDob';
import { requiredAddressShape } from './Shapes';
import NameInputsWithLimit from 'react/member/components/common/NameInputsWithLimit';
import programNameShape from 'react/shared/shapes/account_program_name_shape';

export default function AlrCardOrderSection({
  administratorAddress,
  beneficiaryAddress,
  onAddressInputChange,
  programName,
  alrCardChoice,
}) {
  return (
    <div>
      <input name="administrator[is_alr]" type="hidden" value="yes" />
      <input name="administrator[alr_card_choice]" type="hidden" value={alrCardChoice} />

      <div className="card--form__section">
        <NameInputsWithLimit
          inputNamePrefix="administrator"
          labelPrefix="ALR"
          lengthLimit={23}
          warningFullNameOwnerPrefix="ALR"
          warningNameLinePrefix="ALR"
        />
        <AddressInputs
          address={administratorAddress}
          labelPrefix="ALR"
          namePrefix="administrator"
          onInputChange={onAddressInputChange}
        />
        <PhoneDob labelPrefix="ALR" />
      </div>

      <div className="card--form__section">
        <NameInputsWithLimit
          inputNamePrefix="beneficiary"
          labelPrefix="Beneficiary"
          lengthLimit={19}
          warningFullNameOwnerPrefix="beneficiary"
          warningNameLinePrefix="beneficiary's"
        />
        <AccountNumberInput programName={programName} />
      </div>
      {alrCardChoice !== 'alr_only' && (
        <BeneAddressInputs
          administratorAddress={administratorAddress}
          beneficiaryAddress={beneficiaryAddress}
          onAddressInputChange={onAddressInputChange}
        />
      )}
    </div>
  );
}

AlrCardOrderSection.propTypes = {
  administratorAddress: requiredAddressShape.isRequired,
  beneficiaryAddress: requiredAddressShape.isRequired,
  onAddressInputChange: PropTypes.func.isRequired,
  programName: programNameShape.isRequired,
  alrCardChoice: PropTypes.string.isRequired,
};
