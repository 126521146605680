export const MODULES = {
  SURVEY: 'survey',
  CREDENTIALS: 'credentials',
  ADMIN: 'admin',
  IDV: 'idv',
  CARD_ORDER: 'cardOrder',
  SHIPPING: 'shipping',
  REVIEW: 'review',
  TRUST: 'trust',
};

export const PSEUDO_MODULES = {
  CARDHOLDER_PROFILE: 'cardholderProfile',
};

export const USER_TYPES = {
  CLIENT: 'client',
  FAMILY: 'family',
  MYSELF: 'myself',
};
