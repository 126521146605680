import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import AccountInfoTabContainer from 'react/member/card/components/account_tab/AccountInfoTabContainer';
import CardAlertsTabContainer from 'react/member/card/components/alerts_tab/CardAlertsTabContainer';
import CardFundingTabContainer from 'react/member/card/components/funding_tab/CardFundingTabContainer';
import CardReportsTabContainer from 'react/member/card/components/reports_tab/CardReportsTabContainer';
import CardSpendingMonitorTabContainer from 'react/member/card/components/spending_monitor_tab/CardSpendingMonitorTabContainer';
import SpendingSettingsSdkContainer from 'react/member/card/components/spending_monitor_tab/SpendingSettingsSdkContainer';
import CardTransactionsTabContainer from 'react/member/card/components/transactions_tab/CardTransactionsTabContainer';
import InProductAdShape from 'react/member/shapes/in_product_ad_shape';
import theme from 'react/shared/theme/Theme';
import PALETTE from 'react/shared/theme/palette';

const useStyles = makeStyles({
  tabsDivider: {
    outline: `1px solid ${PALETTE.grey5}`,
  },
  tabsContainer: {
    maxWidth: '940px',
    margin: '0 auto',
  },
  header: {
    borderBottom: '1px solid #ccc',

    '& .MuiTabs-scrollButtons.Mui-disabled': {
      display: 'none',
    },
  },
  customTab: {
    marginTop: '10px',
    minWidth: '130px',
    textTransform: 'capitalize',
    fontSize: '18px',
  },
});

export default function DashboardTabs(props) {
  const classes = useStyles();

  const tabs = [
    { id: 'transactions_tab_link', label: 'Transactions', display: props.displayTransactionsTab },
    { id: 'funding_tab_link', label: 'Funding', display: props.displayFundingTab },
    {
      id: 'spending_monitor_tab_link',
      label: 'Spending Settings',
      display: props.displaySpendingMonitorTab,
    },
    {
      id: 'spending_settings_sdk_link',
      label: 'Spending Settings SDK',
      display: $tlf.flipperEnableSpendingSettingsSdkFlag && props.cardReference,
    },
    { id: 'alerts_tab_link', label: 'Alerts', display: props.displayAlertsTab },
    { id: 'reports_tab_link', label: 'Reports', display: props.displayReportsTab },
    { id: 'account_info_tab_link', label: 'Account', display: props.displayAccountInfoTab },
  ];

  const firstVisibleTab = tabs.find((tab) => tab.display === true);

  const [activeTab, setActiveTab] = useState(firstVisibleTab?.id);

  useEffect(() => {
    let hash = window.location.hash.slice(1);
    if (hash == 'report_lost_or_stolen_modal') {
      hash = 'account_info_tab_link';
    }
    if (!hash.endsWith('_link')) {
      hash += '_link';
    }
    if (tabs.some((tab) => tab.id === hash && tab.display)) {
      setActiveTab(hash);
    }

    if (Cookies.get('activate_card_modal')) {
      $('button#account_info_tab_link').trigger('click');
      window.setTimeout(() => $('#activate-card-button').trigger('click'), 2000);
      Cookies.remove('activate_card_modal');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <div className={classes.tabsDivider} />
        <div className={classes.tabsContainer}>
          <TabContext value={activeTab}>
            <Tabs
              allowScrollButtonsMobile
              className={classes.header}
              id="dashboard-nav-tabs"
              indicatorColor="primary"
              onChange={handleTabChange}
              scrollButtons
              textColor="primary"
              value={activeTab}
              variant="scrollable"
            >
              {tabs.map(
                (tab) =>
                  tab.display && (
                    <Tab
                      className={classes.customTab}
                      id={tab.id}
                      key={tab.id}
                      label={tab.label}
                      value={tab.id}
                    />
                  ),
              )}
            </Tabs>

            <TabPanel value="transactions_tab_link">
              <CardTransactionsTabContainer
                accountId={props.accountId}
                inProductAd={props.inProductAdTransactionsTab}
                ruleSetId={`${props.ruleSetId}`}
              />
            </TabPanel>
            <TabPanel value="funding_tab_link">
              <CardFundingTabContainer
                accountId={props.accountId}
                card={props.card}
                copyableBankAccounts={props.copyableBankAccounts}
                isFamilyFunder={props.isFamilyFunder}
                isViewOnly={props.isViewOnly}
                mockPublicToken={props.mockPublicToken}
              />
            </TabPanel>
            <TabPanel value="spending_monitor_tab_link">
              <CardSpendingMonitorTabContainer
                accountId={props.accountId}
                canEdit={props.canEdit}
                cardReference={props.cardReference}
                scope={props.scope}
              />
            </TabPanel>
            <TabPanel value="spending_settings_sdk_link">
              <SpendingSettingsSdkContainer
                accountId={props.accountId}
                canEdit={props.canEdit}
                showTestingToggles
              />
            </TabPanel>
            <TabPanel value="alerts_tab_link">
              <CardAlertsTabContainer accountId={props.accountId} canEdit={props.canEdit} />
            </TabPanel>
            <TabPanel value="reports_tab_link">
              <CardReportsTabContainer
                accountId={props.accountId}
                canEdit={props.canEdit}
                inProductAd={props.inProductAdReportsTab}
                monthlyStatementDates={props.monthlyStatementDates}
              />
            </TabPanel>
            <TabPanel value="account_info_tab_link">
              <AccountInfoTabContainer
                ableProgram={props.ableProgram}
                accountFeeSettings={props.accountFeeSettings}
                accountId={props.accountId}
                bankAccountNumber={props.bankAccountNumber}
                cardActivated={props.cardActivated}
                cardBalance={props.cardBalance}
                cardLast4={props.cardLast4}
                cardOpen={props.cardOpen}
                cardPresent={props.cardPresent}
                cardProgramPresent={props.cardProgramPresent}
                cardholderName={props.cardholderName}
                dischargeFamilyFunderAllowed={props.dischargeFamilyFunderAllowed}
                familyFunders={props.familyFunders}
                familyFundingAllowed={props.familyFundingAllowed}
                filteredBankAccounts={props.filteredBankAccounts}
                isNextStepProgram={props.isNextStepProgram}
                nonDeletedCardExists={props.nonDeletedCardExists}
                notAbleActProgram={props.notAbleActProgram}
                pseudoDda={props.pseudoDda}
                routingNumber={props.routingNumber}
                stableProgram={props.stableProgram}
                subOrgId={props.subOrgId}
              />
            </TabPanel>
          </TabContext>
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

DashboardTabs.propTypes = {
  ableProgram: PropTypes.bool,
  accountFeeSettings: PropTypes.any,
  accountId: PropTypes.string,
  bankAccountNumber: PropTypes.string,
  canEdit: PropTypes.bool,
  card: PropTypes.any,
  cardActivated: PropTypes.bool,
  cardBalance: PropTypes.string,
  cardholderName: PropTypes.string,
  cardLast4: PropTypes.string,
  cardOpen: PropTypes.bool,
  cardPresent: PropTypes.bool,
  cardReference: PropTypes.string,
  cardProgramPresent: PropTypes.bool,
  copyableBankAccounts: PropTypes.array,
  dischargeFamilyFunderAllowed: PropTypes.bool,
  displayAccountInfoTab: PropTypes.bool,
  displayAlertsTab: PropTypes.bool,
  displayFundingTab: PropTypes.bool,
  displayReportsTab: PropTypes.bool,
  displaySpendingMonitorTab: PropTypes.bool,
  displayTransactionsTab: PropTypes.bool,
  familyFunders: PropTypes.array,
  familyFundingAllowed: PropTypes.bool,
  filteredBankAccounts: PropTypes.any,
  inProductAdReportsTab: InProductAdShape,
  inProductAdTransactionsTab: InProductAdShape,
  isFamilyFunder: PropTypes.bool,
  isNextStepProgram: PropTypes.bool,
  isViewOnly: PropTypes.bool,
  mockPublicToken: PropTypes.string,
  monthlyStatementDates: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      card_slug: PropTypes.string.isRequired,
      last_4_digits: PropTypes.string.isRequired,
    }).isRequired,
  ),
  nonDeletedCardExists: PropTypes.bool,
  notAbleActProgram: PropTypes.bool,
  pseudoDda: PropTypes.string,
  routingNumber: PropTypes.string,
  ruleSetId: PropTypes.number,
  scope: PropTypes.string,
  stableProgram: PropTypes.bool,
  subOrgId: PropTypes.number,
};
