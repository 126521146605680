import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import ModalWarningIcon from './ModalWarningIcon';
import HolidayImpactedTransfersTables from './holiday_impacted_transfers_table/HolidayImpactedTransfersTables';
import { holidayShape } from 'react/member/card/shapes/HolidayShape';
import { impactedTransferShape } from 'react/member/card/shapes/TransferShape';
import TrueLinkModal from 'react/shared/components/true_link/lab/TrueLinkModal';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import PALETTE from 'react/shared/theme/palette';

const useStyles = makeStyles({
  modal: {
    width: 'auto',
    borderRadius: '6px !important',
    '& .modal-content': {
      backgroundColor: PALETTE.evergreen,
    },
    '& .modal-header': {
      backgroundColor: PALETTE.evergreen,
      color: '#FFF',
      padding: '1em 1.5em',
    },
    '& .modal-title': {
      fontSize: '18px',
      fontWeight: '700',
      display: 'flex',
      alignItems: 'center',
    },
  },
  note: {
    marginBottom: '15px',
  },
  remindMeButton: {
    marginLeft: '8px',
  },
});

export default function HolidayImpactedTransfersModal({
  canEdit,
  delayedCount,
  holiday,
  impactedTransfersByDate,
  onClose,
  onSubmit,
  submitting,
}) {
  // Hooks
  // -----------------------------------------------------
  const classes = useStyles();

  // Transfer guidance values across multiple tables, to be submitted for update
  // {
  //    [transfer1.id]: true
  //    [transfer2.id]: false
  // }
  const [changedGuidancesById, setChangedGuidancesById] = useState({});

  // Transfer guidance values across multiple tables, flattened to derive count
  // {
  //    [transfer1.id]: true
  //    [transfer2.id]: false
  // }
  const [totalGuidanceById, setTotalGuidanceById] = useState(() => {
    let flattenedGuidance = {};
    for (const date in impactedTransfersByDate) {
      if (Object.prototype.hasOwnProperty.call(impactedTransfersByDate, date)) {
        flattenedGuidance = { ...flattenedGuidance, ...impactedTransfersByDate[date].guidance };
      }
    }
    return flattenedGuidance;
  });

  // Display value used by submit button across multiple tables
  const totalAcceptCount = useMemo(
    () => Object.values(totalGuidanceById).filter((bool) => bool).length,
    [totalGuidanceById],
  );

  // Handlers
  // -----------------------------------------------------
  const memoizedHandleSelectGuidance = useCallback((rowState) => {
    const rows = Object.values(rowState);
    const newBulkGuidanceById = rows.reduce(
      (memo, row) => ({
        ...memo,
        [row.transferId]: row.guidanceAccepted,
      }),
      {},
    );
    setChangedGuidancesById((prevState) => ({ ...prevState, ...newBulkGuidanceById }));
    setTotalGuidanceById((prevState) => ({ ...prevState, ...newBulkGuidanceById }));
  }, []);

  const handleSubmitGuidance = (ev) => {
    ev.preventDefault();
    const toAccept = [];
    const toReject = [];
    for (const transferId in changedGuidancesById) {
      if (Object.prototype.hasOwnProperty.call(changedGuidancesById, transferId)) {
        changedGuidancesById[transferId] ? toAccept.push(transferId) : toReject.push(transferId);
      }
    }
    onSubmit([
      { guidance_accepted: 'true', transfer_ids: toAccept },
      { guidance_accepted: 'false', transfer_ids: toReject },
    ]);
  };

  const flip = moment(holiday.flipDate, 'YYYY-MM-DD');
  const isBeforeFlipDay = moment().isBefore(flip, 'day');
  const isEditable = canEdit && isBeforeFlipDay;

  const title = `${delayedCount} ${pluralize('transfer', delayedCount)} will be delayed by the ${
    holiday.name
  } holiday`;

  const submitText =
    totalAcceptCount === 0
      ? 'Leave transfers as is'
      : `Adjust ${totalAcceptCount} ${pluralize('Transfer', totalAcceptCount)}`;

  const closeButtonText = isEditable ? 'Remind me later' : 'Close';

  return (
    <TrueLinkModal
      modalProps={{ className: classes.modal, maxWidth: 'lg' }}
      title={
        <>
          <ModalWarningIcon />
          {title}
        </>
      }
    >
      <HolidayImpactedTransfersTables
        canEdit={isEditable}
        impactedTransfersByDate={impactedTransfersByDate}
        onSelectGuidance={memoizedHandleSelectGuidance}
      />
      {totalAcceptCount > 0 && (
        <div className={classes.note}>
          Note: Adjusted transfers will initiate 1-2 days earlier than scheduled
        </div>
      )}
      {isEditable && (
        <TrueLinkButton disabled={submitting} onClick={handleSubmitGuidance} variant="primary">
          {submitText}
        </TrueLinkButton>
      )}
      {!isBeforeFlipDay && <p>These transfers can no longer be adjusted.</p>}
      <TrueLinkButton className={classes.remindMeButton} onClick={onClose} variant="primaryOutline">
        {closeButtonText}
      </TrueLinkButton>
    </TrueLinkModal>
  );
}

HolidayImpactedTransfersModal.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  delayedCount: PropTypes.number.isRequired,
  holiday: holidayShape.isRequired,
  impactedTransfersByDate: PropTypes.shape({
    guidance: PropTypes.objectOf(PropTypes.bool),
    transfers: PropTypes.arrayOf(impactedTransferShape),
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
};

HolidayImpactedTransfersModal.defaultProps = {
  submitting: false,
};
