function getField(place, fieldName, shortName = false) {
  const piece = place?.address_components?.find((component) => component.types.includes(fieldName));
  return (shortName ? piece?.short_name : piece?.long_name) || '';
}

export function translatePlaceToAddress(place) {
  let street1 = getField(place, 'street_number');
  const streetName = getField(place, 'route');
  if (streetName) {
    street1 += ` ${streetName}`;
  }
  const translated = {
    street1,
    city: getField(place, 'locality') || getField(place, 'sublocality_level_1'),
    state: getField(place, 'administrative_area_level_1', true),
    zip: getField(place, 'postal_code'),
  };
  return translated;
}
