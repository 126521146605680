import PropTypes from 'prop-types';
import userAccessInvitationShape from './UserAccessInvitationShape';

const { string, bool, number, shape } = PropTypes;

export const connectionShape = shape({
  id: number,
  person: shape({
    firstName: string,
    lastName: string,
    mobile: string,
  }),
  address: shape({
    street1: string,
    street2: string,
    city: string,
    state: string,
    zip: string,
  }),
  email: string,
  connectionRelationship: shape({
    id: number,
    professional: string,
    social: string,
  }),
  notes: string,
  isCommon: bool.isRequired,
  userAccessInvitation: userAccessInvitationShape,
  companyName: string,
});

export default connectionShape;
