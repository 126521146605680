import { FastField, Field } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import ReactInputMask from 'react-input-mask';

function TextField({
  id,
  name,
  mask,
  type,
  autoComplete,
  disabled,
  readOnly,
  onChangeCallback,
  fast,
  style,
  dataAnalyticsKey,
}) {
  const FieldComponent = fast ? FastField : Field;

  const inputProps = {
    id,
    type: type || 'text',
    autoComplete,
    disabled,
    readOnly,
    'data-analytics-key': dataAnalyticsKey,
  };

  return (
    <FieldComponent name={name}>
      {({ field, form }) =>
        mask ? (
          <ReactInputMask
            {...inputProps}
            mask={mask}
            name={field.name}
            onBlur={(_event) => form.setFieldTouched(field.name, true)}
            onChange={(event) => {
              form.setFieldValue(field.name, event.currentTarget.value);
              onChangeCallback && onChangeCallback(event.currentTarget.value, field.name, form);
            }}
            value={field.value}
          />
        ) : (
          <input
            {...inputProps}
            name={field.name}
            onBlur={(_event) => form.setFieldTouched(field.name, true)}
            onChange={(event) => {
              form.setFieldValue(field.name, event.currentTarget.value);
              onChangeCallback && onChangeCallback(event.currentTarget.value, field.name, form);
            }}
            style={style}
            value={field.value}
          />
        )
      }
    </FieldComponent>
  );
}

TextField.propTypes = {
  dataAnalyticsKey: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  mask: PropTypes.string,
  type: PropTypes.string,
  autoComplete: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  onChangeCallback: PropTypes.func,
  fast: PropTypes.bool,
  style: PropTypes.object,
};

TextField.defaultProps = {
  mask: null,
  type: null,
  autoComplete: '',
  disabled: false,
  readOnly: false,
  fast: true,
};

export default TextField;
