import PropTypes from 'prop-types';
import React from 'react';
import DashboardActions from 'react/member/actions/dashboard_actions';
import AttachmentsContainer from 'react/member/components/attachments/drop_zone_uploader/AttachmentsContainer';
import userRoleShape from 'react/member/shapes/UserRoleShape';
import DashboardDocumentStore from 'react/member/stores/beneficiary_dashboard/DashboardDocumentStore';
import ToggleSectionHeader from 'react/shared/components/ToggleSectionHeader';
import bindAll from 'react/shared/utils/bind_all';

export default class DashboardTrustBeneficiaryProfileDocuments extends React.Component {
  constructor(props) {
    super(props);
    bindAll(this);
  }

  render() {
    const { trustBeneficiary } = this.props;
    const canEdit = !this.props.userRoles.viewOnly;
    return (
      <div className="toggle-section">
        <ToggleSectionHeader expanded title="Documents" />
        <div className="documents-section-content">
          <AttachmentsContainer
            attachable={trustBeneficiary}
            attachableType="TrustBeneficiary"
            canEdit={canEdit}
            category={'profile'}
            createFolderAction={DashboardActions.createClientFolder}
            destroyFileAction={DashboardActions.destroyClientDocument}
            destroyFolderAction={DashboardActions.destroyClientFolder}
            dropzoneSuccessAction={DashboardActions.dropzoneClientDocumentUploadSuccess}
            fetchFilesAndFoldersAction={DashboardActions.fetchClientDocumentsAndFolders}
            fileType="document"
            renameFolderAction={DashboardActions.updateClientFolder}
            store={DashboardDocumentStore}
            updateFileAction={DashboardActions.updateClientDocument}
          />
        </div>
      </div>
    );
  }
}
DashboardTrustBeneficiaryProfileDocuments.propTypes = {
  trustBeneficiary: PropTypes.shape({
    id: PropTypes.number.isRequired,
    slug: PropTypes.string.isRequired,
    trust: PropTypes.shape({
      organization: PropTypes.shape({}),
    }).isRequired,
  }).isRequired,
  userRoles: userRoleShape.isRequired,
};
