import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import DatePicker from 'react/shared/components/DatePicker';

export default function CompletedTransfersReportForm({
  dateYesterday,
  downloadCompletedTransfersReport,
}) {
  return (
    <DatePicker
      action="Download as Spreadsheet"
      endDate={dateYesterday}
      maxDate={dateYesterday}
      onSubmit={downloadCompletedTransfersReport}
      startDate={dateYesterday}
    />
  );
}

CompletedTransfersReportForm.propTypes = {
  dateYesterday: PropTypes.any,
  downloadCompletedTransfersReport: PropTypes.func.isRequired,
};

CompletedTransfersReportForm.defaultProps = {
  dateYesterday: moment().subtract(1, 'day').toDate(),
};
