import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles({
  statusLabel: {
    backgroundColor: '#D4EFDB',
    borderRadius: '4px',
    color: '#27AE60',
    flex: '0 0 auto',
    fontSize: '12px',
    fontWeight: 700,
    padding: '0 15px',
  },
});

export default function EmergencyFundedLabel(_props) {
  const classes = useStyles();
  return <div className={classes.statusLabel}>EMERGENCY FUNDED</div>;
}
