import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import _identity from 'underscore/modules/identity';
import _sortBy from 'underscore/modules/sortBy';
import HolidayImpactedTransfersTable from './HolidayImpactedTransfersTable';
import { impactedTransferShape } from 'react/member/card/shapes/TransferShape';

const useStyles = makeStyles({
  table: {
    margin: '50px 0 30px',
  },
});

export default function HolidayImpactedTransfersTables({
  canEdit,
  impactedTransfersByDate,
  onSelectGuidance,
}) {
  const classes = useStyles();
  const orderedDates = useMemo(
    () => _sortBy(Object.keys(impactedTransfersByDate), _identity),
    [impactedTransfersByDate],
  );

  return (
    <>
      {orderedDates.map((date, idx) => (
        <div
          className={classNames({ [classes.table]: idx !== 0 })}
          key={`holiday-impacted-transfers-table-${date}`}
        >
          <HolidayImpactedTransfersTable
            canEdit={canEdit}
            date={date}
            impactedTransfers={impactedTransfersByDate[date]}
            onSelectGuidance={onSelectGuidance}
            withSelectAll={canEdit}
          />
        </div>
      ))}
    </>
  );
}

HolidayImpactedTransfersTables.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  impactedTransfersByDate: PropTypes.shape({
    guidance: PropTypes.objectOf(PropTypes.bool),
    transfers: PropTypes.arrayOf(impactedTransferShape),
  }).isRequired,
  onSelectGuidance: PropTypes.func.isRequired,
};
