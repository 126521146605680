/* eslint react/no-multi-comp: off */
import Typography from '@mui/material/Typography';
import React from 'react';
import TrueLinkButton, { buttonSizes, buttonVariants } from './TrueLinkButton';
import { camelToTitle } from 'react/shared/utils/Strings';

export default {
  title: 'Standard/Button',
  component: TrueLinkButton,
  args: {
    size: 'regular',
  },
  argTypes: {
    children: {
      control: 'text',
      table: {
        category: 'Contents',
      },
    },
    disabled: {
      control: 'boolean',
      table: {
        category: 'Style',
      },
    },
    loading: {
      control: 'boolean',
      table: {
        category: 'Contents',
      },
    },
    className: {
      control: 'text',
      table: {
        category: 'Style',
      },
    },
    onClick: {
      name: 'On Click Handler',
      description: 'Handler for click events',
      table: {
        category: 'Events',
      },
    },
    size: {
      options: buttonSizes,
      control: { type: 'select' },
      table: {
        category: 'Style',
      },
    },
    variant: {
      options: buttonVariants,
      control: { type: 'select' },
      table: {
        category: 'Style',
      },
    },
  },
};

function Template(args) {
  return (
    <div>
      <TrueLinkButton {...args} />
    </div>
  );
}

export function Variants(args) {
  const text = args.children || 'Button Text';
  return (
    <span style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
      {buttonVariants.map((variant) => (
        <span key={variant} style={{ margin: '10px' }}>
          <Typography>{camelToTitle(variant)}</Typography>
          {buttonSizes.map((size) => (
            <div key={size} style={{ margin: '10px' }}>
              <TrueLinkButton {...args} size={size} variant={variant}>
                {text}
              </TrueLinkButton>
            </div>
          ))}
        </span>
      ))}
    </span>
  );
}

export const Loading = Template.bind({});
Loading.args = {
  variant: 'primary',
  size: 'regular',
  children: 'Button Text',
  loading: true,
};
