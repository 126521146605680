import PropTypes from 'prop-types';
import React from 'react';
import GenericSubmitPopUp from './GenericSubmitPopUp';
import genericSubmitPopUpShapeKeys from 'react/shared/shapes/GenericSubmitPopUpShape';

export default class AreYouSurePopUp extends React.Component {
  getPopUpProps() {
    const popupProps = this.props.popupProps || {};

    return {
      maxWidth: '440px',
      paddingTop: '165px',
      ...popupProps,
    };
  }

  getFooterProps() {
    return { id: 'are_you_sure', className: 'btn btn-default normal' };
  }

  render() {
    const { openModal, ...genericSubmitPopUpProps } = this.props;

    if (!openModal) return null;

    return (
      <GenericSubmitPopUp
        {...genericSubmitPopUpProps}
        popupProps={this.getPopUpProps()}
        submitButtonProps={this.getFooterProps()}
      />
    );
  }
}

AreYouSurePopUp.propTypes = {
  ...genericSubmitPopUpShapeKeys,
  onClose: PropTypes.func.isRequired,
  headerText: PropTypes.string,
  submitText: PropTypes.string,
  openModal: PropTypes.bool,
};

AreYouSurePopUp.defaultProps = {
  openModal: false,
  headerText: 'Are you sure?',
  submitText: 'Confirm',
  dismissBtnText: 'No, cancel',
  onSubmit: () => {
    // Do nothing by default
  },
};
