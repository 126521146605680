import PropTypes from 'prop-types';
import React from 'react';
import BeneficiaryDisbursementsTable from './BeneficiaryDisbursementsTable';

function BeneficiaryDisbursements({ clientId }) {
  return (
    <div>
      <div className="sub_section_header" style={{ borderBottom: 'none' }}>
        <div style={{ float: 'left' }}>Disbursements</div>
        <div style={{ clear: 'both' }} />
      </div>
      <BeneficiaryDisbursementsTable clientId={clientId} />
      <div className="disbursement-descriptions">
        <p>
          Each of the disbursements listed above has a status. Here’s what the different statuses
          mean:
        </p>
        <ul>
          <li>
            <strong>Requested: </strong>
            <span>
              This disbursement request has been received and is currently being reviewed.
            </span>
          </li>
          <li>
            <strong>Approved: </strong>
            <span>
              This disbursement has been approved and will be processed on the next business day
              (e.g., if it is approved on Monday, it will be processed on Tuesday).
            </span>
          </li>
          <li>
            <strong>Processed: </strong>
            <span>
              This disbursement has been made but may not yet have been received by the recipient
              (e.g., the check has been mailed, the electronic payment has been issued).
            </span>
          </li>
          <li>
            <strong>Cleared: </strong>
            <span>
              The recipient has received this disbursement (e.g., the check has been cashed, the
              electronic payment has been received).
            </span>
          </li>
          <li>
            <strong>Not Approved: </strong>
            <span>
              This disbursement was not approved and will not be processed. If you have any
              questions, please reach out to the trust administrator.
            </span>
          </li>
          <li>
            <strong>Stopped: </strong>
            <span>
              This disbursement was approved, but the payment was canceled, so it was not completed.
              If you have any questions, please reach out to the trust administrator.
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
}

BeneficiaryDisbursements.propTypes = {
  clientId: PropTypes.string.isRequired,
};

export default BeneficiaryDisbursements;
