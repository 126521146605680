import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import TransferDescriptionLite from 'react/member/card/components/funding_tab/transfers_table/TransferDescriptionLite';
import { transferShape } from 'react/member/card/shapes/TransferShape';
import { isRecurring, isSkipped, isPrepared } from 'react/member/utils/Transfer';
import PopUp from 'react/shared/components/popups/PopUp';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';

const useStyles = makeStyles({
  subHeader: {
    fontSize: '16px',
    paddingLeft: '10px',
  },
  subHeaderSkipped: {
    marginBottom: 0,
  },
  description: {
    paddingTop: '20px',
    paddingLeft: '30px',
  },
});

export default function TransferSkipCancelUnskipModal({ onClose, onConfirm, transfer }) {
  const classes = useStyles();

  let header;
  let subheader;
  if (isSkipped(transfer)) {
    header = 'Unskip this recurring transfer';
    subheader = (
      <p className={classes.subHeaderSkipped}>
        Would you like to <b>unskip</b> this transfer?
      </p>
    );
  } else if (isRecurring(transfer)) {
    header = 'Skip this recurring transfer';
    subheader = <b>Are you sure you want to skip this transfer?</b>;
  } else {
    header = 'Cancel this one-time transfer';
    subheader = <b>Are you sure you want to cancel this transfer?</b>;
  }

  const footer = (
    <TrueLinkButton
      className={`btn ${isRecurring(transfer) ? 'normal' : 'btn-danger'}`}
      onClick={onConfirm}
      variant="none"
    >
      Confirm
    </TrueLinkButton>
  );

  return (
    <PopUp footer={footer} header={header} maxWidth="580px" modalOpen onClose={onClose}>
      <div>
        <div className={classes.subHeader}>{subheader}</div>
        <div className={classes.description}>
          <TransferDescriptionLite item={transfer} />
        </div>
        {isPrepared(transfer) && (
          <div className={classes.description}>
            You can cancel this in-progress transfer up until 3:45 PM PT to avoid funding the card.
          </div>
        )}
      </div>
    </PopUp>
  );
}

TransferSkipCancelUnskipModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  transfer: transferShape.isRequired,
};
