import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import theme from 'react/shared/theme/Theme';

const useStyles = makeStyles(() => ({
  nextPage: {
    display: 'block',
    fontSize: '1.125rem',
    width: '100%',
    marginTop: '30px',
    [theme.breakpoints.up('md')]: {
      display: 'inline-block',
      fontSize: '1.25rem',
      marginRight: '1rem',
      padding: '1rem',
      width: 'auto',
      position: 'inherit',
    },
  },
}));

export default function NextPageButton({ className, disabled, id, label, onClick, variant }) {
  const classes = useStyles();
  const klassName = classNames(classes.nextPage, { [className]: !!className });

  return (
    <TrueLinkButton
      className={klassName}
      disabled={disabled}
      id={id}
      onClick={onClick}
      variant={variant}
    >
      {label}
    </TrueLinkButton>
  );
}

NextPageButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  variant: PropTypes.string,
};

NextPageButton.defaultProps = {
  disabled: false,
  label: 'Continue',
  variant: 'primary',
};
