import CircularProgress from '@mui/material/CircularProgress';
import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { fetchAccessCodeValidationForUser } from 'react/shared/apis/v2/access_codes_api';

export default function UserAccessCodeValidator({ userId }) {
  const [accessCode, setAccessCode] = useState('');
  const { isLoading, data, error } = useQuery(
    ['validate_access_code', userId, accessCode],
    () => fetchAccessCodeValidationForUser(userId, accessCode),
    { staleTime: 60 * 1000 },
  );

  const handleChange = (event) => {
    const value = event.target.value;
    setAccessCode(value);
  };

  let errorMessage;
  if (error) {
    errorMessage = 'An error occurred: {{ error }}';
  } else if (data === 'bad_format') {
    errorMessage = 'Access codes must be 4 digits.';
  } else {
    errorMessage = null;
  }

  return (
    <div className="user-access-code-validator">
      <span>Validate access code:</span>
      <input className="user-access-code-validator--input" onChange={handleChange} type="text" />
      {isLoading && (
        <CircularProgress
          className="user-access-code-validator--progress"
          style={{ height: '20px', width: '20px' }}
        />
      )}
      {data === 'valid' && <span className="user-access-code-validator--valid">VALID</span>}
      {data === 'invalid' && <span className="user-access-code-validator--invalid">INVALID</span>}
      {errorMessage && (
        <span className="user-access-code-validator--error-message">{errorMessage}</span>
      )}
    </div>
  );
}

UserAccessCodeValidator.propTypes = {
  userId: PropTypes.string.isRequired,
};
