import axios from 'axios';
import DashboardActions from 'react/member/actions/dashboard_actions';
import { AXIOS_XHR_CONFIG } from 'react/shared/utils/Axios';

const DashboardCategoryStore = flux.createStore({
  categories: [],
  categoriesLoaded: false,
  actions: [DashboardActions.fetchCategories, DashboardActions.getOrFetchCategories],

  fetchCategories(organization_id) {
    axios
      .get(
        RailsRoutes.dashboard_organization_path(organization_id, { format: 'json' }),
        AXIOS_XHR_CONFIG,
      )
      .then(({ data: organization }) => {
        this.categoriesLoaded = true;
        const categories = organization.disbursement_categories;
        this.categories = categories;
        this.emit('categories.fetch', categories);
      });
  },

  getOrFetchCategories(organization_id) {
    if (this.categoriesLoaded === true) {
      this.emit('categories.fetch', this.categories);
    } else {
      DashboardActions.fetchCategories(organization_id);
    }
  },

  exports: {
    getCategories() {
      return this.categories;
    },
    add(category) {
      return this.categories.push(category);
    },
    clearCategories() {
      this.categories.length = 0;
      this.categoriesLoaded = false;
    },
  },
});

export default DashboardCategoryStore;
