import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

function LoadingIndicator({ containerStyle, spinnerClass }) {
  return (
    <div aria-label="loading indicator" className="loading-container" style={containerStyle}>
      <div className={classNames('loading-indicator', { [spinnerClass]: spinnerClass })} />
    </div>
  );
}

LoadingIndicator.propTypes = {
  containerStyle: PropTypes.object,
  spinnerClass: PropTypes.string,
};

export default LoadingIndicator;
