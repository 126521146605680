import PropTypes from 'prop-types';
import React from 'react';

export default function OnboardingWidgetManualTesting({ onSelectCheckbox }) {
  const testingControls = [
    'expedite_shipping',
    'spending_monitor_modified',
    'bank_account_added',
    'micro_deposits_sent',
    'bank_account_verified',
    'transfer_scheduled',
    'funding_completed',
    'card_activated',
    'card_open',
  ];

  return (
    <fieldset className="onboarding_widget_testing_controls">
      {testingControls.map((display) => (
        <div key={display}>
          <input
            id={display}
            name={display}
            onClick={onSelectCheckbox}
            type="checkbox"
            value="true"
          />
          <label htmlFor={display}>{display}</label>
        </div>
      ))}
    </fieldset>
  );
}

OnboardingWidgetManualTesting.propTypes = {
  onSelectCheckbox: PropTypes.func.isRequired,
};
