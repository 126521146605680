import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import theme from 'react/shared/theme/Theme';

const useStyles = makeStyles(() => ({
  directDepositInfo: {
    [theme.breakpoints.down('md')]: {
      '& .direct-deposit-info': {
        width: '100%',
        '& .dl-horizontal': {
          '& dt': {
            width: '45%',
          },
          '& dd': {
            marginLeft: '55%',
          },
        },
      },
    },
  },
}));
export default function DirectDepositInfo({
  bankAccountNumber,
  nonDeletedCardExists,
  isNextStepProgram,
  cardActivated,
  cardholderName,
  cardProgramPresent,
  routingNumber,
}) {
  const classes = useStyles();

  const depositInfo = (
    <div className={classes.directDepositInfo}>
      <p>
        When prompted, provide the information below to your payor.
        {cardActivated ? null : 'Deposits to this card cannot be made until it has been activated.'}
      </p>
      <br />
      <div className="direct-deposit-info">
        <div className="deposit-warning">
          {isNextStepProgram && (
            <p>
              This direct deposit information can be used to deposit money onto the card. It cannot
              be used to remove funds from the card.
            </p>
          )}
          {!isNextStepProgram && cardProgramPresent && (
            <p>
              The card routing and account numbers can be used to directly credit or debit funds
              from the card. This information is most commonly used to deposit funds onto the card
              or to set up recurring bill pay for expenses like utilities. These types of direct
              payments are designed to override any Spending Settings settings, so please{' '}
              <b>do not</b> share this information with the cardholder unless you want them to have
              that authority.
            </p>
          )}
        </div>
        <hr />
        <dl className="dl-horizontal">
          <dt>Name:</dt>
          <dd>{cardholderName}</dd>
          <dt>Bank Name:</dt>
          <dd>Sunrise Banks N.A.</dd>
          <dt>Bank Address:</dt>
          <dd>
            200 University Ave W
            <br />
            St. Paul, MN 55103
          </dd>
          <dt>Account Number:</dt>
          <dd>{bankAccountNumber || 'Sorry, an error occurred.'}</dd>
          <dt>Account Type:</dt>
          <dd>Checking</dd>
          <dt>Routing Number:</dt>
          <dd>{routingNumber}</dd>
        </dl>
        <br />
      </div>
    </div>
  );

  return (
    <>
      <div className="sub_section_header">Direct deposit information</div>
      <div className="account_info_section">
        {nonDeletedCardExists ? (
          depositInfo
        ) : (
          <p>Deposits to this card cannot be made until it has been activated.</p>
        )}
      </div>
    </>
  );
}

DirectDepositInfo.propTypes = {
  bankAccountNumber: PropTypes.string,
  nonDeletedCardExists: PropTypes.bool,
  isNextStepProgram: PropTypes.bool,
  cardActivated: PropTypes.bool,
  cardholderName: PropTypes.string,
  cardProgramPresent: PropTypes.bool,
  routingNumber: PropTypes.string,
};
