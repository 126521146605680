import PropTypes from 'prop-types';
import React from 'react';
import { formattedTotalFromBalances } from 'react/member/utils/IndividualTrustBalance';

function IndividualTrustBalanceCell({ value }) {
  if (value.length === 0) {
    return;
  }

  return <span>{formattedTotalFromBalances(value[0])}</span>;
}

IndividualTrustBalanceCell.propTypes = {
  value: PropTypes.array.isRequired,
};

export default IndividualTrustBalanceCell;
