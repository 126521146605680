import PropTypes from 'prop-types';
import React from 'react';
import _groupBy from 'underscore/modules/groupBy';
import _isEmpty from 'underscore/modules/isEmpty';
import _map from 'underscore/modules/map';
import DeprecatedPositionsRow from './DeprecatedPositionsRow';
import SubSectionHeader from 'react/shared/components/SubSectionHeader';

export default function DeprecatedPositions(props) {
  const getPositionsByWealthAccount = () =>
    _map(_groupBy(props.positions, 'wealth_account_name'), (value, key) => ({
      name: key,
      positions: value,
    }));

  const totalAssetsRow = (positions) => {
    if (props.showTotalAssets && !_isEmpty(positions)) {
      const amts = _map(positions, (position) => [
        Number(position.units),
        Number(position.unit_value),
        Number(position.total_value),
        Number(position.cost_basis),
        Number(position.unrealized_gain_loss),
      ]);
      const units = _map(amts, (amt) => amt[0]).reduce((prev, curr) => prev + curr);
      const unitValues = _map(amts, (amt) => amt[1]).reduce((prev, curr) => prev + curr);
      const totalValues = _map(amts, (amt) => amt[2]).reduce((prev, curr) => prev + curr);
      const totalCostBases = _map(amts, (amt) => amt[3]).reduce((prev, curr) => prev + curr);
      const totalGains = _map(amts, (amt) => amt[4]).reduce((prev, curr) => prev + curr);
      const totalAssetPosition = {
        units,
        unit_value: unitValues,
        total_value: totalValues,
        name: 'Total Assets',
        symbol: '',
        cost_basis: totalCostBases,
        unrealized_gain_loss: totalGains,
      };
      return <DeprecatedPositionsRow position={totalAssetPosition} />;
    }
  };

  const positionsByWealthAccount = getPositionsByWealthAccount().map((value, idx) => {
    const positionList = _map(value.positions, (position) => (
      <DeprecatedPositionsRow key={position.id} position={position} />
    ));
    const headers =
      idx < 1 ? (
        <tr>
          <th className="table-cell-left">Holdings</th>
          <th>Ticker</th>
          <th>Units</th>
          <th className="table-cell-right">Unit Value</th>
          <th className="table-cell-right">Market Value</th>
          <th className="table-cell-right">Cost Basis</th>
          <th className="table-cell-right">Unrealized G/L</th>
        </tr>
      ) : (
        <tr>
          <th>
            <p
              style={{
                borderBottom: 'black',
                borderBottomStyle: 'solid',
                borderBottomWidth: 'thin',
                width: 300,
                marginBottom: 5,
              }}
            >
              {value.name}
            </p>
          </th>
          <th />
          <th />
          <th className="table-cell-right" />
          <th className="table-cell-right" />
          <th className="table-cell-right" />
          <th className="table-cell-right" />
        </tr>
      );

    return (
      <div key={idx}>
        <table className="table table-condensed" style={{ fontSize: '16px' }}>
          <thead>{headers}</thead>
          <tbody>
            {positionList}
            {totalAssetsRow(value.positions)}
          </tbody>
        </table>
      </div>
    );
  });

  return (
    <div>
      <SubSectionHeader>Investment Positions</SubSectionHeader>
      {positionsByWealthAccount}
    </div>
  );
}

DeprecatedPositions.propTypes = {
  positions: PropTypes.array.isRequired,
  showTotalAssets: PropTypes.bool,
};
