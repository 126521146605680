import PropTypes from 'prop-types';
import React, { useState } from 'react';

export default function CheckBoxWithLabel({ checked, className, name, text }) {
  const [isChecked, setChecked] = useState(checked);
  const toggleChange = () => setChecked((prevBool) => !prevBool);
  return (
    <label htmlFor={name}>
      <input
        checked={isChecked}
        className={className ? className : ''}
        id={name}
        name={name}
        onChange={toggleChange}
        type="checkbox"
        value={name}
      />
      {text}
    </label>
  );
}

CheckBoxWithLabel.propTypes = {
  checked: PropTypes.bool.isRequired,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};
