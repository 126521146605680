import moment from 'moment-timezone';

export function isRequested(transfer) {
  return transfer.state === 'requested';
}

export function isWithinNextSevenDays(transfer) {
  const date = moment(transfer.dateUnformatted, 'YYYY-MM-DD');
  const today = moment();
  const threshold = moment(today).add(7, 'days');

  return date.isSameOrAfter(today, 'day') && date.isBefore(threshold, 'day');
}

export function isBeyondNextSevenDays(transfer) {
  const date = moment(transfer.dateUnformatted, 'YYYY-MM-DD');
  const today = moment();
  const threshold = moment(today).add(7, 'days');

  return date.isSameOrAfter(threshold, 'day');
}

export function isPrefunding(transfer) {
  return isRequested(transfer) && isWithinNextSevenDays(transfer);
}

export function isPrepared(transfer) {
  return transfer.state === 'prepared';
}

export function isReady(transfer) {
  return transfer.isReady === true;
}

export function isPreparedButNotReady(transfer) {
  return transfer.state === 'prepared' && transfer.isReady === false;
}

export function isCancelled(transfer) {
  return transfer.state === 'cancelled';
}

export function isRecurring(transfer) {
  return transfer.autoTransferId !== null;
}

export function isSkipped(transfer) {
  return isCancelled(transfer) && isRecurring(transfer);
}

export function isCancelable(transfer) {
  return transfer.allowCancel && !isRecurring(transfer);
}

export function isHolidayImpacted(transfer) {
  return transfer.isHolidayImpacted === true;
}

export function hasAcceptedGuidance(transfer) {
  return transfer.guidanceAccepted === true;
}

export function delayedByHoliday(transfer) {
  return (
    transfer.isHolidayImpacted === true &&
    (transfer.guidanceAccepted === null || transfer.guidanceAccepted === false)
  );
}

export function beforeFlipDate(transfer) {
  const today = moment();
  return today.isBefore(transfer.holidayImpactedDate.holiday.flipDate, 'day');
}

export function isEmergencyFundable(transfer) {
  if (!transfer.instantOnDashboardEnabled) return false;
  if (!transfer.instantFundable) return false;
  return isPrefunding(transfer);
}

export function isEmergencyCreditable(transfer) {
  if (!transfer.instantOnDashboardEnabled) return false;
  if (!transfer.instantCreditable) return false;
  return isPrepared(transfer);
}

export function isEmergencyFunded(transfer) {
  return transfer.instantFundedBy !== null && transfer.landedDateUnformatted !== null;
}

export function isPartiallyLanded(transfer) {
  return isPrepared(transfer) && transfer.deferredCreditDate !== null;
}
