import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import api from 'react/member/card/components/account_tab/api';
import AreYouSurePopUp from 'react/shared/components/popups/AreYouSurePopUp';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';

const useStyles = makeStyles({
  reactivateButton: {
    alignItems: 'center',
    display: 'flex',
    '& svg': {
      marginRight: '10px',
    },
  },
});

export default function ReactivateCardButton({ accountSlug, onCardDeactivateReactivateSuccess }) {
  const classes = useStyles();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleOpenModal = (ev) => {
    ev.preventDefault();
    setIsModalOpen(true);
  };
  const handleCloseModal = () => setIsModalOpen(false);

  const handleSubmit = () => {
    setSubmitting(true);
    api.postReactivateCard(accountSlug).then(({ data }) => {
      setSubmitting(false);
      setIsModalOpen(false);
      Truelink.flash('success', data.response);
      onCardDeactivateReactivateSuccess();
    });
  };

  return (
    <div>
      <TrueLinkButton
        className={classes.reactivateButton}
        onClick={handleOpenModal}
        variant="primaryOutline"
      >
        <i className="fa fa-unlock-alt" />
        Reactivate card
      </TrueLinkButton>
      {isModalOpen && (
        <AreYouSurePopUp
          disableSubmit={submitting}
          messageText="This will reactivate the card, allowing it to be used as normal. Are you sure you want to reactivate the card?"
          onClose={handleCloseModal}
          onSubmit={handleSubmit}
          openModal
          submitText={submitting ? 'Processing...' : 'Continue'}
        />
      )}
    </div>
  );
}

ReactivateCardButton.propTypes = {
  accountSlug: PropTypes.string.isRequired,
  onCardDeactivateReactivateSuccess: PropTypes.func.isRequired,
};
