import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Textarea from 'react-textarea-autosize';
import DashboardActions from 'react/member/actions/dashboard_actions';
import DashboardNoteStore from 'react/member/stores/beneficiary_dashboard/DashboardNoteStore';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import bindAll from 'react/shared/utils/bind_all';

export default class DashboardTrustBeneficiaryProfilePrimaryNoteForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      changed: false,
      currentNoteContent: this.props.noteContent,
      isSubmitting: false,
    };

    bindAll(this);
  }

  componentDidMount() {
    DashboardNoteStore.on('dashboardPrimaryNote.update', this.onSubmissionFinish);
    DashboardNoteStore.on('dashboardPrimaryNote.add', this.onSubmissionFinish);
  }

  componentWillUnmount() {
    DashboardNoteStore.off('dashboardPrimaryNote.update', this.onSubmissionFinish);
    DashboardNoteStore.off('dashboardPrimaryNote.add', this.onSubmissionFinish);
  }

  onSubmissionFinish() {
    this.setState({ isSubmitting: false });
  }

  onChangeNoteContent(ev) {
    this.setState({
      changed: true,
      currentNoteContent: ev.currentTarget.value,
    });
  }

  noteData() {
    return { note_content: this.state.currentNoteContent };
  }

  updatePrimaryNote(ev) {
    ev.preventDefault();
    const client_id = this.props.trustBeneficiary.slug;
    if (this.state.changed) {
      if (!this.props.noteId) {
        DashboardActions.addClientNote(client_id, this.noteData());
      } else {
        DashboardActions.updateDashboardNote(client_id, this.props.noteId, this.noteData());
      }
      this.setState({ changed: false, isSubmitting: true });
    }
  }

  render() {
    return (
      <div>
        <form id="dashboard_primary_note_form" onSubmit={this.updatePrimaryNote}>
          <Textarea
            cols="100"
            id="primary_note_content"
            maxRows="10"
            name="dashboard_note[note_content]"
            onBlur={this.updatePrimaryNote}
            onChange={this.onChangeNoteContent}
            rows="2"
            style={{ width: '100%' }}
            value={this.state.currentNoteContent || ''}
          />
          <br />
          <TrueLinkButton
            className={classNames('btn btn-success', {
              disabled: this.state.isSubmitting,
            })}
            disabled={this.state.isSubmitting}
            type="submit"
            variant="none"
          >
            {this.state.isSubmitting ? 'Submitting...' : 'Submit'}
          </TrueLinkButton>
        </form>
      </div>
    );
  }
}

DashboardTrustBeneficiaryProfilePrimaryNoteForm.propTypes = {
  noteContent: PropTypes.string,
  noteId: PropTypes.number,
  trustBeneficiary: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    trust: PropTypes.shape({
      organization: PropTypes.shape({}),
    }).isRequired,
  }).isRequired,
};
