import axios from 'axios';
import DashboardActions from 'react/member/actions/dashboard_actions';
import ListItemStore from 'react/shared/utils/list_item_store';

const DashboardTrustRemainderBeneficiariesStore = flux.createStore({
  trustRemainderBeneficiaries: new ListItemStore(),
  actions: [
    DashboardActions.fetchTrustRemainderBeneficiaries,
    DashboardActions.createTrustRemainderBeneficiary,
    DashboardActions.updateTrustRemainderBeneficiary,
    DashboardActions.destroyTrustRemainderBeneficiary,
  ],

  fetchTrustRemainderBeneficiaries(trustBeneficiarySlug, onRequestCompleted) {
    axios
      .get(RailsRoutes.dashboard_client_trust_remainder_beneficiaries_path(trustBeneficiarySlug))
      .then((trustRemainderBeneficiariesResponse) => {
        const updatedItems = this.trustRemainderBeneficiaries.addItems(
          trustBeneficiarySlug,
          trustRemainderBeneficiariesResponse.data,
        );
        this.emit(`trustRemainderBeneficiaries.fetch.${trustBeneficiarySlug}`, updatedItems);
        this.emit(`trustRemainderBeneficiaries.updated.${trustBeneficiarySlug}`, updatedItems);
      })
      .finally(onRequestCompleted);
  },

  createTrustRemainderBeneficiary(
    trustBeneficiarySlug,
    trustRemainderBeneficiary,
    onRequestCompleted,
  ) {
    axios
      .post(RailsRoutes.dashboard_client_trust_remainder_beneficiaries_path(trustBeneficiarySlug), {
        trust_remainder_beneficiary: trustRemainderBeneficiary,
      })
      .then((trustRemainderBeneficiaryResponse) => {
        const updatedItems = this.trustRemainderBeneficiaries.addItems(trustBeneficiarySlug, [
          trustRemainderBeneficiaryResponse.data,
        ]);
        this.emit(
          `trustRemainderBeneficiaries.create.${trustBeneficiarySlug}`,
          trustRemainderBeneficiaryResponse.data,
        );
        this.emit(`trustRemainderBeneficiaries.updated.${trustBeneficiarySlug}`, updatedItems);
      })
      .catch((trustRemainderBeneficiaryResponse) => {
        this.emit(
          `trustRemainderBeneficiaries.create.${trustBeneficiarySlug}.error`,
          trustRemainderBeneficiaryResponse.data,
        );
      })
      .finally(onRequestCompleted);
  },

  updateTrustRemainderBeneficiary(
    trustBeneficiarySlug,
    trustRemainderBeneficiary,
    onRequestCompleted,
  ) {
    axios
      .patch(
        RailsRoutes.dashboard_client_trust_remainder_beneficiary_path(
          trustBeneficiarySlug,
          trustRemainderBeneficiary.id,
        ),
        { trust_remainder_beneficiary: trustRemainderBeneficiary },
      )
      .then((trustRemainderBeneficiaryResponse) => {
        const updatedItems = this.trustRemainderBeneficiaries.updateItem(
          trustBeneficiarySlug,
          trustRemainderBeneficiaryResponse.data,
        );
        this.emit(
          `trustRemainderBeneficiaries.update.${trustBeneficiarySlug}`,
          trustRemainderBeneficiaryResponse.data,
        );
        this.emit(`trustRemainderBeneficiaries.updated.${trustBeneficiarySlug}`, updatedItems);
      })
      .catch((trustRemainderBeneficiaryResponse) => {
        this.emit(
          `trustRemainderBeneficiaries.update.${trustBeneficiarySlug}.error`,
          trustRemainderBeneficiaryResponse.data,
        );
      })
      .finally(onRequestCompleted);
  },

  destroyTrustRemainderBeneficiary(
    trustBeneficiarySlug,
    trustRemainderBeneficiary,
    onRequestCompleted,
  ) {
    axios
      .delete(
        RailsRoutes.dashboard_client_trust_remainder_beneficiary_path(
          trustBeneficiarySlug,
          trustRemainderBeneficiary.id,
        ),
      )
      .then((trustRemainderBeneficiaryResponse) => {
        const updatedItems = this.trustRemainderBeneficiaries.removeItem(
          trustBeneficiarySlug,
          trustRemainderBeneficiaryResponse.data,
        );
        this.emit(
          `trustRemainderBeneficiaries.destroy.${trustBeneficiarySlug}`,
          trustRemainderBeneficiaryResponse.data,
        );
        this.emit(`trustRemainderBeneficiaries.updated.${trustBeneficiarySlug}`, updatedItems);
      })
      .catch((trustRemainderBeneficiaryResponse) => {
        this.emit(
          `trustRemainderBeneficiaries.destroy.${trustBeneficiarySlug}.error`,
          trustRemainderBeneficiaryResponse.data,
        );
      })
      .finally(onRequestCompleted);
  },
});

export default DashboardTrustRemainderBeneficiariesStore;
