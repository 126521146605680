import React from 'react';
import TrueLinkModal from './TrueLinkModal';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';

const dialogText = ` Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in,
        egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Praesent
        commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue
        laoreet rutrum faucibus dolor auctor. Aenean lacinia bibendum nulla sed consectetur.
        Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui.
        Donec ullamcorper nulla non metus auctor fringilla.`;

export default {
  title: 'Standard/Lab/Modal',
  component: TrueLinkModal,
};

function Template(args) {
  return (
    <div>
      <TrueLinkModal {...args} />
    </div>
  );
}

export const SimpleDialog = Template.bind({});
SimpleDialog.args = {
  title: 'Simple Dialog',
  children: <div>{dialogText}</div>,
};

export const CloseButtonDialog = Template.bind({});
CloseButtonDialog.args = {
  title: 'Simple Dialog',
  children: <div>{dialogText}</div>,
  handleClose: () => {
    null;
  },
};

export const BackButtonDialog = Template.bind({});
BackButtonDialog.args = {
  title: 'Simple Dialog with back button',
  children: <div>{dialogText}</div>,
  handleClose: () => {
    null;
  },
  handleBack: () => {
    null;
  },
};

export const CloseButtonIconDialog = Template.bind({});
CloseButtonDialog.args = {
  title: 'Simple Dialog',
  children: <div>{dialogText}</div>,
  handleClose: () => {
    null;
  },
  iconCLoseBtn: true,
};

export const ActionButtonMDialog = Template.bind({});
ActionButtonMDialog.args = {
  title: 'Modal Title',
  children: (
    <div>
      <div>{dialogText}</div>
      <TrueLinkButton variant="primaryOutline">Other</TrueLinkButton>
      <TrueLinkButton variant="primary">Primary</TrueLinkButton>
    </div>
  ),
  handleClose: () => {
    null;
  },
  scrollable: true,
};
