import PropTypes from 'prop-types';
import React from 'react';

function RadioLabel({
  labelContent,
  htmlFor,
  disabled,
  radioLabelClass,
  radioTextClass,
  radioCheckboxContainerClass,
  afterLabel,
}) {
  return (
    <label className={`${radioLabelClass}${disabled ? ' disabled' : ''}`} htmlFor={htmlFor}>
      <span className={radioCheckboxContainerClass} />
      <span className={radioTextClass}>
        {labelContent}
        {afterLabel}
      </span>
    </label>
  );
}

RadioLabel.propTypes = {
  labelContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  htmlFor: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  radioLabelClass: PropTypes.string,
  radioTextClass: PropTypes.string,
  radioCheckboxContainerClass: PropTypes.string,
  afterLabel: PropTypes.node,
};

RadioLabel.defaultProps = {
  disabled: false,
  radioLabelClass: 'card--form__radio__label',
  radioTextClass: 'card--form__radio__text',
  radioCheckboxContainerClass: 'card--form__radio__checkbox-container',
  afterLabel: null,
};

export default RadioLabel;
