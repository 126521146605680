import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';
import Field from './Field';
import FieldMixin from './FieldMixin';
import { capitalizeWords, humanize } from 'react/shared/utils/Strings';

const RadioField = createReactClass({
  propTypes: {
    inputValues: PropTypes.array.isRequired,
    onCheck: PropTypes.func.isRequired,
  },

  mixins: [FieldMixin],

  getDefaultProps() {
    return {
      format: (value) => capitalizeWords(humanize(value)),
    };
  },

  componentDidMount() {
    this.initField();
  },

  renderShow() {
    return <span>{this.formattedValue()}</span>;
  },

  renderEdit() {
    return this.props.inputValues.map((inputValue) => {
      const key = `radioInput${this.inputName()}${inputValue}`;
      return (
        // eslint-disable-next-line jsx-a11y/label-has-associated-control
        <label key={key}>
          <input
            data-checkbox-style="single"
            defaultChecked={this.value() == inputValue}
            name={this.inputName()}
            onChange={this.onCheck}
            type="radio"
            value={inputValue}
          />{' '}
          {this.format()(inputValue)}
        </label>
      );
    });
  },

  onCheck(evt) {
    this.change(evt.target.value);
    if (this.props.onCheck) {
      this.props.onCheck(evt);
    }
  },

  render() {
    return (
      <Field
        {...this.props}
        editable={this.editable}
        label={this.label}
        renderEdit={this.renderEdit}
        renderShow={this.renderShow}
      />
    );
  },
});

RadioField.propTypes = {
  inputValues: PropTypes.array.isRequired,
  onCheck: PropTypes.func.isRequired,
};

export default RadioField;
