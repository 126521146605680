import PropTypes from 'prop-types';
import React from 'react';
import CheckboxLine from 'react/shared/card/components/CheckboxLine';
import programNameShape from 'react/shared/shapes/account_program_name_shape';
import { isAbleActProgramAccount } from 'react/shared/utils/account_program_type';

export default class TopLineAlerts extends React.Component {
  getMaxTxValues() {
    const { warn_rules } = this.props;

    if (undefined === warn_rules['maxTransactionSize']) {
      return {
        maxTxSizeText: '0',
        maxTxSizeChecked: false,
      };
    }

    return {
      maxTxSizeText: warn_rules['maxTransactionSize'].toString(),
      maxTxSizeChecked: true,
    };
  }

  getLowBalanceValues() {
    const { notificationPrefs: prefs } = this.props;

    return {
      lowBalanceText: (prefs['low_balance'] || 50).toString(),
      lowBalanceChecked: !!prefs['low_balance'],
    };
  }

  render() {
    const { warn_rules, notificationPrefs: prefs, programName } = this.props;

    const { lowBalanceText, lowBalanceChecked } = this.getLowBalanceValues();
    const { maxTxSizeText, maxTxSizeChecked } = this.getMaxTxValues();

    return (
      <div className="alert-checkbox-container">
        <CheckboxLine
          detailText={`Want to receive an alert about every transaction on the ${programName} Card? Enable this option with the amount set to $0.`}
          id="warn-max-transaction-size"
          isChecked={maxTxSizeChecked}
          name="maxTransactionSize"
          text1="Spending: "
          text2="Alert me about transactions over $"
          textfield={maxTxSizeText}
          updateChecked={this.props.addWarnRules}
          updateUnChecked={this.props.removeWarnRules}
        />
        <CheckboxLine
          id="notify-low-balance"
          isChecked={lowBalanceChecked}
          name="low_balance"
          text1="Balance: "
          text2="Alert me when card balance drops below $"
          textfield={lowBalanceText}
          updateChecked={this.props.onUpdatePrefs}
          updateUnChecked={this.props.onUpdatePrefs}
        />
        {!isAbleActProgramAccount(programName) && [
          <CheckboxLine
            id="warn-cash-withdrawls"
            isChecked={!!warn_rules['blockATM']}
            key="warn-cash-withdrawls"
            rules={{
              blockATM: true,
              blockSignatureCash: true,
              blockPOSCash: true,
            }}
            text1="Cash: "
            text2="Alert me about cash withdrawals"
            updateChecked={this.props.addWarnRules}
            updateUnChecked={this.props.removeWarnRules}
          />,
          <CheckboxLine
            id="notify-funding-initiated"
            isChecked={!!prefs['funding']}
            key="notify-funding-initiated"
            name="funding"
            text1="Funding: "
            text2="Alert me when recurring transfers are initiated"
            updateChecked={this.props.onUpdatePrefs}
            updateUnChecked={this.props.onUpdatePrefs}
          />,
        ]}
        {this.props.allowsFamilyFunder && (
          <CheckboxLine
            id="notify-family-funding-initiated"
            isChecked={!!prefs['family_funding']}
            name="family_funding"
            text1="Funding: "
            text2="Alert me when a family funder adds funds to a card"
            updateChecked={this.props.onUpdatePrefs}
            updateUnChecked={this.props.onUpdatePrefs}
          />
        )}
      </div>
    );
  }
}

TopLineAlerts.propTypes = {
  notificationPrefs: PropTypes.object.isRequired,
  warn_rules: PropTypes.object.isRequired,
  addWarnRules: PropTypes.func.isRequired,
  removeWarnRules: PropTypes.func.isRequired,
  onUpdatePrefs: PropTypes.func.isRequired,
  programName: programNameShape.isRequired,
  allowsFamilyFunder: PropTypes.bool.isRequired,
};
