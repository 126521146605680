import PropTypes from 'prop-types';
import React from 'react';
import _filter from 'underscore/modules/filter';
import _map from 'underscore/modules/map';
import DashboardActions from 'react/member/actions/dashboard_actions';
import AttachmentDropZone from 'react/member/components/attachments/drop_zone_uploader/AttachmentDropZone';
import DepositAttachment from 'react/member/components/dashboard/deposits/DepositAttachment';
import DashboardBudgetItemAttachmentStore from 'react/member/stores/DashboardBudgetItemAttachmentStore';
import LoadingIndicator from 'react/shared/components/LoadingIndicator';
import bindAll from 'react/shared/utils/bind_all';

export default class DashboardDepositFormAttachments extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      attachments: [],
      showSpinner: false,
    };

    bindAll(this);
  }

  componentDidMount() {
    const { store } = this.props;

    store.on('attachments.fetch', this.onChangeAttachments);
    store.on('attachments.destroy', this.onDestroyAttachment);
    store.on('attachment.upload', this.onUpload);

    if (this.props.deposit) {
      DashboardActions.fetchBudgetItemAttachmentsByBudgetItem(this.props.deposit);
    }
  }

  componentWillUnmount() {
    const { store } = this.props;

    store.off('attachments.fetch', this.onChangeAttachments);
    store.off('attachments.destroy', this.onDestroyAttachment);
    store.off('attachment.upload', this.onUpload);
  }

  allowedExtensionsErrorMessage(fileName) {
    return `You tried to upload "${fileName}". We currently do not support this file type. Please use these file types: ${$tlf.domains.allowedExtensions.join(
      ', ',
    )}`;
  }

  allowedExtensionsMessage() {
    return `Supported file types: ${$tlf.domains.allowedExtensions.join(', ')}`;
  }

  // These attachments get fetched whenever there is an existing Deposit and the list of deposits
  // changes (e.g., a new attachment is uploaded).
  onChangeAttachments(budgetItemId, attachments) {
    this.setState({ showSpinner: false });
    if (this.props.deposit) {
      if (this.props.deposit.id === budgetItemId) {
        return this.setAttachments(attachments);
      }
    } else {
      // the 'getAttachments' method will incorrectly return attachments for other budget items
      // so we need to filter them https://truelink.atlassian.net/browse/TRUST-678
      const newAttachments = this.props.store
        .getAttachments()
        .filter((attachment) => attachment.attachable_type !== 'BudgetItem');
      return this.setAttachments(newAttachments);
    }
  }

  setAttachments(attachments) {
    this.setState({
      attachments,
    });
  }

  onDestroyAttachment(attachmentId) {
    const attachments = _filter(this.state.attachments, (attach) => attach.id !== attachmentId);
    this.setState({ attachments });
  }

  upload(file) {
    const { deposit, store } = this.props;
    const fileInputName = 'attachment[file]';

    if (deposit) {
      const doneCallback = (_attachment) => {
        DashboardActions.fetchBudgetItemAttachmentsByBudgetItem(deposit);
      };
      return store.uploadAttachmentToBudgetItem(
        fileInputName,
        file,
        false,
        deposit.is_recurring ? 'RecurringBudgetItem' : 'BudgetItem',
        deposit.id,
        doneCallback,
      );
    }

    store.uploadAttachment(fileInputName, file);
  }

  // This handler is solely used when the attachment is being uploaded and the deposit has not yet been
  // created. (i.e., the new deposit form.)
  onUpload(attachment) {
    // On an error, attachment will come back as null
    if (attachment) {
      this.setState((prevState) => {
        const updatedAttachments = [...prevState.attachments, attachment];
        return { attachments: updatedAttachments };
      });
      this.props.onUpload(attachment);
    }
  }

  renderAttachments() {
    const { attachments } = this.state;
    const { deposit, actions, userCanEdit } = this.props;

    if (attachments.length === 0) {
      return;
    }

    const links = (() => {
      if (attachments.length === 0) {
        return (
          <tr>
            <td className="italic" colSpan={3}>
              No attachments
            </td>
          </tr>
        );
      }
      return _map(attachments, (attachment) => (
        <DepositAttachment
          actions={actions}
          attachment={attachment}
          deposit={deposit}
          key={attachment.id}
          userCanEdit={userCanEdit}
        />
      ));
    })();
    return (
      <div>
        <table
          className="table"
          style={{
            width: '100%',
            marginBottom: '20px !important',
            borderBottom: '1px solid #ddd',
            paddingBottom: 10,
          }}
        >
          <thead>
            <tr>
              <th className="padding-top-0">File name</th>
              {userCanEdit && <th className="align-right padding-top-0">Remove</th>}
            </tr>
          </thead>
          <tbody>{links}</tbody>
        </table>
      </div>
    );
  }

  onBeginUpload() {
    this.setState({ showSpinner: true });
  }

  onUploadSuccess() {
    this.setState({ showSpinner: false });
  }

  uploadButton() {
    if (!this.props.userCanEdit) {
      return null;
    }

    return (
      <AttachmentDropZone
        onBeginUpload={this.onBeginUpload}
        onSuccess={this.onUploadSuccess}
        upload={this.upload}
      />
    );
  }

  render() {
    const { showSpinner } = this.state;
    return (
      <div className="new-form__dropzone">
        {this.renderAttachments()}
        <div className="button-wrapper" style={{ marginBottom: 12 }}>
          {showSpinner && (
            <LoadingIndicator
              containerStyle={{
                display: 'inline',
                margin: 8,
                verticalAlign: 'middle',
              }}
            />
          )}
          {this.uploadButton()}
        </div>
      </div>
    );
  }
}

DashboardDepositFormAttachments.propTypes = {
  deposit: PropTypes.object,
  actions: PropTypes.object,
  store: PropTypes.object,
  onUpload: PropTypes.func,
  userCanEdit: PropTypes.bool,
};

DashboardDepositFormAttachments.defaultProps = {
  store: DashboardBudgetItemAttachmentStore,
  actions: DashboardActions,
};
