(function() {
"use strict";

function initFeeSettings() {
  $('.fee_settings_review').change(validate_form_and_submit);
}

function validate_form_and_submit(event) {
  var data, id, url, form;
  event.preventDefault();
  form = $(event.currentTarget).closest('form');
  data = form.serializeArray();
  url = form.attr('action');
  if (form.attr('id') === 'select_all') {
    $.post(url, data)
    .done(function(response) {
      Truelink.flash('success', String(response.success_message));
      $('#spinner, #overlay').show();
    })
    .fail(function() {
      Truelink.flash('error', "Unable to update fee settings.");
    });
  } else {
    id = $(event.currentTarget).data('id');
    url += '/' + id;
    $.ajax({ url: url, data: data, type: "PUT" })
    .done(function() {
      Truelink.flash('success', "Account fee settings updated!");
    })
    .fail(function() {
      Truelink.flash('error', "Unable to update fee settings.");
    });
  }
}

function init() {
  if ($('.account_fee_settings_list').length === 0) return;
  initFeeSettings();
}

$(document).ready(init);

})();
