import DashboardActions from 'react/member/actions/dashboard_actions';

const DashboardDailyPortfolioCloseoutStore = flux.createStore({
  closeouts: [],
  latestCloseout: {},

  actions: [
    DashboardActions.fetchDailyPortfolioCloseouts,
    DashboardActions.fetchLatestCloseout,
    DashboardActions.generateCloseoutReport,
    DashboardActions.generateCloseoutReportForTrust,
  ],

  fetchDailyPortfolioCloseouts(portfolio_id) {
    $.get(
      RailsRoutes.dashboard_portfolio_closeouts_path(portfolio_id, {
        format: 'json',
      }),
      (closeouts) => {
        this.closeouts = closeouts;
        this.emit('dailyPortfolioCloseouts.fetch', this.closeouts);
      },
    );
  },

  fetchLatestCloseout(portfolio_id) {
    $.get(
      RailsRoutes.latest_dashboard_portfolio_closeouts_path(portfolio_id, {
        format: 'json',
      }),
      (latestCloseout) => {
        this.latestCloseout = latestCloseout;
        this.emit('dailyPortfolioCloseout.fetchLatest', this.latestCloseout);
      },
    );
  },

  generateCloseoutReport(portfolio_id, date) {
    $.get(
      RailsRoutes.report_for_date_dashboard_portfolio_closeouts_path(portfolio_id, {
        format: 'json',
        date,
      }),
      (report) => {
        this.emit('dailyPortfolioCloseout.reportGenerated', report);
      },
    );
  },

  generateCloseoutReportForTrust(trust_id, date) {
    $.get(
      RailsRoutes.report_for_date_dashboard_trust_closeouts_path(trust_id, {
        format: 'json',
        date,
      }),
      (report) => {
        this.emit('dailyPortfolioCloseout.reportGenerated', report);
      },
    );
  },

  exports: {
    getCloseouts() {
      return this.closeouts;
    },
  },
});

export default DashboardDailyPortfolioCloseoutStore;
