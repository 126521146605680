export default function DisplayModal(
  title,
  message,
  callback,
  cancelMsg = 'Cancel',
  continueMsg = 'Continue',
) {
  const modal = $('#confirmation-modal');
  // unbind previous actions
  $('#continue', modal).unbind('click');
  //bind our callback
  if (callback) {
    $('#continue', modal).click(callback);
  }
  // hide continue if there is no continue message
  $('#continue', modal).text(continueMsg || '');
  $('#continue', modal).toggle(!!continueMsg);
  $('#cancel', modal).text(cancelMsg);
  // display our title if desired
  $('.modal-title', modal).toggle(!!title);
  $('.modal-title', modal).text(title);
  // display modal text
  $('.modal-body p', modal).html(message);
  // display the modal
  return modal.modal({ show: true, keyboard: true });
}
