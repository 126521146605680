import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import _isEmpty from 'underscore/modules/isEmpty';
import DropZonePopUp from './DropZonePopUp';
import GenericInlineLabel from './GenericInlineLabel';
import {
  attachableShape,
  attachableTypeShape,
  attachmentCategoryShape,
  fileTypeShape,
  foldersShape,
} from 'react/member/shapes/attachments_shapes';
import DatePickerField from 'react/shared/components/forms/DatePickerField';
import ReactDropdown from 'react/shared/components/forms/ReactDropdown';
import bindAll from 'react/shared/utils/bind_all';

export default class AttachmentUploadPopUp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      folderId: null,
      receiptDate: null,
    };

    bindAll(this);
  }

  buildUploadUrl() {
    return RailsRoutes.api_v2_attachments_path({ format: 'json' });
  }

  buildDateConstraints() {
    return {
      minDate: moment('01/01/2013', 'MM/DD/YYYY').toDate(),
      maxDate: moment().toDate(),
    };
  }

  appendAttachmentDataOnSubmit(file, xhr, formData) {
    const { attachable, attachableType, category, fileType } = this.props;

    formData.append('attachableId', attachable.id);
    formData.append('attachableType', attachableType);

    if (category) {
      formData.append('attachment[category]', category);
    }

    if (fileType === 'document' && this.state.folderId) {
      formData.append('attachment[folder_id]', this.state.folderId);
    }

    if (fileType === 'receipt') {
      formData.append(
        'attachment[content_date]',
        moment(this.state.receiptDate, 'MM/DD/YYYY').format('YYYY-MM-DD'),
      );
    }
  }

  handleValidateForm() {
    if (this.props.fileType === 'receipt') {
      const isDatePresent = !_isEmpty(this.state.receiptDate);
      const { minDate, maxDate } = this.buildDateConstraints();
      const isDateValid = this.handleValidateReceiptDate(minDate, maxDate);

      return isDatePresent && isDateValid;
    }

    return true;
  }

  handleValidateReceiptDate(minDate, maxDate) {
    const isEmpty = _isEmpty(this.state.receiptDate);

    const date = moment(this.state.receiptDate, 'MM/DD/YYYY');
    const isValid = date.isValid();
    const isMinBound = date.isSame(minDate) || date.isAfter(minDate);
    const isMaxBound = date.isSame(maxDate) || date.isBefore(maxDate);

    return isEmpty || (isValid && isMinBound && isMaxBound);
  }

  handleFolderDropdownChange(e) {
    const folderId = e.target.value.toString();

    this.setState({
      folderId: _isEmpty(folderId) ? null : folderId,
    });
  }

  handleDatepickerChange(date) {
    this.setState({ receiptDate: date });
  }

  renderFolderDropdown() {
    const inputId = 'upload-files-to-folder-select';

    return (
      <div style={{ marginTop: '20px' }}>
        <GenericInlineLabel inputId={inputId} labelContent="Upload to folder" optional>
          <ReactDropdown
            includeBlankOption
            inputId={inputId}
            onChange={this.handleFolderDropdownChange}
            options={this.props.folders}
          />
        </GenericInlineLabel>
      </div>
    );
  }

  renderDatepicker() {
    const inputId = 'upload-receipt-datepicker';

    const { minDate, maxDate } = this.buildDateConstraints();
    const validDate = this.handleValidateReceiptDate(minDate, maxDate);

    return (
      <div style={{ marginTop: '20px' }}>
        <GenericInlineLabel inputId={inputId} labelContent="Receipt date">
          <DatePickerField
            dateFormat="mm/dd/yy"
            handleDateSelect={this.handleDatepickerChange}
            inputProps={{
              id: inputId,
              placeholder: 'MM/DD/YYYY',
              className: validDate ? '' : 'invalid',
            }}
            maxDate={maxDate}
            minDate={minDate}
          />
        </GenericInlineLabel>
      </div>
    );
  }

  render() {
    const uploadUrl = this.buildUploadUrl();
    const paramName = 'attachment[file]';

    const additionalInputs = {
      document: this.renderFolderDropdown,
      receipt: this.renderDatepicker,
    };

    const formValid = this.handleValidateForm();

    return (
      <DropZonePopUp
        additionalContent={additionalInputs[this.props.fileType]()}
        appendFormDataOnSubmit={this.appendAttachmentDataOnSubmit}
        headerText={this.props.headerText}
        onClose={this.props.onClose}
        onSubmit={this.props.onSubmit}
        onSuccess={this.props.onSuccess}
        uploadDisabled={!formValid}
        uploadParamName={paramName}
        uploadUrl={uploadUrl}
      />
    );
  }
}

AttachmentUploadPopUp.propTypes = {
  headerText: PropTypes.string.isRequired,
  attachable: attachableShape.isRequired,
  attachableType: attachableTypeShape.isRequired,
  fileType: fileTypeShape.isRequired,
  folders: foldersShape.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  category: attachmentCategoryShape,
};
