/* eslint-disable react/no-multi-comp */
import PropTypes from 'prop-types';
import React from 'react';
import TrueLinkTable from './TrueLinkTable';

export default {
  title: 'Standard/Table',
  component: TrueLinkTable,
};

function MoneyCell({ value }) {
  return <span style={{ float: 'right' }}>{value}</span>;
}
MoneyCell.propTypes = {
  value: PropTypes.string,
};

const columns = [
  { Header: 'Process date', accessor: 'processDate' },
  { Header: 'Type', accessor: 'type' },
  { Header: 'Description', accessor: () => 'A brief description' },
  {
    Header: () => <span style={{ flexGrow: 1, textAlign: 'right' }}>Amount</span>,
    accessor: 'amount',
    Cell: MoneyCell,
  },
];
const data = [
  {
    processDate: '08/30/2021',
    type: 'Wire',
    amount: '$15,000.00',
  },
  { processDate: '07/30/2021', type: 'Check', amount: '$35,000.12' },
  { processDate: '06/31/2021', type: 'Check', amount: '$1,900.03' },
  { processDate: '05/30/2021', type: 'Wire', amount: '$21,000.98' },
];

function Template(args) {
  return <TrueLinkTable {...args} />;
}

export const Standard = Template.bind({});
Standard.args = {
  columns,
  data,
};

export const Compressed = Template.bind({});
Compressed.args = {
  columns,
  data,
  variant: 'compressed',
};

export const Selectable = Template.bind({});
Selectable.args = {
  columns,
  data,
  selectKeyField: 'processDate',
  selectable: true,
};

export const SelectableWithLongText = Template.bind({});
SelectableWithLongText.args = {
  columns,
  data: [
    ...data.map((e, i) =>
      i === 0
        ? {
            ...e,
            type: 'Fly me to the moon Let me play among the stars Let me see what spring is like on A-Jupiter and Mars In other words, hold my hand',
          }
        : e,
    ),
  ],
  selectKeyField: 'processDate',
  selectable: true,
};

export const WithFooter = Template.bind({});
WithFooter.args = {
  columns: [...columns.slice(0, 3), { ...columns[3], Footer: <MoneyCell value="$72,002.73" /> }],
  data,
  showFooter: true,
};

export const WithSubheaders = Template.bind({});
WithSubheaders.args = {
  columns: [...columns.slice(0, 2), { ...columns[2], Subheader: <>Check this</> }, columns[3]],
  data,
};

export const Unsortable = Template.bind({});
Unsortable.args = {
  sortable: false,
  columns,
  data,
};

export const Paginated = Template.bind({});
Paginated.args = {
  paginated: true,
  pageSize: 2,
  columns,
  data,
};

export const PaginatedControlledExternally = Template.bind({});
PaginatedControlledExternally.args = {
  controlledPageCount: 5,
  paginated: true,
  columns,
  data,
};

export const Loading = Template.bind({});
Loading.args = {
  columns,
  data: [],
  loading: true,
};

export const NoData = Template.bind({});
NoData.args = {
  columns,
  data: [],
};

export const CustomMessageNoData = Template.bind({});
CustomMessageNoData.args = {
  columns,
  data: [],
  noDataText: 'Missing some stuff I guess.',
};
