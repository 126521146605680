import PropTypes from 'prop-types';
import React from 'react';
import BankAccount from './BankAccount';
import { dashboardAccountShape } from 'react/member/card/shapes/AccountShape';
import { bankAccountShape } from 'react/member/card/shapes/BankAccountShape';

export default function BankAccountList({ account, bankAccounts, cardStatus, isViewOnly }) {
  return (
    <div id="bank_accounts">
      {bankAccounts.map((bankAccount) => (
        <BankAccount
          account={account}
          bankAccount={bankAccount}
          cardStatus={cardStatus}
          isViewOnly={isViewOnly}
          key={bankAccount.id}
        />
      ))}
    </div>
  );
}

BankAccountList.propTypes = {
  account: dashboardAccountShape.isRequired,
  bankAccounts: PropTypes.arrayOf(bankAccountShape).isRequired,
  cardStatus: PropTypes.string,
  isViewOnly: PropTypes.bool.isRequired,
};
