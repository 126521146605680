import PropTypes from 'prop-types';

const { string, number, bool, shape, oneOf, oneOfType } = PropTypes;

const customerBannerShape = shape({
  color: oneOf(['green', 'red', 'orange']).isRequired,
  color_class: string.isRequired,
  color_code: number.isRequired,
  content: string,
  id: oneOfType([string, number]),
  markdown_content: string.isRequired,
  show_on_account_list: bool.isRequired,
  show_on_cardholder_sign_in: bool.isRequired,
  show_on_dashboard: bool.isRequired,
});

export default customerBannerShape;
