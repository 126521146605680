import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import TrueLinkLink from 'react/shared/components/true_link/main/TrueLinkLink';
import TrueLinkNumericCode from 'react/shared/components/true_link/main/form/TrueLinkNumericCode';
import PALETTE from 'react/shared/theme/palette';

const useStyles = makeStyles({
  body: {
    paddingTop: '10px',
    '& input': {
      width: '40px !important',
      height: '55px !important',
      borderColor: `${PALETTE.grey4}!important`,
      marginRight: '10px !important',
      paddingLeft: '10px !important',
    },
  },

  infoMsg: {
    paddingBottom: '10px',
  },

  numericCode: {
    paddingBottom: '8px',
  },

  errorMsg: {
    color: PALETTE.red,
    visibility: 'hidden',
  },

  error: {
    visibility: 'visible',
    '& input': {
      borderColor: `${PALETTE.red} !important`,
    },
  },
});

export default function MfaVerifyWizardStep2({
  email,
  phoneNumber,
  sendMfaCode,
  setCode,
  showError,
}) {
  const classes = useStyles();
  const infoMsg = `Enter the code we sent via ${
    phoneNumber ? `text to the number ending in ${phoneNumber.slice(-4)}. ` : `email to ${email}. `
  }`;

  return (
    <div className={classNames(classes.body, { [classes.error]: showError })}>
      <p className={classes.infoMsg}>
        {infoMsg}
        <TrueLinkLink ariaLabel="Resend code" onClick={sendMfaCode}>
          Resend code
        </TrueLinkLink>
      </p>
      <div className={classes.numericCode}>
        <TrueLinkNumericCode fields={6} isValid={!showError} onChange={setCode} />
        <p className={classNames(classes.errorMsg, { [classes.error]: showError })}>
          You have entered the wrong code.
        </p>
      </div>
    </div>
  );
}

MfaVerifyWizardStep2.propTypes = {
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
  sendMfaCode: PropTypes.func.isRequired,
  setCode: PropTypes.func.isRequired,
  showError: PropTypes.bool,
};
