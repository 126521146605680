import api from './api';
import { Poller } from './poller';
import { originTypes } from 'react/member/components/dashboard/reports/Constants';

export default class ReportDownloader {
  generateParams(values) {
    const params = {
      report_type: values.reportType,
      start_date: values.startDate,
      end_date: values.endDate,
      quarterly_statement_date: values.quarterlyDate,
    };

    switch (values.entityType) {
      case originTypes.bene: {
        params.trust_beneficiary_id = values.entityId;
        break;
      }
      case originTypes.organization: {
        params.organization_id = values.entityId;
        break;
      }
      case originTypes.trust: {
        params.trust_id = values.entityId;
        break;
      }
      case originTypes.portfolio: {
        params.portfolio_id = values.entityId;
        break;
      }
    }

    return params;
  }

  requestReport(doneHandler, values) {
    this.poller = new Poller(doneHandler);
    const params = this.generateParams(values);

    api
      .createReportRequest(params)
      .then((response) => this.poller.start(response.data.data.id))
      .catch((_error) => {
        doneHandler({});
      });
  }

  cancelRequest() {
    this.poller?.stop();
  }
}
