import axios from 'axios';
import Actions from 'react/shared/actions/actions';

const CategorizedBudgetTimelineStore = flux.createStore({
  actions: [Actions.createBudgetTimeline, Actions.updateBudgetTimeline],

  createBudgetTimeline(clientId, params) {
    axios
      .post(
        RailsRoutes.dashboard_client_categorized_budget_timelines_path(clientId, {
          categorizedBudgetTimeline: params,
        }),
      )
      .then((budgetTimelineResponse) => {
        this.budgetTimeline = budgetTimelineResponse.data;
        this.emit('budgetTimelines.create', this.budgetTimeline);
      })
      .catch(() => this.emit('budgetTimelines.create.error', this.budgetTimeline));
  },

  updateBudgetTimeline(timelineId, params) {
    axios
      .put(
        RailsRoutes.categorized_budget_timeline_path(timelineId, {
          categorizedBudgetTimeline: params,
        }),
      )
      .then((budgetTimelineResponse) => {
        this.budgetTimeline = budgetTimelineResponse.data;
        this.emit('budgetTimelines.update', this.budgetTimeline);
      })
      .catch(() => this.emit('budgetTimelines.update.error', this.budgetTimeline));
  },
});

export default CategorizedBudgetTimelineStore;
