import PropTypes from 'prop-types';
import React from 'react';
import ReactSelect from 'react-select';
import bindAll from 'react/shared/utils/bind_all';

export default class FormSelectField extends React.Component {
  static get propTypes() {
    return {
      field: PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }),
      form: PropTypes.shape({
        setFieldValue: PropTypes.func,
      }),
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
        }),
      ),
      reactSelectProps: PropTypes.object,
    };
  }

  constructor(props) {
    super(props);
    bindAll(this);
  }

  handleChange(selectedOption) {
    const { setFieldValue } = this.props.form;
    const { name } = this.props.field;
    setFieldValue(name, selectedOption.value);
  }

  render() {
    const { field, options, reactSelectProps } = this.props;
    const selectedOption = field.value
      ? options.find((element) => element.value === field.value)
      : '';

    return (
      <ReactSelect
        className="tl-select"
        classNamePrefix="tl-select"
        isSearchable={false}
        name={field.name}
        onChange={this.handleChange}
        options={options}
        placeholder=""
        value={selectedOption}
        {...reactSelectProps}
      />
    );
  }
}
