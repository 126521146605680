import React, { useState, useMemo } from 'react';
import TrueLinkRadios from './TrueLinkRadios';
import MultiLineLabel from 'react/signups/card/common/MultiLineLabel.js';

export default {
  title: 'Forms/Radios',
  component: TrueLinkRadios,
  argTypes: {
    onChange: { action: 'clicked' },
  },
};

function Template(args) {
  const [value, setValue] = useState('');
  useMemo(() => {
    setValue(args.value);
  }, [args.value]);
  const handleChange = (e) => {
    setValue(e.target.value);
    args.onChange(e);
  };

  return <TrueLinkRadios {...args} onChange={handleChange} value={value} />;
}

export const Preselected = Template.bind({});
Preselected.args = {
  options: [
    { value: 'choice1', label: 'Choice 1' },
    { value: 'choice2', label: 'Choice 2' },
    { value: 'choice3', label: 'Choice 3' },
  ],
  ariaLabel: 'Radios',
  name: 'radio-selection',
  value: 'choice2',
};

export const Unselected = Template.bind({});
Unselected.args = {
  value: '',
  options: [
    { value: 'vanilla', label: 'Vanilla' },
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
  ],
  ariaLabel: 'Radios',
  name: 'radio-selection',
};

export const NoBorder = Template.bind({});
NoBorder.args = {
  value: '',
  options: [
    { value: 'vanilla', label: 'Vanilla' },
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
  ],
  noBorder: true,
  ariaLabel: 'Radios',
  name: 'radio-selection',
};

export const SmallSize = Template.bind({});
SmallSize.args = {
  value: '',
  options: [
    { value: 'vanilla', label: 'Vanilla' },
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
  ],
  noBorder: true,
  ariaLabel: 'Radios',
  name: 'radio-selection',
  size: 'small',
};

export const MultiLineLabels = Template.bind({});
MultiLineLabels.args = {
  value: '',
  options: [
    {
      label: (
        <MultiLineLabel
          bold
          primaryText="Neapolitan"
          secondaryText="Describing a combination of the flavors chocolate, vanilla, and strawberry, in order."
        />
      ),
      value: 'all',
    },
    {
      label: (
        <MultiLineLabel
          primaryText="Chocolate"
          secondaryText="Milk chocolates often give off aromas of milk or cream, or caramel or malt. Dark chocolate aromas may be characterized by toasted nuts, roasted coffee, dried fruit or wine."
        />
      ),
      value: 'chocolate',
    },
    {
      label: (
        <MultiLineLabel
          primaryText="Vanilla"
          secondaryText="The characteristic flavor of vanilla comes from the aromatic compound 'vanillin' which is described as tasting like a marshmallow."
        />
      ),
      value: 'vanilla',
    },
    {
      label: (
        <MultiLineLabel
          primaryText="Strawberry"
          secondaryText="This fruit is widely appreciated for its characteristic aroma, bright red color, juicy texture, and sweetness."
        />
      ),
      value: 'strawberry',
    },
    { value: 'none', label: 'None' },
  ],
  ariaLabel: 'Radios',
  name: 'radio-selection',
};

export const MultiLineLabelsWithHelpContent = Template.bind({});
MultiLineLabelsWithHelpContent.args = {
  value: '',
  options: [
    {
      label: (
        <MultiLineLabel primaryText="Yes" secondaryText="">
          <div>
            <p>
              We are here to help! Tens of thousands of customers use True Link to spend funds from
              a trust.
            </p>
            <p>
              <b>Did you know? </b>
              The SSA1 provides guidelines for using the True Link Visa Card to make disbursements
              from SNTs, helping family trustees feel confident and comfortable using True Link.
            </p>
          </div>
        </MultiLineLabel>
      ),
      value: 'all',
    },
  ],
  ariaLabel: 'Radios',
  name: 'radio-selection',
};
