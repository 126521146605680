import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import CardDetails from 'react/member/card/components/account_tab/card_status/CardDetails';
import CardShipment from 'react/member/card/components/account_tab/card_status/CardShipment';
import { CARD_STATUSES } from 'react/member/utils/CardStatus';

export default function Overdue({
  cardBreached,
  cardFboName,
  cardLast4,
  cardName,
  cardShipmentTrackingNumber,
  estimatedArrivalDate,
  expedited,
  expiryDate,
  oldCardLast4,
  shippingAddress,
  status,
  supportPhone,
}) {
  const formattedDate = moment(estimatedArrivalDate, 'MM/DD/YYYY').format('MMMM Do, YYYY');
  const note = `Your card should have arrived by ${formattedDate}. Please call ${supportPhone} if  the card has not yet arrived.

  Activating this card will close the current card ending in ${oldCardLast4} and transfer the balance to this card.`;

  return (
    <>
      <CardDetails
        cardBreached={cardBreached}
        cardLast4={cardLast4}
        expiryDate={expiryDate}
        note={note}
        status={status}
      />
      <CardShipment
        cardFboName={cardFboName}
        cardName={cardName}
        cardShipmentTrackingNumber={cardShipmentTrackingNumber}
        expedited={expedited}
        shippingAddress={shippingAddress}
      />
    </>
  );
}

Overdue.propTypes = {
  cardBreached: PropTypes.bool,
  cardFboName: PropTypes.string,
  cardLast4: PropTypes.string.isRequired,
  cardName: PropTypes.string.isRequired,
  cardShipmentTrackingNumber: PropTypes.string,
  estimatedArrivalDate: PropTypes.string.isRequired,
  expedited: PropTypes.bool.isRequired,
  expiryDate: PropTypes.string,
  oldCardLast4: PropTypes.string.isRequired,
  shippingAddress: PropTypes.string.isRequired,
  status: PropTypes.oneOf([CARD_STATUSES.OVERDUE_RELEASED_BACKUP_CARD_ORDER_STATUS]).isRequired,
  supportPhone: PropTypes.string.isRequired,
};
