import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import TrueLinkIcon from 'react/shared/components/true_link/main/TrueLinkIcon';
import theme, { mediumBreakpointMediaQuery } from 'react/shared/theme/Theme';
import PALETTE from 'react/shared/theme/palette';

const useStyles = makeStyles(() => ({
  cancelButton: {
    background: 'transparent',
    border: 'none',
    color: PALETTE.emerald,
    marginBottom: theme.spacing(1),
    minHeight: '23px',
    opacity: '100%',
    position: 'absolute',
    right: '5%',
    top: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      color: 'inherit',
      float: 'right',
      opacity: '65%',
      position: 'static',
    },
  },
  cancelButtonNoMobile: {
    background: 'transparent',
    border: 'none',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    color: 'inherit',
    opacity: '65%',
    position: 'static',
    marginBottom: theme.spacing(1),
  },
  cancelMobile: {
    fontSize: '1.5rem',
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

export default function CancelButton({ desktopOnly, label = 'Cancel' }) {
  const classes = useStyles();
  const desktop = useMediaQuery(mediumBreakpointMediaQuery);

  const onClick = () => {
    window.location.href = RailsRoutes.user_home_path();
  };

  return (
    <TrueLinkButton
      aria-label={label}
      className={desktopOnly ? classes.cancelButtonNoMobile : classes.cancelButton}
      onClick={onClick}
      variant="none"
    >
      <TrueLinkIcon className={classes.cancelMobile} icon="xmark" />
      {(desktopOnly || desktop) && label}
    </TrueLinkButton>
  );
}

CancelButton.propTypes = {
  desktopOnly: PropTypes.bool,
  label: PropTypes.string,
};

CancelButton.defaultProps = {
  desktopOnly: false,
};
