import React from 'react';
import BackupCardReissueStatus from './BackupCardReissueStatus';
import LegacyCardReissueStatus from './LegacyCardReissueStatus';
import ReplacementCardReissueStatus from './ReplacementCardReissueStatus';
import { accountShape } from 'react/member/card/shapes/AccountShape';
import { cardOrderShape } from 'react/member/card/shapes/CardOrderShape';
import { cardShape } from 'react/member/card/shapes/CardShape';
import { cardholderShape } from 'react/member/card/shapes/CardholderShape';
import { CARD_ORDER_TYPES } from 'react/shared/utils/CardOrderType';

export default function CardReissueStatus({ account, card, cardReissue, cardholder }) {
  const { REISSUE_C_LEGACY: C_LEGACY, REISSUE_C: C } = CARD_ORDER_TYPES;

  switch (cardReissue.type) {
    case C:
      return <BackupCardReissueStatus account={account} card={card} cardReissue={cardReissue} />;
    case C_LEGACY:
      return (
        <LegacyCardReissueStatus
          account={account}
          card={card}
          cardReissue={cardReissue}
          cardholder={cardholder}
        />
      );
    default:
      // Default is for Reissue A
      return <ReplacementCardReissueStatus account={account} />;
  }
}

CardReissueStatus.propTypes = {
  account: accountShape.isRequired,
  card: cardShape,
  cardReissue: cardOrderShape.isRequired,
  cardholder: cardholderShape.isRequired,
};
