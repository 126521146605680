import PropTypes from 'prop-types';
import React from 'react';
import TransferOptionsMenu from './TransferOptionsMenu';
import TransferStatusLabel from 'react/member/card/components/funding_tab/transfers_table/action_cells/transfer_status_labels/TransferStatusLabel';
import { transferShape } from 'react/member/card/shapes/TransferShape';
import {
  isCancelled,
  isCancelable,
  isPrepared,
  isEmergencyFunded,
  isEmergencyCreditable,
  isReady,
} from 'react/member/utils/Transfer';

export default function TransferOptionsCellContent({
  isFullyFunded,
  onEditOneTime,
  onEditRecurring,
  onEmergencyFund,
  onFixHolidayImpact,
  onRevert,
  onSkipCancel,
  onUnskip,
  transfer,
}) {
  if (isReady(transfer) || isCancelled(transfer) || isEmergencyFunded(transfer)) {
    return (
      <TransferStatusLabel
        onEmergencyFund={onEmergencyFund}
        onUnskip={onUnskip}
        transfer={transfer}
      />
    );
  } else if (isPrepared(transfer)) {
    return (
      <span>
        <TransferStatusLabel
          onEmergencyFund={onEmergencyFund}
          onUnskip={onUnskip}
          transfer={transfer}
        />
        <br />
        {(isCancelable(transfer) || isEmergencyCreditable(transfer)) && (
          <TransferOptionsMenu
            isFullyFunded={isFullyFunded}
            onEditOneTime={onEditOneTime}
            onEditRecurring={onEditRecurring}
            onEmergencyFund={onEmergencyFund}
            onFixHolidayImpact={onFixHolidayImpact}
            onRevert={onRevert}
            onSkipCancel={onSkipCancel}
            transfer={transfer}
          />
        )}
      </span>
    );
  } else if (!transfer.editable) {
    return null;
  }
  return (
    <TransferOptionsMenu
      isFullyFunded={isFullyFunded}
      onEditOneTime={onEditOneTime}
      onEditRecurring={onEditRecurring}
      onEmergencyFund={onEmergencyFund}
      onFixHolidayImpact={onFixHolidayImpact}
      onRevert={onRevert}
      onSkipCancel={onSkipCancel}
      transfer={transfer}
    />
  );
}

TransferOptionsCellContent.propTypes = {
  isFullyFunded: PropTypes.bool.isRequired,
  onEditOneTime: PropTypes.func.isRequired,
  onEditRecurring: PropTypes.func.isRequired,
  onEmergencyFund: PropTypes.func.isRequired,
  onFixHolidayImpact: PropTypes.func.isRequired,
  onRevert: PropTypes.func.isRequired,
  onSkipCancel: PropTypes.func.isRequired,
  onUnskip: PropTypes.func.isRequired,
  transfer: transferShape.isRequired,
};
