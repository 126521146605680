import PropTypes from 'prop-types';
import React, { useState } from 'react';
import DashboardActions from 'react/member/actions/dashboard_actions';
import RevertToOriginalModal from 'react/member/card/components/funding_tab/transfers/RevertToOriginalModal';
import HolidayImpactedTransferTooltip from 'react/member/card/components/funding_tab/transfers_table/tooltips/HolidayImpactedTransferTooltip';
import HolidayMitigatedTransferTooltip from 'react/member/card/components/funding_tab/transfers_table/tooltips/HolidayMitigatedTransferIcon';
import SingleTransferHolidayContainer from 'react/member/card/components/holidays/SingleTransferHolidayContainer';
import { transferShape } from 'react/member/card/shapes/TransferShape';
import { beforeFlipDate } from 'react/member/utils/Transfer';

export default function TransferIconCell({ transfer, tooltipOn, className, color }) {
  const [showImpactedTransferModal, setImpactedTransferModal] = useState(false);
  const handleOpenImpactedTransferModal = () => setImpactedTransferModal(true);
  const handleCloseImpactedTransferModal = () => {
    setImpactedTransferModal(false);
    DashboardActions.fetchScheduledTransfers();
    DashboardActions.reloadHolidayBanner();
  };

  const [showRevertToOriginalModal, setRevertToOriginalModal] = useState(false);
  const handleOpenRevertToOriginalModal = () => setRevertToOriginalModal(true);
  const handleCloseRevertToOriginalModal = () => setRevertToOriginalModal(false);

  if (tooltipOn && className === 'fa-triangle-exclamation' && transfer) {
    return (
      <div className={`transfer-icon-cell-${transfer.id}`}>
        <HolidayImpactedTransferTooltip
          beforeFlipDate={beforeFlipDate(transfer)}
          color={color}
          holidayName={transfer.holidayImpactedDate.holiday.name}
          onClick={handleOpenImpactedTransferModal}
          placement="right"
          transferId={transfer.id}
        />
        {showImpactedTransferModal && (
          <SingleTransferHolidayContainer
            holidayImpactedTransfer={transfer}
            onClose={handleCloseImpactedTransferModal}
          />
        )}
      </div>
    );
  }

  if (tooltipOn && className === 'fa-circle-info' && transfer) {
    return (
      <div className={`transfer-icon-cell-${transfer.id}`}>
        <HolidayMitigatedTransferTooltip
          color={color}
          guidanceType={transfer.guidanceType}
          holidayName={transfer.holidayImpactedDate.holiday.name}
          onClick={handleOpenRevertToOriginalModal}
          transferId={transfer.id}
        />
        {showRevertToOriginalModal && (
          <RevertToOriginalModal onClose={handleCloseRevertToOriginalModal} transfer={transfer} />
        )}
      </div>
    );
  }

  return <i className={`fa ${className}`} style={{ color: `${color}` }} />;
}

TransferIconCell.propTypes = {
  transfer: transferShape,
  tooltipOn: PropTypes.bool.isRequired,
  className: PropTypes.string.isRequired,
  color: PropTypes.string,
};
