import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React from 'react';
import TrueLinkChip from 'react/shared/components/true_link/main/TrueLinkChip';

function IndividualTrustNameCell({ cell: { value }, row: { original } }) {
  if (!original.trust_beneficiaries[0]) {
    return value;
  }
  let badge;

  if (original.event_notification_count > 0) {
    const badgeText = `${original.event_notification_count} new ${pluralize(
      'deposit',
      original.event_notification_count,
    )}`;
    const depositsUrl = RailsRoutes.dashboard_organization_client_path(
      original.organization.slug,
      original.trust_beneficiaries[0],
      { anchor: 'client-deposits-tab' },
    );
    badge = (
      <TrueLinkChip
        clickable
        color="primary"
        component="a"
        href={depositsUrl}
        label={badgeText}
        size="small"
      />
    );
  }

  return (
    <>
      <a
        href={RailsRoutes.dashboard_organization_client_path(
          original.organization.slug,
          original.trust_beneficiaries[0],
        )}
      >
        {value}
      </a>
      {badge}
    </>
  );
}

IndividualTrustNameCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      trust_beneficiaries: PropTypes.array.isRequired,
      organization: PropTypes.shape({ slug: PropTypes.string }).isRequired,
      event_notification_count: PropTypes.number,
    }).isRequired,
  }).isRequired,
  cell: PropTypes.shape({
    value: PropTypes.string,
  }).isRequired,
};

export default IndividualTrustNameCell;
