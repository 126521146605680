import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import RecurringTransferEditOptionsModal from './RecurringTransferEditOptionsModal';
import DashboardActions from 'react/member/actions/dashboard_actions';
import api from 'react/member/card/components/funding_tab/api';
import AutoTransferDeleteModal from 'react/member/card/components/funding_tab/auto_transfers/AutoTransferDeleteModal';
import AutoTransferReviewChangesModal from 'react/member/card/components/funding_tab/auto_transfers/AutoTransferReviewChangesModal';
import AutoTransferUpsertModal from 'react/member/card/components/funding_tab/auto_transfers/AutoTransferUpsertModal';
import TransferFundsModal from 'react/member/card/components/funding_tab/transfers/TransferFundsModal';
import TransferSkipCancelUnskipModal from 'react/member/card/components/funding_tab/transfers/TransferSkipCancelUnskipModal';
import SingleTransferHolidayContainer from 'react/member/card/components/holidays/SingleTransferHolidayContainer';
import { transferShape } from 'react/member/card/shapes/TransferShape';
import DashboardAutoTransferStore from 'react/member/stores/DashboardAutoTransferStore';
import { isRecurring } from 'react/member/utils/Transfer';

export default function RecurringTransferEditOptionsContainer({
  cardholderName,
  cardStatus,
  emergencyFundingEnabled,
  fundingType,
  onClose,
  transfer,
}) {
  const autoTransfer = DashboardAutoTransferStore.findAutoTransfer(transfer.autoTransferId);
  const editTransfersFlag = $tlf?.flipperEnableEditTransfersFlag;
  // Hooks
  // -----------------------------------------------------
  const [showDeleteSeriesModal, setDeleteSeriesModal] = useState(false);
  const [showEditInstanceModal, setEditInstanceModal] = useState(false);
  const [showEditSeriesModal, setEditSeriesModal] = useState(false);
  const [showReviewChangesModal, setReviewChangesModal] = useState(false);
  const [showHolidayImpactedTransferModal, setHolidayImpactedTransferModal] = useState(false);
  const [holidayImpactedTransfer, setHolidayImpactedTransfer] = useState();
  const [showSkipCancelModal, setSkipCancelModal] = useState(false);

  useEffect(
    () =>
      function cleanup() {
        onClose();
      },
    [onClose],
  );

  // Guards
  // -----------------------------------------------------
  if (!isRecurring(transfer)) return null;

  // Handlers
  // -----------------------------------------------------
  // AutoTransferUpsertModal
  const handleOpenEditInstanceModal = () => setEditInstanceModal(true);
  const handleCloseEditInstanceModal = () => setEditInstanceModal(false);

  // AutoTransferReviewChangesModal
  const handleOpenReviewChangesModal = () => setReviewChangesModal(true);
  const handleCloseReviewChangesModal = () => {
    setReviewChangesModal(false);
    // defer Recurring Transfer refresh until Review Changes modal is closed,
    // since the table row containing this modal will disappear
    DashboardActions.fetchScheduledTransfers();
    DashboardActions.reloadHolidayBanner();
    onClose();
  };

  // AutoTransferUpsertModal
  const handleOpenEditSeriesModal = () => setEditSeriesModal(true);
  const handleCloseEditSeriesModal = () => setEditSeriesModal(false);
  const handleUpdateSeriesSuccess = (data) => {
    handleCloseEditSeriesModal();
    if (data.updatedRecurrenceInformation) {
      if (data.upcomingHolidayImpactedTransfer) {
        setHolidayImpactedTransfer(data.upcomingHolidayImpactedTransfer);
        setHolidayImpactedTransferModal(true);
      } else {
        handleOpenReviewChangesModal();
      }
    } else {
      DashboardActions.fetchScheduledTransfers();
      DashboardActions.reloadHolidayBanner();
      onClose();
    }
  };

  // SingleTransferHolidayContainer
  const handleCloseHolidayImpactedTransferModal = () => {
    setHolidayImpactedTransferModal(false);
    // similar to review modal above, defer reloading transfers to after holiday
    // guidance has been actioned
    DashboardActions.fetchScheduledTransfers();
    DashboardActions.reloadHolidayBanner();
    onClose();
  };

  // AutoTransferDeleteModal
  const handleOpenDeleteSeriesModal = () => setDeleteSeriesModal(true);
  const handleCloseDeleteSeriesModal = () => setDeleteSeriesModal(false);
  const handleDeleteSeries = () => {
    DashboardActions.deleteAutoTransfer(autoTransfer.id, () => {
      handleCloseDeleteSeriesModal();
      onClose();
    });
  };

  // TransferSkipCancelUnskipModal
  const handleOpenSkipCancelModal = () => setSkipCancelModal(true);
  const handleCloseSkipCancelModal = () => setSkipCancelModal(false);
  const handleSkipCancelTransfer = () => {
    api.toggleTransferCancellation(transfer.id).then(() => {
      handleCloseSkipCancelModal();
      DashboardActions.fetchScheduledTransfers();
      DashboardActions.reloadHolidayBanner();
      const formattedDate = moment(transfer.dateUnformatted).format('dddd, MMMM D');
      const action = isRecurring(transfer) ? 'skipped' : 'cancelled';
      const msg = `The $${transfer.amount} transfer on ${formattedDate} has been ${action}`;
      Truelink.flash('success', msg, true, true);
      onClose();
    });
  };

  if (showEditInstanceModal && !editTransfersFlag) {
    return (
      <AutoTransferUpsertModal
        autoTransfer={autoTransfer}
        bankAccount={autoTransfer.bankAccount}
        edit
        onBack={handleCloseEditInstanceModal}
        onClose={onClose}
        recurringTransferInstance={transfer}
      />
    );
  }

  if (showEditInstanceModal && editTransfersFlag) {
    return (
      <TransferFundsModal
        bankAccount={transfer.bankAccount}
        cardStatus={cardStatus}
        cardholderName={transfer.cardholder?.name}
        editInstance
        emergencyFundingEnabled={emergencyFundingEnabled}
        fundingType={fundingType}
        onBack={handleCloseEditInstanceModal}
        onClose={handleCloseEditInstanceModal}
        onDelete={handleOpenSkipCancelModal}
        onSuccess={onClose}
        transfer={transfer}
      />
    );
  }

  if (showEditSeriesModal && !editTransfersFlag) {
    return (
      <AutoTransferUpsertModal
        autoTransfer={autoTransfer}
        bankAccount={autoTransfer.bankAccount}
        edit
        onBack={handleCloseEditSeriesModal}
        onClose={handleCloseEditSeriesModal}
        onDelete={handleOpenDeleteSeriesModal}
        onUpdateSuccess={handleUpdateSeriesSuccess}
      />
    );
  }

  if (showEditSeriesModal && editTransfersFlag) {
    return (
      <TransferFundsModal
        bankAccount={transfer.bankAccount}
        cardStatus={cardStatus}
        cardholderName={transfer.cardholder?.name}
        emergencyFundingEnabled={emergencyFundingEnabled}
        fundingType={fundingType}
        onBack={handleCloseEditSeriesModal}
        onClose={handleCloseEditSeriesModal}
        onDelete={handleOpenDeleteSeriesModal}
        onSuccess={handleUpdateSeriesSuccess}
        transfer={autoTransfer}
      />
    );
  }

  if (showReviewChangesModal) {
    return (
      <AutoTransferReviewChangesModal
        cardholderName={cardholderName}
        onClose={handleCloseReviewChangesModal}
      />
    );
  }

  if (showDeleteSeriesModal) {
    return (
      <AutoTransferDeleteModal
        autoTransfer={autoTransfer}
        onClose={onClose}
        onConfirm={handleDeleteSeries}
      />
    );
  }

  if (showHolidayImpactedTransferModal) {
    return (
      <SingleTransferHolidayContainer
        holidayImpactedTransfer={holidayImpactedTransfer}
        onClose={handleCloseHolidayImpactedTransferModal}
      />
    );
  }
  if (showSkipCancelModal) {
    return (
      <TransferSkipCancelUnskipModal
        onClose={handleCloseSkipCancelModal}
        onConfirm={handleSkipCancelTransfer}
        transfer={transfer}
      />
    );
  }

  return (
    <RecurringTransferEditOptionsModal
      onClose={onClose}
      onEditInstance={handleOpenEditInstanceModal}
      onEditSeries={handleOpenEditSeriesModal}
      transfer={transfer}
    />
  );
}

RecurringTransferEditOptionsContainer.propTypes = {
  cardholderName: PropTypes.string,
  cardStatus: PropTypes.string,
  emergencyFundingEnabled: PropTypes.bool,
  fundingType: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  transfer: transferShape.isRequired,
};
