import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { originShape } from './OriginShape';
import EntityTypeRadios from './request_modal/EntityTypeRadios';
import QuarterlyStatementDateDropdown from './request_modal/QuarterlyStatementDateDropdown';
import TimePeriod from './request_modal/TimePeriod';
import api from './request_modal/api';
import ReportDownloader from './request_modal/report_downloader';
import { originTypes, reportNames } from 'react/member/components/dashboard/reports/Constants';
import RadioDropdownCollectionsShape from 'react/member/components/dashboard/reports/RadioDropdownCollectionsShape';
import LoadingIndicator from 'react/shared/components/LoadingIndicator';
import TrueLinkModal from 'react/shared/components/true_link/lab/TrueLinkModal';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
const useStyles = makeStyles({
  formActions: {
    alignItems: 'center',
    display: 'flex',
    marginTop: 10,
  },
});

export default function ReportRequestModal({ entityCollections, onClose, origin, report }) {
  const classes = useStyles();
  const today = new Date().toISOString().split('T')[0];
  const [quarterlyStatementDates, setQuarterlyStatementDates] = useState(
    origin.quarterlyStatementDates,
  );
  // We can't always default the entity to org because some reports don't support being queried by org
  const disallowOrgEntity =
    !report.supportsSearchBy.organization && origin.type === originTypes.organization;
  let initialValues = {
    entityType: disallowOrgEntity ? '' : origin.type,
    entityId: disallowOrgEntity ? '' : origin.id,
    reportType: report.type,
    startDate: today,
    endDate: today,
    minDate: '',
    maxDate: today,
    quarterlyDate: quarterlyStatementDates ? quarterlyStatementDates[0] : '',
  };

  const restrictDatesToReleasedStatementDates = [
    reportNames.customStatements,
    reportNames.disbursementsByCategory,
  ].includes(report.name);

  if (restrictDatesToReleasedStatementDates) {
    const updateValues = {
      startDate: origin.customStatementDates.startDate,
      endDate: origin.customStatementDates.maxDate,
      minDate: origin.customStatementDates.minDate,
      maxDate: origin.customStatementDates.maxDate,
    };

    initialValues = { ...initialValues, ...updateValues };
  }

  const downloader = new ReportDownloader();
  const popUpCloseHandler = () => {
    downloader.cancelRequest();
    onClose();
  };

  const updateEntityId = (event, formikChangeHandler, fieldSetter) => {
    const isOrgEntity = event.target.value === originTypes.organization;
    const resetId = isOrgEntity ? origin.id : '';
    fieldSetter('entityId', resetId);
    fieldSetter('startDate', origin.customStatementDates.startDate);
    fieldSetter('endDate', origin.customStatementDates.maxDate);
    fieldSetter('minDate', origin.customStatementDates.minDate);
    fieldSetter('maxDate', origin.customStatementDates.maxDate);

    if (isOrgEntity) {
      setQuarterlyStatementDates(origin.quarterlyStatementDates);
      fieldSetter('quarterlyDate', origin.quarterlyStatementDates[0]);
    }

    formikChangeHandler(event);
  };

  const onDropdownChange = ({ selectedItems, entityType, setFieldValue }) => {
    if (origin.type === originTypes.organization) {
      if (selectedItems.length) {
        const entitySlug = selectedItems[0].id;

        if (report.datePickerFormat) {
          api.getEntityDateRange(entityType, entitySlug).then((response) => {
            const { startDate, minDate, maxDate } = response.data.availableCustomStatementDates;
            if (report.name === reportNames.quarterlyStatements) {
              setQuarterlyStatementDates(response.data.quarterlyStatementDates);
              setFieldValue('quarterlyDate', response.data.quarterlyStatementDates[0]);
            }
            setFieldValue('entityId', entitySlug);
            setFieldValue('startDate', startDate);
            setFieldValue('endDate', maxDate);
            setFieldValue('minDate', minDate);
            setFieldValue('maxDate', maxDate);
          });
        } else {
          setFieldValue('entityId', entitySlug);
        }
      }
    }
  };

  return (
    <TrueLinkModal
      handleClose={popUpCloseHandler}
      scrollable={false}
      title={`Preparing "${report.name}" report`}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          const doneHandler = ({ isSuccessful = false, isError = false, errorMessage = '' }) => {
            actions.setSubmitting(false);
            if (isSuccessful) {
              Truelink.flash('success', 'Report created! Downloading now.');
              onClose();
            } else if (isError) {
              Truelink.flash('error', `Error: ${errorMessage}`);
            }
          };

          downloader.requestReport(doneHandler, values, actions.setSubmitting);
        }}
      >
        {({ handleChange, values, setFieldValue, isSubmitting }) => {
          const validSelection = values.entityId || values.entityType === originTypes.organization;
          const disableSubmit = isSubmitting || !validSelection;
          return (
            <Form>
              <EntityTypeRadios
                entityCollections={entityCollections}
                onDropdownChange={(selectedItems) =>
                  onDropdownChange({
                    selectedItems,
                    entityType: values.entityType,
                    setFieldValue,
                  })
                }
                onRadioChange={(event) => updateEntityId(event, handleChange, setFieldValue)}
                origin={origin}
                reportSupportsSearchBy={report.supportsSearchBy}
                setFieldValue={setFieldValue}
                value={values.entityType}
              />
              {report.datePickerFormat !== 'quarter' && (
                <TimePeriod
                  format={report.datePickerFormat}
                  quarterlyDate={values.quarterlyDate}
                  rangeEndDate={values.endDate}
                  rangeMaxDate={values.maxDate}
                  rangeMinDate={values.minDate}
                  rangeStartDate={values.startDate}
                />
              )}
              {report.datePickerFormat === 'quarter' && (
                <QuarterlyStatementDateDropdown
                  handleChange={handleChange}
                  latestStatementEndDate={origin.latestTrustStatementEndDate}
                  quarterlyDate={values.quarterlyDate}
                  quarterlyStatementDates={quarterlyStatementDates}
                />
              )}
              <div className={classes.formActions}>
                <TrueLinkButton disabled={disableSubmit} type="submit" variant="primary">
                  {isSubmitting ? 'Downloading' : 'Download'}
                </TrueLinkButton>
                {isSubmitting && (
                  <>
                    <LoadingIndicator
                      containerStyle={{
                        display: 'inline',
                        marginLeft: '1em',
                        maxWidth: 'fit-content',
                      }}
                    />
                    {'Don’t leave the page, this shouldn’t take long...'}
                  </>
                )}
              </div>

              <Typography variant="caption">
                If you need assistance pulling reports for your organization, please contact{' '}
                <a href="mailto:trustsupport@truelinkfinancial.com">
                  trustsupport@truelinkfinancial.com
                </a>
              </Typography>
            </Form>
          );
        }}
      </Formik>
    </TrueLinkModal>
  );
}

ReportRequestModal.propTypes = {
  entityCollections: RadioDropdownCollectionsShape.isRequired,
  onClose: PropTypes.func.isRequired,
  origin: originShape,
  report: PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    datePickerFormat: PropTypes.string,
    supportsSearchBy: PropTypes.shape({
      beneficiary: PropTypes.bool.isRequired,
      organization: PropTypes.bool.isRequired,
      portfolio: PropTypes.bool.isRequired,
      trust: PropTypes.bool.isRequired,
      trustType: PropTypes.string,
    }).isRequired,
  }).isRequired,
};
