import PropTypes from 'prop-types';
import React, { useState } from 'react';
import AddressInputs from './AddressInputs';
import PhoneDob from './PhoneDob';
import { requiredAddressShape } from './Shapes';
import ShippingAddressInputs from './ShippingAddressInputs';

export default function BeneAddressInputs({
  onAddressInputChange,
  administratorAddress,
  beneficiaryAddress,
}) {
  const [beneLivesWithALR, setBeneLivesWithALR] = useState(false);

  const handleBeneAtAlrAddress = (evt) => {
    setBeneLivesWithALR(evt.target.checked);
  };

  return (
    <div>
      <div className="card--form__section">
        <div className="card--form__row">
          <div className="card--form__col width-100">
            <label className="card--form__checkbox-label" htmlFor="beneLivesWithALR">
              <input id="beneLivesWithALR" onChange={handleBeneAtAlrAddress} type="checkbox" /> The
              Beneficiary lives with the Authorized Legal Representative.
            </label>
          </div>
        </div>
        <AddressInputs
          address={beneLivesWithALR ? administratorAddress : beneficiaryAddress}
          labelPrefix="Beneficiary"
          namePrefix="beneficiary"
          onInputChange={onAddressInputChange}
          readonly={beneLivesWithALR}
        />
        <PhoneDob inputNamePrefix="beneficiary" labelPrefix="Beneficiary" />
      </div>
      <ShippingAddressInputs />
    </div>
  );
}

BeneAddressInputs.propTypes = {
  administratorAddress: requiredAddressShape.isRequired,
  beneficiaryAddress: requiredAddressShape.isRequired,
  onAddressInputChange: PropTypes.func.isRequired,
};
