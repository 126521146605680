import PropTypes from 'prop-types';
import React from 'react';
import AccountListTable from './AccountListTable';
import DashboardInProductAd from 'react/member/components/in_product_ads/DashboardInProductAd';
import InProductAdShape from 'react/member/shapes/in_product_ad_shape';

export default function AccountsTabContainer({
  showAccountId,
  showLatestTransfer,
  showRefreshButton,
  inProductAdForAccountsList,
  inProductAd,
  accountListMessage,
}) {
  // All of the Route calls and data polling for AccountListTable && Datatable should be here

  let dashboardInProductAd;
  if (inProductAdForAccountsList) {
    dashboardInProductAd = (
      <div style={{ marginTop: 11 }}>
        <DashboardInProductAd inProductAd={inProductAd} />
      </div>
    );
  }

  return (
    <div>
      {dashboardInProductAd}
      <AccountListTable
        accountListMessage={accountListMessage}
        showAccountId={showAccountId}
        showLatestTransfer={showLatestTransfer}
        showRefreshButton={showRefreshButton}
      />
    </div>
  );
}

AccountsTabContainer.propTypes = {
  // AccountListTable
  showAccountId: PropTypes.bool.isRequired,
  showLatestTransfer: PropTypes.bool.isRequired,
  showRefreshButton: PropTypes.bool.isRequired,
  accountListMessage: PropTypes.string,
  // DashboardInProductAd
  inProductAdForAccountsList: PropTypes.bool.isRequired,
  inProductAd: InProductAdShape,
};
