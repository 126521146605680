import { bool, number, string, shape, oneOf } from 'prop-types';
import { estimatedArrivalShapeCamelized } from 'react/member/shapes/estimated_arrival_shape';
import addressShape from 'react/shared/shapes/address_shape';
import shippingDestinationShape from 'react/shared/shapes/shipping_destination_shape';
import { CARD_ORDER_TYPES } from 'react/shared/utils/CardOrderType';

export const cardOrderShape = shape({
  estimatedArrival: estimatedArrivalShapeCamelized,
  expediteShipping: bool,
  id: number,
  newAccountId: string,
  oldAccountId: string,
  requestedBy: shape({
    address: addressShape,
    banned: bool,
    email: string,
    fullName: string,
    mobile: string,
    slug: string,
  }),
  shippingDestination: shippingDestinationShape,
  status: string,
  tlCardProgram: string,
  type: oneOf(Object.values(CARD_ORDER_TYPES)),
});
