import PropTypes from 'prop-types';
import React from 'react';
import AreYouSurePopUp from 'react/shared/components/popups/AreYouSurePopUp';
import bindAll from 'react/shared/utils/bind_all';

export default class DisbursementAttachment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
    };

    bindAll(this);
  }

  onDestroy(evt) {
    evt.preventDefault();
    const { disbursement, disbursementCategoryAmount, attachment, actions } = this.props;
    if (disbursementCategoryAmount) {
      actions.destroyBudgetItemAttachment(attachment.id);
    } else if (disbursement) {
      actions.destroyBudgetItemAttachmentForBudgetItem(attachment.id, disbursement);
    } else {
      actions.destroyBudgetItemAttachment(attachment.id);
    }
    this.closeDestructionModal();
  }

  closeDestructionModal() {
    this.setState({ showModal: false });
  }

  showDestructionModal(evt) {
    evt.stopPropagation();
    this.setState({ showModal: true });
  }

  renderRemoveButton() {
    const { attachment, categoryNumber, disbursement } = this.props;
    if (disbursement && disbursement.can_add_documentation === false) {
      return null;
    }

    const inputName = `attachment_ids[${categoryNumber}][]`;
    return (
      <td className="align-right" style={{ width: 75 }}>
        <a
          onClick={this.showDestructionModal}
          onKeyUp={this.showDestructionModal}
          role="link"
          tabIndex="0"
        >
          <i className="trash" style={{ marginTop: -5 }} />
        </a>
        <input defaultValue={attachment.id} name={inputName} style={{ display: 'none' }} />
        {this.state.showModal && (
          <AreYouSurePopUp
            key={attachment.id}
            messageText={'Are you sure you want to delete this document?'}
            onClose={this.closeDestructionModal}
            onSubmit={this.onDestroy}
            openModal={this.state.showModal}
            submitText={'Yes, delete'}
          />
        )}
      </td>
    );
  }

  render() {
    let smartpayablesInsertControl;
    let inputName;
    const { attachment, readOnly } = this.props;

    if (readOnly) {
      inputName = `attachment_smartpayables_insert_ids[${this.props.categoryNumber}][]`;
      smartpayablesInsertControl =
        // Temporarily need to handle both of these until all attachment APIs are on V2
        attachment.smartpayables_insert || attachment.smartpayablesInsert ? (
          <span>
            <i className="icon-checkmark" />
            <input
              key={attachment.id}
              name={inputName}
              type="hidden"
              value={attachment.id.toString()}
            />
          </span>
        ) : undefined;
    } else if (
      // Temporarily need to handle both of these until all attachment APIs are on V2
      attachment.content_type === 'application/pdf' ||
      attachment.contentType === 'application/pdf'
    ) {
      inputName = `attachment_smartpayables_insert_ids[${this.props.categoryNumber}][]`;
      smartpayablesInsertControl = (
        <input
          disabled={this.props.insertDisabled}
          key={attachment.id}
          name={inputName}
          onChange={this.props.checkboxOnchange}
          type="checkbox"
          value={attachment.id.toString()}
        />
      );
    } else {
      smartpayablesInsertControl = (
        <span className="italic">File must be a PDF to include as insert</span>
      );
    }

    const url = RailsRoutes.api_v2_attachment_path(attachment.id);

    return (
      <tr key={attachment.id}>
        <td className="break-word">
          <a className="inline-block" href={url}>
            {' '}
            {attachment.name}
          </a>
        </td>
        <td style={{ width: 300 }}>
          <div className="inline-block">{smartpayablesInsertControl}</div>
        </td>
        {this.renderRemoveButton()}
      </tr>
    );
  }
}

DisbursementAttachment.propTypes = {
  attachment: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  checkboxOnchange: PropTypes.func.isRequired,
  disbursement: PropTypes.object,
  disbursementCategoryAmount: PropTypes.object,
  insertDisabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  categoryNumber: PropTypes.number,
};
