import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import theme from 'react/shared/theme/Theme';
import PALETTE from 'react/shared/theme/palette';

const useStyles = makeStyles(() => ({
  section: {
    marginTop: '54px',
    [theme.breakpoints.down('md')]: {
      padding: '10px',
    },
  },
  container: {
    maxWidth: '70rem',
  },
  items: {
    marginTop: '30px',
  },
  item: {
    marginBottom: '1rem',
  },
  title: {
    color: PALETTE.emerald,
    marginBottom: '8px',
  },
  order: {
    width: '37px',
    height: '37px',
    color: PALETTE.emerald,
    borderRadius: '25px',
    background: PALETTE.emeraldLight,
    textAlign: 'center',
    padding: '5px',
    marginRight: '20px',
  },
  waiver: {
    textAlign: 'center',
    marginTop: '50px',
    color: PALETTE.grey3,
  },
}));

export default function HowItWorksReferralCard(props) {
  const cardsContent = [
    {
      order: 1,
      title: 'Share your referral link',
      content:
        'Share your unique referral link with family, friends, or those in your network who could benefit from True Link.',
    },
    {
      order: 2,
      title:
        props.referrerType == 'consumer'
          ? 'Three months of your True Link fees will be waived*'
          : 'They sign up',
      content:
        props.referrerType == 'consumer'
          ? 'When someone activates their new Visa card via your unique referral link, your next three months of True Link fees will be waived.'
          : 'They can use your unique referral link to get started.',
    },
    {
      order: 3,
      title:
        props.referrerType == 'consumer'
          ? 'Friends get fees waived too'
          : 'They get three months of fees waived*',
      content:
        props.referrerType == 'consumer'
          ? 'Your referral receives three months of True Link fees waived after they activate their new True Link Visa Card.'
          : 'Your referral receives three months of True Link fees waived when they activate their new True Link Visa Card.',
    },
  ];
  const waiver =
    '*True Link will waive its monthly fee for three consecutive months once the new Visa card has been activated. This offer applies to new True Link Visa card administrators, customers, or first-time cardholders, and will only apply to the first activated True Link Visa card.';
  const classes = useStyles();
  return (
    <div className={classes.section} data-testid="howItWorksReferralCard">
      <Typography variant="h4">How it works</Typography>

      <div className={classes.container}>
        <Grid className={classes.items} container justifyContent="space-between" spacing={3}>
          {cardsContent.map((item, index) => (
            <Grid
              className={classes.item}
              container
              data-testid="cardItem"
              item
              key={index}
              md={4}
              sm={12}
            >
              <Grid item md={2}>
                <div className={classes.order}>{item.order}</div>
              </Grid>
              <Grid item xs={10}>
                <Typography className={classes.title} component="div" variant="body2">
                  <b>{item.title}</b>
                </Typography>
                <Typography component="div" variant="caption">
                  {item.content}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
        <div className={classes.waiver}>
          <Typography variant="caption">{waiver}</Typography>
        </div>
      </div>
    </div>
  );
}

HowItWorksReferralCard.propTypes = {
  referrerType: PropTypes.oneOf(['consumer', 'professional']).isRequired,
};
