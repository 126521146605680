import PropTypes from 'prop-types';
import React from 'react';
import _map from 'underscore/modules/map';
import bindAll from 'react/shared/utils/bind_all';

export default class PaymentMethodDropdown extends React.Component {
  static get propTypes() {
    return {
      label: PropTypes.string,
      paymentMethod: PropTypes.string,
      handlePaymentMethod: PropTypes.func.isRequired,
      customStyle: PropTypes.object,
      displayedPaymentMethods: PropTypes.array,
      hideLabel: PropTypes.bool,
      isEditable: PropTypes.bool,
      isDeposit: PropTypes.bool,
      // In specific cases (e.g., bene portal), we want the Check payment method to display as "Check" and
      // never "Check (True Link Fulfills)"
      forceCheckPlainDisplay: PropTypes.bool,
      formErrors: PropTypes.shape({
        payment_method: PropTypes.string,
      }),
    };
  }

  static get defaultProps() {
    return {
      object: {},
      isDeposit: false,
      forceCheckPlainDisplay: false,
    };
  }

  constructor(props) {
    super(props);

    this.state = {
      lockPaymentMethod: !!(props.paymentMethod && !props.isEditable),
      paymentMethod: props.paymentMethod,
    };
    bindAll(this);
  }

  componentDidMount() {
    if (this.state.lockPaymentMethod) {
      this.props.handlePaymentMethod({
        target: { value: this.props.paymentMethod },
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.paymentMethod !== this.props.paymentMethod) {
      // This is legacy behavior and would require refactoring the state structure to fix. New behavior should not thrash the render cycle this way.
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        paymentMethod: this.props.paymentMethod,
      });
    }
  }

  formatPaymentMethod(paymentMethodKey) {
    let paymentMethodValue;
    if (
      (this.props.isDeposit || this.props.forceCheckPlainDisplay) &&
      paymentMethodKey === 'Check'
    ) {
      paymentMethodValue = 'Check';
    } else {
      paymentMethodValue = $tlf.domains.paymentMethodsMap[paymentMethodKey];
    }
    return (
      <option id={paymentMethodKey} key={paymentMethodKey} value={paymentMethodKey}>
        {paymentMethodValue}
      </option>
    );
  }

  loadPaymentMethods() {
    const supportedPaymentMethods = this.props.displayedPaymentMethods;

    return _map(supportedPaymentMethods, (supportedPaymentMethod) =>
      this.formatPaymentMethod(supportedPaymentMethod),
    );
  }

  label() {
    const label = this.props.label ? this.props.label : 'Payment Type';
    if (!this.props.hideLabel) {
      return <label htmlFor="payee_payment_method">{label}</label>;
    }
    return null;
  }

  showPaymentMethods() {
    if (this.state.lockPaymentMethod) {
      const paymentMethodValue = $tlf.domains.paymentMethodsMap[this.props.paymentMethod];
      return (
        <div>
          {paymentMethodValue}
          <input name="payment_method" type="hidden" value={this.state.paymentMethod} />
        </div>
      );
    }
    return (
      <select
        aria-label="Payment Type"
        className="form-control"
        id="payee_payment_method"
        name="payment_method"
        onChange={this.props.handlePaymentMethod}
        style={this.props.customStyle}
        value={this.state.paymentMethod}
      >
        <option value="">Select One</option>
        {this.loadPaymentMethods()}
      </select>
    );
  }

  render() {
    if (this.props.hideLabel) {
      return <div className="new-form__data">{this.showPaymentMethods()}</div>;
    }
    return (
      <div className="new-form-field">
        {!this.props.hideLabel && <div className="new-form__label">{this.label()}</div>}
        <div className="new-form__data">
          {this.showPaymentMethods()}
          {this.props.formErrors && this.props.formErrors.payment_method && (
            <p className="form-error">{this.props.formErrors.payment_method}</p>
          )}
        </div>
      </div>
    );
  }
}
