import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import DashboardActions from 'react/member/actions/dashboard_actions';
import trustStatementRecipientShape from 'react/member/shapes/TrustStatementRecipientShape';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';

export default function DashboardTrustBeneficiaryProfileTrustStatementRecipientsDestroyForm({
  trustStatementRecipient,
  trustBeneficiary,
}) {
  const [isSubmitting, updateSubmitting] = useState(false);
  const onSubmit = (ev) => {
    ev.preventDefault();
    updateSubmitting(true);
    DashboardActions.destroyTrustStatementRecipient(
      trustBeneficiary.slug,
      trustStatementRecipient,
      () => {
        updateSubmitting(false);
      },
    );
  };

  return (
    <div>
      <div>
        Are you sure you wish to remove {trustStatementRecipient.name} as a statement recipient?
      </div>
      <div>
        <form onSubmit={onSubmit}>
          <div className="tl-form-actions-row">
            <TrueLinkButton
              className={classNames('btn btn-danger', {
                disabled: isSubmitting,
              })}
              disabled={isSubmitting}
              type="submit"
              variant="none"
            >
              Remove Statement Recipient
            </TrueLinkButton>
          </div>
        </form>
      </div>
    </div>
  );
}

DashboardTrustBeneficiaryProfileTrustStatementRecipientsDestroyForm.propTypes = {
  trustBeneficiary: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
  trustStatementRecipient: trustStatementRecipientShape.isRequired,
};
