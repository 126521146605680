import PropTypes from 'prop-types';
import React from 'react';

function GenericCheckbox(props) {
  return <input {...props} type="checkbox" />;
}

GenericCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

GenericCheckbox.defaultProps = {
  checked: false,
};

export default GenericCheckbox;
