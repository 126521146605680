import PropTypes from 'prop-types';
const { string, func, bool, number, shape } = PropTypes;

export const paginationShapeKeys = {
  itemsName: string,
  itemsTotal: number.isRequired,
  loading: bool,
  onPageChange: func.isRequired,
  page: number.isRequired,
  pageSize: number,
};

const paginationShape = shape(paginationShapeKeys);

export default paginationShape;
