import DashboardActions from 'react/member/actions/dashboard_actions';

const DashboardBankAccountStore = flux.createStore({
  bankAccounts: [],
  actions: [
    DashboardActions.fetchConnectedBankAccounts,
    DashboardActions.connectBankAccount,
    DashboardActions.removeBankAccount,
    DashboardActions.verifyBankAccount,
    DashboardActions.copyBankAccount,
    DashboardActions.connectFeeNewBankAccount,
    DashboardActions.createPlaidBankAccount,
  ],

  fetchConnectedBankAccounts() {
    $.get(
      RailsRoutes.dashboard_bank_accounts_path({
        format: 'json',
      }),
    ).done((response) => {
      this.bankAccounts = response.bankAccounts;
      this.emit('bankAccounts.fetch', this.bankAccounts);
    });
  },

  connectBankAccount(accountSlug, data) {
    const params = {
      format: 'json',
      account_id: accountSlug,
    };
    $.post(RailsRoutes.dashboard_bank_accounts_path(params), data).done((result) => {
      this.emit('bankAccount.create', result);
      DashboardActions.fetchConnectedBankAccounts();
    });
  },

  createPlaidBankAccount(data) {
    const params = {
      format: 'json',
    };
    $.post(RailsRoutes.create_bank_account_path(params), data).done((result) => {
      this.emit('bankAccount.create', result);
      this.emit('bankAccount.plaidcreate', result);
      DashboardActions.fetchConnectedBankAccounts();
    });
  },

  removeBankAccount(bankAccountId) {
    $.ajax({
      url: RailsRoutes.dashboard_bank_account_path(bankAccountId, {
        format: 'json',
      }),
      type: 'DELETE',
    }).done((_result) => {
      this.emit('bankAccount.delete');
      DashboardActions.fetchConnectedBankAccounts();
    });
  },

  verifyBankAccount(bankAccountId, data) {
    $.post(
      RailsRoutes.verify_bank_account_dashboard_bank_account_path(bankAccountId, {
        format: 'json',
      }),
      data,
    ).done((result) => {
      this.emit('bankAccount.verify', result);
      return DashboardActions.fetchConnectedBankAccounts();
    });
  },

  copyBankAccount(bankAccountId) {
    $.post(
      RailsRoutes.copy_bank_account_dashboard_bank_account_path(bankAccountId, {
        format: 'json',
      }),
      {},
    ).done((result) => {
      this.emit('bankAccount.copy', result);
      DashboardActions.fetchConnectedBankAccounts();
    });
  },

  connectFeeNewBankAccount(accountSlug, bankAccountId) {
    const params = {
      format: 'json',
      account_id: accountSlug,
    };
    return $.post(
      RailsRoutes.connect_fee_new_bank_account_dashboard_bank_account_path(bankAccountId, params),
      {},
    ).done((_result) => {
      this.emit('bankAccount.fee');
    });
  },

  exports: {
    getBankAccounts() {
      return this.bankAccounts;
    },
  },
});

export default DashboardBankAccountStore;
