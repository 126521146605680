// A mix of CardReissue::REISSUE_TYPES and "first card" for AccountActivations
export const CARD_ORDER_TYPES = {
  ORIGINAL_CARD: 'original card',
  REISSUE_A: 'reissue a',
  REISSUE_B: 'reissue b',
  REISSUE_C_LEGACY: 'reissue c legacy',
  REISSUE_C: 'reissue c',
};

export const formattedReissueType = (cardReissue) => {
  switch (cardReissue.reissue_type) {
    case CARD_ORDER_TYPES.REISSUE_A:
      return 'Reissue A';
    case CARD_ORDER_TYPES.REISSUE_B:
      return 'Reissue B';
    case CARD_ORDER_TYPES.REISSUE_C_LEGACY:
      return 'Reissue C Legacy';
    case CARD_ORDER_TYPES.REISSUE_C:
      return 'Reissue C';
    default:
      return '';
  }
};
