import moment from 'moment-timezone';
import PropTypes, { shape } from 'prop-types';
import React from 'react';
import DashboardBudgetItemAttachments from 'react/member/components/dashboard/disbursements/DashboardBudgetItemAttachments';

const addressString = (check) => {
  const { street1, street2, city, state, zip } = check.payee;
  return `${street1} ${street2 ? street2 : ''}\n ${city}, ${state} ${zip}`;
};

function PrintChecksDetails(row) {
  const check = row.original;
  const colSpan = 7;
  // Disbursement payee attrs are serialized using the `fetch_payee_<attr>` helpers
  // because the disbursement_payee_<attr> fields can be used to override existing payee fields.
  let payee,
    payeeAddress,
    payeeBankInfo,
    accountNumberWithPayee,
    disposition,
    card,
    specialInstructions,
    postageType,
    merchant,
    editInstructions,
    denyDetails;
  if (check.payee.id) {
    payee = (
      <tr className="dashboard-table-row">
        <th>Payee:</th>
        <td style={{ paddingTop: 4 }}>
          <span style={{ fontSize: 14 }}>{check.payee.name}</span>
        </td>
      </tr>
    );
    payeeAddress = (
      <tr className="dashboard-table-row">
        <th>Payee Address:</th>
        <td style={{ paddingTop: 4 }}>
          <span style={{ fontSize: 14 }}>{addressString(check)}</span>
        </td>
      </tr>
    );
    if (check.payee.bank_routing_number) {
      payeeBankInfo = (
        <tr className="dashboard-table-row">
          <th>Payee Bank Information:</th>
          <td style={{ paddingTop: 4 }}>
            <div>
              <div style={{ fontSize: 14 }}>{check.payee.bank_routing_number} (Routing number)</div>
              <div style={{ fontSize: 14 }}>{check.payee.bank_account_number} (Account number)</div>
            </div>
          </td>
        </tr>
      );
    }
    if (check.payee.client_account_number) {
      accountNumberWithPayee = (
        <tr className="dashboard-table-row">
          <th>Memo:</th>
          <td>{check.payee.client_account_number}</td>
        </tr>
      );
    }
    if (check.payee.disposition) {
      disposition = (
        <tr className="dashboard-table-row">
          <th>Disposition:</th>
          <td>{check.payee.disposition}</td>
        </tr>
      );
    }
    if (check.payee.special_instructions) {
      specialInstructions = (
        <tr className="dashboard-table-row">
          <th>Special Instructions:</th>
          <td>{check.payee.special_instructions}</td>
        </tr>
      );
    }
  }
  if (check.payment_method == 'Card') {
    card = check.card;
    if (card) {
      card = (
        <tr className="dashboard-table-row">
          <th>Card</th>
          <td>{card.name}</td>
        </tr>
      );
    }
  }
  if (check.postage_type) {
    postageType = (
      <tr className="dashboard-table-row">
        <th>Postage Type:</th>
        <td>{check.postage_type}</td>
      </tr>
    );
  }
  if (check.merchant) {
    merchant = (
      <tr className="dashboard-table-row">
        <th>Merchant:</th>
        <td>{check.merchant}</td>
      </tr>
    );
  }

  if (check.status == 'Approved') {
    editInstructions =
      'This check has not yet been cleared. To make an adjustment, please email our support team: support@truelinkfinancial.com';
  }

  let approval;
  if (row.isExpanded) {
    if (check.approver) {
      if (check.status != 'pending') {
        approval = (
          <tr className="dashboard-table-row">
            <th style={{ width: '120px' }}>Approved By:</th>
            <td style={{ width: '800px' }}>
              <span>{(check.approver && check.approver.email) || 'N/A'}&nbsp;</span>
              <span>
                at {moment(check.approved_at).format('h:mm a')} on{' '}
                {moment(check.approved_at).format('MM-DD-YYYY')}.
              </span>
            </td>
          </tr>
        );
      } else {
        approval = (
          <tr className="dashboard-table-row">
            <th style={{ width: '120px' }}>Approved By:</th>
            <td>
              <span>Not yet approved</span>
            </td>
          </tr>
        );
      }
    }
    return (
      <tr>
        <td colSpan={9999}>
          <table>
            <tbody>
              <tr style={{ background: '#F0F0F0' }}>
                <td colSpan={colSpan} style={{ background: '#F0F0F0' }}>
                  <table className="table" style={{ width: '62%', background: '#F0F0F0' }}>
                    <tbody>
                      <tr>
                        <th>
                          <b style={{ fontWeight: 700 }}>Disbursement Details</b>
                        </th>
                      </tr>
                      <tr className="dashboard-table-row">
                        <th>ID:</th>
                        <td>{check.id}</td>
                      </tr>
                      <tr className="dashboard-table-row">
                        <th>Notes:</th>
                        <td>{check.memo}</td>
                      </tr>
                      <tr className="dashboard-table-row">
                        <th>Payment Type:</th>
                        <td>{$tlf.domains.paymentMethodsMap[check.payment_method]}</td>
                      </tr>
                      {postageType}
                      {merchant}
                      {card}
                      <tr className="dashboard-table-row">
                        <th>Category:</th>
                        <td>{check.category}</td>
                      </tr>
                      <tr className="dashboard-table-row">
                        <th>Attachments:</th>
                        <td style={{ paddingTop: 4 }}>
                          <DashboardBudgetItemAttachments
                            disbursement={check}
                            disbursementCategoryAmount={{
                              dca_id: check.first_disbursement_category_amount_id,
                            }}
                            readOnly
                            style={'link'}
                          />
                        </td>
                      </tr>
                      {payee}
                      {payeeAddress}
                      {payeeBankInfo}
                      {accountNumberWithPayee}
                      {disposition}
                      {specialInstructions}
                      <tr className="dashboard-table-row">
                        <th style={{ width: '150px' }}>Requested By:</th>
                        <td style={{ width: '800px' }}>
                          <span>{check.requester.email}&nbsp;</span>
                          <span>
                            at {moment(check.requested_at).format('h:mm a')} on{' '}
                            {moment(check.requested_at).format('MM-DD-YYYY')}.
                          </span>
                        </td>
                      </tr>
                      {denyDetails}
                      {approval}
                    </tbody>
                  </table>
                  <div style={{ fontSize: 14, margin: 10 }}>{editInstructions}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    );
  }
}

PrintChecksDetails.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      payee: PropTypes.shape({
        street1: PropTypes.string,
        street2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zip: PropTypes.string,
        name: PropTypes.string,
        bank_routing_number: PropTypes.string,
        bank_account_number: PropTypes.string,
        client_account_number: PropTypes.string,
        disposition: PropTypes.string,
        special_instructions: PropTypes.string,
      }).isRequired,
      payment_method: PropTypes.string,
      card: shape({
        name: PropTypes.string,
      }),
      postage_type: PropTypes.string,
      merchant: PropTypes.string,
      status: PropTypes.string,
      approver: PropTypes.shape({
        email: PropTypes.string,
      }),
      approved_at: PropTypes.string,
      id: PropTypes.string,
      memo: PropTypes.string,
      category: PropTypes.string,
      requester: shape({
        email: PropTypes.string,
      }),
      requested_at: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default PrintChecksDetails;
