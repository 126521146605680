const ASSET_TYPES = {
  Alimony: 'Outside Accounts',
  Allonge: 'Liability',
  Artwork: 'Tangible Property',
  'Beneficiary Occupied Real Estate': 'Real Estate',
  Bond: 'Individual Held Security',
  'Burial Contract': 'Outside Accounts',
  'Closely Held Business': 'Business Interest',
  Coins: 'Tangible Property',
  Collectibles: 'Tangible Property',
  'Commercial Real Estate': 'Real Estate',
  'Conservatorship/Guardianship': 'Outside Accounts',
  'Ditch Rights': 'Mineral Interest',
  'Fixed Annuity': 'Annuities',
  'Investment Account': 'Outside Accounts',
  IRA: 'Outside Accounts',
  'Land Contract': 'Liability',
  'Life Estate': 'Real Estate',
  'Life Insurance': 'Outside Accounts',
  Advance: 'Liability',
  LLC: 'Business Interest',
  LLP: 'Business Interest',
  Loan: 'Liability',
  MSA: 'Outside Accounts',
  'Oil and Gas': 'Mineral Interest',
  'Other Account': 'Outside Accounts',
  'Other Annuities': 'Annuities',
  'Other Business Interests': 'Business Interest',
  'Other Individual Security': 'Individual Held Security',
  'Other Mineral Interests': 'Mineral Interest',
  'Other Tangible Personal Property': 'Tangible Property',
  Partnership: 'Business Interest',
  'Promissory Note': 'Liability',
  'Real Estate': 'Real Estate',
  'Rep Payee': 'Outside Accounts',
  'Stock Certificate': 'Individual Held Security',
  'Surface Rights': 'Mineral Interest',
  'Structured Settlement': 'Annuities',
  'US Savings Bond': 'Individual Held Security',
  'Variable Annuity': 'Annuities',
  Vehicle: 'Vehicle',
  'Vehicle Lien': 'Vehicle Lien',
  'Water Rights': 'Mineral Interest',
};

export default ASSET_TYPES;
