export function getPostageTypeDisplayValue(postageType) {
  switch (postageType) {
    case 3:
    case '3':
      return 'USPS Priority';
    case 8:
    case '8':
      return 'USPS Overnight';
    case 1:
    case '1':
    default:
      return 'USPS First Class';
  }
}
