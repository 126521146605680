import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';

export default function SearchOption({ props, client }) {
  const { ...optionProps } = props;
  return (
    <Box
      component="li"
      style={{ color: client.status === 'Inactive' ? '#999999' : 'initial' }}
      {...optionProps}
    >
      {client.status === 'Inactive' ? `${client.person.name} - INACTIVE` : client.person.name}
    </Box>
  );
}

SearchOption.propTypes = {
  props: PropTypes.object,
  client: PropTypes.object,
};
