export function noWeekendsOrHolidays(date) {
  const noWeekends = $.datepicker.noWeekends(date);
  const noHolidays = [!$tlf.bank_holidays.includes($.datepicker.formatDate('yy-mm-dd', date))];
  return noWeekends[0] ? noHolidays : noWeekends;
}

export function getFirstLabelFromTarget(target) {
  return target.labels ? target.labels[0].textContent : 'Internet Explorer 11';
}

export function getFirstLabelFromEvent(ev) {
  return getFirstLabelFromTarget(ev.target);
}
