import axios from 'axios';
import ReactDOM from 'react-dom';

export function callAPI(url, data, el) {
  let $saved = $();
  if (el) {
    $saved = $(ReactDOM.findDOMNode(el)).find('.saved');
  }

  $saved.hide();

  return axios.post(url, data).then(({ data }) => {
    window.setTimeout(() => $saved.show(), 100);
    window.setTimeout(() => $saved.fadeOut(), 2500);
    return data;
  });
}

export function getUrl(endpoint, accountId) {
  let url = `/member/dashboard/${endpoint}`;
  if (accountId) {
    url += `/${accountId}`;
  }
  return url;
}
