import createReactClass from 'create-react-class';
import React from 'react';
import Field from './Field';
import FieldMixin from './FieldMixin';

const CheckboxField = createReactClass({
  mixins: [FieldMixin],

  getDefaultProps() {
    return { format: (flag) => (flag ? 'YES' : 'NO') };
  },

  componentDidMount() {
    this.initField();
  },

  renderShow() {
    return <span>{this.formattedValue()}</span>;
  },

  renderEdit() {
    return (
      <input
        data-checkbox-style="single"
        defaultChecked={this.value()}
        name={this.inputName()}
        onChange={this.onCheck}
        type="checkbox"
      />
    );
  },

  onCheck(evt) {
    const c = evt.target;
    this.change(c.checked);
  },

  render() {
    return (
      <Field
        {...this.props}
        editable={this.editable}
        label={this.label}
        renderEdit={this.renderEdit}
        renderShow={this.renderShow}
      />
    );
  },
});

export default CheckboxField;
