import React from 'react';
import TrueLinkTooltip from 'react/shared/components/true_link/main/TrueLinkTooltip';

export default function LabelTooltipAdornment() {
  const tooltipContent = (
    <p>
      Federal regulation requires us to gather this information. It will only be used to verify your
      identity and will not impact your credit score.
    </p>
  );

  return (
    <>
      <div style={{ flexGrow: 1 }} />
      <TrueLinkTooltip tooltipContent={tooltipContent}>
        <span id={'tooltipEmailContent'}>Why do we need this? </span>
        <i className="fa-light fa-circle-question" />
      </TrueLinkTooltip>
    </>
  );
}
