import numeral from 'numeral';
import PropTypes from 'prop-types';
import React from 'react';
import bindAll from 'react/shared/utils/bind_all';

export default class FormCurrencyField extends React.Component {
  constructor(props) {
    super(props);
    bindAll(this);
  }

  onChange(event) {
    const { name } = this.props.field;
    const { setFieldValue } = this.props.form;
    const value = event.currentTarget.value;
    const formattedValue =
      // Don't use numeral.format() here because it throws off the position of the user's cursor
      value === '$' || value === '' ? '' : `$${value.replace(/[^\d.,]/g, '')}`;
    setFieldValue(name, formattedValue);
  }

  setFormattedValueOnBlur(event) {
    const { name } = this.props.field;
    const { setFieldValue } = this.props.form;
    const value = event.currentTarget.value;
    const formattedValue = value === '' ? '' : numeral(value).format('$0,0.00');
    setFieldValue(name, formattedValue);
  }

  render() {
    const {
      field: { name, value },
    } = this.props;
    return (
      <input
        name={name}
        onBlur={this.setFormattedValueOnBlur}
        onChange={this.onChange}
        type="text"
        value={value}
      />
    );
  }
}

FormCurrencyField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
  form: PropTypes.shape({
    setFieldValue: PropTypes.func,
  }),
};
