import PropTypes from 'prop-types';
import React from 'react';
import childrenShape from 'react/shared/shapes/children';

export default function TabContent(props) {
  return (
    <div className="tab-pane" id={props.id} role="tabpanel">
      {props.children}
    </div>
  );
}

TabContent.propTypes = {
  id: PropTypes.string,
  children: childrenShape.isRequired,
};
