import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import HolidayImpactedTransfersAmountCell from './HolidayImpactedTransfersAmountCell';
import HolidayImpactedTransfersCheckboxCell from './HolidayImpactedTransfersCheckboxCell';
import HolidayImpactedTransfersSelectAllCell from './HolidayImpactedTransfersSelectAllCell';
import { impactedTransferShape } from 'react/member/card/shapes/TransferShape';
import TrueLinkTable from 'react/shared/components/true_link/main/table/TrueLinkTable';

const borderStyles = '1px solid #E7E7E7';
const useStyles = makeStyles({
  wrap: {
    fontSize: '16px',
    margin: '10px 0 15px',
  },
  subHeader: {
    fontSize: '26px',
    lineHeight: 1.2,
  },
  holidayImpactedTransfersTable: {
    width: '950px',
    border: 'none',
    margin: '20px 0',
    '& table': {
      overflowX: 'hidden',
    },
    '& thead': {
      boxShadow: 'none',
      borderBottom: borderStyles,
    },
    '& thead th': {
      borderBottom: borderStyles,
    },
    '& td': {
      whiteSpace: 'normal',
    },
  },
});

export default function HolidayImpactedTransfersTable({
  canEdit,
  date,
  impactedTransfers,
  onSelectGuidance,
  withSelectAll,
}) {
  const classes = useStyles();

  // Array of impacted trasfers for a given Holiday Impacted Date
  const tableData = useMemo(() => impactedTransfers.transfers, [impactedTransfers]);

  // Transfer guidance values for a given Holiday Impacted Date
  // {
  //    [transfer1.id]: true
  //    [transfer2.id]: false
  // }
  const [guidanceById, setGuidanceById] = useState(impactedTransfers.guidance);

  const allGuidanceAccepted = useMemo(() => {
    const count = Object.values(guidanceById).filter((guidance) => guidance === true).length;
    return count === tableData.length;
  }, [guidanceById, tableData]);

  const allGuidanceRejected = useMemo(() => {
    const count = Object.values(guidanceById).filter((guidance) => guidance === false).length;
    return count === tableData.length;
  }, [guidanceById, tableData]);

  const memoizedHandleSelectGuidance = useCallback(
    (rowState) => {
      // If 'select all' feature is enabled for this table,
      // reset guidance totals for this table before passing to parent select handler
      if (withSelectAll) {
        const rows = Object.values(rowState);
        const newBulkGuidanceById = rows.reduce(
          (memo, row) => ({
            ...memo,
            [row.transferId]: row.guidanceAccepted,
          }),
          {},
        );

        setGuidanceById((prevState) => ({ ...prevState, ...newBulkGuidanceById }));
      }

      onSelectGuidance(rowState);
    },
    [onSelectGuidance, withSelectAll],
  );

  const columns = useMemo(
    () => [
      {
        accessor: 'memo',
        id: 'transferType',
        Header: 'Transfer Type',
      },
      {
        Cell: HolidayImpactedTransfersAmountCell,
        accessor: 'amount',
        id: 'amount',
        Header: 'Amount',
      },
      {
        accessor: 'cardholder.name',
        id: 'cardholderName',
        Header: 'Cardholder',
      },
      {
        Cell: HolidayImpactedTransfersCheckboxCell,
        Subheader: withSelectAll ? HolidayImpactedTransfersSelectAllCell : undefined,
        accessor: (transfer) => ({ action: 'rejectGuidance', canEdit, transfer }),
        id: 'rejectGuidance',
        Header: 'If You Do Nothing',
        ...(withSelectAll ? { date, allGuidanceAccepted, allGuidanceRejected } : {}),
      },
      {
        Cell: HolidayImpactedTransfersCheckboxCell,
        Subheader: withSelectAll ? HolidayImpactedTransfersSelectAllCell : undefined,
        accessor: (transfer) => ({ action: 'acceptGuidance', canEdit, transfer }),
        id: 'acceptGuidance',
        Header: 'If You Adjust',
        ...(withSelectAll ? { date, allGuidanceAccepted, allGuidanceRejected } : {}),
      },
    ],
    [allGuidanceAccepted, allGuidanceRejected, canEdit, date, withSelectAll],
  );

  return (
    <div className={classes.wrap}>
      <div className={classes.subHeader}>
        {canEdit ? 'Adjust transfers ' : 'Transfers '}
        scheduled for {moment(date).format('dddd, MMMM Do')}
        {canEdit ? ' to avoid the delay' : ''}
      </div>
      <TrueLinkTable
        className={classes.holidayImpactedTransfersTable}
        columns={columns}
        data={tableData}
        initialRowStateAccessor={(row) => ({
          guidanceAccepted: row.original.guidanceAccepted,
          transferId: row.original.id,
        })}
        onChangeRowState={memoizedHandleSelectGuidance}
        rowProps={() => ({ style: { borderBottom: borderStyles, padding: '5px' } })}
        variant="compressed"
        withRowState
      />
    </div>
  );
}

HolidayImpactedTransfersTable.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  date: PropTypes.string.isRequired, // HolidayImpactedDate.date
  impactedTransfers: PropTypes.shape({
    guidance: PropTypes.objectOf(PropTypes.bool), // used by withSelectAll
    transfers: PropTypes.arrayOf(impactedTransferShape).isRequired,
  }).isRequired,
  onSelectGuidance: PropTypes.func.isRequired,
  withSelectAll: PropTypes.bool,
};
