import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import Clipboard from 'clipboard';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';

const useStyles = makeStyles({
  copyButton: {
    padding: '2px 10px',
    lineHeight: '16px !important',
    fontSize: '12px !important',
    fontWeight: 700,
    verticalAlign: 'bottom',
  },
  copyButtonVariant: {
    width: '105px',
  },
  temporaryMessage: {
    color: 'green',
  },
});
export default function CopyButton({ copyText, text, title, variant }) {
  const classes = useStyles();
  const [temporaryMessage, setTemporaryMessage] = useState('');
  const ref = useRef(null);
  useEffect(() => {
    const clip = new Clipboard(ref.current, {
      text: () => copyText,
    });
    return () => clip.destroy();
  }, [copyText]);

  const handleButtonClick = () => {
    setTemporaryMessage('Copied');
    window.setTimeout(() => setTemporaryMessage(''), 1000);
  };

  const defaultBtnClasses = classNames('btn btn-small btn-default', classes.copyButton, {
    [classes.temporaryMessage]: temporaryMessage,
  });

  return (
    <TrueLinkButton
      className={variant ? classes.copyButtonVariant : defaultBtnClasses}
      onClick={handleButtonClick}
      ref={ref}
      title={title}
      variant={variant || 'none'}
    >
      {temporaryMessage || text}
    </TrueLinkButton>
  );
}

CopyButton.propTypes = {
  copyText: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  title: PropTypes.string,
  variant: PropTypes.string,
};
