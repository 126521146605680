import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles({
  warningIconGroup: {
    fontSize: '1.5em',
    marginRight: '0.5em',
  },
  warningIcon: {
    color: '#fc824e',
  },
  iconBackgroundCircle: {
    color: 'white',
  },
});

export default function ModalWarningIcon() {
  const classes = useStyles();

  return (
    <span className={`fa-layers fa-fw ${classes.warningIconGroup}`}>
      <i
        className={`fa fa-circle ${classes.iconBackgroundCircle}`}
        data-fa-transform="shrink-6 down-2"
      />
      <i className={`fa fa-triangle-exclamation ${classes.warningIcon}`} />
    </span>
  );
}
