import PropTypes from 'prop-types';
import React from 'react';
import DepositCancelModal from './DepositCancelModal';
import depositsTableConfig from './DepositsTableConfig';
import PaginatedTableBuilder from 'react/member/components/PaginatedTableBuilder';
import DepositPopUp from 'react/member/components/dashboard/deposits/DepositPopUp';
import userRoleShape from 'react/member/shapes/UserRoleShape';
import LoadingIndicator from 'react/shared/components/LoadingIndicator';
import bindAll from 'react/shared/utils/bind_all';

export default class DashboardDepositsTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedDeposit: null,
    };

    bindAll(this);
  }

  renderDepositPopUp() {
    const recurring = this.props.depositType === 'recurring';
    if (this.state.modalOpen) {
      return (
        <DepositPopUp
          client={this.props.client}
          closeModal={this.closeModal}
          deposit={this.state.selectedDeposit}
          modalOpen={this.state.modalOpen}
          newDepositForm={!this.state.selectedDeposit}
          organization={this.props.organization}
          recurring={recurring}
          refreshDeposits={this.props.refreshDeposits}
          trustBeneficiaries={this.props.clientList}
          userCanEdit={this.userCanEdit()}
        />
      );
    }
  }

  renderDepositCancelPopUp() {
    if (this.state.modalOpen) {
      return <DepositCancelModal />;
    }
  }

  userCanEdit() {
    const roles = this.props.userRoles;
    return !(roles.viewOnly || roles.restrictedView);
  }

  renderTable() {
    return (
      <PaginatedTableBuilder
        columnList={this.props.columnList}
        defaultSort={this.props.defaultSort}
        indexRoute={this.props.indexRoute}
        shouldShowSearchBox={false}
        tableFieldConfig={depositsTableConfig(
          this.props.depositType,
          this.openDepositDetailView,
          this.userCanEdit(),
        )}
        viewOnly={this.props.userRoles.viewOnly}
      />
    );
  }

  closeModal() {
    return this.setState({ modalOpen: false, selectedDeposit: null });
  }

  openDepositDetailView(deposit) {
    return this.setState({
      modalOpen: true,
      selectedDeposit: deposit,
    });
  }

  render() {
    if (this.props.isLoading) {
      <LoadingIndicator />;
    }
    return (
      <div className={`${this.props.depositType}-deposits-table`}>
        {this.renderTable()}
        {this.renderDepositPopUp()}
        {this.renderDepositCancelPopUp()}
      </div>
    );
  }
}

DashboardDepositsTable.propTypes = {
  indexRoute: PropTypes.string,
  client: PropTypes.object,
  organization: PropTypes.object,
  clientList: PropTypes.array,
  columnList: PropTypes.array.isRequired,
  depositType: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  refreshDeposits: PropTypes.func,
  defaultSort: PropTypes.shape({
    key: PropTypes.string,
    direction: PropTypes.string,
  }),
  userRoles: userRoleShape.isRequired,
};

DashboardDepositsTable.defaultProps = {
  client: null,
  organization: null,
  clientList: [],
  columnList: [],
  depositType: 'one_time',
  isLoading: false,
};
