import Input from '@mui/material/Input';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import PALETTE from 'react/shared/theme/palette';

const useStyles = makeStyles(() => ({
  errorMessage: {
    color: PALETTE.red,
    marginBottom: '12px',
  },
  inputDiv: {
    // This is written to target the div plus the input because other CSS was overriding
    // this when targeting the input directly
    '& input': {
      padding: '12px',
      height: 'auto',
      '&::placeholder': {
        fontStyle: 'normal',
      },
      '&:disabled': {
        color: PALETTE.grey3,
      },
    },
    // Adorned start adjustments
    '& input.MuiInputBase-inputAdornedStart': {
      paddingLeft: 0,
    },
    // Multiline adjustments
    '&.MuiInputBase-multiline': {
      padding: '10px',
    },
    '&.MuiInputBase-multiline .MuiInputBase-inputMultiline': {
      boxShadow: 'none',
    },
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  labelWrap: {
    alignItems: 'center',
    display: 'flex',
    gap: '5px',
  },
  wrap: {
    marginBottom: '12px',
  },
}));

function TrueLinkTextInput(
  {
    className,
    disabled,
    endAdornment,
    inputProps,
    labelAdornment,
    labelText,
    multiline,
    name,
    onChange,
    placeholder,
    required,
    rows,
    startAdornment,
    setFieldTouched,
    value,
    type = 'text',
    isFormik = true,
    bottomLabelText,
    fullWidth = true,
  },
  ref,
) {
  const classes = useStyles();

  return (
    <div className={classes.wrap}>
      {labelText && (
        <div className={classes.labelWrap}>
          <label htmlFor={name}>
            <Typography variant="body1">{labelText}</Typography>
          </label>
          {labelAdornment}
        </div>
      )}
      <Input
        className={classNames(classes.inputDiv, className)}
        disableUnderline
        disabled={disabled}
        endAdornment={endAdornment}
        fullWidth={fullWidth}
        id={name}
        inputProps={inputProps}
        inputRef={ref}
        multiline={multiline}
        name={name}
        onBlur={() => setFieldTouched && setFieldTouched(name, true)}
        onChange={onChange}
        onWheel={(e) => e.target.blur()}
        placeholder={placeholder}
        required={required}
        rows={rows}
        startAdornment={startAdornment}
        type={type}
        value={value}
      />
      {bottomLabelText && (
        <div className={classes.labelWrap}>
          <label htmlFor={name}>
            <Typography variant="caption">{bottomLabelText}</Typography>
          </label>
          {labelAdornment}
        </div>
      )}
      {isFormik && <ErrorMessage className={classes.errorMessage} component="p" name={name} />}
    </div>
  );
}

export default React.forwardRef(TrueLinkTextInput);

export const textInputTypes = ['text', 'password'];

TrueLinkTextInput.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  startAdornment: PropTypes.node,
  endAdornment: PropTypes.node,
  inputProps: PropTypes.object,
  labelAdornment: PropTypes.node,
  labelText: PropTypes.string,
  bottomLabelText: PropTypes.string,
  multiline: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  rows: PropTypes.number,
  setFieldTouched: PropTypes.func,
  value: PropTypes.string,
  type: PropTypes.oneOf(textInputTypes),
  isFormik: PropTypes.bool,
  fullWidth: PropTypes.bool,
};
