import PropTypes from 'prop-types';
import React from 'react';
import InfoTooltip from 'react/shared/components/tooltips/InfoTooltip';
import TrueLinkLink from 'react/shared/components/true_link/main/TrueLinkLink';

export default function CategoryManagement({ idx, destroyCategoryFunc, addCategoryFunc, length }) {
  return (
    <div>
      <TrueLinkLink onClick={addCategoryFunc} role="link" tabIndex={0}>
        + Add Category
      </TrueLinkLink>
      <InfoTooltip
        placement="top"
        tooltipText="Multi-category disbursements allow payments going to the same payee to be entered in a single request."
      />
      {length !== 1 && (
        <button
          aria-label="Close"
          className="btn-modal-close"
          data-index={idx}
          onClick={destroyCategoryFunc}
          type="button"
        >
          <span aria-hidden="true" data-index={idx}>
            ×
          </span>
        </button>
      )}
    </div>
  );
}

CategoryManagement.propTypes = {
  destroyCategoryFunc: PropTypes.func.isRequired,
  addCategoryFunc: PropTypes.func.isRequired,
  length: PropTypes.number,
  idx: PropTypes.number,
};
