import pluralize from 'pluralize';
import { underscorize } from 'react/shared/utils/Strings';

export function find(model) {
  return RailsRoutes[`${underscorize(model)}_path`];
}

export function list(model) {
  return RailsRoutes[`${underscorize(pluralize(model))}_path`];
}

export function destroy(model, id) {
  return find(model)(id);
}

export default {
  destroy,
  find,
  list,
};
