import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import React from 'react';
import childrenShape from 'react/shared/shapes/children';
import PALETTE from 'react/shared/theme/palette';

export default function TrueLinkTooltip({
  children,
  className,
  leaveDelay,
  tooltipContent,
  placement,
  maxWidth,
}) {
  const styleOverrides = {
    tooltip: {
      sx: {
        padding: '18px',
        backgroundColor: PALETTE.white,
        color: PALETTE.black,
        boxShadow: `0 4px 14px ${PALETTE.blackShadow}`,
        border: `1px solid ${PALETTE.blackShadow}`,
        maxWidth: maxWidth || '300px',
      },
    },
    arrow: {
      sx: {
        '&:before': {
          border: `1px solid ${PALETTE.blackShadow}`,
          boxShadow: `0 4px 14px ${PALETTE.blackShadow}`,
        },
        color: PALETTE.white,
      },
    },
  };

  return (
    <Tooltip
      arrow
      className={className}
      componentsProps={styleOverrides}
      enterTouchDelay={0}
      leaveDelay={leaveDelay}
      placement={placement}
      title={tooltipContent}
    >
      <span>{children}</span>
    </Tooltip>
  );
}

TrueLinkTooltip.propTypes = {
  className: PropTypes.string,
  children: childrenShape.isRequired,
  leaveDelay: PropTypes.number,
  placement: PropTypes.string,
  tooltipContent: childrenShape.isRequired,
  maxWidth: PropTypes.string,
};
