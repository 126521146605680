import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import _pick from 'underscore/modules/pick';
import BeneficiaryDashboardActions from 'react/member/actions/beneficiary_dashboard_actions';
import BeneficiarySignupStore from 'react/member/stores/beneficiary_dashboard/beneficiary_signup_store';

export default function BeneficiarySignupLogin({ completeLogin, resourceSlug, tbis, email }) {
  const [submitting, setSubmitting] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [formState, setFormState] = useState({
    email,
    password: '',
    passwordConfirm: '',
    completed: false,
    submitting: false,
    agreeTermsAndPrivacy: false,
  });

  const userCreated = useCallback(() => {
    setSubmitting(false);
    setCompleted(true);
    completeLogin();
  }, [completeLogin]);

  const userCreateError = useCallback(() => {
    setSubmitting(false);
  }, []);

  useEffect(() => {
    BeneficiarySignupStore.on('beneficiarySignups.user.create', userCreated);
    BeneficiarySignupStore.on('beneficiarySignups.user.create.error', userCreateError);

    return function cleanup() {
      BeneficiarySignupStore.off('beneficiarySignups.user.create', userCreated);
      BeneficiarySignupStore.off('beneficiarySignups.user.create.error', userCreateError);
    };
  }, [userCreateError, userCreated]);

  const updateField = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const updateCheck = (e) => {
    setFormState({ ...formState, [e.target.name]: !!e.target.checked });
  };

  const submitLogin = () => {
    const data = _pick(formState, 'email', 'password', 'passwordConfirm');
    setSubmitting(true);
    BeneficiaryDashboardActions.createSignupUser({
      data,
      resourceSlug,
      tbis,
    });
  };

  const renderCreatedUser = () => (
    <div className="card card--form section-completed" id="get-started-section-next-step">
      <div className="card--form__left">
        <div className="card--form__left__header">1. Create your True Link Login</div>
      </div>
      <div className="card--form__right">
        <div className="card--form__right__body show-on-complete" style={{ display: 'block' }}>
          <div className="summary-area clearfix">
            <div className="summary-area__left">
              <div className="summary-area__data-item">
                <label htmlFor="user-email">Email address:</label>
                <span>
                  <span id="user-email">{formState.email}</span>
                </span>
              </div>
              <div className="summary-area__data-item">
                <label htmlFor="user-password">Password:</label>
                <span id="user-password">{formState.password.replace(/./g, '*')}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const renderForm = () => {
    const { email, password, passwordConfirm, agreeTermsAndPrivacy } = formState;
    const continueButtonClass = agreeTermsAndPrivacy
      ? 'btn-new btn-new--green btn-new--large'
      : 'btn-new btn-new--large';
    return (
      <div className="card card--form" id="get-started-section-next-step">
        <div className="card--form__left">
          <div className="card--form__left__header">1. Create your True Link Login</div>
          <div className="card--form__left__body hide-on-complete">
            Enter and confirm a secure password that you will use each time you log in.
          </div>
        </div>
        <div className="card--form__right">
          <form
            action="/404.html"
            className="card--form__right__body hide-on-complete clearfix"
            id="get-started-form"
          >
            <div className="card--form__section">
              <div className="card--form__row">
                <div className="card--form__col width-100">
                  <label htmlFor="beneficiary-signup-email">
                    Email address
                    <input
                      autoComplete="off"
                      className="width100"
                      id="beneficiary-signup-email"
                      name="email"
                      onChange={updateField}
                      type="email"
                      value={email}
                    />
                  </label>
                </div>
              </div>
              <div className="card--form__row">
                <div className="card--form__col width-100">
                  <label htmlFor="beneficiary-signup-password">
                    Password
                    <span className="card--form__input-addition">
                      min 8 characters, 1 letter, 1 number, 1 special character
                    </span>
                    <input
                      autoComplete="off"
                      id="beneficiary-signup-password"
                      name="password"
                      onChange={updateField}
                      type="password"
                      value={password}
                    />
                  </label>
                </div>
              </div>
              <div className="card--form__row password-row">
                <div className="card--form__col width-100">
                  <label htmlFor="beneficiary-signup-password-confirm">
                    Password confirmation
                    <input
                      autoComplete="off"
                      id="beneficiary-signup-password-confirm"
                      name="passwordConfirm"
                      onChange={updateField}
                      type="password"
                      value={passwordConfirm}
                    />
                  </label>
                </div>
              </div>
            </div>
            <div className="card--form__right__footer">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <input
                        className={continueButtonClass}
                        disabled={!agreeTermsAndPrivacy || submitting}
                        onClick={submitLogin}
                        type="button"
                        value="Continue"
                      />
                    </td>
                    <td
                      style={{
                        paddingLeft: '30px',
                        paddingRight: '100px',
                        lineHeight: '15px',
                      }}
                    >
                      <input
                        name="agreeTermsAndPrivacy"
                        onClick={updateCheck}
                        required
                        type="checkbox"
                      />{' '}
                      <b
                        style={{
                          fontSize: 'small',
                          position: 'relative',
                          lineHeight: '2em',
                        }}
                      >
                        {'I agree to the '}
                        <a href="/legal/terms-of-service" target="_blank">
                          terms of service
                        </a>
                        {' and '}
                        <a href="/legal/privacy-policy" target="_blank">
                          privacy policy
                        </a>
                        .
                      </b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </form>
        </div>
      </div>
    );
  };

  if (completed === true) {
    return renderCreatedUser();
  }
  return renderForm();
}

BeneficiarySignupLogin.propTypes = {
  tbis: PropTypes.string.isRequired,
  resourceSlug: PropTypes.string.isRequired,
  email: PropTypes.string,
  completeLogin: PropTypes.func,
};
