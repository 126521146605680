import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import Promotions from 'react/member/components/referrals/Promotions';

export default function PromotionsWrapper() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <Promotions />
    </QueryClientProvider>
  );
}
