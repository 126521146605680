import PropTypes from 'prop-types';
import React from 'react';
import PopUp from 'react/shared/components/popups/PopUp';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import bindAll from 'react/shared/utils/bind_all';

export default class FamilyFunderDischarge extends React.Component {
  constructor(props) {
    super(props);

    const { familyFunders } = this.props;
    this.state = {
      isModalOpen: false,
      selectedFunder: familyFunders && familyFunders.length == 1 ? familyFunders[0].slug : null,
    };

    bindAll(this);
  }

  toggleModal() {
    this.setState((prevState) => ({
      isModalOpen: !prevState.isModalOpen,
    }));
  }

  closeModal() {
    this.setState({ isModalOpen: false });
  }

  handleOptionChange({ target: { value } }) {
    this.setState({ selectedFunder: value });
  }

  transferAdminControl() {
    const form = $('form#ff-discharge-account-form');
    const data = form.serializeArray();
    const url = form.attr('action');
    $.post(url, data).done(() => {
      window.location.href = RailsRoutes.accounts_list_path();
    });
  }

  onSubmit(event) {
    event.preventDefault();
    if (this.state.selectedFunder) {
      this.transferAdminControl();
    } else {
      Truelink.flash('error', 'You must select a Family Funder in order to discharge the account.');
    }
  }

  renderModal() {
    const { cardholderName, cardLast4, familyFunders, subOrgId } = this.props;

    return (
      <PopUp
        footer={
          <TrueLinkButton form="ff-discharge-account-form" type="submit" variant="primary">
            Transfer administrative access to Family Funder
          </TrueLinkButton>
        }
        header="Discharge account"
        maxWidth="640px"
        modalBodyStyle={{ padding: 0 }}
        onClose={this.closeModal}
        openModal={this.state.isModalOpen}
      >
        <div className="ff-discharge-card-info">
          <div>
            <p>
              <span>Cardholder:</span>
              <span>{cardholderName}</span>
            </p>
            <p>
              <span>Last 4 digits of True Link card:</span>
              <span>{cardLast4}</span>
            </p>
          </div>
        </div>
        <div className="ff-discharge-disclosure">
          <p>
            Discharging the account will transfer administrative access to
            {familyFunders.length == 1
              ? " this card's Family Funder"
              : ' one of the Family Funders selected below'}
            . They will have access to adjust all settings including the Spending Settings and
            closing the card.
          </p>
          <p>The Family Funder will be notified of this action via email.</p>
          <p>You will no longer have administrative access to this account.</p>
        </div>
        <form
          action={RailsRoutes.discharge_family_funder_path(subOrgId)}
          id="ff-discharge-account-form"
          method="POST"
          onSubmit={this.onSubmit}
        >
          <div className="modal-header">
            <h4 className="modal-title">Select a Family Funder</h4>
          </div>
          <div style={{ padding: '0 1.25rem' }}>
            {familyFunders.map((funder, i) => (
              <div className="funders" key={`funder_${i}`}>
                <input
                  checked={funder.slug == this.state.selectedFunder}
                  className="questionnaire-radio__input__ff"
                  id={`option-${funder.slug}`}
                  name="funder_slug"
                  onChange={this.handleOptionChange}
                  type="radio"
                  value={funder.slug}
                />
                <label className="questionnaire-radio__label__ff" htmlFor={`option-${funder.slug}`}>
                  <span className="questionnaire-radio__checkbox-container__ff" />
                  <span className="questionnaire-radio__text__ff">
                    <span>{funder.full_name}</span>
                    <span>{funder.email}</span>
                  </span>
                </label>
              </div>
            ))}
          </div>
        </form>
      </PopUp>
    );
  }

  render() {
    return (
      <div>
        <div className="sub_section_header">Discharge</div>
        <div className="account_info_section">
          <p>Has the cardholder been discharged from your facility?</p>
          <TrueLinkButton onClick={this.toggleModal} variant="warning">
            Discharge account
          </TrueLinkButton>
        </div>
        {this.renderModal()}
      </div>
    );
  }
}

FamilyFunderDischarge.propTypes = {
  familyFunders: PropTypes.array,
  cardholderName: PropTypes.string,
  cardLast4: PropTypes.string,
  subOrgId: PropTypes.number,
};
