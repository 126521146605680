import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import _isFunction from 'underscore/modules/isFunction';
import LoadingIndicator from 'react/shared/components/LoadingIndicator';
import bindAll from 'react/shared/utils/bind_all';

export default class Datatable extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isLoading: true };
    this._isMounted = false;

    bindAll(this);
  }

  componentDidMount() {
    this._isMounted = true;
    return this.initDatatable();
  }

  componentDidUpdate(prevProps) {
    if (this.props.needsRefresh) {
      this.refreshDatatable();
      return this.props.afterRefresh();
    } else if (this.props.route !== prevProps.route) {
      return this.reInitDatatable();
    }
  }

  componentWillUnmount() {
    return (this._isMounted = false);
  }

  normalizedColumnDefs() {
    return this.props.columnDefs.map((columnDef) => ({ defaultContent: '', ...columnDef }));
  }

  setDatatableRef(datatable) {
    return (this._datatable_table = datatable);
  }

  datatableNode() {
    return $(ReactDOM.findDOMNode(this._datatable_table));
  }

  initDatatable() {
    $.fn.DataTable.ext.errMode = 'none';
    const table = this.datatableNode().DataTable({
      paging: !this.props.hidePagination,
      info: !this.props.hidePagination,
      serverSide: true,
      columnDefs: this.normalizedColumnDefs(),
      order: this.props.initialSort,
      // Disable DataTables autosizing so it doesn't make the
      // column widths look bad after the table dynamically updates.
      autoWidth: false,
      rowId: this.props.rowId,
      pageLength: this.props.pageLength,
      searching: this.props.shouldShowSearchBox,
      select: this.props.selectOption,
      ajax: {
        url: this.props.route,
        data: (data) => {
          // Don't show the loading indicator if the user has typed into the search box
          // (otherwise, the indicator would intrusively pop up after every typed character).
          //
          // NOTE: This check isn't perfect, as `search` remains populated as long as
          // there's text in the search box, even if the user didn't just type it in.
          // I don't have time to figure out a more foolproof way to accomplish this right now.
          const searchParam = data.search;
          if (!searchParam || !searchParam.value) {
            if (this._isMounted) {
              this.setState({
                isLoading: true,
              });
            }
          }

          const sortColumn = data.order[0] ? data.order[0].column : undefined;
          if (data.order[0]) {
            data.order[0].column = data.columns[sortColumn].data;
          }
          delete data['columns']; //to prevent 414 URI Too Long

          let { serverParams } = this.props;
          if (_isFunction(serverParams)) {
            serverParams = serverParams();
          }
          return { tl_datatable: true, ...data, ...serverParams };
        },
        error: (jqXHR, _textStatus, errorThrown) => {
          if (jqXHR.status !== 401) {
            throw new Error(errorThrown);
          }
        },
      },

      preDrawCallback: this.props.beforeDraw,

      createdRow: (row, data, dataIndex) => {
        if (this.props.rowIdPrefix) {
          $(row).attr('id', `${this.props.rowIdPrefix}-row-${dataIndex}`);
        }
      },
      drawCallback: (settings) => {
        const api = new $.fn.DataTable.Api(settings);

        if (this.props.selectOption) {
          this.props.selected.map((selected) => api.rows(`#${selected}`).select());
        }

        if (_isFunction(this.props.addEventListeners)) {
          this.props.addEventListeners();
        }

        // Provide a way to short-circuit this callback if desired.
        const doShortCircuit = this.props.afterDraw(settings) === false;
        if (!doShortCircuit) {
          $(api.table().node())
            .closest('.js-datatable-container')
            .find('.js-datatable-display-count')
            .text(api.page.info().recordsDisplay);
        }

        if (this._isMounted) {
          return this.setState({
            isLoading: false,
          });
        }
      },
    });

    // Currently the component using this component must track the selected state
    if (this.props.onSelect) {
      // Function signature: function ( e, dt, type, indexes )
      table.on('select', this.props.onSelect);
    }
    if (this.props.onDeselect) {
      // Function signature: function ( e, dt, type, indexes )
      return table.on('deselect', this.props.onDeselect);
    }
  }

  reInitDatatable() {
    this.datatableNode().DataTable().clear().destroy();
    return this.initDatatable();
  }

  refreshDatatable() {
    return this.datatableNode().DataTable().draw(this.props.refreshFullReset);
  }

  table() {
    const { isLoading } = this.state;

    return (
      <div>
        {isLoading && <LoadingIndicator />}
        <div className={isLoading ? 'hidden' : ''}>
          <table className="table table-hover" ref={this.setDatatableRef}>
            <thead>
              <tr>{this.props.columns}</tr>
            </thead>

            <tbody />
          </table>
        </div>
      </div>
    );
  }

  render() {
    return this.props.handleRender(this.table());
  }
}

Datatable.propTypes = {
  columnDefs: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  handleRender: PropTypes.func.isRequired,
  route: PropTypes.string.isRequired,
  addEventListeners: PropTypes.func,
  afterDraw: PropTypes.func,
  afterRefresh: PropTypes.func,
  beforeDraw: PropTypes.func,
  initialSort: PropTypes.array,
  needsRefresh: PropTypes.bool,
  refreshFullReset: PropTypes.bool,
  pageLength: PropTypes.number,
  rowId: PropTypes.string,
  rowIdPrefix: PropTypes.string,
  serverParams: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  shouldShowSearchBox: PropTypes.bool,
  selected: PropTypes.array,
  selectOption: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  onSelect: PropTypes.func,
  onDeselect: PropTypes.func,
  hidePagination: PropTypes.bool,
};

Datatable.defaultProps = {
  afterDraw() {
    // Do nothing by default
  },
  beforeDraw() {
    // Do nothing by default
  },
  initialSort: [],
  pageLength: 10,
  rowId: 'DT_RowId',
  serverParams: {},
  shouldShowSearchBox: true,
  selectOption: false,
  refreshFullReset: true,
};
