import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import ModalWarningIcon from './ModalWarningIcon';
import SingleHolidayImpactedTransferTable from './holiday_impacted_transfers_table/SingleHolidayImpactedTransferTable';
import { holidayShape } from 'react/member/card/shapes/HolidayShape';
import { impactedTransferShape } from 'react/member/card/shapes/TransferShape';
import TrueLinkModal from 'react/shared/components/true_link/lab/TrueLinkModal';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import PALETTE from 'react/shared/theme/palette';

const useStyles = makeStyles({
  modal: {
    width: 'auto',
    borderRadius: '6px !important',
    '& .modal-header': {
      backgroundColor: PALETTE.evergreen,
      color: '#FFF',
      padding: '1em 1.5em',
    },
    '& .modal-title': {
      fontSize: '18px',
      fontWeight: '700',
      display: 'flex',
      alignItems: 'center',
    },
  },
  note: {
    marginBottom: '15px',
  },
});

export default function SingleHolidayImpactedTransferModal({
  holiday,
  impactedTransfer,
  onSubmit,
}) {
  // Hooks
  // -----------------------------------------------------
  const classes = useStyles();

  // Handlers
  // -----------------------------------------------------
  const handleAcceptGuidance = (ev) => {
    ev.preventDefault();
    onSubmit([{ guidance_accepted: 'true', transfer_ids: [impactedTransfer.id] }]);
  };

  const handleRejectGuidance = (ev) => {
    ev.preventDefault();
    onSubmit([{ guidance_accepted: 'false', transfer_ids: [impactedTransfer.id] }]);
  };

  const title = `This transfer will be delayed by the ${holiday.name} holiday`;

  return (
    <TrueLinkModal
      modalProps={{ className: classes.modal, maxWidth: 'lg' }}
      title={
        <>
          <ModalWarningIcon />
          {title}
        </>
      }
    >
      <SingleHolidayImpactedTransferTable
        date={impactedTransfer.holidayImpactedDate.date}
        impactedTransfer={impactedTransfer}
      />
      <TrueLinkButton onClick={handleAcceptGuidance} variant="primary">
        Adjust to avoid delay
      </TrueLinkButton>
      <TrueLinkButton
        onClick={handleRejectGuidance}
        style={{ marginLeft: '8px' }}
        variant="primaryOutline"
      >
        Leave as is
      </TrueLinkButton>
    </TrueLinkModal>
  );
}

SingleHolidayImpactedTransferModal.propTypes = {
  holiday: holidayShape.isRequired,
  impactedTransfer: impactedTransferShape.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
