import PropTypes from 'prop-types';
import React, { Children, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import childrenShape from 'react/shared/shapes/children';

export default function TabNavigation({
  initialTabIndex,
  tabs,
  style,
  onTabChange,
  customHeaderStyle,
  showBackground,
  children,
  classOptions,
  disableHash,
}) {
  const [currentTabIndex, setCurrentTabIndex] = useState(initialTabIndex || 0);

  const showTabBasedOnUrl = useCallback(() => {
    const urlFragment = window.location.hash;

    if (tabs.find((tab) => urlFragment === `#${tab.id}`)) {
      const tabId = `${urlFragment}-id`;
      $(tabId).tab('show');
      setCurrentTabIndex($(tabId).data('tab-index'));
    }
  }, [tabs]);

  // This is all very cludgy and is because this TabNavigation needs to be able
  // to respond to page navigation that happens outside of itself, but that
  // requires relying on `useLocation`, which is not available in all cases
  // where this is used and we want it to work as it used to without this
  // behavior in the older cases.
  let location;
  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    location = useLocation();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      showTabBasedOnUrl();
    }, [location, showTabBasedOnUrl]);
  } catch (e) {
    // There are times where the Tab Navigation is not under React Router and that is expected
  }

  useEffect(() => {
    showTabBasedOnUrl();
  }, [showTabBasedOnUrl]);

  const changeTab = (ev) => {
    const tabLink = $(ev.currentTarget).find('a');
    const tab = tabLink.attr('id').slice(0, -3);
    if (!disableHash) {
      window.location.hash = tab;
    }
    setCurrentTabIndex(tabLink.data('tab-index'));
    if (onTabChange) {
      onTabChange(tab, tabLink.text());
    }
  };

  const formatTab = (tab, index) => {
    let bubble;
    if (tab.notification) {
      bubble = (
        <span className="notify-bubble" style={tab.customNotificationStyle}>
          {tab.notification}
        </span>
      );
    } else if (tab.generic) {
      bubble = (
        <span className="notify-bubble" style={tab.customNotificationStyle}>
          &#9679;
        </span>
      );
    }

    if (tab.href) {
      return (
        <li key={tab.id} role="presentation">
          {bubble}
          <a
            aria-controls={tab.id}
            data-bubble={tab.notification}
            data-tab-index={index}
            data-tab-type={tab.type}
            href={tab.href}
            role="tab"
            target={'_self'}
          >
            {tab.title}
          </a>
        </li>
      );
    }

    if (tab.hideHref == true) {
      let className;
      if (index == currentTabIndex) {
        className = 'active ';
      } else {
        className = ' ';
      }
      className += tab.className;

      return (
        <li className={className} key={tab.id} onClick={changeTab} role="presentation">
          {bubble}
          <a
            aria-controls={`${String(tab.id)}-id`}
            data-tab-index={index}
            data-tab-type={tab.type}
            data-toggle="tab"
            id={`${String(tab.id)}-id`}
            role="tab"
          >
            {tab.title}
          </a>
        </li>
      );
    }

    let className;
    if (index == currentTabIndex) {
      className = 'active ';
    } else {
      className = ' ';
    }

    className += tab.className;

    return (
      <li className={className} key={tab.id} onClick={changeTab} role="presentation">
        {bubble}
        <a
          aria-controls={`${tab.id}-id`}
          data-bubble={tab.notification}
          data-tab-index={index}
          data-tab-type={tab.type}
          data-toggle="tab"
          href={`#${tab.id}`}
          id={`${tab.id}-id`}
          role="tab"
        >
          {tab.title}
        </a>
      </li>
    );
  };

  return (
    <div className="tab_navigation">
      <div style={customHeaderStyle || {}}>
        {showBackground && <div className="nav-background" />}
        <ul className={classOptions || 'nav nav-tabs'} role="tablist" style={style}>
          {tabs.map((tab, index) => formatTab(tab, index))}
        </ul>
      </div>
      <div>{Children.toArray(children).filter(Boolean)[currentTabIndex]}</div>
    </div>
  );
}

TabNavigation.propTypes = {
  children: childrenShape.isRequired,
  initialTabIndex: PropTypes.number,
  showBackground: PropTypes.bool,
  disableHash: PropTypes.bool,
  onTabChange: PropTypes.func,
  classOptions: PropTypes.string,
  customHeaderStyle: PropTypes.object,
  style: PropTypes.object,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    }),
  ).isRequired,
};
