import jQuery from 'jquery';

(function ($) {
  $.validator.addMethod("ssn", function (value, element) {
    if (value == "") {
      return true;
    }
    var regex = /^([0-8]\d{2})([ \-]?)(\d{2})\2(\d{4})$/;
    if (!regex.test(value)) {
      return false;
    }
    var temp = value;
    if (value.indexOf("-") != -1) {
      temp = (value.split("-")).join("");
    }
    if (value.indexOf(" ") != -1) {
      temp = (value.split(" ")).join("");
    }
    if (temp.substring(0, 3) == "000") {
      return false;
    }
    if (temp.substring(0, 3) == "666") {
      return false;
    }
    if (temp.substring(3, 5) == "00") {
      return false;
    }
    if (temp.substring(5, 9) == "0000") {
      return false;
    }
    return true;
  }, 'Please enter a valid SSN.');

  $.validator.addMethod("requireNonPOBox", function (value, element) {
    return !(new RegExp($tlf.domains.poBoxRegex, 'gmi').test(value));
  }, 'This address cannot be a P.O. Box.');

  $.validator.addMethod("cardLast4", function (value, element) {
    return new RegExp('^\\d{4}$', 'gm').test(value);
  }, "Please enter a 4-digit number.");

  $.validator.addMethod("validDate", function (value, element) {
    return (String(value.replace(new RegExp('_', 'g'), '')).length == 10 && moment(value, "MM/DD/YYYY").isValid());
  }, "Please enter a valid date.");

  $.validator.addMethod("charsOnly", function(value, element) {
    return /^[a-zA-Z ]+$/.test(value);
  }, "Must contain only letters.");

  $.validator.addMethod("maxlength", function(value, element, len) {
    return value == '' || value.length <= len;
  }, "Name is too long.");

  $.validator.addMethod("northAmericanPhone", function(value, element) {
    return this.optional(element) ||  /^[2-9]\d{2}-?\d{3}-?\d{4}$/.test(value);
  }, "Phone number cannot start with 0 or 1.");

  $.validator.addMethod("embossingLine", function(value, element) {
    return this.optional(element) || /^[a-z\d\s]+$/i.test(value);
  }, "Name must contain only letters, numbers, or spaces.");

  $.validator.addMethod("nonFutureDate", function(value, element) {
    var now = moment();
    var date = moment(value, "MM-DD-YYYY");
    return this.optional(element) || date.diff(now) < 0;
  }, "Date cannot be in future.");

}(jQuery));
