import PropTypes from 'prop-types';
import React from 'react';
import ClientSelectNav from './clients/ClientSelectNav';
import DashboardActions from 'react/member/actions/dashboard_actions';
import DashboardClientStore from 'react/shared/stores/DashboardClientStore';
import bindAll from 'react/shared/utils/bind_all';

export default class BeneDashboardClientSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clients: [],
      loading: true,
    };
    bindAll(this);
  }

  componentDidMount() {
    DashboardClientStore.on('clients.fetchSelectIds', this.onChangeClients);
    DashboardActions.fetchClientSelectIds();
  }

  componentWillUnmount() {
    DashboardClientStore.off('clients.fetchSelectIds', this.onChangeClients);
  }

  onChangeClients(clients) {
    this.setState({
      areClientsLoading: false,
      clients,
    });
  }

  redirectToClient(clientSlug) {
    return RailsRoutes.client_beneficiary_home_path(clientSlug);
  }

  render() {
    return (
      <ClientSelectNav
        clients={this.state.clients}
        isLoading={this.state.areClientsLoading}
        redirectToClientURLFunc={this.redirectToClient}
        selected={this.props.client}
      />
    );
  }
}

BeneDashboardClientSelect.propTypes = {
  client: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    person: PropTypes.object.isRequired,
  }),
};
