import PropTypes from 'prop-types';
import React from 'react';

export default function EventsExport({ clientId, clientName }) {
  if (!clientId || !clientName) {
    return null;
  }

  const transactionExportPath = () => {
    const fileName = `${clientName} - investment`;
    return RailsRoutes.dashboard_client_wealth_account_events_path(
      clientId,
      'investment',
      fileName,
      {
        format: 'csv',
      },
    );
  };

  return (
    <form action={transactionExportPath()} method="get">
      <button className="btn btn-normal" type="submit">
        Export as CSV
      </button>
    </form>
  );
}

EventsExport.propTypes = {
  clientId: PropTypes.string,
  clientName: PropTypes.string,
};
