import PropTypes from 'prop-types';
import React from 'react';
import CardDetails from 'react/member/card/components/account_tab/card_status/CardDetails';
import { CARD_STATUSES } from 'react/member/utils/CardStatus';

export default function Inactive({ cardBalance, cardBreached, cardExpiryDate, cardLast4, status }) {
  const note = 'This card has been temporarily frozen.';
  return (
    <CardDetails
      cardBalance={cardBalance}
      cardBreached={cardBreached}
      cardLast4={cardLast4}
      expiryDate={cardExpiryDate}
      note={note}
      status={status}
    />
  );
}

Inactive.propTypes = {
  cardBalance: PropTypes.number,
  cardBreached: PropTypes.bool,
  cardExpiryDate: PropTypes.string,
  cardLast4: PropTypes.string.isRequired,
  status: PropTypes.oneOf([CARD_STATUSES.INACTIVE_CARD_STATUS]).isRequired,
};
