/* eslint-disable react/prop-types */
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import camelCase from 'lodash/camelCase';
import startCase from 'lodash/startCase';
import moment from 'moment-timezone';
import React from 'react';

const useStyles = makeStyles(() => ({
  newFormField: {
    minHeight: '31px',
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'left',
  },
  newFormLabel: {
    width: '220px',
    lineHeight: '1.2em',
    paddingTop: '9px',
    marginBottom: '10px',
    fontWeight: 'bold',
  },
  newFormData: {
    width: 'calc(100% - 220px)',
    fontSize: '18px',
    lineHeight: '1.2em',
  },
  createdAt: {
    fontSize: '14px',
    color: 'rgb(102, 102, 102)',
  },
}));

export default function DisbursementDecisions({ decisions, disbursementId }) {
  const classes = useStyles();

  const approvedBy = decisions.find((dec) => dec.decision === 'approved');
  const requestedBy = decisions.find((dec) => dec.decision === 'requested');

  return (
    <tr>
      <td colSpan={6}>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '100%' }}>
            {!approvedBy && (
              <Box className={classes.newFormField}>
                <Box className={classes.newFormLabel}>Approved By:</Box>{' '}
                <Box className={classes.newFormData}>N/A</Box>
              </Box>
            )}
            {!requestedBy && (
              <Box className={classes.newFormField}>
                <Box className={classes.newFormLabel}>Requested By:</Box>{' '}
                <Box className={classes.newFormData}>N/A</Box>
              </Box>
            )}
            {decisions.map((decision) => (
              <Box className={classes.newFormField} key={decision.id}>
                <Box className={classes.newFormLabel}>
                  {startCase(camelCase(decision.decision))} By:
                </Box>{' '}
                <Box className={classes.newFormData}>
                  {decision.email}
                  <Box className={classes.createdAt}>
                    {moment(decision.createdAt).format('MM-DD-YYYY h:mm a')}
                  </Box>
                </Box>
              </Box>
            ))}
          </div>

          <div style={{ width: '50%' }}>
            <Box className={classes.newFormField}>
              <Box className={classes.newFormLabel}>Disbursement ID:</Box>{' '}
              <Box>{disbursementId}</Box>
            </Box>
          </div>
        </div>
      </td>
    </tr>
  );
}
