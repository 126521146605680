// Taken from https://stackoverflow.com/a/53210158
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

// corresponds to 'PhoneFormatter#mobile_in_national_format'
const maskedPhoneRegExp = /^\(\d{3}\)\s\d{3}\s?-\s?\d{4}$/;

// corresponds to 'Person#dob_ordered' format
const maskedDateRegExp = /^(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])-(19|20)\d\d$/;

// year-first version of maskedDateRegExp
const dateRegExp = /^(19|20)\d\d-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/;

const zipRegExp = /^[0-9]{5}(?:-[0-9]{4})?$/;

const digitsOnlyRegExp = /^\d+$/;

export default {
  dateRegExp,
  digitsOnlyRegExp,
  phoneRegExp,
  maskedPhoneRegExp,
  maskedDateRegExp,
  zipRegExp,
};
