import ButtonGroup from '@mui/material/ButtonGroup';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import TablePageButton from './TablePageButton';
import TablePageCount from './TablePageCount';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    fontSize: '18px',
    paddingTop: '20px',
  },
  spacer: {
    flexGrow: 1,
  },
  paginationLinks: {
    cursor: 'pointer',
  },
  buttonGroup: {
    '& button': {
      borderRadius: 0,
      '&:last-child': {
        borderBottomRightRadius: 4,
        borderTopRightRadius: 4,
      },
      '&:first-child': {
        borderBottomLeftRadius: 4,
        borderTopLeftRadius: 4,
      },
    },
  },
});

export default function TablePagination({
  canPreviousPage,
  canNextPage,
  gotoPage,
  nextPage,
  pageIndex,
  pageCount,
  previousPage,
  pageSize,
  totalRecords,
  showPagination = false,
  rowLength,
}) {
  const classes = useStyles();

  if (pageCount === 1 && !showPagination) {
    return null;
  }

  return (
    <div className={classes.root}>
      <TablePageCount
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageSize={pageSize}
        rowLength={rowLength}
        totalRecords={totalRecords}
      />
      <div className={classes.spacer} />
      <ButtonGroup aria-label="outlined secondary button group" className={classes.buttonGroup}>
        <TablePageButton disabled={!canPreviousPage} onClick={() => gotoPage(0)} value="First" />
        <TablePageButton disabled={!canPreviousPage} onClick={previousPage} value="Previous" />
        <TablePageButton disabled={!canNextPage} onClick={nextPage} value="Next" />
        <TablePageButton
          disabled={!canNextPage}
          onClick={() => gotoPage(pageCount - 1)}
          value="Last"
        />
      </ButtonGroup>
    </div>
  );
}

TablePagination.propTypes = {
  rowLength: PropTypes.number,
  canPreviousPage: PropTypes.bool.isRequired,
  canNextPage: PropTypes.bool.isRequired,
  pageIndex: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  totalRecords: PropTypes.number,
  gotoPage: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  showPagination: PropTypes.bool,
};
