import PropTypes from 'prop-types';
import React from 'react';
import _map from 'underscore/modules/map';
import _sortBy from 'underscore/modules/sortBy';
import DashboardActions from 'react/member/actions/dashboard_actions';
import DashboardCardStore from 'react/shared/stores/DashboardCardStore';
import bindAll from 'react/shared/utils/bind_all';

export default class OldAttachCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cards: [],
      selected_card: undefined,
    };

    bindAll(this);
  }

  componentDidMount() {
    DashboardActions.fetchUnlinkedCardsByOrganization(this.props.organization.slug);
    DashboardCardStore.on('unlinked_cards.fetch', this.onChangeCards);
  }

  componentWillUnmount() {
    DashboardCardStore.off('unlinked_cards.fetch', this.onChangeCards);
  }

  onChangeCards(cards) {
    this.setState({ cards });
  }

  cardsList() {
    const { cards } = this.state;
    const sorted_cards = _sortBy(cards, (card) => card.cardholder.name);
    const list = _map(sorted_cards, (card, idx) => this.formatCard(card, idx));
    return (
      <select id="card_id" onChange={(e) => this.onChange(e)} style={{ margin: 0 }}>
        <option key="" value="">
          Select Card
        </option>
        {list}
      </select>
    );
  }

  onChange(e) {
    this.setState({ selected_card: e.currentTarget.value });
  }

  attachCard() {
    if (!this.state.selected_card) {
      Truelink.flash('error', 'Please select a card from the dropdown');
      return;
    }

    DashboardActions.linkCardToClient(this.props.client.slug, this.state.selected_card);
  }

  formatCard(card) {
    return (
      <option key={card.id} value={card.id}>
        {card.cardholder.name} - {card.last_4_digits} ({card.status})
      </option>
    );
  }

  render() {
    const list = this.cardsList();

    const style = {
      display: 'flex',
      alignItems: 'center',
      padding: '10px 20px',
    };

    return (
      <div className="info-box" style={style}>
        Connect a True Link Card to this client:&nbsp;&nbsp;
        <div>
          {list}
          &nbsp;&nbsp;
          <button className="btn normal" onClick={this.attachCard} type="button">
            Submit
          </button>
        </div>
      </div>
    );
  }
}

OldAttachCard.propTypes = {
  client: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
};
