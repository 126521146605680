import PropTypes from 'prop-types';
import React from 'react';

export default function RequestDisbursementButton({
  disbursementsFor,
  initialClient,
  toggleDisbursementForm,
}) {
  const hideDisbursementButton =
    initialClient &&
    !initialClient.disallow_disbursement_requests &&
    !initialClient.can_have_disbursement_requested;

  if (
    hideDisbursementButton ||
    (disbursementsFor !== 'Trust Beneficiary' && disbursementsFor !== 'Organization')
  ) {
    return null;
  }

  const showDisabledButton = initialClient && initialClient.disallow_disbursement_requests;
  const buttonContent = showDisabledButton ? (
    <button
      className="btn btn-success pull-right"
      disabled
      style={{ color: 'black' }}
      type="button"
    >
      Disbursement Creation Disabled
    </button>
  ) : (
    <button className="btn btn-success pull-right" onClick={toggleDisbursementForm} type="button">
      Request New Disbursement
    </button>
  );

  return <div className="pull-right">{buttonContent}</div>;
}

RequestDisbursementButton.propTypes = {
  disbursementsFor: PropTypes.oneOf(['Trust Beneficiary', 'Trust', 'Portfolio', 'Organization'])
    .isRequired,
  initialClient: PropTypes.object,
  toggleDisbursementForm: PropTypes.func.isRequired,
};
