import numeral from 'numeral';

export function asMoney(value, precision = 2) {
  if (typeof value !== 'number' && typeof value !== 'string') {
    throw 'asMoney can only be used on String and Number values.';
  }
  const format = `$0,0${precision ? `.${new Array(precision + 1).join('0')}` : ''}`;
  return numeral(value).format(format);
}

export function currencyFormatter(value, options = {}) {
  const { defaultValue, ...rest } = options;

  if (typeof value !== 'number' && typeof value !== 'string') {
    return defaultValue || '-';
  }

  const formatted = new window.Intl.NumberFormat('en-US', {
    currency: 'USD',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'currency',
    signDisplay: 'auto',
    ...rest,
  }).format(value);

  return formatted === '$NaN' ? defaultValue || '-' : formatted;
}
