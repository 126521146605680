import PropTypes from 'prop-types';
import React from 'react';
import {
  checkBlank,
  checkErrored,
  checkInputLength,
  clearBlankError,
  getPayeeErrorInitialState,
  listErrors,
  updateParentErrorState,
} from 'react/jaffe/components/forms/PayeeErrors';
import bindAll from 'react/shared/utils/bind_all';

export default class PayeeTextInputWithCounter extends React.Component {
  constructor(props) {
    super(props);

    this.state = getPayeeErrorInitialState.bind(this)({ value: this.props.defaultValue || '' });

    bindAll(this);
  }

  componentDidUpdate(prevProps, prevState) {
    checkErrored.bind(this)();
    if (prevState.errored !== this.state.errored || !this.state.canUpdateParentErrorState) {
      if (this.state.canUpdateParentErrorState) return updateParentErrorState.bind(this)();
    }
  }

  error() {
    return <ul className="new-form__error">{listErrors.bind(this)()}</ul>;
  }

  updateCounter(ev) {
    return this.setState({ value: ev.target.value });
  }

  counter(enteredChars) {
    const charsLeft = this.props.maxLength - enteredChars.length;
    const almostDone = Math.ceil(this.props.maxLength / 5);

    if (this.props.minLength === 0) {
      return (
        <span className={charsLeft < almostDone ? 'red' : null} style={{ marginLeft: '10px' }}>
          {charsLeft} characters left
        </span>
      );
    }
  }

  validate(ev) {
    checkInputLength.bind(this)(ev, this.props.minLength, this.props.maxLength, this.props.label);
    checkBlank.bind(this)(ev, this.props.requiredToSubmit);
  }

  onChange(ev) {
    if (this.props.onChange) this.props.onChange(ev);
    this.updateCounter(ev);
    return clearBlankError.bind(this)(ev);
  }

  render() {
    let inputClassName = 'new-form__data ';
    if (this.state.errored) inputClassName += 'validate-error';

    return (
      <div>
        <div className="new-form__label">
          <label htmlFor={this.props.type}>{this.props.label}</label>
        </div>
        <div className={inputClassName}>
          <input
            defaultValue={this.state.value}
            id={this.props.type}
            maxLength={this.props.maxLength}
            name={this.props.name}
            onBlur={this.validate}
            onChange={this.onChange}
            type="text"
          />
          {this.counter(this.state.value)}
          {this.error()}
        </div>
      </div>
    );
  }
}

PayeeTextInputWithCounter.propTypes = {
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  label: PropTypes.string,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  requiredToSubmit: PropTypes.bool,
  type: PropTypes.string.isRequired,
  // These are a hack we needed for removing a mix-in. These props are inspected by a Store that in bound to this component.
  // eslint-disable-next-line react/no-unused-prop-types
  parentError: PropTypes.object,
  // eslint-disable-next-line react/no-unused-prop-types
  addError: PropTypes.func,
  // eslint-disable-next-line react/no-unused-prop-types
  removeError: PropTypes.func,
};

PayeeTextInputWithCounter.defaultProps = {
  errorText: {
    cannotBeBlank: ' cannot be blank.',
  },
  maxLength: 50,
  minLength: 0,
  defaultValue: '',
};
