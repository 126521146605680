import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import TransferFundsForm from './TransferFundsForm';
import { autoTransferShape } from 'react/member/card/shapes/AutoTransferShape';
import { bankAccountShapeLite } from 'react/member/card/shapes/BankAccountShape';
import { transferShape } from 'react/member/card/shapes/TransferShape';
import TrueLinkModal from 'react/shared/components/true_link/lab/TrueLinkModal';
import theme from 'react/shared/theme/Theme';

const useStyles = makeStyles(() => ({
  transferFundsModal: {
    zIndex: '1000',
    '& .modal-body': {
      padding: '20px 30px 30px',
    },

    '& .MuiPaper-root': {
      width: '552px',

      [theme.breakpoints.down('sm')]: {
        margin: '0',

        '& .modal-body': {
          padding: '20px',
        },
      },
    },
  },
}));

export default function TransferFundsModal({
  cardholderName,
  cardStatus,
  bankAccount,
  editInstance,
  emergencyFundingEnabled,
  fundingType,
  isEffectivelyFullyFunded,
  onBack,
  onClose,
  onSuccess,
  onDelete,
  transfer,
}) {
  const classes = useStyles();
  let transferTitle = 'Transfer funds onto the True Link Card';
  if (editInstance) {
    transferTitle = 'Edit recurring transfer instance';
  } else if (transfer?.recurringType && transfer.recurringType !== 'one_time') {
    transferTitle = 'Edit recurring transfer series';
  } else if (transfer) {
    transferTitle = 'Edit one time transfer';
  }

  return (
    <TrueLinkModal
      handleBack={onBack}
      handleClose={onClose}
      modalProps={{ className: classes.transferFundsModal, maxWidth: 'md' }}
      title={transferTitle}
    >
      <div className={classes.transferFundsModal}>
        <TransferFundsForm
          bankAccount={bankAccount}
          cardStatus={cardStatus || ''}
          cardholderName={cardholderName}
          editInstance={editInstance}
          emergencyFundingEnabled={emergencyFundingEnabled}
          fundingType={fundingType}
          isEffectivelyFullyFunded={isEffectivelyFullyFunded}
          onClose={onClose}
          onDelete={onDelete}
          onSuccess={onSuccess}
          transfer={transfer}
        />
      </div>
    </TrueLinkModal>
  );
}

TransferFundsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  onDelete: PropTypes.func,
  bankAccount: bankAccountShapeLite,
  cardholderName: PropTypes.string,
  cardStatus: PropTypes.string,
  isEffectivelyFullyFunded: PropTypes.bool,
  editInstance: PropTypes.bool,
  transfer: PropTypes.oneOfType([transferShape, autoTransferShape]),
  emergencyFundingEnabled: PropTypes.bool,
  fundingType: PropTypes.string,
};
