import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import _isEmpty from 'underscore/modules/isEmpty';
import CardPaymentForm from 'react/shared/card/components/CardPaymentForm';
import StateDropdown from 'react/shared/components/state_dropdown';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import TrueLinkRadios from 'react/shared/components/true_link/main/form/TrueLinkRadios';
import bindAll from 'react/shared/utils/bind_all';

export default class PersonalizedCardForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      shippingDestination: '',
      expediteShipping: false,
      cardRecipientPhone: '',
    };

    bindAll(this);
  }

  onChangeShippingDestination(ev) {
    this.setState({ shippingDestination: ev.target.value });
  }

  onChangeExpediteShipping(ev) {
    const value = ev.target.value === 'on';
    this.setState({ expediteShipping: value });
  }

  onChangeCardRecipientPhone(ev) {
    this.setState({ cardRecipientPhone: ev.target.value });
  }

  cardholderName() {
    return `${this.context.cardholder.first_name} ${this.context.cardholder.last_name}`;
  }

  renderOtherShippingDestinationInputs() {
    const home_address = this.context.address || {};
    if (this.state.shippingDestination === 'other') {
      return (
        <div>
          <br />
          <div className="flex">
            <div>
              <label htmlFor="card_reissue[address_attributes][street1]">Street</label>
              <input
                defaultValue={home_address.street1}
                disabled={this.state.shippingDestination != 'other'}
                name="card_reissue[address_attributes][street1]"
                style={{ height: 37 }}
                type="text"
              />
            </div>
            <div style={{ marginLeft: 10 }}>
              <label htmlFor="card_reissue[address_attributes][street2]">Apt or unit number</label>
              <input
                defaultValue={home_address.street2}
                disabled={this.state.shippingDestination != 'other'}
                name="card_reissue[address_attributes][street2]"
                style={{ height: 37 }}
                type="text"
              />
            </div>
          </div>
          <div className="flex">
            <div>
              <label htmlFor="card_reissue[address_attributes][city]">City</label>
              <input
                defaultValue={home_address.city}
                name="card_reissue[address_attributes][city]"
                style={{ height: 37 }}
                type="text"
              />
            </div>
            <div style={{ marginLeft: 10 }}>
              <label htmlFor="card_reissue[address_attributes][state]">State</label>
              <StateDropdown
                defaultValue={home_address.state || ''}
                id="payee_state"
                name="card_reissue[address_attributes][state]"
              />
            </div>
            <div style={{ marginLeft: 10 }}>
              <label htmlFor="card_reissue[address_attributes][zip]">Zip</label>
              <input
                defaultValue={home_address.zip}
                maxLength="5"
                name="card_reissue[address_attributes][zip]"
                placeholder="Zip code"
                style={{ width: 70, height: 37 }}
                type="text"
              />
            </div>
          </div>
          <br />
        </div>
      );
    }
  }

  onSubmit(ev) {
    ev.preventDefault();
    if (_isEmpty(this.state.shippingDestination)) {
      return Truelink.flash(
        'error',
        `You must select where to send ${this.cardholderName()}'s True Link Card before requesting a reissue.`,
      );
    } else if (this.state.expediteShipping && _isEmpty(this.state.cardRecipientPhone)) {
      return Truelink.flash(
        'error',
        'Please provide a valid contact phone number for expedited contact.',
      );
    }

    this.requestCardReissue();
  }

  requestCardReissue() {
    const data = $('#card-reissue-form').serializeArray();
    const filteredData = data.filter((item) => item.name !== 'phone_type');
    filteredData.forEach((input) => {
      if (
        !input.name.startsWith('card_reissue') &&
        !input.name.startsWith('expedited_shipping_card_fee')
      ) {
        input.name = `card_reissue[${input.name}]`;
      }
    });

    const url = this.orderPersonalizedCardUrl();
    $.post(url, filteredData).done(this.onSuccessForm);
  }

  orderPersonalizedCardUrl() {
    return RailsRoutes.order_personalized_card_dashboard_cards_path({
      account_id: this.context.accountId,
      format: 'json',
    });
  }

  onSuccessForm() {
    Truelink.flashClear();
    this.context.toggleConfirmationModal();
    Truelink.flash('success', 'Personalized card order submitted!');
  }

  render() {
    const shippingDestinationOptions = [
      {
        id: 'ship_to_home',
        label: (
          <>
            <Typography variant="body2">Cardholder home</Typography>
            <Typography style={{ color: '#757575' }} variant="body2">
              Ship To: {this.context.cardholderAddressAsString}
            </Typography>
          </>
        ),
        value: 'cardholder_home',
      },
      {
        id: 'ship_to_office',
        label: (
          <>
            <Typography variant="body2">Office</Typography>
            <Typography style={{ color: '#757575' }} variant="body2">
              Ship To: {this.context.officeAddressStringAsString}
            </Typography>
          </>
        ),
        value: 'office',
      },
      {
        id: 'ship_to_other',
        label: (
          <>
            <Typography variant="body2">Other</Typography>
            <Typography style={{ color: '#757575' }} variant="body2" />
          </>
        ),
        value: 'other',
      },
    ];

    return (
      <form id="card-reissue-form" onSubmit={this.onSubmit}>
        <br />
        <b style={{ fontSize: 20 }}>
          Please select where to send {this.cardholderName()}'s new True Link Card:
        </b>
        <div>
          <TrueLinkRadios
            ariaLabel="Which shipping address would you prefer"
            name="shipping_destination"
            noBorder
            onChange={this.onChangeShippingDestination}
            options={shippingDestinationOptions}
            value={this.state.shippingDestination}
          />
          {this.renderOtherShippingDestinationInputs()}
          <CardPaymentForm
            adminMobile={this.context.adminMobile}
            allowAutomatedPayment={this.context.allowAutomatedPayment}
            cardholderMobile={this.context.cardholderMobile}
            expeditedShipping={this.context.expeditedShipping}
            paymentMethods={this.context.paymentMethodOptions}
            regularShipping={this.context.regularShipping}
            shippingFee={this.context.shippingFee}
          />
        </div>
        <TrueLinkButton className="btn btn-success" type="submit" variant="none">
          Order Personalized Card
        </TrueLinkButton>
      </form>
    );
  }
}

PersonalizedCardForm.contextTypes = {
  accountId: PropTypes.string,
  adminMobile: PropTypes.string,
  cardholder: PropTypes.object,
  cardholderMobile: PropTypes.string,
  address: PropTypes.object,
  cardholderAddressAsString: PropTypes.string,
  officeAddressStringAsString: PropTypes.string,
  toggleConfirmationModal: PropTypes.func,
  regularShipping: PropTypes.string,
  expeditedShipping: PropTypes.string,
  shippingFee: PropTypes.string,
  allowAutomatedPayment: PropTypes.bool,
  paymentMethodOptions: PropTypes.object,
};
