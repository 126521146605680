import PropTypes from 'prop-types';
import React from 'react';
import _has from 'underscore/modules/has';
import {
  addError,
  removeError,
  checkBlank,
  checkErrored,
  clearBlankError,
  listErrors,
  getPayeeErrorInitialState,
  updateParentErrorState,
} from 'react/jaffe/components/forms/PayeeErrors';
import bindAll from 'react/shared/utils/bind_all';

export default class PayeeBankAccountNumberInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = getPayeeErrorInitialState.bind(this)();

    bindAll(this);
  }

  componentDidUpdate(prevProps, prevState) {
    checkErrored.bind(this)();
    if (prevState.errored !== this.state.errored || !this.state.canUpdateParentErrorState) {
      return updateParentErrorState.bind(this)();
    }
  }

  checkAccountNumber(ev) {
    if (this.accountNumberValid(ev.target.value)) {
      return removeError.bind(this)('invalidAccountNumber');
    }
    return addError.bind(this)({ invalidAccountNumber: this.props.errorText.invalidAccountNumber });
  }

  accountNumberValid(value) {
    return !(!this.accountNumLengthValid(value) || /\D/.test(value));
  }

  accountNumLengthValid(value) {
    return value.length >= 4 && value.length <= 17;
  }

  validate(ev) {
    checkBlank.bind(this)(ev);
    this.checkAccountNumber(ev);
    if (_has(this.props, 'updateBankNumber')) return this.props.updateBankNumber(ev);
  }

  error() {
    return <ul className="new-form__error">{listErrors.bind(this)()}</ul>;
  }

  render() {
    let inputClassName = 'new-form__data ';
    if (this.state.errored) {
      inputClassName += 'validate-error';
    }
    return (
      <div className="hide-arrows">
        <div className="new-form__label">
          <label htmlFor={this.props.type}>{this.props.label}</label>
        </div>
        <div className={inputClassName}>
          <input
            defaultValue={this.props.defaultValue}
            id={this.props.type}
            maxLength={this.props.maxLength}
            name={this.props.name}
            onBlur={this.validate}
            onChange={clearBlankError.bind(this)}
            onCopy={this.props.onCopy}
            type="number"
            value={this.props.value}
          />
          {this.error()}
        </div>
      </div>
    );
  }
}

PayeeBankAccountNumberInput.propTypes = {
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  errorText: PropTypes.object,
  value: PropTypes.string,
  label: PropTypes.string,
  maxLength: PropTypes.number,
  onCopy: PropTypes.func,
  type: PropTypes.string.isRequired,
  updateBankNumber: PropTypes.func,
  // These are a hack we needed for removing a mix-in. These props are inspected by a Store that in bound to this component.
  // eslint-disable-next-line react/no-unused-prop-types
  parentError: PropTypes.object,
  // eslint-disable-next-line react/no-unused-prop-types
  addError: PropTypes.func,
  // eslint-disable-next-line react/no-unused-prop-types
  removeError: PropTypes.func,
};

PayeeBankAccountNumberInput.defaultProps = {
  errorText: {
    cannotBeBlank: ' cannot be blank. ',
    invalidAccountNumber: 'Please enter an account number between 4 and 16 characters',
  },
  maxLength: 50,
};
