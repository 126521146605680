import PropTypes from 'prop-types';
import React from 'react';

function EmailAndPasswordFields(props) {
  const defaultEmail = props.defaultEmail || '';
  const disabledInputs = props.disabledInputs || false;

  return (
    <div className="card--form__section">
      <div className="card--form__row">
        <div className="card--form__col width-100">
          <label htmlFor="user_email">
            Email address
            <input
              autoComplete="off"
              className="required"
              defaultValue={defaultEmail}
              disabled={disabledInputs}
              id="user_email"
              name="user[email]"
              type="email"
            />
          </label>
        </div>
      </div>
      <div className="card--form__row password-row">
        <div className="card--form__col width-100">
          <label htmlFor="user_password">
            Password
            <span className="card--form__input-addition">
              min 8 characters, 1 letter, 1 number, 1 special character
            </span>
            <input
              autoComplete="off"
              className="required"
              disabled={disabledInputs}
              id="user_password"
              name="user[password]"
              type="password"
            />
          </label>
        </div>
      </div>
      <div className="card--form__row password-row">
        <div className="card--form__col width-100">
          <label htmlFor="user_password_confirmation">
            Password confirmation
            <input
              autoComplete="off"
              className="required"
              disabled={disabledInputs}
              id="user_password_confirmation"
              name="user[password_confirmation]"
              type="password"
            />
          </label>
        </div>
      </div>
    </div>
  );
}

EmailAndPasswordFields.propTypes = {
  defaultEmail: PropTypes.string,
  disabledInputs: PropTypes.bool,
};

export default EmailAndPasswordFields;
