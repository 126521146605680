export function isMapsAvailable() {
  return Boolean(window.google);
}

export function getPlaceDetails(placeId) {
  const placesService = new window.google.maps.places.PlacesService(
    new window.google.maps.Map(document.createElement('div')),
  );
  return new Promise((resolve) =>
    placesService.getDetails({ placeId }, (result) => resolve(result)),
  );
}

export function getAutoCompleteResults(predictionText) {
  const autocompleteService = new window.google.maps.places.AutocompleteService();
  return autocompleteService.getPlacePredictions({
    input: predictionText,
    types: ['address'],
    componentRestrictions: { country: 'us' },
  });
}
