import axios from 'axios';
import { AXIOS_XHR_CONFIG } from 'react/shared/utils/Axios';

export default {
  getImpactedTransfersByDate(holidayImpactedDate) {
    const url = RailsRoutes.dashboard_transfers_path({
      holiday_impacted_date_id: holidayImpactedDate.id,
      format: 'json',
    });
    return axios.get(url, AXIOS_XHR_CONFIG);
  },
  patchApplyTransferGuidance(guidance) {
    const url = RailsRoutes.apply_guidance_dashboard_holiday_impacted_transfers_path({
      format: 'json',
    });

    return axios.patch(url, { guidance }, AXIOS_XHR_CONFIG);
  },
};
