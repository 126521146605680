// Disabling since this is only internal info and coming from an internal API
/* eslint-disable react/no-danger */
import React from 'react';
import InProductAdShape from 'react/member/shapes/in_product_ad_shape';
import bindAll from 'react/shared/utils/bind_all';

export default class DashboardInProductAd extends React.Component {
  static get propTypes() {
    return {
      inProductAd: InProductAdShape.isRequired,
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      dismissed: this.props.inProductAd.dismissed_at,
    };
    bindAll(this);
  }

  componentDidMount() {
    this.handleAdView();
  }

  handleAdView() {
    const url = RailsRoutes.viewed_dashboard_in_product_ad_path(this.props.inProductAd.id, {
      format: 'json',
    });
    $.post(url, {});
  }

  handleAdClick(evt) {
    const $target = $(evt.target);
    if ($target.is('a')) {
      evt.preventDefault();
      const adUrl = $target.attr('href');
      window.open(
        RailsRoutes.clicked_dashboard_in_product_ad_path(this.props.inProductAd.id, {
          format: 'json',
          url: adUrl,
        }),
      );
    }
  }

  handleAdDismiss(evt) {
    evt.preventDefault();
    const url = RailsRoutes.dismissed_dashboard_in_product_ad_path(this.props.inProductAd.id, {
      format: 'json',
    });
    $.post(url, {}).done((_response) => {
      this.setState({ dismissed: true });
    });
  }

  renderDismissedContent() {
    const { inProductAd } = this.props;

    return (
      <div
        className="in-product-ad--dismissed"
        dangerouslySetInnerHTML={{ __html: inProductAd.dismissed_content_html }}
        onClick={this.handleAdClick}
        onKeyUp={this.handleAdClick}
        role="link"
        tabIndex={0}
      />
    );
  }

  renderUndismissedContent() {
    const { inProductAd } = this.props;

    return (
      <div className="in-product-ad--box">
        <a
          className="dismiss"
          onClick={this.handleAdDismiss}
          onKeyUp={this.handleAdDismiss}
          role="button"
          tabIndex={0}
        >
          ×
        </a>
        <div
          dangerouslySetInnerHTML={{ __html: inProductAd.undismissed_content_html }}
          onClick={this.handleAdClick}
          onKeyUp={this.handleAdClick}
          role="link"
          tabIndex={0}
        />
      </div>
    );
  }

  render() {
    if (this.state.dismissed) {
      return this.renderDismissedContent();
    }
    return this.renderUndismissedContent();
  }
}
