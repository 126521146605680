export const originTypes = {
  bene: 'TrustBeneficiary',
  organization: 'Organization',
  portfolio: 'Portfolio',
  trust: 'Trust',
};

export const reportNames = {
  customStatements: 'Custom time period statements',
  quarterlyStatements: 'Quarterly statements',
  disbursementsByCategory: 'Disbursements by category',
};
