export const States = {
  INIT: 'initial',
  CONSUMER: {
    FIRST: 'consumer_first',
    SECOND: 'consumer_second',
    FINAL: 'consumer_final',
  },
  PROFESSIONAL: {
    FIRST: 'professional_first',
    SECOND: 'professional_second',
    FINAL: 'professional_final',
  },
};
