import React from 'react';
import _isEmpty from 'underscore/modules/isEmpty';
import PopUp from 'react/shared/components/popups/PopUp';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import genericSubmitPopUpShapeKeys from 'react/shared/shapes/GenericSubmitPopUpShape';
import bindAll from 'react/shared/utils/bind_all';

export default class GenericSubmitPopUp extends React.Component {
  static get propTypes() {
    return {
      ...genericSubmitPopUpShapeKeys,
    };
  }

  static get defaultProps() {
    return {
      disableSubmit: false,
      displayErrorText: false,
      errorText: 'Please enter a value',
      messageText: null,
      warningText: null,
      submitText: 'Confirm',
      subheaderNode: null,
      messageNode: null,
      footerNode: null,
      popupProps: {},
      submitButtonProps: {},
    };
  }

  constructor(props) {
    super(props);
    bindAll(this);
  }

  handleClose(_event) {
    this.props.onClose(null);
  }

  renderSubheader() {
    return <div className="generic-popup__subheader">{this.props.subheaderNode || null}</div>;
  }

  renderBody() {
    const { messageText, errorText, warningText } = this.props;

    return (
      <div className="generic-popup__body">
        <div>
          {this.props.messageNode || <p className="generic-popup__body--text">{messageText}</p>}
          {!_isEmpty(warningText) ? (
            <div className="generic-popup__body--warn">{warningText}</div>
          ) : null}
          {this.props.displayErrorText ? (
            <div className="generic-popup__body--input-error">{errorText}</div>
          ) : null}
        </div>
      </div>
    );
  }

  renderFooter() {
    return (
      <div className="generic-popup__footer">
        {this.props.footerNode || (
          <TrueLinkButton
            className="btn btn-success"
            disabled={this.props.disableSubmit}
            onClick={this.props.onSubmit}
            variant="none"
            {...this.props.submitButtonProps}
          >
            {this.props.submitText}
          </TrueLinkButton>
        )}
      </div>
    );
  }

  render() {
    return (
      <PopUp
        dismissBtnText={this.props.dismissBtnText}
        footer={this.renderFooter()}
        header={this.props.headerText}
        onClose={this.handleClose}
        openModal
        {...this.props.popupProps}
      >
        {this.renderSubheader()}
        {this.renderBody()}
      </PopUp>
    );
  }
}
