import PropTypes from 'prop-types';

const { number, oneOf, string, shape } = PropTypes;

export const holidayImpactedDateShape = shape({
  date: string.isRequired,
  holidayId: number.isRequired,
  id: number.isRequired,
  guidance: oneOf(['Deliver before', 'Initiate before']).isRequired,
  guidanceDeliverDate: string.isRequired,
  supposedDeliverDate: string.isRequired,
});
