import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles({
  cell: {
    padding: '10px 15px',
    fontWeight: 700,
  },
});
export default function TableFooter({ footerGroup }) {
  const classes = useStyles();

  return (
    <tr {...footerGroup.getFooterGroupProps()}>
      {footerGroup.headers.map((column, columnIndex) => (
        <td className={classes.cell} key={columnIndex} {...column.getFooterProps()}>
          {column.render('Footer')}
        </td>
      ))}
    </tr>
  );
}

TableFooter.propTypes = {
  footerGroup: PropTypes.shape({
    getFooterGroupProps: PropTypes.func.isRequired,
    headers: PropTypes.arrayOf(
      PropTypes.shape({
        Footer: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
        id: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
};
