import PropTypes from 'prop-types';
import React from 'react';

export default function CardChoiceHeader({ isAlr, alrCardChoice }) {
  const getHeaderText = () => {
    if (!isAlr) {
      return 'You are a Beneficiary ordering a card for yourself';
    }

    switch (alrCardChoice) {
      case 'alr_only':
        return 'You are an Authorized Legal Representative ordering a card for yourself';
      case 'bene_only':
        return 'You are an Authorized Legal Representative ordering a card for your beneficiary';
      case 'alr_and_bene':
        return 'You are an Authorized Legal Representative ordering a card for both yourself and for your beneficiary';
    }
  };

  return <h2 style={{ fontWeight: 'bold' }}>{getHeaderText()}</h2>;
}

CardChoiceHeader.propTypes = {
  isAlr: PropTypes.bool.isRequired,
  alrCardChoice: PropTypes.string.isRequired,
};
