export function dateBetweenFilterFn(rows, id, filterValues) {
  const startDate = filterValues[0] ? new Date(filterValues[0]) : undefined;
  const endDate = filterValues[1] ? new Date(filterValues[1]) : undefined;

  if (endDate || startDate) {
    return rows.filter((r) => {
      const cellDate = new Date(r.values.deliveryDate);

      if (endDate && startDate) {
        return cellDate >= startDate && cellDate <= endDate;
      } else if (startDate) {
        return cellDate >= startDate;
      }
      return cellDate <= endDate;
    });
  }
  return rows;
}
