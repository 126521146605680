import PropTypes from 'prop-types';
import React from 'react';
import NavigationButtons from './navigation_buttons';
import { States } from './states';
import bindAll from 'react/shared/utils/bind_all';

export default class Profession extends React.Component {
  constructor(props) {
    super(props);

    const { profession, otherProfession } = this.props;
    this.state = {
      selectedOption: profession,
      textOption: otherProfession,
      error: undefined,
      textOptionError: undefined,
    };

    bindAll(this);
  }

  transitionTo(ev) {
    ev.preventDefault();
    const { selectedOption, textOption } = this.state;

    if (!selectedOption) {
      this.props.ensureOption(this, 'Please select an option.');
    } else if (selectedOption == 'other' && !textOption) {
      this.setState({ textOptionError: 'This field is required.' });
    } else {
      this.props.transition(States.PROFESSIONAL.SECOND, {
        profession: selectedOption,
        otherProfession: textOption,
      });
    }
  }

  transitionBackTo(ev) {
    ev.preventDefault();
    this.props.transition(States.INIT);
  }

  handleOptionChange(ev) {
    const { target } = ev;

    if (target.value !== 'other') {
      this.setState({
        selectedOption: target.value,
        textOption: '',
        error: undefined,
        textOptionError: undefined,
      });
    } else {
      this.setState({ selectedOption: target.value, error: undefined });
    }
  }

  handleTextChange(ev) {
    this.setState({ textOption: ev.target.value, textOptionError: undefined });
  }

  renderRadioButton(selectedOption, label, text) {
    const id = `${label}-radio`;

    return (
      <div className="questionnaire-radio">
        <input
          checked={selectedOption == label}
          className="questionnaire-radio__input"
          id={id}
          onChange={this.handleOptionChange}
          type="radio"
          value={label}
        />
        <label className="questionnaire-radio__label" htmlFor={id}>
          <span className="questionnaire-radio__checkbox-container" />
          {text}
        </label>
      </div>
    );
  }

  render() {
    const { selectedOption, textOption, error, textOptionError } = this.state;

    return (
      <div>
        <div className="questionnaire-form-item questionnaire-form-item__medium">
          <form onSubmit={this.transitionTo}>
            <div className="questionnaire-form-item-content">
              <div className="questionnaire-form-item-question">
                <h2>
                  In which of the following professional capacities will you primarily be managing
                  True Link Card(s)?
                </h2>
              </div>
              <div className="hide-on-mobile">{error && <p className="form-error">{error}</p>}</div>
              <div className="questionnaire-radio-wrapper questionnaire-radio-button-list--professional">
                {this.renderRadioButton(
                  selectedOption,
                  'unknown-profession',
                  'Professional guardian, fiduciary, representative payee, or trust administrator',
                )}
                {this.renderRadioButton(selectedOption, 'recovery', 'Recovery center')}

                {this.renderRadioButton(selectedOption, 'home-care', 'Home care agency')}
                {this.renderRadioButton(selectedOption, 'money-manager', 'Daily money manager')}
                {this.renderRadioButton(selectedOption, 'care-manager', 'Care manager')}
                {this.renderRadioButton(
                  selectedOption,
                  'retirement-community',
                  'Retirement community',
                )}
                {this.renderRadioButton(
                  selectedOption,
                  'residential-community',
                  'Other residential community',
                )}

                <div className="questionnaire-radio">
                  <input
                    checked={selectedOption == 'other'}
                    className="questionnaire-radio__input"
                    id="other-radio"
                    onChange={this.handleOptionChange}
                    type="radio"
                    value="other"
                  />
                  <label className="questionnaire-radio__label" htmlFor="other-radio">
                    <span className="questionnaire-radio__checkbox-container" />
                    <span className="questionnaire-radio__text">Other:</span>
                    <br />
                    <input
                      className="questionnaire-radio__label-input"
                      id="other-text"
                      onChange={this.handleTextChange}
                      type="text"
                      value={textOption}
                    />
                    {textOptionError && <p className="form-error">{textOptionError}</p>}
                  </label>
                </div>
              </div>
              <div className="questionnaire-info-section hide-on-mobile">
                We know that many of our customers act in more than one professional capacity. Don't
                worry - we'll ask more about your role in the next step.
              </div>
            </div>
            <div className="show-on-mobile">{error && <p className="form-error">{error}</p>}</div>
            <NavigationButtons transitionBackTo={this.transitionBackTo} />
          </form>
        </div>
      </div>
    );
  }
}

Profession.propTypes = {
  ensureOption: PropTypes.func.isRequired,
  otherProfession: PropTypes.string,
  profession: PropTypes.string,
  transition: PropTypes.func.isRequired,
};
