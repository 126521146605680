import PropTypes from 'prop-types';
import React from 'react';

const patriotActTextContent = `
  To help the federal government fight the funding of terrorism and money laundering activities,
  the USA PATRIOT Act requires all financial institutions and their third parties to obtain,
  verify and record information that identifies each person who opens a card account. What this
  means for you: When you open a card account, we will ask for your name, address, date of
  birth, and other identifying information that will allow us to identify you. We may also ask
  to see your driver's license or other identifying documents.
`;

function PatriotActDisclaimer({ className }) {
  return (
    <div className={className}>
      <b>Important information for opening a card account:</b>
      <p>{patriotActTextContent}</p>
    </div>
  );
}

PatriotActDisclaimer.propTypes = {
  className: PropTypes.string,
};

PatriotActDisclaimer.defaultProps = {
  className: '',
};

export default PatriotActDisclaimer;
export { patriotActTextContent };
