import Typography from '@mui/material/Typography';
import React from 'react';
import TrueLinkChip, { sizeTypes, colorTypes } from './TrueLinkChip';
import { camelToTitle } from 'react/shared/utils/Strings';

export default {
  title: 'Standard/Chip',
  component: TrueLinkChip,
  argTypes: {
    color: {
      options: colorTypes,
      control: { type: 'select' },
      table: {
        category: 'Style',
      },
    },
    size: {
      options: sizeTypes,
      control: { type: 'select' },
      table: {
        category: 'Style',
      },
    },
    clickable: {
      control: 'boolean',
      table: {
        category: 'Style',
      },
    },
    label: {
      control: 'text',
      table: {
        category: 'Contents',
      },
    },
    className: {
      control: 'text',
      table: {
        category: 'Style',
      },
    },
  },
};

export function Variants(args) {
  const text = args.children || 'Chip Text';
  return (
    <span style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
      {colorTypes.map((color) => (
        <React.Fragment key={color}>
          <span style={{ margin: '10px' }}>
            <Typography>{`Clickable: ${camelToTitle(color)}`}</Typography>
            {sizeTypes.map((size) => (
              <React.Fragment key={size}>
                <div style={{ margin: '10px' }}>
                  <TrueLinkChip {...args} clickable color={color} label={text} size={size} />
                </div>
              </React.Fragment>
            ))}
          </span>
          <span style={{ margin: '10px' }}>
            <Typography>{`Non-Clickable: ${camelToTitle(color)}`}</Typography>
            {sizeTypes.map((size) => (
              <React.Fragment key={size}>
                <div style={{ margin: '10px' }}>
                  <TrueLinkChip
                    {...args}
                    clickable={false}
                    color={color}
                    label={text}
                    size={size}
                  />
                </div>
              </React.Fragment>
            ))}
          </span>
        </React.Fragment>
      ))}
    </span>
  );
}
