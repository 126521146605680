import PropTypes from 'prop-types';

const { string, bool, func, node, object } = PropTypes;

const genericSubmitPopUpShapeKeys = {
  // popup text strings
  headerText: string.isRequired,
  submitText: string.isRequired,
  dismissBtnText: string,

  // modal content strings
  messageText: string,
  errorText: string,
  warningText: string,

  // modal content overrides
  subheaderNode: node,
  messageNode: node,
  footerNode: node,

  // popup actions
  onClose: func,
  onSubmit: func.isRequired,

  // form validity
  disableSubmit: bool,
  displayErrorText: bool,

  // targeted passdown props
  popupProps: object,
  submitButtonProps: object,
};

export default genericSubmitPopUpShapeKeys;
