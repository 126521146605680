import { func } from 'prop-types';
import React from 'react';
import SingleHolidayImpactedTransferModal from './SingleHolidayImpactedTransferModal';
import api from './api';
import { transferShape } from 'react/member/card/shapes/TransferShape';

export default function SingleTransferHolidayContainer({ holidayImpactedTransfer, onClose }) {
  const handleModalSubmit = (guidance) => {
    api.patchApplyTransferGuidance(guidance).then(() => {
      Truelink.flash('success', 'Your transfer has been updated.');
      onClose();
    });
  };

  const holidayImpactedDate = holidayImpactedTransfer.holidayImpactedDate;
  const holiday = holidayImpactedDate.holiday;

  const guidanceDeliverDate = holidayImpactedDate.guidanceDeliverDate;
  const supposedDeliverDate = holidayImpactedDate.supposedDeliverDate;

  const impactedTransfer = { ...holidayImpactedTransfer, guidanceDeliverDate, supposedDeliverDate };

  return (
    <SingleHolidayImpactedTransferModal
      holiday={holiday}
      impactedTransfer={impactedTransfer}
      onSubmit={handleModalSubmit}
    />
  );
}

SingleTransferHolidayContainer.propTypes = {
  holidayImpactedTransfer: transferShape.isRequired,
  onClose: func.isRequired,
};
