import PropTypes from 'prop-types';

const userRoleShape = PropTypes.shape({
  viewOnly: PropTypes.bool,
  restrictedView: PropTypes.bool,
  isTrueLinkAdmin: PropTypes.bool,
  managesSubaccounts: PropTypes.bool,
});

export default userRoleShape;
