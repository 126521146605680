import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import _range from 'underscore/modules/range';
import CategorizedBudgetTimelineStore from 'react/member/stores/CategorizedBudgetTimelineStore';
import Actions from 'react/shared/actions/actions';
import PopUp from 'react/shared/components/popups/PopUp';

const INPUT_DATE_FORMAT = 'M/DD/YY';
const DATEPICKER_FORMAT = 'M/D/YYYY';
const MAX_BUDGET_DURATION_YEARS = 10;

export default class CategorizedBudgetTimelineModal extends React.Component {
  constructor(props) {
    super(props);
    if (this.props.budgetTimeline && this.props.budgetTimeline.startDate) {
      this.isEdit = this.props.budgetTimeline.id;
      const { startDate, renewalDate } = this.props.budgetTimeline;
      this.state = {
        startDate: moment(startDate, INPUT_DATE_FORMAT).format(DATEPICKER_FORMAT),
        renewalDate: moment(renewalDate, INPUT_DATE_FORMAT).format(DATEPICKER_FORMAT),
        maintainCategories: false,
        startDateValid: startDate.length > 0,
        renewalDateValid: renewalDate.length > 0,
      };
    } else {
      this.state = {
        startDate: '',
        renewalDate: '',
        maintainCategories: false,
        startDateValid: false,
        renewalDateValid: false,
      };
    }
    this.state = { ...this.state, submitted: false, submitting: false };
    this.onSubmitSuccess = this.onSubmitSuccess.bind(this);
    this.onSubmitError = this.onSubmitError.bind(this);
  }

  componentDidMount() {
    this.initDatePicker();
    CategorizedBudgetTimelineStore.on('budgetTimelines.create', this.onSubmitSuccess);
    CategorizedBudgetTimelineStore.on('budgetTimelines.create.error', this.onSubmitError);
    CategorizedBudgetTimelineStore.on('budgetTimelines.update', this.onSubmitSuccess);
    CategorizedBudgetTimelineStore.on('budgetTimelines.update.error', this.onSubmitError);
  }

  componentWillUnmount() {
    CategorizedBudgetTimelineStore.off('budgetTimelines.create', this.onSubmitSuccess);
    CategorizedBudgetTimelineStore.off('budgetTimelines.create.error', this.onSubmitError);
    CategorizedBudgetTimelineStore.off('budgetTimelines.update', this.onSubmitSuccess);
    CategorizedBudgetTimelineStore.off('budgetTimelines.update.error', this.onSubmitError);
  }

  initDatePicker() {
    const $dp = $(ReactDOM.findDOMNode(this))
      .find('.js-start-date')
      .datepicker({ dateFormat: 'm/d/yy' });
    $dp.on('change', (e) => this.handleStartDateSelect(e));
  }

  handleStartDateSelect(e) {
    const startDate = e.target.value;
    const startDateValid = startDate.length > 0 && startDate !== 'Invalid date';
    this.setState({
      startDate,
      startDateValid,
    });
    // Update the renewal day and month to match the start day and month
    // because budget windows must be in increments of 1 year
    this.setState((oldState) => {
      if (oldState.renewalDate.length > 0) {
        const newStartMoment = moment(startDate, DATEPICKER_FORMAT);
        const newRenewalMoment = moment(oldState.renewalDate, DATEPICKER_FORMAT);
        newRenewalMoment.date(newStartMoment.date());
        newRenewalMoment.month(newStartMoment.month());

        return {
          renewalDate: newRenewalMoment.format(DATEPICKER_FORMAT),
        };
      }
    });
  }

  handleRenewalDateSelect(e) {
    const newRenewalDate = e.target.value;
    const renewalDateValid = newRenewalDate.length > 0 && newRenewalDate !== 'Invalid date';
    this.setState({
      renewalDate: newRenewalDate,
      renewalDateValid,
    });
  }

  handleMaintainCategories(e) {
    this.setState({ maintainCategories: e.target.checked });
  }

  renderHeader() {
    if (this.props.budgetTimelineExpired === true) {
      return <div>Budget Renewal Required</div>;
    }

    return <div>Budget timeframe</div>;
  }

  renderStartDatePicker() {
    const { startDate } = this.state;
    return (
      <div className="datepicker-wrapper">
        <input
          autoComplete="off"
          className="date_input budget__modal-input js-start-date"
          defaultValue={startDate}
          id="timeline-start-date"
          name="startDate"
          type="text"
        />
      </div>
    );
  }

  renderBody() {
    const { renewalDate } = this.state;
    const { trustBeneficiary, budgetTimelineExpired } = this.props;
    return (
      <div className="budget__modal-form">
        {budgetTimelineExpired && (
          <div>
            <div className="budget__modal-input-container">
              If you wish to keep your current budget categories, please select 'Maintain
              categories' below when selecting a new timeframe.
            </div>
            <hr />
          </div>
        )}
        <div className="budget__modal-input-container">
          <label htmlFor="beneficiary-person-name">Beneficiary:</label>
          <span id="beneficiary-person-name">{trustBeneficiary.person.name}</span>
        </div>
        <hr />
        <div className="budget__modal-input-container">
          <label htmlFor="timeline-start-date">Budget start:</label>
          {this.renderStartDatePicker()}
        </div>
        {this.renderStartDateValidationError()}
        <div className="budget__modal-input-container">
          <label className="" htmlFor="timeline-year">
            Budget renewal year:
          </label>
          <select
            className="budget__modal-input budget__modal-input--short"
            id="timeline-year"
            onChange={(e) => this.handleRenewalDateSelect(e)}
            type="number"
            value={renewalDate}
          >
            {this.renderYearOptions()}
          </select>
        </div>
        {this.renderRenewalYearValidationError()}
        <div className="budget__modal-input-container">
          <label htmlFor="timeline-renewal">Budget renewal:</label>
          <div className="budget__modal-input" id="timeline-renewal">
            {renewalDate}
          </div>
        </div>
        {budgetTimelineExpired && (
          <div className="budget__modal-input-container">
            <label htmlFor="timeline-maintain-categories">Maintain categories:</label>
            <div className="budget__modal-input" id="timeline-maintain-categories">
              <input
                name="maintainCategories"
                onChange={(e) => this.handleMaintainCategories(e)}
                type="checkbox"
                value="1"
              />
            </div>
          </div>
        )}
      </div>
    );
  }

  renderStartDateValidationError() {
    if (!this.state.startDateValid && this.state.submitted) {
      return <div className="red margin-bottom-10">Budget start date is required.</div>;
    }
  }

  renderRenewalYearValidationError() {
    if (!this.state.renewalDateValid && this.state.submitted) {
      return <div className="red margin-bottom-10">Budget renewal year is required.</div>;
    }
  }

  renderYearOptions() {
    const startDate = this.state.startDate.length > 0 ? this.state.startDate : moment();

    const renewalDates = _range(1, MAX_BUDGET_DURATION_YEARS).map((year) =>
      moment(startDate, DATEPICKER_FORMAT).add(year, 'years'),
    );

    const yearOptions = renewalDates.map((date) => (
      <option key={date} value={date.format(DATEPICKER_FORMAT)}>
        {date.format('YYYY')}
      </option>
    ));

    const blankOption = <option key="blank" value="" />;
    yearOptions.unshift(blankOption);
    return yearOptions;
  }

  renderSaveButton() {
    let buttonClass = 'btn btn-success';
    if (this.state.submitting) {
      buttonClass += ' disabled';
    }
    return (
      <div>
        <button
          className={buttonClass}
          id="submit-timeline-button"
          onClick={(e) => this.handleSave(e)}
          type="button"
        >
          Save budget timeframe
        </button>
      </div>
    );
  }

  handleSave(e) {
    e.preventDefault();
    if (this.state.submitting) {
      return;
    }
    this.setState({ submitted: true });
    if (this.isValid()) {
      this.submit();
    }
  }

  isValid() {
    const { startDateValid, renewalDateValid } = this.state;
    return startDateValid && renewalDateValid;
  }

  getTimelineAttrs() {
    return {
      startDate: moment(this.state.startDate, DATEPICKER_FORMAT).format(),
      renewalDate: moment(this.state.renewalDate, DATEPICKER_FORMAT).format(),
      maintainCategories: this.state.maintainCategories,
    };
  }

  submit() {
    const { trustBeneficiary } = this.props;
    const timelineAttrs = this.getTimelineAttrs();
    if (this.isEdit) {
      Actions.updateBudgetTimeline(this.props.budgetTimeline.id, timelineAttrs);
    } else {
      Actions.createBudgetTimeline(trustBeneficiary.id, timelineAttrs);
    }
    this.setState({ submitting: true });
  }

  onSubmitSuccess() {
    const { onClose, trustBeneficiary } = this.props;
    window.Truelink.flash('success', 'Budget timeframe saved');
    Actions.fetchCategorizedBudgets(trustBeneficiary.id);
    this.setState({ submitting: false });
    onClose();
  }

  onSubmitError() {
    this.setState({ submitting: false });
  }

  render() {
    return (
      <PopUp
        footer={this.renderSaveButton()}
        header={this.renderHeader()}
        maxWidth="550px"
        onClose={this.props.onClose}
        openModal
      >
        {this.renderBody()}
      </PopUp>
    );
  }
}

CategorizedBudgetTimelineModal.propTypes = {
  onClose: PropTypes.func,
  budgetTimeline: PropTypes.shape({
    id: PropTypes.string,
    startDate: PropTypes.string,
    renewalDate: PropTypes.string,
  }),
  budgetTimelineExpired: PropTypes.bool,
  trustBeneficiary: PropTypes.shape({
    id: PropTypes.number,
    person: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
};
