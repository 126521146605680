import PropTypes from 'prop-types';
import React from 'react';
import CharacterCountWarning from './CharacterCountWarning';
import {
  checkBlank,
  clearBlankError,
  checkErrored,
  getPayeeErrorInitialState,
  listErrors,
  updateParentErrorState,
} from 'react/jaffe/components/forms/PayeeErrors';
import bindAll from 'react/shared/utils/bind_all';

export default class PayeeAccountNumberInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = getPayeeErrorInitialState.bind(this)();

    bindAll(this);
  }

  componentDidUpdate(prevProps, prevState) {
    checkErrored.bind(this)();
    if (prevState.errored !== this.state.errored || !this.state.canUpdateParentErrorState) {
      return updateParentErrorState.bind(this)();
    }
  }

  onChange(ev) {
    this.props.onChange(ev);
    return clearBlankError.bind(this)(ev);
  }

  error() {
    return <ul className="new-form__error">{listErrors.bind(this)()}</ul>;
  }

  render() {
    let inputClassName = 'new-form__data ';
    if (this.state.errored) inputClassName += 'validate-error';

    return (
      <div>
        <div className="new-form__label">
          <label htmlFor={this.props.type}>{this.props.label}</label>
        </div>
        <div className={inputClassName}>
          <input
            id={this.props.type}
            maxLength={this.props.maxLength}
            name={this.props.name}
            onBlur={checkBlank.bind(this)}
            onChange={this.onChange}
            type="text"
            value={this.props.value || ''}
          />
          &nbsp; &nbsp;
          <CharacterCountWarning lengths={this.props.lengths} />
          {this.error()}
          <div className="new-form__callout" style={{ marginTop: 10 }}>
            This text will appear on the 'Memo' line of every check sent to this payee. You will be
            able to add additional text to the 'Memo' line when you create specific disbursements as
            well.
          </div>
        </div>
      </div>
    );
  }
}
PayeeAccountNumberInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  lengths: PropTypes.object,
  type: PropTypes.string.isRequired,
  // These are a hack we needed for removing a mix-in. These props are inspected by a Store that in bound to this component.
  // eslint-disable-next-line react/no-unused-prop-types
  parentError: PropTypes.object,
  // eslint-disable-next-line react/no-unused-prop-types
  addError: PropTypes.func,
  // eslint-disable-next-line react/no-unused-prop-types
  removeError: PropTypes.func,
};

PayeeAccountNumberInput.defaultProps = {
  errorText: {
    cannotBeBlank: ' cannot be blank.',
  },
  maxLength: 50,
};
