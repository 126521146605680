import PropTypes from 'prop-types';
import React from 'react';
import PopUp from 'react/shared/components/popups/PopUp';
import { possessiverize } from 'react/shared/utils/Strings';

export default function AutoTransferReviewChangesModal({ cardholderName, onClose }) {
  const name = possessiverize(cardholderName || 'the cardholder');
  return (
    <PopUp
      dismissBtnText="Close"
      header={`Review ${name} Scheduled Transfers`}
      hideFooter
      modalOpen
      onClose={onClose}
    >
      <div style={{ fontSize: 16 }}>
        <p>The frequency of the recurring transfer was updated.</p>
        <p>Please review the changes to {name} upcoming scheduled transfers.</p>
      </div>
    </PopUp>
  );
}

AutoTransferReviewChangesModal.propTypes = {
  cardholderName: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};
