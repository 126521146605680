import PropTypes from 'prop-types';
import React from 'react';
import { asMoney } from 'react/shared/utils/Money';

function BeneficiaryAccountBalance({ trustName, accountBalance }) {
  return (
    <div>
      <div className="sub_section_header">
        <div style={{ float: 'left' }}>Account Balance</div>
        <div style={{ float: 'right' }}>
          <span>Total: {asMoney(accountBalance)}</span>
        </div>
        <div style={{ clear: 'both' }} />
      </div>
      <div
        className="info-box"
        style={{ display: 'flex', alignItems: 'center', padding: '10px 30px' }}
      >
        {`The balance displayed is for informational purposes only. The balance will not necessarily include recent deposits, market fluctuations, interest earned and dividends earned. For your most accurate balance, please contact your representative at ${trustName}.`}
      </div>
    </div>
  );
}

BeneficiaryAccountBalance.propTypes = {
  trustName: PropTypes.string.isRequired,
  accountBalance: PropTypes.number.isRequired,
};

export default BeneficiaryAccountBalance;
