import { ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import _contains from 'underscore/modules/contains';
import CheckboxField from './fields/CheckboxField';

function AgreementCheckboxes({ cardProgram, rowClass, colClass, labelClass }) {
  const trustProgram = _contains(['1', '2'], cardProgram);
  const masterAgreementUrl = {
    1: '/legal/second-prog-master-services-agreement',
    2: '/legal/second-prog-master-services-agreement',
    3: '/legal/independence-master-services-agreement',
    4: '/legal/independence-ii-master-services-agreement',
    5: '/legal/independence-master-services-agreement',
    6: '/legal/p1-master-services-agreement',
    7: '/legal/next-step-master-services-agreement',
  };

  return (
    <div className={`${rowClass} margin-bottom-0`}>
      <div className={`${colClass} margin-bottom-0`}>
        <label className={labelClass} htmlFor="i_agree_to_the_master_services_agreement">
          <CheckboxField
            id="i_agree_to_the_master_services_agreement"
            name="i_agree_to_the_master_services_agreement"
          />
          <p>
            I acknowledge that I have received, reviewed, and agree to the terms of the{' '}
            <a
              href={masterAgreementUrl[cardProgram] || '/legal/master-services-agreement'}
              rel="noreferrer"
              target="_blank"
            >
              Master Services Agreement{' '}
            </a>
            and{' '}
            <a href="/legal/esign-consent" target="_blank">
              E-sign Agreement
            </a>
            .
          </p>
          <ErrorMessage
            className="error"
            component="label"
            name="i_agree_to_the_master_services_agreement"
          />
        </label>
        {!trustProgram && (
          <label className={labelClass} htmlFor="i_am_authorized_by_cardholder">
            <CheckboxField
              id="i_am_authorized_by_cardholder"
              name="i_am_authorized_by_cardholder"
            />
            <p>
              The intended Cardholder has knowingly and voluntarily authorized me to manage the True
              Link service settings in association with their card.
            </p>
            <ErrorMessage
              className="error"
              component="label"
              name="i_am_authorized_by_cardholder"
            />
          </label>
        )}
      </div>
    </div>
  );
}

AgreementCheckboxes.propTypes = {
  cardProgram: PropTypes.string.isRequired,
  rowClass: PropTypes.string,
  colClass: PropTypes.string,
  labelClass: PropTypes.string,
};

AgreementCheckboxes.defaultProps = {
  rowClass: 'card--form__row',
  colClass: 'card--form__col',
  labelClass: 'card--form__checkbox-label multiline',
};

export default AgreementCheckboxes;
