import PropTypes from 'prop-types';
import React from 'react';

export default function QuarterlyStatementDateDropdown({
  latestStatementEndDate,
  handleChange,
  quarterlyStatementDates,
  quarterlyDate,
}) {
  const optionText = (dateString) => {
    const parsedDate = latestStatementEndDate ? new Date(latestStatementEndDate) : null;
    const optionDate = new Date(dateString);
    if (parsedDate && parsedDate < optionDate) {
      return `${dateString} [DRAFT]`;
    }
    return dateString;
  };

  const renderOption = (date, idx) => (
    <option key={idx} value={date}>
      {optionText(date)}
    </option>
  );

  const onQuarterlyStatementDateChange = (event) => {
    handleChange(event);
  };

  let disabled, options;
  if (quarterlyStatementDates && quarterlyStatementDates.length > 0) {
    options = quarterlyStatementDates.map((date, idx) => renderOption(date, idx));
  } else {
    disabled = true;
    options = (
      <option key="N/A" value="N/A">
        No Quarterly Statements
      </option>
    );
  }

  return (
    <>
      <p>Select a quarter </p>
      <select
        disabled={disabled}
        name="quarterlyDate"
        onChange={onQuarterlyStatementDateChange}
        value={quarterlyDate}
      >
        {options}
      </select>
    </>
  );
}

QuarterlyStatementDateDropdown.propTypes = {
  latestStatementEndDate: PropTypes.string,
  handleChange: PropTypes.func,
  quarterlyStatementDates: PropTypes.arrayOf(PropTypes.string).isRequired,
  quarterlyDate: PropTypes.string,
};
