import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React from 'react';
import { formattedTotalFromBalances } from 'react/member/utils/IndividualTrustBalance';
import Datatable from 'react/shared/components/Datatable';
import TrueLinkLink from 'react/shared/components/true_link/main/TrueLinkLink';
import DashboardClientStore from 'react/shared/stores/DashboardClientStore';
import { isRestrictedViewUser } from 'react/shared/utils/Authorization';
import { bigDecimalWithCommas } from 'react/shared/utils/BigDecimal';
import bindAll from 'react/shared/utils/bind_all';

export default class DashboardPaginatedClientList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeSlideDownOpen: true,
      closingSlideDownOpen: false,
      inactiveSlideDownOpen: false,
      pendingSlideDownOpen: false,
      needsRefresh: false,
    };

    bindAll(this);
  }

  componentDidMount() {
    DashboardClientStore.on('clients.add', this.setNeedsRefresh);
  }

  componentWillUnmount() {
    DashboardClientStore.off('clients.add', this.setNeedsRefresh);
  }

  afterRefresh() {
    this.setState({
      needsRefresh: false,
    });
  }

  setNeedsRefresh() {
    this.setState({
      needsRefresh: true,
    });
  }

  isPendingView() {
    return this.props.statusFilter === 'Pending';
  }

  indexRoute() {
    const { orgSlug } = this.props;
    if (orgSlug) {
      return RailsRoutes.dashboard_organization_clients_path(orgSlug, {
        format: 'json',
      });
    }
    return RailsRoutes.dashboard_clients_path({ format: 'json' });
  }

  clientRoute(clientSlug, opts = {}) {
    const { orgSlug } = this.props;
    if (orgSlug) {
      return RailsRoutes.dashboard_organization_client_path(orgSlug, clientSlug, opts);
    }
    return RailsRoutes.dashboard_client_path(clientSlug, opts);
  }

  columns() {
    const accountID = !isRestrictedViewUser() && (
      <th className="js-datatable-col-account-id" key="datatable-account-id">
        Account ID
      </th>
    );
    return [
      <th className="js-datatable-col-client" key="datatable-client">
        Client
      </th>,
      accountID,
    ]
      .concat(this.pooledColumns())
      .concat([
        <th className="table-cell-right js-datatable-col-total-assets" key="datatable-total-assets">
          Total Assets
        </th>,
      ])
      .concat(this.pendingColumns());
  }

  pooledColumns() {
    if (!this.props.isPooled) {
      return [];
    }
    return [
      <th className="js-datatable-col-portfolio" key="datatable-portfolio">
        Portfolio
      </th>,
      <th className="table-cell-right js-datatable-col-units" key="datatable-units">
        Units
      </th>,
      <th className="table-cell-right js-datatable-col-unit-value" key="datatable-unit-value">
        Unit Value
      </th>,
    ];
  }

  pendingColumns() {
    if (!this.isPendingView()) {
      return [];
    }
    return [
      <th className="js-datatable-col-days-pending" key="datatable-days-pending">
        Days Pending
      </th>,
    ];
  }

  columnDefs() {
    return [
      {
        targets: ['js-datatable-col-client'],
        data: 'display_name',
        render: (data, type, record) => {
          let badge;
          if (!this.props.isPooled && record.event_notification_count > 0) {
            const badgeText = `${record.event_notification_count} new ${pluralize(
              'deposit',
              record.event_notification_count,
            )}<i class="arrow-icon fas fa-long-arrow-alt-right"></i>`;
            const depositsUrl = this.clientRoute(record.slug, {
              anchor: 'client-deposits-tab',
            });
            badge = `<a class="notification-chip" href=${depositsUrl}>${badgeText}</a>`;
          } else {
            badge = '';
          }
          return `
                <a href="${this.clientRoute(record.slug)}">${data}</a>
                ${badge}
            `;
        },
      },
      {
        targets: ['js-datatable-col-account-id'],
        data: 'account_id',
      },
      {
        targets: ['js-datatable-col-portfolio'],
        data: 'holding.portfolio_name',
        render: this.formatPortfolioOrTrustName,
      },
      {
        targets: ['js-datatable-col-units'],
        data: 'holding.units',
        render: this.formatBigDecimal,
        className: 'table-cell-right',
      },
      {
        targets: ['js-datatable-col-unit-value'],
        data: 'holding.current_unit_value',
        className: 'table-cell-right',
      },
      {
        targets: ['js-datatable-col-total-assets'],
        orderable: false,
        data: 'holding.total_unit_value',
        render: this.formatTotalAssets,
        className: 'table-cell-right',
      },
      {
        targets: ['js-datatable-col-days-pending'],
        data: 'created_at',
      },
    ];
  }

  formatBigDecimal(data) {
    if (data) {
      return bigDecimalWithCommas(data);
    }
  }

  formatTotalAssets(data, type, record) {
    const { trust } = record;

    if (trust.is_pooled) {
      return data;
    }
    return formattedTotalFromBalances(trust.balances[0]);
  }

  formatPortfolioOrTrustName(data, type, record) {
    return data || record.trust.name;
  }

  serverParams() {
    const { statusFilter } = this.props;
    return { status: statusFilter && statusFilter.toLowerCase() };
  }

  slideDownStateKey(statusFilter) {
    return `${statusFilter.toLowerCase()}SlideDownOpen`;
  }

  slideDownData(statusFilter) {
    const result = {};

    if (this.state[this.slideDownStateKey(statusFilter)]) {
      result.closeClass = 'font-size--default btn-link angle-down angle-down--open';
      result.hideClass = '';
      result.toggleText = 'Close';
    } else {
      result.closeClass = 'font-size--default btn-link angle-down';
      result.hideClass = 'hidden';
      result.toggleText = 'View';
    }

    return result;
  }

  toggleSlideDown(e) {
    e.preventDefault();

    const stateKey = this.slideDownStateKey(this.props.statusFilter);
    this.setState({
      [stateKey]: !this.state[stateKey],
    });
  }

  handleRender(table) {
    const { statusFilter } = this.props;
    if (statusFilter) {
      const slideDownData = this.slideDownData(statusFilter);

      return (
        <div className="js-datatable-container">
          <div className="sub_section_header__subhead">
            {statusFilter} (<span className="js-datatable-display-count" />)
            <div className="pull-right">
              <TrueLinkLink className={slideDownData.closeClass} onClick={this.toggleSlideDown}>
                {slideDownData.toggleText}
              </TrueLinkLink>
            </div>
          </div>

          <div className={slideDownData.hideClass}>{table}</div>
        </div>
      );
    }
    return <div>{table}</div>;
  }

  render() {
    return (
      <Datatable
        afterRefresh={this.afterRefresh}
        columnDefs={this.columnDefs()}
        columns={this.columns()}
        handleRender={this.handleRender}
        needsRefresh={this.state.needsRefresh}
        pageLength={25}
        route={this.indexRoute()}
        serverParams={this.serverParams()}
      />
    );
  }
}

DashboardPaginatedClientList.propTypes = {
  orgSlug: PropTypes.string,
  statusFilter: PropTypes.string,
  isPooled: PropTypes.bool,
};
