import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ReportLostOrStolenModal from './ReportLostOrStolenModal';
import { CARD_STATUSES } from 'react/member/utils/CardStatus';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import addressShape from 'react/shared/shapes/address_shape';

const useStyles = makeStyles({
  reissueButton: {
    alignItems: 'center',
    display: 'flex',
    '& svg': {
      marginRight: '10px',
    },
  },
});

export default function OrderReplacementCardButton({
  accountSlug,
  allowAutomatedPayment,
  adminMobile,
  cardBreached = false,
  cardholderAddress = '',
  cardholderCountryOfCitizenship,
  cardholderName,
  cardholderMobile,
  disableSelectingLastKnownAddress = false,
  onCardReissueSuccess,
  organizationAddress = '',
  otherShippingDestinationDefault,
  paymentMethods,
  shippingAddress = '',
  shippingFee,
  shippingSettings,
  status,
}) {
  const classes = useStyles();

  const [isModalOpen, setIsModalOpen] = useState(
    window.location.hash === '#report_lost_or_stolen_modal',
  );

  const openModal = (ev) => {
    ev.preventDefault();
    setIsModalOpen(true);
  };

  const handleCloseModal = () => setIsModalOpen(false);

  const primary = cardBreached && ![CARD_STATUSES.OPEN_CARD_STATUS].includes(status);
  return (
    <div>
      <TrueLinkButton
        className={classes.reissueButton}
        onClick={openModal}
        variant={primary ? 'primary' : 'primaryOutline'}
      >
        <i className="fa fa-sync" />
        Order replacement
      </TrueLinkButton>
      {isModalOpen && (
        <ReportLostOrStolenModal
          accountSlug={accountSlug}
          adminMobile={adminMobile}
          allowAutomatedPayment={allowAutomatedPayment}
          cardholderAddress={cardholderAddress}
          cardholderCountryOfCitizenship={cardholderCountryOfCitizenship}
          cardholderMobile={cardholderMobile}
          cardholderName={cardholderName}
          disableSelectingLastKnownAddress={disableSelectingLastKnownAddress}
          onClose={handleCloseModal}
          onSuccess={onCardReissueSuccess}
          organizationAddress={organizationAddress}
          otherShippingDestinationDefault={otherShippingDestinationDefault}
          paymentMethods={paymentMethods}
          shippingAddress={shippingAddress}
          shippingFee={shippingFee}
          shippingSettings={shippingSettings}
        />
      )}
    </div>
  );
}

OrderReplacementCardButton.propTypes = {
  accountSlug: PropTypes.string.isRequired,
  allowAutomatedPayment: PropTypes.bool,
  cardBreached: PropTypes.bool,
  cardholderAddress: PropTypes.string,
  cardholderMobile: PropTypes.string,
  adminMobile: PropTypes.string,
  cardholderName: PropTypes.string,
  cardholderCountryOfCitizenship: PropTypes.string,
  disableSelectingLastKnownAddress: PropTypes.bool,
  onCardReissueSuccess: PropTypes.func.isRequired,
  organizationAddress: PropTypes.string,
  otherShippingDestinationDefault: addressShape,
  paymentMethods: PropTypes.object,
  shippingAddress: PropTypes.string,
  shippingFee: PropTypes.string,
  shippingSettings: PropTypes.object,
  status: PropTypes.oneOf(Object.values(CARD_STATUSES)).isRequired,
};
