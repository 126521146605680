$(function () {
  $(document).on('click', '.edit_link_account_activation_editor', function (e) {
    modal = getModal(this);
    modal.on('show', function () {
      accountActivationId = $(this).data('account-activation-id');
      accountId = $(this).data('account-id');
      return Truelink.flashClear();
    });
    modal.modal('show');
  });

  $('.account_activation_row form').on('submit', function (e) {
    $(this).find('.btn-release').prop('disabled', true);
  });

  $('.account_activation_editor form').on('ajax:success', function (evt) {
    var accountActivationId, organizationId, shippingDestination;
    shippingDestination = $(
      '.account_activation_editor .select_shipping_destination, .account_activation_editor select',
    );
    $(this).closest('.account_activation_editor').trigger('closeModal');
    accountActivationId = $(this)
      .closest('.account_activation_editor')
      .data('account-activation-id');
    organizationId = $(this).closest('.account_activation_organization').data('organization-id');
    return $.get('/admin/organizations/' + organizationId + '/address', function (html) {
      return $('[data-organization-id="' + organizationId + '"] span.address').replaceWith(html);
    });
  });

  $(document).on('change', '#all_activations', function (evt) {
    return $('[name="account_activations[]"]').prop('checked', $(this).prop('checked'));
  });

  $(document).on('click', '.account_activation_editor .cancel', function (evt) {
    evt.preventDefault();
    modal = getModal(this);
    modal.modal('hide');
  });

  $(document).on('click', '.account_activation_editor .save_account_activation', function (evt) {
    modal = getModal(this);
    modal.modal('hide');
  });

  $(document).on('ajax:success', '.default_account_fee_settings_update_by_org', function (ev) {
    Truelink.flash('success', 'Default Account Fee Settings updated!');
  });

  $(document).on('click', '.btn-release-all', function (evt) {
    var failedReleaseNames = [];

    $('[name="account_activations[]"]:checked').each(function (index, check_box) {
      var button = $('.btn-release', $(check_box).closest('.account_activation_row'));

      if (button.is(':disabled')) {
        var cardholderName = $('.cardholder-name', button.closest('.account_activation_row'))
          .text()
          .trim();
        failedReleaseNames.push(cardholderName);
      } else {
        button.click();
      }
    });

    if (failedReleaseNames.length) {
      $('#failed-release-names').html(
        $('<ul>', { style: 'list-style-type:none;' }).append(
          failedReleaseNames.map(function (name) {
            return $('<li>').text(name);
          }),
        ),
      );
      modal = $('#release-failed-modal');
      modal.modal('show');
      $('.modal-cancel-button').on('click', function (event) {
        event.preventDefault();
        modal.modal('hide');
      });
    }
  });

  function getModal(item) {
    accountActivationId = $(item).data('account-activation-id');
    modal = $('#editor_account_activation_' + accountActivationId);
    return modal;
  }
});
