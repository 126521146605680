export default class ListItemStore {
  constructor() {
    this.itemList = {};
  }

  getFullItemList() {
    return this.itemList;
  }

  getItemList(itemHolderId) {
    return this.itemList[itemHolderId] && this.itemList[itemHolderId]['data'];
  }

  addItems(itemHolderId, items) {
    this.itemList[itemHolderId] = this.itemList[itemHolderId] || {};
    this.itemList[itemHolderId]['data'] = this.itemList[itemHolderId]['data'] || [];

    this.itemList[itemHolderId]['data'] = this.itemList[itemHolderId]['data'].concat(items);
    this.itemList[itemHolderId]['fetching'] = false;

    return this.itemList[itemHolderId]['data'];
  }

  updateItem(itemHolderId, updatedItem) {
    if (!this.itemList[itemHolderId] || !this.itemList[itemHolderId]['data']) return null;

    this.itemList[itemHolderId] = this.itemList[itemHolderId] || {};
    this.itemList[itemHolderId]['data'] = this.itemList[itemHolderId]['data'] || [];

    this.itemList[itemHolderId]['data'].forEach((item, i) => {
      if (item.id === updatedItem.id) this.itemList[itemHolderId]['data'][i] = updatedItem;
    });
    this.itemList[itemHolderId]['fetching'] = false;

    return this.itemList[itemHolderId]['data'];
  }

  removeItem(itemHolderId, removedItem) {
    if (!this.itemList[itemHolderId] || !this.itemList[itemHolderId]['data']) return null;

    this.itemList[itemHolderId]['data'] = this.itemList[itemHolderId]['data'].filter(
      (item) => item.id != removedItem.id,
    );

    return this.itemList[itemHolderId]['data'];
  }
}
