import PropTypes from 'prop-types';
import React from 'react';
import DashboardActions from 'react/member/actions/dashboard_actions';
import TransferIconCell from 'react/member/card/components/funding_tab/transfers_table/TransferIconCell';
import TransfersTable from 'react/member/card/components/funding_tab/transfers_table/TransfersTable';
import TransfersTableHeader from 'react/member/card/components/funding_tab/transfers_table/TransfersTableHeader';
import AutoTransferEditCell from 'react/member/card/components/funding_tab/transfers_table/action_cells/AutoTransferEditCell';
import { dashboardAccountShape } from 'react/member/card/shapes/AccountShape';
import DashboardAutoTransferStore from 'react/member/stores/DashboardAutoTransferStore';
import DashboardBankAccountStore from 'react/member/stores/DashboardBankAccountStore';
import bindAll from 'react/shared/utils/bind_all';

export default class ScheduledAutoTransfersSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      autoTransfers: [],
    };
    bindAll(this);
  }

  componentDidMount() {
    DashboardAutoTransferStore.on('scheduled_auto_transfers.fetch', this.onChangeAutoTransfers);
    DashboardBankAccountStore.on('bankAccount.delete', this.refreshAutoTransfers);
    this.refreshAutoTransfers();
  }

  componentWillUnmount() {
    DashboardAutoTransferStore.off('scheduled_auto_transfers.fetch', this.onChangeAutoTransfers);
    DashboardBankAccountStore.off('bankAccount.delete', this.refreshAutoTransfers);
  }

  refreshAutoTransfers() {
    DashboardActions.fetchScheduledAutoTransfers();
  }

  onChangeAutoTransfers(autoTransfers) {
    this.setState({ autoTransfers });
  }

  makeEditButton(autoTransfer, isViewOnly) {
    const { cardholderName, customerInstantFunding, fundingType } = this.props.account.meta;
    return (
      <AutoTransferEditCell
        autoTransfer={autoTransfer}
        cardStatus={this.props.cardStatus}
        cardholderName={cardholderName}
        emergencyFundingEnabled={customerInstantFunding}
        fundingType={fundingType}
        isViewOnly={isViewOnly}
      />
    );
  }

  render() {
    const { account, isViewOnly } = this.props;
    const { autoTransfers } = this.state;

    const title = (
      <div>
        <TransferIconCell className={'fa-sync large-icon'} color={'grey'} tooltipOn={false} />
        Recurring transfers
      </div>
    );

    return (
      <div id="upcoming_auto_transfers_table">
        <TransfersTableHeader account={account} enableFundsAvailableModal title={title} />
        <TransfersTable
          id="scheduled_auto_transfers_table"
          isViewOnly={!!isViewOnly}
          items={autoTransfers}
          renderActionCell={this.makeEditButton}
          zeroStateText="You have no scheduled recurring transfers"
        />
      </div>
    );
  }
}

ScheduledAutoTransfersSection.propTypes = {
  account: dashboardAccountShape.isRequired,
  cardStatus: PropTypes.string,
  isViewOnly: PropTypes.bool.isRequired,
};
