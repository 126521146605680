import axios from 'axios';
import { AXIOS_XHR_CONFIG, makeCancellableAxiosXhrConfig } from 'react/shared/utils/Axios';

const requests = {
  getAccount(accountSlug) {
    return axios.get(
      RailsRoutes.dashboard_account_path(accountSlug, { format: 'json' }),
      AXIOS_XHR_CONFIG,
    );
  },
  getBomorrow(date, cancelToken) {
    const url = RailsRoutes.bomorrow_dashboard_transfers_path({ format: 'json' });
    const config = cancelToken ? makeCancellableAxiosXhrConfig(cancelToken) : AXIOS_XHR_CONFIG;

    return axios.get(url, { params: { date }, ...config });
  },
  getHoliday(holidayId) {
    return axios.get(
      RailsRoutes.dashboard_holiday_path(holidayId, { format: 'json' }),
      AXIOS_XHR_CONFIG,
    );
  },
};

const autoTransferRequests = {
  getAutoTransfers() {
    return axios.get(
      RailsRoutes.dashboard_auto_transfers_path({ format: 'json' }),
      AXIOS_XHR_CONFIG,
    );
  },
  createAutoTransfer(data) {
    return axios.post(
      RailsRoutes.dashboard_auto_transfers_path({ format: 'json' }),
      data,
      AXIOS_XHR_CONFIG,
    );
  },
  updateAutoTransfer(autoTransferId, data) {
    return axios.put(
      RailsRoutes.dashboard_auto_transfer_path(autoTransferId, { format: 'json' }),
      data,
      AXIOS_XHR_CONFIG,
    );
  },
  deleteAutoTransfer(autoTransferId) {
    return axios.delete(
      RailsRoutes.dashboard_auto_transfer_path(autoTransferId, { format: 'json' }),
      AXIOS_XHR_CONFIG,
    );
  },
};

const transferRequests = {
  getTransfers(type = null) {
    return axios.get(
      RailsRoutes.dashboard_transfers_path(type ? { type, format: 'json' } : { format: 'json' }),
      AXIOS_XHR_CONFIG,
    );
  },
  getSoonestTransferDate(cancelToken) {
    const url = RailsRoutes.soonest_transfer_date_dashboard_transfers_path({ format: 'json' });
    const config = cancelToken ? makeCancellableAxiosXhrConfig(cancelToken) : AXIOS_XHR_CONFIG;

    return axios.get(url, config);
  },
  instantFundTransfer(transferId) {
    return axios.post(
      RailsRoutes.fund_now_dashboard_transfer_path(transferId, { format: 'json' }),
      AXIOS_XHR_CONFIG,
    );
  },
  toggleTransferCancellation(transferId) {
    return axios.patch(
      RailsRoutes.toggle_cancellation_dashboard_transfer_path(transferId, { format: 'json' }),
      AXIOS_XHR_CONFIG,
    );
  },
  patchApplyTransferGuidance(guidance) {
    const url = RailsRoutes.apply_guidance_dashboard_holiday_impacted_transfers_path({
      format: 'json',
    });

    return axios.patch(url, { guidance }, AXIOS_XHR_CONFIG);
  },
};

const agreementsRequests = {
  getOneTimeTransferAgreement(cancelToken) {
    const url = RailsRoutes.one_time_transfer_agreement_dashboard_transfers_path({
      format: 'html',
    });
    return axios.get(url, makeCancellableAxiosXhrConfig(cancelToken)).then((r) => r.data);
  },
  getAutoTransferAgreement(cancelToken) {
    const url = RailsRoutes.auto_transfer_agreement_dashboard_auto_transfers_path({
      format: 'html',
    });
    return axios.get(url, makeCancellableAxiosXhrConfig(cancelToken)).then((r) => r.data);
  },
};

export default {
  ...requests,
  ...autoTransferRequests,
  ...transferRequests,
  ...agreementsRequests,
};
