import { ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import NoPoBoxDisclaimer from './NoPoBoxDisclaimer';
import RadioGroup from './fields/RadioGroup';
import AddressFields from 'react/member/components/signups/signup_form/form_partials/fields/AddressFields';
import FieldLabel from 'react/member/components/signups/signup_form/form_partials/labels/field_label';
import fieldLabelShape from 'react/shared/shapes/field_label_shape';

function ShippingDestinationRadios({
  pro,
  shippingDestination,
  officeName,
  officeAddress,
  cardholderAddress,
  fieldLabelProps,
  subsectionClass,
  rowClass,
  colClass,
  radioGroupClass,
  radioLabelClass,
  viewport,
}) {
  const addressReset = { street1: '', street2: '', city: '', state: '', zip: '' };

  const otherAddressReset = (_newValue, _fieldName, form) => {
    if (form.values.other_address) {
      form.setFieldValue('other_address', addressReset);
    }
  };

  const addressSummary = (address) => (
    <div className="card--form__radio__label--sub-text">
      <div>{address.street1}</div>
      {address.street2 && <div>{address.street2}</div>}
      <span>
        {address.city} {address.state} {address.zip}
      </span>
    </div>
  );

  const homeLabel = `Cardholder's home address${viewport === 'mobile' ? ':' : ''}`;
  const officeLabel = viewport === 'mobile' ? 'My office address:' : 'Your office address';
  const officeHomeLabel = viewport === 'mobile' ? 'My home address:' : 'Your home address';

  let shippingDestinationOptions = [
    {
      id: 'shipping_destination_cardholder_home',
      value: 'cardholder_home',
      labelContent: homeLabel,
      onChangeCallback: pro ? otherAddressReset : null,
      afterLabel: (
        <div className="show-on-mobile" id="ch-address-summary">
          {addressSummary(cardholderAddress)}
        </div>
      ),
    },
    {
      id: 'shipping_destination_office',
      value: 'office',
      labelContent: pro ? officeLabel : officeHomeLabel,
      onChangeCallback: pro ? otherAddressReset : null,
      afterLabel: (
        <div className="show-on-mobile" id="office-address-summary">
          {addressSummary(officeAddress)}
        </div>
      ),
    },
  ];

  if (pro) {
    shippingDestinationOptions = shippingDestinationOptions.concat([
      { id: 'shipping_destination_other', value: 'other', labelContent: 'Other' },
    ]);
  }

  return (
    <div>
      <div className={rowClass}>
        <div className={colClass}>
          <FieldLabel {...fieldLabelProps} />
          <RadioGroup
            id="shipping_destination_radios"
            name="shipping_destination"
            options={shippingDestinationOptions}
            radioGroupClass={radioGroupClass}
            radioLabelClasses={{ radioLabelClass }}
          />
          <ErrorMessage className="error" component="label" name="shipping_destination" />
        </div>
      </div>
      {shippingDestination === 'office' && (
        <div className={`${subsectionClass} hide-on-mobile`} id="office-address-summary">
          <div className={rowClass}>
            <div className={colClass}>
              {officeName && <div>{officeName}</div>}
              <div>{officeAddress.street1}</div>
              {officeAddress.street2 && <div>{officeAddress.street2}</div>}
              <span>
                {officeAddress.city} {officeAddress.state} {officeAddress.zip}
              </span>
            </div>
          </div>
        </div>
      )}
      {pro && shippingDestination === 'other' && (
        <div>
          <br className="show-on-mobile" />
          <AddressFields
            entity="other_address"
            street1AfterLabel={<NoPoBoxDisclaimer addressType="other" />}
            street1LabelPrefix="New"
            viewport={viewport}
          />
          <br className="show-on-mobile" />
        </div>
      )}
    </div>
  );
}

ShippingDestinationRadios.propTypes = {
  pro: PropTypes.bool.isRequired,
  shippingDestination: PropTypes.oneOf(['cardholder_home', 'office', 'other', '']),
  officeName: PropTypes.string,
  officeAddress: PropTypes.object,
  cardholderAddress: PropTypes.object,
  fieldLabelProps: fieldLabelShape,
  subsectionClass: PropTypes.string,
  rowClass: PropTypes.string,
  colClass: PropTypes.string,
  radioGroupClass: PropTypes.string,
  radioLabelClass: PropTypes.string,
  viewport: PropTypes.string,
};

ShippingDestinationRadios.defaultProps = {
  shippingDestination: '',
  officeName: '',
  officeAddress: {},
  cardholderAddress: {},
  fieldLabelProps: {},
  subsectionClass: 'card--form__sub-section',
  rowClass: 'card--form__row card--form__row--no-pad-mobile',
  colClass: 'card--form__col card--form__col--no-pad-mobile',
  radioGroupClass: 'card--form__radio card--form__radio--100-tablet',
  radioLabelClass: 'card--form__radio__label card--form__radio__label--small-text',
  viewport: null,
};

export default ShippingDestinationRadios;
