import PropTypes from 'prop-types';
import React, { createContext, useState } from 'react';

export const DashboardCardContext = createContext();

// eslint-disable-next-line react/prop-types
function DashboardCardProvider({ children, props }) {
  const [accountId, setAccountId] = useState(props.accountId);
  const [address, setAddress] = useState(props.address);

  const [cardholder, setCardholder] = useState({
    ...props.cardholder,
    dob: '',
    card_last_4: '',
    ssn: '',
  });

  const onChangeCardholderAttr = (ev) => {
    const attr = $(ev.target).attr('id');
    const value = ev.target.value;

    setCardholder((prevState) => ({
      ...prevState,
      [attr]: value,
    }));
  };

  const onChangeAddressAttr = (ev) => {
    const attr = $(ev.target).attr('id');
    const value = ev.target.value;
    setAddress((prevState) => ({
      ...prevState,
      [attr]: value,
    }));
  };

  return (
    <DashboardCardContext.Provider
      value={{
        accountId,
        address,
        cardholder,
        msaLink: props.msaLink,
        programName: props.programName,
        supportADTollfree: props.supportADTollfree,
        supportCHTollfree: props.supportCHTollfree,
        supportEmail: props.supportEmail,
        onChangeAddressAttr,
        onChangeCardholderAttr,
        setCardholder,
        setAccountId,
      }}
    >
      {children}
    </DashboardCardContext.Provider>
  );
}
export default DashboardCardProvider;

DashboardCardProvider.propTypes = {
  accountId: PropTypes.string,
  address: PropTypes.object,
  cardholder: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    mobile: PropTypes.string,
    nickname: PropTypes.string,
    dob: PropTypes.string,
  }),
  msaLink: PropTypes.string,
  programName: PropTypes.string,
  supportADTollfree: PropTypes.string,
  supportCHTollfree: PropTypes.string,
  supportEmail: PropTypes.string,
};
