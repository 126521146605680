import PropTypes from 'prop-types';
import React from 'react';
import ToggleSectionHeader from 'react/shared/components/ToggleSectionHeader';
import bindAll from 'react/shared/utils/bind_all';
import tlFieldTransformers from 'react/shared/utils/tl_field_transformers';

export default class DashboardTrustBeneficiaryProfileBereavement extends React.Component {
  constructor(props) {
    super(props);

    bindAll(this);
  }

  renderContent() {
    if (!this.props.expanded) {
      return null;
    }

    const { formatMoney, snakeCaseToTitle } = tlFieldTransformers;
    const { trustBeneficiary } = this.props;

    return (
      <div className="toggle-section-content">
        <div className="flex">
          <div className="info-column">
            <div className="info-item">
              <strong>Reason for Closure: </strong>
              <span className="padding-10l">
                {snakeCaseToTitle(trustBeneficiary.trustClosingReason)}
              </span>
            </div>
            <div className="info-item">
              <strong>Date of Death: </strong>
              <span className="padding-10l">{trustBeneficiary.dateOfDeath}</span>
            </div>
          </div>
          <div className="info-column">
            <div className="info-item">
              <strong>Medicaid Claim Amount: </strong>
              <span className="padding-10l">
                {formatMoney(trustBeneficiary.medicaidClaimAmount)}
              </span>
            </div>
            <div className="info-item">
              <strong>Trust Balance at Death: </strong>
              <span className="padding-10l">
                {formatMoney(trustBeneficiary.trustBalanceAtDeath)}
              </span>
            </div>
          </div>
        </div>
        <div className="subsection-text-block">
          <div className="subsection-text-header">Notes</div>
          <div className="subsection-text-body">
            <span>{trustBeneficiary.trustClosingNotes}</span>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="toggle-section">
        <ToggleSectionHeader
          expanded={this.props.expanded}
          title="Account Closing Details"
          toggleExpanded={this.props.toggleExpanded}
        />
        {this.renderContent()}
      </div>
    );
  }
}

DashboardTrustBeneficiaryProfileBereavement.propTypes = {
  trustBeneficiary: PropTypes.object,
  expanded: PropTypes.bool.isRequired,
  toggleExpanded: PropTypes.func.isRequired,
};
