import PropTypes from 'prop-types';
import React from 'react';
import OldDashboardCard from './cards/OldDashboardCard';
import HoldingIndex from './holdings/HoldingIndex';
import UniqueAssetsTable from './unique_assets/UniqueAssetsTable';
import CategorizedBudget from 'react/member/components/dashboard/clients/categorized_budget/CategorizedBudget';
import DashboardDepositsTabContent from 'react/member/components/dashboard/deposits/DashboardDepositsTabContent';
import DisbursementTabsWrapper from 'react/member/components/dashboard/disbursements/tab_content/DisbursementTabsWrapper';
import DashboardHoldingEventIndex from 'react/member/components/dashboard/holding_events/DashboardHoldingEventIndex';
import DashboardPaginatedPayeeList from 'react/member/components/dashboard/payees/DashboardPaginatedPayeeList';
import { originTypes } from 'react/member/components/dashboard/reports/Constants';
import Reports from 'react/member/components/dashboard/reports/Reports';
import DashboardTrustBeneficiaryProfile from 'react/member/components/dashboard/trust_beneficiaries/profile/DashboardTrustBeneficiaryProfile';
import userRoleShape from 'react/member/shapes/UserRoleShape';
import TabContent from 'react/shared/components/TabContent';
import TabNavigation from 'react/shared/components/TabNavigation';
import DashboardClientEffectiveBalance from 'react/shared/components/dashboard/clients/DashboardClientEffectiveBalance';
import { isRestrictedViewUser } from 'react/shared/utils/Authorization';
import bindAll from 'react/shared/utils/bind_all';

export default class DashboardPooledClient extends React.Component {
  constructor(props) {
    super(props);

    bindAll(this);
  }

  getChildContext() {
    return { client: this.props.client };
  }

  tabDefs() {
    return {
      trustAssets: {
        id: 'client-trust-assets-tab',
        title: 'Account',
        type: 'choseTrustAssets',
      },
      disbursements: {
        id: 'client-disbursements-tab',
        title: 'Disbursements',
        type: 'choseDisbursements',
      },
      deposits: {
        id: 'client-deposits-tab',
        title: 'Deposits',
        type: 'choseDeposits',
      },
      payees: { id: 'client-payees-tab', title: 'Payees', type: 'chosePayees' },
      reports: {
        id: 'client-reports-tab',
        title: 'Reports',
        type: 'choseReports',
      },
      card: {
        id: 'client-card-tab',
        title: 'True Link Card',
        type: 'choseCard',
      },
      budgetItems: {
        id: 'client-budget-items-tab',
        title: 'Budget',
        type: 'choseBudgetItems',
      },
      notes: { id: 'client-notes-tab', title: 'Profile', type: 'choseNotes' },
    };
  }

  tabList() {
    const { trustAssets, disbursements, deposits, payees, reports, card, budgetItems, notes } =
      this.tabDefs();

    if (isRestrictedViewUser()) {
      return [trustAssets, disbursements, deposits, payees, reports, budgetItems, notes];
    }
    return [trustAssets, disbursements, deposits, payees, reports, card, budgetItems, notes];
  }

  trustAssetsTab() {
    return (
      <TabContent id="client-trust-assets-tab" key="client-trust-assets-tab">
        <DashboardClientEffectiveBalance
          client={this.props.client}
          depositWorkflow={this.props.depositWorkflow}
        />
        <HoldingIndex client={this.props.client} />
        <UniqueAssetsTable
          client={this.props.client}
          showAdvanceOption={this.props.showAdvanceOption}
          userRoles={this.props.userRoles}
        />
        <DashboardHoldingEventIndex client={this.props.client} />
      </TabContent>
    );
  }

  disbursementsTab() {
    return (
      <TabContent id="client-disbursements-tab" key="client-disbursements-tab">
        <DisbursementTabsWrapper
          disbursementsFor={'Trust Beneficiary'}
          displayedPaymentMethods={this.props.client.trust.displayed_payment_types}
          editExternalCheckDisposition={this.props.editExternalCheckDisposition}
          initialClient={this.props.client}
          organizationSlug={this.props.client.organization.slug}
          renderOnHoldDisbursements={this.props.renderOnHoldDisbursements}
          showCashBalance={this.props.showCashBalance}
          showSendDisbursementBackToPending={this.props.showSendDisbursementBackToPending}
          threeMonthInfo={this.props.threeMonthInfo}
          userRoles={this.props.userRoles}
        />
      </TabContent>
    );
  }

  depositsTab() {
    return (
      <TabContent id="client-deposits-tab" key="client-deposits-tab">
        <DashboardDepositsTabContent client={this.props.client} userRoles={this.props.userRoles} />
      </TabContent>
    );
  }

  payeesTab() {
    return (
      <TabContent id="client-payees-tab" key="client-payees-tab">
        <DashboardPaginatedPayeeList client={this.props.client} userRoles={this.props.userRoles} />
      </TabContent>
    );
  }

  reportsTab() {
    const { person, slug, trust } = this.props.client;
    const origin = {
      id: slug,
      name: person.full_name_w_nick,
      type: originTypes.bene,
      customStatementDates: this.props.availableCustomStatementDates,
      latestTrustStatementEndDate: trust.latest_statement_end_date,
      quarterlyStatementDates: this.props.quarterlyStatementDates,
    };
    return (
      <TabContent id="client-reports-tab" key="client-reports-tab">
        <Reports origin={origin} />
      </TabContent>
    );
  }

  trueLinkCardTab() {
    return (
      <TabContent id="client-card-tab" key="client-card-tab">
        <OldDashboardCard client={this.props.client} userRoles={this.props.userRoles} />
      </TabContent>
    );
  }

  budgetItemsTab() {
    return (
      <TabContent id="client-budget-items-tab" key="client-budget-items-tab">
        <CategorizedBudget trustBeneficiary={this.props.client} />
      </TabContent>
    );
  }

  beneficiaryProfileTab() {
    return (
      <TabContent id="client-notes-tab" key="client-notes-tab">
        <DashboardTrustBeneficiaryProfile
          showConnectionsCompanyName={this.props.showConnectionsCompanyName}
          trustBeneficiarySlug={this.props.client.slug}
          userRoles={this.props.userRoles}
        />
      </TabContent>
    );
  }

  tabContentList() {
    if (isRestrictedViewUser()) {
      return [
        this.trustAssetsTab(),
        this.disbursementsTab(),
        this.depositsTab(),
        this.payeesTab(),
        this.reportsTab(),
        this.budgetItemsTab(),
        this.beneficiaryProfileTab(),
      ];
    }
    return [
      this.trustAssetsTab(),
      this.disbursementsTab(),
      this.depositsTab(),
      this.payeesTab(),
      this.reportsTab(),
      this.trueLinkCardTab(),
      this.budgetItemsTab(),
      this.beneficiaryProfileTab(),
    ];
  }

  showAccountClosingBanner() {
    return this.props.client && this.props.client.show_closing_banner;
  }

  accountClosingBanner() {
    if (this.showAccountClosingBanner()) {
      return (
        <div className="alert alert--banner-full-width alert-warning">
          Account Closing: Creating new disbursements may slow down the transfer of this beneficiary
          to the cash portfolio
        </div>
      );
    }
  }

  showCardAttachedBanner() {
    return (
      this.props.client && this.props.client.status === 'Closing' && this.props.client.cards.length
    );
  }

  cardAttachedBanner() {
    if (this.showCardAttachedBanner()) {
      return (
        <div className="alert alert-info alert--banner-full-width alert-warning">
          True Link Card attached to beneficiary. Please close or update funding source.
        </div>
      );
    }
  }

  render() {
    return (
      <div>
        {this.accountClosingBanner()}
        {this.cardAttachedBanner()}
        <div className="widescreen-inner-container">
          <TabNavigation
            classOptions={'nav nav-tabs wm-dash-tabs pooled'}
            showBackground
            tabs={this.tabList()}
          >
            {this.tabContentList()}
          </TabNavigation>
        </div>
      </div>
    );
  }
}

DashboardPooledClient.childContextTypes = {
  client: PropTypes.object,
};

DashboardPooledClient.propTypes = {
  availableCustomStatementDates: PropTypes.shape({
    minDate: PropTypes.string,
    maxDate: PropTypes.string,
    startDate: PropTypes.string,
  }).isRequired,
  client: PropTypes.object.isRequired,
  depositWorkflow: PropTypes.bool,
  editExternalCheckDisposition: PropTypes.bool,
  quarterlyStatementDates: PropTypes.array.isRequired,
  showAdvanceOption: PropTypes.bool,
  threeMonthInfo: PropTypes.object,
  renderOnHoldDisbursements: PropTypes.bool,
  showConnectionsCompanyName: PropTypes.bool,
  userRoles: userRoleShape.isRequired,
  showSendDisbursementBackToPending: PropTypes.bool,
  showCashBalance: PropTypes.bool,
};
