import React from 'react';
import UniqueAssets from './UniqueAssetsCard';

export default {
  title: 'Invest/Dashboard/UniqueAssets',
  component: UniqueAssets,
  argTypes: {
    amount: {
      control: 'number',
      table: {
        category: 'Contents',
      },
    },
  },
};

function Template(args) {
  return <UniqueAssets {...args} />;
}

export const Standard = Template.bind({});
Standard.args = {
  amount: 8422.44,
};

export const WithoutAmount = Template.bind({});
WithoutAmount.args = {
  amount: 0,
};
