import axios from 'axios';
import Actions from 'react/shared/actions/actions';

const HolidayCalendarStore = flux.createStore({
  actions: [Actions.fetchBankHolidays],

  fetchBankHolidays() {
    if (!this.bankHolidays && !this.fetchingBankHolidays) {
      this.fetchingBankHolidays = true;
      axios
        .get(RailsRoutes.api_calendar_path({ format: 'json' }))
        .then((response) => {
          this.bankHolidays = response.data.bankHolidays;
          this.earliestDeliveryDate = response.data.earliestDeliveryDate;
          this.emit('bankHolidays.fetch');
        })
        .finally(() => {
          this.fetchingBankHolidays = false;
        });
    } else if (this.bankHolidays) {
      this.emit('bankHolidays.fetch');
    }
  },

  exports: {
    getBankHolidays() {
      return this.bankHolidays;
    },
    getEarliestDeliveryDate() {
      return this.earliestDeliveryDate;
    },
  },
});

export default HolidayCalendarStore;
