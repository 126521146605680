import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import TrueLinkIcon from 'react/shared/components/true_link/main/TrueLinkIcon';

const useStyles = makeStyles({
  iconButton: {
    border: 0,
  },
});

export default function AdditionalEmail({ email, onRemoveAdditionalEmail }) {
  const classes = useStyles();
  const handleRemoveAdditionalEmail = () => {
    onRemoveAdditionalEmail(email);
  };

  return (
    <li className="alerts-tab__edit-panel--inline-section">
      {email}&nbsp;&nbsp;
      <TrueLinkButton
        aria-label="Remove additional email"
        className={classes.iconButton}
        onClick={handleRemoveAdditionalEmail}
        tabIndex="0"
        variant="none"
      >
        <TrueLinkIcon icon="times" />
      </TrueLinkButton>
    </li>
  );
}

AdditionalEmail.propTypes = {
  email: PropTypes.string.isRequired,
  onRemoveAdditionalEmail: PropTypes.func.isRequired,
};
