import axios from 'axios';
import Actions from 'react/shared/actions/actions';

const CategorizedBudgetStore = flux.createStore({
  categorizedBudgets: {},

  actions: [
    Actions.fetchCategorizedBudgets,
    Actions.createCategorizedBudgetCategory,
    Actions.deleteCategorizedBudgetCategory,
    Actions.updateCategorizedBudgetCategory,
  ],

  fetchCategorizedBudgets(clientId, params) {
    axios
      .get(RailsRoutes.dashboard_client_categorized_budgets_path(clientId, params))
      .then((categorizedBudgetsResponse) => {
        this.categorizedBudgets = categorizedBudgetsResponse.data;
        this.emit('categorizedBudgets.fetch', this.categorizedBudgets);
      });
  },

  createCategorizedBudgetCategory(timelineId, params) {
    const url =
      RailsRoutes.categorized_budget_timeline_categorized_budget_categories_path(timelineId);
    axios
      .post(url, { categorizedBudgetCategory: params })
      .then((createResponse) =>
        this.emit('categorizedBudgets.createCategory', createResponse.data),
      );
  },

  deleteCategorizedBudgetCategory(categoryId) {
    axios
      .delete(RailsRoutes.categorized_budget_category_path(categoryId))
      .then((deleteResponse) =>
        this.emit('categorizedBudgets.deleteCategory', deleteResponse.data),
      );
  },

  updateCategorizedBudgetCategory(categoryId, params) {
    axios
      .put(RailsRoutes.categorized_budget_category_path(categoryId), {
        categorizedBudgetCategory: params,
      })
      .then((updateResponse) =>
        this.emit('categorizedBudgets.updateCategory', updateResponse.data),
      );
  },

  exports: {
    getCategorizedBudgets() {
      return this.categorizedBudgets;
    },
  },
});

export default CategorizedBudgetStore;
