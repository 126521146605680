import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import CardDetails from 'react/member/card/components/account_tab/card_status/CardDetails';
import CardShipment from 'react/member/card/components/account_tab/card_status/CardShipment';
import { expeditedShipping } from 'react/member/utils/CardOrder';
import { CARD_STATUSES } from 'react/member/utils/CardStatus';

export default function Released({
  cardBalance,
  cardBreached,
  cardExpiryDate,
  cardFboName,
  cardLast4,
  cardName,
  cardShipmentTrackingNumber,
  estimatedArrivalDate: date,
  estimatedArrivalRange: range,
  shippingAddress,
  status,
}) {
  const expedited = expeditedShipping(range);
  const formattedDate = moment(date, 'MM/DD/YYYY').format('MMMM Do, YYYY');
  const note = `Your card is on its way! Cards typically arrive within ${range[0]} to ${range[1]} business days. It will arrive at your preferred address by ${formattedDate}.`;

  return (
    <>
      <CardDetails
        cardBalance={cardBalance}
        cardBreached={cardBreached}
        cardLast4={cardLast4}
        expiryDate={cardExpiryDate}
        note={note}
        status={status}
      />
      <CardShipment
        cardFboName={cardFboName}
        cardName={cardName}
        cardShipmentTrackingNumber={cardShipmentTrackingNumber}
        expedited={expedited}
        shippingAddress={shippingAddress}
      />
    </>
  );
}

Released.propTypes = {
  cardBalance: PropTypes.number,
  cardBreached: PropTypes.bool,
  cardExpiryDate: PropTypes.string,
  cardFboName: PropTypes.string,
  cardLast4: PropTypes.string.isRequired,
  cardName: PropTypes.string.isRequired,
  cardShipmentTrackingNumber: PropTypes.string,
  estimatedArrivalDate: PropTypes.string.isRequired,
  estimatedArrivalRange: PropTypes.arrayOf(PropTypes.number).isRequired,
  shippingAddress: PropTypes.string,
  status: PropTypes.oneOf([CARD_STATUSES.RELEASED_FIRST_CARD_ORDER_STATUS]).isRequired,
};
