import { asMoney } from 'react/shared/utils/Money';

const truncateWithEllipses = (string, maxLength = 45) => {
  if (string.length > maxLength) {
    return `${string.slice(0, Math.max(0, maxLength - 3))}...`;
  }
  return string;
};

const toYesNo = (value) => {
  if (value) {
    return 'Yes';
  }
  return 'No';
};

const formatMoney = (number, precision) => {
  // Ideally this would be tighter controlled, but it is legacy
  // eslint-disable-next-line no-eq-null
  if (number == null) {
    return null;
  }

  return asMoney(number, precision);
};

const formatPercent = (decimal, precision = 2) => {
  const decimalNumber = Number.parseFloat(decimal);
  if (Number.isNaN(decimalNumber)) return '';
  return `${(decimalNumber * 100).toFixed(precision)}%`;
};

const snakeCaseToTitle = (str) => {
  if (!str) return '';
  const frags = str.split('_');
  for (let i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(' ');
};

const formatPhoneNumber = (numberString) => {
  // Ideally this would be tighter controlled, but it is legacy
  // eslint-disable-next-line no-eq-null
  if (numberString == null) {
    return null;
  }

  const cleaned = numberString.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return null;
};

export default {
  truncateWithEllipses,
  toYesNo,
  formatPhoneNumber,
  formatMoney,
  formatPercent,
  snakeCaseToTitle,
};
