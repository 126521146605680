import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import childrenShape from 'react/shared/shapes/children';
import theme from 'react/shared/theme/Theme';

const useStyles = makeStyles({
  root: {
    alignItems: 'start',
  },
  label: {
    padding: '9px 12px 9px 0',
  },
  smallLabel: {
    padding: '5px 12px 5px 0',
  },

  noBoarder: {
    '& .MuiFormControlLabel-root': {
      border: 'none',
    },
  },
});

export default function TrueLinkRadios({
  ariaLabel,
  name,
  onChange,
  value,
  options,
  noBorder,
  size,
}) {
  const classes = useStyles();

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.target.click();
    }
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <FormControl className={noBorder ? classes.noBoarder : ''}>
          <RadioGroup
            aria-label={ariaLabel}
            name={name}
            onChange={onChange}
            onKeyDown={handleKeyDown}
            value={value}
          >
            {options.map((option) => (
              <FormControlLabel
                classes={{
                  label: size === 'small' ? classes.smallLabel : classes.label,
                  root: classes.root,
                }}
                control={<Radio color="primary" size={size || 'medium'} />}
                id={option.id}
                key={option.value}
                label={value == option.value && option.helpText ? option.helpText : option.label}
                value={option.value}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

TrueLinkRadios.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  size: PropTypes.string,
  noBorder: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: childrenShape.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
};
