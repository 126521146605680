import PropTypes from 'prop-types';
import React from 'react';
import api from './api.js';
import TransactionList from 'react/shared/card/components/transaction_list/TransactionList';
import LoadingIndicator from 'react/shared/components/LoadingIndicator';
import { isRepPayee } from 'react/shared/utils/account_card_program';
import bindAll from 'react/shared/utils/bind_all';

export default class CardInInvestTransactionsTabContainer extends React.Component {
  static get propTypes() {
    return {
      accountId: PropTypes.string.isRequired,
    };
  }

  constructor(props) {
    super(props);

    this.state = {
      account: {},
      loading: true,
    };

    bindAll(this);
  }

  componentDidMount() {
    this.fetchAccount();
  }

  componentDidUpdate(prevProps, _prevState) {
    if (prevProps.accountId != this.props.accountId) {
      // This is legacy behavior and would require refactoring the state structure to fix. New behavior should not thrash the render cycle this way.
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ account: {}, loading: true });
      this.fetchAccount();
    }
  }

  fetchAccount() {
    const { accountId } = this.props;
    api.getAccount(accountId).then((response) => {
      this.setState({
        account: response.data.data,
        loading: false,
      });
    });
  }

  render() {
    const { account, loading } = this.state;

    if (loading) {
      return <LoadingIndicator />;
    }

    return (
      <TransactionList
        accountId={account.id}
        cardCount={account.meta.cardCount}
        hideClosedCardTransactions={account.attributes.hideClosedCardTransactions}
        hideClosedCardTransactionsEnabled
        isRepPayee={isRepPayee(account.attributes.cardProgramNumber)}
        ruleSetId={`${account.meta.ruleSetId}`}
        showNotes={account.meta.showTransactionNotes}
        showQDE={account.meta.showTransactionQde}
        showReceipts={account.meta.showTransactionReceipts}
        supportEmail={account.meta.locale.supportEmail}
      />
    );
  }
}
