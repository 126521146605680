import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles({
  container: {
    paddingTop: '10px',
    '& p': {
      paddingBottom: '15px',
    },
  },
});

export default function MfaVerifyWizardStep1({ email, phoneNumber }) {
  const classes = useStyles();
  const emailText = `We'll send a 6-digit code via email: `;
  const phoneText = `We'll send a 6-digit code via text message to your phone number: `;

  return (
    <div className={classes.container}>
      <p>We just need additional confirmation that it's you.</p>
      <p>{phoneNumber ? phoneText : emailText}</p>
      <p>{phoneNumber ? `(xxx) xxx-${phoneNumber.slice(-4)}` : email}</p>
    </div>
  );
}

MfaVerifyWizardStep1.propTypes = {
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
};
