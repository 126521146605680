import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import api from 'react/member/card/components/reports_tab/api';
import ReportDateRange from 'react/shared/card/components/reports_tab/ReportDateRange';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import TrueLinkIcon from 'react/shared/components/true_link/main/TrueLinkIcon';

const useStyles = makeStyles({
  wrapper: {
    marginTop: '20px',
  },
  alertIcon: {
    fontSize: '22px',
    color: '#305035',
    marginRight: '15px',
  },
  alertSuccess: {
    lineHeight: '21px',
    fontWeight: '400',
    cursor: 'default',
    position: 'relative',
    border: 0,
    backgroundColor: '#eee',
    color: '#222',
    padding: '10px 10px',
    marginTop: '10px',
    marginBottom: '15px',
    textShadow: 'none',
    boxShadow: 'none',
  },
});

export default function DetailedTransactionReport(props) {
  const classes = useStyles();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const email = window.user.email;

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      detailed_transaction_report_start_date: startDate,
      detailed_transaction_report_end_date: endDate,
    };

    setSubmitting(true);
    api
      .postDetailedTransactionReport(props.accountId, data)
      .then(() => setSubmitted(true))
      .catch(() => {
        // Do nothing other than what happens from the interceptor
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <div className={classes.wrapper}>
      <ReportDateRange
        endDate={endDate}
        onSetEndDate={setEndDate}
        onSetStartDate={setStartDate}
        reportName="detailed_transaction_report"
        startDate={startDate}
      />
      {submitted && (
        <div className={classes.alertSuccess}>
          <TrueLinkIcon className={classes.alertIcon} icon="check-circle" />
          Request sent! You will receive the report to your email ( <b>{email}</b> ) in 1-2 business
          days.
        </div>
      )}
      <TrueLinkButton disabled={submitting || submitted} onClick={handleSubmit} variant="primary">
        Request report
      </TrueLinkButton>
    </div>
  );
}

DetailedTransactionReport.propTypes = {
  accountId: PropTypes.string.isRequired,
};
