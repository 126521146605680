import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import TransferDescriptionLite from 'react/member/card/components/funding_tab/transfers_table/TransferDescriptionLite';
import { autoTransferShape } from 'react/member/card/shapes/AutoTransferShape';
import PopUp from 'react/shared/components/popups/PopUp';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';

const useStyles = makeStyles({
  description: {
    padding: '20px 30px',
  },
});

export default function AutoTransferDeleteModal({ autoTransfer, onClose, onConfirm }) {
  const classes = useStyles();
  const footer = (
    <TrueLinkButton className="btn btn-danger" onClick={onConfirm} variant="none">
      Confirm
    </TrueLinkButton>
  );

  return (
    <PopUp
      footer={footer}
      header="Delete recurring transfer"
      maxWidth="580px"
      modalOpen
      onClose={onClose}
    >
      <div>
        <b>Are you sure you want to delete this recurring transfer?</b>
        <div className={classes.description}>
          <TransferDescriptionLite item={autoTransfer} />
        </div>
        <p>This change will be effective immediately.</p>
      </div>
    </PopUp>
  );
}

AutoTransferDeleteModal.propTypes = {
  autoTransfer: autoTransferShape.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};
