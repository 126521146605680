import {
  isCardAwaitingReplacement,
  isCardClosed,
  isCardInactive,
  isCardLostOrStolen,
  isCardFraudBlocked,
  isCardOpen,
} from 'react/member/utils/Card';
import {
  FIRST_CARD,
  REISSUE_A_ACCOUNT,
  REISSUE_C_LEGACY_NEW_ACCOUNT,
  cardOrderType,
  isPreReleaseCardOrder,
  isReleasedCardOrder,
  isOverduePreReleaseCardOrder,
  isOverdueReleasedCardOrder,
} from 'react/member/utils/CardOrder';

export const CARD_STATUSES = {
  CLOSED_CARD_STATUS: 'CLOSED_CARD_STATUS',
  INACTIVE_CARD_STATUS: 'INACTIVE_CARD_STATUS',
  FRAUD_BLOCK_CARD_STATUS: 'FRAUD_BLOCK_CARD_STATUS',
  LOST_CARD_STATUS: 'LOST_CARD_STATUS',
  OPEN_CARD_STATUS: 'OPEN_CARD_STATUS',
  OVERDUE_PRE_RELEASE_FIRST_CARD_ORDER_STATUS: 'OVERDUE_PRE_RELEASE_FIRST_CARD_ORDER_STATUS',
  OVERDUE_PRE_RELEASE_ADDITIONAL_CARD_ORDER_STATUS: 'OVERDUE_PRE_RELEASE_ADDITIONAL_CARD_ORDER_STATUS', // prettier-ignore
  OVERDUE_RELEASED_FIRST_CARD_ORDER_STATUS: 'OVERDUE_RELEASED_FIRST_CARD_ORDER_STATUS',
  OVERDUE_RELEASED_ADDITIONAL_CARD_ORDER_STATUS: 'OVERDUE_RELEASED_ADDITIONAL_CARD_ORDER_STATUS',
  OVERDUE_RELEASED_BACKUP_CARD_ORDER_STATUS: 'OVERDUE_RELEASED_BACKUP_CARD_ORDER_STATUS',
  OVERDUE_RELEASED_LEGACY_CARD_ORDER_STATUS: 'OVERDUE_RELEASED_LEGACY_CARD_ORDER_STATUS',
  OVERDUE_RELEASED_REPLACEMENT_CARD_ORDER_STATUS: 'OVERDUE_RELEASED_REPLACEMENT_CARD_ORDER_STATUS',
  PRE_RELEASE_FIRST_CARD_ORDER_STATUS: 'PRE_RELEASE_FIRST_CARD_ORDER_STATUS',
  PRE_RELEASE_ADDITIONAL_CARD_ORDER_STATUS: 'PRE_RELEASE_ADDITIONAL_CARD_ORDER_STATUS',
  PRE_RELEASE_BACKUP_CARD_ORDER_STATUS: 'PRE_RELEASE_BACKUP_CARD_ORDER_STATUS',
  PRE_RELEASE_LEGACY_CARD_ORDER_STATUS: 'PRE_RELEASE_LEGACY_CARD_ORDER_STATUS',
  PRE_RELEASE_REPLACEMENT_CARD_ORDER_STATUS: 'PRE_RELEASE_REPLACEMENT_CARD_ORDER_STATUS',
  RELEASED_FIRST_CARD_ORDER_STATUS: 'RELEASED_FIRST_CARD_ORDER_STATUS',
  RELEASED_ADDITIONAL_CARD_ORDER_STATUS: 'RELEASED_ADDITIONAL_CARD_ORDER_STATUS',
  RELEASED_LEGACY_CARD_ORDER_STATUS: 'RELEASED_LEGACY_CARD_ORDER_STATUS',
  RELEASED_REPLACEMENT_CARD_ORDER_STATUS: 'RELEASED_REPLACEMENT_CARD_ORDER_STATUS',
  RELEASED_BACKUP_CARD_ORDER_STATUS: 'RELEASED_BACKUP_CARD_ORDER_STATUS',
  REPLACED_CARD_STATUS: 'REPLACED_CARD_STATUS',
};

export const cardStatusSortingHat = (account, card) => {
  const {
    CLOSED_CARD_STATUS,
    INACTIVE_CARD_STATUS,
    LOST_CARD_STATUS,
    FRAUD_BLOCK_CARD_STATUS,
    OPEN_CARD_STATUS,
    OVERDUE_PRE_RELEASE_FIRST_CARD_ORDER_STATUS,
    OVERDUE_PRE_RELEASE_ADDITIONAL_CARD_ORDER_STATUS,
    OVERDUE_RELEASED_LEGACY_CARD_ORDER_STATUS,
    OVERDUE_RELEASED_REPLACEMENT_CARD_ORDER_STATUS,
    OVERDUE_RELEASED_FIRST_CARD_ORDER_STATUS,
    OVERDUE_RELEASED_ADDITIONAL_CARD_ORDER_STATUS,
    PRE_RELEASE_FIRST_CARD_ORDER_STATUS,
    PRE_RELEASE_ADDITIONAL_CARD_ORDER_STATUS,
    RELEASED_FIRST_CARD_ORDER_STATUS,
    RELEASED_ADDITIONAL_CARD_ORDER_STATUS,
    RELEASED_REPLACEMENT_CARD_ORDER_STATUS,
    RELEASED_LEGACY_CARD_ORDER_STATUS,
    REPLACED_CARD_STATUS,
  } = CARD_STATUSES;

  if (isCardClosed(card)) return CLOSED_CARD_STATUS;
  if (isCardAwaitingReplacement(card)) return REPLACED_CARD_STATUS;
  if (isCardLostOrStolen(card)) return LOST_CARD_STATUS;
  if (isCardFraudBlocked(card)) return FRAUD_BLOCK_CARD_STATUS;
  if (isCardInactive(card)) return INACTIVE_CARD_STATUS;
  if (isCardOpen(card)) return OPEN_CARD_STATUS;

  if (isOverduePreReleaseCardOrder(account, card)) {
    switch (cardOrderType(account, card)) {
      case FIRST_CARD:
        return OVERDUE_PRE_RELEASE_FIRST_CARD_ORDER_STATUS;
      default:
        return OVERDUE_PRE_RELEASE_ADDITIONAL_CARD_ORDER_STATUS;
    }
  }
  if (isPreReleaseCardOrder(account, card)) {
    switch (cardOrderType(account, card)) {
      case FIRST_CARD:
        return PRE_RELEASE_FIRST_CARD_ORDER_STATUS;
      default:
        return PRE_RELEASE_ADDITIONAL_CARD_ORDER_STATUS;
    }
  }
  if (isOverdueReleasedCardOrder(account, card)) {
    switch (cardOrderType(account, card)) {
      case FIRST_CARD:
        return OVERDUE_RELEASED_FIRST_CARD_ORDER_STATUS;
      case REISSUE_A_ACCOUNT:
        return OVERDUE_RELEASED_REPLACEMENT_CARD_ORDER_STATUS;
      case REISSUE_C_LEGACY_NEW_ACCOUNT:
        return OVERDUE_RELEASED_LEGACY_CARD_ORDER_STATUS;
      default:
        return OVERDUE_RELEASED_ADDITIONAL_CARD_ORDER_STATUS;
    }
  }
  if (isReleasedCardOrder(account, card)) {
    switch (cardOrderType(account, card)) {
      case FIRST_CARD:
        return RELEASED_FIRST_CARD_ORDER_STATUS;
      case REISSUE_A_ACCOUNT:
        return RELEASED_REPLACEMENT_CARD_ORDER_STATUS;
      case REISSUE_C_LEGACY_NEW_ACCOUNT:
        return RELEASED_LEGACY_CARD_ORDER_STATUS;
      default:
        return RELEASED_ADDITIONAL_CARD_ORDER_STATUS;
    }
  }

  return null;
};
