import PropTypes from 'prop-types';
import React from 'react';

export default function HolidayImpactedTransfersAmountCell({ value }) {
  return <span>${value}</span>;
}

HolidayImpactedTransfersAmountCell.propTypes = {
  value: PropTypes.string.isRequired,
};
