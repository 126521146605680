import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import Content from './Content';
import DashboardTrustBeneficiaryProfileCoreInfoForm from './DashboardTrustBeneficiaryProfileCoreInfoForm';
import Header from './Header';
import { trustBeneficiaryShape } from 'react/member/components/dashboard/trust_beneficiaries/profile/core_info/CoreInfoShape';
import DashboardTrustBeneficiaryStore from 'react/member/stores/beneficiary_dashboard/DashboardTrustBeneficiaryStore';
import TrueLinkModal from 'react/shared/components/true_link/lab/TrueLinkModal';

export default function DashboardTrustBeneficiaryProfileCoreInfo({
  allExpanded,
  expandAll,
  collapseAll,
  canEdit,
  trustBeneficiary,
}) {
  const [editing, setEditing] = useState(false);

  const setEditingFalse = () => {
    setEditing(false);
  };

  const setEditingTrue = () => {
    setEditing(true);
  };

  useEffect(() => {
    const { slug } = trustBeneficiary;
    DashboardTrustBeneficiaryStore.on(`trustBeneficiary.update.${slug}`, setEditingFalse);
    return () => {
      DashboardTrustBeneficiaryStore.off(`trustBeneficiary.update.${slug}`, setEditingFalse);
    };
  }, [trustBeneficiary]);

  return (
    <div className="toggle-section">
      {canEdit && editing && (
        <TrueLinkModal
          handleClose={setEditingFalse}
          scrollable
          title={'Update Demographic Information'}
        >
          <DashboardTrustBeneficiaryProfileCoreInfoForm
            trustBeneficiary={{ ...trustBeneficiary }}
          />
        </TrueLinkModal>
      )}
      <Header
        allExpanded={allExpanded}
        canEdit={canEdit}
        collapseAll={collapseAll}
        expandAll={expandAll}
        onEditClick={setEditingTrue}
        trustBeneficiary={trustBeneficiary}
      />
      <Content canEdit={canEdit} trustBeneficiary={trustBeneficiary} />
    </div>
  );
}

DashboardTrustBeneficiaryProfileCoreInfo.propTypes = {
  allExpanded: PropTypes.bool.isRequired,
  expandAll: PropTypes.func.isRequired,
  collapseAll: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
  trustBeneficiary: trustBeneficiaryShape.isRequired,
};
