import PropTypes from 'prop-types';
import React from 'react';
import ReactInputMask from 'react-input-mask';
import _every from 'underscore/modules/every';
import _map from 'underscore/modules/map';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import { camelize, underscorize } from 'react/shared/utils/Strings';

export default class FamilyFunderForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.formDefaults();
    this.fields = ['First name', 'Last name', 'Email address', 'Mobile phone', 'Home phone'];

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmission = this.handleFormSubmission.bind(this);
  }

  formDefaults() {
    return {
      firstName: '',
      lastName: '',
      emailAddress: '',
      mobilePhone: '',
      homePhone: '',
      hasSubmittedForm: false,
      formErrors: {
        firstName: '',
        lastName: '',
        emailAddress: '',
      },
    };
  }

  handleInputChange({ target: { value, name } }) {
    const stateObj = {};
    stateObj[name] = value;
    if (this.state.hasSubmittedForm) {
      this.setState(stateObj, () => this.validateFormInput());
    } else {
      this.setState(stateObj);
    }
  }

  validateFormInput() {
    const { formErrors } = this.state;

    const inputs = {
      firstName: 'Please enter a first name',
      lastName: 'Please enter a last name',
      emailAddress: 'Please enter a valid email',
    };
    _map(
      Object.keys(inputs),
      (requiredInput) => {
        if (requiredInput === 'emailAddress') {
          // I didn't write this by hand, please see https://emailregex.com/ (and forgive me if you see this later on and it's no longer valid).
          const emailValid = this.state[requiredInput].match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
          );
          formErrors[requiredInput] = emailValid ? '' : inputs[requiredInput];
        } else {
          formErrors[requiredInput] = this.state[requiredInput] === '' ? inputs[requiredInput] : '';
        }
      },
      this,
    );

    this.setState({ formErrors });
  }

  validForm() {
    return _every(this.state.formErrors, (error) => error === '');
  }

  handleFormSubmission(ev) {
    ev.preventDefault();
    this.validateFormInput();
    if (!this.validForm()) return;
    const { firstName, lastName, emailAddress, mobilePhone, homePhone } = this.state;

    this.setState({ hasSubmittedForm: true });

    $.post({
      url: RailsRoutes.dashboard_send_family_funding_invite_path(),
      data: {
        family_funder_first_name: firstName,
        family_funder_last_name: lastName,
        family_funder_email: emailAddress,
        family_funder_mobile_phone: mobilePhone,
        family_funder_home_phone: homePhone,
      },
    })
      .done(() => {
        this.setState({ ...this.formDefaults(), hasSubmittedForm: false });
        this.props.refreshFamilyFunders();
        this.props.toggleModal();
        Truelink.flash(
          'success',
          'Thank you! Your email has been sent and your requested family funder can now create a login to fund this True Link Card.',
          false,
          true,
        );
      })
      .fail(() => {
        this.setState({ hasSubmittedForm: false });
        Truelink.flash('error', 'An error occured while trying to create an invite.', true, false);
      });
  }

  renderFields() {
    return this.fields.map((inputName, index) => {
      const Input = inputName.includes('phone') ? ReactInputMask : 'input';
      return (
        <div className="new-form-field" key={index} style={{ marginRight: 60 }}>
          <label className="new-form__label" htmlFor={underscorize(inputName)}>
            {inputName}:
          </label>
          <div className="new-form__data">
            <Input
              id={`ff_invitee_${underscorize(inputName)}`}
              mask="(999) 999-9999"
              name={camelize(inputName)}
              onChange={this.handleInputChange}
              type="text"
              value={this.props.defaultValues[camelize(inputName)]}
            />
            <div className="inline-errors">{this.state.formErrors[camelize(inputName)]}</div>
          </div>
        </div>
      );
    });
  }

  render() {
    const { hasSubmittedForm } = this.state;

    return (
      <div className="family_funder_invite_form">
        <form className="new-form--compact" onSubmit={this.handleFormSubmission}>
          <p className="ff-form-copy">
            Would you like to make a family member or loved one a family funder for this card?
            Please provide a name, email, and phone number for the family funder. We will use this
            information to contact them and ask them to fund the card on behalf of the cardholder.
          </p>
          <div className="ff-flex-container">{this.renderFields()}</div>
          <div className="ff-form-actions">
            <TrueLinkButton disabled={hasSubmittedForm} type="submit" variant="primary">
              Add Family Funder
            </TrueLinkButton>
          </div>
        </form>
      </div>
    );
  }
}

FamilyFunderForm.propTypes = {
  refreshFamilyFunders: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  defaultValues: PropTypes.object,
};

FamilyFunderForm.defaultProps = {
  defaultValues: {},
};
