import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import TrueLinkIcon from 'react/shared/components/true_link/main/TrueLinkIcon';
import TrueLinkTooltip from 'react/shared/components/true_link/main/TrueLinkTooltip';

const useStyles = makeStyles({
  icon: ({ color }) => ({
    color: `${color}`,
    fontSize: '18px',
  }),
  content: {
    fontSize: '16px',
  },
});

function HolidayMitigatedTransferIcon({ onClick, holidayName, transferId, guidanceType, color }) {
  const classes = useStyles({ color });
  const fundsArrivingText = guidanceType === 'Deliver before' ? 'before the holiday' : 'on time';

  const content = (
    <div className={classes.content} id={`holiday-updated-transfer-popover-${transferId}`}>
      <b>{`This transfer has been updated to avoid a delay due to the ${holidayName} holiday`}</b>
      <br />
      <br />
      {`Your transfer will initiate earlier than normal, so the funds will arrive ${fundsArrivingText}`}
      <br />
      <br />
      <span
        id={`impacted-transfer-revert-link-${transferId}`}
        onClick={onClick}
        onKeyUp={onClick}
        role="link"
        style={{ textDecoration: 'underline', cursor: 'pointer' }}
        tabIndex="0"
      >
        {'To undo, revert to original'}
      </span>
    </div>
  );

  return (
    <TrueLinkTooltip placement="right" tooltipContent={content}>
      <span id={`holiday-updated-transfer-tooltip-${transferId}`}>
        <TrueLinkIcon className={classes.icon} icon="info-circle" />
      </span>
    </TrueLinkTooltip>
  );
}

HolidayMitigatedTransferIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
  holidayName: PropTypes.string.isRequired,
  transferId: PropTypes.number.isRequired,
  guidanceType: PropTypes.string,
  color: PropTypes.string,
};

export default HolidayMitigatedTransferIcon;
