import PropTypes from 'prop-types';
import React from 'react';
import _map from 'underscore/modules/map';
import RadioField from './RadioField';
import RadioLabel from './RadioLabel';

function RadioGroup({
  id: radioGroupId,
  name,
  options,
  radioGroupClass,
  radioFieldClass,
  radioInputClass,
  radioLabelClasses,
  dataAnalyticsKey,
}) {
  return (
    <div className={radioGroupClass} id={radioGroupId} role="radiogroup">
      {_map(
        options,
        ({ id, value, disabled, labelContent, onChangeCallback, afterLabel, afterRadio }) => (
          <span className={radioFieldClass} key={`${name}-${id}`}>
            <span>
              <RadioField
                className={radioInputClass}
                dataAnalyticsKey={dataAnalyticsKey}
                disabled={disabled}
                id={id}
                name={name}
                onChangeCallback={onChangeCallback}
                value={value}
              />
              <RadioLabel
                afterLabel={afterLabel}
                disabled={disabled}
                htmlFor={id}
                labelContent={labelContent}
                {...radioLabelClasses}
              />
            </span>
            {afterRadio}
          </span>
        ),
      )}
    </div>
  );
}

RadioGroup.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
      disabled: PropTypes.bool,
      labelContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      onChangeCallback: PropTypes.func,
      afterLabel: PropTypes.node,
      afterRadio: PropTypes.node,
    }),
  ).isRequired,
  dataAnalyticsKey: PropTypes.string,
  radioFieldClass: PropTypes.string,
  radioGroupClass: PropTypes.string,
  radioContainerClass: PropTypes.string,
  radioInputClass: PropTypes.string,
  radioLabelClasses: PropTypes.shape({
    radioLabelClass: PropTypes.string,
    radioTextClass: PropTypes.string,
    radioCheckboxContainerClass: PropTypes.string,
  }),
};

RadioGroup.defaultProps = {
  radioGroupClass: 'card--form__radio',
  radioFieldClass: 'card--form__radio__field',
  radioInputClass: 'card--form__radio__input',
  radioLabelClasses: {},
};

export default RadioGroup;
