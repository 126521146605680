import axios from 'axios';

const includedWithType = (response, type) =>
  response.data.included.reduce((result, field) => {
    if (field.type === type) {
      result.push(field);
    }
    return result;
  }, []);

export const fetchDisbursementsIndex = async (clientId, filters) => {
  const analysisFields = 'displayCategory,displayPaymentMethod';
  const relationships = 'categoryAmounts';
  const res = await axios.get(
    RailsRoutes.api_v2_trust_beneficiary_disbursements_path(clientId, {
      'filter[status]': filters,
      include: relationships,
      'fields[disbursement.analysis]': analysisFields,
      'page[size]': 3000,
    }),
  );

  const formattedResult = res.data.data.reduce((result, disbursement) => {
    const [year, month, day] = disbursement.attributes.deliveryDate
      ? disbursement.attributes.deliveryDate.split('-')
      : '';
    const [clearedYear, clearedMonth, clearedDay] = disbursement.attributes.clearedDate
      ? disbursement.attributes.clearedDate.split('-')
      : '';

    const includedCategoryAmounts = disbursement.relationships.categoryAmounts.data.map(
      (categoryAmount) =>
        res.data.included.find(
          (included) =>
            included.type === 'disbursement_category_amount' && included.id === categoryAmount.id,
        ),
    );

    result.push({
      deliveryDate: disbursement.attributes.deliveryDate && [month, day, year].join('/'),
      clearedDate:
        disbursement.attributes.clearedDate && [clearedMonth, clearedDay, clearedYear].join('/'),
      attributes: disbursement.attributes,
      meta: disbursement.meta,
      id: disbursement.id,
      relationships: disbursement.relationships,
      categoryAmounts: includedCategoryAmounts,
    });

    return result;
  }, []);

  return formattedResult;
};

export const fetchDisbursementsShow = async (id) => {
  const fields =
    'decisions,decisions.user,categoryAmounts,categoryAmounts.attachments,sourceBankAccount';
  const analysisFields = 'canAddAttachments';
  const res = await axios.get(
    `${window.location.origin}/api/v2/disbursements/${id}?include=${fields}&fields[disbursement.analysis]=${analysisFields}`,
  );

  const disbursementCategoryAmounts = includedWithType(res, 'disbursement_category_amount');
  const includedAttachments = includedWithType(res, 'attachment');

  // Find attachment attributes for each attachment relation and
  // combine them onto the DCA so they are accessible from one place.
  disbursementCategoryAmounts.map((dca) => {
    if (dca.relationships?.attachments)
      dca.relationships.attachments.data.map((attachment) => {
        const foundAttachment = includedAttachments.find(
          (localAttachment) => localAttachment.id === attachment.id,
        );
        attachment.attributes = foundAttachment.attributes;
      });
  });

  const includedDecisions = includedWithType(res, 'disbursement_decision');
  const users = includedWithType(res, 'user');

  const decisions = includedDecisions.reduce((result, decision) => {
    const foundUser = users.find(
      (localUser) => decision.relationships.user.data.id === localUser.id,
    );

    result.push({
      id: decision.id,
      email: foundUser.attributes.email,
      decision: decision.attributes.decision,
      createdAt: decision.attributes.createdAt,
    });
    return result;
  }, []);

  const includedBankAccounts = includedWithType(res, 'bank_account');

  return {
    data: res.data.data,
    disbursementCategoryAmounts,
    decisions,
    includedAttachments,
    sourceBankAccount: includedBankAccounts[0],
  };
};
