import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import GenericInlineLabel from './GenericInlineLabel';
import DatePickerField from 'react/shared/components/forms/DatePickerField';
import GenericSubmitPopUp from 'react/shared/components/popups/GenericSubmitPopUp';
import genericSubmitPopUpShapeKeys from 'react/shared/shapes/GenericSubmitPopUpShape';
import { underscorize } from 'react/shared/utils/Strings';
import bindAll from 'react/shared/utils/bind_all';

const dateDisplayFormat = 'MM/DD/YYYY';

export default class GenericDatePickerPopUp extends React.Component {
  constructor(props) {
    super(props);

    const initialDateString = props.initialDate
      ? props.initialDate.format(dateDisplayFormat)
      : null;

    this.state = {
      selectedDate: initialDateString,
      valid: false,
      dirty: false,
    };

    bindAll(this);
  }

  handleValidate(newDateStr) {
    const { minDate, maxDate } = this.props;

    const date = moment(newDateStr, dateDisplayFormat);
    const isValid = date.isValid();

    const isMinBound = minDate ? date.isSame(minDate) || date.isAfter(minDate) : true;
    const isMaxBound = maxDate ? date.isSame(maxDate) || date.isBefore(maxDate) : true;

    return isValid && isMinBound && isMaxBound;
  }

  handleChange(newDate) {
    this.setState({
      selectedDate: newDate,
      valid: this.handleValidate(newDate),
      dirty: true,
    });
  }

  handleSubmit() {
    const { onSubmit } = this.props;

    const reformattedDate = moment(this.state.selectedDate, dateDisplayFormat).format('YYYY-MM-DD');

    onSubmit(reformattedDate);
  }

  renderDatePickerWithLabel() {
    const { datePickerLabel, minDate, maxDate } = this.props;
    const inputId = `generic-popup-date-picker-${underscorize(datePickerLabel)}`;

    return (
      <GenericInlineLabel inputId={inputId} labelContent={datePickerLabel}>
        <DatePickerField
          dateFormat="mm/dd/yy"
          handleDateSelect={this.handleChange}
          inputProps={{
            id: inputId,
            defaultValue: this.state.selectedDate,
            placeholder: dateDisplayFormat,
            className: this.state.valid || !this.state.dirty ? '' : 'invalid',
          }}
          maxDate={maxDate ? maxDate.toDate() : null}
          minDate={minDate ? minDate.toDate() : null}
        />
      </GenericInlineLabel>
    );
  }

  render() {
    return (
      <GenericSubmitPopUp
        {...this.props}
        disableSubmit={!this.state.valid || !this.state.dirty}
        displayErrorText={false}
        messageNode={this.renderDatePickerWithLabel()}
        onSubmit={this.handleSubmit}
      />
    );
  }
}

GenericDatePickerPopUp.propTypes = {
  ...genericSubmitPopUpShapeKeys,
  datePickerLabel: PropTypes.string.isRequired,
  initialDate: PropTypes.object,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
};

GenericDatePickerPopUp.defaultProps = {
  initialDate: null, // moment object
  minDate: null, // moment object
  maxDate: null, // moment object
};
