import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import TransferRow from './TransferRow';
import TransferRowEmpty from './TransferRowEmpty';
import { autoTransferShape } from 'react/member/card/shapes/AutoTransferShape';
import { transferShape } from 'react/member/card/shapes/TransferShape';
import theme from 'react/shared/theme/Theme';

const useStyles = makeStyles(() => ({
  colAction: {
    width: '18%',
  },
  colIcon: {
    width: '8%',
  },
  colAmount: {
    width: '10%',
  },
  colBankAccount: {
    width: '18%',
  },
  colDescription: {
    width: '46%',
  },
  table: {
    tableLayout: 'fixed',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      tableLayout: 'auto',
    },
  },
}));
export default function TransfersTable({
  columnTitles,
  id,
  items,
  isViewOnly,
  renderActionCell,
  zeroStateText,
}) {
  const classes = useStyles();

  return (
    <table className={`table dataTable stripe ${classes.table}`} id={id}>
      <thead>
        <tr>
          <th className={classes.colAmount}>{columnTitles[0]}</th>
          <th className={classes.colIcon}>{columnTitles[1]}</th>
          <th className={classes.colDescription}>{columnTitles[2]}</th>
          <th className={classes.colBankAccount}>{columnTitles[3]}</th>
          <th className={classes.colAction}>{columnTitles[4]}</th>
        </tr>
      </thead>
      <tbody>
        {items.length === 0 ? (
          <TransferRowEmpty zeroStateText={zeroStateText} />
        ) : (
          items.map((item, i) => (
            <TransferRow
              i={i}
              isViewOnly={isViewOnly}
              item={item}
              key={item.id}
              renderActionCell={renderActionCell}
            />
          ))
        )}
      </tbody>
    </table>
  );
}

TransfersTable.propTypes = {
  columnTitles: PropTypes.arrayOf(PropTypes.string),
  id: PropTypes.string.isRequired,
  items: PropTypes.oneOfType([
    PropTypes.arrayOf(autoTransferShape),
    PropTypes.arrayOf(transferShape),
  ]).isRequired,
  isViewOnly: PropTypes.bool.isRequired,
  renderActionCell: PropTypes.func.isRequired,
  zeroStateText: PropTypes.string.isRequired,
};

TransfersTable.defaultProps = {
  columnTitles: ['Amount', '', 'Initiate On', 'Account', ''],
};
