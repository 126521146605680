import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import TrueLinkIcon from 'react/shared/components/true_link/main/TrueLinkIcon';
import TrueLinkLink from 'react/shared/components/true_link/main/TrueLinkLink';
import childrenShape from 'react/shared/shapes/children';
import theme from 'react/shared/theme/Theme';
import PALETTE from 'react/shared/theme/palette';

const useStyles = makeStyles(() => ({
  card: {
    boxShadow: 'none',
    border: `1px solid ${PALETTE.grey4}`,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(4),
  },
  spacer: {
    flexGrow: 1,
  },
  icon: {
    color: PALETTE.emerald,
    marginRight: theme.spacing(3),
  },
  viewLink: {
    fontWeight: 400,
  },
  viewLinkIcon: {
    marginLeft: theme.spacing(2),
    fontSize: '16px',
  },
  footer: {
    borderTop: `1px solid ${PALETTE.grey4}`,
    padding: 0,
  },
  cardContent: {
    padding: theme.spacing(4),
    paddingTop: 0,
  },
  cardHeaderContainer: {
    padding: theme.spacing(4),
  },
  cardHeaderGrid: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
}));

export default function DashboardCard({
  title,
  content,
  icon,
  onViewLink,
  footer,
  viewLinkAriaLabel,
}) {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardHeader
        className={classes.cardHeaderContainer}
        title={
          <Grid alignItems="center" container direction="row" justifyContent="flex-start">
            {icon && <TrueLinkIcon className={classes.icon} icon={icon} />}
            {title}
            <span className={classes.spacer} />
            {onViewLink && (
              <Typography className={classes.viewLink} component="span" variant="body2">
                <TrueLinkLink ariaLabel={viewLinkAriaLabel} onClick={onViewLink}>
                  View
                  <TrueLinkIcon className={classes.viewLinkIcon} icon="chevron-right" />
                </TrueLinkLink>
              </Typography>
            )}
          </Grid>
        }
      />
      {content && <CardContent className={classes.cardContent}>{content}</CardContent>}
      {footer && <CardActions className={classes.footer}>{footer}</CardActions>}
    </Card>
  );
}

DashboardCard.propTypes = {
  content: childrenShape,
  footer: childrenShape,
  icon: PropTypes.string,
  onViewLink: PropTypes.func,
  viewLinkAriaLabel: PropTypes.string,
  title: childrenShape.isRequired,
};
