import PropTypes from 'prop-types';
import React from 'react';
import DisbursementTabsWrapper from 'react/member/components/dashboard/disbursements/tab_content/DisbursementTabsWrapper';
import userRoleShape from 'react/member/shapes/UserRoleShape';
import TabContent from 'react/shared/components/TabContent';
import TabNavigation from 'react/shared/components/TabNavigation';

function DashboardOrganization({ organization, renderOnHoldDisbursements, userRoles }) {
  const tabList = [{ id: 'organization-disbursements-tab', title: 'Disbursements' }];
  return (
    <div className="widescreen-inner-container">
      <TabNavigation showBackground tabs={tabList}>
        <TabContent id="organization-disbursements-tab">
          <DisbursementTabsWrapper
            disbursementsFor={'Organization'}
            organizationSlug={organization.slug}
            renderOnHoldDisbursements={renderOnHoldDisbursements}
            userRoles={userRoles}
          />
        </TabContent>
      </TabNavigation>
    </div>
  );
}

DashboardOrganization.propTypes = {
  organization: PropTypes.object.isRequired,
  renderOnHoldDisbursements: PropTypes.bool,
  userRoles: userRoleShape.isRequired,
};

DashboardOrganization.defaultProps = {
  renderOnHoldDisbursements: false,
};

export default DashboardOrganization;
