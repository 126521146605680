import PropTypes from 'prop-types';
import React from 'react';
import _times from 'underscore/modules/times';

export default function InlineFrequencySelect({
  frequency,
  recurringType,
  setFrequency,
  chosenRecurringType,
  limit,
}) {
  const options = _times(limit, (i) => (
    <option key={i} value={i + 1}>
      {i + 1}
    </option>
  ));
  return (
    <select
      defaultValue={frequency || 1}
      disabled={recurringType != chosenRecurringType}
      name="recurring_disbursement[frequency]"
      onChange={setFrequency}
      style={{ width: 50, marginLeft: 5, marginRight: 5 }}
    >
      {options}
    </select>
  );
}

InlineFrequencySelect.propTypes = {
  frequency: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  recurringType: PropTypes.string.isRequired,
  setFrequency: PropTypes.func.isRequired,
  chosenRecurringType: PropTypes.string.isRequired,
  limit: PropTypes.number.isRequired,
};
