import PropTypes from 'prop-types';
import React from 'react';
import _bindAll from 'underscore/modules/bindAll';
import _map from 'underscore/modules/map';
import DashboardActions from 'react/member/actions/dashboard_actions';
import DashboardCategoryStore from 'react/member/stores/DashboardCategoryStore';

// Nomenclature note: this is the dropdown where the name of the category is selected. The rest of the data
// for the category (amount, memo, etc...) is collected on form elements rendered by
// DashboardBudgetItemCategories.
export default class CategoryDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      categories: [],
      display: false,
      currentValue: this.props.initialValue || '',
    };

    _bindAll(this, 'handleCurrentValue', 'setCategories');
  }

  componentDidMount() {
    DashboardCategoryStore.on('categories.fetch', this.setCategories);

    const organizationSlug = this.props.organizationSlug || this.association().organization.slug;
    DashboardActions.getOrFetchCategories(organizationSlug);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(
      JSON.stringify(this.props) === JSON.stringify(nextProps) &&
      JSON.stringify(this.state) === JSON.stringify(nextState)
    );
  }

  componentDidUpdate(prevProps) {
    this.reloadDropdown();
    if (prevProps.initialValue !== this.props.initialValue) {
      // This is legacy behavior and would require refactoring the state structure to fix. New behavior should not thrash the render cycle this way.
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ currentValue: this.props.initialValue }, this.reloadDropdown);
    }
  }

  componentWillUnmount() {
    DashboardCategoryStore.off('categories.fetch', this.setCategories);
  }

  reloadDropdown() {
    $(`#${this.htmlId()}`).trigger('chosen:updated');
  }

  handleCurrentValue(ev) {
    this.setState({ currentValue: ev.target.value });
  }

  setCategories(categories) {
    this.setState({ categories });
    this.addChosen();
  }

  formatCategory(category, idx) {
    return (
      <option key={idx} value={category}>
        {category}
      </option>
    );
  }

  association() {
    const ctx = this.context;
    return ctx.client || ctx.trust || ctx.portfolio;
  }

  htmlId() {
    return `disbursement_categories${this.props.categoryNumber}`;
  }

  getDropdownName() {
    const { isExistingRecord, isRecurringDisbursement } = this.props;
    if (!isExistingRecord) {
      return 'categories';
    }

    if (isRecurringDisbursement) {
      return 'recurring_disbursement[category]';
    }
    return 'disbursement[category]';
  }

  dropdown() {
    const list = _map(this.state.categories, (category, idx) => this.formatCategory(category, idx));
    const id = this.htmlId();
    return (
      <select
        aria-label="Category"
        className="input"
        id={id}
        name={this.getDropdownName()}
        onChange={this.handleCurrentValue}
        style={{ width: 300 }}
        value={this.state.currentValue}
      >
        <option disabled key="" value="">
          Select from this list
        </option>
        {list}
      </select>
    );
  }

  addChosen() {
    const dropSelector = `#disbursement_categories${this.props.categoryNumber}`;
    const dropChosenSelector = `${dropSelector}_chosen`;

    $(dropSelector)
      .chosen({
        no_results_text: 'No category matches',
        inherit_select_classes: true,
      })
      .change((evt) =>
        this.props.onChange ? this.props.onChange(evt) : this.handleCurrentValue(evt),
      );
    return $(dropChosenSelector).on('keyup', (evt) => $(evt.target).val());
  }

  render() {
    return (
      <div>
        {!this.props.hideLabel && <label htmlFor={this.htmlId()}>Category</label>}
        {this.dropdown()}
      </div>
    );
  }
}

CategoryDropdown.propTypes = {
  initialValue: PropTypes.string,
  isRecurringDisbursement: PropTypes.bool,
  isExistingRecord: PropTypes.bool,
  onChange: PropTypes.func,
  organizationSlug: PropTypes.string,
  hideLabel: PropTypes.bool,
  categoryNumber: PropTypes.number,
};

CategoryDropdown.contextTypes = {
  client: PropTypes.object,
  trust: PropTypes.object,
  portfolio: PropTypes.object,
};
