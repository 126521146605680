import PropTypes from 'prop-types';
import React from 'react';
import DashboardTrustBeneficiaryProfileTrustRemainderBeneficiariesDestroyForm from './DashboardTrustBeneficiaryProfileTrustRemainderBeneficiariesDestroyForm';
import DashboardTrustBeneficiaryProfileTrustRemainderBeneficiariesEditForm from './DashboardTrustBeneficiaryProfileTrustRemainderBeneficiariesEditForm';
import DashboardActions from 'react/member/actions/dashboard_actions';
import BasicTableBuilder from 'react/member/components/dashboard/misc/BasicTableBuilder';
import DashboardTrustRemainderBeneficiariesStore from 'react/member/stores/DashboardTrustRemainderBeneficiariesStore';
import ToggleSectionHeader from 'react/shared/components/ToggleSectionHeader';
import TrueLinkModal from 'react/shared/components/true_link/lab/TrueLinkModal';
import bindAll from 'react/shared/utils/bind_all';
import TlFieldTransformers from 'react/shared/utils/tl_field_transformers';

export default class DashboardTrustBeneficiaryProfileTrustRemainderBeneficiaries extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      addingItem: false,
      editingItem: null,
      destroyingItem: null,

      trustRemainderBeneficiaries: [],
      isLoading: true,
    };

    bindAll(this);
  }

  componentDidMount() {
    const trustBeneficiarySlug = this.props.trustBeneficiary.slug;
    this.fetchedTrustRemainderBeneficiaries = (trustRemainderBeneficiaries) =>
      this.setState({ trustRemainderBeneficiaries, isLoading: false });

    this.resetCreatingItem = () => this.setState({ addingItem: false });
    this.resetUpdatingItem = () => this.setState({ editingItem: null });
    this.resetDestroyingItem = () => this.setState({ destroyingItem: null });

    DashboardTrustRemainderBeneficiariesStore.on(
      `trustRemainderBeneficiaries.fetch.${trustBeneficiarySlug}`,
      this.fetchedTrustRemainderBeneficiaries,
    );
    DashboardTrustRemainderBeneficiariesStore.on(
      `trustRemainderBeneficiaries.updated.${trustBeneficiarySlug}`,
      this.fetchedTrustRemainderBeneficiaries,
    );
    DashboardTrustRemainderBeneficiariesStore.on(
      `trustRemainderBeneficiaries.create.${trustBeneficiarySlug}`,
      this.resetCreatingItem,
    );
    DashboardTrustRemainderBeneficiariesStore.on(
      `trustRemainderBeneficiaries.update.${trustBeneficiarySlug}`,
      this.resetUpdatingItem,
    );
    DashboardTrustRemainderBeneficiariesStore.on(
      `trustRemainderBeneficiaries.destroy.${trustBeneficiarySlug}`,
      this.resetDestroyingItem,
    );

    DashboardActions.fetchTrustRemainderBeneficiaries(this.props.trustBeneficiary.slug);
  }

  componentWillUnmount() {
    const trustBeneficiarySlug = this.props.trustBeneficiary.slug;
    DashboardTrustRemainderBeneficiariesStore.off(
      `trustRemainderBeneficiaries.fetch.${trustBeneficiarySlug}`,
      this.fetchedTrustRemainderBeneficiaries,
    );
    DashboardTrustRemainderBeneficiariesStore.off(
      `trustRemainderBeneficiaries.updated.${trustBeneficiarySlug}`,
      this.fetchedTrustRemainderBeneficiaries,
    );
    DashboardTrustRemainderBeneficiariesStore.off(
      `trustRemainderBeneficiaries.create.${trustBeneficiarySlug}`,
      this.resetCreatingItem,
    );
    DashboardTrustRemainderBeneficiariesStore.off(
      `trustRemainderBeneficiaries.update.${trustBeneficiarySlug}`,
      this.resetUpdatingItem,
    );
    DashboardTrustRemainderBeneficiariesStore.off(
      `trustRemainderBeneficiaries.destroy.${trustBeneficiarySlug}`,
      this.resetDestroyingItem,
    );
  }

  renderAddTrustRemainderBeneficiaryModal() {
    const { addingItem, editingItem } = this.state;
    if (!addingItem && !editingItem) return;

    const modalProps = {
      title: editingItem ? 'Edit Remainder Beneficiary' : 'Add Remainder Beneficiary',
      handleClose: () => this.setState({ addingItem: false, editingItem: null }),
    };

    return (
      <TrueLinkModal {...modalProps}>
        <DashboardTrustBeneficiaryProfileTrustRemainderBeneficiariesEditForm
          trustBeneficiary={this.props.trustBeneficiary}
          {...(editingItem ? { trustRemainderBeneficiary: editingItem } : {})}
        />
      </TrueLinkModal>
    );
  }

  renderDestroyTrustRemainderBeneficiaryModal() {
    const { destroyingItem } = this.state;
    if (!destroyingItem) return;

    return (
      <TrueLinkModal handleClose={this.resetDestroyingItem} title={'Remove Remainder Beneficiary'}>
        <DashboardTrustBeneficiaryProfileTrustRemainderBeneficiariesDestroyForm
          trustBeneficiary={this.props.trustBeneficiary}
          trustRemainderBeneficiary={destroyingItem}
        />
      </TrueLinkModal>
    );
  }

  renderHeader() {
    const { canEdit, expanded, toggleExpanded } = this.props;

    return (
      <ToggleSectionHeader
        itemCount={this.state.trustRemainderBeneficiaries.length}
        title="Trust Remainder Beneficiaries"
        {...{ expanded, toggleExpanded }}
      >
        {canEdit ? (
          <div className="action-item">
            <button
              className="edit-link btn btn-link"
              onClick={() => this.setState({ addingItem: true })}
              type="button"
            >
              <i className="fa fa-plus-circle" />
              <span className="padding-5l">Add Remainder Beneficiary</span>
            </button>
          </div>
        ) : null}
      </ToggleSectionHeader>
    );
  }

  renderContentTable() {
    const { truncateWithEllipses, formatPhoneNumber } = TlFieldTransformers;
    const tableFieldConfig = {
      name: {
        title: 'Name',
        value: (trustRemainderBeneficiary) => <p>{trustRemainderBeneficiary.name}</p>,
      },
      email: {
        title: 'Email',
        thAttributes: { style: { width: '200px' } },
        value: (trustRemainderBeneficiary) => (
          <p>
            {trustRemainderBeneficiary.email
              ? truncateWithEllipses(trustRemainderBeneficiary.email, 25)
              : ''}
          </p>
        ),
      },
      phone: {
        title: 'Phone',
        value: (trustRemainderBeneficiary) => (
          <p>{formatPhoneNumber(trustRemainderBeneficiary.mobile)}</p>
        ),
      },
      address: {
        title: 'Address',
        value: (trustRemainderBeneficiary) => <p>{trustRemainderBeneficiary.address.street1}</p>,
      },
      city: {
        title: 'City',
        value: (trustRemainderBeneficiary) => <p>{trustRemainderBeneficiary.address.city}</p>,
      },
      state: {
        title: 'State',
        thAttributes: { style: { width: '50px' } },
        value: (trustRemainderBeneficiary) => <p>{trustRemainderBeneficiary.address.state}</p>,
      },
      zip: {
        title: 'Zip',
        thAttributes: { style: { width: '60px' } },
        value: (trustRemainderBeneficiary) => <p>{trustRemainderBeneficiary.address.zip}</p>,
      },
      actionItems: {
        title: '',
        thAttributes: { style: { width: '60px' } },
        value: (trustRemainderBeneficiary) => {
          if (!this.props.canEdit) return null;
          return (
            <div className="action-items">
              <button
                className="action-item btn btn-link edit-item"
                onClick={() => this.setState({ editingItem: trustRemainderBeneficiary })}
                type="button"
              >
                <i className="fa fa-edit" />
              </button>
              <button
                className="action-item btn btn-link danger delete-item"
                onClick={() => this.setState({ destroyingItem: trustRemainderBeneficiary })}
                type="button"
              >
                <i className="fa fa-trash" />
              </button>
            </div>
          );
        },
      },
    };

    return (
      <div className="content-table">
        <BasicTableBuilder
          columnList={['name', 'email', 'phone', 'address', 'city', 'state', 'zip', 'actionItems']}
          itemTypeKey={'trust-remainder-beneficiaries'}
          tableFieldConfig={tableFieldConfig}
          tableItems={this.state.trustRemainderBeneficiaries}
        />
      </div>
    );
  }

  renderContentNoItemsMessage() {
    if (this.state.trustRemainderBeneficiaries.length > 0) return null;
    return (
      <div className="default-content-message">
        <p>No remainder beneficiaries attached.</p>
      </div>
    );
  }

  renderContent() {
    if (!this.props.expanded) return;
    return (
      <div className="toggle-section-content full-width">
        {this.renderContentTable()}
        {this.renderContentNoItemsMessage()}
      </div>
    );
  }

  render() {
    return (
      <div className="toggle-section trust-remainder-beneficiaries">
        {this.renderAddTrustRemainderBeneficiaryModal()}
        {this.renderDestroyTrustRemainderBeneficiaryModal()}
        {this.renderHeader()}
        {this.renderContent()}
      </div>
    );
  }
}

DashboardTrustBeneficiaryProfileTrustRemainderBeneficiaries.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  expanded: PropTypes.bool.isRequired,
  toggleExpanded: PropTypes.func.isRequired,
  trustBeneficiary: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
};
