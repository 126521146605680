import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import MfaSetupWizardStep1 from './MfaSetupWizardStep1';
import MfaSetupWizardStep2 from './MfaSetupWizardStep2';
import PopUp from 'react/shared/components/popups/PopUp';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';

export default function MfaSetupWizard({
  closeModal,
  email,
  enable,
  handleMfaOptionChange,
  mfaDeliveryOption,
  phoneNumber,
}) {
  const [step, setStep] = useState(1);
  const [code, setCode] = useState('');
  const [showError, setShowError] = useState(false);
  const [mfaVerificationId, setMfaVerificationId] = useState();
  const [disabled, setDisabled] = useState(enable);
  const continueBtnRef = useRef(null);

  const { mutate: sendMfaCode } = useMutation({
    mutationFn: () =>
      axios.post(
        RailsRoutes.enable_mfa_verifications_path({
          mfa_option: mfaDeliveryOption,
          format: 'json',
        }),
      ),
    onSuccess: (data) => {
      setMfaVerificationId(data.data.id);
      setStep(2);
      setShowError(false);
    },
  });

  const { mutate: verifyMfaCode } = useMutation({
    mutationFn: () =>
      axios.put(RailsRoutes.enable_mfa_verification_path(mfaVerificationId, { format: 'json' }), {
        code,
        enable,
      }),
    onSuccess: () => {
      closeModal(!enable);
      const mfaEnabledText = enable ? 'on' : 'off';
      Truelink.flash(
        'success',
        `You have successfully turned ${mfaEnabledText} two-step verification!`,
      );
      window.setTimeout(Truelink.flashClear, 3000);
    },
    onError: () => setShowError(true),
  });

  const handleRadioSelection = (_e, val) => {
    setDisabled(false);
    handleMfaOptionChange(val);
  };

  let stepComponent = (
    <MfaSetupWizardStep1
      email={email}
      enable={enable}
      mfaDeliveryOption={mfaDeliveryOption}
      onChange={handleRadioSelection}
      phoneNumber={phoneNumber}
    />
  );
  let next = sendMfaCode;

  if (step > 1) {
    stepComponent = (
      <MfaSetupWizardStep2
        email={email}
        enable={enable}
        mfaDeliveryOption={mfaDeliveryOption}
        phoneNumber={phoneNumber}
        sendMfaCode={sendMfaCode}
        setCode={setCode}
        showError={showError}
      />
    );
    next = verifyMfaCode;

    if (code.length < 6 && !disabled) {
      setDisabled(true);
    } else if (code.length == 6 && disabled) {
      setDisabled(false);
      window.setTimeout(() => continueBtnRef.current?.focus(), 10);
    }
  }

  const close = () => {
    closeModal(enable);
  };

  return (
    <PopUp
      bodyHeight="48vh"
      footer={
        <TrueLinkButton disabled={disabled} onClick={next} ref={continueBtnRef} variant="primary">
          Continue
        </TrueLinkButton>
      }
      header={
        <div style={{ width: '100%', marginLeft: '0%' }}>
          <VerifiedUserOutlinedIcon sx={{ marginRight: '10px', verticalAlign: '-.125em' }} />
          Two-step verification
        </div>
      }
      id="mfa_setup_wizard"
      maxHeight="300px"
      maxWidth="620px"
      onClose={close}
      openModal
    >
      {stepComponent}
    </PopUp>
  );
}

MfaSetupWizard.propTypes = {
  closeModal: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  enable: PropTypes.bool.isRequired,
  handleMfaOptionChange: PropTypes.func.isRequired,
  mfaDeliveryOption: PropTypes.string,
  phoneNumber: PropTypes.string.isRequired,
};
