import PropTypes from 'prop-types';
import React from 'react';
import CardDetails from 'react/member/card/components/account_tab/card_status/CardDetails';
import { CARD_STATUSES } from 'react/member/utils/CardStatus';
import programNameShape from 'react/shared/shapes/account_program_name_shape';
import { possessiverize } from 'react/shared/utils/Strings';

export default function PreRelease({
  cardType,
  cardholderName,
  estimatedArrivalRange,
  formattedArrivalDate,
  oldCardLast4,
  status,
}) {
  const name = possessiverize(cardholderName);
  const note = (
    <>
      <p>Replacement card requested!</p>
      <p>
        {name} current {cardType} Card ending in {oldCardLast4} will remain active. A card with a
        new 16-digit number has been ordered. The current {cardType} Card will be automatically
        closed, and the settings and remaining balance will be transferred to the new card when it
        is activated.
      </p>
      <p>
        Cards typically arrive within {estimatedArrivalRange[0]} to {estimatedArrivalRange[1]}{' '}
        business days. Your card is estimated to arrive at your preferred address by{' '}
        {formattedArrivalDate}.
      </p>
    </>
  );

  return <CardDetails cardLast4={null} expiryDate={null} note={note} status={status} />;
}

PreRelease.propTypes = {
  cardType: programNameShape.isRequired,
  cardholderName: PropTypes.string,
  estimatedArrivalRange: PropTypes.array.isRequired,
  formattedArrivalDate: PropTypes.string.isRequired,
  oldCardLast4: PropTypes.string.isRequired,
  status: PropTypes.oneOf([CARD_STATUSES.PRE_RELEASE_LEGACY_CARD_ORDER_STATUS]).isRequired,
};

PreRelease.defaultProps = {
  cardholderName: 'The cardholder',
};
