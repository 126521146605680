import axios from 'axios';
import { AXIOS_XHR_CONFIG } from 'react/shared/utils/Axios';

export default {
  getAccount(accountSlug) {
    return axios.get(
      RailsRoutes.dashboard_account_path(accountSlug, { format: 'json' }),
      AXIOS_XHR_CONFIG,
    );
  },
};
