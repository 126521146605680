import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import TrueLinkIcon from 'react/shared/components/true_link/main/TrueLinkIcon';
import { grey2 } from 'react/shared/theme/palette';

const useStyles = makeStyles({
  icon: {
    color: grey2,
    marginRight: '10px',
  },
});

export default function AutocompleteOption({ option, isManualEntry }) {
  const classes = useStyles();
  return (
    <Grid alignItems="center" container>
      <Grid item key={isManualEntry}>
        <TrueLinkIcon
          className={classes.icon}
          icon={isManualEntry ? 'keyboard' : 'search-location'}
        />
      </Grid>
      <Grid item xs>
        <Typography color="textSecondary" variant="body2">
          {option.description}
        </Typography>
      </Grid>
    </Grid>
  );
}

AutocompleteOption.propTypes = {
  option: PropTypes.shape({
    description: PropTypes.string.isRequired,
  }).isRequired,
  isManualEntry: PropTypes.bool.isRequired,
};
