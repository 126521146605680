import { ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import TextField from 'react/member/components/signups/signup_form/form_partials/fields/TextField';
import FieldLabel from 'react/member/components/signups/signup_form/form_partials/labels/field_label';

function SsnField({ entity, rowClass, colClass, afterLabel }) {
  return (
    <div className={rowClass}>
      <div className={colClass}>
        <FieldLabel
          afterLabel={afterLabel}
          htmlFor={`${entity}_ssn`}
          labelContent="Social Security Number"
        />
        <TextField
          dataAnalyticsKey="personal_ssn"
          id={`${entity}_ssn`}
          mask="999-99-9999"
          name={`${entity}.ssn`}
        />
        <ErrorMessage className="error" component="label" name={`${entity}.ssn`} />
      </div>
    </div>
  );
}

SsnField.propTypes = {
  entity: PropTypes.oneOf(['administrator']).isRequired,
  rowClass: PropTypes.string,
  colClass: PropTypes.string,
  afterLabel: PropTypes.node,
};

SsnField.defaultProps = {
  rowClass: 'card--form__row',
  colClass: 'card--form__col',
  afterLabel: null,
};

export default SsnField;
