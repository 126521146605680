import PropTypes from 'prop-types';
import React from 'react';
import CardDetails from 'react/member/card/components/account_tab/card_status/CardDetails';
import { CARD_STATUSES } from 'react/member/utils/CardStatus';

export default function Lost({
  cardBalance,
  cardBreached,
  cardExpiryDate,
  cardLast4,
  contactEmail,
  status,
}) {
  const note = `This card has been reported lost or stolen. An email has been sent to ${contactEmail}.`;
  return (
    <CardDetails
      cardBalance={cardBalance}
      cardBreached={cardBreached}
      cardLast4={cardLast4}
      expiryDate={cardExpiryDate}
      note={note}
      status={status}
    />
  );
}

Lost.propTypes = {
  cardBalance: PropTypes.number,
  cardBreached: PropTypes.bool,
  cardExpiryDate: PropTypes.string,
  cardLast4: PropTypes.string.isRequired,
  contactEmail: PropTypes.string.isRequired,
  status: PropTypes.oneOf([CARD_STATUSES.LOST_CARD_STATUS]).isRequired,
};
