import React from 'react';
import addressShape from 'react/shared/shapes/address_shape';

export default function Address({ address }) {
  if (!address || !address.street1) {
    return <span>{'no address on file'}</span>;
  }

  return (
    <>
      <div>{address.street1}</div>
      {address.street2 && <div>{address.street2}</div>}
      <div>{`${address.city}, ${address.state} ${address.zip}`}</div>
    </>
  );
}

Address.propTypes = {
  address: addressShape,
};
