import PropTypes from 'prop-types';
import React from 'react';
import { asMoney } from 'react/shared/utils/Money';
import bindAll from 'react/shared/utils/bind_all';

export default class DetailBalanceInfo extends React.Component {
  static get propTypes() {
    return {
      currentBalance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      effectiveBalance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    };
  }

  constructor(props) {
    super(props);
    bindAll(this);
  }

  renderCurrentBalance() {
    const { currentBalance } = this.props;
    if (currentBalance) {
      return (
        <div className="new-form-field">
          <div className="new-form__label">Account Balance:</div>
          <div className="new-form__data">{asMoney(Number(currentBalance))}</div>
        </div>
      );
    }
  }

  renderEffectiveBalance() {
    const { effectiveBalance } = this.props;
    if (effectiveBalance) {
      return (
        <div className="new-form-field">
          <div className="new-form__label" style={{ width: 265 }}>
            3-month effective balance:
          </div>
          <div className="new-form__data">{asMoney(Number(effectiveBalance))}</div>
        </div>
      );
    }
  }

  render() {
    const { currentBalance, effectiveBalance } = this.props;
    return currentBalance || effectiveBalance ? (
      <div className="new-form__section new-form--compact new-form__section--subheader">
        <div style={{ width: '50%', paddingRight: 20 }}>{this.renderCurrentBalance()}</div>
        <div style={{ width: '50%', paddingLeft: 20 }}>{this.renderEffectiveBalance()}</div>
      </div>
    ) : (
      <div />
    );
  }
}
