import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import CardDetails from 'react/member/card/components/account_tab/card_status/CardDetails';
import CardShipment from 'react/member/card/components/account_tab/card_status/CardShipment';
import { CARD_STATUSES } from 'react/member/utils/CardStatus';
import { possessiverize } from 'react/shared/utils/Strings';
import { programNameFromType } from 'react/shared/utils/account_program_type';

export default function Overdue({
  cardBalance,
  cardBreached,
  cardFboName,
  cardLast4,
  cardName,
  cardShipmentTrackingNumber,
  cardholderName,
  estimatedArrivalDate,
  expedited,
  expiryDate,
  oldCardLast4,
  programType,
  shippingAddress,
  supportPhone,
  status,
}) {
  const name = possessiverize(cardholderName);
  const cardType = programNameFromType(programType);
  const formattedDate = moment(estimatedArrivalDate, 'MM/DD/YYYY').format('MMMM Do, YYYY');
  const note = (
    <>
      <p>
        Your card should have arrived by {formattedDate}. Please call {supportPhone} if the card has
        not yet arrived.
      </p>
      <p>
        This is a replacement card with a new 16-digit number. Activating the card will
        automatically close {name} current {cardType} Card ending in {oldCardLast4}. Upon
        activation, bank account(s), transfer settings, and any remaining balance will be
        transferred from the old card.
      </p>
    </>
  );

  return (
    <>
      <CardDetails
        cardBalance={cardBalance}
        cardBreached={cardBreached}
        cardLast4={cardLast4}
        expiryDate={expiryDate}
        note={note}
        status={status}
      />
      <CardShipment
        cardFboName={cardFboName}
        cardName={cardName}
        cardShipmentTrackingNumber={cardShipmentTrackingNumber}
        expedited={expedited}
        shippingAddress={shippingAddress}
      />
    </>
  );
}

Overdue.propTypes = {
  cardBalance: PropTypes.number,
  cardBreached: PropTypes.bool,
  cardFboName: PropTypes.string,
  cardLast4: PropTypes.string.isRequired,
  cardName: PropTypes.string.isRequired,
  cardShipmentTrackingNumber: PropTypes.string,
  cardholderName: PropTypes.string,
  estimatedArrivalDate: PropTypes.string.isRequired,
  expedited: PropTypes.bool.isRequired,
  expiryDate: PropTypes.string,
  oldCardLast4: PropTypes.string.isRequired,
  programType: PropTypes.oneOf(['en', 'stable', 'able']).isRequired,
  shippingAddress: PropTypes.string,
  supportPhone: PropTypes.string.isRequired,
  status: PropTypes.oneOf([CARD_STATUSES.OVERDUE_RELEASED_LEGACY_CARD_ORDER_STATUS]).isRequired,
};

Overdue.defaultProps = {
  cardholderName: 'The cardholder',
};
