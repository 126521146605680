import PropTypes from 'prop-types';
import { bankAccountShapeLite } from 'react/member/card/shapes/BankAccountShape';
import { holidayShape } from 'react/member/card/shapes/HolidayShape';
import {
  TRANSFER_STATES,
  TRANSFER_STATUSES,
  TRANSFER_TYPES,
} from 'react/member/utils/TransferConstants';

const { bool, number, string, shape, oneOf } = PropTypes;

const holidayImpactedDateShape = shape({
  id: number.isRequired,
  date: string.isRequired,
  guidance: string.isRequired,
  guidanceDeliverDate: string.isRequired,
  holiday: holidayShape.isRequired,
  supposedDeliverDate: string.isRequired,
});

const transferShapeKeys = {
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  autoTransferId: number,
  bankAccount: bankAccountShapeLite.isRequired,
  date: string.isRequired,
  dateUnformatted: string.isRequired,
  deferredCreditDate: string,
  editable: bool.isRequired,
  guidanceAccepted: bool,
  guidanceType: string,
  holidayImpactedDate: holidayImpactedDateShape,
  id: number.isRequired,
  instantCreditable: bool.isRequired,
  instantFundable: bool.isRequired,
  instantFundedBy: string,
  instantOnDashboardEnabled: bool.isRequired,
  isHolidayImpacted: bool,
  landedDate: string,
  landedDateUnformatted: string,
  memo: string,
  revertDate: string,
  status: oneOf(TRANSFER_STATUSES).isRequired,
  state: oneOf(TRANSFER_STATES).isRequired,
  type: oneOf(TRANSFER_TYPES).isRequired,
};

export const transferShape = shape(transferShapeKeys);

export const impactedTransferShape = shape({
  ...transferShapeKeys,
  guidanceDeliverDate: string.isRequired,
  supposedDeliverDate: string.isRequired,
});
