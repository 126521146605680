import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import ReferralIncentives from './ReferralIncentives';
import theme from 'react/shared/theme/Theme';

export default function ReferralIndex(props) {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <ReferralIncentives {...props} />
        </ThemeProvider>
      </StyledEngineProvider>
    </QueryClientProvider>
  );
}
