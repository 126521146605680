import PropTypes from 'prop-types';
import React from 'react';
import _debounce from 'underscore/modules/debounce';
import GenericInlineLabel from './GenericInlineLabel';
import GenericTextInput from 'react/shared/components/forms/GenericTextInput';
import GenericSubmitPopUp from 'react/shared/components/popups/GenericSubmitPopUp';
import genericSubmitPopUpShapeKeys from 'react/shared/shapes/GenericSubmitPopUpShape';
import { underscorize } from 'react/shared/utils/Strings';
import bindAll from 'react/shared/utils/bind_all';

export default class GenericTextInputPopUp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inputValue: '',
      valid: false,
      dirty: false,
    };

    bindAll(this);

    this.debouncedHandleInputChange = _debounce((newValue) => {
      this.setState({
        inputValue: newValue,
        valid: this.handleValidate(newValue),
        dirty: true,
      });
    }, 250);
  }

  componentWillUnmount() {
    this.debouncedHandleInputChange.cancel();
  }

  handleValidate(str) {
    return !!str;
  }

  handleTextInputChange({ target: { value } }) {
    this.debouncedHandleInputChange(value.trim());
  }

  handleValidateOnSubmit() {
    const { inputValue } = this.state;

    const valid = this.handleValidate(inputValue);

    if (valid) {
      this.props.onSubmit(inputValue);
    } else {
      this.setState({ valid: false });
    }
  }

  renderTextInputWithLabel() {
    const { inputLabel } = this.props;

    const inputId = `generic-popup-text-input-${underscorize(inputLabel)}`;

    return (
      <GenericInlineLabel inputId={inputId} labelContent={inputLabel}>
        <GenericTextInput id={inputId} onChange={this.handleTextInputChange} />
      </GenericInlineLabel>
    );
  }

  render() {
    return (
      <GenericSubmitPopUp
        {...this.props}
        disableSubmit={!this.state.valid}
        displayErrorText={!this.state.valid && this.state.dirty}
        messageNode={this.renderTextInputWithLabel()}
        onSubmit={this.handleValidateOnSubmit}
      />
    );
  }
}

GenericTextInputPopUp.propTypes = {
  ...genericSubmitPopUpShapeKeys,
  inputLabel: PropTypes.string.isRequired,
};
