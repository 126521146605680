import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import PALETTE from 'react/shared/theme/palette';

const useStyles = makeStyles({
  primaryText: {
    fontWeight: 700,
  },
  helperText: {
    margin: '10px 0 20px',
    fontSize: '18px',
    color: PALETTE.grey2,
    '& p': {
      marginBottom: '20px',
    },
    '& b': {
      color: PALETTE.black,
    },
  },
});

export default function MultiLineLabel({ primaryText, secondaryText, bold = false, children }) {
  const classes = useStyles();
  return (
    <>
      <Typography className={bold ? classes.primaryText : ''}>{primaryText}</Typography>
      <Typography color="textSecondary" variant="body2">
        {secondaryText}
      </Typography>
      <div className={classes.helperText}>{children}</div>
    </>
  );
}

MultiLineLabel.propTypes = {
  bold: PropTypes.bool,
  primaryText: PropTypes.string.isRequired,
  secondaryText: PropTypes.string.isRequired,
  children: PropTypes.element,
};
