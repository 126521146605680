import PropTypes from 'prop-types';
import addressShape from 'react/shared/shapes/address_shape';

const { string, shape } = PropTypes;

export const cardholderShape = shape({
  address_change_request: addressShape,
  name: string,
  first_name: string,
  last_name: string,
  nickname: string,
  address: addressShape,
  address_as_string: string,
  mobile: string,
  slug: string.isRequired,
});
