import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import theme from 'react/shared/theme/Theme';

const useStyles = makeStyles(() => ({
  selectedBankAccount: {
    width: '450px',
    marginLeft: '10px',
    fontSize: '16px !important',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginRight: '10px',
    },
  },
}));
export default function FeeSetting({
  accountFeeSettings,
  cardPresent,
  cardBalance,
  filteredBankAccounts,
}) {
  const classes = useStyles();
  const [selectedBankAccount, setSelectedBankAccount] = useState('');

  useEffect(() => {
    if (accountFeeSettings?.payment_method === 'CARD') {
      setSelectedBankAccount('CARD');
    } else if (accountFeeSettings?.payment_method === 'ACH') {
      const bankAccount = filteredBankAccounts.find(
        (acc) => acc.id === accountFeeSettings.bank_account_id,
      );
      if (bankAccount) setSelectedBankAccount(bankAccount.id);
    }
  }, [accountFeeSettings, filteredBankAccounts]);

  const handleFormSubmit = async (value) => {
    setSelectedBankAccount(value);
    const path = RailsRoutes.account_info_update_dashboard_account_fee_setting_path(
      accountFeeSettings.id,
    );
    let data;

    if (value === 'CARD') {
      data = {
        payment_method: 'CARD',
      };
    } else {
      data = {
        payment_method: 'ACH',
        bank_account_id: value,
      };
    }
    $.ajax({
      url: path,
      data,
      method: 'PUT',
    }).done(() => {
      Truelink.flash('success', 'Your fee settings have been updated!');
    });
  };

  const balance = cardPresent ? ` with balance ${cardBalance}` : '';

  return (
    <>
      <div className="sub_section_header">Account fee settings</div>
      <div className="fee_settings_section flex-section" style={{ display: 'flex' }}>
        <p>
          <label htmlFor="account_info_update_fee_settings">
            The monthly fee for this card will be deducted directly from
          </label>
        </p>
        <select
          className={classes.selectedBankAccount}
          id="account_info_update_fee_settings"
          name="payment_method"
          onChange={(e) => handleFormSubmit(e.target.value)}
          value={selectedBankAccount}
        >
          <option disabled value="">
            Select One
          </option>
          {filteredBankAccounts.map((bankAccount) => (
            <option key={bankAccount.id} value={bankAccount.id}>
              {`the ${
                bankAccount.nickname || bankAccount.bank_name
              } bank account ending in ${bankAccount.account_number?.slice(-4)}`}
            </option>
          ))}
          <option value="CARD">{`the True Link Card${balance}`}</option>
        </select>
      </div>
    </>
  );
}

FeeSetting.propTypes = {
  accountFeeSettings: PropTypes.any,
  cardPresent: PropTypes.bool,
  cardBalance: PropTypes.string,
  filteredBankAccounts: PropTypes.any,
};
