import withStyles from '@mui/styles/withStyles';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import React from 'react';
import AccountsTabContainer from './accounts/AccountsTabContainer';
import InvestmentsTabContainer from './investments/InvestmentsTabContainer';
import OrganizationReportsTabContainer from './reports/OrganizationReportsTabContainer';
import UserTabContainer from './users/UserTabContainer';
import InProductAdShape from 'react/member/shapes/in_product_ad_shape';
import TabContent from 'react/shared/components/TabContent';
import TabNavigation from 'react/shared/components/TabNavigation';
import localeShape from 'react/shared/shapes/locale_shape';
import PALETTE from 'react/shared/theme/palette';
import sendEvent from 'react/shared/utils/google_analytics';

const styles = () => ({
  investmentsTab: {
    display: 'flex',
  },
  investmentsTabLabel: {
    marginRight: '5px',
    marginBottom: '1px',
  },
  chip: {
    backgroundColor: PALETTE.emerald,
    color: PALETTE.white,
    borderRadius: '2px',
    padding: '2px 5px',
    alignItems: 'center',
    fontSize: '16px',
    marginBottom: '1px',
  },
});

class OrganizationHomeContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      initialTabIndex: 0,
      tabList: [
        { id: 'accounts-tab', title: 'Accounts' },
        { id: 'reports-tab', title: 'Reports' },
      ],
    };

    this.renderTabs = this.renderTabs.bind(this);
  }

  getChildContext() {
    return {
      localeProps: this.props.localeProps,
    };
  }

  componentDidMount() {
    const { classes, reportsTabEnabled, usersTabEnabled, investmentsTabEnabled } = this.props;
    const { tabList } = this.state;

    let newTabList = [...tabList];
    if (!reportsTabEnabled) {
      const tabsWithoutReports = newTabList.filter((tab) => tab.id !== 'reports-tab');
      newTabList = [...tabsWithoutReports];
    }
    if (usersTabEnabled) {
      newTabList.push({ id: 'users-tab', title: 'Users' });
    }
    if (investmentsTabEnabled) {
      sendEvent('page_load', {
        campaign: 'Investment Tab Marketing',
        page: 'Accounts List Page',
        reason: 'Investments tab loaded on page',
        component: 'OrganizationHomeContainer',
      });
      newTabList.push({
        id: 'investments-tab',
        title: (
          <span className={classes.investmentsTab}>
            <p className={classes.investmentsTabLabel}>Investments</p>
            <p className={classes.chip}>NEW</p>
          </span>
        ),
      });
    }

    this.setState({ tabList: newTabList });
  }

  // Necessary to push tab content components to an array because TabNavigation operates based on index of props.children
  renderTabs() {
    const {
      accountsContainerProps,
      reportsTabEnabled,
      reportsContainerProps,
      usersTabEnabled,
      usersContainerProps,
      investmentsTabEnabled,
    } = this.props;

    const tabs = [];
    tabs.push(
      <TabContent id="accounts-tab" key="accounts-tab">
        <AccountsTabContainer {...accountsContainerProps} />
      </TabContent>,
    );
    if (reportsTabEnabled) {
      tabs.push(
        <TabContent id="reports-tab" key="reports-tab">
          <OrganizationReportsTabContainer {...reportsContainerProps} />
        </TabContent>,
      );
    }
    if (usersTabEnabled) {
      tabs.push(
        <TabContent id="users-tab" key="users-tab">
          <UserTabContainer {...usersContainerProps} />
        </TabContent>,
      );
    }
    if (investmentsTabEnabled) {
      tabs.push(
        <TabContent id="investments-tab" key="investments-tab">
          <InvestmentsTabContainer />
        </TabContent>,
      );
    }

    return tabs;
  }

  handleTabChange(tab) {
    if (tab === 'investments-tab') {
      sendEvent('tab_click', {
        campaign: 'Investment Tab Marketing',
        page: 'Accounts List Page',
        reason: 'Investments tab clicked',
        component: 'OrganizationHomeContainer',
      });
    }
  }

  render() {
    const { initialTabIndex, tabList } = this.state;
    const queryClient = new QueryClient();

    return (
      <QueryClientProvider client={queryClient}>
        <TabNavigation
          initialTabIndex={initialTabIndex}
          onTabChange={this.handleTabChange}
          showBackground
          tabs={tabList}
        >
          {this.renderTabs()}
        </TabNavigation>
      </QueryClientProvider>
    );
  }
}

export default withStyles(styles)(OrganizationHomeContainer);

OrganizationHomeContainer.childContextTypes = {
  localeProps: PropTypes.object,
};

OrganizationHomeContainer.propTypes = {
  classes: PropTypes.object,
  reportsTabEnabled: PropTypes.bool.isRequired,
  usersTabEnabled: PropTypes.bool.isRequired,
  investmentsTabEnabled: PropTypes.bool.isRequired,
  accountsContainerProps: PropTypes.shape({
    showAccountId: PropTypes.bool.isRequired,
    showLatestTransfer: PropTypes.bool.isRequired,
    showRefreshButton: PropTypes.bool.isRequired,
    inProductAdForAccountsList: PropTypes.bool.isRequired,
    inProductAd: InProductAdShape,
    accountListMessage: PropTypes.string,
  }),
  reportsContainerProps: PropTypes.shape({
    organizationId: PropTypes.number,
    fundedAccountTransfer: PropTypes.bool.isRequired,
    largeNumberOfAccounts: PropTypes.bool.isRequired,
    clientStatementsFormProps: PropTypes.shape({
      statementMonthsToShow: PropTypes.array.isRequired,
      largeNumberOfAccounts: PropTypes.bool.isRequired,
    }),
  }),
  usersContainerProps: PropTypes.shape({
    isOwner: PropTypes.bool.isRequired,
    owner: PropTypes.object,
    supportEmailAddress: PropTypes.string.isRequired,
    supportPhoneNumber: PropTypes.string.isRequired,
    users: PropTypes.array.isRequired,
  }),
  localeProps: PropTypes.shape({
    able: localeShape.isRequired,
    en: localeShape.isRequired,
    stable: localeShape.isRequired,
  }),
};
