import PropTypes from 'prop-types';

const { bool, number, string, shape } = PropTypes;

export const bankAccountShapeLite = shape({
  accountNumber: string.isRequired,
  nickname: string,
});

export const bankAccountShape = shape({
  accountNumber: string.isRequired,
  allowMicrodepositVerification: bool,
  bankInfo: string.isRequired,
  familyFundingAccess: bool.isRequired,
  funderId: number,
  id: number.isRequired,
  isActive: bool.isRequired,
  isVerified: bool.isRequired,
  name: string.isRequired,
  nickname: string,
});

// TODO: CARD-2690
export const bankAccountShapeSnake = shape({
  account_number: string.isRequired,
  allow_microdeposit_verification: bool,
  bank_info: string.isRequired,
  family_funding_access: bool.isRequired,
  funder_id: number,
  id: number.isRequired,
  is_active: bool.isRequired,
  is_verified: bool.isRequired,
  name: string.isRequired,
  nickname: string,
});
