import RemoveCircleRounded from '@mui/icons-material/RemoveCircleRounded';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import theme from 'react/shared/theme/Theme';
import PALETTE from 'react/shared/theme/palette';

const useStyles = makeStyles(() => ({
  notification: {
    width: '100%',
    outline: `1px solid ${PALETTE.grey4}`,
    borderRadius: '10px',
    boxShadow: ' 0px 4px 14px 0px rgba(0, 0, 0, 0.15)',

    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
  },
  title: {
    color: PALETTE.redDark,
    '& h4': {
      fontWeight: '400',
    },
  },
  subTitle: {
    textAlign: 'left',
  },
  actionBtn: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));

export default function TrueLinkNotification({ title, callToAction, callToActionText, subTitle }) {
  const classes = useStyles();

  return (
    <Card className={classes.notification}>
      <CardContent>
        <Grid alignItems="center" container gap={4} justifyContent="space-between">
          <Grid alignItems="flex-start" container gap={1} item sm={8} xs={12}>
            <Grid alignItems="center" className={classes.title} container>
              <RemoveCircleRounded style={{ fill: PALETTE.redDark }} />
              &nbsp;&nbsp;
              <Typography variant="h4"> {title}</Typography>
            </Grid>
            <Typography className={classes.subTitle} variant="body2">
              {subTitle}
            </Typography>
          </Grid>
          <Grid item sm={'auto'} xs={12}>
            <TrueLinkButton
              className={classes.actionBtn}
              onClick={callToAction}
              size="large"
              variant="primary"
            >
              {callToActionText}
            </TrueLinkButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

TrueLinkNotification.propTypes = {
  callToAction: PropTypes.func,
  callToActionText: PropTypes.string,
  subTitle: PropTypes.string,
  title: PropTypes.string,
};
