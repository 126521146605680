import PropTypes from 'prop-types';
import React from 'react';
import SpendingSettingsSdkContainer from 'react/member/card/components/spending_monitor_tab/SpendingSettingsSdkContainer';

export default function CardInInvestSpendingMonitorTabContainer({ accountId, canEdit }) {
  return <SpendingSettingsSdkContainer accountId={accountId} canEdit={canEdit} />;
}

CardInInvestSpendingMonitorTabContainer.propTypes = {
  accountId: PropTypes.string.isRequired,
  canEdit: PropTypes.bool.isRequired,
};
