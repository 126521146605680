import axios from 'axios';
import _find from 'underscore/modules/find';
import DashboardActions from 'react/member/actions/dashboard_actions';
import { AXIOS_XHR_CONFIG } from 'react/shared/utils/Axios';

const DashboardPositionStore = flux.createStore({
  positions: [],
  actions: [
    DashboardActions.fetchPositionsByTrust,
    DashboardActions.fetchPositionsByOrganization,
    DashboardActions.fetchPositionsByClient,
    DashboardActions.fetchPositionsByPortfolio,
  ],

  fetchPositionsByTrust(trustSlug) {
    $.get(
      RailsRoutes.dashboard_trust_positions_path(trustSlug, { format: 'json' }),
      (positions) => {
        this.positions = positions;
        this.emit('positions.fetch');
      },
    );
  },

  fetchPositionsByOrganization(organization_id) {
    $.get(
      RailsRoutes.dashboard_organization_positions_path(organization_id, {
        format: 'json',
      }),
      (positions) => {
        this.positions = positions;
        this.emit('positions.fetch');
      },
    );
  },

  fetchPositionsByClient(client_slug) {
    axios
      .get(
        RailsRoutes.dashboard_client_positions_path(client_slug, { format: 'json' }),
        AXIOS_XHR_CONFIG,
      )
      .then(({ data: positions }) => {
        this.positions = positions;
        this.emit('positions.fetchByClient');
      })
      .catch(() => {
        // Do nothing other than let the interceptor handle it
      });
  },

  fetchPositionsByPortfolio(portfolio_slug) {
    $.get(
      RailsRoutes.dashboard_portfolio_positions_path(portfolio_slug, {
        format: 'json',
      }),
      (positions) => {
        this.positions = positions;
        // We need to start doing these emits properly
        this.emit('positions.fetch', this.positions);
      },
    );
  },

  exports: {
    get(id) {
      return _find(this.positions, (position) => position.id === id);
    },

    getPositions() {
      return this.positions;
    },
  },
});

export default DashboardPositionStore;
