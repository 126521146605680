import PropTypes from 'prop-types';
import React, { useState } from 'react';
import CategorizedBudgetCategory from './CategorizedBudgetCategory';
import CategorizedBudgetCategoryModal from './CategorizedBudgetCategoryModal';
import CategorizedBudgetDollarsUsed from './CategorizedBudgetDollarsUsed';

export default function CategorizedBudgetBreakdown({ trustBeneficiary, timelineId, categories }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMonthlyModal, setIsMonthlyModal] = useState(false);

  const toggleBudgetModal = (e, opts) => {
    e && e.preventDefault();

    if (!timelineId) {
      window.Truelink.flash('error', 'Please create a budget timeline before adding categories.');
    } else {
      setIsModalOpen(!isModalOpen);
      setIsMonthlyModal(opts.isMonthly);
    }
  };

  const closeBudgetModal = () => {
    setIsModalOpen(false);
  };

  const renderBudgetCategories = (budgetCategories, opts = {}) => {
    if (budgetCategories.length > 0) {
      return budgetCategories.map((category) => (
        <CategorizedBudgetCategory
          budgetCategory={category}
          isMonthly={opts.isMonthly}
          key={category.id}
          timelineId={timelineId}
          trustBeneficiary={trustBeneficiary}
        />
      ));
    }
    const timeIncrement = opts.isMonthly ? 'monthly' : 'annual';
    return (
      <div className="budget__category">
        <div className="budget__empty_categories">No {timeIncrement} budget categories</div>
      </div>
    );
  };

  const renderOtherRow = (otherSpend) => (
    <div>
      <span>Other:</span>
      <div className="fright">
        <CategorizedBudgetDollarsUsed available={0} used={otherSpend} />
      </div>
    </div>
  );

  const { annual, annualOtherSpend, monthly, monthlyOtherSpend, monthlyX12 } = categories;
  return (
    <div className="budget__breakdown-wrapper">
      <div className="budget__breakdown">
        <div className="budget__breakdown-header">
          <a href="#" onClick={(e) => toggleBudgetModal(e, { isMonthly: true })}>
            + Add monthly budget category
          </a>
          <div className="budget__breakdown-header-title">Budget categories</div>
          <a href="#" onClick={(e) => toggleBudgetModal(e, { isMonthly: false })}>
            + Add annual budget category
          </a>
        </div>
        {isModalOpen && (
          <CategorizedBudgetCategoryModal
            isMonthly={isMonthlyModal}
            onClose={closeBudgetModal}
            timelineId={timelineId}
            trustBeneficiary={trustBeneficiary}
          />
        )}
        <div className="budget__column budget__column--left">
          {renderBudgetCategories(monthly, { isMonthly: true })}
        </div>
        <div className="budget__column budget__column--right">
          {renderBudgetCategories(annual, { isMonthly: false })}
          <CategorizedBudgetCategory budgetCategory={monthlyX12} isMonthly12x />
        </div>
      </div>
      <div className="budget__breakdown-footer">
        <div className="budget__column budget__column--left">
          {renderOtherRow(monthlyOtherSpend)}
        </div>
        <div className="budget__column budget__column--right">
          {renderOtherRow(annualOtherSpend)}
        </div>
      </div>
    </div>
  );
}

CategorizedBudgetBreakdown.propTypes = {
  categories: PropTypes.shape({
    monthly: PropTypes.array,
    annual: PropTypes.array,
    monthlyOtherSpend: PropTypes.number,
    annualOtherSpend: PropTypes.number,
    monthlyX12: PropTypes.shape({
      available: PropTypes.number,
      name: PropTypes.string,
      percentComplete: PropTypes.number,
      used: PropTypes.number,
    }),
  }),
  trustBeneficiary: PropTypes.shape({
    id: PropTypes.number,
    person: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  timelineId: PropTypes.number,
};
