import PropTypes from 'prop-types';
import React from 'react';
import _contains from 'underscore/modules/contains';
import _without from 'underscore/modules/without';
import NavigationButtons from './navigation_buttons';
import { States } from './states';
import bindAll from 'react/shared/utils/bind_all';

export default class Duties extends React.Component {
  constructor(props) {
    super(props);

    const { duties, otherDuty } = this.props;
    this.state = {
      selectedCheckboxes: duties,
      textOption: otherDuty,
      error: undefined,
      textOptionError: undefined,
    };

    bindAll(this);
  }

  transitionTo(ev) {
    ev.preventDefault();
    const { selectedCheckboxes, textOption } = this.state;

    if (selectedCheckboxes.length === 0) {
      this.props.ensureOption(this, 'Please select at least one option.');
    } else if (selectedCheckboxes.includes('other') && !textOption) {
      this.setState({ textOptionError: 'This field is required.' });
    } else {
      this.props.transition(States.PROFESSIONAL.FINAL, {
        duties: selectedCheckboxes,
        otherDuty: textOption,
      });
    }
  }

  transitionBackTo(ev) {
    ev.preventDefault();
    this.props.transition(States.PROFESSIONAL.FIRST);
  }

  handleCheckboxChange(ev) {
    const { target } = ev;

    if (target.checked) {
      this.setState((prevState) => ({
        selectedCheckboxes: [...prevState.selectedCheckboxes, target.value],
        error: undefined,
      }));
    } else if (target.value === 'other') {
      this.setState((prevState) => ({
        selectedCheckboxes: _without(prevState.selectedCheckboxes, target.value),
        textOption: '',
        error: undefined,
        textOptionError: undefined,
      }));
    } else {
      this.setState((prevState) => ({
        selectedCheckboxes: _without(prevState.selectedCheckboxes, target.value),
        error: undefined,
      }));
    }
  }

  handleTextChange(ev) {
    this.setState({ textOption: ev.target.value, textOptionError: undefined });
  }

  renderCheckbox(selectedCheckboxes, label, text) {
    const id = `${label}-checkbox`;

    return (
      <div className="questionnaire-radio">
        <input
          checked={_contains(selectedCheckboxes, label)}
          className="questionnaire-radio__input"
          id={id}
          onChange={this.handleCheckboxChange}
          type="checkbox"
          value={label}
        />
        <label className="questionnaire-radio__label" htmlFor={id}>
          <span className="questionnaire-radio__checkbox-container questionnaire-radio__checkbox-container--checkbox" />
          {text}
        </label>
      </div>
    );
  }

  render() {
    const { selectedCheckboxes, textOption, error, textOptionError } = this.state;

    return (
      <div>
        <div className="questionnaire-form-item questionnaire-form-item__large">
          <form onSubmit={this.transitionTo}>
            <div className="questionnaire-form-item-content">
              <div className="questionnaire-form-item-question">
                <h2>
                  Our customers often act in more than one professional capacity. Please select all
                  the roles that apply:
                </h2>
              </div>
              <div className="hide-on-mobile">{error && <p className="form-error">{error}</p>}</div>
              <div className="questionnaire-radio-wrapper questionnaire-radio-wrapper--checkbox questionnaire-radio-button-list--professional">
                {this.renderCheckbox(selectedCheckboxes, 'public-guardian', 'Public guardian')}
                {this.renderCheckbox(selectedCheckboxes, 'guardian', 'Guardian')}
                {this.renderCheckbox(selectedCheckboxes, 'fiduciary', 'Fiduciary')}
                {this.renderCheckbox(selectedCheckboxes, 'rep-payee', 'Representative payee')}
                {this.renderCheckbox(selectedCheckboxes, 'pooled-trust', 'Pooled trust')}
                {this.renderCheckbox(selectedCheckboxes, 'attorney', 'Attorney')}
                {this.renderCheckbox(
                  selectedCheckboxes,
                  'trust-company-or-bank',
                  'Trust company or bank',
                )}
                <div className="questionnaire-radio">
                  <input
                    checked={_contains(selectedCheckboxes, 'other')}
                    className="questionnaire-radio__input"
                    id="other-checkbox"
                    onChange={this.handleCheckboxChange}
                    type="checkbox"
                    value="other"
                  />
                  <label className="questionnaire-radio__label" htmlFor="other-checkbox">
                    <span className="questionnaire-radio__checkbox-container questionnaire-radio__checkbox-container--checkbox" />
                    <span className="questionnaire-radio__text">Other:</span>
                    <br />
                    <input
                      className="questionnaire-radio__label-input"
                      id="other-text"
                      onChange={this.handleTextChange}
                      type="text"
                      value={textOption}
                    />
                    {textOptionError && <p className="form-error">{textOptionError}</p>}
                  </label>
                </div>
              </div>
            </div>
            <div className="show-on-mobile">{error && <p className="form-error">{error}</p>}</div>
            <NavigationButtons transitionBackTo={this.transitionBackTo} />
          </form>
        </div>
      </div>
    );
  }
}

Duties.propTypes = {
  duties: PropTypes.arrayOf(PropTypes.string),
  otherDuty: PropTypes.string,
  ensureOption: PropTypes.func.isRequired,
  transition: PropTypes.func.isRequired,
};
