import pluralize from 'pluralize';
import _map from 'underscore/modules/map';
import _reduce from 'underscore/modules/reduce';
import FieldDefaultLayout from './FieldDefaultLayout';
import { capitalizeWords, humanize, underscorize } from 'react/shared/utils/Strings';

const FieldMixin = {
  defaultLayout: FieldDefaultLayout,

  initField() {
    this.addField();
    return this.set(this.initialValue());
  },

  addField() {
    if (this.controller()) {
      this.controller().addField(this.attributeName(), this);
    }
  },

  layout() {
    return this.props.layout || this.defaultLayout;
  },

  controller() {
    return this.props.controller;
  },

  readOnly() {
    return this.props.readOnly;
  },

  editable() {
    if (this.readOnly()) {
      return false;
    } else if (this.props.editable != undefined) {
      return this.props.editable;
    }
    return this.controller().editable();
  },

  is_association() {
    return this.props.association;
  },

  allows_multiple() {
    // `fakeMultiple` means that the underlying model supports multiple values for this field,
    // but our UI allows the user to select only one value when in edit mode.
    // This is needed because we name multi-value inputs differently from single-value inputs.
    // The `fakeMultiple` prop allows us to mix the behaviors of single-value and multi-value inputs.
    return this.props.multiple || this.props.fakeMultiple;
  },

  keyup(evt) {
    if (evt.keyCode == 13) {
      evt.preventDefault();
      evt.stopPropagation();
      this.controller().toggleEdit();
    }
  },

  label() {
    let result =
      this.props.label != undefined ? this.props.label : capitalizeWords(humanize(this.name()));

    if (this.props.required) {
      result += '*';
    }

    return result;
  },

  name() {
    return this.props.name;
  },

  formattedValue() {
    return this.format()(this.value());
  },

  value() {
    if (this.state && this.state.value != undefined) {
      return this.state.value;
    }

    return this.initialValue();
  },

  get() {
    return this.state && this.state.value;
  },

  set(value) {
    this.setState({ value });
  },

  initialValue() {
    // Ideally this would be tighter controlled, but it is legacy
    // eslint-disable-next-line no-eq-null
    if (this.props.value != undefined && this.props.value != null) {
      return this.props.value;
    }
    const v = _reduce(
      this.attributeName().split('.'),
      (current, attribute) => (current ? current[attribute] : ''),
      this.controller().object(),
    );

    if (this.props.allowFalse) {
      return v;
    }

    return v || '';
  },

  inputName(_fieldName) {
    if (this.props.inputName) {
      return this.props.inputName;
    }

    const attributeName = _fieldName != undefined ? _fieldName : this.fieldName();
    let fieldName;
    if (this.is_association()) {
      if (this.allows_multiple()) {
        fieldName = `${pluralize(attributeName, 1)}_ids`;
      } else {
        fieldName = `${attributeName}_id`;
      }
    } else if (this.allows_multiple()) {
      fieldName = pluralize(attributeName);
    } else {
      fieldName = attributeName;
    }

    let inputName;
    if (this.model() != undefined) {
      inputName =
        underscorize(this.model()) +
        _map(fieldName.replace('.', '_attributes.').split('.'), (part) => `[${part}]`).join('');
    } else {
      inputName = fieldName;
    }

    return inputName;
  },

  attributeName() {
    if (this.props.attributeName != undefined) {
      if (this.allows_multiple()) {
        return pluralize(this.props.attributeName);
      }
      return this.props.attributeName;
    }
    return this.name();
  },

  fieldName() {
    if (this.props.fieldName != undefined) {
      return this.props.fieldName;
    }
    return this.name();
  },

  format() {
    if (this.props.format != undefined) {
      return this.props.format;
    }
    return (a) => a;
  },

  object() {
    return this.controller().object();
  },

  model() {
    return this.controller().model();
  },

  onKeyUp(evt) {
    if (evt.keyCode == 13) {
      return this.controller().toggleEdit();
    }
  },

  onChange(evt) {
    const value = evt.target.value;
    this.change(value);
  },

  change(value) {
    const name = this.name();
    this.controller().set(name, value);
    this.setState({ value });
  },
};

export default FieldMixin;
