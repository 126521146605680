import PropTypes from 'prop-types';
import React, { useEffect, useCallback, useState } from 'react';
import DashboardActions from 'react/member/actions/dashboard_actions';
import DashboardDraftDisbursementStore from 'react/member/stores/DashboardDraftDisbursementStore';
import TlAreYouSureModal from 'react/shared/components/TlAreYouSureModal';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';

export default function DisbursementButtons({ onResumeDraft, onDeleteDraft, id }) {
  const [deletingDraft, setDeletingDraft] = useState(false);
  const handleResumeClick = useCallback(() => {
    onResumeDraft(id);
  }, [onResumeDraft, id]);

  const handleDraftDeleteSuccess = useCallback(() => {
    Truelink.flash('success', 'Draft Successfully Deleted.');
    setDeletingDraft(false);
    onDeleteDraft();
  }, [onDeleteDraft]);

  useEffect(() => {
    DashboardDraftDisbursementStore.on('draftDisbursement.delete', handleDraftDeleteSuccess);

    return function cleanup() {
      DashboardDraftDisbursementStore.off('draftDisbursement.delete', handleDraftDeleteSuccess);
    };
  }, [handleDraftDeleteSuccess]);

  const deleteDraftDisbursement = () => {
    DashboardActions.deleteDraftDisbursement(id);
  };

  const closeDraftConfirmDeleteModal = () => {
    setDeletingDraft(false);
  };

  const openDraftConfirmDeleteModal = () => {
    setDeletingDraft(true);
  };

  return (
    <>
      {deletingDraft && (
        <TlAreYouSureModal
          buttonClass="btn-success"
          handleClose={closeDraftConfirmDeleteModal}
          headerText="Delete Draft"
          messageText="Are you sure you want to delete this draft?"
          onSubmit={deleteDraftDisbursement}
          submitText="Submit"
        />
      )}
      <TrueLinkButton onClick={handleResumeClick} size="small" variant="primary">
        Resume
      </TrueLinkButton>
      <TrueLinkButton onClick={openDraftConfirmDeleteModal} size="small" variant="error">
        Delete
      </TrueLinkButton>
    </>
  );
}

DisbursementButtons.propTypes = {
  onDeleteDraft: PropTypes.func.isRequired,
  onResumeDraft: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
};
