import classNames from 'classnames';
import { ErrorMessage, Form, Field, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { trustBeneficiaryShape } from './CoreInfoShape';
import useDisabilityDesignations from './useDisabilityDesignationIndexQuery';
import DashboardActions from 'react/member/actions/dashboard_actions';
import FormDatepickerField from 'react/shared/components/forms/form_datepicker_field';
import FormSelectField from 'react/shared/components/forms/form_select_field';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';

export default function DashboardTrustBeneficiaryProfileCoreInfoForm({ trustBeneficiary }) {
  const initialValues = () => ({
    person: {
      dob: trustBeneficiary.person.dob || '',
      gender: trustBeneficiary.person.gender || '',
      livingSituation: trustBeneficiary.person.livingSituation || '',
      maritalStatus: trustBeneficiary.person.maritalStatus || '',
    },
    settlement: trustBeneficiary.settlement || false,
    externalId: trustBeneficiary.externalId || '',
    beneficiaryId: trustBeneficiary.beneficiaryId || '',
    welcomeLetterSigned: !!trustBeneficiary.welcomeLetterSignedAt,
    disbursementCreationEnabled: !trustBeneficiary.disallowDisbursementRequests,
    soleTrustee: trustBeneficiary.soleTrustee || false,
    investmentDirective: trustBeneficiary.investmentDirective || false,
    overdrawAllowed: trustBeneficiary.overdrawAllowed || false,
    disabilityDesignationId: trustBeneficiary.disabilityDesignation?.id?.toString() || '',
  });

  const { data: disabilityDesignationsData, isLoading } = useDisabilityDesignations();
  const [disabilityDesignationOptions, setDisabilityDesignationOptions] = useState([]);

  useEffect(() => {
    if (!isLoading) {
      const formattedDisabilityDesignations = disabilityDesignationsData.map((designation) => ({
        value: designation.id,
        label: designation.attributes.name,
      }));

      setDisabilityDesignationOptions([
        { value: '', label: 'None' },
        ...formattedDisabilityDesignations,
      ]);
    }
  }, [isLoading, disabilityDesignationsData]);

  const validationSchema = () =>
    Yup.object().shape({
      person: Yup.object().shape({
        dob: Yup.date().typeError('Date of birth is required'),
      }),
      externalId: Yup.string().max(30, 'must be less than 30 characters'),
      beneficiaryId: Yup.string().max(30, 'must be less than 30 characters'),
    });

  const onSubmit = (values, { setSubmitting }) => {
    const onRequestCompleted = () => {
      setSubmitting(false);
      if (
        values.disallowDisbursementRequests != trustBeneficiary.disallowDisbursementRequests ||
        values.overdrawAllowed != trustBeneficiary.overdrawAllowed
      ) {
        window.location.reload();
      }
    };
    values.disallowDisbursementRequests = !values.disbursementCreationEnabled;
    DashboardActions.updateTrustBeneficiaryData(trustBeneficiary.slug, values, onRequestCompleted);
  };

  const genderOptions = () => {
    const none = { value: '', label: 'None' };
    const genderOptions = $tlf.domains.genderOptions.map((gender) => ({
      value: gender,
      label: gender,
    }));
    genderOptions.unshift(none);

    return genderOptions;
  };

  const livingSituationOptions = () => {
    const none = { value: '', label: 'None' };
    const livingSituationOptions = $tlf.domains.livingSituationOptions.map((livingSituation) => ({
      value: livingSituation,
      label: livingSituation,
    }));
    livingSituationOptions.unshift(none);

    return livingSituationOptions;
  };

  const maritalStatusOptions = () => {
    const none = { value: '', label: 'None' };
    const maritalStatusOptions = $tlf.domains.maritalStatusOptions.map((maritalStatus) => ({
      value: maritalStatus,
      label: maritalStatus,
    }));
    maritalStatusOptions.unshift(none);

    return maritalStatusOptions;
  };

  const renderForm = ({ isSubmitting, isValid, values }) => (
    <Form>
      <div className="form-content-edit tl-form-edit align-left">
        <div className="tl-fields-row">
          <div className="tl-field span6">
            <label className="tl-field-label" htmlFor="bene-core-info-dob-field">
              DOB
            </label>
            <div className="tl-field-data">
              <Field
                component={FormDatepickerField}
                datepickerProps={{ id: 'bene-core-info-dob-field', maxDate: new Date() }}
                name="person.dob"
              />
              <ErrorMessage name="person.dob">
                {(msg) => <div className="tl-field-error">{msg}</div>}
              </ErrorMessage>
            </div>
          </div>
          <div className="tl-field span6">
            <label className="tl-field-label" htmlFor="bene-core-info-gender-field">
              Gender
            </label>
            <div className="tl-field-data">
              <Field
                component={FormSelectField}
                name="person.gender"
                options={genderOptions()}
                reactSelectProps={{ id: 'bene-core-info-gender-field' }}
              />
            </div>
          </div>
        </div>
        <div className="tl-fields-row">
          <div className="tl-field span6">
            <label className="tl-field-label" htmlFor="externalId">
              External ID
            </label>
            <div className="tl-field-data">
              <Field id="externalId" name="externalId" type="text" />
            </div>
            <ErrorMessage name="externalId">
              {(msg) => <div className="tl-field-error">{msg}</div>}
            </ErrorMessage>
          </div>
          <div className="tl-field span6">
            <label className="tl-field-label" htmlFor="beneficiaryId">
              Beneficiary ID
            </label>
            <div className="tl-field-data">
              <Field id="beneficiaryId" name="beneficiaryId" type="text" />
            </div>
            <ErrorMessage name="beneficiaryId">
              {(msg) => <div className="tl-field-error">{msg}</div>}
            </ErrorMessage>
          </div>
        </div>
        <div className="tl-fields-row" style={{ marginTop: '1em' }}>
          <div className="tl-field span6">
            <label className="tl-field-label" htmlFor="bene-core-info-living-situation-field">
              Living Situation
            </label>
            <div className="tl-field-data">
              <Field
                component={FormSelectField}
                name="person.livingSituation"
                options={livingSituationOptions()}
                reactSelectProps={{ id: 'bene-core-info-living-situation-field' }}
              />
            </div>
          </div>
          <div className="tl-field span6">
            <label className="tl-field-label" htmlFor="bene-core-info-marital-status-field">
              Marital Status
            </label>
            <div className="tl-field-data">
              <Field
                component={FormSelectField}
                name="person.maritalStatus"
                options={maritalStatusOptions()}
                reactSelectProps={{ id: 'bene-core-info-marital-status-field' }}
              />
            </div>
          </div>
        </div>
        <div className="tl-fields-row" style={{ marginTop: '1em' }}>
          <div className="tl-field span6">
            <label className="tl-field-label" htmlFor="bene-core-info-disability-designation-field">
              Disability Designation
            </label>
            <div className="tl-field-data">
              <Field
                component={FormSelectField}
                name="disabilityDesignationId"
                options={disabilityDesignationOptions}
                reactSelectProps={{
                  id: 'bene-core-info-disability-designation-field',
                  maxMenuHeight: '200px',
                }}
              />
            </div>
          </div>
          <div className="tl-field span6" />
        </div>
        <div className="tl-fields-row" style={{ marginTop: '1em' }}>
          <div className="info-column">
            <div className="tl-field">
              <label className="tl-field-label inline-block" htmlFor="welcomeLetterSigned">
                <span className="tl-checkbox-input">
                  <Field
                    checked={values.welcomeLetterSigned}
                    component="input"
                    id="welcomeLetterSigned"
                    name="welcomeLetterSigned"
                    type="checkbox"
                    {...{ disabled: initialValues().welcomeLetterSigned }}
                  />
                </span>
                Welcome Letter Signed
              </label>
            </div>
            <div className="tl-field span6">
              <label className="tl-field-label inline-block" htmlFor="settlement">
                <span className="tl-checkbox-input">
                  <Field
                    checked={values.settlement}
                    component="input"
                    id="settlement"
                    name="settlement"
                    type="checkbox"
                  />
                </span>
                Settlement
              </label>
            </div>
            <div className="tl-field span6">
              <label className="tl-field-label inline-block" htmlFor="disbursementCreationEnabled">
                <span className="tl-checkbox-input">
                  <Field
                    checked={values.disbursementCreationEnabled}
                    component="input"
                    id="disbursementCreationEnabled"
                    name="disbursementCreationEnabled"
                    type="checkbox"
                  />
                </span>
                Disbursement Creation Enabled
              </label>
            </div>
            {trustBeneficiary.trust.organization.showSoleTrusteeFeatures && (
              <div className="tl-field span6">
                <label className="tl-field-label inline-block" htmlFor="soleTrustee">
                  <span className="tl-checkbox-input">
                    <Field
                      checked={values.soleTrustee}
                      component="input"
                      id="soleTrustee"
                      name="soleTrustee"
                      type="checkbox"
                    />
                  </span>
                  Sole Trustee
                </label>
              </div>
            )}

            {trustBeneficiary.trust.organization.showInvestmentDirectiveFeatures && (
              <div className="tl-field span6">
                <label className="tl-field-label inline-block" htmlFor="investmentDirective">
                  <span className="tl-checkbox-input">
                    <Field
                      checked={values.investmentDirective}
                      component="input"
                      id="investmentDirective"
                      name="investmentDirective"
                      type="checkbox"
                    />
                  </span>
                  Investment Directive
                </label>
              </div>
            )}

            {trustBeneficiary.trust.organization.ableToOverdraw && (
              <div className="tl-field span6">
                <label className="tl-field-label inline-block" htmlFor="overdrawAllowed">
                  <span className="tl-checkbox-input">
                    <Field
                      checked={values.overdrawAllowed}
                      component="input"
                      id="overdrawAllowed"
                      name="overdrawAllowed"
                      type="checkbox"
                    />
                  </span>
                  Overdraw Allowed
                </label>
              </div>
            )}
          </div>
        </div>
        <div className="tl-form-actions-row">
          <TrueLinkButton
            className={classNames('btn btn-success', {
              disabled: !isValid || isSubmitting,
            })}
            disabled={!isValid || isSubmitting}
            type="submit"
            variant="none"
          >
            Update
          </TrueLinkButton>
        </div>
      </div>
    </Form>
  );

  return (
    <Formik
      component={renderForm}
      initialValues={initialValues()}
      isInitialValid
      onSubmit={onSubmit}
      validationSchema={validationSchema()}
    />
  );
}

DashboardTrustBeneficiaryProfileCoreInfoForm.propTypes = {
  trustBeneficiary: trustBeneficiaryShape.isRequired,
};
