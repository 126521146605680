import moment from 'moment-timezone';
import React from 'react';
import tlFieldTransformers from 'react/shared/utils/tl_field_transformers';

const depositsTableConfig = (depositType, editFunc, userCanEdit) => {
  const tableFields = {
    status: {
      title: 'Status',
      attribute: 'status',
      // The way the columns were created here is done in an awkward way that needs a
      // tie back to this component but is not doing it in a react way so we cannot
      // pass it via props.
      // eslint-disable-next-line react/no-multi-comp
      value: (deposit) => <p>{deposit.status}</p>,
    },
    paymentMethod: {
      title: 'Type',
      attribute: 'payment_method',
      // eslint-disable-next-line react/no-multi-comp
      value: (deposit) => <p>{deposit.payment_method}</p>,
    },
    paymentId: {
      title: 'Payment ID',
      attribute: 'payment_id',
      // eslint-disable-next-line react/no-multi-comp
      value: (deposit) => <p>{deposit.payment_id}</p>,
    },
    trustBeneficiary: {
      title: 'Trust Beneficiary',
      attribute: 'trust_beneficiary',
      // eslint-disable-next-line react/no-multi-comp
      value: (deposit) => <p>{deposit.trust_beneficiary}</p>,
    },
    portfolio: {
      title: 'Portfolio',
      attribute: 'portfolio',
      // eslint-disable-next-line react/no-multi-comp
      value: (deposit) => <p>{deposit.portfolio}</p>,
    },
    amount: {
      title: 'Amount',
      attribute: 'amount',
      // eslint-disable-next-line react/no-multi-comp
      value: (deposit) => <p>{tlFieldTransformers.formatMoney(deposit.amount)}</p>,
    },
    expectedAmount: {
      title: 'Expected Amount',
      attribute: 'expected_amount',
      // eslint-disable-next-line react/no-multi-comp
      value: (deposit) => <p>{tlFieldTransformers.formatMoney(deposit.expected_amount)}</p>,
    },
    cancelDate: {
      title: 'Cancel date',
      attribute: 'cancelled_at',
      // eslint-disable-next-line react/no-multi-comp
      value: (deposit) => {
        if (deposit.cancelled_at) {
          return <p>{moment(deposit.cancelled_at).format('MM/DD/YYYY')}</p>;
        }
        return <p />;
      },
    },
    edit: {
      title: <span>&nbsp;</span>,
      attribute: null,
      // eslint-disable-next-line react/no-multi-comp
      value: (deposit) => (
        <p>
          {/* This should be seen as a pretty out of the ordinary way to call it */}
          {/* eslint-disable-next-line no-invalid-this */}
          <button className="btn normal" onClick={editFunc.bind(this, deposit)} type="button">
            {deposit.read_only || !userCanEdit ? 'View' : 'Edit'}
          </button>
        </p>
      ),
    },
  };
  const oneTimeTableFields = {
    deliveryDate: {
      title: 'Delivery Date',
      attribute: 'delivery_date',
      // eslint-disable-next-line react/no-multi-comp
      value: (deposit) => {
        if (deposit.delivery_date) {
          return <p>{moment(deposit.delivery_date).format('MM/DD/YYYY')}</p>;
        }
        return <p />;
      },
    },
    expectedDeliveryDate: {
      title: 'Expected Delivery Date',
      attribute: 'expected_delivery_date',
      // eslint-disable-next-line react/no-multi-comp
      value: (deposit) => {
        if (deposit.expected_delivery_date) {
          return <p>{moment(deposit.expected_delivery_date).format('MM/DD/YYYY')}</p>;
        }
        return <p />;
      },
    },
    depositType: {
      title: <span>Schedule</span>,
      attribute: 'deposit_type',
      // eslint-disable-next-line react/no-multi-comp
      value: (deposit) => {
        if (
          typeof deposit.recurring_deposit_id === 'undefined' ||
          deposit.recurring_deposit_id === null
        ) {
          return <p>{'One-time'}</p>;
        }
        return <p>{'Recurring'}</p>;
      },
    },
  };

  const recurringTableFields = {
    frequency: {
      title: 'Frequency',
      attribute: 'frequency_description',
      // eslint-disable-next-line react/no-multi-comp
      value: (deposit) => <p>{deposit.frequency_description}</p>,
    },
    deliveryDate: {
      title: 'Next delivery date',
      attribute: 'due_next',
      // eslint-disable-next-line react/no-multi-comp
      value: (deposit) => {
        if (deposit.due_next) {
          return <p>{moment(deposit.due_next).format('MM/DD/YYYY')}</p>;
        }
        return <p />;
      },
    },
  };
  if (depositType === 'one_time') return { ...tableFields, ...oneTimeTableFields };
  else if (depositType == 'recurring') return { ...tableFields, ...recurringTableFields };
  return tableFields;
};

export default depositsTableConfig;
