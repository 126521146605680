import PropTypes from 'prop-types';
import React from 'react';
import bindAll from 'react/shared/utils/bind_all';

export default class NameInputsWithLimit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: '',
      lastName: '',
      fullName: '',
    };

    bindAll(this);
  }

  onFirstNameChange(ev) {
    this.onFieldChange('firstName', ev);
  }

  onLastNameChange(ev) {
    this.onFieldChange('lastName', ev);
  }

  onFullNameChange(ev) {
    this.onFieldChange('fullName', ev);
  }

  onFieldChange(key, ev) {
    const value = ev.target.value;
    const fieldName = ev.target.name;

    if (typeof this.props.onFieldChange == 'function') {
      this.props.onFieldChange(fieldName, value);
    }

    this.setState({
      [key]: value,
    });
  }

  render() {
    const {
      labelPrefix,
      lengthLimit,
      inputNamePrefix,
      warningFullNameOwnerPrefix,
      warningNameLinePrefix,
      useLabelAsPlaceholder,
      rowClass,
    } = this.props;
    const { firstName, lastName, fullName } = this.state;

    const nameExceedsLengthLimit = `${firstName} ${lastName}`.length > lengthLimit;

    let fullNameClass = 'italic';
    if (fullName.length > lengthLimit) {
      fullNameClass += ' red';
    }

    const firstNameLabel = `${labelPrefix} first name`;
    const lastNameLabel = `${labelPrefix} last name`;
    return (
      <div>
        <div className={rowClass || 'card--form__row'}>
          <div className="card--form__col">
            <label htmlFor={`${inputNamePrefix}_first_name`}>
              {!useLabelAsPlaceholder && firstNameLabel}
              <input
                className="required"
                id={`${inputNamePrefix}_first_name`}
                name={`${inputNamePrefix}[first_name]`}
                onChange={this.onFirstNameChange}
                placeholder={useLabelAsPlaceholder && firstNameLabel}
                required
                type="text"
                value={firstName}
              />
            </label>
          </div>
          <div className="card--form__col">
            <label htmlFor={`${inputNamePrefix}_last_name`}>
              {!useLabelAsPlaceholder && lastNameLabel}
              <input
                className="required"
                id={`${inputNamePrefix}_last_name`}
                name={`${inputNamePrefix}[last_name]`}
                onChange={this.onLastNameChange}
                placeholder={useLabelAsPlaceholder && lastNameLabel}
                required
                type="text"
                value={lastName}
              />
            </label>
          </div>
        </div>
        {nameExceedsLengthLimit && (
          <div className={`${inputNamePrefix}_card_name`}>
            <div
              className="card--form__row"
              style={{
                margin: '21px',
                padding: '15px',
                borderRadius: '5px',
                backgroundColor: '#eee',
              }}
            >
              <div className="card--form__col width-100">
                <p>
                  We are only able to print {lengthLimit} total characters on the{' '}
                  {warningNameLinePrefix} name line of our cards. Please enter a variant of the{' '}
                  {warningFullNameOwnerPrefix}'s full name that is {lengthLimit} characters or
                  fewer.
                </p>
                <hr style={{ borderBottom: '1px solid #ccc' }} />
                <label htmlFor={`${inputNamePrefix}_card_name`}>
                  Name to be printed on card
                  <div className="field" style={{ width: '70%' }}>
                    <input
                      id={`${inputNamePrefix}_card_name`}
                      name={`${inputNamePrefix}[card_name]`}
                      onChange={this.onFullNameChange}
                      value={fullName}
                    />
                    <span
                      className={fullNameClass}
                      id={`${inputNamePrefix}-character-count`}
                      style={{
                        width: '25%',
                        position: 'absolute',
                        right: '0px',
                        bottom: '12px',
                      }}
                    >
                      {fullName.length} characters
                    </span>
                  </div>
                </label>
                <div id={`${inputNamePrefix}_card_name_error`} />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

NameInputsWithLimit.propTypes = {
  labelPrefix: PropTypes.string.isRequired,
  lengthLimit: PropTypes.number.isRequired,
  inputNamePrefix: PropTypes.string.isRequired,
  warningFullNameOwnerPrefix: PropTypes.string.isRequired,
  onFieldChange: PropTypes.func,
  warningNameLinePrefix: PropTypes.string,
  useLabelAsPlaceholder: PropTypes.bool,
  rowClass: PropTypes.string,
};
