import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import * as Yup from 'yup';
import CardActivationAgreementForm from './CardActivationAgreementForm';

const useStyles = makeStyles(() => ({
  activationConfirmationModal: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  activationData: {},
  activationDataRow: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '20px',
    padding: '10px 0px',
    borderTop: '1px solid #ddd',
    '&:first-child': {
      borderTop: 'none',
    },
  },
}));

export default function CardActivationConfirmation({
  firstName,
  isLoading,
  last4digits,
  lastName,
  nickname,
  onClose,
  onSubmit,
}) {
  const classes = useStyles();

  const validationSchema = Yup.object().shape({
    agreements: Yup.array().test(
      'signed-all',
      'Please confirm all agreements',
      (value) => value.includes('esignAgreement') && value.includes('cardholderAgreement'),
    ),
  });

  const initialValues = {
    agreements: [],
  };

  return (
    <div className={classes.activationConfirmationModal} id="activation-confirmation-form">
      <Typography variant="h4">Please review the following to activate this card</Typography>
      <div className={classes.activationData}>
        <div className={classes.activationDataRow}>
          <span>Name</span>
          <span>
            {firstName} {lastName}
          </span>{' '}
        </div>
        <div className={classes.activationDataRow}>
          <span>Last 4 digits of card</span>
          <span>xxxx-xxxx-xxxx-{last4digits}</span>
        </div>
        {nickname && (
          <div className={classes.activationDataRow}>
            <span>Account ID</span>
            <span>{nickname}</span>
          </div>
        )}
      </div>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        {({ handleSubmit, setFieldValue, values }) => (
          <>
            <CardActivationAgreementForm
              isLoading={isLoading}
              onClose={onClose}
              onSubmit={handleSubmit}
              setFieldValue={setFieldValue}
              values={values}
            />
          </>
        )}
      </Formik>
    </div>
  );
}

CardActivationConfirmation.propTypes = {
  firstName: PropTypes.string,
  isLoading: PropTypes.bool,
  last4digits: PropTypes.string,
  lastName: PropTypes.string,
  nickname: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
