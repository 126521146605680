import PropTypes from 'prop-types';
import React from 'react';
import _omit from 'underscore/modules/omit';

function GenericRadioInput(props) {
  return <input {..._omit(props, 'handleChange')} onChange={props.handleChange} type="radio" />;
}

GenericRadioInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  defaultChecked: PropTypes.bool,
  style: PropTypes.object,
};

export default GenericRadioInput;
