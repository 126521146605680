import PropTypes from 'prop-types';
import React from 'react';
import _contains from 'underscore/modules/contains';
import _map from 'underscore/modules/map';
import CardTransactionAttachmentRow from './CardTransactionAttachmentRow';
import Processing from 'images/processing.gif';
import DashboardActions from 'react/member/actions/dashboard_actions';
import TransactionAttachmentStore from 'react/member/stores/TransactionAttachmentStore';
import TrueLinkLink from 'react/shared/components/true_link/main/TrueLinkLink';
import bindAll from 'react/shared/utils/bind_all';

export default class CardTransactionAttachments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      attachments: [],
      showSpinner: false,
    };
    this.fileInputRef = React.createRef();
    bindAll(this);
  }

  componentDidMount() {
    TransactionAttachmentStore.on('attachments.fetch', this.onChangeAttachments);
    TransactionAttachmentStore.on('attachment.upload', this.onUpload);

    if (this.props.cardTransactionId) {
      DashboardActions.fetchTransactionAttachmentsByTransaction(this.props.cardTransactionId);
    }
  }

  componentWillUnmount() {
    TransactionAttachmentStore.off('attachments.fetch', this.onChangeAttachments);
    TransactionAttachmentStore.off('attachment.upload', this.onUpload);
  }

  allowedExtensionsErrorMessage(fileName) {
    return `You tried to upload "${fileName}". We currently do not support this file type. Please use these file types: ${$tlf.domains.allowedExtensions.join(
      ', ',
    )}`;
  }

  onChangeAttachments(transactionId, attachments) {
    if (this.props.cardTransactionId === transactionId) {
      this.setState({ attachments });
    }
  }

  upload(evt) {
    Truelink.flashClear();
    const fileInput = evt.target;
    if (fileInput.value !== '') {
      const fileName = fileInput.files[0].name;
      const ext = fileName.slice(fileName.lastIndexOf('.') + 1).toLowerCase();
      if (_contains($tlf.domains.allowedExtensions, ext)) {
        this.setState({ showSpinner: true });
        TransactionAttachmentStore.uploadAttachmentToTransaction(
          fileInput,
          this.props.cardTransactionId,
        );
      } else {
        Truelink.flash('error', this.allowedExtensionsErrorMessage(fileName));
      }
    }
  }

  onUpload() {
    this.setState({ showSpinner: false });
  }

  renderAttachments() {
    const { canEdit, cardTransactionId } = this.props;
    const { attachments } = this.state;
    const links = (() => {
      if (attachments.length !== 0) {
        const attachmentRender = _map(attachments, (attachment) => (
          <CardTransactionAttachmentRow
            attachment={attachment}
            canEdit={canEdit}
            cardTransactionId={cardTransactionId}
            isCardholderView={this.props.isCardholderView}
            key={attachment.id}
          />
        ));
        return <div>{attachmentRender}</div>;
      }
    })();
    return (
      <div className="new-form__data">
        {links}
        {this.uploadButton()}
        {this.state.showSpinner && (
          <span style={{ marginLeft: 15 }}>
            <span style={{ margin: 8, verticalAlign: 'middle' }}>
              <img
                alt="Loading..."
                className="inline-block"
                height={20}
                src={Processing}
                style={{ marginTop: -6 }}
                width={20}
              />
            </span>
          </span>
        )}
      </div>
    );
  }

  uploadButton() {
    if (!this.props.canEdit) return;

    const onClick = (evt) => {
      evt.preventDefault();
      this.fileInputRef.current.click();
    };
    return (
      <div className="inline-block">
        <TrueLinkLink ariaLabel="Add a receipt" onClick={onClick}>
          + Add receipt
        </TrueLinkLink>
      </div>
    );
  }

  render() {
    // we do this so that we always have an array of attachments
    const blankInput = (
      <input name="attachment_ids[]" readOnly style={{ display: 'none' }} value="" />
    );
    return (
      <div className="new-form__section new-form--compact new-form__section--inset">
        <div className="new-form-field">
          <div className="new-form__label">
            <label htmlFor="attachment[file]">Receipts:</label>
          </div>
          {blankInput}
          {this.renderAttachments()}
          <input
            name="attachment[file]"
            onChange={this.upload}
            ref={this.fileInputRef}
            style={{ display: 'none' }}
            type="file"
          />
        </div>
      </div>
    );
  }
}

CardTransactionAttachments.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  cardTransactionId: PropTypes.string.isRequired,
  isCardholderView: PropTypes.bool,
};
