import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import ActionMenuDropdown from 'react/member/components/common/ActionMenuDropdown';
import { fileTypeShape, filesShape, folderShape } from 'react/member/shapes/attachments_shapes';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import TrueLinkIcon from 'react/shared/components/true_link/main/TrueLinkIcon';

export default function FolderRow({
  folder,
  files,
  fileType,
  canEdit,
  expanded,
  onExpand,
  onCollapse,
  onRenameFolder,
  onDeleteFolder,
}) {
  const toggleFiles = useCallback(
    () => (expanded ? onCollapse(folder.id) : onExpand(folder.id)),
    [onCollapse, onExpand, expanded, folder.id],
  );
  const handleDeleteFolder = useCallback(() => onDeleteFolder(folder), [folder, onDeleteFolder]);
  const handleRenameFolder = useCallback(() => onRenameFolder(folder), [folder, onRenameFolder]);

  return (
    <div className="browseable-files__row--folder" id={`folder-row-${folder.id}`}>
      <TrueLinkButton
        key={expanded}
        onClick={toggleFiles}
        style={{ border: 0, background: 'none' }}
        variant="none"
      >
        <TrueLinkIcon icon={expanded ? 'caret-down' : 'caret-right'} />
        <TrueLinkIcon icon="folder" />
        <span>{`${folder.name} (${files.length})`}</span>
      </TrueLinkButton>
      {canEdit && fileType !== 'receipt' && (
        <span className="row-actions">
          <ActionMenuDropdown
            menuItems={[
              {
                displayText: 'Rename folder',
                action: handleRenameFolder,
              },
              {
                displayText: 'Delete folder',
                action: handleDeleteFolder,
              },
            ]}
          />
        </span>
      )}
    </div>
  );
}

FolderRow.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  folder: folderShape.isRequired,
  files: filesShape.isRequired,
  fileType: fileTypeShape.isRequired,
  expanded: PropTypes.bool.isRequired,
  onExpand: PropTypes.func.isRequired,
  onCollapse: PropTypes.func.isRequired,
  onRenameFolder: PropTypes.func.isRequired,
  onDeleteFolder: PropTypes.func.isRequired,
};
