import _reduce from 'underscore/modules/reduce';
import _values from 'underscore/modules/values';
import { asMoney } from 'react/shared/utils/Money';

export function formattedTotalFromBalances(balances) {
  const total = _reduce(
    balances,
    (total, category) => {
      const categoryTotal = _reduce(
        category,
        (sum, item) => sum + Number(_values(item)[0]) || 0,
        0,
      );
      return total + categoryTotal;
    },
    0,
  );
  return asMoney(total);
}
