import React from 'react';
import ManagedAssets from './ManagedAssets';

export default {
  title: 'Invest/Dashboard/ManagedAssets',
  component: ManagedAssets,
  argTypes: {
    cash: {
      control: 'number',
      table: {
        category: 'Contents',
      },
    },
    investments: {
      control: 'number',
      table: {
        category: 'Contents',
      },
    },
    beneficiaryId: {
      control: 'text',
      table: {
        category: 'Contents',
      },
    },
    beneficiarySlug: {
      control: 'text',
      table: {
        category: 'Contents',
      },
    },
    canDisburse: {
      control: 'boolean',
      table: {
        category: 'Contents',
      },
    },
    canRaiseCash: {
      control: 'boolean',
      table: {
        category: 'Contents',
      },
    },
  },
};

function Template(args) {
  return <ManagedAssets {...args} />;
}

export const Standard = Template.bind({});
Standard.args = {
  canDisburse: true,
  canRaiseCash: true,
  beneficiaryId: 213,
  beneficiarySlug: 'bene-fischer-213',
  cash: 420_000,
  investments: 0,
};
