import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

function SignupFlowProgressDisplay({ progressStep }) {
  const getCircleClassNames = (stepNumber) =>
    classNames({
      'step-number-circle': true,
      complete: progressStep > stepNumber,
      current: progressStep === stepNumber,
    });

  return (
    <div className="sign-up-flow__progress-steps">
      <span className={getCircleClassNames(1)}>1</span>
      <span className="step-number-separator" />
      <span className={getCircleClassNames(2)}>2</span>
      <span className="step-number-separator" />
      <span className={getCircleClassNames(3)}>3</span>
    </div>
  );
}

SignupFlowProgressDisplay.propTypes = {
  progressStep: PropTypes.oneOf([1, 2, 3]).isRequired,
};

export default SignupFlowProgressDisplay;
