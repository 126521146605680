import { ErrorMessage, FastField, Field } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import _isEmpty from 'underscore/modules/isEmpty';
import GoogleAutoComplete from 'react/member/components/signups/signup_form/form_partials/GoogleAutoComplete';
import TextField from 'react/member/components/signups/signup_form/form_partials/fields/TextField';
import FieldLabel from 'react/member/components/signups/signup_form/form_partials/labels/field_label';
import formikShape from 'react/member/shapes/FormikShape';
import StateDropdown from 'react/shared/components/state_dropdown';

function AddressFields({
  entity,
  street1LabelPrefix,
  street1AfterLabel,
  street2LabelPrefix,
  prefill,
  rowClass,
  colClass,
  street1Class,
  street2Class,
  cityClass,
  stateClass,
  zipClass,
  formik,
  googleAutoComplete,
  dataAnalyticsPrefix,
  viewport,
}) {
  const FieldComponent = prefill ? Field : FastField;

  const dataAnalyticsKey = (fieldName) =>
    _isEmpty(dataAnalyticsPrefix) ? fieldName : `${dataAnalyticsPrefix}_${fieldName}`;

  let street1Field = (
    <TextField
      dataAnalyticsKey={dataAnalyticsKey('address_line_1')}
      fast={!prefill}
      id={`${entity.replace('.', '_')}_street1`}
      name={`${entity}.street1`}
      readOnly={prefill}
    />
  );

  if (googleAutoComplete) {
    const handlePlaceSelectedFunc = (addressComponents) => {
      formik.setFieldValue(
        `${entity}.street1`,
        `${addressComponents['street_number']} ${addressComponents['route']}`,
      );
      formik.setFieldValue(`${entity}.city`, `${addressComponents['locality']}`);
      formik.setFieldValue(
        `${entity}.state`,
        `${addressComponents['administrative_area_level_1']}`,
      );
      formik.setFieldValue(`${entity}.zip`, `${addressComponents['postal_code']}`);
    };

    street1Field = (
      <GoogleAutoComplete
        handlePlaceSelected={handlePlaceSelectedFunc}
        id={`${entity.replace('.', '_')}_street1`}
      >
        {street1Field}
      </GoogleAutoComplete>
    );
  }

  const addressTwoLabelContent =
    viewport === 'mobile'
      ? `${street2LabelPrefix || 'Apartment'}/unit#`
      : `${street2LabelPrefix || 'Apartment'} or unit number`;

  return (
    <div>
      <div className={rowClass}>
        <div className={`${colClass} ${street1Class}`}>
          <FieldLabel
            afterLabel={street1AfterLabel}
            htmlFor={`${entity.replace('.', '_')}_street1`}
            labelContent={street1LabelPrefix ? `${street1LabelPrefix} address` : 'Address'}
          />
          {street1Field}
          <ErrorMessage className="error" component="label" name={`${entity}.street1`} />
        </div>
      </div>
      <div className={rowClass}>
        <div className={`${colClass} ${street2Class}`}>
          <FieldLabel
            htmlFor={`${entity.replace('.', '_')}_street2`}
            labelContent={addressTwoLabelContent}
          />
          <TextField
            dataAnalyticsKey={dataAnalyticsKey('address_line_2')}
            fast={!prefill}
            id={`${entity.replace('.', '_')}_street2`}
            name={`${entity}.street2`}
            readOnly={prefill}
          />
          <ErrorMessage className="error" component="label" name={`${entity}.street2`} />
        </div>
      </div>
      <div className={rowClass}>
        <div className={`${colClass} ${cityClass}`}>
          <FieldLabel htmlFor={`${entity.replace('.', '_')}_city`} labelContent="City" />
          <TextField
            dataAnalyticsKey={dataAnalyticsKey('address_city')}
            fast={!prefill}
            id={`${entity.replace('.', '_')}_city`}
            name={`${entity}.city`}
            readOnly={prefill}
          />
          <ErrorMessage className="error" component="label" name={`${entity}.city`} />
        </div>
        <div className={`${colClass} ${stateClass}`}>
          <FieldLabel htmlFor={`${entity.replace('.', '_')}_state`} labelContent="State" />
          <FieldComponent name={`${entity}.state`}>
            {({ field, form }) => (
              <StateDropdown
                dataAnalyticsKey={dataAnalyticsKey('address_state')}
                disabled={prefill}
                id={`${entity.replace('.', '_')}_state`}
                name={field.name}
                onBlur={(_event) => form.setFieldTouched(field.name, true)}
                onChange={(event) => form.setFieldValue(field.name, event.currentTarget.value)}
                readOnly={prefill}
                style={{
                  width: '100%',
                  ...(field.value === '' ? { color: '#757575', fontStyle: 'italic' } : {}),
                }}
                value={field.value}
              />
            )}
          </FieldComponent>
          <ErrorMessage className="error" component="label" name={`${entity}.state`} />
        </div>
        <div className={`${colClass} ${zipClass}`}>
          <FieldLabel htmlFor={`${entity.replace('.', '_')}_zip`} labelContent="Zip code" />
          <TextField
            dataAnalyticsKey={dataAnalyticsKey('address_zip')}
            fast={!prefill}
            id={`${entity.replace('.', '_')}_zip`}
            mask="99999"
            name={`${entity}.zip`}
            readOnly={prefill}
          />
          <ErrorMessage className="error" component="label" name={`${entity}.zip`} />
        </div>
      </div>
    </div>
  );
}

AddressFields.propTypes = {
  entity: PropTypes.oneOf([
    'address_attributes',
    'administrator.address',
    'cardholder.address',
    'organization.address',
    'other_address',
  ]).isRequired,
  formik: PropTypes.shape(formikShape),
  googleAutoComplete: PropTypes.bool,
  street1LabelPrefix: PropTypes.string,
  street1AfterLabel: PropTypes.node,
  street2LabelPrefix: PropTypes.string,
  prefill: PropTypes.bool,
  rowClass: PropTypes.string,
  colClass: PropTypes.string,
  street1Class: PropTypes.string,
  street2Class: PropTypes.string,
  cityClass: PropTypes.string,
  stateClass: PropTypes.string,
  zipClass: PropTypes.string,
  dataAnalyticsPrefix: PropTypes.string,
  viewport: PropTypes.string,
};

AddressFields.defaultProps = {
  street1LabelPrefix: null,
  street1AfterLabel: null,
  street2LabelPrefix: null,
  prefill: false,
  rowClass: 'card--form__row',
  colClass: 'card--form__col',
  street1Class: '',
  street2Class: 'card--form__col--flex-60-mobile',
  cityClass: 'card--form__col--flex-60-tablet',
  stateClass: 'card--form__col--flex-60-tablet',
  zipClass: 'card--form__col--flex-18',
  dataAnalyticsPrefix: null,
  viewport: null,
};

export default AddressFields;
