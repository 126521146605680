/* eslint react/no-multi-comp: off */
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import DashboardCard from './DashboardCard';
import TrueLinkLink from 'react/shared/components/true_link/main/TrueLinkLink';

const useStyles = makeStyles({
  footer: {
    padding: '24px',
    width: '100%',
    height: '100%',
    textAlign: 'center',
  },
});
export default {
  title: 'Invest/Dashboard/DashboardCard',
  component: DashboardCard,
  argTypes: {
    icon: {
      control: 'text',
      table: {
        category: 'Contents',
      },
    },
    title: {
      control: 'text',
      table: {
        category: 'Contents',
      },
    },
    viewLinkAriaLabel: {
      control: 'text',
      table: {
        category: 'Style',
      },
    },
    onViewLink: { action: 'changed' },
  },
};

function Template(args) {
  return <DashboardCard {...args} />;
}

function Footer() {
  const classes = useStyles();

  return (
    <TrueLinkLink className={classes.footer}>
      <Typography>See neat stuff</Typography>
    </TrueLinkLink>
  );
}

export const Everything = Template.bind({});
Everything.args = {
  title: 'Dashboard card title',
  icon: 'chart-line',
  content: (
    <>
      <Divider />
      <Typography>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec quam justo, varius quis
        tempor vel, posuere sodales neque. Phasellus id leo eu risus scelerisque lacinia. Quisque ex
        purus, condimentum ac nunc non, sagittis aliquet est. Quisque scelerisque justo a viverra
        cursus. Nunc eu massa est. Vivamus sollicitudin urna id massa lacinia, quis pretium ante
        rutrum. Sed pretium sollicitudin aliquam. Etiam feugiat sagittis leo non tristique. Aenean
        libero eros, facilisis quis laoreet sed, vestibulum sit amet nisi. Sed lectus justo, porta
        dignissim dignissim sed, mattis vitae nibh. Suspendisse id libero a eros facilisis
        pellentesque laoreet eget nisl. Phasellus enim lorem, faucibus non lobortis eget, aliquet ut
        leo. Suspendisse in libero quam. Aliquam suscipit ac augue et malesuada. Proin auctor risus
        sed nulla aliquet, vulputate tempor purus porttitor. Sed vel ultrices arcu, in vestibulum
        mi.
      </Typography>
    </>
  ),
  footer: <Footer />,
};

export const WithoutViewLink = Template.bind({});
WithoutViewLink.args = {
  title: 'Not all cards go places',
  icon: 'ice-cream',
  content: (
    <Typography>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec quam justo, varius quis tempor
      vel, posuere sodales neque. Phasellus id leo eu risus scelerisque lacinia. Quisque ex purus,
      condimentum ac nunc non, sagittis aliquet est. Quisque scelerisque justo a viverra cursus.
      Nunc eu massa est. Vivamus sollicitudin urna id massa lacinia, quis pretium ante rutrum. Sed
      pretium sollicitudin aliquam. Etiam feugiat sagittis leo non tristique. Aenean libero eros,
      facilisis quis laoreet sed, vestibulum sit amet nisi. Sed lectus justo, porta dignissim
      dignissim sed, mattis vitae nibh. Suspendisse id libero a eros facilisis pellentesque laoreet
      eget nisl. Phasellus enim lorem, faucibus non lobortis eget, aliquet ut leo. Suspendisse in
      libero quam. Aliquam suscipit ac augue et malesuada. Proin auctor risus sed nulla aliquet,
      vulputate tempor purus porttitor. Sed vel ultrices arcu, in vestibulum mi.
    </Typography>
  ),
  onViewLink: null,
};
