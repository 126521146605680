import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import OneTimeTransferAgreement from './OneTimeTransferAgreement';
import TransferWarningModal from './TransferWarningModal';
import DashboardActions from 'react/member/actions/dashboard_actions';
import api from 'react/member/card/components/funding_tab/api';
import { transferShape } from 'react/member/card/shapes/TransferShape';
import Actions from 'react/shared/actions/actions';
import LoadingIndicator from 'react/shared/components/LoadingIndicator';
import DatePickerField from 'react/shared/components/forms/DatePickerField';
import PopUp from 'react/shared/components/popups/PopUp';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import HolidayCalendarStore from 'react/shared/stores/HolidayCalendarStore';
import theme from 'react/shared/theme/Theme';
import { swallowAxiosCancelation } from 'react/shared/utils/Axios';
import { extractFormData } from 'react/shared/utils/form_serialization';

const useStyles = makeStyles(() => ({
  modalContainer: {
    fontSize: '16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
      '& .form-break': {
        display: 'none',
      },
      '& .modal-footer': {
        border: 'none',
        '& .button-wrapper': {
          width: '100%',

          '& button': {
            width: '100%',
            padding: '10px',
            marginBottom: '10px',
          },
        },
      },
    },
  },
  formRow: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '10px',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  formRowData: {
    marginLeft: '120px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0',
    },
  },
  formContainer: {
    '& .form-group': {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        '& .new-form__label': {
          fontWeight: 'normal',
          marginBottom: '0',
        },
        '& .new-form__data': {
          position: 'relative',
          width: '100%',
          marginBottom: '5px',
          '& .datepicker-wrapper': {
            width: '100% !important',
            height: '44px',
            '&:after': {
              top: '8px',
            },
          },
          '& input': {
            width: '100% !important',
            height: '44px',
          },
          '& input.currency': {
            paddingLeft: '30px',
          },
        },
      },
    },
  },

  inputPrefix: {
    marginRight: '5px',
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      left: '10px',
      top: '11px',
    },
  },
}));

export default function TransferUpsertModal({
  bankAccount,
  edit,
  isEffectivelyFullyFunded,
  isFullyFunded,
  onClose,
  transfer,
}) {
  const classes = useStyles();

  const [amountError, setAmountError] = useState(false);
  const [amountErrorMessage, setAmountErrorMessage] = useState('');
  const [availableOnDate, setAvailableOnDate] = useState(null);
  const [dateError, setDateError] = useState(false);
  const [dateErrorMessage, setDateErrorMessage] = useState('');
  const [loadingHolidays, setLoadingHolidays] = useState(true);
  const [loadingSoonestTransferDates, setLoadingSoonestTransferDates] = useState(true);
  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [soonestTransferDate, setSoonestTransferDate] = useState(null);
  const [soonestAvailableDate, setSoonestAvailableDate] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const cancelTokenSource = axios.CancelToken.source();

  const finishLoading = () => {
    setLoadingHolidays(false);
  };

  const openWarningModal = () => {
    setShowWarningModal(true);
  };

  const closeWarningModal = () => {
    setShowWarningModal(false);
  };

  const validDateSelected = (value) => {
    const day = moment(value).day();
    const soonestTransferDateStartDay = moment(soonestTransferDate)
      .startOf('day')
      .format('YYYY-MM-DD');
    let dateErrorMessage;

    const holidays = HolidayCalendarStore.getBankHolidays();
    const isHoliday = holidays ? holidays[value] : true;
    let dateBeforeSoonestTransferDate;

    if (value && soonestTransferDate) {
      dateBeforeSoonestTransferDate = moment(value).isBefore(soonestTransferDateStartDay);
    }

    if (Number.isNaN(Date.parse(value))) {
      dateErrorMessage = 'Must be a valid date';
    } else if (dateBeforeSoonestTransferDate) {
      dateErrorMessage = `Transfer date must be ${soonestTransferDateStartDay} or later.`;
    } else if (day === 0 || day === 6 || isHoliday) {
      dateErrorMessage = 'Transfer cannot be scheduled on a bank holiday or weekend';
    }

    if (dateErrorMessage) {
      setDateError(true);
      setDateErrorMessage(dateErrorMessage);
      return false;
    }

    setDateError(false);
    setDateErrorMessage('');
    setShowErrorBanner(showErrorBanner && !amountError ? false : showErrorBanner);
    return true;
  };

  const updateAvailableOnDate = (date) => {
    api
      .getBomorrow(date, cancelTokenSource.token)
      .then(({ data }) => {
        setAvailableOnDate(data.bomorrow);
      })
      .catch(swallowAxiosCancelation);
  };

  const onDateSelected = (date) => {
    if (isFullyFunded && date) {
      updateAvailableOnDate(date);
      validDateSelected(date);
    }
  };

  const fetchSoonestTransferDate = () => {
    api
      .getSoonestTransferDate(cancelTokenSource.token)
      .then(({ data }) => {
        setSoonestTransferDate(data.boday);
        setSoonestAvailableDate(data.bomorrow);
        setAvailableOnDate(data.bomorrow);
        setLoadingSoonestTransferDates(false);
      })
      .catch(swallowAxiosCancelation);
  };

  useEffect(() => {
    Actions.fetchBankHolidays();
    HolidayCalendarStore.on('bankHolidays.fetch', finishLoading);
    fetchSoonestTransferDate();

    if (edit && transfer) {
      onDateSelected(transfer.date);
    }

    return () => {
      HolidayCalendarStore.off('bankHolidays.fetch', finishLoading);
      cancelTokenSource.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const shouldShowTransferWarning = (date) => {
    if (edit) return false;
    return date === moment(soonestTransferDate).add(1, 'day').format('YYYY-MM-DD');
  };

  const validAmount = (value) => {
    const splitted = value.split('.');
    const decimal = splitted[1];
    if (value.length === 0 || Number(value) <= 0) {
      setAmountError(true);
      setAmountErrorMessage('Cannot be zero or empty');
      return false;
    } else if (Number(value) > 5000) {
      setAmountError(true);
      setAmountErrorMessage('Please enter a value less than or equal to 5000');
      return false;
    } else if (Number.isNaN(Number(value))) {
      setAmountError(true);
      setAmountErrorMessage('Not a valid number');
      return false;
    } else if (decimal && decimal.length > 2) {
      setAmountError(true);
      setAmountErrorMessage('Only 2 decimal places are allowed');
      return false;
    }

    setAmountError(false);
    setAmountErrorMessage('');
    setShowErrorBanner(showErrorBanner && !dateError ? false : showErrorBanner);
    return true;
  };

  const scrapeFormData = () => {
    const form = $('#one-time-transfer-form');
    const data = extractFormData(form.serializeArray());
    const isValidAmount = validAmount(data['amount']);
    const isValidDate = validDateSelected(data['date']);

    if (isValidAmount) {
      // Safari and IE will leave a trailing decimal, which we can fix by parsing the amount as a number
      data['amount'] = Number(data['amount']).toString();
    }

    return { data, isValidAmount, isValidDate };
  };

  const createTransfer = (data) => {
    DashboardActions.createTransfer(bankAccount.id, data, onClose);
  };

  const updateTransfer = (data) => {
    DashboardActions.updateTransfer(transfer.id, { transfer: data }, onClose);
  };

  const deleteTransfer = () => {
    DashboardActions.deleteTransfer(transfer.id, onClose);
  };

  const submitForm = (formData) => {
    setSubmitting(true);
    edit ? updateTransfer(formData) : createTransfer(formData);
  };

  const onSubmitForm = (ev) => {
    ev.preventDefault();
    const { data } = scrapeFormData();
    submitForm(data);
  };

  const validateAndSubmitForm = (ev) => {
    ev.preventDefault();
    const { data, isValidAmount, isValidDate } = scrapeFormData();

    if (isValidAmount && isValidDate) {
      if (shouldShowTransferWarning(data['date'])) {
        openWarningModal();
      } else {
        submitForm(data);
      }
    } else {
      setShowErrorBanner(true);
    }
  };

  const amount = () => {
    const error = amountError && <p style={{ color: '#d93302' }}>{amountErrorMessage}</p>;

    return (
      <div className="form-group">
        <div className="new-form__label nowrap">
          <label htmlFor="transfer_amount">Amount to transfer: </label>
        </div>
        <div className="new-form__data">
          <span className={classes.inputPrefix}>$</span>
          <input
            className="currency"
            defaultValue={transfer?.amount}
            id="transfer_amount"
            max="5000"
            maxLength="7"
            min="5"
            name="amount"
            onBlur={({ target }) => validAmount(target.value)}
            placeholder="0.00"
            size="7"
            style={{ width: 90 }}
            type="number"
          />
          {error}
        </div>
      </div>
    );
  };

  const memo = () => (
    <div className="form-group">
      <div className="new-form__label nowrap">
        <label htmlFor="transfer_memo">Memo:</label>
      </div>
      <div className="new-form__data">
        <input
          defaultValue={transfer?.memo}
          id="transfer_memo"
          maxLength="255"
          name="memo"
          placeholder="Optional"
          style={{ width: 'auto' }}
          type="text"
        />
      </div>
    </div>
  );

  const date = () => {
    let date;
    if (transfer) {
      date = moment(transfer.date, 'MMMM DD, YYYY').format('YYYY-MM-DD');
    } else if (soonestTransferDate) {
      date = soonestTransferDate;
    }
    const error = dateError && <p style={{ color: '#d93302' }}>{dateErrorMessage}</p>;
    const availableOn = isEffectivelyFullyFunded && availableOnDate && (
      <div>Funds available on {availableOnDate}</div>
    );

    return (
      <div className="form-group">
        <div className="new-form__label nowrap">
          <label htmlFor="transfer_date">Initiate transfer on:</label>
        </div>
        <div className="new-form__data">
          <DatePickerField
            dateFormat="yy-mm-dd"
            excludeWeekendsAndHolidays
            handleDateSelect={onDateSelected}
            inputProps={{
              id: 'transfer_date',
              name: 'date',
              defaultValue: date,
            }}
            maxDate="+1Y"
            minDate={moment(soonestTransferDate).toDate()}
          />
          {error}
          {availableOn}
        </div>
      </div>
    );
  };

  const header = () => {
    const text = edit ? 'Edit one-time transfer' : 'Make one-time transfer';
    return <div>{text}</div>;
  };

  const footer = () => {
    const deleteButton = edit && !submitting && (
      <TrueLinkButton
        className="btn btn-link"
        id="one-time-transfer-delete-cta"
        onClick={deleteTransfer}
        variant="none"
      >
        Delete this transfer
      </TrueLinkButton>
    );

    const submitButton = (
      <TrueLinkButton
        className="btn normal"
        disabled={submitting}
        id="one-time-transfer-submit-cta"
        onClick={validateAndSubmitForm}
        variant="none"
      >
        Submit
      </TrueLinkButton>
    );

    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {deleteButton}
        <span className="button-wrapper">
          {submitButton}
          {submitting && (
            <LoadingIndicator
              containerStyle={{
                display: 'inline',
                margin: 8,
              }}
            />
          )}
        </span>
      </div>
    );
  };

  const body = () => {
    if (loadingHolidays || loadingSoonestTransferDates) {
      return <LoadingIndicator />;
    }

    const accountInfo = bankAccount.nickname
      ? `${bankAccount.nickname} - ${bankAccount.accountNumber}`
      : `${bankAccount.accountNumber}`;

    const id = transfer && <input name="id" type="hidden" value={transfer.id || ''} />;

    return (
      <div>
        <div className={classes.formRow}>
          <span>Account: </span>
          <span className={classes.formRowData}>{accountInfo}</span>
        </div>
        <hr className="form-break" />
        <div>
          <form className={classes.formContainer} id="one-time-transfer-form">
            {amount()}
            {memo()}
            {date()}
            {id}
          </form>
        </div>
        <hr className="form-break" />
        <OneTimeTransferAgreement />
      </div>
    );
  };

  const renderOneTimeTransferModal = () => (
    <div className={classes.modalContainer}>
      <PopUp
        bannerMessage="Please correct the form fields highlighted below and submit again"
        footer={footer()}
        header={header()}
        maxWidth="750px"
        modalOpen
        onClose={onClose}
        showBanner={showErrorBanner}
      >
        {body()}
      </PopUp>
    </div>
  );

  const renderTransferWarningModal = () => {
    if (edit) return null;

    return (
      <TransferWarningModal
        availableDate={availableOnDate}
        date={soonestAvailableDate}
        onClose={closeWarningModal}
        onSubmit={onSubmitForm}
        submitting={submitting}
      />
    );
  };

  return (
    <div>
      {renderOneTimeTransferModal()}
      {showWarningModal && renderTransferWarningModal()}
    </div>
  );
}

TransferUpsertModal.propTypes = {
  bankAccount: PropTypes.object.isRequired,
  edit: PropTypes.bool.isRequired,
  isEffectivelyFullyFunded: PropTypes.bool.isRequired,
  isFullyFunded: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  transfer: transferShape,
};
