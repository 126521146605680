import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import TransferIconCell from 'react/member/card/components/funding_tab/transfers_table/TransferIconCell';
import { autoTransferShape } from 'react/member/card/shapes/AutoTransferShape';
import { transferShape } from 'react/member/card/shapes/TransferShape';
import {
  isCancelled,
  isSkipped,
  isEmergencyFunded,
  isPartiallyLanded,
  hasAcceptedGuidance,
} from 'react/member/utils/Transfer';

const useStyles = makeStyles({
  small: {
    fontSize: '14px',
  },
  grey: {
    opacity: '50%',
  },
  both: {
    fontSize: '14px',
    opacity: '50%',
  },
  cell: {
    display: 'flex',
    marginLeft: '-20px',
  },
  icon: {
    width: '20px',
  },
});

export default function TransferDescriptionCell({ item }) {
  const classes = useStyles();
  const isAutoTransfer = item.recurringType;
  const isRecurringTransfer = item.autoTransferId;
  const formattedDate = moment(item.dateUnformatted).format('dddd, MMMM D');
  const formattedLandedDate = moment(item.landedDateUnformatted).format('MMM D');
  const formattedOriginalDate = moment(item.revertDate).format('dddd, MMMM D');

  if (isAutoTransfer) {
    return (
      <div className={classes.cell}>
        <div className={classes.icon}>
          <TransferIconCell className={'fa-sync'} color={'grey'} tooltipOn={false} />
        </div>
        <div>{item.description}</div>
      </div>
    );
  }

  let statusMessage = null;
  if (isEmergencyFunded(item)) {
    statusMessage = `${item.instantFundedBy} emergency funded this transfer on ${formattedLandedDate}`;
  } else if (isSkipped(item)) {
    statusMessage = 'This recurring transfer has been skipped';
  } else if (isCancelled(item)) {
    statusMessage = 'This one-time transfer has been cancelled';
  } else if (hasAcceptedGuidance(item)) {
    statusMessage = 'Updated to mitigate holiday impact';
  } else if (isPartiallyLanded(item)) {
    statusMessage = `A portion of this transfer is now available, and the remaining funds will be available ${moment(
      item.deferredCreditDate,
    ).format('MMM D')}`;
  }

  return (
    <div className={classes.cell}>
      <div className={classes.icon}>
        {isRecurringTransfer && (
          <TransferIconCell className={'fa-sync'} color={'grey'} tooltipOn={false} />
        )}
      </div>
      <div>
        {hasAcceptedGuidance(item) && !isSkipped(item) && (
          <div>
            <s>{formattedOriginalDate}</s>
          </div>
        )}
        <div>
          {isSkipped(item) || isCancelled(item) ? (
            <s>{formattedDate}</s>
          ) : (
            <span className={isEmergencyFunded(item) ? classes.grey : ''}>{formattedDate}</span>
          )}
        </div>
        {item.memo && (
          <div className={classes.both}>
            <b>Memo: </b>
            {item.memo}
          </div>
        )}
        {statusMessage && (
          <div className={isEmergencyFunded(item) ? classes.small : classes.both}>
            {statusMessage}
          </div>
        )}
      </div>
    </div>
  );
}

TransferDescriptionCell.propTypes = {
  item: PropTypes.oneOfType([autoTransferShape, transferShape]).isRequired,
};
