import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { download_checks_dashboard_disbursements_path } from 'member_routes';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import TrueLinkIcon from 'react/shared/components/true_link/main/TrueLinkIcon';

const useStyles = makeStyles({
  root: {
    textAlign: 'right',
    display: 'flex',
  },
  tableButton: {
    whiteSpace: 'nowrap',
    margin: '2px',
  },
  viewIcon: {
    marginLeft: '4px',
    verticalAlign: 'text-bottom',
  },
});

function PrintChecksButtonsCell({ value, row }) {
  const { check, action } = value;
  const classes = useStyles();

  const downloadCheck = (check, action) => () => {
    action && action([check.id]);
    const downloadUrl = download_checks_dashboard_disbursements_path({
      disbursements_for_download: check.id,
    });
    window.open(downloadUrl);
  };

  return (
    <div className={classes.root}>
      <TrueLinkButton
        className={classes.tableButton}
        onClick={downloadCheck(check, action)}
        variant="primary"
      >
        Download check
      </TrueLinkButton>
      <TrueLinkButton
        className={classes.tableButton}
        onClick={() => row.toggleRowExpanded()}
        variant="neutral"
      >
        {row.isExpanded ? 'Close' : 'View'}
        <span key={row.isExpanded}>
          <TrueLinkIcon
            className={classes.viewIcon}
            icon={row.isExpanded ? 'angle-up' : 'angle-down'}
          />
        </span>
      </TrueLinkButton>
    </div>
  );
}

PrintChecksButtonsCell.propTypes = {
  value: PropTypes.shape({
    check: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
    action: PropTypes.func.isRequired,
  }),
  row: PropTypes.shape({
    isExpanded: PropTypes.bool,
    toggleRowExpanded: PropTypes.func.isRequired,
  }).isRequired,
};

export default PrintChecksButtonsCell;
