import PropTypes from 'prop-types';
import React from 'react';
import { transferShape } from 'react/member/card/shapes/TransferShape';
import PopUp from 'react/shared/components/popups/PopUp';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';

export default function TransferEmergencyFundModal({
  cardLast4,
  onClose,
  onSubmit,
  submitting,
  transfer,
}) {
  const header = <div style={{ textAlign: 'left' }}>Emergency fund</div>;
  const footer = (
    <TrueLinkButton className="btn normal" disabled={submitting} onClick={onSubmit} variant="none">
      Confirm
    </TrueLinkButton>
  );

  const amount = transfer.amount;
  const bankAccountLast4 = transfer.bankAccount.accountNumber.slice(-4);

  return (
    <PopUp footer={footer} header={header} maxWidth="550px" modalOpen onClose={onClose}>
      <div style={{ fontSize: 16 }}>
        <p>
          {`We understand emergencies happen and we're happy to advance funds from True Link. $${amount} will be made available on the card ending in ${cardLast4} in 10 minutes or less.`}
        </p>
        <p>
          {`Note: Funds will be drawn from the bank account (ending ${bankAccountLast4}) within 24 hours, please ensure you have enough funds available to avoid overdraft fees.`}
        </p>
      </div>
    </PopUp>
  );
}

TransferEmergencyFundModal.propTypes = {
  cardLast4: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  transfer: transferShape.isRequired,
};
