import Skeleton from '@mui/material/Skeleton';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { grey4, grey } from 'react/shared/theme/palette';

const useStyles = makeStyles({
  root: {
    borderBottom: `1px solid ${grey4}`,
    '&:hover': {
      background: grey,
    },
  },
  cell: {
    padding: '15px',
  },
});

export default function TableLoadingRow() {
  const classes = useStyles();
  return (
    <>
      <tr aria-label="table loading row" className={classes.root}>
        <td className={classes.cell} colSpan={99_999}>
          <Skeleton variant="text" />
        </td>
      </tr>
    </>
  );
}
