import { ThemeProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import OnboardingWidget from './OnboardingWidget';
import DashboardCardProvider from 'react/member/card/components/onboarding_widget/DashboardCardContext';
import theme from 'react/shared/theme/Theme';
function OnboardingWidgetIndex(props) {
  const queryClient = new QueryClient();

  //Eventually this will become the dashboard index
  //that contains all the content form app/views/dashboard/dashboard_home.html.haml
  //TODO: rename to DashboardIndex when this is the single point of entry
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <DashboardCardProvider props={props}>
          <OnboardingWidget {...props} />
        </DashboardCardProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default OnboardingWidgetIndex;

OnboardingWidgetIndex.propTypes = {};
