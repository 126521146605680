import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import childrenShape from 'react/shared/shapes/children';

export default function ToggleSectionHeader({
  children,
  itemCount,
  toggleExpanded,
  expanded,
  title,
}) {
  return (
    <div className={classNames('toggle-section-header', { expanded })}>
      <div className="header-main">
        <div className="header-main-title">
          <h3 className="title-text">{title}</h3>
          {itemCount === Number.parseInt(itemCount, 10) && (
            <div className="circle-counter">
              <b>{itemCount}</b>
            </div>
          )}
        </div>
        <div className="header-main-toggle">
          {toggleExpanded && (
            <button
              className="expand-link btn btn-link"
              key={expanded}
              onClick={toggleExpanded}
              type="button"
            >
              <i className={classNames('fa', expanded ? 'fa-angle-up' : 'fa-angle-down')} />
            </button>
          )}
        </div>
      </div>
      {children && expanded ? <div className="header-secondary">{children}</div> : null}
    </div>
  );
}

ToggleSectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  itemCount: PropTypes.number,
  expanded: PropTypes.bool.isRequired,
  toggleExpanded: PropTypes.func,
  children: childrenShape,
};

ToggleSectionHeader.defaultProps = {
  itemCount: null,
  toggleExpanded: null,
};
