import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import DashboardActions from 'react/member/actions/dashboard_actions';
import { bankAccountShape } from 'react/member/card/shapes/BankAccountShape';
import DashboardBankAccountStore from 'react/member/stores/DashboardBankAccountStore';
import PopUp from 'react/shared/components/popups/PopUp';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';

export default function RemoveBankAccountModal({ bankAccount, onClose }) {
  useEffect(() => {
    DashboardBankAccountStore.on('bankAccount.delete', onClose);

    return () => {
      DashboardBankAccountStore.off('bankAccount.delete', onClose);
    };
  });

  const removeBankAccount = () => {
    DashboardActions.removeBankAccount(bankAccount.id);
  };

  const onSubmit = (ev) => {
    ev.preventDefault();
    removeBankAccount();
  };

  const header = () => <div style={{ textAlign: 'left' }}>Remove bank account</div>;

  const footer = () => (
    <TrueLinkButton className="btn normal" onClick={onSubmit} variant="none">
      Remove Account
    </TrueLinkButton>
  );

  const body = () => (
    <div style={{ fontSize: 16 }}>
      <p>
        Account:{' '}
        <span style={{ marginLeft: 100 }}>
          {bankAccount.nickname} - {bankAccount.accountNumber}
        </span>
      </p>
      <hr />
      <p>
        Removing a bank account will also remove any upcoming one-time and recurring transfers from
        this bank account.
      </p>
      <p>Are you sure you want to remove this bank account?</p>
    </div>
  );

  return (
    <div>
      <PopUp footer={footer()} header={header()} maxWidth="550px" modalOpen onClose={onClose}>
        {body()}
      </PopUp>
    </div>
  );
}

RemoveBankAccountModal.propTypes = {
  bankAccount: bankAccountShape.isRequired,
  onClose: PropTypes.func.isRequired,
};
