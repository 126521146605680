import PropTypes from 'prop-types';
import React from 'react';
import CancelModal from './cancel_modal/CancelModal';
import bindAll from 'react/shared/utils/bind_all';

export default class RecurringDisbursementDetailActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showCancelModal: false };
    bindAll(this);
  }

  toggleCancelModal() {
    this.setState((prevState) => ({
      showCancelModal: !prevState.showCancelModal,
    }));
  }

  closeCancelModal() {
    this.setState({ showCancelModal: false });
  }

  render() {
    return (
      <span>
        {this.props.canCancel && (
          <button
            className="red btn btn-link"
            onClick={this.toggleCancelModal}
            style={{ paddingRight: 25 }}
            type="button"
          >
            Cancel Recurring Disbursement
          </button>
        )}
        {this.props.canEdit && (
          <button className="btn btn-link" onClick={this.props.onToggleEdit} type="button">
            {this.props.isEditing ? 'Save' : 'Edit'}
          </button>
        )}
        {this.state.showCancelModal && (
          <CancelModal
            onClose={this.closeCancelModal}
            recurringDisbursement={this.props.recurringDisbursement}
          />
        )}
      </span>
    );
  }
}

RecurringDisbursementDetailActions.propTypes = {
  recurringDisbursement: PropTypes.object.isRequired,
  canEdit: PropTypes.bool.isRequired,
  canCancel: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  onToggleEdit: PropTypes.func.isRequired,
};
