import I18n from 'i18n-js';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import Overdue from './display/legacy_card/Overdue';
import PreRelease from './display/legacy_card/PreRelease';
import Released from './display/legacy_card/Released';
import { select_account_path } from 'member_routes';
import api from 'react/member/card/components/account_tab/api';
import Section from 'react/member/card/components/account_tab/card_status/Section';
import { accountShape } from 'react/member/card/shapes/AccountShape';
import { cardOrderShape } from 'react/member/card/shapes/CardOrderShape';
import { cardShape } from 'react/member/card/shapes/CardShape';
import { cardholderShape } from 'react/member/card/shapes/CardholderShape';
import { OVERDUE_STATUS } from 'react/member/shapes/estimated_arrival_shape';
import { CARD_STATUSES } from 'react/member/utils/CardStatus';
import LoadingIndicator from 'react/shared/components/LoadingIndicator';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import { programNameFromType } from 'react/shared/utils/account_program_type';

export default function LegacyCardReissueStatus({ account, card, cardReissue, cardholder }) {
  const cardType = programNameFromType(account.program_type);
  const { estimatedArrival } = cardReissue;
  const range = estimatedArrival.range;
  const formattedArrivalDate = moment(estimatedArrival.arrivalDate, 'MM/DD/YYYY').format(
    'MMMM Do, YYYY',
  );
  const [reissueCDetails, setReissueCDetails] = useState(null);

  useEffect(() => {
    if (!cardReissue.newAccountId) {
      return;
    }

    api.getCardStatusDetails(cardReissue.newAccountId).then((response) => {
      if (response && response.data && response.data.account) {
        setReissueCDetails(response.data);
      } else {
        Truelink.flash('error', 'There was an error retrieving card status information.');
      }
    });
  }, [cardReissue]);

  if (cardReissue.status == 'pending_release') {
    return (
      <Section>
        <PreRelease
          cardType={cardType}
          cardholderName={cardholder.name}
          estimatedArrivalRange={range}
          formattedArrivalDate={formattedArrivalDate}
          oldCardLast4={card.card_last_4}
          status={CARD_STATUSES.PRE_RELEASE_LEGACY_CARD_ORDER_STATUS}
        />
      </Section>
    );
  }

  if (reissueCDetails === null) return <LoadingIndicator />;

  const { account: newAccount, card: newCard } = reissueCDetails;
  const supportPhone = I18n.t('corp.ad_tollfree_numeric', { locale: account.program_type });

  if (cardReissue.estimatedArrival.status === OVERDUE_STATUS) {
    return (
      <Section
        footer={
          <a href={select_account_path(cardReissue.newAccountId)}>
            <TrueLinkButton variant="primary">Go to New Account</TrueLinkButton>
          </a>
        }
      >
        <Overdue
          cardBreached={newCard.is_breached}
          cardFboName={newAccount.fbo_person_name}
          cardLast4={newCard.card_last_4}
          cardName={newAccount.card_name}
          cardShipmentTrackingNumber={newCard.shipment_tracking_number}
          cardType={cardType}
          cardholderName={cardholder.name}
          estimatedArrivalDate={cardReissue.estimatedArrival.arrivalDate}
          estimatedArrivalRange={cardReissue.estimatedArrival.range}
          expedited={cardReissue.expediteShipping}
          expiryDate={newCard.expiry_date}
          oldCardLast4={card.card_last_4}
          shippingAddress={cardReissue.shippingAddress}
          status={CARD_STATUSES.OVERDUE_RELEASED_LEGACY_CARD_ORDER_STATUS}
          supportPhone={supportPhone}
        />
      </Section>
    );
  }

  return (
    <Section
      footer={
        <a href={select_account_path(cardReissue.newAccountId)}>
          <TrueLinkButton variant="primary">Go to New Account</TrueLinkButton>
        </a>
      }
    >
      <Released
        cardBreached={newCard.is_breached}
        cardFboName={newAccount.fbo_person_name}
        cardLast4={newCard.card_last_4}
        cardName={newAccount.card_name}
        cardShipmentTrackingNumber={newCard.shipment_tracking_number}
        cardType={cardType}
        cardholderName={cardholder.name}
        estimatedArrivalDate={cardReissue.estimatedArrival.arrivalDate}
        estimatedArrivalRange={cardReissue.estimatedArrival.range}
        expedited={cardReissue.expediteShipping}
        expiryDate={newCard.expiry_date}
        oldCardLast4={card.card_last_4}
        shippingAddress={cardReissue.shippingAddress}
        status={CARD_STATUSES.RELEASED_LEGACY_CARD_ORDER_STATUS}
      />
    </Section>
  );
}

LegacyCardReissueStatus.propTypes = {
  account: accountShape.isRequired,
  card: cardShape,
  cardReissue: cardOrderShape.isRequired,
  cardholder: cardholderShape.isRequired,
};
