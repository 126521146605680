import PropTypes from 'prop-types';
import React from 'react';
import DashboardDepositsTable from './DashboardDepositsTable';
import userRoleShape from 'react/member/shapes/UserRoleShape';
import bindAll from 'react/shared/utils/bind_all';

export default class DashboardDeposits extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      deposits: [],
      isLoading: true,
      selectedDeposit: null,
      tableVersionId: 0,
      depositIndexRoute: this.depositIndexRoute(),
    };

    bindAll(this);
  }

  depositIndexRoute() {
    const { props } = this;

    if (props.client) {
      return RailsRoutes.dashboard_client_deposits_path(props.client.id, {
        status_filter: props.statusFilter,
        format: 'json',
      });
    } else if (props.portfolio) {
      return RailsRoutes.dashboard_portfolio_deposits_path(props.portfolio.id, {
        status_filter: props.statusFilter,
        format: 'json',
      });
    } else if (props.organization) {
      return RailsRoutes.dashboard_organization_deposits_path(props.organization.id, {
        status_filter: props.statusFilter,
        format: 'json',
      });
    }
    return RailsRoutes.dashboard_deposits_path({
      status_filter: props.statusFilter,
      format: 'json',
    });
  }

  render() {
    return (
      <div>
        <DashboardDepositsTable
          client={this.props.client}
          clientList={this.props.clientList}
          columnList={this.props.columnList}
          defaultSort={this.props.defaultSort}
          depositType={'one_time'}
          deposits={this.state.deposits}
          indexRoute={this.state.depositIndexRoute}
          isLoading={this.state.isLoading}
          organization={this.props.organization}
          refreshDeposits={this.props.refreshDeposits}
          userRoles={this.props.userRoles}
        />
      </div>
    );
  }
}

DashboardDeposits.propTypes = {
  client: PropTypes.object,
  portfolio: PropTypes.object,
  organization: PropTypes.object,
  clientList: PropTypes.array,
  columnList: PropTypes.array.isRequired,
  statusFilter: PropTypes.string.isRequired,
  refreshDeposits: PropTypes.func,
  defaultSort: PropTypes.shape({
    key: PropTypes.string,
    direction: PropTypes.string,
  }),
  userRoles: userRoleShape.isRequired,
};

DashboardDeposits.defaultProps = {
  client: null,
  portfolio: null,
  organization: null,
  clientList: [],
  columnList: [],
  statusFilter: null,
};
