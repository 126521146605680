import PropTypes from 'prop-types';
import React from 'react';
import WmDashboardClientSelect from './WmDashboardClientSelect';
import WmDashboardNav from './WmDashboardNav';
import IndividualTrustBeneficiaryOverviewHeader from './clients/dashboard_client/overview/IndividualTrustBeneficiaryOverviewHeader';
import WmBalances from 'react/member/components/dashboard/clients/WmBalances';

export default function WmDashboardTitleBar({
  title,
  client,
  organization,
  balanceInfo,
  showIndividualTrustBeneficiaryOverview,
  trustBeneficiaries,
  preloadedTrustBenes,
  autocompleteSelectEnabled,
}) {
  if (client) {
    if (showIndividualTrustBeneficiaryOverview && !client.isPooled) {
      return (
        <IndividualTrustBeneficiaryOverviewHeader
          autocompleteSelectEnabled={autocompleteSelectEnabled}
          client={client}
          organization={organization}
          preloadedTrustBenes={preloadedTrustBenes}
          title={title}
          trustBeneficiaries={trustBeneficiaries}
        />
      );
    }

    return (
      <WmDashboardNav>
        <div className="pull-left">
          <WmDashboardClientSelect
            autocompleteSelectEnabled={autocompleteSelectEnabled}
            client={client}
            organization={organization}
            preloadedTrustBenes={preloadedTrustBenes}
            trustBeneficiaries={trustBeneficiaries}
          />
        </div>
        {balanceInfo && <WmBalances balanceInfo={balanceInfo} />}
      </WmDashboardNav>
    );
  }

  return (
    <WmDashboardNav>
      <span className="b_s_h__hed">{title}</span>
      <div className="pull-right">
        <WmDashboardClientSelect
          autocompleteSelectEnabled={autocompleteSelectEnabled}
          client={client}
          organization={organization}
          preloadedTrustBenes={preloadedTrustBenes}
          trustBeneficiaries={trustBeneficiaries}
        />
      </div>
    </WmDashboardNav>
  );
}

WmDashboardTitleBar.propTypes = {
  autocompleteSelectEnabled: PropTypes.bool,
  title: PropTypes.string,
  client: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    person: PropTypes.shape({ name: PropTypes.string }).isRequired,
    isPooled: PropTypes.bool,
  }),
  preloadedTrustBenes: PropTypes.bool,
  organization: PropTypes.object,
  balanceInfo: PropTypes.object,
  showIndividualTrustBeneficiaryOverview: PropTypes.bool,
  trustBeneficiaries: PropTypes.array,
};
