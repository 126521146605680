import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import _range from 'underscore/modules/range';
import InlineFrequencySelect from './InlineFrequencySelect';
import bindAll from 'react/shared/utils/bind_all';

export default class InlineFrequencyInputs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      recurringType: this.props.recurringDisbursement.recurring_type || 'every_n_weeks',
      day_of_month: this.props.recurringDisbursement.day_of_month || 1,
      day_of_month_2: this.props.recurringDisbursement.day_of_month_2 || 15,
      day_of_week: this.props.recurringDisbursement.day_of_week || 1,
      start_date:
        this.props.recurringDisbursement.start_date || moment().format('YYYY-MM-DD').toString(),
      end_date: this.props.recurringDisbursement.end_date,
      frequency: this.props.recurringDisbursement.frequency,
      form_errors: {},
    };

    bindAll(this);
  }

  componentDidMount() {
    $(`#first_payment_date_${this.props.recurringDisbursement.id}`).datepicker({
      beforeShowDay: this.isDateValid,
      minDate: '+1D',
      maxDate: '+1Y',
      dateFormat: 'yy-mm-dd',
      onSelect: (date) => this.setStateAndErrors({ start_date: date }),
    });
    $(`#last_payment_date_${this.props.recurringDisbursement.id}`).datepicker({
      beforeShowDay: this.isDateValid,
      minDate: '+1D',
      maxDate: '+1Y',
      dateFormat: 'yy-mm-dd',
      onSelect: (date) => this.setStateAndErrors({ end_date: date }),
    });
  }

  formatDay(day, i) {
    const value = moment.weekdays().indexOf(day);
    return (
      <option key={i} value={value}>
        {day}
      </option>
    );
  }

  formatDayOfMonth(day, i) {
    return (
      <option key={i} value={i + 1}>
        {day}
      </option>
    );
  }

  formatDayOfMonth2(day, i) {
    return (
      <option key={i} value={i + 1}>
        {day}
      </option>
    );
  }

  isDateValid(date) {
    let isValid;
    const dayOfMonth = date.getDate();
    switch (this.state.recurringType) {
      case 'every_n_weeks':
        isValid = date.getDay() === Number.parseInt(this.state.day_of_week, 10);
        break;
      case 'every_n_months':
        isValid = dayOfMonth === Number.parseInt(this.state.day_of_month, 10);
        break;
      case 'twice_monthly_every_n_months':
        isValid =
          dayOfMonth === Number.parseInt(this.state.day_of_month, 10) ||
          dayOfMonth === Number.parseInt(this.state.day_of_month_2, 10);
        break;
    }

    return [isValid, ''];
  }

  setStateAndErrors(newState) {
    this.setState(newState);
    this.setDateRangeErrors();
  }

  setDateRangeErrors() {
    if (this.state.start_date && this.state.end_date) {
      const dateFrom = moment(this.state.start_date, 'YYYY-MM-DD');
      const dateTo = moment(this.state.end_date, 'YYYY-MM-DD');
      if (dateTo.isBefore(dateFrom)) {
        this.setState({
          form_errors: {
            start_date: ['Invalid date selected'],
            end_date: ['Invalid date selected'],
          },
        });
      }
      this.setState({ form_errors: {} });
    }
  }

  handleChangeDayOfWeek(ev) {
    this.initDayChange(ev);
    this.setState({ day_of_week: ev.target.value });
  }

  handleChangeDayOfMonth(ev) {
    this.initDayChange(ev);
    this.setState({ day_of_month: ev.target.value });
  }

  handleChangeDayOfMonth2(ev) {
    this.initDayChange(ev);
    this.setState({ day_of_month_2: ev.target.value });
  }

  initDayChange(ev) {
    $(ev.target).attr('value', ev.target.value);
    this.clearStartAndEndDates();
  }

  clearStartAndEndDates() {
    this.setState({
      start_date: '',
      end_date: '',
    });
  }

  listDayNames() {
    const weekdays = _range(5).map((n) => moment.weekdays(n + 1));
    return weekdays.map(this.formatDay);
  }

  listDaysOfMonth() {
    const days = _range(30).map((n) => n + 1);
    return days.map(this.formatDayOfMonth);
  }

  listDaysOfMonth2() {
    return $tlf.domains.daysOfMonth.map(this.formatDayOfMonth2);
  }

  setRecurringType(ev) {
    const recurringType = ev.target.value;
    this.clearStartAndEndDates();
    this.setState({ recurringType });
  }

  setFrequency(ev) {
    this.clearStartAndEndDates();
    this.setState({ frequency: ev.target.value });
  }

  render() {
    const day_list = this.listDayNames();
    const day_of_month_list = this.listDaysOfMonth();
    const every_week_checked =
      this.state.recurringType === undefined || this.state.recurringType === 'every_n_weeks';
    const every_month_checked = this.state.recurringType === 'every_n_months';
    const twice_monthly_checked = this.state.recurringType === 'twice_monthly_every_n_months';
    const day_of_month_2 = this.state.day_of_month_2 || 15;

    return (
      <div className="frequency-inputs--inline" style={{ minWidth: 480 }}>
        <div className="radio-group">
          <div className="margin-bottom-10">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label style={{ display: 'inline' }}>
              <input
                checked={every_week_checked}
                name="recurring_disbursement[recurring_type]"
                onChange={this.setRecurringType}
                type="radio"
                value="every_n_weeks"
              />{' '}
              Every
              <InlineFrequencySelect
                chosenRecurringType="every_n_weeks"
                frequency={this.state.frequency}
                limit={52}
                recurringType={this.state.recurringType}
                setFrequency={this.setFrequency}
              />
              week(s) on
            </label>
            <select
              defaultValue={this.state.day_of_week}
              disabled={this.state.recurringType != 'every_n_weeks'}
              name="recurring_disbursement[day_of_week]"
              onChange={this.handleChangeDayOfWeek}
              style={{ width: 120, marginLeft: 5 }}
            >
              {day_list}
            </select>
          </div>
          <div className="margin-bottom-10">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label style={{ display: 'inline' }}>
              <input
                checked={every_month_checked}
                name="recurring_disbursement[recurring_type]"
                onChange={this.setRecurringType}
                type="radio"
                value="every_n_months"
              />
              {' Every'}
              <InlineFrequencySelect
                chosenRecurringType="every_n_months"
                frequency={this.state.frequency}
                limit={12}
                recurringType={this.state.recurringType}
                setFrequency={this.setFrequency}
              />
              month(s) on
            </label>
            <select
              defaultValue={this.state.day_of_month}
              disabled={this.state.recurringType != 'every_n_months'}
              name="recurring_disbursement[day_of_month]"
              onChange={this.handleChangeDayOfMonth}
              style={{ width: 55, marginLeft: 5 }}
            >
              {day_of_month_list}
            </select>
          </div>
          <div className="margin-bottom-10">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label style={{ display: 'inline' }}>
              <input
                checked={twice_monthly_checked}
                name="recurring_disbursement[recurring_type]"
                onChange={this.setRecurringType}
                type="radio"
                value="twice_monthly_every_n_months"
              />{' '}
              Twice every
              <InlineFrequencySelect
                chosenRecurringType="twice_monthly_every_n_months"
                frequency={this.state.frequency}
                limit={12}
                recurringType={this.state.recurringType}
                setFrequency={this.setFrequency}
              />
              month(s) on the
            </label>
            <select
              defaultValue={this.state.day_of_month}
              disabled={this.state.recurringType != 'twice_monthly_every_n_months'}
              name="recurring_disbursement[day_of_month]"
              onChange={this.handleChangeDayOfMonth}
              style={{ width: 55, marginLeft: 5, marginRight: 5 }}
            >
              {day_of_month_list}
            </select>
            <span>and</span>
            <select
              defaultValue={day_of_month_2}
              disabled={this.state.recurringType != 'twice_monthly_every_n_months'}
              name="recurring_disbursement[day_of_month_2]"
              onChange={this.handleChangeDayOfMonth2}
              style={{ width: 55, marginLeft: 5 }}
            >
              {day_of_month_list}
            </select>
          </div>
        </div>
        <div className="margin-bottom-10">
          <label htmlFor={`first_payment_date_${this.props.recurringDisbursement.id}`}>
            Payment start date:
          </label>
          <input
            className="business_datepicker"
            defaultValue={this.state.start_date}
            id={`first_payment_date_${this.props.recurringDisbursement.id}`}
            name="recurring_disbursement[start_date]"
            type="text"
          />
          {this.state.form_errors.start_date && (
            <div className="form-error" style={{ marginTop: 5 }}>
              {this.state.form_errors.start_date}
            </div>
          )}
        </div>
        <div className="margin-bottom-10">
          <label htmlFor={`last_payment_date_${this.props.recurringDisbursement.id}`}>
            Payment end date:
          </label>
          <input
            className="business_datepicker"
            defaultValue={this.state.end_date}
            id={`last_payment_date_${this.props.recurringDisbursement.id}`}
            name="recurring_disbursement[end_date]"
            type="text"
          />
          {this.state.form_errors.end_date && (
            <div className="form-error" style={{ marginTop: 5 }}>
              {this.state.form_errors.end_date}
            </div>
          )}
        </div>
      </div>
    );
  }
}

InlineFrequencyInputs.propTypes = {
  recurringDisbursement: PropTypes.object.isRequired,
};
