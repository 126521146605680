const single_value_rules = [
  'blockAllTransactions',
  'allowAllTransactions',
  'blockScamWatch',
  'blockInternationalTransactions',
  'blockCardNotPresent',
  'blockATM',
  'blockSignatureCash',
  'blockPOSCash',
  'maxTransactionSizeCash',
  'maxTransactionSize',
];

const array_rules = [
  'allowlistMIDs',
  'allowlistMCCs',
  'allowlistPOSCodes',
  'allowlistMTIs',
  'allowlistMerchantNames',
  'allowlistSemantics',
  'allowlistMerchantClusters',
  'allowlistMerchants',
  'denylistMIDs',
  'denylistMCCs',
  'denylistPOSCodes',
  'denylistMTIs',
  'denylistMerchantNames',
  'denylistSemantics',
  'denylistMerchantClusters',
  'denylistMerchants',
];

export function addRules(rules, rules_to_be_added) {
  Object.keys(rules_to_be_added).forEach((ruleName) => {
    const value = rules_to_be_added[ruleName];

    if (single_value_rules.includes(ruleName)) {
      rules[ruleName] = value;
    } else if (array_rules.includes(ruleName)) {
      const list = rules[ruleName] || [];
      const items = value.split(';');
      rules[ruleName] = list.concat(items);

      // If adding an allowlist remove from denylist and vice versa
      if (ruleName.includes('allowlist') || ruleName.includes('denylist')) {
        const oppositeRuleName = ruleName.replace(/(allowlist|denylist)/g, (foundValue) =>
          foundValue === 'denylist' ? 'allowlist' : 'denylist',
        );
        const oppositeRuleValue = rules[oppositeRuleName] || [];
        const index = oppositeRuleValue.indexOf(value);
        if (index >= 0) oppositeRuleValue.splice(index, 1);
        rules[oppositeRuleName] = oppositeRuleValue;
      }
    }
  });
  return rules;
}

export function removeRules(rules, rules_to_be_removed) {
  Object.keys(rules_to_be_removed).forEach((ruleName) => {
    const value = rules_to_be_removed[ruleName];

    if (single_value_rules.includes(ruleName)) {
      delete rules[ruleName];
    } else if (array_rules.includes(ruleName)) {
      const list = rules[ruleName] || [];
      const items = value.split(';');
      const new_list = list.filter((el) => !items.includes(el));
      rules[ruleName] = new_list;
      if (new_list.length === 0) {
        delete rules[ruleName];
      }
    }
  });
  return rules;
}
