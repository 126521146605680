import PropTypes from 'prop-types';
import React from 'react';
import AbleActProgramsAlrCardQuestionnaire from './AbleActProgramsAlrCardQuestionnaire';
import AbleActProgramsOwnerTypeQuestionnaire from './AbleActProgramsOwnerTypeQuestionnaire';
import AbleActProgramsCardOrderForm from './card_order_form/AbleActProgramsCardOrderForm';
import bindAll from 'react/shared/utils/bind_all';

export default class AbleActProgramsSignup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ownerType: '',
      alrCardChoice: '',
    };

    bindAll(this);
  }

  handleOwnerTypeQuestionnaireChoice(ownerType) {
    this.setState({ ownerType });
  }

  handleAlrCardQuestionnaireChoice(alrCardChoice) {
    this.setState({ alrCardChoice });
  }

  render() {
    const { isStableSite, msaLink, programName, programNameWithArticle } = this.props;
    const { ownerType, alrCardChoice } = this.state;

    if (ownerType === '') {
      return (
        <AbleActProgramsOwnerTypeQuestionnaire
          handleQuestionnaireChoice={this.handleOwnerTypeQuestionnaireChoice}
          programName={programName}
          programNameWithArticle={programNameWithArticle}
        />
      );
    }

    const isAlr = ownerType === 'alr';

    if (isAlr && alrCardChoice === '') {
      return (
        <AbleActProgramsAlrCardQuestionnaire
          handleQuestionnaireChoice={this.handleAlrCardQuestionnaireChoice}
          programName={programName}
        />
      );
    }

    return (
      <AbleActProgramsCardOrderForm
        alrCardChoice={alrCardChoice}
        isAlr={isAlr}
        isStableSite={isStableSite}
        msaLink={msaLink}
        programName={programName}
        programNameWithArticle={programNameWithArticle}
      />
    );
  }
}

AbleActProgramsSignup.propTypes = {
  isStableSite: PropTypes.bool.isRequired,
  msaLink: PropTypes.string.isRequired,
  programName: PropTypes.string.isRequired,
  programNameWithArticle: PropTypes.string.isRequired,
};
