import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import TrueLinkLink from 'react/shared/components/true_link/main/TrueLinkLink';

const useStyles = makeStyles({
  cancel: {
    marginLeft: '12px',
  },
});

function SaveOrCancelButtons({ onCancel, onSave }) {
  const classes = useStyles();

  return (
    <div>
      <TrueLinkButton onClick={onSave} variant="primary">
        Save
      </TrueLinkButton>
      <TrueLinkLink className={classes.cancel} onClick={onCancel}>
        Cancel
      </TrueLinkLink>
    </div>
  );
}

SaveOrCancelButtons.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default SaveOrCancelButtons;
