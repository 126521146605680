export function routingNumberValid(value) {
  const requiredLength = 9;

  if (/\D/.test(value)) {
    return false;
  }
  if (value.length !== requiredLength) {
    return false;
  }

  let sum = 0;
  let multiplier = 3;
  [...value].forEach((digit) => {
    sum += Number.parseInt(digit, 10) * multiplier;

    // cycles between 3 7 and 1 as digit multiplier
    switch (multiplier) {
      case 3:
        multiplier = 7;
        break;
      case 7:
        multiplier = 1;
        break;
      case 1:
        multiplier = 3;
        break;
    }
  });

  if (sum % 10 !== 0 || sum === 0) {
    return false;
  }

  return true;
}
