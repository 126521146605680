// see also ./account_card_program#isAbleActAccount

export function isAbleActProgramAccount(programName) {
  return programName === 'ABLE' || programName === 'STABLE';
}

export function isAbleAccount(programName) {
  return programName === 'ABLE';
}

export function getProgramNameArticle(programName) {
  return programName === 'ABLE' ? 'an' : 'a';
}

export function programNameFromType(programType) {
  switch (programType) {
    case 'stable':
      return 'STABLE';
    case 'able':
      return 'ABLE';
    case 'en':
    default:
      return 'True Link';
  }
}
