import React, { useState, useMemo } from 'react';
import TrueLinkCheckboxes from './TrueLinkCheckboxes';

export default {
  title: 'Forms/Checkboxes',
  component: TrueLinkCheckboxes,
  argTypes: {
    value: {
      control: 'object',
      table: {
        category: 'Contents',
      },
    },
    options: {
      control: 'object',
      table: {
        category: 'Contents',
      },
    },
    name: {
      control: 'text',
      table: {
        category: 'Labeling',
      },
    },
    ariaLabel: {
      control: 'text',
      table: {
        category: 'Labeling',
      },
    },
    onChange: {
      table: {
        category: 'Events',
      },
    },
    required: {
      control: 'boolean',
      table: {
        category: 'Labeling',
      },
    },
  },
};

function Template(args) {
  const [value, setValue] = useState([]);
  useMemo(() => {
    setValue(args.value);
  }, [args.value]);
  const handleChange = (e) => {
    if (e.target.checked) {
      setValue(value.concat(e.target.name));
    } else {
      setValue(value.filter((name) => name !== e.target.name));
    }
    args.onChange(e);
  };

  return <TrueLinkCheckboxes {...args} onChange={handleChange} value={value} />;
}

export const Standard = Template.bind({});
Standard.args = {
  ariaLabel: 'Label',
  name: 'name',
  value: ['yes'],
  options: [
    { label: 'Yes', value: 'yes' },
    { label: 'No', value: 'no' },
    { label: 'Disabled no', value: 'no', disabled: true },
    { label: 'Disabled Yes', value: 'yes', disabled: true },
  ],
};

export const NoBorder = Template.bind({});
NoBorder.args = {
  ariaLabel: 'Label',
  name: 'name',
  value: ['yes'],
  options: [
    { label: 'Yes', value: 'yes' },
    { label: 'No', value: 'no' },
    { label: 'Disabled no', value: 'no', disabled: true },
    { label: 'Disabled Yes', value: 'yes', disabled: true },
  ],
  hideBorderStyle: true,
};
