import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';
import _extend from 'underscore/modules/extend';
import Field from './Field';
import FieldMixin from './FieldMixin';

const TextField = createReactClass({
  propTypes: {
    controller: PropTypes.object,
    editable: PropTypes.bool,
    label: PropTypes.string,
    model: PropTypes.string,
    name: PropTypes.string,
    rows: PropTypes.string,
    object: PropTypes.object,
    inputDidMount: PropTypes.func,
    value: PropTypes.node,
    format: PropTypes.func,
    required: PropTypes.bool,
    style: PropTypes.object,
  },

  mixins: [FieldMixin],

  componentDidMount() {
    this.initField();
  },

  inputDidMount(c) {
    if (this.props.inputDidMount != undefined) {
      this.props.inputDidMount(c);
    }
  },

  renderShow() {
    return <span>{this.formattedValue()}</span>;
  },

  renderEdit() {
    const style = this.props.style || {};
    return (
      <textarea
        name={this.inputName()}
        onChange={this.onChange}
        ref={this.inputDidMount}
        required={this.props.required}
        rows={this.props.rows}
        style={_extend(style, { margin: 0, padding: 0, paddingLeft: 9 })}
        type="text"
        value={this.value()}
      />
    );
  },

  render() {
    return (
      <Field
        {...this.props}
        editable={this.editable}
        label={this.label}
        onKeyUp={this.onKeyUp}
        renderEdit={this.renderEdit}
        renderShow={this.renderShow}
      />
    );
  },
});

export default TextField;
