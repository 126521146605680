import PropTypes from 'prop-types';

const { shape, node, number, string } = PropTypes;

const InProductAdShape = shape({
  id: number.isRequired,
  dismissed_at: string,
  dismissed_content_html: node.isRequired,
  undismissed_content_html: node.isRequired,
});

export default InProductAdShape;
