import PropTypes from 'prop-types';
import React from 'react';
import ReactInputMask from 'react-input-mask';
import NameInputsWithLimit from 'react/member/components/common/NameInputsWithLimit';
import bindAll from 'react/shared/utils/bind_all';

export default class AccountIdentifierAndFboDetails extends React.Component {
  static get propTypes() {
    return {
      fboEnabled: PropTypes.bool.isRequired,
      currentProUser: PropTypes.bool.isRequired,
      cardholderLastName: PropTypes.string,
    };
  }

  constructor(props) {
    super(props);

    this.state = {
      is_fbo_card: false,
      'for_benefit_of[first_name]': '',
      'for_benefit_of[last_name]': '',
      'for_benefit_of[card_name]': '',
      'for_benefit_of[dob]': '',
      'cardholder[nickname]': '',
    };

    bindAll(this);
  }

  handleInputChange({ target }) {
    this.setState({ [target.name]: target.value });
  }

  handleFboToggle({ target }) {
    this.setState({
      is_fbo_card: target.value === 'yes',
      'for_benefit_of[first_name]': '',
      'for_benefit_of[last_name]': '',
      'for_benefit_of[card_name]': '',
      'for_benefit_of[dob]': '',
      'cardholder[nickname]': '',
    });
  }

  handleFboNameChange(name, value) {
    this.setState((prevState) => {
      const firstName =
        name === 'for_benefit_of[first_name]' ? value : prevState['for_benefit_of[first_name]'];
      const lastName =
        name === 'for_benefit_of[last_name]' ? value : prevState['for_benefit_of[last_name]'];
      const cardName =
        name === 'for_benefit_of[card_name]' ? value : prevState['for_benefit_of[card_name]'];

      const fboFullName = cardName.length > 0 ? cardName : `${firstName} ${lastName}`;

      return {
        [name]: value,
        'cardholder[nickname]': fboFullName.trim().length > 0 ? `FBO ${fboFullName}` : '',
      };
    });
  }

  renderFboRadioToggle() {
    return (
      <div className="row section-separator">
        <div>Will this card be For the Benefit Of (FBO) someone other than the cardholder?</div>
        <div className="width30">
          <div className="inline-inputs">
            <label htmlFor="is_fbo_card_no">
              <input
                checked={!this.state.is_fbo_card}
                id="is_fbo_card_no"
                name="is_fbo_card"
                onChange={this.handleFboToggle}
                type="radio"
                value="no"
              />
              No
            </label>
            <label htmlFor="is_fbo_card_yes">
              <input
                checked={this.state.is_fbo_card}
                id="is_fbo_card_yes"
                name="is_fbo_card"
                onChange={this.handleFboToggle}
                type="radio"
                value="yes"
              />
              Yes
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderFboNameInputs() {
    return (
      <div id="fbo-account-name-details">
        <div className="row">
          <p className="width100">
            Tell us some information about the person this card is For the Benefit Of (FBO).
          </p>
          <NameInputsWithLimit
            inputNamePrefix="for_benefit_of"
            labelPrefix="FBO"
            lengthLimit={19}
            onFieldChange={this.handleFboNameChange}
            rowClass="row"
            useLabelAsPlaceholder
            warningFullNameOwnerPrefix="beneficiary"
          />
        </div>
      </div>
    );
  }

  renderCardholderNickname() {
    const value =
      this.state['cardholder[nickname]'].trim().length > 0
        ? this.state['cardholder[nickname]']
        : this.props.cardholderLastName || '';

    return (
      <div className="row section-separator" id="account-identifier">
        <div className="width100">
          <label htmlFor="cardholder_nickname">
            <input
              aria-label="Account identifier (optional, will not appear on the actual card)"
              id="cardholder_nickname"
              name="cardholder[nickname]"
              onChange={this.handleInputChange}
              placeholder="Account identifier (optional, will not appear on the actual card)"
              type="text"
              value={value}
            />
          </label>
        </div>
      </div>
    );
  }

  renderReadOnlyFboCardholderNickname() {
    return (
      <div className="row section-separator" id="account-identifier">
        <div className="width100 inline-inputs disabled-display-input">
          <span>Account identifier:</span>
          <input
            aria-label="Enter FBO name above"
            id="fbo-cardholder-nickname"
            name="cardholder[nickname]"
            onChange={this.handleInputChange}
            placeholder="Enter FBO name above"
            readOnly
            type="text"
            value={this.state['cardholder[nickname]']}
          />
        </div>
      </div>
    );
  }

  renderFboAdditionalInputs() {
    return (
      <div className="width100" id="fbo-account-additional-details">
        <div className="row section-separator">
          <div className="width60">
            <ReactInputMask
              aria-label="FBO date of birth"
              id="for_benefit_of_dob"
              mask="99-99-9999"
              name="for_benefit_of[dob]"
              onChange={this.handleInputChange}
              placeholder="FBO date of birth"
              required
              type="text"
              value={this.state['for_benefit_of[dob]']}
            />
          </div>
          <div className="placeholder-text">&nbsp;mm-dd-yyyy</div>
        </div>
      </div>
    );
  }

  renderFboInputs() {
    return (
      <div>
        {this.renderFboRadioToggle()}
        {this.state.is_fbo_card ? (
          <div>
            {this.renderFboNameInputs()}
            {this.renderReadOnlyFboCardholderNickname()}
            {this.renderFboAdditionalInputs()}
          </div>
        ) : (
          this.renderCardholderNickname()
        )}
      </div>
    );
  }

  renderAccountIdentifierInput() {
    if (!this.props.currentProUser) return null;

    return this.renderCardholderNickname();
  }

  render() {
    return this.props.fboEnabled ? this.renderFboInputs() : this.renderAccountIdentifierInput();
  }
}
