import PropTypes from 'prop-types';

const { shape, string, arrayOf } = PropTypes;
const paymentMethodOptionsShape = shape({
  available_bank_accounts: arrayOf(
    shape({
      id: string,
      label: string,
    }),
  ),
  available_payment_methods: arrayOf(string),
  preferred_bank_account: shape({
    id: string,
    label: string,
  }),
  preferred_payment_method: string,
});

export default paymentMethodOptionsShape;
