/* eslint-disable react/no-danger */
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import React from 'react';
import { holidayShape } from 'react/member/card/shapes/HolidayShape';
import Banner from 'react/member/components/common/Banner';

const useStyles = makeStyles({
  contentWrap: {
    '& b': {
      fontWeight: '700',
    },
  },
});

// allowing use of dangerouslySetInnerHTML since this component is served from internal data
export default function HolidayNotificationBanner({ holiday }) {
  const classes = useStyles();
  const formattedDate = moment(holiday.date).format('dddd[,] MMMM D[,] YYYY');
  const oneTimeTransferText = holiday.oneTimeTransferText;

  return (
    <Banner variant="warning">
      <div className={classes.contentWrap}>
        The upcoming holiday <b>{holiday.name}</b> on <b>{formattedDate}</b> may impact your funding
        transfers. No transfers can be initiated or made available on <b>{formattedDate}</b>.
        {oneTimeTransferText && (
          <span dangerouslySetInnerHTML={{ __html: ` ${oneTimeTransferText}` }} />
        )}
      </div>
    </Banner>
  );
}

HolidayNotificationBanner.propTypes = {
  holiday: holidayShape.isRequired,
};
