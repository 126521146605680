import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import PopUp from 'react/shared/components/popups/PopUp';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import theme from 'react/shared/theme/Theme';

const useStyles = makeStyles(() => ({
  [theme.breakpoints.down('sm')]: {
    transferWarningModal: {
      '& .modal-footer': {
        borderTop: 'none',
      },
    },
  },

  footer: {
    display: 'flex',
    flexDirection: 'row',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      paddingBottom: '10px',

      '& button': {
        width: '100%',
        padding: '10px',
        marginBottom: '10px',
      },
    },
  },
}));

export default function TransferWarningModal({
  availableDate,
  date,
  onClose,
  onSubmit,
  submitting,
}) {
  const classes = useStyles();
  const header = (
    <div>These funds will be available on {moment(availableDate).format('dddd, MMMM Do')}</div>
  );

  const footer = (
    <div className={classes.footer}>
      <TrueLinkButton
        className="btn normal"
        disabled={submitting}
        id="transfer-warning-continue-cta"
        onClick={onSubmit}
        variant="none"
      >
        Continue
      </TrueLinkButton>
      <TrueLinkButton
        className="btn btn-link"
        id="transfer-warning-cancel-cta"
        onClick={onClose}
        variant="none"
      >
        Cancel
      </TrueLinkButton>
    </div>
  );

  const body = (
    <div>
      <p>
        If you would like the transfer to be available on {moment(date).format('dddd, MMMM Do')},
        please press cancel and select today as the initiation date.
      </p>
    </div>
  );

  return (
    <div className={classes.transferWarningModal}>
      <PopUp
        footer={footer}
        header={header}
        hideDismissButton
        maxWidth="550px"
        modalOpen
        onClose={onClose}
      >
        {body}
      </PopUp>
    </div>
  );
}

TransferWarningModal.propTypes = {
  availableDate: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};
