import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

export default function CategorizedBudgetProgressBar({ largeBar, percentComplete }) {
  const style = {
    textAlign: 'right',
    paddingRight: '5px',
    paddingLeft: '5px',
    width: percentComplete <= 100 ? `${percentComplete}%` : '100%',
  };

  return (
    <div
      className={classNames('progress progress--budget', {
        'progress--large': largeBar,
      })}
    >
      <div
        aria-valuemax="100"
        aria-valuemin="0"
        aria-valuenow={percentComplete}
        className={classNames('bar', {
          'bar-success': percentComplete < 90,
          'bar-warning': percentComplete >= 90 && percentComplete < 100,
          'bar-danger': percentComplete >= 100,
        })}
        role="progressbar"
        style={style}
      >
        <span className="progress__percentage">{percentComplete}%</span>
      </div>
    </div>
  );
}

CategorizedBudgetProgressBar.propTypes = {
  largeBar: PropTypes.bool,
  percentComplete: PropTypes.number,
};
