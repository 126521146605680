import PropTypes from 'prop-types';
import React from 'react';
import { transferShape } from 'react/member/card/shapes/TransferShape';
import ActionMenuDropdown from 'react/member/components/common/ActionMenuDropdown';
import {
  delayedByHoliday,
  hasAcceptedGuidance,
  isCancelable,
  isEmergencyFundable,
  isEmergencyCreditable,
  isRequested,
  isRecurring,
  beforeFlipDate,
} from 'react/member/utils/Transfer';

export default function TransferOptionsMenu({
  isFullyFunded,
  onEditOneTime,
  onEditRecurring,
  onEmergencyFund,
  onFixHolidayImpact,
  onRevert,
  onSkipCancel,
  transfer,
}) {
  if (!transfer.editable && !transfer.allowCancel) return null;
  const editFn = isRecurring(transfer) ? onEditRecurring : onEditOneTime;

  const cancel = { displayText: 'Cancel transfer', action: onSkipCancel };
  const edit = { displayText: 'Edit transfer', action: editFn };
  const emergencyFund = { displayText: 'Emergency fund', action: onEmergencyFund };
  const skip = { displayText: 'Skip transfer', action: onSkipCancel };
  const fixHolidayImpact = {
    displayText: 'How to fix',
    action: onFixHolidayImpact,
    color: '#FC824E',
  };
  const revert = { displayText: 'Revert to original', action: onRevert };

  const items = [
    isRequested(transfer) && delayedByHoliday(transfer) && beforeFlipDate(transfer)
      ? fixHolidayImpact
      : null,
    isRequested(transfer) && hasAcceptedGuidance(transfer) ? revert : null,
    (isFullyFunded && isEmergencyFundable(transfer)) || isEmergencyCreditable(transfer)
      ? emergencyFund
      : null,
    transfer.allowSkip ? skip : null,
    isCancelable(transfer) ? cancel : null,
    transfer.editable ? edit : null,
  ].filter((item) => item !== null);

  return <ActionMenuDropdown menuItems={items} menuLabel="Options" />;
}

TransferOptionsMenu.propTypes = {
  isFullyFunded: PropTypes.bool.isRequired,
  onEditOneTime: PropTypes.func.isRequired,
  onEditRecurring: PropTypes.func.isRequired,
  onEmergencyFund: PropTypes.func.isRequired,
  onFixHolidayImpact: PropTypes.func.isRequired,
  onRevert: PropTypes.func.isRequired,
  onSkipCancel: PropTypes.func.isRequired,
  transfer: transferShape.isRequired,
};
