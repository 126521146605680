import Cookies from 'js-cookie';

(window.onpopstate = function () {
  var match,
    pl = /\+/g, // Regex for replacing addition symbol with a space
    search = /([^&=]+)=?([^&]*)/g,
    decode = function(s) { return decodeURIComponent(s.replace(pl, " ")); },
    query = window.location.search.substring(1),
    urlParams = {};

  while (match = search.exec(query)) {
    urlParams[decode(match[1])] = decode(match[2]);
  }

  const utm_vars = Cookies.get('utm_vars') ? JSON.parse(Cookies.get('utm_vars')) : {};

  for (var i in urlParams) {
    i = i.toLowerCase();
    if (utm_vars[i] || !i.startsWith('utm_')) continue;
    utm_vars[i] = urlParams[i];
  }

  Cookies.set('utm_vars', JSON.stringify(utm_vars));
})();
