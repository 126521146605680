import _extend from 'underscore/modules/extend';
import _find from 'underscore/modules/find';
import _findWhere from 'underscore/modules/findWhere';
import _sortBy from 'underscore/modules/sortBy';
import _without from 'underscore/modules/without';
import DashboardActions from 'react/member/actions/dashboard_actions';

const DashboardNoteStore = flux.createStore({
  notes: [],
  primaryNote: {},
  actions: [
    DashboardActions.addClientNote,
    DashboardActions.destroyClientNote,
    DashboardActions.fetchDashboardNotes,
    DashboardActions.fetchPrimaryNote,
    DashboardActions.updateDashboardNote,
  ],

  afterCreateDisbursementNoteCallback(callback, note) {
    if (!note.is_stream) {
      this.primaryNote = note;
      this.emit('dashboardNote.add', note);
    } else {
      this.addNote(note);
      this.emit('dashboardNote.add', this.notes);
    }
    Truelink.flash('success', 'New Note created!');
    if (callback) {
      callback(note);
    }
  },

  addClientNote(client_id, data, callback) {
    $.ajax({
      url: RailsRoutes.dashboard_client_notes_path(client_id, { format: 'json' }),
      xhrFields: { withCredentials: true },
      data: JSON.stringify(data),
      contentType: 'application/json; charset=utf-8',
      method: 'POST',
    })
      .done((note) => {
        if (!note.is_stream) {
          this.primaryNote = note;
          this.emit('dashboardPrimaryNote.add', note);
        } else {
          this.addNote(note);
          this.emit('dashboardNote.add', this.notes);
        }
        Truelink.flash('success', 'New Note created!');
        if (callback) {
          callback(note);
        }
      })
      .fail(() => {
        Truelink.flash(
          'error',
          'Unable to create note. Please contact True Link support for further assistance.',
        );
      });
  },

  destroyClientNote(client_id, id) {
    $.ajax({
      url: RailsRoutes.dashboard_client_note_path(client_id, id, { format: 'json' }),
      type: 'DELETE',
      dataType: 'json',
    })
      .done(() => {
        this.removeNote(id);
        this.emit('dashboardNote.destroy', this.notes);
        Truelink.flash('success', 'Note deleted!');
      })
      .fail(() => {
        Truelink.flash(
          'error',
          'Unable to delete note. Please contact True Link support for further assistance.',
        );
      });
  },

  fetchDashboardNotes(client_id) {
    $.ajax({
      url: RailsRoutes.dashboard_client_notes_path(client_id, { format: 'json' }),
      xhrFields: { withCredentials: true },
      dataType: 'json',
      method: 'GET',
    }).done((notes) => {
      this.notes = this.sortNotes(notes);
      this.emit('dashboardNotes.fetch', this.notes);
    });
  },

  fetchPrimaryNote(client_id) {
    $.ajax({
      url: RailsRoutes.dashboard_client_primary_note_path(client_id, { format: 'json' }),
      xhrFields: { withCredentials: true },
      dataType: 'json',
      method: 'GET',
    }).done((note) => {
      this.primaryNote = note;
      this.emit('dashboardPrimaryNote.fetch', note);
    });
  },

  updateDashboardNote(client_id, id, data) {
    $.ajax({
      url: RailsRoutes.dashboard_client_note_path(client_id, id, { format: 'json' }),
      xhrFields: { withCredentials: true },
      data: JSON.stringify(data),
      contentType: 'application/json; charset=utf-8',
      method: 'PUT',
    })
      .done((note) => {
        if (!note.is_stream) {
          this.primaryNote = note;
          this.emit('dashboardPrimaryNote.update', note);
        } else {
          this.updateNote(note);
          this.emit('dashboardNote.update', this.notes);
        }
        Truelink.flash('success', 'Note updated!');
      })
      .fail(() => {
        Truelink.flash('error', 'Unable to update note! Please contact True Link support.');
      });
  },

  get(id) {
    return _find(this.notes, (note) => note.id === id);
  },

  getNotes() {
    return this.notes;
  },

  getPrimaryNote() {
    return this.primaryNote;
  },

  sortNotes(notes) {
    return _sortBy(_sortBy(notes, 'created_at'), 'is_important').reverse();
  },

  updateNote(note) {
    let { notes } = this;
    _extend(_findWhere(notes, { id: note.id }), note);
    notes = this.sortNotes(notes);
    return (this.notes = notes);
  },

  removeNote(id) {
    let { notes } = this;
    notes = _without(notes, _findWhere(notes, { id }));
    return (this.notes = notes);
  },

  addNote(note) {
    let { notes } = this;
    notes.unshift(note);
    notes = this.sortNotes(notes);
    return (this.notes = notes);
  },
});

export default DashboardNoteStore;
