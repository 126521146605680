import PropTypes from 'prop-types';
import React from 'react';
import BlockedTransactionAlerts from './BlockedTransactionAlerts';
import TopLineAlerts from './TopLineAlerts';
import AlertPreferences from './alert_preferences/AlertPreferences';
import DashboardActions from 'react/member/actions/dashboard_actions';
import LoadingIndicator from 'react/shared/components/LoadingIndicator';
import SubSectionHeader from 'react/shared/components/SubSectionHeader';
import DashboardRulesetStore from 'react/shared/stores/DashboardRulesetStore';
import bindAll from 'react/shared/utils/bind_all';
import { callAPI, getUrl } from 'react/shared/utils/call_api';
import { addRules, removeRules } from 'react/shared/utils/ruleset';

export default class AlertList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      accountLoaded: false,
      rulesetLoaded: false,
      organizationLoaded: false,
      contactInfo: {},
      additionalEmails: [],
      allOrgAdminEmails: [],
      notificationPrefs: {},
      warnRules: {},
      programName: '',
      allowsFamilyFunder: false,
      showClosedCardCheckbox: false,
    };

    bindAll(this);
  }

  componentDidMount() {
    DashboardRulesetStore.on('accountInfo.fetch', this.onAccountInfoLoaded);
    DashboardRulesetStore.on('rulesetWarnRules.fetch', this.onRulesetInfoLoaded);
    DashboardRulesetStore.on('organizationInfo.fetch', this.onOrganizationInfoLoaded);
    DashboardRulesetStore.on('account.additionalEmails.updated', this.onAdditionalEmailsUpdated);
    DashboardRulesetStore.on(
      'account.notificationPreferences.updated',
      this.onNotificationPreferencesUpdated,
    );
    DashboardRulesetStore.on('person.mobile.updated', this.onMobileUpdated);

    DashboardActions.fetchAccountInfo(this.props.accountId);
    DashboardActions.fetchRulesetWarnRules(this.props.accountId);
    DashboardActions.fetchOrganizationInfo(this.props.accountId);
    $('[data-toggle="tooltip"]').tooltip();
  }

  componentWillUnmount() {
    DashboardRulesetStore.off('accountInfo.fetch', this.onAccountInfoLoaded);
    DashboardRulesetStore.off('rulesetWarnRules.fetch', this.onRulesetInfoLoaded);
    DashboardRulesetStore.off('organizationInfo.fetch', this.onOrganizationInfoLoaded);
    DashboardRulesetStore.off('account.additionalEmails.updated', this.onAdditionalEmailsUpdated);
    DashboardRulesetStore.off(
      'account.notificationPreferences.updated',
      this.onNotificationPreferencesUpdated,
    );
    DashboardRulesetStore.off('person.mobile.updated', this.onMobileUpdated);
  }

  onAccountInfoLoaded() {
    this.setState({
      accountLoaded: true,
      notificationPrefs: DashboardRulesetStore.getNotificationPrefs(),
      contactInfo: DashboardRulesetStore.getContactInfo(),
      additionalEmails: DashboardRulesetStore.getAdditionalEmails(),
      programName: DashboardRulesetStore.getProgramName(),
      allowsFamilyFunder: DashboardRulesetStore.getAllowsFamilyFunder(),
      showClosedCardCheckbox: DashboardRulesetStore.getShowClosedCardCheckbox(),
    });
  }

  onRulesetInfoLoaded() {
    this.setState({
      rulesetLoaded: true,
      warnRules: DashboardRulesetStore.getWarnRules(),
    });
  }

  onOrganizationInfoLoaded() {
    this.setState({
      organizationLoaded: true,
      allOrgAdminEmails: DashboardRulesetStore.getUserEmails(),
    });
  }

  onAdditionalEmailsUpdated() {
    this.setState({ additionalEmails: DashboardRulesetStore.getAdditionalEmails() });
  }

  onNotificationPreferencesUpdated() {
    this.setState({ notificationPrefs: DashboardRulesetStore.getNotificationPrefs() });
  }

  onMobileUpdated() {
    this.setState({ contactInfo: DashboardRulesetStore.getContactInfo() });
  }

  requestUpdateNotificationPrefs(changedPref) {
    DashboardActions.updateNotificationPreference(this.props.accountId, changedPref);
  }

  addWarnRules(changed_rules, element_clicked) {
    // update state in place
    const updated_warn_rules = addRules(this.state.warnRules, changed_rules);
    this.setState({ warnRules: updated_warn_rules });

    // call API to update data on server, then update state again with response
    const url = getUrl('add_warn_rules', this.props.accountId);
    callAPI(url, changed_rules, element_clicked).then((data) =>
      DashboardRulesetStore.updateWarnRules(data.warnRules),
    );
  }

  removeWarnRules(changed_rules, element_clicked) {
    // update state in place
    const updated_warn_rules = removeRules(this.state.warnRules, changed_rules);
    this.setState({
      warnRules: updated_warn_rules,
    });

    // call API to update data on server, then update state again with response
    const url = getUrl('remove_warn_rules', this.props.accountId);
    callAPI(url, changed_rules, element_clicked).then((data) =>
      DashboardRulesetStore.updateWarnRules(data.warnRules),
    );
  }

  requestAddAdditionalEmail(email) {
    DashboardActions.addAdditionalEmail(this.props.accountId, email);
  }

  requestRemoveAdditionalEmail(email) {
    DashboardActions.removeAdditionalEmail(this.props.accountId, email);
  }

  requestUpdateMobile(mobile) {
    DashboardActions.updateMobileForNotification(this.props.accountId, mobile);
  }

  render() {
    if (!this.state.rulesetLoaded || !this.state.accountLoaded || !this.state.organizationLoaded) {
      return <LoadingIndicator containerStyle={{ marginTop: 10 }} />;
    }

    const {
      warnRules,
      contactInfo,
      additionalEmails,
      allOrgAdminEmails,
      notificationPrefs,
      programName,
      allowsFamilyFunder,
      showClosedCardCheckbox,
    } = this.state;

    return (
      <div className="text-left">
        <section className="tab-section">
          <SubSectionHeader>Top-line alerts</SubSectionHeader>
          <TopLineAlerts
            addWarnRules={this.addWarnRules}
            allowsFamilyFunder={allowsFamilyFunder}
            notificationPrefs={notificationPrefs}
            onUpdatePrefs={this.requestUpdateNotificationPrefs}
            programName={programName}
            removeWarnRules={this.removeWarnRules}
            warn_rules={warnRules}
          />
        </section>
        <br />

        <section className="tab-section">
          <SubSectionHeader>Blocked transaction alerts</SubSectionHeader>
          <BlockedTransactionAlerts
            notificationPrefs={notificationPrefs}
            onUpdatePrefs={this.requestUpdateNotificationPrefs}
            showClosedCardCheckbox={showClosedCardCheckbox}
          />
        </section>
        <br />

        <section className="tab-section">
          <SubSectionHeader>Alert preferences</SubSectionHeader>
          <AlertPreferences
            additionalEmails={additionalEmails}
            allOrgAdminEmails={allOrgAdminEmails}
            contactInfo={contactInfo}
            notificationPrefs={notificationPrefs}
            onAddAdditionalEmail={this.requestAddAdditionalEmail}
            onRemoveAdditionalEmail={this.requestRemoveAdditionalEmail}
            onUpdateMobile={this.requestUpdateMobile}
            onUpdatePrefs={this.requestUpdateNotificationPrefs}
          />
        </section>
        <br />
      </div>
    );
  }
}

AlertList.propTypes = {
  accountId: PropTypes.string.isRequired,
};
