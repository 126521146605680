require('bootstrap-sass/js/bootstrap-transition');
require('bootstrap-sass/js/bootstrap-affix');
require('bootstrap-sass/js/bootstrap-alert');
require('bootstrap-sass/js/bootstrap-button');
require('bootstrap-sass/js/bootstrap-carousel');
require('bootstrap-sass/js/bootstrap-collapse');
require('bootstrap-sass/js/bootstrap-dropdown');
require('bootstrap-sass/js/bootstrap-modal');
require('bootstrap-sass/js/bootstrap-scrollspy');
require('bootstrap-sass/js/bootstrap-tab'); // app/javascript/react/shared/components/TabNavigation.js and others
require('bootstrap-sass/js/bootstrap-tooltip');
require('bootstrap-sass/js/bootstrap-popover');
require('bootstrap-sass/js/bootstrap-typeahead');
