import PropTypes from 'prop-types';
import React from 'react';
import {
  addError,
  removeError,
  getPayeeErrorInitialState,
  checkErrored,
  listErrors,
  updateParentErrorState,
} from 'react/jaffe/components/forms/PayeeErrors';
import bindAll from 'react/shared/utils/bind_all';

export default class PayeeName extends React.Component {
  constructor(props) {
    super(props);
    this.state = getPayeeErrorInitialState.bind(this)();

    bindAll(this);
  }

  componentDidUpdate(prevProps, prevState) {
    checkErrored.bind(this)();
    if (prevState.errored !== this.state.errored || !this.state.canUpdateParentErrorState) {
      return updateParentErrorState.bind(this)();
    }
  }

  validate(ev) {
    if (ev.target.value.length <= 2) {
      return addError.bind(this)({ invalidLength: 'Payee name must be longer than 2 characters.' });
    }
    return removeError.bind(this)('invalidLength');
  }

  render() {
    let inputClassName = 'new-form__data ';
    if (this.state.errored) inputClassName += 'validate-error';

    return (
      <div className="new-form-field">
        <div className="new-form__label">
          <label htmlFor="payee_name">Payee Name</label>
        </div>
        <div className={inputClassName}>
          <input
            className="form-control"
            defaultValue={this.props.name}
            id="payee_name"
            maxLength="48"
            name="name"
            onBlur={this.validate}
            type="text"
          />
          <ul className="new-form__error">{listErrors.bind(this)()}</ul>
        </div>
      </div>
    );
  }
}

PayeeName.propTypes = {
  name: PropTypes.string,
  // These are a hack we needed for removing a mix-in. These props are inspected by a Store that in bound to this component.
  // eslint-disable-next-line react/no-unused-prop-types
  addError: PropTypes.func,
  // eslint-disable-next-line react/no-unused-prop-types
  removeError: PropTypes.func,
  // eslint-disable-next-line react/no-unused-prop-types
  parentError: PropTypes.object,
};

PayeeName.defaultProps = {
  possibleErrors: {},
};
