import DashboardActions from 'react/member/actions/dashboard_actions';

const DashboardBudgetItemAttachmentStore = flux.createStore({
  attachments: [],
  allCachedAttachments: [],

  actions: [
    DashboardActions.fetchBudgetItemAttachmentsByBudgetItem,
    DashboardActions.fetchAttachmentsByDisbursementCategoryAmount,
    DashboardActions.destroyBudgetItemAttachment,
    DashboardActions.destroyBudgetItemAttachmentForBudgetItem,
  ],

  addCachedAttachments(attachments) {
    attachments.forEach((attachment) => {
      if (this.allCachedAttachments.filter((e) => e.id === attachment.id).length === 0) {
        this.allCachedAttachments.push(attachment);
      }
    });
  },

  destroyBudgetItemAttachment(attachmentId) {
    return $.ajax({
      url: RailsRoutes.api_v2_attachment_path(attachmentId, { format: 'json' }),
      type: 'DELETE',
      dataType: 'json',
    }).done((_result) => {
      this.emit('attachments.destroy', attachmentId);
    });
  },

  destroyBudgetItemAttachmentForBudgetItem(attachmentId, budgetItem) {
    return $.ajax({
      url: RailsRoutes.api_v2_attachment_path(attachmentId, { format: 'json' }),
      type: 'DELETE',
      dataType: 'json',
    }).done((_result) => {
      this.fetchBudgetItemAttachmentsByBudgetItem(budgetItem);
    });
  },

  fetchBudgetItemAttachmentsByBudgetItem(budgetItem) {
    let params;
    if (budgetItem.is_recurring) {
      params = {
        attachableType: 'RecurringBudgetItem',
        attachableId: budgetItem.id,
        format: 'json',
      };
    } else {
      params = { attachableType: 'BudgetItem', attachableId: budgetItem.id, format: 'json' };
    }
    return $.get(RailsRoutes.api_v2_attachments_path(params), (attachments) => {
      const formattedAttachments = attachments.data.map((attachment) => ({
        ...attachment.attributes,
        id: attachment.id,
      }));
      this.addCachedAttachments(formattedAttachments);
      this.attachments = formattedAttachments;
      this.emit('attachments.fetch', budgetItem.id, formattedAttachments);
    });
  },

  fetchAttachmentsByDisbursementCategoryAmount(disbursementCategoryAmount) {
    const params = {
      attachableType: 'DisbursementCategoryAmount',
      attachableId: disbursementCategoryAmount.dca_id,
      format: 'json',
    };

    return $.get(RailsRoutes.api_v2_attachments_path(params), (attachments) => {
      const formattedAttachments = attachments.data.map((attachment) => ({
        ...attachment.attributes,
        id: attachment.id,
      }));
      this.addCachedAttachments(formattedAttachments);
      this.attachments = formattedAttachments;
      this.emit('attachments.fetchForDCA', disbursementCategoryAmount.dca_id, formattedAttachments);
    });
  },

  exports: {
    // This must be here as an export (instead of an action),
    // because actions have their arguments serialized
    // ...and we need the real input element
    uploadAttachment(fileInputName, fileInputFile, inputNumber, includeAsInsert) {
      const formData = new window.FormData();
      formData.append(fileInputName, fileInputFile);

      if (includeAsInsert && fileInputFile.type !== 'application/pdf') {
        Truelink.flash('error', 'You can only include a pdf file as an insert. Please try again.');
        this.emit('attachment.upload', null);
        return;
      }

      formData.append(
        'attachment[smartpayables_insert]',
        // Ideally this would be tighter controlled, but it is legacy
        // eslint-disable-next-line no-eq-null
        includeAsInsert == null ? false : includeAsInsert,
      );

      const url = RailsRoutes.api_v2_attachments_path({ format: 'json' });

      this.emit('attachment.uploading');
      return $.ajax({
        url,
        xhrFields: { withCredentials: true },
        type: 'POST',
        data: formData,
        enctype: 'multipart/form-data',
        processData: false,
        contentType: false,
        dataType: 'json',
      })
        .done((attachment) => {
          // Ideally this would be tighter controlled, but it is legacy
          // eslint-disable-next-line no-eq-null
          this.emit(
            'attachment.upload',
            { ...attachment.data.attributes, id: attachment.data.id },
            inputNumber === null ? false : inputNumber,
          );
        })
        .fail(() => {
          Truelink.flash(
            'error',
            'An error occurred while uploading the attachment. Please check the file size is less than 25MB.',
          );
          this.emit('attachment.upload', null);
        });
    },

    uploadAttachmentToBudgetItem(
      fileInputName,
      fileInputFile,
      includeAsInsert,
      attachableType,
      attachableId,
      doneCallback,
    ) {
      const formData = new window.FormData();
      formData.append(fileInputName, fileInputFile);
      formData.append(
        'attachment[smartpayables_insert]',
        // Ideally this would be tighter controlled, but it is legacy
        // eslint-disable-next-line no-eq-null
        includeAsInsert == null ? false : includeAsInsert,
      );

      if (includeAsInsert && fileInputFile.type !== 'application/pdf') {
        Truelink.flash('error', 'You can only include a pdf file as an insert. Please try again.');
        this.emit('attachment.upload', null);
        return;
      }

      const url = RailsRoutes.api_v2_attachments_path({
        format: 'json',
        attachableId,
        attachableType,
      });

      this.emit('attachment.uploading');
      return $.ajax({
        url,
        xhrFields: { withCredentials: true },
        type: 'POST',
        data: formData,
        enctype: 'multipart/form-data',
        processData: false,
        contentType: false,
        dataType: 'json',
      })
        .done(doneCallback)
        .fail(() => {
          Truelink.flash(
            'error',
            'An error occurred while uploading the attachment. Please check the file size is less than 25MB.',
          );
          this.emit('attachment.upload', null);
        });
    },

    getAttachments() {
      return this.attachments;
    },

    addAttachment(attachment) {
      this.addCachedAttachments([attachment]);
      return this.attachments.push(attachment);
    },

    getCachedAttachments(attachmentIds) {
      return this.allCachedAttachments.filter((e) =>
        attachmentIds.includes(Number.parseInt(e.id, 10)),
      );
    },
  },
});
DashboardBudgetItemAttachmentStore.setMaxListeners(100);
export default DashboardBudgetItemAttachmentStore;
