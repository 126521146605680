import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Textarea from 'react-textarea-autosize';
import DashboardActions from 'react/member/actions/dashboard_actions';
import DashboardNoteStore from 'react/member/stores/beneficiary_dashboard/DashboardNoteStore';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import bindAll from 'react/shared/utils/bind_all';

export default class DashboardTrustBeneficiaryProfileActivityNotesForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      noteFormContent: '',
      isSubmitting: false,
    };

    bindAll(this);
  }

  componentDidMount() {
    DashboardNoteStore.on('dashboardNote.add', this.onSubmissionFinish);
  }

  componentWillUnmount() {
    DashboardNoteStore.off('dashboardNote.add', this.onSubmissionFinish);
  }

  onSubmissionFinish() {
    this.setState({ isSubmitting: false });
  }

  onChangeFormNoteContent(ev) {
    this.setState({ noteFormContent: ev.target.value });
  }

  noteData() {
    const { noteFormContent } = this.state;
    const data = { is_stream: true };

    data.note_content = noteFormContent;

    return data;
  }

  createNote(ev) {
    ev.preventDefault();
    const { trustBeneficiary } = this.props;
    const client_id = trustBeneficiary.slug;
    DashboardActions.addClientNote(client_id, this.noteData(), this.clearFormNoteContent);
    this.setState({ isSubmitting: true });
  }

  clearFormNoteContent() {
    this.setState({ noteFormContent: '' });
  }

  render() {
    const { noteFormContent, isSubmitting } = this.state;

    return (
      <form onSubmit={this.createNote}>
        <Textarea
          cols="100"
          id="new_note_content"
          maxRows="10"
          name="dashboard_note[note_content]"
          onChange={this.onChangeFormNoteContent}
          rows="2"
          style={{ width: '100%' }}
          value={noteFormContent}
        />
        <br />
        <TrueLinkButton
          className={classNames('btn btn-success', {
            disabled: isSubmitting,
          })}
          disabled={isSubmitting}
          type="submit"
          variant="none"
        >
          {isSubmitting ? 'Submitting...' : 'Submit'}
        </TrueLinkButton>
        <br />
      </form>
    );
  }
}

DashboardTrustBeneficiaryProfileActivityNotesForm.propTypes = {
  trustBeneficiary: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    trust: PropTypes.shape({
      organization: PropTypes.shape({}),
    }).isRequired,
  }).isRequired,
};
