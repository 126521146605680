import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import _range from 'underscore/modules/range';
import _times from 'underscore/modules/times';
import bindAll from 'react/shared/utils/bind_all';

export default class FrequencyInputs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      recurringType: props.formData.recurring_type || 'every_n_weeks',
      frequency: props.formData.frequency || 1,
      day_of_week: props.formData.day_of_week || 1,
      day_of_month: props.formData.day_of_month || 1,
      day_of_month_2: props.formData.day_of_month_2 || 15,
      start_date: props.formData.start_date || null,
      end_date: props.formData.end_date || null,
      recurringStartDateError: props.formData.start_date
        ? ''
        : 'A valid start date must be selected',
      recurringEndDateError: '',
    };

    bindAll(this);
  }

  componentDidMount() {
    const { maxDate } = this.props;
    $('#first_payment_date').datepicker({
      beforeShowDay: this.isDateValid,
      minDate: '+1D',
      maxDate,
      dateFormat: 'yy-mm-dd',
      onSelect: this.onStartDateSelected,
    });
    $('#payment_end_date').datepicker({
      beforeShowDay: this.isDateValid,
      minDate: '+1D',
      dateFormat: 'yy-mm-dd',
      onSelect: this.onEndDateSelected,
    });
    $('#first_payment_date').datepicker('setDate', this.state.start_date);
    $('#payment_end_date').datepicker('setDate', this.state.end_date);
    if (this.props.updateRecurringDeposit) {
      return this.props.updateRecurringDeposit(this.state);
    }

    $('#first_payment_date').change((event) => this.onStartDateSelected(event.target.value));
    $('#payment_end_date').change((event) => this.onEndDateSelected(event.target.value));
  }

  componentDidUpdate(_prevProps, prevState) {
    if (prevState !== this.state) {
      if (this.props.updateRecurringDeposit) {
        return this.props.updateRecurringDeposit(this.state);
      }
    }
  }

  formatDay(day, i) {
    const value = moment.weekdays().indexOf(day);
    return (
      <option key={i} value={value}>
        {day}
      </option>
    );
  }

  formatDayOfMonth(day, i) {
    return (
      <option key={i} value={i + 1}>
        {day}
      </option>
    );
  }

  isDateValid(date) {
    let frequency;
    const dayOfMonth = date.getDate();
    switch (this.state.recurringType) {
      case 'every_n_weeks':
        frequency = date.getDay() === Number.parseInt(this.state.day_of_week, 10);
        break;
      case 'every_n_months':
        frequency = dayOfMonth === Number.parseInt(this.state.day_of_month, 10);
        break;
      case 'twice_monthly_every_n_months':
        frequency =
          dayOfMonth === Number.parseInt(this.state.day_of_month, 10) ||
          dayOfMonth === Number.parseInt(this.state.day_of_month_2, 10);
        break;
    }

    const validDate = frequency;
    return [validDate, ''];
  }

  onStartDateSelected(dateString) {
    this.setState({ start_date: dateString });
    return this.validateDateRange(dateString, this.state.end_date);
  }

  onEndDateSelected(dateString) {
    this.setState({ end_date: dateString });
    return this.validateDateRange(this.state.start_date, dateString);
  }

  validateDateRange(startDateStr, endDateStr) {
    this.validateStartDate(startDateStr);
    this.validateEndDate(endDateStr);

    if (startDateStr && endDateStr) {
      const startDate = moment.tz(startDateStr, moment.tz.guess()).toDate();
      const endDate = moment.tz(endDateStr, moment.tz.guess()).toDate();
      if (startDate > endDate) {
        const error = 'Start date must be before end date';
        this.setState({ recurringStartDateError: error });
      }
    }
  }

  validateStartDate(dateString = this.state.start_date) {
    const date = moment.tz(dateString, moment.tz.guess()).toDate();
    const error = this.isDateValid(date)[0] ? '' : 'A valid start date must be selected';
    this.setState({ recurringStartDateError: error });
  }

  validateEndDate(dateString = this.state.end_date) {
    if (!dateString) {
      this.setState({ recurringEndDateError: '' });
      return;
    }

    const date = moment.tz(dateString, moment.tz.guess()).toDate();
    const error = this.isDateValid(date)[0] ? '' : 'A valid end date must be selected';
    this.setState({ recurringEndDateError: error });
  }

  handleChange(ev) {
    const { target } = ev;

    const partialState = {};
    partialState[target.name] = target.value;
    this.setState(partialState);
    $(target).attr('value', target.value);

    $('#first_payment_date, #payment_end_date').datepicker('refresh');
    this.validateDateRange(this.state.start_date, this.state.end_date);
  }

  listDayNames() {
    const weekdays = _range(5).map((n) => moment.weekdays(n + 1));
    return weekdays.map(this.formatDay);
  }

  listDaysOfMonth() {
    const days = _range(30).map((n) => n + 1);
    return days.map(this.formatDayOfMonth);
  }

  setRecurringType(ev) {
    const recurringType = ev.target.value;
    this.setState({ recurringType });
    return $('#first_payment_date, #payment_end_date').datepicker('refresh');
  }

  renderInfoWarning() {
    if (this.props.subclass !== 'deposit') {
      return (
        <div className="info-box--warning" style={{ marginTop: 17 }}>
          A payment will be released on the selected first payment date. If you'd also like a
          payment to be released today, please create a One-Time request in addition to this
          recurring payment.
        </div>
      );
    }
  }

  renderFrequencyInput(chosenRecurringType, limit) {
    const { recurringType } = this.state;
    const options = _times(limit, (i) => (
      <option key={i} value={i + 1}>
        {i + 1}
      </option>
    ));
    return (
      <select
        defaultValue={this.props.formData.frequency || 1}
        disabled={recurringType != chosenRecurringType}
        name="frequency"
        onChange={this.handleChange}
        style={{ width: 50, marginLeft: 8, marginRight: 8 }}
      >
        {options}
      </select>
    );
  }

  renderWeeksInput() {
    const { recurringType } = this.state;
    const enabled = recurringType === 'every_n_weeks';
    return (
      <div style={{ marginBottom: 10 }}>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className="inline-block">
          <input
            checked={enabled}
            name="recurring_type"
            onChange={this.setRecurringType}
            type="radio"
            value="every_n_weeks"
          />
          Every {this.renderFrequencyInput('every_n_weeks', 52)} week(s) on
          <select
            defaultValue={this.props.formData.day_of_week}
            disabled={!enabled}
            name="day_of_week"
            onChange={this.handleChange}
            style={{ width: 140, marginLeft: 8 }}
          >
            {this.listDayNames()}
          </select>
        </label>
      </div>
    );
  }

  renderMonthsInput() {
    const { recurringType } = this.state;
    const enabled = recurringType === 'every_n_months';
    return (
      <div style={{ marginBottom: 10 }}>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className="inline-block">
          <input
            checked={enabled}
            name="recurring_type"
            onChange={this.setRecurringType}
            type="radio"
            value="every_n_months"
          />
          Every {this.renderFrequencyInput('every_n_months', 12)} month(s) on
          <select
            defaultValue={this.props.formData.day_of_month}
            disabled={!enabled}
            name="day_of_month"
            onChange={this.handleChange}
            style={{ width: 75, marginLeft: 8 }}
          >
            {this.listDaysOfMonth()}
          </select>
        </label>
      </div>
    );
  }

  renderTwiceMonthlyInput() {
    const { recurringType } = this.state;
    const enabled = recurringType === 'twice_monthly_every_n_months';
    return (
      <div style={{ marginBottom: 10 }}>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className="inline-block">
          <input
            checked={enabled}
            name="recurring_type"
            onChange={this.setRecurringType}
            type="radio"
            value="twice_monthly_every_n_months"
          />
          Twice every {this.renderFrequencyInput('twice_monthly_every_n_months', 12)} month(s) on
          the
          <select
            defaultValue={this.props.formData.day_of_month}
            disabled={!enabled}
            name="day_of_month"
            onChange={this.handleChange}
            style={{ width: 75, marginLeft: 8, marginRight: 8 }}
          >
            {this.listDaysOfMonth()}
          </select>
          and
          <select
            defaultValue={
              this.props.formData.day_of_month_2 ? this.props.formData.day_of_month_2 : 15
            }
            disabled={!enabled}
            name="day_of_month_2"
            onChange={this.handleChange}
            style={{ width: 75, marginLeft: 8 }}
          >
            {this.listDaysOfMonth()}
          </select>
        </label>
      </div>
    );
  }

  renderWeekendWarning() {
    const { recurringType } = this.state;
    if (recurringType === 'every_n_months' || recurringType === 'twice_monthly_every_n_months') {
      return (
        <div className="info-box--warning" style={{ marginTop: 17 }}>
          Any disbursements scheduled for a weekend day will be processed on the first following
          business day
        </div>
      );
    }
  }

  renderStartDate() {
    const { subclass } = this.props;
    return (
      <div>
        <div className="new-form__label">
          <label htmlFor="first_payment_date">
            {subclass === 'deposit' ? 'First Deposit' : 'First Payment'}
          </label>
        </div>
        <div className="new-form__data--inline">
          <div className="datepicker-wrapper">
            <input
              autoComplete="off"
              className="business_datepicker"
              id="first_payment_date"
              name="start_date"
              onChange={this.handleChange}
              placeholder="YYYY-MM-DD"
              type="text"
            />
          </div>
          <div
            className="new-form__error"
            id="first-payment-date-error"
            style={{ marginLeft: '1em' }}
          >
            {this.state.recurringStartDateError}
          </div>
          {this.renderInfoWarning()}
        </div>
      </div>
    );
  }

  renderEndDate() {
    const { subclass } = this.props;
    if (subclass != 'disbursement' && subclass != 'deposit') {
      return;
    }

    return (
      <div className="clearfix">
        <div className="new-form__label new-form__label--optional">
          <label htmlFor="end_date">
            {subclass === 'deposit' ? 'Final Deposit' : 'Final Payment'}
          </label>
        </div>
        <div className="new-form__data">
          <div className="datepicker-wrapper">
            <input
              autoComplete="off"
              className="business_datepicker"
              defaultValue={this.props.formData.end_date}
              id="payment_end_date"
              name="end_date"
              onChange={this.handleChange}
              placeholder="YYYY-MM-DD"
              type="text"
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <div className="new-form__label">Frequency</div>
        <div className="radio-group">
          {this.renderWeeksInput()}
          {this.renderMonthsInput()}
          {this.renderTwiceMonthlyInput()}
          {this.renderWeekendWarning()}
        </div>

        {this.renderStartDate()}
        {this.renderEndDate()}
      </div>
    );
  }
}

FrequencyInputs.propTypes = {
  formData: PropTypes.object.isRequired,
  subclass: PropTypes.string,
  updateRecurringDeposit: PropTypes.func,
  maxDate: PropTypes.string,
};

FrequencyInputs.defaultProps = {
  maxDate: '+1Y',
};
