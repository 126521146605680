import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import DashboardActions from 'react/member/actions/dashboard_actions';
import DashboardDepositStore from 'react/member/stores/DashboardDepositStore';
import DashboardRecurringDepositStore from 'react/member/stores/DashboardRecurringDepositStore';
import PopUp from 'react/shared/components/popups/PopUp';

export default class DepositCancelModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.formDefaults();

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.updateReason = this.updateReason.bind(this);
    this.cancelDeposit = this.cancelDeposit.bind(this);
  }

  componentDidMount() {
    DashboardDepositStore.on('depositCancelModal.open', this.openModal);
    DashboardDepositStore.on('depositCancelModal.close', this.closeModal);
    DashboardRecurringDepositStore.on('depositCancelModal.close', this.closeModal);
  }

  componentDidUpdate() {
    if (this.state.modalOpen) {
      return $(ReactDOM.findDOMNode(this)).find('textarea').focus();
    }
  }

  componentWillUnmount() {
    DashboardDepositStore.off('depositCancelModal.open', this.openModal);
    DashboardDepositStore.off('depositCancelModal.close', this.closeModal);
    DashboardRecurringDepositStore.off('depositCancelModal.close', this.closeModal);
  }

  formDefaults() {
    return {
      reason: '',
      modalOpen: false,
      depositID: null,
      trustBeneID: null,
    };
  }

  openModal(id, budgetItemType, trustBeneID) {
    this.setState({
      reason: '',
      modalOpen: true,
      depositID: id,
      cancellingBudgetItemType: budgetItemType,
      trustBeneID,
    });
  }

  closeModal() {
    return this.setState(this.formDefaults());
  }

  updateReason(evt) {
    return this.setState({ reason: evt.target.value });
  }

  cancelDeposit() {
    const { depositID, trustBeneID, reason, cancellingBudgetItemType } = this.state;
    if (cancellingBudgetItemType === 'one_time') {
      return DashboardActions.cancelOneTimeDeposit(depositID, trustBeneID, reason);
    }
    DashboardActions.cancelRecurringDeposit(depositID, trustBeneID, reason);
  }

  render() {
    const modalProps = {
      modalOpen: this.state.modalOpen,
      cancel: this.closeModal,
      header: 'Why do you want to cancel this deposit?',
      footer: (
        <button
          className="btn btn-success"
          id="cancel-deposit-confirm"
          onClick={this.cancelDeposit}
          type="button"
        >
          Cancel Deposit
        </button>
      ),
      hideDismissButton: true,
    };

    $.extend(modalProps, this.props.style);

    return (
      <PopUp {...modalProps}>
        <textarea
          onChange={this.updateReason}
          placeholder="Optional"
          style={{ width: '100%' }}
          value={this.state.reason}
        />
      </PopUp>
    );
  }
}

DepositCancelModal.propTypes = {
  style: PropTypes.shape({
    maxWidth: PropTypes.string,
    paddingTop: PropTypes.string,
  }),
};

DepositCancelModal.defaultProps = {
  style: {
    maxWidth: '640px',
    paddingTop: '160px',
  },
};
