import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import RemoveBankAccountModal from './RemoveBankAccountModal';
import VerifyBankAccountModal from './VerifyBankAccountModal';
import DashboardActions from 'react/member/actions/dashboard_actions';
import AutoTransferUpsertModal from 'react/member/card/components/funding_tab/auto_transfers/AutoTransferUpsertModal';
import TransferFundsModal from 'react/member/card/components/funding_tab/transfers/TransferFundsModal';
import TransferUpsertModal from 'react/member/card/components/funding_tab/transfers/TransferUpsertModal';
import SingleTransferHolidayContainer from 'react/member/card/components/holidays/SingleTransferHolidayContainer';
import { dashboardAccountShape } from 'react/member/card/shapes/AccountShape';
import { bankAccountShape } from 'react/member/card/shapes/BankAccountShape';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import theme from 'react/shared/theme/Theme';
import PALETTE from 'react/shared/theme/palette';

const useStyles = makeStyles(() => ({
  bankAccountContainer: {
    marginBottom: '10px',
    border: 'solid 1px #eee',
    borderRadius: 3,
    boxShadow: '-1px 2px 5px -1px #999',
    width: '100%',
  },
  accountHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      alignItems: 'center',
      marginTop: '15px',
    },
  },
  divider: {
    margin: '10px 5px',
    [`@media (max-width: 768px)`]: {
      display: 'none',
    },
  },
  status: {
    padding: '4px 8px',
    borderRadius: 3,
    [`@media (max-width: 768px)`]: {
      marginBottom: '10px',
    },
  },
  actionButtons: {
    [`@media (max-width: 768px)`]: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '10px',

      '& button': {
        width: '100%',
        padding: '10px',
        '&:not(:last-child)': {
          marginBottom: '10px',
        },
      },
    },
  },
}));
export default function BankAccount({ bankAccount, account, cardStatus, isViewOnly }) {
  const classes = useStyles();
  const [holidayImpactedTransfer, setHolidayImpactedTransfer] = useState(null);
  const [showHolidayImpactedTransferModal, setShowHolidayImpactedTransferModal] = useState(false);
  const [showAutoTransferUpsertModal, setShowAutoTransferUpsertModal] = useState(false);
  const [showTransferUpsertModal, setShowTransferUpsertModal] = useState(false);
  const [showRemoveBankAccountModal, setShowRemoveBankAccountModal] = useState(false);
  const [showVerifyBankAccountModal, setShowVerifyBankAccountModal] = useState(false);
  const [showTransferFundsModal, setShowTransferFundsModal] = useState(false);

  const simplifiedTransfersModalFlag = $tlf?.flipperEnableSimplifiedTransfersModal;

  const openVerifyBankAccountModal = (ev) => {
    ev.preventDefault();
    setShowVerifyBankAccountModal(true);
  };

  const openRemoveBankAccountModal = (ev) => {
    ev.preventDefault();
    setShowRemoveBankAccountModal(true);
  };

  const openTransferUpsertModal = (ev) => {
    ev.preventDefault();
    setShowTransferUpsertModal(true);
  };

  const closeTransferUpsertModal = () => {
    setShowTransferUpsertModal(false);
  };

  const openAutoTransferUpsertModal = (ev) => {
    ev.preventDefault();
    setShowAutoTransferUpsertModal(true);
  };

  const openTransferFundsModal = (ev) => {
    ev.preventDefault();
    setShowTransferFundsModal(true);
  };

  const closeTransferFundsModal = () => {
    setShowTransferFundsModal(false);
  };
  const closeAutoTransferUpsertModal = () => {
    setShowAutoTransferUpsertModal(false);
  };

  const closeRemoveBankAccountModal = () => {
    setShowRemoveBankAccountModal(false);
  };

  const closeVerifyBankAccountModal = () => {
    setShowVerifyBankAccountModal(false);
  };

  const closeHolidayImpactedTransferModal = () => {
    setHolidayImpactedTransfer(null);
    setShowHolidayImpactedTransferModal(false);
    DashboardActions.reloadHolidayBanner();
  };

  const checkForHolidayImpactedTransfers = (response) => {
    if (response.upcomingHolidayImpactedTransfer) {
      closeTransferFundsModal();
      setHolidayImpactedTransfer(response.upcomingHolidayImpactedTransfer);
      setShowHolidayImpactedTransferModal(true);
    }
  };

  const renderActionButtons = () => {
    const removeBankAccountButton = (
      <div className="pull-right">
        <TrueLinkButton
          className="btn btn-link"
          id="remove-bank-account"
          onClick={openRemoveBankAccountModal}
          variant="none"
        >
          Remove bank account
        </TrueLinkButton>
      </div>
    );

    const recurringTransferButton = (
      <TrueLinkButton
        className="btn normal recurring-transfer"
        id="schedule-recurring-transfer-cta"
        onClick={openAutoTransferUpsertModal}
        style={{ marginRight: 10 }}
        variant="none"
      >
        Schedule recurring transfers
      </TrueLinkButton>
    );

    const oneTimeTransferButton = (
      <TrueLinkButton
        className="btn normal one-time-transfer"
        id="make-one-time-transfer-cta"
        onClick={openTransferUpsertModal}
        style={{ marginRight: 10 }}
        variant="none"
      >
        Make one-time transfer
      </TrueLinkButton>
    );

    const verifyAccountButton = (
      <TrueLinkButton
        className="btn normal"
        id="verify-account-cta"
        onClick={openVerifyBankAccountModal}
        variant="none"
      >
        Verify account
      </TrueLinkButton>
    );

    const transferFundsButton = (
      <TrueLinkButton
        className="btn normal"
        id="transfer-funds-cta"
        onClick={openTransferFundsModal}
        variant="none"
      >
        Transfer funds
      </TrueLinkButton>
    );

    if (bankAccount.isVerified) {
      return (
        <div className={classes.actionButtons}>
          {!simplifiedTransfersModalFlag && recurringTransferButton}
          {!simplifiedTransfersModalFlag && oneTimeTransferButton}
          {simplifiedTransfersModalFlag && transferFundsButton}
          {removeBankAccountButton}
        </div>
      );
    } else if (bankAccount.allowMicrodepositVerification) {
      return (
        <div className={classes.actionButtons}>
          {verifyAccountButton}
          {removeBankAccountButton}
        </div>
      );
    }
    return <div style={{ height: 34 }}>{removeBankAccountButton}</div>;
  };

  const renderBankAccountModals = () => (
    <>
      {!bankAccount.isVerified ? (
        <>
          {showVerifyBankAccountModal && (
            <VerifyBankAccountModal
              bankAccount={bankAccount}
              onClose={closeVerifyBankAccountModal}
            />
          )}
        </>
      ) : (
        <>
          {showTransferUpsertModal && (
            <TransferUpsertModal
              bankAccount={bankAccount}
              edit={false}
              isEffectivelyFullyFunded={account.meta.effectivelyFullyFunded}
              isFullyFunded={account.meta.fullyFunded}
              onClose={closeTransferUpsertModal}
              transfer={null}
            />
          )}
          {showAutoTransferUpsertModal && (
            <AutoTransferUpsertModal
              autoTransfer={null}
              bankAccount={bankAccount}
              edit={false}
              onClose={closeAutoTransferUpsertModal}
              onCreateSuccess={checkForHolidayImpactedTransfers}
            />
          )}
          {showTransferFundsModal && (
            <TransferFundsModal
              autoTransfer={null}
              bankAccount={bankAccount}
              cardStatus={cardStatus}
              cardholderName={account.meta.cardholderName}
              edit={false}
              emergencyFundingEnabled={account.meta.customerInstantFunding}
              fundingType={account.meta.fundingType}
              isEffectivelyFullyFunded={account.meta.effectivelyFullyFunded}
              onClose={closeTransferFundsModal}
              onSuccess={checkForHolidayImpactedTransfers}
            />
          )}
        </>
      )}
      {showRemoveBankAccountModal && (
        <RemoveBankAccountModal bankAccount={bankAccount} onClose={closeRemoveBankAccountModal} />
      )}
      {showHolidayImpactedTransferModal && (
        <SingleTransferHolidayContainer
          holidayImpactedTransfer={holidayImpactedTransfer}
          onClose={closeHolidayImpactedTransferModal}
        />
      )}
    </>
  );

  const text = bankAccount.isVerified ? 'Connected' : 'Needs verification';
  const backgroundColor = bankAccount.isVerified ? PALETTE.emeraldLight : '#ffcc66';
  const color = PALETTE.evergreen;

  const accountHeader = (
    <div className={classes.accountHeader}>
      <div>
        <span style={{ marginRight: 20 }}>{bankAccount.nickname}</span>
        <span>{bankAccount.accountNumber}</span>
      </div>
      <div className={classes.status} style={{ backgroundColor, color }}>
        {text}
      </div>
    </div>
  );

  const verifyBankAccountHelperText = !bankAccount.isVerified && (
    <div>
      <hr style={{ margin: '10px 5px' }} />
      <p>
        True Link is sending two small deposits of less than $1.00 to this bank account. This will
        take 2-4 days. Check this bank account's recent transaction history to see the amount of the
        deposits. Then, return here to click "Verify account" below and enter the deposit amounts.
        Once verified you will be able to immediately schedule transfers.
      </p>
    </div>
  );

  return (
    <div className={classes.bankAccountContainer}>
      <div style={{ margin: 10 }}>
        {accountHeader}
        {verifyBankAccountHelperText}
        <hr className={classes.divider} />
        {!isViewOnly && renderActionButtons()}
      </div>
      {!isViewOnly && renderBankAccountModals()}
    </div>
  );
}

BankAccount.propTypes = {
  account: dashboardAccountShape.isRequired,
  bankAccount: bankAccountShape.isRequired,
  cardStatus: PropTypes.string,
  isViewOnly: PropTypes.bool.isRequired,
};
