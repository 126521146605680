import axios from 'axios';
export const AXIOS_XHR_CONFIG = {
  headers: { 'X-Requested-With': 'XMLHttpRequest' },
};

export const AXIOS_FORM_DATA_CONFIG = {
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'multipart/form-data',
  },
};

export function makeCancellableAxiosXhrConfig(cancelToken) {
  return {
    ...AXIOS_XHR_CONFIG,
    cancelToken,
  };
}
export function swallowAxiosCancelation(error) {
  if (!(error instanceof axios.Cancel)) {
    throw error;
  }
}
