import PropTypes from 'prop-types';

function PrintChecksPayeeAddressCell({ value }) {
  const { street1, street2, city, state, zip } = value;
  return `${street1} ${street2 ? street2 : ''}\n ${city}, ${state} ${zip}`;
}

PrintChecksPayeeAddressCell.propTypes = {
  value: PropTypes.shape({
    street1: PropTypes.string,
    street2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
  }).isRequired,
};

export default PrintChecksPayeeAddressCell;
