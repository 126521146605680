import PropTypes from 'prop-types';

export const originShape = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string.isRequired,
  customStatementDates: PropTypes.shape({
    minDate: PropTypes.string.isRequired,
    maxDate: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
  }).isRequired,
});
