import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles({
  emptyRow: {
    fontStyle: 'italic',
    whiteSpace: 'nowrap',
  },
});

export default function TransferRowEmpty({ zeroStateText }) {
  const classes = useStyles();
  return (
    <tr className="odd">
      <td className={classes.emptyRow}>{zeroStateText}</td>
      <td />
      <td />
      <td />
      <td />
    </tr>
  );
}

TransferRowEmpty.propTypes = {
  zeroStateText: PropTypes.string.isRequired,
};
