import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
  },
  label: {
    paddingLeft: '10px',
  },
});

export default function HolidayImpactedTransfersSelectAllCell({ column, rows }) {
  const { id: columnId, date, allGuidanceAccepted, allGuidanceRejected } = column;

  const classes = useStyles();
  const checked =
    (columnId === 'acceptGuidance' && allGuidanceAccepted) ||
    (columnId === 'rejectGuidance' && allGuidanceRejected);

  const handleSelectAllClick = () => {
    if (columnId === 'acceptGuidance' && !checked) {
      rows.forEach((row) => row.setState((prevRow) => ({ ...prevRow, guidanceAccepted: true })));
    }
    if (columnId === 'rejectGuidance' && !checked) {
      rows.forEach((row) => row.setState((prevRow) => ({ ...prevRow, guidanceAccepted: false })));
    }
  };

  const action = columnId === 'acceptGuidance' ? 'accept' : 'reject';
  return (
    <div className={classes.wrapper}>
      <input
        aria-label={`${action}-all-transfer-guidance-${date}`}
        checked={checked}
        id={`${action}-all-transfer-guidance-${date}`}
        onChange={handleSelectAllClick}
        type="checkbox"
      />
      <label className={classes.label} htmlFor={`${action}-all-transfer-guidance-${date}`}>
        Select all
      </label>
    </div>
  );
}

HolidayImpactedTransfersSelectAllCell.propTypes = {
  column: PropTypes.shape({
    id: PropTypes.string,
    date: PropTypes.string,
    allGuidanceAccepted: PropTypes.bool,
    allGuidanceRejected: PropTypes.bool,
  }).isRequired,
  rows: PropTypes.array.isRequired,
};
