import PropTypes from 'prop-types';
import React from 'react';
import BlockedChargesReport from './BlockedChargesReport';
import SubSectionHeader from 'react/shared/components/SubSectionHeader';

export default function BlockedChargesReportSection(props) {
  return (
    <div id="blocked_charges_report">
      <SubSectionHeader>Blocked Transactions Report</SubSectionHeader>
      <div className="account_info_section">
        <p>Generate a report of blocked transactions on this card for any time period:</p>
        <BlockedChargesReport accountId={props.accountId} />
      </div>
    </div>
  );
}

BlockedChargesReportSection.propTypes = {
  accountId: PropTypes.string.isRequired,
};
