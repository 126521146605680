import PropTypes from 'prop-types';
import React from 'react';
import AppleStoreImage from './apple-store.svg';
import GoogleStoreImage from './google-play-store.svg';

const imageLinks = {
  ios: AppleStoreImage,
  android: GoogleStoreImage,
};

export default function TrueLinkAppStoreButton({
  store,
  height,
  width,
  linkStyles,
  linkProps,
  ...props
}) {
  const branchMobileStoreLink = 'https://truelink.app.link/VFFsgx9YHvb';
  const defaultLinkStyles = {
    background: `url(${imageLinks[store]}) no-repeat`,
    backgroundSize: 'contain',
    display: 'inline-block',
    overflow: 'hidden',
    textDecoration: 'none',
    height: '100%',
    width: '100%',
    padding: '5px',
    ...linkStyles,
  };

  return (
    <div {...props}>
      <a
        href={branchMobileStoreLink}
        rel="noopener noreferrer"
        style={defaultLinkStyles}
        target="_blank"
        {...linkProps}
      >
        &nbsp;
      </a>
    </div>
  );
}

TrueLinkAppStoreButton.propTypes = {
  store: PropTypes.oneOf(['ios', 'android']).isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
  linkStyles: PropTypes.object,
  linkProps: PropTypes.object,
};

TrueLinkAppStoreButton.defaultProps = {
  height: 75,
  width: 255,
};
