import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React from 'react';
import BannerCard from './referral_cards/BannerCard';
import FamilyCard from './referral_cards/FamilyCard';
import HowItWorksCard from './referral_cards/HowItWorksCard';
import IncentiveCard from './referral_cards/IncentiveCard';
import ReferralFooter from './referral_cards/ReferralFooter';
import ReviewsCard from './referral_cards/ReviewsCard';
import theme, { mediumBreakpointMediaQuery } from 'react/shared/theme/Theme';

export default function ReferralShare(props) {
  const desktop = useMediaQuery(mediumBreakpointMediaQuery);
  const mainContainer = document.querySelector('#main');
  if (mainContainer) {
    mainContainer.style.margin = 0;
    mainContainer.style.width = 'auto';
  }
  const footerContainer = document.getElementById('footer');
  if (footerContainer) {
    footerContainer.style.display = 'none';
  }
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <IncentiveCard
          desktop={desktop}
          isCareAgencyExperiment={props.isCareAgencyExperiment}
          referrerFirstName={props.referrerFirstName}
          referrerLastName={props.referrerLastName}
        />
        <HowItWorksCard desktop={desktop} isCareAgencyExperiment={props.isCareAgencyExperiment} />
        {!props.isCareAgencyExperiment && <FamilyCard />}
        <ReviewsCard desktop={desktop} />
        <BannerCard desktop={desktop} isCareAgencyExperiment={props.isCareAgencyExperiment} />
        <ReferralFooter isCareAgencyExperiment={props.isCareAgencyExperiment} />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

ReferralShare.propTypes = {
  isCareAgencyExperiment: PropTypes.bool,
  referrerFirstName: PropTypes.string,
  referrerLastName: PropTypes.string,
};
