import PropTypes from 'prop-types';
import React from 'react';

function PayeeFields(props) {
  const { payee, status, checkMemoLine2, paymentMethod } = props;

  return (
    <>
      <div className="new-form-field">
        <div className="new-form__label">Status:</div>
        <div className="new-form__data">{status}</div>
      </div>
      <div className="new-form-field">
        <div className="new-form__label">Payment Type:</div>
        <div className="new-form__data">{$tlf.domains.paymentMethodsMap[paymentMethod]}</div>
      </div>
      {payee.id && (
        <div className="new-form-field">
          <div className="new-form__label">Payee:</div>
          <div className="new-form__data">
            <span>{payee.name}</span>
          </div>
        </div>
      )}
      {payee.full_address && (
        <div className="new-form-field">
          <div className="new-form__label">Payee Address:</div>
          <div className="new-form__data">
            <span>{payee.full_address}</span>
          </div>
        </div>
      )}
      {payee.bank_routing_number && (
        <div className="new-form-field">
          <div className="new-form__label">Payee Bank Information:</div>
          <div className="new-form__data">
            <div style={{ display: 'inline-block', width: 86 }}>Routing #:</div>
            <div style={{ display: 'inline-block' }}>{payee.bank_routing_number}</div>
            <br />
            <div style={{ display: 'inline-block', width: 86 }}>Account #:</div>
            <div style={{ display: 'inline-block' }}>{payee.bank_account_number}</div>
          </div>
        </div>
      )}
      {payee.client_account_number && (
        <div className="new-form-field">
          <div className="new-form__label">Memo:</div>
          <div className="new-form__data">
            <span>{payee.client_account_number}</span>
          </div>
        </div>
      )}
      {checkMemoLine2 && (
        <div className="new-form-field">
          <div className="new-form__label">Memo Line 2:</div>
          <div className="new-form__data">{checkMemoLine2}</div>
        </div>
      )}
      {payee.disposition && (
        <div className="new-form-field">
          <div className="new-form__label">Disposition:</div>
          <div className="new-form__data">{payee.disposition}</div>
        </div>
      )}
      {payee.special_instructions && (
        <div className="new-form-field">
          <div className="new-form__label">Special Instructions:</div>
          <div className="new-form__data">{payee.special_instructions}</div>
        </div>
      )}
    </>
  );
}

PayeeFields.propTypes = {
  checkMemoLine2: PropTypes.string,
  payee: PropTypes.object,
  paymentMethod: PropTypes.string,
  status: PropTypes.string,
};

export default PayeeFields;
