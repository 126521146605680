import PropTypes from 'prop-types';
import React from 'react';
import QuestionnaireRadioOption from './QuestionnaireRadioOption';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import bindAll from 'react/shared/utils/bind_all';

export default class AbleActProgramsAlrCardQuestionnaire extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      alrCardChoice: '',
    };

    bindAll(this);
  }

  setAlrCardChoice(ev) {
    Truelink.flashClear();
    this.setState({ alrCardChoice: ev.target.value });
  }

  submitForm(ev) {
    ev.preventDefault();
    const { alrCardChoice } = this.state;

    if (alrCardChoice === '') {
      Truelink.flash(
        'error',
        'Please select one of the options provided before pressing continue.',
      );
      return;
    }

    this.props.handleQuestionnaireChoice(alrCardChoice);
  }

  render() {
    const { programName } = this.props;

    return (
      <div>
        <div className="questionnaire-header__able">
          <h1>Welcome to {programName} Visa&reg; Prepaid Card Enrollment!</h1>
        </div>
        <div className="widescreen-inner-container">
          <div className="questionnaire-container__able">
            <div className="questionnaire-form-item__able" style={{ width: '720px' }}>
              <h2>Who are you ordering a card for?</h2>
              <form id="alr-card-form" onSubmit={this.submitForm}>
                <div className="questionnaire-radio-wrapper__able">
                  <QuestionnaireRadioOption
                    inputProps={{
                      name: 'alr_card_choice',
                      id: 'alr_card_choice_alr_only',
                      value: 'alr_only',
                      onChange: this.setAlrCardChoice,
                    }}
                    labelText="One card for me (the Authorized Legal Representative)"
                  />
                  <QuestionnaireRadioOption
                    inputProps={{
                      name: 'alr_card_choice',
                      id: 'alr_card_choice_bene_only',
                      value: 'bene_only',
                      onChange: this.setAlrCardChoice,
                    }}
                    labelText="One card for the beneficiary"
                  />
                  <QuestionnaireRadioOption
                    inputProps={{
                      name: 'alr_card_choice',
                      id: 'alr_card_choice_alr_and_bene',
                      value: 'alr_and_bene',
                      onChange: this.setAlrCardChoice,
                    }}
                    labelText="Two cards - one for me and one for the beneficiary"
                  />
                  <p
                    style={{
                      width: '570px',
                      margin: '0 auto',
                      marginTop: '30px',
                      fontSize: '18px',
                    }}
                  >
                    If you are a beneficiary with an Authorized Legal Representative (ALR), please
                    contact your ALR to order your {programName} Account card.
                  </p>
                </div>
                <div className="questionnaire-item-footer__able">
                  <TrueLinkButton size="large" type="submit" variant="primary">
                    <span>Continue&nbsp;&nbsp;</span>
                    <span
                      style={{
                        fontSize: '30px',
                        position: 'relative',
                        top: '1px',
                      }}
                    >
                      ›
                    </span>
                  </TrueLinkButton>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AbleActProgramsAlrCardQuestionnaire.propTypes = {
  handleQuestionnaireChoice: PropTypes.func.isRequired,
  programName: PropTypes.string.isRequired,
};
