import PropTypes from 'prop-types';
import React from 'react';
import TrueLinkIcon from 'react/shared/components/true_link/main/TrueLinkIcon';
import bindAll from 'react/shared/utils/bind_all';

export default class DisbursementDetailActions extends React.Component {
  constructor(props) {
    super(props);
    bindAll(this);
  }

  renderPutOnHoldAction() {
    if (this.props.renderOnHoldDisbursements) {
      const { disbursement } = this.props;
      if (
        disbursement.can_be_updated &&
        (disbursement.status === 'Pending' ||
          disbursement.status === 'Proposed' ||
          disbursement.status === 'Additional Approval')
      ) {
        return (
          <button className="btn btn-link" onClick={this.props.putOnHold} type="button">
            Put On Hold
          </button>
        );
      }
    }
  }

  renderSendBackAction() {
    if (
      this.props.showSendDisbursementBackToPending &&
      this.props.disbursement.status === 'Additional Approval' &&
      this.props.disbursement.can_be_returned_for_revision === true
    ) {
      const { disbursement } = this.props;
      if (disbursement.status == 'Additional Approval') {
        return (
          <button
            className="btn btn-link"
            onClick={this.props.sendDisbursementBackToPending}
            type="button"
          >
            Return for Revision
          </button>
        );
      }
    }
  }

  renderRemoveHoldAction() {
    if (this.props.renderOnHoldDisbursements) {
      const { disbursement } = this.props;
      if (
        disbursement.status === 'Pending on Hold' ||
        disbursement.status === 'Proposed on Hold' ||
        disbursement.status === 'Additional Approval on Hold'
      ) {
        return (
          <button className="btn btn-link" onClick={this.props.removeHold} type="button">
            Remove Hold
          </button>
        );
      }
    }
  }

  // Hiding the buttons currently used to update a disbursement for all
  // DraftDisbursements in favor of the Submit button displayed on the
  // new DraftDisbursement edit form
  renderEditAction() {
    const { disbursement } = this.props;
    if (disbursement.status !== 'Draft' && disbursement.can_be_updated) {
      return (
        <button className="btn btn-link" onClick={this.props.onToggleEdit} type="button">
          <TrueLinkIcon icon={'pen-to-square'} style={{ marginRight: '5px' }} />
          {this.props.isEditing ? 'Save' : 'Edit'}
        </button>
      );
    } else if (disbursement.status === 'Approved') {
      return (
        <div>
          This disbursement has not yet been cleared. To make an adjustment, please email our
          support team: support@truelinkfinancial.com.
        </div>
      );
    }
  }

  renderPrintAction() {
    if (this.props.canPrint) {
      const printRoute = `${RailsRoutes.dashboard_organization_disbursement_path(
        this.props.disbursement.beneficiary.organization.slug,
        this.props.disbursement.id,
      )}.pdf`;
      return (
        <a href={printRoute} rel="noreferrer" target="_blank">
          <TrueLinkIcon icon={'print'} style={{ marginRight: '5px' }} /> Print
        </a>
      );
    }
  }

  renderDeleteAction() {
    if (this.props.canDelete) {
      return (
        <a href="#" onClick={this.props.deletePendingDisbursement}>
          <TrueLinkIcon icon={'trash'} style={{ marginRight: '5px' }} /> Delete
        </a>
      );
    }
  }

  render() {
    return (
      <span>
        {this.renderSendBackAction()}
        {this.renderRemoveHoldAction()}
        {this.renderPutOnHoldAction()}
        {this.renderEditAction()}
        {this.renderPrintAction()}
        {this.renderDeleteAction()}
      </span>
    );
  }
}

DisbursementDetailActions.propTypes = {
  disbursement: PropTypes.shape({
    can_be_updated: PropTypes.bool.isRequired,
    can_be_returned_for_revision: PropTypes.bool.isRequired,
    id: PropTypes.number,
    payment_method: PropTypes.string,
    status: PropTypes.string,
    beneficiary: PropTypes.object,
  }).isRequired,
  canPrint: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  onToggleEdit: PropTypes.func.isRequired,
  putOnHold: PropTypes.func.isRequired,
  sendDisbursementBackToPending: PropTypes.func,
  removeHold: PropTypes.func.isRequired,
  renderOnHoldDisbursements: PropTypes.bool,
  showSendDisbursementBackToPending: PropTypes.bool,
  canDelete: PropTypes.bool,
  deletePendingDisbursement: PropTypes.func,
};

DisbursementDetailActions.defaultProps = {
  canPrint: true,
  renderOnHoldDisbursements: false,
};
