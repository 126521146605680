import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';
import _isEmpty from 'underscore/modules/isEmpty';
import _map from 'underscore/modules/map';
import _omit from 'underscore/modules/omit';
import Field from './Field';
import FieldMixin from './FieldMixin';
import HiddenField from 'react/shared/components/forms/HiddenField';
import { underscorize } from 'react/shared/utils/Strings';

const SelectField = createReactClass({
  propTypes: {
    controller: PropTypes.object,
    options: PropTypes.func,
    onChange: PropTypes.func,
    optionList: PropTypes.array,
    association: PropTypes.bool,
    format: PropTypes.func,
    required: PropTypes.bool,
    readOnly: PropTypes.bool,
    multiple: PropTypes.bool,
    fakeMultiple: PropTypes.bool,
    allowBlank: PropTypes.bool,
    useChosen: PropTypes.bool,
  },

  mixins: [FieldMixin],

  getDefaultProps() {
    return {
      association: false,
      readOnly: false,
      multiple: false,
      fakeMultiple: false,
      format: null,
      useChosen: false,
    };
  },

  getInitialState() {
    return {
      options: [],
    };
  },

  componentDidMount() {
    this.initField();
    this.setChosen();

    if (this.props.options != undefined) {
      this.props.options(this);
    }
  },

  componentDidUpdate() {
    if (this.editable()) {
      this.setChosen();
    } else {
      this.destroyChosen();
    }
  },

  componentWillUnmount() {
    this.destroyChosen();
  },

  setChosen() {
    if (this.props.useChosen && this.editable() && this.selectField != undefined) {
      this.$el = $(this.selectField);
      if (this.$el.data('chosen')) {
        this.$el.trigger('chosen:updated');
      } else {
        this.$el.chosen().on('change', (evt) => this.onSelect(evt));
      }
    }
  },

  destroyChosen() {
    if (this.props.useChosen && this.$el != undefined) {
      this.$el.chosen('destroy');
    }
  },

  optionList() {
    // Prefer a passed in option list over state from calling a function
    return this.props.optionList || this.state.options;
  },

  // Try to use the option list to find an option matching the value
  displayNameFromList(value) {
    const options = this.optionList();
    if (options.length <= 0) {
      return;
    }

    // Grab the first option that matches the value
    const option = options.find((option) => option.id == value);

    // Assuming we found the option and it has a name use that; otherwise fall back to the value
    if (typeof option == 'object' && option.name) {
      return option.name;
    }
    return value;
  },

  renderShow() {
    const value = this.value();

    const formatter = (value) => {
      if (this.props.format) {
        return this.props.format(value);
      } else if (typeof value == 'object' && value) {
        return value.name;
      }
      return this.displayNameFromList(value);
    };

    let show;
    if (Array.isArray(value)) {
      show = _map(value, formatter).join(', ');
    } else {
      show = formatter(value);
    }

    let input;
    if (this.controller().editable() && !this.editable() && !this.readOnly()) {
      // if the page state is editable, but this specific field is not
      // user-editable, add a hidden field that will send the proper value back
      // to the server when form is submitted
      input = (
        <HiddenField
          association={this.is_association()}
          controller={this.controller()}
          name={this.name()}
          value={value ? value.id : null}
        />
      );
    }

    return (
      <div>
        {show}
        {input}
      </div>
    );
  },

  onSelect(evt) {
    const c = evt.target;
    const values = [];
    $('option:selected', c).each((i, opt) => {
      if (this.is_association()) {
        const id = $(opt).val();
        const name = $(opt).text();
        values.push({ id, name });
      } else {
        values.push($(opt).val());
      }
    });

    let value;
    if (this.allows_multiple()) {
      value = values;
    } else {
      value = values[0];
    }

    this.change(value);

    if (this.props.onChange) {
      this.props.onChange(evt);
    }
  },

  renderEdit() {
    if (this.setSelectRef == undefined) {
      this.setSelectRef = (element) => {
        this.selectField = element;
      };
    }

    const value = this.value();

    let optionValue;
    if (this.is_association()) {
      optionValue = (obj) => obj.id;
    } else {
      optionValue = (obj) => obj;
    }

    let selected;
    if (this.allows_multiple() || this.props.fakeMultiple) {
      if (value) {
        if (Array.isArray(value)) {
          selected = _map(value, optionValue);
        } else {
          selected = [optionValue(value)];
        }
      }

      // For fakeMultiple, the select isn't actually rendered with
      // multiple={true}, so we do NOT want the value actually rendered in an
      // array. (And we can only select one item, so we pick the first)
      if (this.props.fakeMultiple && Array.isArray(selected)) {
        if (selected.length) {
          selected = selected[0];
        } else {
          selected = '';
        }
      }
    } else {
      selected = optionValue(value);
    }

    let blank;
    // Ideally this would be tighter controlled, but it is legacy
    // eslint-disable-next-line no-eq-null
    if (selected == null) {
      blank = [
        <option key="please_select" value="">
          Please select an option...
        </option>,
      ];
    } else if (_isEmpty(selected) || this.props.fakeMultiple || this.props.allowBlank) {
      blank = [
        <option key="none" value="">
          None
        </option>,
      ];
    } else {
      blank = [];
    }

    const options = blank.concat(
      _map(this.optionList(), (option) => (
        <option key={underscorize(option.id.toString())} value={option.id}>
          {' '}
          {option.name}{' '}
        </option>
      )),
    );

    const selectProps = _omit(this.props, [
      'format',
      'options',
      'association',
      'controller',
      'fakeMultiple',
      'useChosen',
      'optionList',
      'allowBlank',
      'editable',
      'optional',
      'helperText',
      'helperTextType',
    ]);

    return (
      <select
        {...selectProps}
        multiple={this.props.multiple}
        name={this.inputName()}
        onChange={this.onSelect}
        ref={this.setSelectRef}
        required={this.props.required}
        value={selected || ''}
      >
        {options}
      </select>
    );
  },

  render() {
    return (
      <Field
        {...this.props}
        editable={this.editable}
        label={this.label}
        onKeyUp={this.onKeyUp}
        renderEdit={this.renderEdit}
        renderShow={this.renderShow}
      />
    );
  },
});

export default SelectField;
