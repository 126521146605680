import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import TrueLinkIcon from './TrueLinkIcon';
import childrenShape from 'react/shared/shapes/children';
import PALETTE from 'react/shared/theme/palette';

const useStyles = makeStyles({
  header: {
    textAlign: 'left',
    fontSize: '18px',
    fontWeight: '600',
  },
  summary: {
    minHeight: '64px',
    '&:hover': {
      backgroundColor: PALETTE.emeraldLight,
    },
  },
  icon: {
    display: 'inline-block',
    paddingRight: '20px',
    color: PALETTE.emerald,
  },
  details: {
    display: 'block',
    textAlign: 'left',
    paddingTop: '12px',

    '& p': {
      margin: '0',
      paddingBottom: '18px',
      fontSize: '18px',
    },

    '& a': {
      display: 'block',
      paddingBottom: '12px',
      textDecoration: 'none',
      fontSize: '18px',
      color: PALETTE.blue,
      '&:hover': {
        textDecoration: 'underline',
      },
      '&:visited': {
        color: PALETTE.blueDark,
      },
    },
  },
  detailsIcon: {
    marginLeft: '40px',
  },

  accordion: {
    //Material overwrite
    '&.MuiAccordion-root.Mui-expanded': {
      margin: '0',
    },
    '&.MuiAccordion-rounded': {
      borderTop: '1px solid #ddd',
      borderRadius: 'unset',

      '&:last-child': {
        borderBottom: '1px solid #ddd',
      },
    },
    '&.MuiPaper-elevation1': {
      boxShadow: 'none',
    },
  },
});

export default function TrueLinkAccordion({
  children,
  className,
  expanded,
  icon,
  id,
  handleChange,
  header,
}) {
  const classes = useStyles();

  return (
    <Accordion
      className={classNames(classes.accordion, { [className]: className })}
      expanded={expanded === id}
      id={id}
      onChange={handleChange}
    >
      <AccordionSummary className={classes.summary} expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.header}>
          {icon && <TrueLinkIcon className={classes.icon} icon={icon} />}
          {header}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        className={classNames(classes.details, {
          [classes.detailsIcon]: icon,
        })}
        id="accordionDetails"
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
}

TrueLinkAccordion.propTypes = {
  className: PropTypes.string,
  children: childrenShape,
  expanded: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  icon: PropTypes.string,
  id: PropTypes.string,
  handleChange: PropTypes.func,
};
