import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import TrueLinkLink from 'react/shared/components/true_link/main/TrueLinkLink';
import theme from 'react/shared/theme/Theme';
import PALETTE from 'react/shared/theme/palette';

const useStyles = makeStyles(() => ({
  section: {
    backgroundColor: PALETTE.emerald,
    color: PALETTE.white,
    borderTop: '1px solid rgba(0,0,0,.1)',
    padding: '5rem',
    [theme.breakpoints.down('md')]: {
      padding: '5rem 2rem',
    },
  },
  container: {
    margin: '80px auto 60px',
    maxWidth: '70rem',
    textAlign: 'center',
  },
  header: {
    marginBottom: '0.5rem',
    fontFamily: 'Tiempos headline,Times New Roman,sans-serif',
    fontSize: '2.5rem',
    fontWeight: '300',
  },
  subHeader: {
    marginBottom: '0.5rem',
    fontFamily: '"Calibre", sans-serif',
    fontWeight: '300',
  },
  signupButton: {
    '&.nav-button': {
      background: PALETTE.white,
      color: PALETTE.emerald,
      margin: '20px 0',
      '&:hover': {
        background: PALETTE.evergreen,
      },
    },
  },
}));

export default function BannerCard(props) {
  const classes = useStyles();
  const headerText = props.isCareAgencyExperiment
    ? 'Getting started is easy'
    : 'Life’s complex, we’re here to make things simpler';
  return (
    <div className={classes.section} data-testid="bannerCard">
      <div className={classes.container}>
        <Typography className={classes.header} variant={props.desktop ? 'h1' : 'h2'}>
          {headerText}
        </Typography>
        {!props.isCareAgencyExperiment && (
          <Typography className={classes.subHeader} variant="body2">
            For a $12* monthly fee, get peace of mind with the True Link Visa Card and Spending
            Monitor.
            <br /> You don’t get charged until activation, and you can cancel at any time.
          </Typography>
        )}
        <div>
          <TrueLinkLink
            className={classNames(`nav-button`, classes.signupButton)}
            href={RailsRoutes.signups_card_new_path()}
          >
            Sign up
          </TrueLinkLink>
        </div>
      </div>
    </div>
  );
}

BannerCard.propTypes = {
  desktop: PropTypes.bool,
  isCareAgencyExperiment: PropTypes.bool,
};
