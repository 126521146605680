import PropTypes from 'prop-types';
import React from 'react';
import { dashboardAccountShape } from 'react/member/card/shapes/AccountShape';
import PopUp from 'react/shared/components/popups/PopUp';

export default function FundsAvailableModal({ account, onClose }) {
  const fullyFundedText =
    'Transfers initiated before 4pm PT, Monday through Friday, will be available the next business day. Transfers initiated on a weekend day, on a bank holiday, or after 4pm PT on a Monday through Friday, will be available in two business days.';
  const notFullyFundedText =
    'Transfers typically take one business day to be completed but may take as long as five business days. Scheduled transfers will go into effect once the card has been activated.';
  return (
    <PopUp
      header={<div style={{ textAlign: 'left' }}>When will funds be available?</div>}
      hideFooter
      maxWidth="550px"
      modalOpen
      onClose={onClose}
    >
      <div style={{ fontSize: 16 }}>
        {account.meta.effectivelyFullyFunded ? fullyFundedText : notFullyFundedText}
      </div>
    </PopUp>
  );
}

FundsAvailableModal.propTypes = {
  account: dashboardAccountShape.isRequired,
  onClose: PropTypes.func.isRequired,
};
