import React from 'react';
import TransferFundsInfoBox from './TransferFundsInfoBox';

const transferTypeOptions = [
  'one_time',
  'every_week',
  'every_month',
  'twice_monthly',
  'when_funds_drop_below',
];
const fundingTypeOptions = [
  'Wait to fund',
  'Fully fund',
  'Cautious fund',
  'Partial ($250)',
  'Partial ($500)',
];
const cardStatusOptions = ['OPEN', 'CLOSED', 'ISSUED_INACTIVE', 'LOST', 'STOLEN'];

export default {
  title: 'Features/TransferFundsInfoBox',
  component: TransferFundsInfoBox,

  argTypes: {
    fundingType: {
      options: fundingTypeOptions,
      control: { type: 'select' },
      table: {
        category: 'Type',
      },
    },
    transferType: {
      options: transferTypeOptions,
      control: { type: 'select' },
      table: {
        category: 'Type',
      },
    },
    cardStatus: {
      options: cardStatusOptions,
      control: { type: 'select' },
      table: {
        category: 'Type',
      },
    },
    availableOnDate: {
      control: { type: false },
    },
    startingTransferDate: {
      control: { type: false },
    },
    dayOfMonth: {
      table: {
        category: 'Data',
      },
    },
    dayOfMonth2: {
      table: {
        category: 'Data',
      },
    },
    dayOfWeek: {
      options: [1, 2, 3, 4, 5],
      control: { type: 'select' },
      table: {
        category: 'Data',
      },
    },
    transferAmount: {
      table: {
        category: 'Data',
      },
    },
    transferThreshold: {
      table: {
        category: 'Data',
      },
    },
  },
};

function Template(args) {
  return (
    <div>
      <TransferFundsInfoBox {...args} />
    </div>
  );
}

export const FullyFundedTransfer = Template.bind({});
FullyFundedTransfer.args = {
  dayOfMonth: '1',
  dayOfMonth2: '15',
  dayOfWeek: 1,
  transferAmount: 100,
  transferThreshold: '150.00',
  fundingType: 'Fully fund',
  transferType: 'one_time',
  emergencyFundingEnabled: false,
  availableOnDate: 'May 28 2024',
  startingTransferDate: 'May 28 2024',
  cardStatus: 'OPEN',
  transferAdjusted: false,
  transferAdjustedReason: 'weekend',
};
