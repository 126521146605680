import { FastField, Field } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import _map from 'underscore/modules/map';
import dropdownOptionsShape from 'react/shared/shapes/dropdown_options_shape';

function SelectField({
  id,
  name,
  options,
  className,
  includeBlankOption,
  blankOptionDesc,
  onChangeCallback,
  fast,
  disabled,
}) {
  const FieldComponent = fast ? FastField : Field;

  return (
    <FieldComponent name={name}>
      {({ field, form }) => (
        <select
          className={className}
          disabled={disabled}
          id={id}
          name={field.name}
          onChange={(event) => {
            form.setFieldValue(field.name, event.currentTarget.value);
            onChangeCallback && onChangeCallback(event.currentTarget.value, field.name, form);
          }}
          value={field.value}
        >
          {includeBlankOption && (
            <option key="" value="">
              {blankOptionDesc}
            </option>
          )}
          {_map(options, (object) => (
            <option key={`${id}-${object.id}`} value={object.id}>
              {object.name}
            </option>
          ))}
        </select>
      )}
    </FieldComponent>
  );
}

SelectField.propTypes = {
  blankOptionDesc: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: dropdownOptionsShape.isRequired,
  className: PropTypes.string,
  includeBlankOption: PropTypes.bool,
  onChangeCallback: PropTypes.func,
  disabled: PropTypes.bool,
  fast: PropTypes.bool,
};

SelectField.defaultProps = {
  className: '',
  includeBlankOption: false,
  disabled: false,
  fast: true,
};

export default SelectField;
