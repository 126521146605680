import I18n from 'i18n-js';
import React from 'react';
import SubSectionHeader from 'react/shared/components/SubSectionHeader';
import addressShape from 'react/shared/shapes/address_shape';

export default function PendingAddressChange({ addressChangeRequest }) {
  const { street1, street2, city, state, zip } = addressChangeRequest;
  return (
    <>
      <SubSectionHeader>Pending Address Change</SubSectionHeader>
      <div className="account_info_section">
        <table>
          <tbody>
            <tr>
              <td>Address:</td>
              <td>
                <p>{street1}</p>
                {street2 && <p>{street2}</p>}
                <p>{`${city}, ${state} ${zip}`}</p>
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <div className="info-box">
          <p>
            Please allow 2-4 business days to update the cardholder’s address. We'll follow up with
            you about any questions that come up as we process this request.
          </p>
          <p>
            {`To cancel this request, or to change any other contact information, please email Customer Support at ${I18n.t(
              'notifications.emails.noreply',
            )}`}
          </p>
        </div>
      </div>
    </>
  );
}

PendingAddressChange.propTypes = {
  addressChangeRequest: addressShape.isRequired,
};
