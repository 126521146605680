import PropTypes from 'prop-types';
import { administratorShape } from 'react/member/card/shapes/AdministratorShape';
import { estimatedArrivalShape } from 'react/member/shapes/estimated_arrival_shape';
import { accountProgramTypeShape } from 'react/shared/shapes/account_program_name_shape';
import addressShape from 'react/shared/shapes/address_shape';

const { bool, number, string, shape, arrayOf, oneOf } = PropTypes;

// TODO CARD-1888
export const accountShape = shape({
  administrator: administratorShape,
  bulk_card_ordered: bool,
  card_name: string,
  contact_email: string,
  contact_name: string,
  dashboard_status: string,
  disable_selecting_last_known_address: bool,
  estimated_arrival: estimatedArrivalShape,
  fbo_person_name: string,
  first_card_order: bool,
  program_type: accountProgramTypeShape,
  shipping_address: addressShape,
  shipping_address_as_string: string,
  slug: string.isRequired,
});

export const dashboardAccountShape = shape({
  type: oneOf(['Account']).isRequired,
  id: string.isRequired, // slug
  attributes: shape({
    additionalEmails: arrayOf(string),
    cardProgramNumber: number,
    directDepositCustomer: bool.isRequired,
    hideClosedCardTransactions: bool.isRequired,
    isFbo: bool.isRequired,
  }),
  meta: shape({
    allowsFamilyFunder: bool.isRequired,
    cardCount: number.isRequired,
    cardCountNonClosed: number.isRequired,
    cardholderName: string,
    effectivelyFullyFunded: bool.isRequired,
    fullyFunded: bool.isRequired,
    hasOpenCard: bool.isRequired,
    hasVerifiedBankAccount: bool.isRequired,
    showAccountReceipts: bool.isRequired,
    showClosedCardCheckbox: bool.isRequired,
    locale: shape({
      msaLink: string.isRequired,
      programName: string.isRequired,
      supportEmail: string.isRequired,
      supportADTollfree: string.isRequired,
      supportCHTollfree: string.isRequired,
    }),
    permissions: shape({
      view: bool.isRequired,
      edit: bool.isRequired,
      delete: bool.isRequired,
      tabs: shape({
        accountInfo: shape({ view: bool.isRequired }),
        alerts: shape({ view: bool.isRequired }),
        funding: shape({ view: bool.isRequired }),
        onboardingWidget: shape({ view: bool.isRequired }),
        reports: shape({ view: bool.isRequired }),
        spendingMonitor: shape({ view: bool.isRequired }),
        transactions: shape({ view: bool.isRequired }),
      }),
    }),
  }),
});
