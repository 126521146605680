import PropTypes from 'prop-types';
import React from 'react';
import Moment from 'react-moment';
import DashboardActions from 'react/member/actions/dashboard_actions';
import BasicTableBuilder from 'react/member/components/dashboard/misc/BasicTableBuilder';
import DashboardNoteStore from 'react/member/stores/beneficiary_dashboard/DashboardNoteStore';
import TlAreYouSureModal from 'react/shared/components/TlAreYouSureModal';
import bindAll from 'react/shared/utils/bind_all';

export default class DashboardTrustBeneficiaryProfileActivityNotesList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
    };

    bindAll(this);
  }

  componentDidMount() {
    DashboardNoteStore.on('dashboardNote.destroy', this.closeModal);
  }

  componentWillUnmount() {
    DashboardNoteStore.off('dashboardNote.destroy', this.closeModal);
  }

  notesTableConfig() {
    return {
      name: {
        title: 'Date',
        thAttributes: { style: { width: '100px' } },
        value: (note) => (
          <p>
            <Moment format="MM-DD-YYYY">{note.created_at}</Moment>
          </p>
        ),
      },
      time: {
        title: 'Time',
        thAttributes: { style: { width: '100px' } },
        value: (note) => (
          <p>
            <Moment format="h:mm A">{note.created_at}</Moment>
          </p>
        ),
      },
      noteContent: {
        title: 'Note',
        value: (note) => {
          const noteContent = note.note_content;

          const createdBy = note.created_by.id ? note.created_by.email : '(user n/a)';
          const createdByDeleted = note.created_by.deleted_at ? ' (deleted)' : undefined;

          return (
            <p>
              {noteContent} -{' '}
              <span className="gray-text">
                {createdBy}
                {createdByDeleted}
              </span>
            </p>
          );
        },
      },
      actionItems: {
        title: '',
        thAttributes: { style: { width: '60px' } },
        value: (note) => (
          <div className="action-items">
            <button
              className="action-item btn btn-link danger"
              key={note.id}
              name={`toggle-important-note-${note.id}`}
              onClick={() => this.toggleImportant(note)}
              type="button"
            >
              <i className={note.is_important ? 'fas fa-star' : 'far fa-star'} />
            </button>
            <button
              className="action-item btn btn-link danger"
              name={`delete-note-${note.id}`}
              onClick={() => this.setState({ deletingNote: note })}
              type="button"
            >
              <i className="fa fa-trash" />
            </button>
          </div>
        ),
      },
    };
  }

  toggleImportant(note) {
    const { trustBeneficiary } = this.props;
    DashboardActions.updateDashboardNote(trustBeneficiary.slug, note.id, {
      is_important: !note.is_important,
    });
  }

  destroyNote() {
    const { trustBeneficiary } = this.props;
    const { deletingNote } = this.state;
    DashboardActions.destroyClientNote(trustBeneficiary.slug, deletingNote.id);
  }

  closeModal() {
    this.setState({ deletingNote: null });
  }

  render() {
    const { deletingNote } = this.state;
    const { notes } = this.props;

    return (
      <div>
        <div className="content-table">
          <BasicTableBuilder
            columnList={['name', 'time', 'noteContent', 'actionItems']}
            itemTypeKey={'notes'}
            tableFieldConfig={this.notesTableConfig()}
            tableItems={notes}
          />
        </div>
        {deletingNote && (
          <TlAreYouSureModal
            buttonClass="btn-danger"
            handleClose={this.closeModal}
            headerText="Delete Note"
            messageText="Are you sure you want to delete this note?"
            onSubmit={this.destroyNote}
            submitText="Yes, delete"
          />
        )}
      </div>
    );
  }
}

DashboardTrustBeneficiaryProfileActivityNotesList.propTypes = {
  notes: PropTypes.array.isRequired,
  trustBeneficiary: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    trust: PropTypes.shape({
      organization: PropTypes.shape({}),
    }).isRequired,
  }).isRequired,
};
