import PropTypes from 'prop-types';
import { holidayImpactedDateShape } from './HolidayImpactedDateShape';

const { arrayOf, bool, number, string, shape } = PropTypes;

export const holidayShape = shape({
  date: string.isRequired,
  emailedAdmin: bool.isRequired,
  flipDate: string.isRequired,
  id: number.isRequired,
  impactedDates: arrayOf(holidayImpactedDateShape).isRequired,
  name: string.isRequired,
  notificationDate: string.isRequired,
  oneTimeTransferText: string,
  resetDate: string.isRequired,
});
