import React from 'react';
import _isString from 'underscore/modules/isString';
import { fieldLabelKeys } from 'react/shared/shapes/field_label_shape';

function FieldLabel({
  labelContent,
  labelClass,
  labelContainerClass,
  afterLabel,
  afterLabelClass,
  optional,
  htmlFor,
}) {
  const optionalJsx = <span className={afterLabelClass}>Optional</span>;

  return (
    <div className={labelContainerClass}>
      <span className={labelClass}>
        {_isString(labelContent) ? <label htmlFor={htmlFor}>{labelContent}</label> : labelContent}
        {optional && optionalJsx}
      </span>
      {afterLabel && <span className={afterLabelClass}>{afterLabel}</span>}
    </div>
  );
}

FieldLabel.propTypes = fieldLabelKeys;

FieldLabel.defaultProps = {
  labelClass: 'flex',
  labelContainerClass: 'label-tooltip-container',
  afterLabel: null,
  afterLabelClass: 'card--form__input-addition card--form__input-addition--inline',
  optional: false,
  htmlFor: '',
};

export default FieldLabel;
