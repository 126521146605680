import PropTypes from 'prop-types';
import React from 'react';
import NavigationButtons from './navigation_buttons';
import { States } from './states';
import bindAll from 'react/shared/utils/bind_all';

export default class QuestionnaireEntry extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedOption: this.props.orgType,
      error: undefined,
    };

    bindAll(this);
  }

  transitionTo(ev) {
    ev.preventDefault();
    const { selectedOption } = this.state;

    if (!selectedOption) {
      this.props.ensureOption(this, '* Please complete the form before continuing');
    } else {
      const nextState = States[selectedOption.toUpperCase()].FIRST;
      this.props.transition(nextState, { orgType: this.state.selectedOption });
    }
  }

  handleOptionChange(ev) {
    this.setState({ selectedOption: ev.target.value, error: undefined });
  }

  render() {
    const { selectedOption, error } = this.state;

    return (
      <div>
        <div className="questionnaire-form-item questionnaire-form-item__medium">
          <form onSubmit={this.transitionTo}>
            <div className="questionnaire-form-item-content">
              <div className="questionnaire-form-item-question">
                <h2>Are you signing up in a professional capacity?</h2>
              </div>
              <div className="hide-on-mobile">{error && <p className="form-error">{error}</p>}</div>
              <div className="questionnaire-radio-wrapper">
                <div className="questionnaire-radio questionnaire-radio--large">
                  <input
                    checked={selectedOption == 'professional'}
                    className="questionnaire-radio__input"
                    id="professional-radio"
                    onChange={this.handleOptionChange}
                    type="radio"
                    value="professional"
                  />
                  <label className="questionnaire-radio__label" htmlFor="professional-radio">
                    <span className="questionnaire-radio__checkbox-container" />
                    <span className="questionnaire-radio__text">Yes</span>
                  </label>
                </div>
                <div className="questionnaire-radio questionnaire-radio--large">
                  <input
                    checked={selectedOption == 'consumer'}
                    className="questionnaire-radio__input"
                    id="consumer-radio"
                    onChange={this.handleOptionChange}
                    type="radio"
                    value="consumer"
                  />
                  <label className="questionnaire-radio__label" htmlFor="consumer-radio">
                    <span className="questionnaire-radio__checkbox-container" />
                    <span className="questionnaire-radio__text">No</span>
                  </label>
                </div>
              </div>
              <h3 className="questionnaire-info-section">
                True Link can be used by everyone; we just need to know this information to set up
                your account properly
              </h3>
            </div>
            <div className="show-on-mobile">{error && <p className="form-error">{error}</p>}</div>
            <NavigationButtons />
          </form>
        </div>
      </div>
    );
  }
}

QuestionnaireEntry.propTypes = {
  ensureOption: PropTypes.func.isRequired,
  orgType: PropTypes.string,
  transition: PropTypes.func.isRequired,
};
