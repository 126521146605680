import { Field } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import FormSelectField from 'react/shared/components/forms/form_select_field';

export default function QuarterlyDateSelect({ options, value }) {
  return (
    <div>
      <p>Select time period</p>
      <Field component={FormSelectField} name="quarterlyDate" options={options} value={value} />
    </div>
  );
}

QuarterlyDateSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired,
  ),
  value: PropTypes.string,
};
