import { shape, string, number } from 'prop-types';

export const cardShape = shape({
  id: string.isRequired,
  lastFour: string.isRequired,
  status: string.isRequired,
  balance: shape({
    amount: number,
    currency: string.isRequired,
    timestamp: string.isRequired,
  }),
});

export const accountShape = shape({
  id: string.isRequired,
  relationships: shape({
    currentCard: shape({
      id: string,
    }),
  }),
});
