import PropTypes from 'prop-types';
import React from 'react';

export default function TableRowCheckbox({ row }) {
  const { indeterminate, ...props } = row.getToggleRowSelectedProps();

  return (
    <input
      aria-label={`select-row-${row.index}`}
      id={`select-${row.index}`}
      type="checkbox"
      {...props}
    />
  );
}

TableRowCheckbox.propTypes = {
  row: PropTypes.shape({
    index: PropTypes.number.isRequired,
    getToggleRowSelectedProps: PropTypes.func.isRequired,
  }).isRequired,
};
