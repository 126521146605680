import DashboardActions from 'react/member/actions/dashboard_actions';
import api from 'react/member/card/components/funding_tab/api';

const DashboardAutoTransferStore = flux.createStore({
  autoTransfers: [],
  actions: [
    DashboardActions.fetchScheduledAutoTransfers,
    DashboardActions.createAutoTransfer,
    DashboardActions.updateAutoTransfer,
    DashboardActions.deleteAutoTransfer,
  ],

  fetchScheduledAutoTransfers() {
    api.getAutoTransfers().then((response) => {
      const autoTransfers = response.data;
      this.autoTransfers = autoTransfers;
      this.emit('scheduled_auto_transfers.fetch', autoTransfers);
    });
  },

  createAutoTransfer(data, callback = null) {
    api.createAutoTransfer(data).then((response) => {
      const autoTransfer = response.data;
      this.emit('auto_transfer.create', autoTransfer);
      this.fetchScheduledAutoTransfers();
      DashboardActions.fetchScheduledTransfers();
      Truelink.flash('success', 'Your recurring transfer has been saved.', true, true);
      if (callback) {
        callback(autoTransfer);
      }
    });
  },

  updateAutoTransfer(autoTransferId, data, callback = null) {
    api.updateAutoTransfer(autoTransferId, data).then((response) => {
      const autoTransfer = response.data;
      this.emit('auto_transfer.update', autoTransfer);
      this.fetchScheduledAutoTransfers();
      Truelink.flash('success', 'Your recurring transfer has been saved.', true, true);
      if (callback) {
        callback(autoTransfer);
      }
    });
  },

  deleteAutoTransfer(autoTransferId, callback = null) {
    api.deleteAutoTransfer(autoTransferId).then((_response) => {
      this.emit('auto_transfer.delete');
      this.fetchScheduledAutoTransfers();
      DashboardActions.fetchScheduledTransfers();
      DashboardActions.reloadHolidayBanner();
      Truelink.flash('success', 'Recurring transfer was removed.', true, true);
      if (callback) {
        callback();
      }
    });
  },

  exports: {
    findAutoTransfer(id) {
      return this.autoTransfers.find((autoTransfer) => autoTransfer.id === id);
    },
    autoTransfers() {
      return this.autoTransfers;
    },
  },
});

export default DashboardAutoTransferStore;
