import PropTypes from 'prop-types';
import React from 'react';
import InfoTooltip from 'react/shared/components/tooltips/InfoTooltip';

const cardProgramTextContent = {
  1: {
    title: 'Designed for beneficiaries with Special Needs Trusts',
    text: 'If this cardholder has a Special Needs Trust, you should select our card designed especially for these beneficiaries. This card does not include access to our ATM network.',
  },
  2: {
    title: 'Designed for individuals with Special Needs Trusts',
    text: 'If this cardholder has a special needs trust, you should select our card designed especially for these individuals. This card does not include access to our ATM network',
  },
  3: {
    // and 4
    title: "Designed for clients who aren't restricted on where they can spend funds",
    text: "If this cardholder receives a spending allowance for personal use, you should select our card designed especially for clients of representative payees who don't require restrictions on where they can spend funds.",
  },
  5: {
    title: 'With standard features and access to in-network ATMs',
    text: 'If this cardholder needs access to our ATM network, you should select our classic card with standard features that includes access to our ATM network.',
  },
  6: {
    title: 'With standard features but without access to in-network ATMs',
    text: 'If this cardholder should NOT have access to our ATM network, you should select our card with standard features that does not include access to our ATM network.',
  },
  7: {
    title: 'Designed for individuals in recovery',
    text: 'If this cardholder is in recovery from addiction, you should select our card designed especially for individuals in recovery.',
  },
  8: {
    // and 9
    title: 'Designed for STABLE beneficiaries',
    text: 'If this cardholder will be using this card to disburse funds from a STABLE account, you should select our card designed especially for this program.',
  },
  12: {
    title: 'Designed for ABLE beneficiaries',
    text: 'If this cardholder will be using this card to disburse funds from an ABLE account, you should select our card designed especially for this program.',
  },
};
cardProgramTextContent[4] = cardProgramTextContent[3];
cardProgramTextContent[9] = cardProgramTextContent[8];

function CardProgramsTooltip({ allowedCardPrograms, placement }) {
  let content = Object.keys(allowedCardPrograms).map((programNumber) => {
    const { title, text } = cardProgramTextContent[programNumber];
    return `<b>${title}</b><p>${text}</p>`;
  });
  content = [...new Set(content)]; // remove duplicates

  const tooltipText = `<b>How to select a card?</b><br/><br />${content.join('')}`;

  return (
    <InfoTooltip
      html
      iconClass="question-circle-green"
      id="card-programs-tooltip"
      placement={placement}
      tooltipStyle="width: 70vw; max-width: 320px"
      tooltipText={tooltipText}
    />
  );
}

CardProgramsTooltip.propTypes = {
  allowedCardPrograms: PropTypes.objectOf(PropTypes.string).isRequired,
  placement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
};

CardProgramsTooltip.defaultProps = {
  placement: 'left',
};

export default CardProgramsTooltip;
export { cardProgramTextContent };
