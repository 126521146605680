import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles({
  statusLabel: {
    backgroundColor: '#F1DADA',
    borderRadius: '4px',
    color: '#EB5757',
    fontSize: '12px',
    fontWeight: 700,
    padding: '0 15px',
  },
});

export default function CancelledLabel(_props) {
  const classes = useStyles();
  return <div className={classes.statusLabel}>CANCELLED</div>;
}
