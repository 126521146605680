import { ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import TextField from 'react/member/components/signups/signup_form/form_partials/fields/TextField';
import FieldLabel from 'react/member/components/signups/signup_form/form_partials/labels/field_label';

function EinField({ entity, rowClass, colClass }) {
  return (
    <div className={rowClass}>
      <div className={colClass}>
        <FieldLabel
          labelContent={
            <label htmlFor={`${entity}_ein`}>
              <span className="hide-on-mobile">What is the </span>
              Employment Identification Number
              <span className="hide-on-mobile"> ("EIN") for your business?</span>
            </label>
          }
        />
        <TextField
          dataAnalyticsKey="business_ein"
          id={`${entity}_ein`}
          mask="99-9999999"
          name={`${entity}.ein`}
        />
        <ErrorMessage className="error" component="label" name={`${entity}.ein`} />
      </div>
    </div>
  );
}

EinField.propTypes = {
  entity: PropTypes.oneOf(['organization']).isRequired,
  rowClass: PropTypes.string,
  colClass: PropTypes.string,
};

EinField.defaultProps = {
  rowClass: 'card--form__row',
  colClass: 'card--form__col width-100',
};

export default EinField;
