import makeStyles from '@mui/styles/makeStyles';
import { ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import PALETTE from 'react/shared/theme/palette';

const useStyles = makeStyles(() => ({
  errorMessage: {
    color: PALETTE.red,
    marginBottom: '12px',
  },
}));

export default function ErrorText({ name }) {
  const classes = useStyles();
  return <ErrorMessage className={classes.errorMessage} component="p" name={name} />;
}

ErrorText.propTypes = {
  name: PropTypes.string.isRequired,
};
