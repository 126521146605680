import DashboardActions from 'react/member/actions/dashboard_actions';

const DashboardCardTransactionNoteStore = flux.createStore({
  cardTransactionNotes: [],
  actions: [
    DashboardActions.fetchCardTransactionNotes,
    DashboardActions.createCardTransactionNote,
    DashboardActions.updateCardTransactionNote,
    DashboardActions.destroyCardTransactionNote,
  ],

  fetchCardTransactionNotes(card_transaction_id) {
    return $.get(
      RailsRoutes.dashboard_card_transaction_notes_path({
        card_transaction_id,
        format: 'json',
      }),
      (function (_this) {
        return function (cardTransactionNotes) {
          _this.cardTransactionNotes = cardTransactionNotes;
          return _this.emit('cardTransactionNotes.fetch', cardTransactionNotes);
        };
      })(this),
    );
  },

  createCardTransactionNote(data) {
    const url = RailsRoutes.dashboard_card_transaction_notes_path({
      format: 'json',
    });
    $.ajax({
      url,
      type: 'POST',
      data,
      dataType: 'json',
    })
      .done((cardTransactionNote) => {
        this.emit('cardTransactionNote.create', cardTransactionNote);
      })
      .fail((_response) => {
        Truelink.flash(
          'error',
          'Unable to create the card transaction note. Please contact True Link support for further assistance.',
        );
      });
  },

  updateCardTransactionNote(card_transaction_note_id, data) {
    const url = RailsRoutes.dashboard_card_transaction_note_path(card_transaction_note_id, {
      format: 'json',
    });
    return $.ajax({
      type: 'PUT',
      url,
      data,
    })
      .done((cardTransactionNote) => {
        this.emit('cardTransactionNote.update', cardTransactionNote);
      })
      .fail((_response) => {
        Truelink.flash(
          'error',
          'Unable to update the card transaction note. Please contact True Link support for further assistance.',
        );
      });
  },

  destroyCardTransactionNote(card_transaction_note_id) {
    $.ajax({
      method: 'DELETE',
      url: RailsRoutes.dashboard_card_transaction_note_path(card_transaction_note_id, {
        format: 'json',
      }),
    })
      .done(() => {
        this.emit('cardTransactionNote.destroy', card_transaction_note_id);
      })
      .fail((_response) =>
        Truelink.flash(
          'error',
          'Unable to create the card transaction note. Please contact True Link support for further assistance.',
        ),
      );
  },
});

export default DashboardCardTransactionNoteStore;
