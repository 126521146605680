import React from 'react';
import PreRelease from './display/replacement_card/PreRelease';
import Section from 'react/member/card/components/account_tab/card_status/Section';
import { accountShape } from 'react/member/card/shapes/AccountShape';
import { CARD_STATUSES } from 'react/member/utils/CardStatus';

export default function ReplacementCardReissueStatus({ account }) {
  return (
    <Section>
      <PreRelease
        programType={account.program_type}
        status={CARD_STATUSES.PRE_RELEASE_REPLACEMENT_CARD_ORDER_STATUS}
      />
    </Section>
  );
}

ReplacementCardReissueStatus.propTypes = {
  account: accountShape.isRequired,
};
