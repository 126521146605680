import PropTypes from 'prop-types';
import React from 'react';
import AreYouSurePopUp from 'react/shared/components/popups/AreYouSurePopUp';
import bindAll from 'react/shared/utils/bind_all';

export default class DepositAttachment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
    };

    bindAll(this);
  }

  onDestroy(evt) {
    evt.preventDefault();
    const { deposit, attachment, actions } = this.props;
    if (deposit) {
      actions.destroyBudgetItemAttachmentForBudgetItem(attachment.id, deposit);
    } else {
      actions.destroyBudgetItemAttachment(attachment.id);
    }
    this.closeDestructionModal();
  }

  closeDestructionModal() {
    this.setState({ showModal: false });
  }

  showDestructionModal(evt) {
    evt.stopPropagation();
    this.setState({ showModal: true });
  }

  render() {
    const { attachment, userCanEdit } = this.props;

    const url = RailsRoutes.api_v2_attachment_path(attachment.id);
    return (
      <tr key={attachment.id}>
        <td className="break-word">
          <a className="inline-block" href={url}>
            {' '}
            {attachment.name}
          </a>
        </td>
        {userCanEdit && (
          <td className="align-right" style={{ width: 75 }}>
            <a
              onClick={this.showDestructionModal}
              onKeyUp={this.showDestructionModal}
              role="link"
              tabIndex="0"
            >
              <i className="trash" style={{ marginTop: -5 }} />
            </a>
            <input
              defaultValue={attachment.id}
              name="attachment_ids[]"
              style={{ display: 'none' }}
            />
            {this.state.showModal && (
              <AreYouSurePopUp
                key={attachment.id}
                messageText={'Are you sure you want to delete this document?'}
                onClose={this.closeDestructionModal}
                onSubmit={this.onDestroy}
                openModal={this.state.showModal}
                submitText={'Yes, delete'}
              />
            )}
          </td>
        )}
      </tr>
    );
  }
}

DepositAttachment.propTypes = {
  attachment: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  deposit: PropTypes.object,
  userCanEdit: PropTypes.bool,
};
