import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';
import PALETTE from 'react/shared/theme/palette';

export default function TrueLinkIconAndTitle(props) {
  return (
    <SvgIcon alt="True Link Logo" viewBox="0 0 140 29" {...props}>
      <path
        d="M0 28.7174C0.36358 25.9906 2.68963 23.9541 5.44061 23.9541H19.3288C22.0798 23.9541 24.4058 25.9906 24.7694 28.7174V28.7174H0V28.7174Z"
        fill={PALETTE.evergreen}
      />
      <path
        d="M24.7695 0.77279C24.406 3.49964 22.0799 5.53613 19.3289 5.53613L5.44076 5.53613C2.68978 5.53613 0.36373 3.49964 0.000150203 0.772788V0.772788L24.7695 0.77279V0.77279Z"
        fill={PALETTE.evergreen}
      />
      <rect fill={PALETTE.emerald} height="19.0534" width="17.4656" x="3.49329" y="5.21777" />
      <path
        d="M38.5222 10.3138H43.0415V22.5469H46.6954V10.3138H51.1826V7.09792H38.5222V10.3138ZM52.5959 22.5469H56.2285V16.8737C56.2285 15.5596 56.9443 14.4485 59.7221 14.7262C59.8289 14.7262 59.9465 14.7369 60.0212 14.769V11.3181C59.9785 11.2967 59.8503 11.286 59.7007 11.286C57.8097 11.286 56.6131 12.6001 56.1002 14.3309V11.4997H52.5959V22.5469ZM65.6704 22.7606C66.8563 22.7606 68.5123 22.3866 68.9183 20.3139L69.3456 22.5469H72.0594V11.4997H68.4482V18.7007C68.4482 19.5874 67.8072 20.1644 66.7815 20.1644C65.7131 20.1644 65.1255 19.5554 65.1255 18.722V11.4997H61.4823V19.2135C61.4823 21.4999 63.7686 22.7606 65.6704 22.7606ZM77.6377 17.8566H84.828V16.9485C84.828 13.6579 83.0331 11.286 79.5394 11.286C76.0778 11.286 74.0586 13.9143 74.0586 17.1515C74.0586 20.549 76.3449 22.7606 79.7104 22.7606C82.0822 22.7606 84.4861 21.3182 84.8493 18.9143H81.6014C81.2809 19.8652 80.533 20.1857 79.6142 20.1857C78.4283 20.1857 77.627 19.3631 77.6377 17.8566ZM77.627 15.7626C77.627 14.7369 78.3963 13.7754 79.5394 13.7754C80.8108 13.7754 81.4519 14.6728 81.4519 15.7626H77.627ZM91.8558 7.09792L91.8451 22.5469H101.941V19.5233H95.499V7.09792H91.8558ZM107.318 7.09792H103.824V10.2604H107.318V7.09792ZM107.297 11.4997H103.835V22.5469H107.297V11.4997ZM109.772 22.5469H113.404V15.6237C113.404 14.7156 114.291 14.2455 115.114 14.2455C115.926 14.2455 116.663 14.7369 116.663 15.7092V22.5469H120.317V15.2284C120.317 12.5147 118.426 11.4249 116.449 11.4249C114.708 11.4249 113.842 12.301 113.361 13.0489V11.4997H109.772V22.5469ZM133.695 22.5469L129.593 15.9869L133.61 11.4997H129.764L126.216 15.6451V6.67057H122.584V22.5469H126.216V19.3844L127.349 18.1344L130.127 22.5469H133.695Z"
        fill={PALETTE.emerald}
      />
    </SvgIcon>
  );
}
