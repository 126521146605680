import Dropzone from 'dropzone';
import 'dropzone/dist/dropzone.css';
import PropTypes from 'prop-types';
import React from 'react';
import PopUp from 'react/shared/components/popups/PopUp';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import bindAll from 'react/shared/utils/bind_all';

export default class DropZonePopUp extends React.Component {
  constructor(props) {
    super(props);

    this.uploadRef = React.createRef();

    bindAll(this);
  }

  componentDidMount() {
    const {
      uploadUrl: url,
      uploadParamName: paramName,
      onSuccess,
      appendFormDataOnSubmit: appendFormData,
    } = this.props;

    const dropzone = new Dropzone(this.uploadRef.current, {
      url,
      paramName,
      autoProcessQueue: false,
      withCredentials: true,
      success: onSuccess,
      parallelUploads: 10,
      maxFiles: 10,
    });
    dropzone.on('sending', (file, xhr, formData) => {
      appendFormData(file, xhr, formData);
    });
  }

  handleSubmit() {
    this.props.onSubmit(this.uploadRef.current);
  }

  allowedExtensionsMessage() {
    return `Supported file types: ${$tlf.domains.allowedExtensions.join(', ')}`;
  }

  renderSubmitFooter() {
    return (
      <TrueLinkButton
        className="btn btn-success"
        disabled={this.props.uploadDisabled}
        onClick={this.handleSubmit}
        variant="none"
      >
        Upload
      </TrueLinkButton>
    );
  }

  render() {
    return (
      <PopUp
        footer={this.renderSubmitFooter()}
        header={this.props.headerText}
        onClose={this.props.onClose}
        openModal
      >
        <form
          action="/file-upload"
          className="dropzone"
          id="file-upload-dropzone"
          ref={this.uploadRef}
        >
          <div className="dz-message" data-dz-message>
            <p>Drag and drop up to 10 files from your computer here</p>
            <p>or</p>
            <div className="btn btn-mini normal">Select file</div>
            <p className="dropzone-text">{this.allowedExtensionsMessage()}</p>
          </div>
        </form>
        {this.props.additionalContent}
      </PopUp>
    );
  }
}

DropZonePopUp.propTypes = {
  headerText: PropTypes.string.isRequired,
  uploadUrl: PropTypes.string.isRequired,
  uploadParamName: PropTypes.string.isRequired,
  uploadDisabled: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  appendFormDataOnSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  additionalContent: PropTypes.node,
};
