import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { cardShape } from 'react/member/card/shapes/CardShape';
import { cardholderShape } from 'react/member/card/shapes/CardholderShape';
import SubSectionHeader from 'react/shared/components/SubSectionHeader';
import TlFieldTransformers from 'react/shared/utils/tl_field_transformers';

const { formatPhoneNumber } = TlFieldTransformers;

const useStyles = makeStyles({
  cardholderTable: {
    '& td:first-of-type': {
      textAlign: 'right',
    },
  },
});

function CardInInvestCardholderInfo({ card, cardholder }) {
  const classes = useStyles();
  return (
    <>
      <SubSectionHeader>Cardholder information</SubSectionHeader>
      <div className="account_info_section">
        <table className={classes.cardholderTable}>
          <tbody>
            <tr>
              <td>Name:</td>
              <td>{cardholder.name}</td>
            </tr>
            <tr>
              <td>Last 4 Digits of True Link Card:</td>
              <td>{card && card.card_last_4}</td>
            </tr>
            <tr>
              <td>Phone:</td>
              <td>{formatPhoneNumber(cardholder.mobile)}</td>
            </tr>
            <tr>
              <td>Address:</td>
              <td>{cardholder.address_as_string}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}

CardInInvestCardholderInfo.propTypes = {
  card: cardShape,
  cardholder: cardholderShape.isRequired,
};

export default CardInInvestCardholderInfo;
