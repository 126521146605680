import axios from 'axios';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import LoadingIndicator from 'react/shared/components/LoadingIndicator';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import TrueLinkIcon from 'react/shared/components/true_link/main/TrueLinkIcon';
import { currencyFormatter } from 'react/shared/utils/Money';

export default function TransferSchedule({ transfer, onShowTransfer }) {
  const [transferSchedule, setTransferSchedule] = useState(null);
  const [showSchedule, setShowSchedule] = useState(false);

  const arrowClass = showSchedule ? 'angle-up' : 'angle-down';
  const scheduleText = showSchedule ? 'Hide' : 'Show';

  useEffect(() => {
    setTransferSchedule(null);
    setShowSchedule(false);
  }, [transfer]);

  const fetchTransferSchedule = () => {
    // don't fetch transfer schedule for invalid transfers
    if (!(transfer.transfer_amount || transfer.amount)) {
      setShowSchedule(false);
      return;
    }

    const data = {
      amount: transfer.transfer_amount || transfer.amount,
      day_of_week: transfer.transfer_day_of_week || transfer.dayOfWeek,
      day_of_month: transfer.transfer_day_of_month || transfer.dayOfMonth,
      day_of_month_2: transfer.transfer_day_of_month_2 || transfer.dayOfMonth2,
      recurring_type: transfer.transfer_frequency || transfer.recurringType,
    };

    axios
      .post(RailsRoutes.schedule_dashboard_auto_transfers_path({ format: 'json' }), {
        auto_transfer: data,
      })
      .then((response) => {
        const transferSchedule = {
          amount: response.data.amount,
          next_5_dates: response.data.next_5_dates.map((date) => ({
            adjusted: date.adjusted_date,
            unadjusted: date.unadjusted_date,
          })),
        };

        setTransferSchedule(transferSchedule);
      });
  };

  const toggleTransferSchedule = (e) => {
    e.preventDefault();

    if (showSchedule) {
      setShowSchedule(false);
    } else {
      onShowTransfer();
      setShowSchedule(true);
      fetchTransferSchedule();
    }
  };

  const transferScheduleBody = () => {
    if (!transferSchedule) {
      return <LoadingIndicator containerStyle={{ width: 'auto' }} />;
    }

    return (
      <div className="transfer_schedule_container">
        <table className="transfer_schedule_table">
          <tbody>
            {transferSchedule.next_5_dates.map((date, index) => (
              <tr key={index}>
                <td>{moment(date.adjusted).format('dddd, MMMM Do, YYYY')}</td>
                <td>{currencyFormatter(transferSchedule.amount, { minimumFractionDigits: 0 })}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div>
      <TrueLinkButton
        className="btn btn-link"
        key={`icon-${arrowClass}`}
        onClick={toggleTransferSchedule}
        style={{ padding: 0, marginTop: '15px' }}
        variant="none"
      >
        {scheduleText} transfer schedule
        <TrueLinkIcon icon={arrowClass} style={{ marginLeft: '5px' }} />
      </TrueLinkButton>

      {showSchedule && transferScheduleBody()}
    </div>
  );
}

TransferSchedule.propTypes = {
  transfer: PropTypes.any,
  onShowTransfer: PropTypes.func,
};
