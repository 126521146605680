import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import TableRow from './TableRow';

const useStyles = makeStyles({
  cell: {
    padding: '15px',
  },
});

export default function TableRows({
  noDataText,
  rows,
  prepareRow,
  rowProps,
  rowSubComponent,
  selectable,
}) {
  const classes = useStyles();

  return (
    <>
      {rows.map((row, idx) => (
        <TableRow
          key={idx}
          prepareRow={prepareRow}
          row={row}
          rowProps={rowProps}
          rowSubComponent={rowSubComponent}
          selectable={selectable}
        />
      ))}
      {rows.length === 0 && (
        <tr>
          <td className={classes.cell} colSpan="9999">
            <p>{noDataText}</p>
          </td>
        </tr>
      )}
    </>
  );
}

TableRows.propTypes = {
  noDataText: PropTypes.string,
  prepareRow: PropTypes.func.isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      cells: PropTypes.arrayOf(PropTypes.object).isRequired,
    }),
  ).isRequired,
  rowProps: PropTypes.func,
  rowSubComponent: PropTypes.func,
  selectable: PropTypes.bool.isRequired,
};
