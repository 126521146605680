import PropTypes from 'prop-types';

const { string, array, number, shape } = PropTypes;

export const userAccessInvitationShape = shape({
  id: number,
  roles: array,
  created_at: string,
  created_by: shape({
    user: shape({
      id: number,
      email: string,
    }),
  }),
  user: shape({
    roles: array,
  }),
});

export default userAccessInvitationShape;
