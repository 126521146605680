import createReactClass from 'create-react-class';
import React from 'react';
import _omit from 'underscore/modules/omit';
import FieldMixin from './FieldMixin';

const HiddenField = createReactClass({
  mixins: [FieldMixin],

  componentDidMount() {
    this.initField();
  },

  render() {
    return (
      <input
        {..._omit(this.props, 'association', 'controller')}
        name={this.inputName()}
        type="hidden"
        value={this.value() || ''}
      />
    );
  },
});

export default HiddenField;
