import PropTypes from 'prop-types';
import React from 'react';
import _extend from 'underscore/modules/extend';
import childrenShape from 'react/shared/shapes/children';
import bindAll from 'react/shared/utils/bind_all';

export default class PopUp extends React.Component {
  constructor(props) {
    super(props);

    bindAll(this);
    document.addEventListener('keydown', this.escFunction, false);
  }

  componentWillUnmount() {
    $('body').removeClass('modal-open'); // cleanup the body class, in case the component is destroyed before it gets a chance to run its close method
    document.removeEventListener('keydown', this.escFunction, false);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      if (this.props.cancel) {
        this.props.cancel();
        return;
      }
      if (this.props.onClose) {
        this.props.onClose();
      }
    }
  }

  isModalOpen() {
    return this.props.openModal === true || this.props.modalOpen === true;
  }

  dismissButton() {
    const close = this.props.cancel ? this.props.cancel : this.props.onClose;
    if (!close || this.props.hideDismissButton) return null;

    const dismissBtnText = this.props.dismissBtnText;
    return (
      <button
        aria-label="close-link"
        className={dismissBtnText ? 'btn btn-link cancel' : 'btn-modal-close'}
        data-dismiss="modal"
        onClick={close}
        type="button"
      >
        <span aria-hidden="true">{dismissBtnText || 'Cancel'}</span>
      </button>
    );
  }

  errorHeader() {
    if (!this.props.showBanner) return null;

    const errorsFixed = this.props.bannerMessage == 'All errors have been fixed!';
    let bannerClass = 'alert alert--banner alert-';
    bannerClass += errorsFixed ? 'fixed' : 'danger';

    return (
      <div
        className={bannerClass}
        style={{
          paddingLeft: 20,
          paddingRight: 20,
          justifyContent: 'flex-start',
        }}
      >
        <div>{this.props.bannerMessage}</div>
      </div>
    );
  }

  isDisplayed() {
    const style = {
      background: this.props.background ? this.props.background : 'rgba(0,0,0,.75)',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 1001,
      padding: this.props.padding,
      paddingTop: this.props.paddingTop,
      overflow: 'auto',
      display: this.isModalOpen() ? 'initial' : 'none',
    };
    const other_options = this.props.modalWrapperStyle ? this.props.modalWrapperStyle : {};
    return _extend(other_options, style);
  }

  modalBodyStyle() {
    const style = {};
    const height = 'auto';
    const maxHeight = this.props.maxBodyHeight;
    const minHeight = this.props.minBodyHeight;
    if (height != undefined) {
      style.height = height;
    }
    if (maxHeight) {
      style.maxHeight = maxHeight;
    }
    if (minHeight) {
      style.minHeight = minHeight;
    }
    return _extend(style, this.props.modalBodyStyle);
  }

  modalHeaderStyle() {
    if (this.props.modalHeaderStyle) {
      return this.props.modalHeaderStyle;
    } else if (this.props.hideHeader) {
      return { display: 'none' };
    }
  }

  modalFooterStyle() {
    if (this.props.hideFooter) {
      return { display: 'none' };
    }
  }

  contentStyle() {
    const maxHeight = this.props.maxHeight;
    const maxWidth = this.props.maxWidth;
    const padding = this.props.padding;
    const margin = this.props.margin;
    const style = {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      margin: '4rem auto',
      padding: 0,
      maxWidth: '825px',
      textAlign: 'left',
    };
    if (maxHeight != undefined) {
      style.maxHeight = maxHeight;
    }
    if (maxWidth != undefined) {
      style.maxWidth = maxWidth;
    }
    if (padding != undefined) {
      style.padding = padding;
    }
    if (margin != undefined) {
      style.margin = margin;
    }

    return style;
  }

  render() {
    if (!this.isModalOpen()) return null;

    const bodyClasses = ['modal-body', this.props.modalBodyClass].join(' ');
    const { headerComponent: HeaderComponent } = this.props;

    return (
      <div id={this.props.id} style={this.isDisplayed()}>
        <div className="modal-dialog">
          <div className="modal-content" style={this.contentStyle()}>
            <div className="modal-header" style={this.modalHeaderStyle()}>
              <HeaderComponent className="modal-title">{this.props.header}</HeaderComponent>
              {this.dismissButton()}
            </div>
            {this.errorHeader()}
            <div className={bodyClasses} style={this.modalBodyStyle()}>
              {this.props.children}
            </div>
            <div className="modal-footer" style={this.modalFooterStyle()}>
              {this.props.footer}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PopUp.propTypes = {
  id: PropTypes.string,
  openModal: PropTypes.bool,
  modalOpen: PropTypes.bool,
  onClose: PropTypes.func,
  cancel: PropTypes.func, // same as onClose
  children: childrenShape,
  header: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
  headerComponent: PropTypes.oneOf(['h1', 'h2', 'h3']),
  footer: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
  padding: PropTypes.string,
  paddingTop: PropTypes.string,
  margin: PropTypes.string,
  maxWidth: PropTypes.string,
  maxHeight: PropTypes.string,
  maxBodyHeight: PropTypes.string,
  minBodyHeight: PropTypes.string,
  hideHeader: PropTypes.bool,
  hideFooter: PropTypes.bool,
  background: PropTypes.string,
  hideDismissButton: PropTypes.bool,
  modalBodyClass: PropTypes.string,
  modalBodyStyle: PropTypes.object,
  modalHeaderStyle: PropTypes.object,
  modalWrapperStyle: PropTypes.object, // Styles the container
  showBanner: PropTypes.bool,
  bannerMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  dismissBtnText: PropTypes.string,
};

PopUp.defaultProps = {
  position: 'fixed',
  dismissBtnText: 'Cancel',
  openModal: false,
  modalOpen: false,
  errorMessage: 'ERROR',
  headerComponent: 'h3',
};
