import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import childrenShape from 'react/shared/shapes/children';

export default function SubSectionHeader({ id, style, children, first, noMarginBottom }) {
  return (
    <div
      className={classNames('sub_section_header', { first, 'margin-bottom-0': noMarginBottom })}
      id={id}
      style={style}
    >
      <span>{children}</span>
    </div>
  );
}

SubSectionHeader.propTypes = {
  id: PropTypes.string,
  first: PropTypes.bool,
  children: childrenShape.isRequired,
  noMarginBottom: PropTypes.bool,
  style: PropTypes.object,
};
