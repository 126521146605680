import PropTypes from 'prop-types';
import React from 'react';

export default function TableHeaderCheckbox({ getToggleAllRowsSelectedProps }) {
  const { indeterminate, ...props } = getToggleAllRowsSelectedProps();

  return <input aria-label={'select-all-rows'} type="checkbox" {...props} />;
}

TableHeaderCheckbox.propTypes = {
  getToggleAllRowsSelectedProps: PropTypes.func.isRequired,
};
