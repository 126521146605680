import classNames from 'classnames';
import { ErrorMessage, Form, Field, Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import Textarea from 'react-textarea-autosize';
import _intersection from 'underscore/modules/intersection';
import _without from 'underscore/modules/without';
import * as Yup from 'yup';
import BeneficiaryDashboardActions from 'react/member/actions/beneficiary_dashboard_actions';
import DashboardPaymentMethodInputs from 'react/member/components/dashboard/budget_items/DashboardPaymentMethodInputs';
import DashboardBudgetItemAttachments from 'react/member/components/dashboard/disbursements/DashboardBudgetItemAttachments';
import DashboardPayee from 'react/member/components/dashboard/payees/DashboardPayee';
import userRoleShape from 'react/member/shapes/UserRoleShape';
import BeneficiaryDashboardAttachmentsStore from 'react/member/stores/beneficiary_dashboard/beneficiary_dashboard_attachments_store';
import BeneficiaryDashboardDisbursementsStore from 'react/member/stores/beneficiary_dashboard/beneficiary_dashboard_disbursements_store';
import BeneficiaryDashboardPayeesStore from 'react/member/stores/beneficiary_dashboard/beneficiary_dashboard_payees_store';
import PaymentMethodDropdown from 'react/shared/components/PaymentMethodDropdown';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import bindAll from 'react/shared/utils/bind_all';
import sendEvent from 'react/shared/utils/google_analytics';

export default class DisbursementRequestForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      payees: [],
      payee: null,
      payeesLoaded: false,
      createPayeeOpen: false,
    };

    bindAll(this);
  }

  componentDidMount() {
    const clientId = this.props.trustBeneficiary.slug;
    BeneficiaryDashboardActions.fetchPayees(clientId);

    BeneficiaryDashboardPayeesStore.on('payees.fetch', this.onChangeBeneficiaryPayee);
    BeneficiaryDashboardPayeesStore.on('payees.create', this.onAddBeneficiaryPayee);
    BeneficiaryDashboardDisbursementsStore.on(
      'disbursements.create.error',
      this.onCreateDisbursementError,
    );
    BeneficiaryDashboardAttachmentsStore.on('attachments.destroy', this.onDestroyAttachment);
    BeneficiaryDashboardAttachmentsStore.on('attachment.upload', this.onUploadAttachment);
  }

  componentWillUnmount() {
    BeneficiaryDashboardPayeesStore.off('payees.fetch', this.onChangeBeneficiaryPayee);
    BeneficiaryDashboardPayeesStore.off('payees.create', this.onAddBeneficiaryPayee);
    BeneficiaryDashboardDisbursementsStore.off(
      'disbursements.create.error',
      this.onCreateDisbursementError,
    );
    BeneficiaryDashboardAttachmentsStore.off('attachments.destroy', this.onDestroyAttachment);
    BeneficiaryDashboardAttachmentsStore.off('attachment.upload', this.onUploadAttachment);
  }

  onChangeBeneficiaryPayee(payees) {
    this.setState({
      payees,
      payeesLoaded: true,
    });
  }

  onAddBeneficiaryPayee(payee) {
    const clientId = this.props.trustBeneficiary.slug;
    BeneficiaryDashboardActions.fetchPayees(clientId);
    this.handleInputChange('payee_id', payee.id);
    this.setState({ payee });
  }

  onCreateDisbursementError() {
    this.form.setSubmitting(false);
  }

  onDestroyAttachment(attachmentId) {
    const previousAttachmentIds = this.form.values.attachment_ids || [];
    this.form.setFieldValue('attachment_ids', _without(previousAttachmentIds, attachmentId));
  }

  onUploadAttachment(attachment, _inputNumber) {
    const previousAttachmentIds = this.form.values.attachment_ids || [];
    this.form.setFieldValue('attachment_ids', previousAttachmentIds.concat([attachment.id]));
  }

  submitForm(values, { setSubmitting }) {
    const onRequestCompleted = () => {
      setSubmitting(false);
    };
    if (values.payment_method == 'Card') values.card_id = this.props.trustBeneficiary.card_id;
    const clientId = this.props.trustBeneficiary.slug;
    BeneficiaryDashboardActions.createDisbursement(clientId, values, onRequestCompleted);
    const routeTracker = window.sessionStorage.getItem('RequestDisbursementsRouteTracking');
    sendEvent('button_click', {
      campaign: 'Request Disbursement',
      page: 'Request Disbursement Form',
      reason: `Disbursement requested via ${routeTracker}`,
      component: 'DisbursementRequestForm',
    });
  }

  availableCard() {
    const { trustBeneficiary } = this.props;
    if (!trustBeneficiary.card_id) return null;
    return trustBeneficiary.cards.find((card) => card.id === trustBeneficiary.card_id);
  }

  availablePaymentMethods() {
    const userPaymentMethods = ['Check', 'EFT', ...(this.availableCard() ? ['Card'] : [])];
    return _intersection(
      userPaymentMethods,
      this.props.trustBeneficiary.trust.displayed_payment_types,
    );
  }

  togglePayeeForm(e) {
    e.preventDefault();
    this.setState({ createPayeeOpen: true });
  }

  closePayeeForm(e) {
    e.preventDefault();
    this.setState({ createPayeeOpen: false });
  }

  onPaymentMethodChange(paymentMethod) {
    this.handleInputChange('payment_method', paymentMethod);

    if (paymentMethod) {
      this.form.setFieldValue('payee_id', null);
      this.form.setFieldTouched('payee_id', false);
      this.form.setFieldValue('check_memo_line', '');
      this.form.setFieldTouched('check_memo_line', false);
      this.form.setFieldValue('card_last_4', '');
      this.form.setFieldTouched('card_last_4', false);
    }
  }

  handleInputChange(name, val) {
    this.form.setFieldValue(name, val);
  }

  validationSchema() {
    return Yup.object().shape({
      payee_id: Yup.number()
        .nullable()
        .when('payment_method', {
          is: (val) => val != 'Card',
          then: () => Yup.number().required('Required Field'),
        }),
      requestor_description: Yup.string().required('Required Field'),
      requested_by_signature: Yup.string().required('Required Field'),
      payment_method: Yup.string().required('Required Field'),
      card_last_4: Yup.string().when('payment_method', {
        is: 'Card',
        then: () =>
          Yup.string().matches(
            this.availableCard() && this.availableCard().card_last_4,
            'Invalid card number',
          ),
      }),
      amount: Yup.number()
        .typeError('Amount must be a number')
        .positive('Amount must be positive')
        .required('Required Field')
        .when('payment_method', {
          is: 'Card',
          then: () =>
            Yup.number()
              .typeError('Amount must be a number')
              .max(5000, 'Request maximum is $5,000'),
        }),
      check_memo_line: Yup.string(),
      attachment_ids: Yup.array().of(Yup.string()),
    });
  }

  initialValues() {
    return {
      payee_id: null,
      requestor_description: '',
      requested_by_signature: '',
      payment_method: '',
      card_last_4: '',
      check_memo_line: '',
      amount: '',
      attachment_ids: [],
    };
  }

  renderError(msg) {
    return <div className="form-error">{msg}</div>;
  }

  renderCardFields({ values }) {
    if (values.payment_method != 'Card') return null;
    return (
      <div className="new-form__section">
        <div>
          <label className="new-form__label" htmlFor="card-last-4">
            Last 4 Digits:
          </label>
          <div className="new-form__data">
            <Field id="card-last-4" maxLength={4} name="card_last_4" type="text" />
            <ErrorMessage name="card_last_4">
              {(msg) => <div className="form-error">{msg}</div>}
            </ErrorMessage>
          </div>
        </div>
      </div>
    );
  }
  renderForm(formProps) {
    this.form = formProps;

    const { values, errors, isSubmitting, isValid } = formProps;
    const { trustBeneficiary, userRoles } = this.props;
    const { createPayeeOpen, payees, payeesLoaded } = this.state;

    return (
      <div>
        {createPayeeOpen && (
          <DashboardPayee
            className="dashboard-new-payee"
            clientSlug={trustBeneficiary.slug}
            createPayeeAction={BeneficiaryDashboardActions.createPayee}
            displayedPaymentMethods={this.availablePaymentMethods()}
            onClose={this.closePayeeForm}
            onSelect={this.payeeSelected}
            paymentMethod={values.payment_method}
            userRoles={userRoles}
          />
        )}
        <Form>
          <div className="form-content-edit tl-form-edit align-left">
            <div className="new-form__section" style={{ paddingBottom: 0 }}>
              <div>
                <label className="new-form__label" htmlFor="requestorDescription">
                  Description:
                </label>
                <div className="new-form__data">
                  <Textarea
                    id="requestorDescription"
                    name="requestor_description"
                    onChange={(ev) =>
                      this.handleInputChange('requestor_description', ev.target.value)
                    }
                    style={{ width: '100%' }}
                    value={values.requestor_description}
                  />
                  <ErrorMessage name="requestor_description">{this.renderError}</ErrorMessage>
                </div>
              </div>
              <div>
                <label className="new-form__label" htmlFor="trustBeneficiaryName">
                  Beneficiary Name:
                </label>
                <div className="new-form__data" id="trustBeneficiaryName">
                  {trustBeneficiary.person.name}
                </div>
              </div>
              <div>
                <label className="new-form__label" htmlFor="requestedBySignature">
                  Requested By:
                </label>
                <div className="new-form__data">
                  <Field id="requestedBySignature" name="requested_by_signature" type="text" />
                  <ErrorMessage name="requested_by_signature">{this.renderError}</ErrorMessage>
                </div>
              </div>
              <div>
                <div className="new-form__label" />
                <div className="new-form__data">
                  <div style={{ float: 'left' }}>
                    <i className="info-circle" />
                  </div>
                  <div style={{ marginLeft: '10px', float: 'left', width: '90%' }}>
                    By entering your name here you are affirming that you are submitting this
                    disbursement request and that it is for the sole benefit of the beneficiary
                  </div>
                </div>
              </div>
            </div>
            <div className="new-form__section" style={{ paddingBottom: 0 }}>
              <PaymentMethodDropdown
                displayedPaymentMethods={this.availablePaymentMethods()}
                forceCheckPlainDisplay
                formErrors={errors}
                handlePaymentMethod={(ev) => this.onPaymentMethodChange(ev.target.value)}
                paymentMethod={values.payment_method}
              />
              <div>
                <div className="new-form__label" />
                <div className="new-form__data">
                  <div style={{ float: 'left' }}>
                    <i className="info-circle" />
                  </div>
                  <div style={{ marginLeft: '10px', float: 'left', width: '90%' }}>
                    You can submit requests for one-time disbursements in this portal. To request a
                    recurring disbursement, please contact your trust administrator.
                  </div>
                </div>
              </div>
            </div>
            <DashboardPaymentMethodInputs
              canCreatePayee
              client={trustBeneficiary}
              currentPaymentMethod={values.payment_method}
              defaultPayee={this.state.payee}
              displayedPaymentMethods={this.availablePaymentMethods()}
              formErrors={errors}
              onCheckMemoChange={(ev) => this.handleInputChange('check_memo_line', ev.target.value)}
              onPayeeChange={(payee) => this.handleInputChange('payee_id', payee ? payee.id : null)}
              payees={payees}
              payeesLoaded={payeesLoaded}
              subclass="disbursement"
              togglePayeeForm={this.togglePayeeForm}
            />
            {this.renderCardFields(formProps)}
            <div className="new-form__section" style={{ paddingBottom: 0 }}>
              <div>
                <label className="new-form__label" htmlFor="amount">
                  Amount:
                </label>
                <div className="new-form__data">
                  $ <Field id="amount" name="amount" type="text" />
                  <ErrorMessage name="amount">{this.renderError}</ErrorMessage>
                </div>
              </div>
              <div>
                <label className="new-form__label" htmlFor="documentation">
                  Documentation
                </label>
                <div className="new-form__data">
                  <DashboardBudgetItemAttachments
                    actions={BeneficiaryDashboardActions}
                    allowInsert={values.paymentMethod == 'Check'}
                    categoryNumber={-1}
                    readOnly={false}
                    store={BeneficiaryDashboardAttachmentsStore}
                  />
                </div>
              </div>
            </div>
            <div className="tl-form-actions-row" style={{ margin: '1em' }}>
              <p>
                By clicking “Submit”, you authorize True Link to complete the transaction described
                above, and you certify that you are authorized to initiate transactions from this
                account.
              </p>
            </div>
            <div className="tl-form-actions-row" style={{ margin: '1em' }}>
              <TrueLinkButton
                className={classNames('btn btn-success', {
                  disabled: !isValid || isSubmitting,
                })}
                disabled={!isValid || isSubmitting}
                type="submit"
                variant="none"
              >
                {isSubmitting ? 'Submitting...' : 'Submit Disbursement Request'}
              </TrueLinkButton>
            </div>
          </div>
        </Form>
      </div>
    );
  }

  render() {
    return (
      <Formik
        component={this.renderForm}
        initialValues={this.initialValues()}
        isInitialValid
        onSubmit={this.submitForm}
        validationSchema={this.validationSchema()}
      />
    );
  }
}

DisbursementRequestForm.propTypes = {
  trustBeneficiary: PropTypes.shape({
    card_id: PropTypes.number,
    slug: PropTypes.string.isRequired,
    trust: PropTypes.shape({
      displayed_payment_types: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    person: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    cards: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        card_last_4: PropTypes.string,
      }),
    ),
  }).isRequired,
  userRoles: userRoleShape.isRequired,
};
