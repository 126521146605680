import PropTypes from 'prop-types';
import { useEffect } from 'react';

export default function SessionTimeoutNotification({ redirectPath, timeoutInSeconds }) {
  const WARNING_BUFFER_SECONDS = 300;
  const SESSION_TIMEOUT = 'session-timeout-warning';
  const KEEPALIVE_PING_URL = '/ping.json';

  const timeoutWarningContent = () => {
    const $warning = $(
      '<div>Your account has been inactive for almost an hour.<br /><span class="link-color">Click here to stay logged in.</span></div>',
    );
    return $warning.click(() => $.get(KEEPALIVE_PING_URL));
  };

  const showTimeoutWarning = () => Truelink.flash(SESSION_TIMEOUT, timeoutWarningContent());

  const resetTimeoutWarning = () => {
    const displayWarningTimeSeconds = timeoutInSeconds - WARNING_BUFFER_SECONDS;
    const delayedWarning = window.setTimeout(showTimeoutWarning, displayWarningTimeSeconds * 1000);
    return delayedWarning;
  };

  const redirectToSignin = () => (window.location = redirectPath);

  const resetSignoutRedirect = () => {
    const delayedRedirect = window.setTimeout(redirectToSignin, timeoutInSeconds * 1000);
    return delayedRedirect;
  };

  const resetWarningAndRedirectDelay = () => {
    resetTimeoutWarning();
    return resetSignoutRedirect();
  };

  useEffect(() => {
    resetWarningAndRedirectDelay();
    $(document).ajaxComplete(() => {
      resetWarningAndRedirectDelay();
      return Truelink.flashClear(`.alert-${SESSION_TIMEOUT}`);
    });
  });

  return null;
}

SessionTimeoutNotification.defaultProps = {
  redirectPath: '/',
};

SessionTimeoutNotification.propTypes = {
  timeoutInSeconds: PropTypes.number.isRequired,
  redirectPath: PropTypes.string,
};
