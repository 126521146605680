import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';

const useStyles = makeStyles({
  wrap: {
    marginTop: '10px',
  },
});

export default function ClientDisconnectButton(props) {
  const classes = useStyles();
  return (
    <div className={classes.wrap}>
      <TrueLinkButton onClick={props.onClick} variant="warning">
        Disconnect card from client
      </TrueLinkButton>
    </div>
  );
}

ClientDisconnectButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};
