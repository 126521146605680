import React from 'react';
import I18nInfoTooltip from 'react/shared/components/tooltips/I18nInfoTooltip';
import InfoTooltip from 'react/shared/components/tooltips/InfoTooltip';

export default function FieldDefaultLayout(
  editable,
  label,
  show,
  edit,
  helperText,
  helperTextType,
  optional,
  _placeholder,
) {
  let helperTextElem;
  if (helperText) {
    if (helperTextType == 'i18n') {
      helperTextElem = <I18nInfoTooltip tooltipKey={helperText} />;
    } else {
      helperTextElem = <InfoTooltip placement="bottom" tooltipText={helperText} />;
    }
  }
  return (
    <div className="new-form-field">
      <div
        className={
          optional && editable() ? 'new-form__label new-form__label--optional' : 'new-form__label'
        }
      >
        {label()}: {helperTextElem}
      </div>
      <div className={editable() ? 'new-form__data new-form__data--editable' : 'new-form__data'}>
        {editable() ? edit() : show()}
      </div>
    </div>
  );
}
