import { ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import RadioGroup from './fields/RadioGroup';
import FieldLabel from 'react/member/components/signups/signup_form/form_partials/labels/field_label';
import fieldLabelShape from 'react/shared/shapes/field_label_shape';

function YesNoRadios({
  fieldName,
  fieldLabelProps,
  rowClass,
  colClass,
  radioGroupClass,
  onChangeCallback,
  dataAnalyticsKey,
}) {
  const yesNoOptions = [
    {
      id: `${fieldName}_no`,
      value: false,
      labelContent: 'No',
      onChangeCallback,
    },
    {
      id: `${fieldName}_yes`,
      value: true,
      labelContent: 'Yes',
      onChangeCallback,
    },
  ];

  return (
    <div className={rowClass}>
      <div className={colClass}>
        <FieldLabel {...fieldLabelProps} />
        <RadioGroup
          dataAnalyticsKey={dataAnalyticsKey}
          id={`${fieldName}-yes-no-radio`}
          name={fieldName}
          options={yesNoOptions}
          radioGroupClass={radioGroupClass}
        />
        <ErrorMessage className="error" component="label" name={fieldName} />
      </div>
    </div>
  );
}

YesNoRadios.propTypes = {
  dataAnalyticsKey: PropTypes.string,
  fieldName: PropTypes.string.isRequired,
  fieldLabelProps: fieldLabelShape,
  rowClass: PropTypes.string,
  colClass: PropTypes.string,
  radioGroupClass: PropTypes.string,
  onChangeCallback: PropTypes.func,
};

YesNoRadios.defaultProps = {
  fieldLabelProps: {},
  rowClass: 'card--form__row card--form__row--no-pad-mobile',
  colClass: 'card--form__col card--form__col--no-pad-mobile',
  radioGroupClass: 'card--form__radio',
};

export default YesNoRadios;
