import DashboardActions from 'react/member/actions/dashboard_actions';
import api from 'react/member/card/components/funding_tab/api';
import {
  isPrepared,
  isRecurring,
  isWithinNextSevenDays,
  isBeyondNextSevenDays,
} from 'react/member/utils/Transfer';

const DashboardTransferStore = flux.createStore({
  recentTransfers: [],
  scheduledTransfers: [],

  actions: [
    DashboardActions.fetchRecentTransfers,
    DashboardActions.reloadHolidayBanner,
    DashboardActions.fetchScheduledTransfers,
    DashboardActions.createTransfer,
    DashboardActions.updateTransfer,
    DashboardActions.deleteTransfer,
  ],

  fetchScheduledTransfers() {
    return api.getTransfers().then((response) => {
      this.scheduledTransfers = response.data;
      this.emit('transfers.scheduled.fetch');
    });
  },

  reloadHolidayBanner() {
    this.emit('transfers.reload.holidayBanner');
  },

  fetchRecentTransfers() {
    return api.getTransfers('recent').then((response) => {
      this.recentTransfers = response.data;
      this.emit('transfers.recent.fetch');
    });
  },

  createTransfer(bankAccountId, data, callback = null) {
    const url = RailsRoutes.dashboard_transfers_path({
      bank_account_id: bankAccountId,
      format: 'json',
    });
    return $.post(url, data).done((transfer) => {
      this.emit('transfer.create', transfer);
      this.fetchScheduledTransfers();
      if (callback) {
        callback(transfer);
      }
    });
  },

  updateTransfer(transferId, data, callback = null) {
    const url = RailsRoutes.dashboard_transfer_path(transferId, { format: 'json' });
    return $.ajax({
      url,
      method: 'PUT',
      data,
    }).done((transfer) => {
      this.emit('transfer.update', transfer);
      this.fetchScheduledTransfers();
      if (callback) {
        callback(transfer);
      }
    });
  },

  deleteTransfer(transferId, callback = null) {
    const url = RailsRoutes.dashboard_transfer_path(transferId, { format: 'json' });
    return $.ajax({
      url,
      method: 'DELETE',
    }).done(() => {
      this.emit('transfer.delete');
      this.fetchScheduledTransfers();
      if (callback) {
        callback();
      }
    });
  },

  exports: {
    futureOneTimeTransfers() {
      return this.scheduledTransfers.filter(
        (transfer) => !isRecurring(transfer) && isBeyondNextSevenDays(transfer),
      );
    },
    transfersInTheNextSevenDays() {
      return this.scheduledTransfers.filter((transfer) => {
        const upcomingOrPastOneTime = !isRecurring(transfer) && !isBeyondNextSevenDays(transfer);
        const upcomingRecurring =
          isRecurring(transfer) && (isPrepared(transfer) || isWithinNextSevenDays(transfer));
        return upcomingOrPastOneTime || upcomingRecurring;
      });
    },
    scheduledTransfers() {
      return this.scheduledTransfers;
    },
    recentTransfers() {
      return this.recentTransfers;
    },
  },
});

export default DashboardTransferStore;
