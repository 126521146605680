import Dropzone from 'dropzone';
import 'dropzone/dist/dropzone.css';
import PropTypes from 'prop-types';
import React from 'react';

const FORM_ID = 'file-upload-dropzone';

export default class MultipleForm extends React.Component {
  componentDidMount() {
    const { organizationSlug, onError, onSuccess } = this.props;
    const url = organizationSlug
      ? RailsRoutes.parse_dashboard_organization_multiple_deposits_path(organizationSlug)
      : RailsRoutes.parse_multiple_deposits_path();
    const dz = new Dropzone(`#${FORM_ID}`, {
      url,
      paramName: 'file',
      autoProcessQueue: false,
      withCredentials: true,
      addRemoveLinks: true,
      acceptedFiles: 'text/csv,.csv',
      maxFiles: 1,
      success: (file, response) => {
        // Note: this callback is firing twice. I don't know why. The form is not being submitted twice.
        onSuccess(response);
      },
      error: (file, errorMessage) => {
        onError(errorMessage);
      },
    });

    // we need this name attribute for our integration tests
    dz.hiddenFileInput.setAttribute('name', 'multi-deposit-dz');
  }

  submitForm() {
    Dropzone.forElement(`#${FORM_ID}`).processQueue();
  }

  render() {
    const { organizationSlug } = this.props;
    const href = organizationSlug
      ? RailsRoutes.new_dashboard_organization_multiple_deposit_path(organizationSlug, {
          format: 'csv',
        })
      : RailsRoutes.new_multiple_deposit_path({ format: 'csv' });

    return (
      <div>
        <div className="well">
          <p>
            To create multiple deposit records, download the deposit record template below and fill
            it in, one deposit per row. Save that file then return here to upload it.
          </p>
          <a href={href} style={{ display: 'flex', justifyContent: 'center' }}>
            <i aria-hidden className="btn-download" /> Download deposit record template
          </a>
        </div>
        <form
          action="/file-upload"
          className="dropzone"
          id={FORM_ID}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <div className="dz-message" data-dz-message>
            <p>Drag your completed deposit record template files here</p>
            <p>or</p>
            <div className="btn btn-mini normal">Select file</div>
            <p
              style={{
                color: 'gray',
                borderTop: '1px solid #ddd',
                marginTop: '20px',
                paddingTop: '20px',
              }}
            >
              Supported file types: csv
            </p>
          </div>
        </form>
      </div>
    );
  }
}

MultipleForm.propTypes = {
  organizationSlug: PropTypes.string,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};
