import classNames from 'classnames';
import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import ConnectionStore from 'react/member/stores/ConnectionStore';
import Actions from 'react/shared/actions/actions';
import FormSelectField from 'react/shared/components/forms/form_select_field';
import FormSelectSearchableField from 'react/shared/components/forms/form_select_searchable_field';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import bindAll from 'react/shared/utils/bind_all';

export default class DashboardTrustBeneficiaryProfileConnectionsAttachForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      connectionRelationships: {},
      connections: [],
      isLoading: true,
    };

    bindAll(this);
  }

  componentDidMount() {
    ConnectionStore.on('connections.fetchSearch', this.connectionsFetchedSearch);
    ConnectionStore.on(
      'connections.fetchRelationshipTypes',
      this.connectionFetchedRelationshipTypes,
    );

    const {
      trustBeneficiary: { slug },
    } = this.props;
    Actions.fetchConnections(slug, true);
    Actions.fetchRelationshipTypes(slug);
  }

  componentWillUnmount() {
    ConnectionStore.off('connections.fetchSearch', this.connectionsFetchedSearch);
    ConnectionStore.off(
      'connections.fetchRelationshipTypes',
      this.connectionFetchedRelationshipTypes,
    );
  }

  connectionsFetchedSearch(connections) {
    this.setState({ connections });
  }

  connectionFetchedRelationshipTypes(relationships) {
    this.setState({ connectionRelationships: relationships });
  }

  connectionOptions() {
    const none = { value: '', label: 'None' };
    const connectionOptions = this.state.connections.map((connection) => {
      // This logic generates a label handling cases where:
      // the connection only has a first name, only a last name, or only a company name,
      // or some combination of the 3
      let label = `${connection.person.firstName} ${connection.person.lastName}`.trim();
      if (label.length > 0 && connection.companyName) {
        label += ` - ${connection.companyName}`;
      } else if (connection.companyName) {
        label = connection.companyName;
      }

      return {
        value: `${connection.id}`,
        label,
      };
    });
    connectionOptions.unshift(none);

    return connectionOptions;
  }

  relationshipOptions(type) {
    const { connectionRelationships } = this.state;
    const none = { value: '', label: 'None' };
    let relationshipOptions = [];
    if (connectionRelationships[type]) {
      relationshipOptions = connectionRelationships[type].map((relationship) => ({
        value: relationship,
        label: relationship,
      }));
    }
    relationshipOptions.unshift(none);

    return relationshipOptions;
  }

  onSubmit(values, { setSubmitting }) {
    const {
      trustBeneficiary: { slug },
    } = this.props;
    const onRequestCompleted = () => {
      setSubmitting(false);
    };
    Actions.connectConnection(slug, values, onRequestCompleted);
  }

  renderForm({ isSubmitting }) {
    return (
      <Form>
        <div className="tl-fields-row" style={{ marginBottom: '1em' }}>
          <div className="tl-field w-col-11">
            <div className="tl-field-data">
              <Field
                component={FormSelectSearchableField}
                name="connection.id"
                options={this.connectionOptions()}
                reactSelectProps={{ id: 'connections-search' }}
              />
            </div>
          </div>
        </div>
        <div className="tl-fields-row">
          <div className="tl-field">
            <label className="tl-field-label" htmlFor="professional">
              Professional Relationship
            </label>
            <div className="tl-field-data">
              <Field
                component={FormSelectField}
                domId={'professional-relationship-select'}
                name="professional"
                options={this.relationshipOptions('professional')}
                reactSelectProps={{ id: 'professional-relationship-select' }}
              />
            </div>
          </div>
          <div className="tl-field">
            <label className="tl-field-label" htmlFor="social">
              Social Relationship
            </label>
            <div className="tl-field-data">
              <Field
                component={FormSelectField}
                domId={'social-relationship-select'}
                name="social"
                options={this.relationshipOptions('social')}
                reactSelectProps={{ id: 'social-relationship-select' }}
              />
            </div>
          </div>
        </div>
        <div className="tl-form-actions-row">
          <TrueLinkButton
            className={classNames('btn btn-success', {
              disabled: isSubmitting,
            })}
            disabled={isSubmitting}
            type="submit"
            variant="none"
          >
            Connect
          </TrueLinkButton>
        </div>
      </Form>
    );
  }

  initialValues() {
    return {
      connection: {
        id: '',
      },
      professional: '',
      social: '',
    };
  }

  render() {
    return (
      <div className="form-content-edit tl-form-edit align-left">
        <Formik
          component={this.renderForm}
          initialValues={this.initialValues()}
          onSubmit={this.onSubmit}
        />
      </div>
    );
  }
}

DashboardTrustBeneficiaryProfileConnectionsAttachForm.propTypes = {
  trustBeneficiary: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
};
