import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import DatePicker from 'react/shared/components/DatePicker';
import SubSectionHeader from 'react/shared/components/SubSectionHeader';

export default function BeneficiaryCustomDateStatementSection(props) {
  const INDIVIDUAL_TRUST_STATEMENT_START_DATE = new Date('2019-4-1');

  const onSubmitCustomDateReportRequest = function (startDate, endDate) {
    if (moment(startDate).isAfter(moment(endDate))) {
      return Truelink.flash(
        'error',
        'Invalid dates: start date should be before end date',
        true,
        true,
      );
    }

    const params = {
      report_type: 'Dashboard::GenerateSubAccountStatements',
      trust_beneficiary_id: props.client.id,
      start_date: moment(startDate).format('YYYY-MM-DD'),
      end_date: moment(endDate).format('YYYY-MM-DD'),
    };
    props.onSubmitReportRequest(params);
  };

  const customDateReports = function () {
    const { client } = props;
    const minDate = INDIVIDUAL_TRUST_STATEMENT_START_DATE;
    const maxTrustStatementDate = client.trust.latest_statement_end_date;
    const maxDate = maxTrustStatementDate ? new Date(maxTrustStatementDate) : new Date();

    const startDate = new Date(maxDate.getTime());
    startDate.setMonth(maxDate.getMonth() - 1);
    const infoText =
      'Choose a date range to view account activity for that timeframe. Only dates that have been released are available.';
    return (
      <div>
        <DatePicker
          action="Open"
          endDate={maxDate}
          infoText={infoText}
          maxDate={maxDate}
          minDate={minDate}
          onSubmit={onSubmitCustomDateReportRequest}
          startDate={startDate}
        />
      </div>
    );
  };

  return (
    <div>
      <SubSectionHeader>Custom Time Period Reports</SubSectionHeader>
      {customDateReports()}
    </div>
  );
}

BeneficiaryCustomDateStatementSection.propTypes = {
  client: PropTypes.shape({
    id: PropTypes.number.isRequired,
    trust: PropTypes.shape({
      latest_statement_end_date: PropTypes.string,
    }).isRequired,
  }),
  onSubmitReportRequest: PropTypes.func.isRequired,
};
