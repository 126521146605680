import PropTypes from 'prop-types';
import React from 'react';
import ReactInputMask from 'react-input-mask';

export default function PhoneDob({ labelPrefix, inputNamePrefix = 'administrator' }) {
  return (
    <div className="card--form__row">
      <div className="card--form__col" style={{ width: '49%' }}>
        <label htmlFor={`${inputNamePrefix}_mobile`}>
          {labelPrefix} Phone number
          <ReactInputMask
            id={`${inputNamePrefix}_mobile`}
            mask="999-999-9999"
            name={`${inputNamePrefix}[mobile]`}
            type="text"
          />
        </label>
      </div>
      <div className="card--form__col" style={{ width: '50%' }}>
        <label htmlFor={`${inputNamePrefix}_dob`}>
          {labelPrefix} Date of birth
          <span className="card--form__input-addition">mm-dd-yyyy</span>
          <ReactInputMask
            className="required"
            id={`${inputNamePrefix}_dob`}
            mask="99-99-9999"
            name={`${inputNamePrefix}[dob]`}
            type="text"
          />
        </label>
      </div>
    </div>
  );
}

PhoneDob.propTypes = {
  labelPrefix: PropTypes.string.isRequired,
  inputNamePrefix: PropTypes.string,
};
