import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import TrueLinkIcon from 'react/shared/components/true_link/main/TrueLinkIcon';
import { grey3 } from 'react/shared/theme/palette';

const useStyles = makeStyles({
  icon: {
    color: grey3,
    fontSize: '1.5em',
    marginRight: '8px',
  },
});

export default function SocialIcon({ title, link, icon }) {
  const classes = useStyles();
  const handleClick = (event) => {
    event.preventDefault();
    $('#exit-tl-modal').modal('show');
    $('a#ok').attr('href', link);
  };

  return (
    <a aria-label={title} href={link} onClick={handleClick} title={title}>
      <TrueLinkIcon className={classes.icon} icon={icon} />
    </a>
  );
}

SocialIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};
