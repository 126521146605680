import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import _contains from 'underscore/modules/contains';
import BankAccountList from './BankAccountList';
import BankAccountMessage from './BankAccountMessage';
import ConnectFeeNewBankAccountModal from './ConnectFeeNewBankAccountModal';
import CopyVerifiedBankAccountModal from './CopyVerifiedBankAccountModal';
import FirstTransferPromptModal from './FirstTransferPromptModal';
import LinkBankAccountModal from './LinkBankAccountModal';
import DashboardActions from 'react/member/actions/dashboard_actions';
import { dashboardAccountShape } from 'react/member/card/shapes/AccountShape';
import DashboardBankAccountStore from 'react/member/stores/DashboardBankAccountStore';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import theme from 'react/shared/theme/Theme';

const useStyles = makeStyles(() => ({
  subSectionHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: '1px solid #aaa',
    lineHeight: '42px',
    marginTop: '15px',
    fontSize: '26px',
    fontWeight: 'normal',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      lineHeight: '25px',
      gap: '10px',
      paddingBottom: '5px',
    },
  },

  subSectionHeaderRight: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      lineHeight: '25px',
      gap: '10px',
      '&  .btn-link': {
        padding: '0px',
      },
    },
  },
}));

export default function BankAccounts(props) {
  const classes = useStyles();

  const [connectedBankAccounts, setConnectedBankAccounts] = useState([]);
  const [selectedBankAccount, setSelectedBankAccount] = useState();
  const [showConnectFeeModal, setShowConnectFeeModal] = useState(false);
  const [showLinkBankAccountModal, setShowLinkBankAccountModal] = useState(false);
  const [showCopyVerifiedBankAccountModal, setShowCopyVerifiedBankAccountModal] = useState(false);
  const [showFirstTransferPromptModal, setShowFirstTransferPromptModal] = useState(false);

  const onChangeBankAccounts = (bankAccounts) => {
    setConnectedBankAccounts(bankAccounts);
  };

  const onChangeBankAccount = (result) => {
    setSelectedBankAccount(result.new_bank_account);
    setShowConnectFeeModal(result.already_has_verified_bank_account);
  };

  const closeConnectFeeModal = () => {
    setShowConnectFeeModal(false);
  };

  const closeLinkBankAccountModal = () => {
    setShowLinkBankAccountModal(false);
  };

  const closeCopyVerifiedBankAccountModal = () => {
    setShowCopyVerifiedBankAccountModal(false);
  };

  const closeFirstTransferPromptModal = () => {
    setShowFirstTransferPromptModal(false);
  };

  const flashCreateSuccess = () => {
    Truelink.flash('success', 'Your bank account has been saved.');
  };

  const openCopyVerifiedBankAccountModal = (ev) => {
    ev.preventDefault();
    setShowCopyVerifiedBankAccountModal(true);
  };

  const openLinkBankAccountModal = (ev) => {
    ev.preventDefault();
    setShowLinkBankAccountModal(true);
  };

  const onLinkPlaidBankAccount = () => {
    setShowFirstTransferPromptModal(connectedBankAccounts.length == 0);
  };

  useEffect(() => {
    DashboardBankAccountStore.on('bankAccounts.fetch', onChangeBankAccounts);
    DashboardBankAccountStore.on('bankAccount.create', onChangeBankAccount);
    DashboardBankAccountStore.on('bankAccount.create', flashCreateSuccess);
    DashboardBankAccountStore.on('bankAccount.plaidcreate', onLinkPlaidBankAccount);
    DashboardBankAccountStore.on('bankAccount.verify', onChangeBankAccount);
    DashboardBankAccountStore.on('bankAccount.copy', onChangeBankAccount);

    DashboardActions.fetchConnectedBankAccounts();

    return () => {
      DashboardBankAccountStore.off('bankAccounts.fetch', onChangeBankAccounts);
      DashboardBankAccountStore.off('bankAccount.create', onChangeBankAccount);
      DashboardBankAccountStore.off('bankAccount.create', flashCreateSuccess);
      DashboardBankAccountStore.off('bankAccount.plaidcreate', onLinkPlaidBankAccount);
      DashboardBankAccountStore.off('bankAccount.verify', onChangeBankAccount);
      DashboardBankAccountStore.off('bankAccount.copy', onChangeBankAccount);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderBankAccountButtonLinks = () => {
    if (connectedBankAccounts.length == 0) return;
    const showCopyButton =
      props.copyableBankAccounts.length > 0 &&
      !_contains($tlf.permissions.userRoles, 'family_funding');
    const copyBankAccountButton = showCopyButton && (
      <TrueLinkButton
        className="btn btn-link copy_bank_account margin-top-10"
        id="copy-verified-bank-account-link-cta"
        onClick={openCopyVerifiedBankAccountModal}
        variant="none"
      >
        Copy verified bank account
      </TrueLinkButton>
    );
    return (
      <div className={classes.subSectionHeaderRight}>
        {copyBankAccountButton}
        <TrueLinkButton
          className="btn btn-link connect_bank_account margin-top-10"
          id="connect-new-bank-account-link-cta"
          onClick={openLinkBankAccountModal}
          variant="none"
        >
          Connect new bank account
        </TrueLinkButton>
      </div>
    );
  };

  const renderBankAccountButtons = () => {
    if (connectedBankAccounts.length !== 0) return;
    const showCopyButton =
      props.copyableBankAccounts.length > 0 &&
      !_contains($tlf.permissions.userRoles, 'family_funding');
    const copyBankAccountButton = showCopyButton && (
      <TrueLinkButton
        className="btn normal copy_bank_account"
        id="copy-verified-bank-account-cta"
        onClick={openCopyVerifiedBankAccountModal}
        style={{ marginRight: 10, marginBottom: 10 }}
        variant="none"
      >
        Copy verified bank account
      </TrueLinkButton>
    );
    return (
      <div>
        <hr style={{ margin: '10px 0px' }} />
        {copyBankAccountButton}
        <TrueLinkButton
          className="btn normal connect_bank_account"
          id="connect_bank_account_btn"
          onClick={openLinkBankAccountModal}
          style={{ marginBottom: 10 }}
          variant="none"
        >
          Connect new bank account
        </TrueLinkButton>
      </div>
    );
  };

  const renderBankAccountModals = () => (
    <>
      <CopyVerifiedBankAccountModal
        bankAccounts={props.copyableBankAccounts}
        modalOpen={showCopyVerifiedBankAccountModal}
        onClose={closeCopyVerifiedBankAccountModal}
      />
      <LinkBankAccountModal
        account={props.account}
        mockPublicToken={props.mockPublicToken}
        modalOpen={showLinkBankAccountModal}
        onClose={closeLinkBankAccountModal}
      />
      <ConnectFeeNewBankAccountModal
        account={props.account}
        bankAccount={selectedBankAccount}
        modalOpen={showConnectFeeModal}
        onClose={closeConnectFeeModal}
      />
      <FirstTransferPromptModal
        modalOpen={showFirstTransferPromptModal}
        onClose={closeFirstTransferPromptModal}
      />
    </>
  );

  const { account, card, isViewOnly } = props;

  const readOnly = classNames({
    'read-only-disabled': isViewOnly,
  });

  return (
    <div className={readOnly}>
      <div className={classes.subSectionHeader}>
        Bank accounts
        {!isViewOnly && renderBankAccountButtonLinks()}
      </div>
      <div>
        <BankAccountMessage account={account} card={card} />
        {!isViewOnly && renderBankAccountButtons()}
        {connectedBankAccounts.length > 0 && (
          <BankAccountList
            account={account}
            bankAccounts={connectedBankAccounts}
            cardStatus={card?.status}
            isViewOnly={isViewOnly}
          />
        )}
        {!isViewOnly && renderBankAccountModals()}
      </div>
    </div>
  );
}

BankAccounts.propTypes = {
  account: dashboardAccountShape.isRequired,
  card: PropTypes.object,
  copyableBankAccounts: PropTypes.array,
  isViewOnly: PropTypes.bool.isRequired,
  mockPublicToken: PropTypes.string,
};
