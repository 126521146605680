import PropTypes from 'prop-types';
import React from 'react';
import CardDetails from 'react/member/card/components/account_tab/card_status/CardDetails';
import { CARD_STATUSES } from 'react/member/utils/CardStatus';

export default function Frozen({
  cardBalance,
  cardBreached,
  cardExpiryDate,
  cardLast4,
  isFullyFunded,
  status,
}) {
  const fullyFundedNote =
    "We've frozen this account due to suspicious activity. Please review recent transactions. If all transactions are authorized you can unfreeze the Visa card. If any transactions are unfamiliar or fraudulent, order a replacement card and reach out to customer support immediately to open a dispute claim.";

  const notFullyFundedNote =
    "We've frozen this account due to suspicious activity. Please review recent transactions. If all are authorized, contact customer support to assist with unfreezing the account. If any transactions are unfamiliar or fraudulent, contact us immediately to order a replacement Visa card and open a dispute claim.";
  const note = isFullyFunded ? fullyFundedNote : notFullyFundedNote;

  return (
    <CardDetails
      cardBalance={cardBalance}
      cardBreached={cardBreached}
      cardLast4={cardLast4}
      expiryDate={cardExpiryDate}
      fraudType={'FROZEN'}
      note={note}
      status={status}
    />
  );
}

Frozen.propTypes = {
  cardBalance: PropTypes.number,
  cardBreached: PropTypes.bool,
  cardExpiryDate: PropTypes.string,
  cardLast4: PropTypes.string.isRequired,
  isFullyFunded: PropTypes.bool.isRequired,
  status: PropTypes.oneOf([CARD_STATUSES.FRAUD_BLOCK_CARD_STATUS]).isRequired,
};
