import PropTypes from 'prop-types';
import React from 'react';
import ClientStatementsByMonthForm from './ClientStatementsByMonthForm';
import CompletedTransfersReportForm from './CompletedTransfersReportForm';
import TransactionReportForAllCards from './TransactionReportForAllCards';
import BackgroundFileGenerationForm from 'react/shared/card/components/BackgroundFileGenerationForm';

export default class OrganizationReportsTabContainer extends React.Component {
  static get propTypes() {
    return {
      organizationId: PropTypes.number,
      clientStatementsFormProps: PropTypes.shape({
        statementMonthsToShow: PropTypes.array.isRequired,
        largeNumberOfAccounts: PropTypes.bool.isRequired,
      }).isRequired,
      fundedAccountTransfer: PropTypes.bool.isRequired,
      largeNumberOfAccounts: PropTypes.bool.isRequired,
      hasOnlyAbleActAccounts: PropTypes.bool.isRequired,
    };
  }

  downloadCompletedTransfersReport(startDate, endDate) {
    window.open(
      RailsRoutes.completed_transfers_report_path({
        start_date: startDate,
        end_date: endDate,
        format: 'csv',
      }),
    );
  }

  render() {
    const {
      organizationId,
      clientStatementsFormProps,
      fundedAccountTransfer,
      largeNumberOfAccounts,
      hasOnlyAbleActAccounts,
    } = this.props;

    return (
      <div>
        <div className="sub_section_header first">Card status report</div>
        <p className="report-section-info">
          This report provides an overview of all cards, including card status, balance,
          {!hasOnlyAbleActAccounts ? ' direct deposit and funding information, ' : ' '}
          and details of the latest transaction.
        </p>
        <BackgroundFileGenerationForm
          buttonText="Download as Spreadsheet"
          downloadDisabled={false}
          downloadRoute={RailsRoutes.accounts_list_path}
          fileType="csv"
          largeNumberOfAccounts={largeNumberOfAccounts}
          modalText="Please wait while your Card Status Report is generated..."
        />

        {fundedAccountTransfer && (
          <div>
            <div className="sub_section_header" style={{ marginTop: 40 }}>
              Completed transfers report
            </div>
            <p className="report-section-info">
              This report lists all transfers which have completed in the selected date range across
              all cardholders. It includes details about the amount of the transfer, and whether it
              is a recurring or one time transfer.
            </p>
            <CompletedTransfersReportForm
              downloadCompletedTransfersReport={this.downloadCompletedTransfersReport}
            />
          </div>
        )}

        <div className="sub_section_header" style={{ marginTop: 40 }}>
          All client statements by month
        </div>
        <p className="report-section-info">
          This zip file will contain PDFs of all of the individual monthly statements for all cards
          on your account, for the month selected. Monthly statements show transactions and
          transfers, as well as fees incurred.
        </p>
        <ClientStatementsByMonthForm
          largeNumberOfAccounts={clientStatementsFormProps.largeNumberOfAccounts}
          statementMonthsToShow={clientStatementsFormProps.statementMonthsToShow}
        />

        {organizationId && (
          <div>
            <div className="sub_section_header" style={{ marginTop: 40 }}>
              Transaction Report for All Cards
            </div>
            <p className="report-section-info">
              This report lists all transactions which have completed in the selected date range
              across all cardholders. This includes transactions and transfers, as well as fees
              incurred.
            </p>
            <TransactionReportForAllCards organizationId={organizationId} />
          </div>
        )}
      </div>
    );
  }
}
