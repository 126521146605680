import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import _groupBy from 'underscore/modules/groupBy';
import _map from 'underscore/modules/map';
import SecurityRow from './SecurityRow';
import { isCash } from './SecurityUtils';
import { getPositionsByTrustBeneficiary } from 'react/member/components/dashboard/clients/dashboard_client/overview/api';
import { asMoney } from 'react/shared/utils/Money';

const useStyles = makeStyles(() => ({
  header: {
    width: '100%',
    display: 'flex',
  },
  spacer: {
    flexGrow: 1,
  },
}));

export default function Securities({ beneficiarySlug }) {
  const classes = useStyles();
  const [positions, setPositions] = useState(null);

  useEffect(() => {
    async function getPositions() {
      const positionsRes = await getPositionsByTrustBeneficiary(beneficiarySlug);
      const filteredPositions = positionsRes?.filter(
        (position) => !position?.position_type?.includes('Cash'),
      );
      setPositions(filteredPositions);
    }
    getPositions();
  }, [beneficiarySlug]);

  const getPositionsByWealthAccount = () =>
    _map(_groupBy(positions, 'wealth_account_name'), (value, key) => ({
      name: key,
      positions: value,
    }));

  const total = positions
    ?.map((position) => Number(position?.total_value))
    ?.reduce((prev, curr) => prev + curr, 0);

  return (
    <div>
      <div className={classes.header}>
        <Typography component="h1" variant="h3">
          Securities
        </Typography>
        <span className={classes.spacer} />
        <Typography component="h1" variant="h3">
          {total ? asMoney(total) : '$0.00'}
        </Typography>
      </div>
      {getPositionsByWealthAccount().map((value, idx) => (
        <div key={idx}>
          <table className="table table-condensed" style={{ fontSize: '16px' }}>
            <thead>
              {idx < 1 ? (
                <tr>
                  <th className="table-cell-left" style={{ paddingLeft: 0 }}>
                    Holdings
                  </th>
                  <th>Ticker</th>
                  <th>Units</th>
                  <th className="table-cell-right">Unit Value</th>
                  <th className="table-cell-right">Market Value</th>
                  <th className="table-cell-right">Unrealized G/L</th>
                  <th className="table-cell-right">Cost Basis</th>
                </tr>
              ) : (
                <tr>
                  <th>
                    <p
                      style={{
                        borderBottom: 'black',
                        borderBottomStyle: 'solid',
                        borderBottomWidth: 'thin',
                        width: 300,
                        marginBottom: 5,
                        paddingLeft: 0,
                      }}
                    >
                      {value.name}
                    </p>
                  </th>
                  <th />
                  <th />
                  <th className="table-cell-right" />
                  <th className="table-cell-right" />
                  <th className="table-cell-right" />
                  <th className="table-cell-right" />
                </tr>
              )}
            </thead>
            <tbody>
              {value.positions
                .filter((p) => !isCash(p))
                .map((position) => (
                  <SecurityRow key={position.id} position={position} />
                ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
}

Securities.propTypes = {
  beneficiarySlug: PropTypes.string.isRequired,
};
