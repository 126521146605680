import api from './api';

const POLLING_TIMEOUT = 20 * 60 * 1000; // twenty min
const POLLING_INTERVAL = 1000; // one second

export class Poller {
  constructor(doneHandler) {
    this.doneHandler = doneHandler;
  }

  start(requestId) {
    this.requestId = requestId;

    const updatePolling = () => this.updateStatus();
    this.intervalId = window.setInterval(updatePolling, POLLING_INTERVAL);

    this.timeoutId = window.setTimeout(() => {
      this.stop();
      this.doneHandler({ isError: true, errorMessage: 'Report generation took too long' });
    }, POLLING_TIMEOUT);
  }

  stop() {
    window.clearInterval(this.intervalId);
    window.clearTimeout(this.timeoutId);
  }

  updateStatus() {
    api
      .checkReportStatus(this.requestId)
      .then((response) => {
        const responseStatus = response.data.data.attributes.status;
        switch (responseStatus) {
          case 'completed':
            api.downloadReport(this.requestId);
            this.stop();
            this.doneHandler({ isSuccessful: true });
            break;
          case 'failed':
            this.doneHandler({ isError: true, errorMessage: 'Report generation failed' });
            this.stop();
            break;
          case 'canceled':
            this.doneHandler({
              isError: true,
              errorMessage:
                'Report generation canceled, result data set too large. Please try a shorter time range or contact customer support.',
            });
            this.stop();
            break;
        }
      })
      .catch((_error) => {
        this.stop();
        this.doneHandler({ isError: true, errorMessage: 'Unable to get report status' });
      });
  }
}
