import PropTypes from 'prop-types';
import React from 'react';
import CategoryManagement from './budget_item_util_components/CategoryManagement';
import CategoryDropdown from 'react/member/components/dashboard/CategoryDropdown';
import DashboardBudgetItemAttachments from 'react/member/components/dashboard/disbursements/DashboardBudgetItemAttachments';

// Nomenclature note: previously, disbursements always had one category, and that category was specified
// through a Chosen dropdown. Disbursement categories are now being stored as full Disbursement records
// behind the scenes, complete with their own memos, amounts, and category names. The Chosen dropdown is
// also still used to collect the name of the category, which gets saved to the Disbursement records.
export default function DashboardBudgetItemCategories({
  formData,
  organizationSlug,
  clearOverdrawError,
  currentPaymentMethod,
  categories,
  addCategoryFunc,
  destroyCategoryFunc,
  multiCategory,
}) {
  function noopOnEnter(e) {
    if (e.which === 13) {
      // Enter key = keycode 13
      e.preventDefault();
    }
  }

  const amountLabel = currentPaymentMethod === 'Direct Debit' ? 'Expected Amount' : 'Amount';
  return (
    <div>
      {categories.map((cat, idx) => {
        const amountId = `amountId${idx}`;
        const memoId = `memoId${idx}`;
        const attachmentsId = `attachmentsId${idx}`;

        return (
          <div className="new-form__section relative" key={idx}>
            <div style={{ position: 'absolute', right: 10 }}>
              {multiCategory && (
                <CategoryManagement
                  addCategoryFunc={addCategoryFunc}
                  destroyCategoryFunc={destroyCategoryFunc}
                  idx={idx}
                  length={categories.length}
                />
              )}
            </div>
            <div className="new-form__label">
              <label htmlFor={amountId}>{amountLabel}</label>
            </div>
            <div className="new-form__data">
              <input
                data-index={idx}
                id={amountId}
                inputMode="numeric"
                min="0.00"
                name="amounts"
                onChange={clearOverdrawError}
                onKeyPress={noopOnEnter}
                pattern="\d+(\.\d{1,2})?"
                placeholder="0.00"
                step={0.01}
                title="Please enter a number."
                type="text"
                value={cat.amounts || ''}
              />
            </div>
            <div className="new-form__label">
              <label htmlFor={memoId}>Internal Notes</label>
            </div>
            <div className="new-form__data">
              <textarea
                data-index={idx}
                defaultValue={cat.memos}
                id={memoId}
                name="memos"
                onKeyPress={noopOnEnter}
                placeholder="Notes"
                style={{ width: '100%' }}
              />
            </div>
            <div className="new-form__label">
              <label htmlFor="budget-item-category-select-container">Category</label>
            </div>
            <div className="new-form__data" id="budget-item-category-select-container">
              <CategoryDropdown
                categoryNumber={idx}
                hideLabel
                initialValue={formData.category || cat.disbursement_categories || ''}
                organizationSlug={organizationSlug}
              />
            </div>
            <div className="new-form__label">
              <label htmlFor="budget-item-attachments-container">Documentation</label>
            </div>
            <div className="new-form__data budget-item-attachments-container" id={attachmentsId}>
              <DashboardBudgetItemAttachments
                allowInsert={currentPaymentMethod == 'Check'}
                attachments={cat.attachments}
                categoryNumber={idx}
                readOnly={false}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}

DashboardBudgetItemCategories.propTypes = {
  formData: PropTypes.object.isRequired,
  organizationSlug: PropTypes.string,
  clearOverdrawError: PropTypes.func,
  currentPaymentMethod: PropTypes.string.isRequired,
  categories: PropTypes.array,
  addCategoryFunc: PropTypes.func,
  destroyCategoryFunc: PropTypes.func,
  multiCategory: PropTypes.bool,
};
