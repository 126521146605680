import DashboardActions from 'react/member/actions/dashboard_actions';

const DashboardUserStore = flux.createStore({
  users: [],
  actions: [DashboardActions.fetchAuthorizedUsers],

  authorizedUsersPath(isRecurring) {
    if (isRecurring === true) {
      return RailsRoutes.dashboard_recurring_disbursement_authorized_users_path;
    }
    return RailsRoutes.dashboard_disbursement_authorized_users_path;
  },

  fetchAuthorizedUsers(disbursement_id, isRecurring) {
    const url = this.authorizedUsersPath(isRecurring);
    $.get(url(disbursement_id, { format: 'json' }), (authorizedUsers) => {
      this.users = authorizedUsers;
      this.emit('authorizedUsers.fetch', authorizedUsers);
    });
  },
});

export default DashboardUserStore;
