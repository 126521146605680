import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import _filter from 'underscore/modules/filter';
import _find from 'underscore/modules/find';
import DisbursementAttachment from './DisbursementAttachment';
import DashboardActions from 'react/member/actions/dashboard_actions';
import AttachmentDropZone from 'react/member/components/attachments/drop_zone_uploader/AttachmentDropZone';
import DashboardBudgetItemAttachmentStore from 'react/member/stores/DashboardBudgetItemAttachmentStore';
import LoadingIndicator from 'react/shared/components/LoadingIndicator';
import InfoTooltip from 'react/shared/components/tooltips/InfoTooltip';
import bindAll from 'react/shared/utils/bind_all';

// This component serves double-duty, rendering both on the form where disbursements are created, as well as
// the lists of pending, approved, and cancelled disbursements. Since we are now creating multiple categories
// of disbursements, this component is now potentially being rendered more than once on the new disbursement
// form.
//
// Attachments can be added to disbursements before or after the disbursement is actually created. This makes
// working with the component all the more challenging to work with: attachments can be easily keyed
// when the disbursement exists, but not so much on the form where the disbursement is created.
//
// The workaround for the challenge is to manually number the instances of this component when it is used
// on the form where the disbursement is originally created. This is done through the `categoryNumber`
// property (since Disbursement records now can be a single disbursement, or can represent a category that is
// a part of a group of Disbursement records.)
export default class DashboardBudgetItemAttachments extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      attachments: [],
      showSpinner: false,
      checkedId: null,
      includeAsInsert: false,
      step: 'home',
    };

    this.cannotAddDocsToPersistedDisbursement =
      props.disbursement && props.disbursement.can_add_documentation === false;

    bindAll(this);
  }

  componentDidMount() {
    const { store } = this.props;

    store.on('attachments.fetch', this.onChangeAttachments);
    store.on('attachments.fetchForDCA', this.onChangeAttachmentsForDCA);
    store.on('attachments.destroy', this.onDestroyAttachment);
    store.on('attachment.upload', this.onUpload);
    if (this.props.disbursementCategoryAmount) {
      DashboardActions.fetchAttachmentsByDisbursementCategoryAmount(
        this.props.disbursementCategoryAmount,
      );
    } else if (this.props.disbursement) {
      DashboardActions.fetchBudgetItemAttachmentsByBudgetItem(this.props.disbursement);
    } else if (this.props.attachments) {
      this.setState({ attachments: this.props.attachments });
    }
  }

  componentDidUpdate(prevProps) {
    $(ReactDOM.findDOMNode(this))
      .find(`input[value="${this.state.checkedId}"]`)
      .prop('checked', true);

    if (prevProps.attachments !== this.props.attachments) {
      this.setAttachments(this.props.attachments);
    }
  }

  componentWillUnmount() {
    const { store } = this.props;

    store.off('attachments.fetch', this.onChangeAttachments);
    store.off('attachments.fetchForDCA', this.onChangeAttachmentsForDCA);
    store.off('attachments.destroy', this.onDestroyAttachment);
    store.off('attachment.upload', this.onUpload);
  }

  allowedExtensionsErrorMessage(fileName) {
    return `You tried to upload "${fileName}". We currently do not support this file type. Please use these file types: ${$tlf.domains.allowedExtensions.join(
      ', ',
    )}`;
  }

  allowedExtensionsMessage() {
    return `Supported file types: ${$tlf.domains.allowedExtensions.join(', ')}`;
  }

  // These attachments get fetched whenever there is an existing DisbursementCategoryAmount
  // and the list of disbursements changes (e.g., a new attachment is uploaded).
  onChangeAttachments(disbursementId, attachments) {
    if (this.props.disbursement) {
      if (this.props.disbursement.id === disbursementId) {
        return this.setAttachments(attachments);
      }
    } else {
      // the 'getAttachments' method will incorrectly return attachments for other disbursements
      // so we need to filter them https://truelink.atlassian.net/browse/TRUST-678
      const newAttachments = this.props.store
        .getAttachments()
        .filter(
          (attachment) =>
            attachment.attachable_type !== 'BudgetItem' ||
            attachment.attachableType !== 'BudgetItem',
        );
      return this.setAttachments(newAttachments);
    }
  }

  // These attachments get fetched whenever there is an existing Disbursement and the list of disbursements
  // changes (e.g., a new attachment is uploaded).
  onChangeAttachmentsForDCA(disbursementCategoryAmountId, attachments) {
    if (
      this.props.disbursementCategoryAmount &&
      this.props.disbursementCategoryAmount.dca_id === disbursementCategoryAmountId
    ) {
      return this.setAttachments(attachments);
    }
  }

  setAttachments(attachments) {
    const checkedId = (() => {
      if (attachments.length) {
        const record = _find(
          attachments,
          (attachment) => attachment.smartpayables_insert || attachment.smartpayablesInsert,
        );
        if (record) {
          return record.id.toString();
        }
        return null;
      }
      return null;
    })();

    this.setState({
      attachments,
      checkedId,
    });
  }

  onDestroyAttachment(attachmentId) {
    const attachments = _filter(this.state.attachments, (attach) => attach.id !== attachmentId);
    this.setState({ attachments });
    if (this.state.checkedId == attachmentId) {
      this.setState({ checkedId: null });
    }
  }

  upload(file) {
    const { disbursement, disbursementCategoryAmount, categoryNumber, store } = this.props;

    const fileInputName = 'attachment[file]';
    let doneCallback;
    if (disbursementCategoryAmount) {
      doneCallback = (_attachment) => {
        DashboardActions.fetchAttachmentsByDisbursementCategoryAmount(disbursementCategoryAmount);
      };
      return store.uploadAttachmentToBudgetItem(
        fileInputName,
        file,
        this.state.includeAsInsert,
        'DisbursementCategoryAmount',
        disbursementCategoryAmount.dca_id,
        doneCallback,
      );
    } else if (disbursement) {
      doneCallback = (_attachment) => {
        DashboardActions.fetchBudgetItemAttachmentsByBudgetItem(disbursement);
      };
      return store.uploadAttachmentToBudgetItem(
        fileInputName,
        file,
        this.state.includeAsInsert,
        disbursement.is_recurring ? 'RecurringBudgetItem' : 'BudgetItem',
        disbursement.id,
        doneCallback,
      );
    }

    store.uploadAttachment(fileInputName, file, categoryNumber, this.state.includeAsInsert);
    this.setState({ includeAsInsert: false });
  }

  // This handler is solely used when the attachment is being uploaded and the disbursement has not yet been
  // created. (i.e., the new disbursement form.)
  onUpload(attachment, inputNumber) {
    // On an error, attachment will come back as null
    if (attachment && this.props.categoryNumber === inputNumber) {
      this.setState((prevState) => {
        // Use prevState to calculate the next state
        const { attachments } = prevState;
        const updatedAttachments = attachments.concat([attachment]);

        if (attachment.smartpayables_insert || attachment.smartpayablesInsert) {
          return {
            attachments: updatedAttachments,
            checkedId: attachment.id,
          };
        }
        return {
          attachments: updatedAttachments,
        };
      });
    }
  }

  readOnly() {
    return this.props.readOnly;
  }

  renderAttachments() {
    const { attachments } = this.state;
    const { disbursement, disbursementCategoryAmount, actions } = this.props;

    // If there are no attachments and the existing disbursement is not updatable,
    // then we should show the attachments view with "No attachments" so there is not
    // a blank space between 2 horizontal line dividers
    if (attachments.length > 0 || this.cannotAddDocsToPersistedDisbursement) {
      const hideInsert = !this.props.allowInsert;
      const tableClass = hideInsert ? 'table hide-second-column' : 'table';
      const insertColumnHeader = 'Included as insert';
      const insertTooltipTitle =
        'Documents included as an insert are mailed with your check disbursement. Only one document can be inserted and check inserts must be in PDF format to be included.';

      const links = (() => {
        if (attachments.length === 0) {
          return (
            <tr>
              <td className="italic" colSpan={3}>
                No attachments
              </td>
            </tr>
          );
        }
        const { checkedId } = this.state;
        return attachments.map((attachment) => {
          let categoryNumber;
          if (disbursementCategoryAmount) {
            categoryNumber = disbursementCategoryAmount.dca_id;
          } else if (disbursement) {
            categoryNumber = disbursement.id;
          } else {
            categoryNumber = this.props.categoryNumber;
          }
          return (
            <DisbursementAttachment
              actions={actions}
              attachment={attachment}
              categoryNumber={categoryNumber}
              checkboxOnchange={this.checkboxOnchange}
              disbursement={disbursement}
              disbursementCategoryAmount={disbursementCategoryAmount}
              insertDisabled={checkedId && checkedId.toString() !== attachment.id.toString()}
              key={attachment.id}
              readOnly={this.readOnly()}
            />
          );
        });
      })();
      const disbId = disbursement ? disbursement.id : null;
      const dcaId = disbursementCategoryAmount ? disbursementCategoryAmount.dca_id : null;

      let selectedInsertClass = '';
      if (dcaId) {
        selectedInsertClass = `js-edit-dca-entity-${dcaId}`;
      } else if (disbId) {
        selectedInsertClass = `js-edit-entity-${disbId}`;
      }
      return (
        <div>
          <table
            className={tableClass}
            style={{
              width: '100%',
              marginBottom: '20px !important',
              borderBottom: '1px solid #ddd',
              paddingBottom: 10,
            }}
          >
            <thead>
              <tr>
                <th className="padding-top-0">File name</th>
                <th className="padding-top-0">
                  {insertColumnHeader}{' '}
                  <InfoTooltip placement="right" tooltipText={insertTooltipTitle} />
                </th>
                {(!disbursement || disbursement.can_be_updated) && (
                  <th className="align-right padding-top-0">Remove</th>
                )}
              </tr>
            </thead>
            <tbody>{links}</tbody>
          </table>
          <input
            className={selectedInsertClass}
            name={
              disbursement && disbursement.is_recurring
                ? 'recurring_disbursement[selected_insert]'
                : 'disbursement[selected_insert]'
            }
            type="hidden"
            value={this.state.checkedId || ''}
          />
        </div>
      );
    }
  }

  checkboxOnchange(evt) {
    const checkedValue = $(evt.target).is(':checked') ? $(evt.target).attr('value') : null;
    return this.setState({ checkedId: checkedValue });
  }

  renderUploadSection() {
    const { showSpinner } = this.state;

    return (
      <div>
        <div className="button-wrapper" style={{ marginBottom: 12 }}>
          {showSpinner && (
            <LoadingIndicator
              containerStyle={{
                display: 'inline',
                margin: 8,
                verticalAlign: 'middle',
              }}
            />
          )}
          {this.uploadButton()}
        </div>
      </div>
    );
  }

  onBeginUpload() {
    this.setState({ showSpinner: true });
  }

  onUploadSuccess() {
    this.setState({ showSpinner: false });
  }

  uploadButton() {
    if (this.cannotAddDocsToPersistedDisbursement) {
      return null;
    }

    return (
      <AttachmentDropZone
        onBeginUpload={this.onBeginUpload}
        onSuccess={this.onUploadSuccess}
        upload={this.upload}
      />
    );
  }

  render() {
    return (
      <div>
        {this.renderAttachments()}
        {this.renderUploadSection()}
      </div>
    );
  }
}

DashboardBudgetItemAttachments.propTypes = {
  disbursement: PropTypes.object,
  disbursementCategoryAmount: PropTypes.object,
  actions: PropTypes.object,
  store: PropTypes.object,
  readOnly: PropTypes.bool,
  allowInsert: PropTypes.bool,
  categoryNumber: PropTypes.number,
  attachments: PropTypes.array,
};

DashboardBudgetItemAttachments.defaultProps = {
  store: DashboardBudgetItemAttachmentStore,
  actions: DashboardActions,
};
