import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import _contains from 'underscore/modules/contains';
import useDisbursementIndexQuery from 'react/member/components/dashboard/clients/hooks/useDisbursementIndexQuery';
import LoadingIndicator from 'react/shared/components/LoadingIndicator';
import TrueLinkTable from 'react/shared/components/true_link/main/table/TrueLinkTable';
import { asMoney } from 'react/shared/utils/Money';

export default function BeneficiaryDisbursementsTable({ clientId }) {
  const { data: tableData } = useDisbursementIndexQuery({ clientId, filters: '' });

  const disbursementsStatusCell = (status) => {
    if (_contains(['Proposed', 'Pending', 'Additional Approval', 'Hold'], status)) {
      return <p>Requested</p>;
    } else if (_contains(['Stopped', 'Stopped and Redeposited'], status)) {
      return <p>Stopped</p>;
    }
    return <p>{status}</p>;
  };

  const memoizedColumns = React.useMemo(
    () => [
      {
        Header: 'Process Date',
        accessor: 'deliveryDate',
        Cell: ({ row }) => (row.values.deliveryDate ? row.values.deliveryDate : 'On Approval'),
        minWidth: 150,
      },
      {
        Header: 'Payee',
        accessor: 'attributes.payeeDescription',
        Cell: ({ row }) => row.values['attributes.payeeDescription'],
        minWidth: 540,
      },
      {
        Header: 'Status',
        accessor: 'attributes.status',
        Cell: ({ row }) => disbursementsStatusCell(row.values['attributes.status']),
        minWidth: 150,
      },
      {
        Header: 'Amount',
        accessor: (row) => row.attributes.amount.amount,
        Cell: ({ row }) => asMoney(row.values.Amount),
        minWidth: 100,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const memoizedData = useMemo(() => tableData, [tableData]);

  return tableData ? (
    <>
      <TrueLinkTable
        columns={memoizedColumns}
        data={memoizedData}
        filterSection
        isFilterable={false}
        pageSize={10}
        paginated
        showPagination
        variant="compressed"
      />
    </>
  ) : (
    <LoadingIndicator />
  );
}

BeneficiaryDisbursementsTable.propTypes = {
  clientId: PropTypes.string,
};
