import classNames from 'classnames';
import { Formik, Form, ErrorMessage, Field } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import * as Yup from 'yup';
import DashboardActions from 'react/member/actions/dashboard_actions';
import trustStatementRecipientShape from 'react/member/shapes/TrustStatementRecipientShape';
import FormSelectSearchableField from 'react/shared/components/forms/form_select_searchable_field';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import bindAll from 'react/shared/utils/bind_all';
import tlFieldOptions from 'react/shared/utils/tl_field_options';
import TlFieldValidators from 'react/shared/utils/tl_field_validators';

export default class DashboardTrustBeneficiaryProfileTrustStatementRecipientsEditForm extends React.Component {
  constructor(props) {
    super(props);
    bindAll(this);
  }

  initialValues() {
    const {
      id,
      name,
      email,
      mobile,
      address: { street1, street2, city, state, zip },
    } = this.props.trustStatementRecipient;

    return {
      id,
      name: name || '',
      email: email || '',
      mobile: mobile || '',
      address: {
        street1: street1 || '',
        street2: street2 || '',
        city: city || '',
        state: state || '',
        zip: zip || '',
      },
    };
  }

  validationSchema() {
    return Yup.object().shape({
      name: Yup.string().required('Name is required'),
      address: Yup.object().shape({
        street1: Yup.string()
          .max(30, 'must be less than 30 characters')
          .required('Address 1 is required'),
        street2: Yup.string().nullable().max(30, 'must be less than 30 characters'),
        city: Yup.string().max(40, 'must be less than 40 characters').required('City is required'),
        state: Yup.string().required('State is required'),
        zip: Yup.string()
          .matches(TlFieldValidators.zipRegExp, 'Zip is not valid')
          .required('Zip is required'),
      }),
      email: Yup.string().nullable().email('Invalid format'),
      mobile: Yup.string().nullable().matches(TlFieldValidators.phoneRegExp, 'Invalid format'),
    });
  }

  isUpdating() {
    const {
      trustStatementRecipient: { id },
    } = this.props;
    return !!id;
  }

  onSubmit(values, { setSubmitting }) {
    const {
      trustBeneficiary: { slug },
    } = this.props;
    const onRequestCompleted = () => {
      setSubmitting(false);
    };

    if (this.isUpdating()) {
      DashboardActions.updateTrustStatementRecipient(slug, values, onRequestCompleted);
    } else {
      DashboardActions.createTrustStatementRecipient(slug, values, onRequestCompleted);
    }
  }

  renderForm({ isSubmitting, isValid }) {
    return (
      <Form>
        <div className="tl-fields-row">
          <div className="tl-field span12">
            <label className="tl-field-label" htmlFor="name">
              Name
            </label>
            <div className="tl-field-data">
              <Field name="name" placeholder="Name" type="text" />
            </div>
            <ErrorMessage name="name">
              {(msg) => <div className="tl-field-error">{msg}</div>}
            </ErrorMessage>
          </div>
        </div>
        <div className="tl-fields-row">
          <div className="tl-field span12">
            <label className="tl-field-label" htmlFor="address.street1">
              Address 1
            </label>
            <div className="tl-field-data">
              <Field name="address.street1" placeholder="Address 1" type="text" />
            </div>
            <ErrorMessage name="address.street1">
              {(msg) => <div className="tl-field-error">{msg}</div>}
            </ErrorMessage>
          </div>
        </div>
        <div className="tl-fields-row">
          <div className="tl-field span12">
            <label className="tl-field-label" htmlFor="address.street2">
              Address 2
            </label>
            <div className="tl-field-data">
              <Field name="address.street2" placeholder="Address 2" type="text" />
            </div>
            <ErrorMessage name="address.street2">
              {(msg) => <div className="tl-field-error">{msg}</div>}
            </ErrorMessage>
          </div>
        </div>
        <div className="tl-fields-row">
          <div className="tl-field span6">
            <label className="tl-field-label" htmlFor="email">
              Email
            </label>
            <div className="tl-field-data">
              <Field name="email" placeholder="Email" type="email" />
            </div>
            <ErrorMessage name="email">
              {(msg) => <div className="tl-field-error">{msg}</div>}
            </ErrorMessage>
          </div>
          <div className="tl-field span6">
            <label className="tl-field-label" htmlFor="mobile">
              Phone
            </label>
            <div className="tl-field-data">
              <Field name="mobile" placeholder="Phone" type="text" />
            </div>
            <ErrorMessage name="mobile">
              {(msg) => <div className="tl-field-error">{msg}</div>}
            </ErrorMessage>
          </div>
        </div>
        <div className="tl-fields-row">
          <div className="tl-field span6">
            <label className="tl-field-label" htmlFor="address.city">
              City
            </label>
            <div className="tl-field-data">
              <Field name="address.city" placeholder="City" type="text" />
            </div>
            <ErrorMessage name="address.city">
              {(msg) => <div className="tl-field-error">{msg}</div>}
            </ErrorMessage>
          </div>
          <div className="tl-field span3">
            <label className="tl-field-label" htmlFor="address.state">
              State
            </label>
            <div className="tl-field-data">
              <Field
                component={FormSelectSearchableField}
                name="address.state"
                options={tlFieldOptions.stateOptions()}
                reactSelectProps={{ id: 'trust-statement-recipients-state' }}
              />
            </div>
            <ErrorMessage name="address.state">
              {(msg) => <div className="tl-field-error">{msg}</div>}
            </ErrorMessage>
          </div>
          <div className="tl-field span3" />
        </div>
        <div className="tl-fields-row">
          <div className="tl-field span3">
            <label className="tl-field-label" htmlFor="address.zip">
              Zip
            </label>
            <div className="tl-field-data">
              <Field name="address.zip" placeholder="Zip" type="text" />
            </div>
            <ErrorMessage name="address.zip">
              {(msg) => <div className="tl-field-error">{msg}</div>}
            </ErrorMessage>
          </div>
        </div>
        <div className="tl-form-actions-row">
          <TrueLinkButton
            className={classNames('btn btn-success', {
              disabled: !isValid || isSubmitting,
            })}
            disabled={!isValid || isSubmitting}
            type="submit"
            variant="none"
          >
            Save Statement Recipient
          </TrueLinkButton>
        </div>
      </Form>
    );
  }

  render() {
    return (
      <div className="form-content-edit tl-form-edit align-left">
        <Formik
          component={this.renderForm}
          initialValues={this.initialValues()}
          isInitialValid={this.isUpdating()}
          onSubmit={this.onSubmit}
          validationSchema={this.validationSchema()}
        />
      </div>
    );
  }
}

DashboardTrustBeneficiaryProfileTrustStatementRecipientsEditForm.propTypes = {
  trustBeneficiary: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
  trustStatementRecipient: trustStatementRecipientShape.isRequired,
};

DashboardTrustBeneficiaryProfileTrustStatementRecipientsEditForm.defaultProps = {
  trustStatementRecipient: {
    name: '',
    email: '',
    mobile: '',
    address: { street1: '', street2: '', city: '', state: '', zip: '' },
  },
};
