import PropTypes from 'prop-types';
import React from 'react';
import { asMoney } from 'react/shared/utils/Money';

const currencyClass = function (amount) {
  if (!amount) return '';
  if (amount < 0) return 'red';
  return 'green';
};

export default function CardholderTransactionRow({ nightlyPost }) {
  const attributes = nightlyPost.attributes;

  return (
    <tr key={nightlyPost.id}>
      <td>{attributes.date}</td>
      <td>
        {attributes.description}
        {attributes.locationOrPhone && ` - ${attributes.locationOrPhone}`}
        <br />
        <i>{attributes.description2}</i>
      </td>
      <td className={currencyClass(attributes.amount)}>{asMoney(Number(attributes.amount))}</td>
    </tr>
  );
}

CardholderTransactionRow.propTypes = {
  nightlyPost: PropTypes.shape({
    type: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    attributes: PropTypes.shape({
      amount: PropTypes.number.isRequired,
      date: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      description2: PropTypes.string.isRequired,
      locationOrPhone: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
