import axios from 'axios';
import { AXIOS_XHR_CONFIG } from 'react/shared/utils/Axios';

/**
 * Gets an organization by slug
 * @param {string} orgSlug the slug for the given organization
 * @param {object} { analysisFields } the comma-separated string of requested analysis fields
 */
export function fetchOrg(orgSlug, { analysisFields }) {
  return axios.get(
    RailsRoutes.api_v2_organization_path(orgSlug, {
      fields: {
        'organization.analysis': analysisFields,
      },
      ...AXIOS_XHR_CONFIG,
    }),
  );
}
