import _find from 'underscore/modules/find';
import DashboardActions from 'react/member/actions/dashboard_actions';

const DashboardWealthAccountEventAttachmentStore = flux.createStore({
  attachments: [],
  actions: [
    DashboardActions.fetchWealthAccountEventAttachments,
    DashboardActions.destroyWealthAccountEventAttachment,
  ],

  destroyWealthAccountEventAttachment(wealthAccountEventId, attachmentId) {
    $.ajax({
      url: RailsRoutes.api_v2_attachment_path(attachmentId, { format: 'json' }),
      type: 'DELETE',
      dataType: 'json',
    }).done(() => {
      this.emit('attachments.destroy');
      this.fetchWealthAccountEventAttachments(wealthAccountEventId);
    });
  },

  fetchWealthAccountEventAttachments(wealthAccountEventId) {
    $.get(
      RailsRoutes.api_v2_attachments_path({
        attachableType: 'WealthAccountEvent',
        attachableId: wealthAccountEventId,
        format: 'json',
      }),
      (attachments) => {
        const formattedAttachments = attachments.data.map((attachment) => ({
          ...attachment.attributes,
          id: attachment.id,
        }));
        this.attachments = formattedAttachments;
        this.emit('attachments.fetch', wealthAccountEventId);
      },
    );
  },

  exports: {
    // This must be here as an export (instead of an action),
    // because actions have their arguments serialized
    // ...and we need the real input element
    uploadAttachment(wealthAccountEventId, fileInput) {
      const formData = new window.FormData();
      formData.append(fileInput.name, fileInput.files[0]);
      fileInput.value = '';
      this.emit('attachment.uploading');
      $.ajax({
        url: RailsRoutes.api_v2_attachments_path(wealthAccountEventId, {
          attachableType: 'WealthAccountEvent',
          attachableId: wealthAccountEventId,
          format: 'json',
        }),
        type: 'POST',
        data: formData,
        enctype: 'multipart/form-data',
        processData: false,
        contentType: false,
        dataType: 'json',
      })
        .done((attachment) => {
          this.emit('attachment.upload', attachment);
          this.fetchWealthAccountEventAttachments(wealthAccountEventId);
        })
        .fail(() => {
          this.emit('attachment.upload', null);
        });
    },

    get(id) {
      return _find(this.attachments, (attachment) => attachment.id === id);
    },
    getAttachments() {
      return this.attachments;
    },
  },
});

export default DashboardWealthAccountEventAttachmentStore;
