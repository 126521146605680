import PropTypes from 'prop-types';
import React from 'react';

export default function GenericTextarea(props) {
  return <textarea name={props.name} onKeyUp={props.onKeyUp} style={props.style} />;
}

GenericTextarea.propTypes = {
  onKeyUp: PropTypes.func,
  name: PropTypes.string,
  style: PropTypes.object.isRequired,
};
