import PropTypes from 'prop-types';
import programNameShape from './account_program_name_shape';

const { string, shape } = PropTypes;

export const localeShapeKeys = {
  msaLink: string,
  programName: programNameShape,
  supportEmail: string,
  supportADTollfree: string,
  supportCHTollfree: string,
};

const localeShape = shape(localeShapeKeys);

export default localeShape;
