export const ALLOCATION_OPTIONS = {
  cash: 'Cash',
  certificates_of_deposit_only: 'Certificates of Deposit Only',
  custom: 'Custom',
  '100_fixed_income': '100% Fixed Income',
  '5_equity_95_fixed': '5% Equity / 95% Fixed Income',
  '10_equity_90_fixed': '10% Equity / 90% Fixed Income',
  '15_equity_85_fixed': '15% Equity / 85% Fixed Income',
  '20_equity_80_fixed': '20% Equity / 80% Fixed Income',
  '25_equity_75_fixed': '25% Equity / 75% Fixed Income',
  '30_equity_70_fixed': '30% Equity / 70% Fixed Income',
  '35_equity_65_fixed': '35% Equity / 65% Fixed Income',
  '40_equity_60_fixed': '40% Equity / 60% Fixed Income',
  '45_equity_55_fixed': '45% Equity / 55% Fixed Income',
  '50_equity_50_fixed': '50% Equity / 50% Fixed Income',
  '55_equity_45_fixed': '55% Equity / 45% Fixed Income',
  '60_equity_40_fixed': '60% Equity / 40% Fixed Income',
  '65_equity_35_fixed': '65% Equity / 35% Fixed Income',
  '70_equity_30_fixed': '70% Equity / 30% Fixed Income',
  '75_equity_25_fixed': '75% Equity / 25% Fixed Income',
  '80_equity_20_fixed': '80% Equity / 20% Fixed Income',
  '85_equity_15_fixed': '85% Equity / 15% Fixed Income',
  '90_equity_10_fixed': '90% Equity / 10% Fixed Income',
};
