import createReactClass from 'create-react-class';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import Field from './Field';
import FieldMixin from './FieldMixin';
import Actions from 'react/shared/actions/actions';
import HolidayCalendarStore from 'react/shared/stores/HolidayCalendarStore';

const DateField = createReactClass({
  propTypes: {
    minDate: PropTypes.object,
    name: PropTypes.string,
    excludeWeekendsAndHolidays: PropTypes.bool,
  },

  mixins: [FieldMixin],

  componentDidMount() {
    this.initField();
    if (
      this.props.excludeWeekendsAndHolidays &&
      (HolidayCalendarStore.getBankHolidays() === undefined ||
        HolidayCalendarStore.getBankHolidays() === null)
    ) {
      Actions.fetchBankHolidays();
    }
  },

  datepicker(c) {
    const domc = ReactDOM.findDOMNode(c);
    const options = {
      minDate: this.props.minDate,
      dateFormat: 'yy-mm-dd',
      onSelect: (date, _picker) => {
        this.controller().set(this.props.name, date);
      },
    };

    if (this.props.excludeWeekendsAndHolidays) {
      options.beforeShowDay = (day) => this.rejectWeekendsAndHolidays(day);
    }

    $(domc).datepicker(options);
  },

  rejectWeekendsAndHolidays(day) {
    const [show, cssClass] = $.datepicker.noWeekends(day);
    this.holidays = this.holidays || HolidayCalendarStore.getBankHolidays();
    if (show && this.holidays) {
      return [!this.holidays[moment(day).format('YYYY-MM-DD')], ''];
    }

    return [show, cssClass];
  },

  renderShow() {
    return this.formattedValue();
  },

  renderEdit() {
    return (
      <input
        name={this.inputName()}
        onChange={this.onChange}
        onKeyUp={this.keyup}
        ref={this.datepicker}
        style={{ margin: 0, padding: 0 }}
        type="text"
        value={this.value() || ''}
      />
    );
  },

  render() {
    return (
      <Field
        {...this.props}
        editable={this.editable}
        label={this.label}
        renderEdit={this.renderEdit}
        renderShow={this.renderShow}
      />
    );
  },
});

DateField.propTypes = {
  minDate: PropTypes.object,
  name: PropTypes.string,
  excludeWeekendsAndHolidays: PropTypes.bool,
};

export default DateField;
