import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import CardActivationConfirmation from './CardActivationConfirmation';
import CardActivationSuccess from './CardActivationSuccess';
import DashboardActions from 'react/member/actions/dashboard_actions';
import TrueLinkModal from 'react/shared/components/true_link/lab/TrueLinkModal';
import theme from 'react/shared/theme/Theme';

const useStyles = makeStyles(() => ({
  ivdModal: {
    '& .modal-body': {
      padding: '50px 30px 30px',
    },
    '& .modal-header': {
      display: 'none',
    },
    '& .MuiPaper-root': {
      width: '552px',
      [theme.breakpoints.down('sm')]: {
        margin: '0',
      },
    },
  },
}));

export default function CardActivationModal(props) {
  const classes = useStyles();

  const [showSuccess, setShowSuccess] = useState(false);

  const { mutate: activateCard, isLoading } = useMutation({
    mutationFn: () =>
      axios.post(RailsRoutes.activate_api_v2_card_path(props.cardSlug, { format: 'json' })),
    onSuccess: () => {
      setShowSuccess(true);
      window.setTimeout(() => {
        props.onSuccess();
        DashboardActions.reloadOnboardingFlow();
      }, 2000);
    },
  });

  const { mutate: setAccountId } = useMutation({
    mutationFn: (nickname) =>
      axios.put(
        RailsRoutes.dashboard_update_cardholder_path(
          props.accountId,
          { format: 'json' },
          { update_account_id: nickname },
        ),
      ),
    onSuccess: () => {
      Truelink.flash('success', 'Your account id has been saved.');
      props.onClose();
    },
    onError: () => {
      props.onClose();
    },
  });

  const onSubmit = (event) => {
    if (event?.nickname) {
      setAccountId(event.nickname);
    } else {
      props.onClose();
    }
    DashboardActions.reloadOnboardingFlow();
    props.onSuccess();
  };

  return (
    <TrueLinkModal modalProps={{ className: classes.ivdModal, maxWidth: 'md' }} title="">
      <IconButton
        aria-label="close"
        onClick={props.onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      {!showSuccess && (
        <CardActivationConfirmation
          firstName={props.firstName}
          isLoading={isLoading}
          last4digits={props.last4digits}
          lastName={props.lastName}
          nickname={props.nickname}
          onClose={props.onClose}
          onSubmit={activateCard}
        />
      )}
      {showSuccess && (
        <CardActivationSuccess
          firstName={props.firstName}
          last4digits={props.last4digits}
          nickname={props.nickname}
          onSubmit={onSubmit}
        />
      )}
    </TrueLinkModal>
  );
}

CardActivationModal.propTypes = {
  accountId: PropTypes.string,
  cardSlug: PropTypes.string,
  firstName: PropTypes.string,
  last4digits: PropTypes.string,
  lastName: PropTypes.string,
  nickname: PropTypes.string,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
};
