import PropTypes from 'prop-types';
import React from 'react';
import HoldingRow from './HoldingRow';
import DashboardActions from 'react/member/actions/dashboard_actions';
import DashboardHoldingStore from 'react/member/stores/DashboardHoldingStore';
import SubSectionHeader from 'react/shared/components/SubSectionHeader';
import bindAll from 'react/shared/utils/bind_all';

export default class HoldingIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = { holdings: [] };

    bindAll(this);
  }

  componentDidMount() {
    DashboardHoldingStore.on('holdings.fetch', this.onChangeHoldings);
    DashboardActions.fetchHoldings(this.props.client.slug);
  }

  componentWillUnmount() {
    DashboardHoldingStore.off('holdings.fetch', this.onChangeHoldings);
  }

  onChangeHoldings(holdings) {
    this.setState({ holdings });
  }

  render() {
    return (
      <div>
        <SubSectionHeader noMarginBottom>Holdings</SubSectionHeader>

        <table className="table" style={{ tableLayout: 'fixed' }}>
          <thead>
            <tr>
              <th colSpan={2}>Portfolio</th>
              <th />
              <th>Units</th>
              <th>Unit Value</th>
              <th>Total Value</th>
            </tr>
          </thead>

          <tbody>
            {this.state.holdings.map((holding, i) => (
              <HoldingRow holding={holding} key={i} />
            ))}
          </tbody>
        </table>

        <br />
      </div>
    );
  }
}

HoldingIndex.propTypes = {
  client: PropTypes.object.isRequired,
};
