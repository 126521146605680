import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import UserCardSvg from 'images/user-card.svg';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import TrueLinkLink from 'react/shared/components/true_link/main/TrueLinkLink';
import theme from 'react/shared/theme/Theme';
import { possessiverize } from 'react/shared/utils/Strings';

const useStyles = makeStyles(() => ({
  idvErrorModal: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  userCardSvg: {
    width: '72px',
  },
  actionBtns: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '10px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      '& .primaryOutline': {
        width: '100%',
        textAlign: 'center',
      },
    },
  },
  submitBtn: {
    width: 'fit-content',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));

export default function IdvError({ firstName, onClose, setShowError }) {
  const classes = useStyles();

  const resetError = () => setShowError(false);

  return (
    <form className={classes.idvErrorModal} id="idv-form-error">
      <img alt="verify user" className={classes.userCardSvg} src={UserCardSvg} />
      <Typography variant="h4">We need a little more information</Typography>
      <Typography variant="body1">
        We are unable to verify {possessiverize(firstName)} identity based on the information
        provided. If you feel like you maybe entered the information wrong,{' '}
        <a onClick={resetError} onKeyDown={resetError} role="button" tabIndex="0">
          you can try again.
        </a>
      </Typography>
      <Typography variant="body1">
        Otherwise, federal guidelines require we gather <b>two forms of valid ID.</b>
      </Typography>
      <div>
        <p>One form of Photo ID:</p>
        <ul>
          <li>Driver's License</li>
          <li>Passport</li>
          <li>State-Issued Identification Card</li>
        </ul>
      </div>
      <div>
        <p>Plus, one of the following documents:</p>
        <ul>
          <li>Utility bill</li>
          <li>Lease agreement</li>
          <li>Current paystub</li>
          <li>Bank or credit card statement showing customer's name and current address</li>
          <li>State-issued birth certificate</li>
          <li>Marriage license</li>
          <li>Social Security or Medicaid award letter</li>
          <li>School or work ID with photo</li>
          <li>ITIN letter</li>
        </ul>
      </div>
      <div>
        Please email the two documents to: <a>documents@truelinkfinancial.com</a>
      </div>
      <div className={classes.actionBtns}>
        <TrueLinkButton
          className={classes.submitBtn}
          onClick={onClose}
          type="submit"
          variant="primary"
        >
          Done
        </TrueLinkButton>
        <TrueLinkLink className="primaryOutline" href="/contact" rel="noreferrer" target="_blank">
          Contact support
        </TrueLinkLink>
      </div>
    </form>
  );
}

IdvError.propTypes = {
  firstName: PropTypes.string,
  onClose: PropTypes.func,
  setShowError: PropTypes.func,
};
