import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useRef, useState, useEffect } from 'react';
import MfaVerifyWizardStep1 from './MfaVerifyWizardStep1';
import MfaVerifyWizardStep2 from './MfaVerifyWizardStep2';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';

export default function MfaVerifyWizard({ email, mfaDeliveryOption, phoneNumber, step, setStep }) {
  const [code, setCode] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [showError, setShowError] = useState(false);
  const [mfaVerificationId, setMfaVerificationId] = useState();
  const [redirecting, setRedirecting] = useState(false);
  const continueBtnRef = useRef(null);

  useEffect(() => {
    setShowError(false);
    if (step > 1) {
      setDisabled(code.length < 6);
      if (code.length === 6) {
        window.setTimeout(() => continueBtnRef.current?.focus(), 10);
      }
    } else {
      setDisabled(false);
      continueBtnRef.current?.focus();
    }
  }, [step, code]);

  const { mutate: sendMfaCode, isLoading: mfaSendLoading } = useMutation({
    mutationFn: () =>
      axios.post(
        RailsRoutes.mfa_verifications_path({
          mfa_option: mfaDeliveryOption,
          format: 'json',
        }),
      ),
    onSuccess: (response) => {
      setMfaVerificationId(response.data.id);
      setStep(2);
      setShowError(false);
    },
  });

  const { mutate: verifyMfaCode, isLoading: mfaVerifyLoading } = useMutation({
    mutationFn: () =>
      axios.put(RailsRoutes.mfa_verification_path(mfaVerificationId, { format: 'json' }), {
        code,
      }),
    onSuccess: () => {
      setRedirecting(true);
      window.location.href = RailsRoutes.root_path();
    },
    onError: () => setShowError(true),
  });

  const step1 = <MfaVerifyWizardStep1 email={email} phoneNumber={phoneNumber} />;
  const step2 = (
    <MfaVerifyWizardStep2
      email={email}
      phoneNumber={phoneNumber}
      sendMfaCode={sendMfaCode}
      setCode={setCode}
      setShowError={setShowError}
      showError={showError}
    />
  );

  return (
    <div>
      <h2 style={{ marginTop: '0px' }}>Let's verify your identity</h2>
      {step == 1 ? step1 : step2}
      <TrueLinkButton
        data-testid="mfaVerifyBtn"
        disabled={disabled}
        id="mfaVerifyBtn"
        loading={mfaSendLoading || mfaVerifyLoading || redirecting}
        onClick={step == 1 ? sendMfaCode : verifyMfaCode}
        ref={continueBtnRef}
        size="large"
        variant="primary"
      >
        {step == 1 ? 'Send me a code' : 'Continue'}
      </TrueLinkButton>
    </div>
  );
}

MfaVerifyWizard.propTypes = {
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
  mfaDeliveryOption: PropTypes.string,
  step: PropTypes.number,
  setStep: PropTypes.func,
};
