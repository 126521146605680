import PropTypes from 'prop-types';
import React from 'react';
import InfoTooltip from 'react/shared/components/tooltips/InfoTooltip';

function NoPoBoxDisclaimer({ addressType }) {
  let tooltipStyle = 'width: 280px';
  let tooltipText =
    'Because this address is used for identity verification, a street address is required.';

  if (addressType === 'administrator') {
    tooltipStyle = 'width: 320px';
    tooltipText +=
      ' We will not send anything to the street address unless you request that we do so.';
  }

  return (
    <span>
      <span className="hide-on-mobile">
        Please no P.O. boxes
        {addressType !== 'other' && (
          <InfoTooltip tooltipStyle={tooltipStyle} tooltipText={tooltipText} />
        )}
      </span>
      <span className="show-on-mobile">No P.O. Box</span>
    </span>
  );
}

NoPoBoxDisclaimer.propTypes = {
  addressType: PropTypes.oneOf(['administrator', 'cardholder', 'other']).isRequired,
};

export default NoPoBoxDisclaimer;
