import { ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import _isEmpty from 'underscore/modules/isEmpty';
import TextField from 'react/member/components/signups/signup_form/form_partials/fields/TextField';
import FieldLabel from 'react/member/components/signups/signup_form/form_partials/labels/field_label';

function NameFields({
  entity,
  labelPrefix,
  onChangeCallback,
  prefill,
  rowClass,
  colClass,
  dataAnalyticsPrefix,
}) {
  const dataAnalyticsKey = (fieldName) =>
    _isEmpty(dataAnalyticsPrefix) ? fieldName : `${dataAnalyticsPrefix}_${fieldName}`;

  return (
    <div className={rowClass}>
      <div className={colClass}>
        <FieldLabel
          htmlFor={`${entity}_first_name`}
          labelContent={labelPrefix ? `${labelPrefix} first name` : 'First name'}
        />
        <TextField
          dataAnalyticsKey={dataAnalyticsKey('first_name')}
          fast={!prefill}
          id={`${entity}_first_name`}
          name={`${entity}.first_name`}
          onChangeCallback={onChangeCallback}
          readOnly={prefill}
        />
        <ErrorMessage className="error" component="label" name={`${entity}.first_name`} />
      </div>
      <div className={colClass}>
        <FieldLabel
          htmlFor={`${entity}_last_name`}
          labelContent={labelPrefix ? `${labelPrefix} last name` : 'Last name'}
        />
        <TextField
          dataAnalyticsKey={dataAnalyticsKey('last_name')}
          fast={!prefill}
          id={`${entity}_last_name`}
          name={`${entity}.last_name`}
          onChangeCallback={onChangeCallback}
          readOnly={prefill}
        />
        <ErrorMessage className="error" component="label" name={`${entity}.last_name`} />
      </div>
    </div>
  );
}

NameFields.propTypes = {
  entity: PropTypes.oneOf(['administrator', 'cardholder']).isRequired,
  labelPrefix: PropTypes.string,
  onChangeCallback: PropTypes.func,
  prefill: PropTypes.bool,
  rowClass: PropTypes.string,
  colClass: PropTypes.string,
  dataAnalyticsPrefix: PropTypes.string,
};

NameFields.defaultProps = {
  labelPrefix: null,
  prefill: false,
  rowClass: 'card--form__row',
  colClass: 'card--form__col',
  dataAnalyticsPrefix: null,
};

export default NameFields;
