import classNames from 'classnames';
import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import Textarea from 'react-textarea-autosize';
import DashboardActions from 'react/member/actions/dashboard_actions';
import FormCurrencyField from 'react/shared/components/forms/form_currency_field';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import bindAll from 'react/shared/utils/bind_all';

export default class DashboardTrustBeneficiaryProfileGovernmentBenefitsForm extends React.Component {
  constructor(props) {
    super(props);

    bindAll(this);
  }

  initialValues() {
    const { governmentBenefits, governmentBenefitNotes } = this.props;
    return {
      governmentBenefits,
      governmentBenefitNotes,
    };
  }

  onSubmit(values, { setSubmitting }) {
    const onRequestCompleted = () => {
      setSubmitting(false);
    };
    DashboardActions.updateBeneGovernmentBenefits(
      this.props.trustBeneficiarySlug,
      {
        trust_beneficiary: {
          government_benefits_trust_beneficiaries_attributes:
            this.adaptedValuesForSubmission(values),
          government_benefit_notes: values.governmentBenefitNotes,
        },
      },
      onRequestCompleted,
    );
  }

  adaptedValuesForSubmission(values) {
    // If the benefit is not activated for the bene, don't submit it to Rails to prevent it from being created
    const benefitsToSubmit = values.governmentBenefits.filter(
      (benefit) => benefit.beneHasBenefit || benefit.id,
    );

    return benefitsToSubmit.map((benefit) => {
      if (!benefit.beneHasBenefit && benefit.id) {
        // If the benefit is being deactivated then send the _destroy flag.
        // Rails will 404 if the benefit doesnt exist
        return {
          _destroy: true,
          id: benefit.id,
        };
      }
      return {
        amount: benefit.amount,
        government_benefit_id: benefit.governmentBenefitId,
        id: benefit.id,
      };
    });
  }

  shouldShowAmountField(govtBenefit) {
    const isActive = govtBenefit.beneHasBenefit;

    return govtBenefit.showAmount && isActive;
  }

  renderForm({ isSubmitting, values }) {
    const { governmentBenefits } = values;
    return (
      <Form>
        <div className="form-content-edit tl-form-edit align-left">
          <div className="tl-field-group">
            {governmentBenefits.map((govtBenefit, index) => (
              <div key={govtBenefit.governmentBenefitId}>
                <div className="tl-fields-row">
                  <div className="tl-field w-col-9">
                    <label
                      className="tl-field-label"
                      htmlFor={`governmentBenefits[${index}].beneHasBenefit`}
                    >
                      <span className="tl-checkbox-input">
                        <Field
                          checked={govtBenefit.beneHasBenefit}
                          component="input"
                          id={`governmentBenefits[${index}].beneHasBenefit`}
                          name={`governmentBenefits[${index}].beneHasBenefit`}
                          type="checkbox"
                        />
                      </span>
                      {govtBenefit.name}
                    </label>
                  </div>

                  {this.shouldShowAmountField(govtBenefit) ? (
                    <div className="tl-field w-col-3">
                      <label
                        className="tl-field-label"
                        htmlFor={`governmentBenefits[${index}].amount`}
                      >
                        Amount
                      </label>

                      <div
                        className="tl-field-data"
                        id={`governmentBenefit-${govtBenefit.governmentBenefitId}-amount`}
                      >
                        <Field
                          component={FormCurrencyField}
                          name={`governmentBenefits[${index}].amount`}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            ))}
            <div className="tl-fields-row">
              <div className="tl-field">
                <label className="tl-field-label" htmlFor="governmentBenefitNotes">
                  Notes
                </label>

                <div className="tl-field-data">
                  <Field name="governmentBenefitNotes">
                    {({ field, form }) => (
                      <Textarea
                        cols="60"
                        name={field.name}
                        onChange={(event) =>
                          form.setFieldValue(field.name, event.currentTarget.value)
                        }
                        style={{ width: '100%' }}
                        value={field.value}
                      />
                    )}
                  </Field>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tl-form-actions-row">
          <TrueLinkButton
            className={classNames('btn btn-success', {
              disabled: isSubmitting,
            })}
            disabled={isSubmitting}
            type="submit"
            variant="none"
          >
            Update
          </TrueLinkButton>
        </div>
      </Form>
    );
  }

  render() {
    return (
      <Formik
        component={this.renderForm}
        initialValues={this.initialValues()}
        onSubmit={this.onSubmit}
      />
    );
  }
}

DashboardTrustBeneficiaryProfileGovernmentBenefitsForm.propTypes = {
  trustBeneficiarySlug: PropTypes.string.isRequired,
  governmentBenefits: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.string,
      id: PropTypes.string,
      governmentBenefitId: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      beneHasBenefit: PropTypes.bool.isRequired,
    }).isRequired,
  ),
  governmentBenefitNotes: PropTypes.string,
};
