import PropTypes from 'prop-types';

const { string, bool, number, arrayOf, oneOf, oneOfType, shape } = PropTypes;

export const attachmentCategoryShape = oneOf(['profile', 'budget']);

export const attachableTypeShape = oneOf(['CardAccountTrust', 'TrustBeneficiary', 'Account']);

export const attachableShape = shape({
  id: oneOfType([string, number]),
  slug: string.isRequired,
});

export const fileTypeShape = oneOf(['document', 'receipt']);

export const fileShape = shape({
  id: oneOfType([string, number]).isRequired,
  name: string.isRequired,
  created_at: string.isRequired,
  uploaded_by: string,
  uploader: string,
  description: string,
  content_type: string,
  content_date: string,
  smartpayables_insert: bool,
  category: string,
  attachable_id: oneOfType([string, number]).isRequired,
  attachable_type: string,
  folder: shape({
    id: oneOfType([string, number]),
    name: string,
  }).isRequired,
});

export const folderShape = shape({
  id: oneOfType([string, number]).isRequired,
  name: string.isRequired,
  trust_beneficiary_id: oneOfType([string, number]),
});

export const filesShape = arrayOf(fileShape);
export const foldersShape = arrayOf(folderShape);

export const browseableFilesAndFoldersShape = arrayOf(
  shape({
    folder: folderShape,
    files: filesShape.isRequired,
  }),
);

export default {
  attachmentCategoryShape,
  attachableTypeShape,
  attachableShape,
  fileTypeShape,
  fileShape,
  folderShape,
  filesShape,
  foldersShape,
  browseableFilesAndFoldersShape,
};
