import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import MfaSetupWizard from 'react/member/components/mfa/MfaSetupWizard';
import TrueLinkLink from 'react/shared/components/true_link/main/TrueLinkLink';

const useStyles = makeStyles({
  mfaState: {
    paddingRight: '10px',
  },
});

export default function MfaField({ enable, phoneNumber, email, mfaDeliveryOption }) {
  const [isModalOpen, setModalOpen] = useState(false);
  const [enableMfa, setEnableMfa] = useState(enable);
  const [mfaOption, setMfaOption] = useState(mfaDeliveryOption);

  const classes = useStyles();

  const mfaStateText = !enableMfa ? 'ON' : 'OFF';
  const mfaActionText = !enableMfa ? 'Deactivate' : 'Activate';

  const validPhoneNumber = phoneNumber && phoneNumber.length === 10;

  const openMfaWizard = () => {
    setModalOpen(true);
  };

  const closeMfaWizard = (enable) => {
    setModalOpen(false);
    setEnableMfa(enable);
  };

  const handleMfaOptionChange = (val) => {
    setMfaOption(val);
  };

  return (
    <>
      <span className={classes.mfaState} data-testid="mfaStateText">
        {mfaStateText}
      </span>
      {validPhoneNumber && (
        <TrueLinkLink ariaLabel={mfaActionText} onClick={openMfaWizard}>
          {mfaActionText}
        </TrueLinkLink>
      )}
      {!validPhoneNumber && (
        <span>
          Please add a phone number to {mfaActionText.toLowerCase()} two-step verification
        </span>
      )}
      {isModalOpen && (
        <MfaSetupWizard
          closeModal={closeMfaWizard}
          email={email}
          enable={enableMfa}
          handleMfaOptionChange={handleMfaOptionChange}
          mfaDeliveryOption={mfaOption}
          phoneNumber={phoneNumber}
        />
      )}
    </>
  );
}

MfaField.propTypes = {
  email: PropTypes.string,
  enable: PropTypes.bool.isRequired,
  mfaDeliveryOption: PropTypes.string,
  phoneNumber: PropTypes.string,
};
