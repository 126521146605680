/* global google */
import PropTypes from 'prop-types';
import React from 'react';
import childrenShape from 'react/shared/shapes/children';
import bindAll from 'react/shared/utils/bind_all';

export default class GoogleAutoComplete extends React.Component {
  constructor(props) {
    super(props);
    bindAll(this);
  }

  componentDidMount() {
    const { id } = this.props;
    // Enable google auto complete on the street 1 field for our entity
    this.autocomplete = new google.maps.places.Autocomplete(document.getElementById(id), {
      types: ['geocode'],
    });

    // This limits the fields in the response lowering our cost per API call
    this.autocomplete.setFields(['address_component']);

    // Set up handler when a place is selected
    this.autocomplete.addListener('place_changed', this.onPlaceSelected);
  }

  onPlaceSelected() {
    const { handlePlaceSelected } = this.props;

    const place = this.autocomplete.getPlace();

    // Rework the address components into a hash. In general, we prefer the short_name
    // from the response, but route (street name) and locality (city name) we prefer
    // the full version.
    const addressComponents = {};
    for (const component of place.address_components) {
      const addressType = component.types[0];

      switch (addressType) {
        case 'route':
        case 'locality':
          addressComponents[addressType] = component.long_name;
          break;
        default:
          addressComponents[addressType] = component.short_name;
      }
    }

    handlePlaceSelected(addressComponents);
  }

  render() {
    return <div>{this.props.children}</div>;
  }
}
GoogleAutoComplete.propTypes = {
  children: childrenShape.isRequired,
  handlePlaceSelected: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};
