import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Accounts from './cards/Accounts';
import EditTrustDescription from './edit_trust_description/EditTrustDescription';
import ManagedAssets from './managed_assets/ManagedAssets';
import Profile from './profile/Profile';
import UniqueAssetsCard from './unique_assets/card/UniqueAssetsCard';
import useTrustBeneficiaryAnalysisQuery from 'react/member/components/dashboard/clients/hooks/useTrustBeneficiaryAnalysisQuery';
import useTrustBeneficiaryShowQuery from 'react/member/components/dashboard/clients/hooks/useTrustBeneficiaryShowQuery';
import TrueLinkLink from 'react/shared/components/true_link/main/TrueLinkLink';
import theme from 'react/shared/theme/Theme';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: '32px',
  },
  banner: {
    marginBottom: theme.spacing(4),
  },
  trustTitle: {
    marginBottom: theme.spacing(2),
    display: 'block',
  },
}));

export default function IndividualTrustBeneficiaryOverview({ client }) {
  const classes = useStyles();
  const [values, setValues] = useState({
    name: '',
  });
  const [openTrustDescription, setOpenTrustDescription] = useState(false);

  const handleOpenTrustDescription = () => {
    setOpenTrustDescription(true);
  };
  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };
  const handleCloseTrustDescription = () => {
    setOpenTrustDescription(false);
  };

  const beneShowData = useTrustBeneficiaryShowQuery({ clientSlug: client.slug });
  const beneAnalysisData = useTrustBeneficiaryAnalysisQuery({ clientSlug: client.slug });

  const { mutate: sendTrustDescription } = useMutation({
    mutationFn: () =>
      axios.patch(RailsRoutes.api_v2_trust_path(beneShowData?.data?.trust.id, { format: 'json' }), {
        data: {
          id: beneShowData?.data?.trust?.id,
          description: values.name,
        },
      }),
    onSuccess: () => {
      beneShowData?.refetch();
      setOpenTrustDescription(false);
    },
  });

  const handleSubmitDescription = async () => {
    sendTrustDescription();
  };
  const isLoading = [beneShowData, beneAnalysisData].some((results) => results.isLoading);
  return (
    !isLoading && (
      <div className={classes.container}>
        <Grid container justifyContent="center" spacing={4}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <>
              <Grid item xs={8}>
                <Typography className={classes.trustTitle} component="span" variant="h3">
                  {beneShowData?.data?.trust?.name}
                </Typography>
                <div
                  style={{
                    marginBottom: '13px',
                    display: 'flex',
                  }}
                >
                  {!beneShowData?.data?.trust?.description ? (
                    <TrueLinkLink onClick={handleOpenTrustDescription} variant="body1">
                      Add a description
                    </TrueLinkLink>
                  ) : (
                    <>
                      <Typography style={{ marginRight: '10px' }} variant="body1">
                        {beneShowData?.data?.trust?.description}
                      </Typography>
                      <TrueLinkLink onClick={handleOpenTrustDescription}>Edit</TrueLinkLink>
                    </>
                  )}
                </div>
                {openTrustDescription && (
                  <EditTrustDescription
                    handleChange={handleChange}
                    handleCloseTrustDescription={handleCloseTrustDescription}
                    handleSubmitDescription={handleSubmitDescription}
                    values={values}
                  />
                )}
                <ManagedAssets
                  beneficiaryId={client.id}
                  beneficiarySlug={client.slug}
                  totalTableState={beneAnalysisData?.data}
                />
                <Accounts
                  accounts={beneShowData?.data?.accounts}
                  beneId={client.slug}
                  beneName={`${beneShowData?.data?.firstName} ${beneShowData?.data?.lastName}`}
                  canAddCardForBene={beneAnalysisData?.data?.canAddCardForBene}
                  cards={beneShowData?.data?.cards}
                />
                <UniqueAssetsCard
                  amount={beneAnalysisData?.data?.uniqueAssetsSum?.amount}
                  beneficiarySlug={client.slug}
                />
              </Grid>
              <Grid item xs={4}>
                <Profile
                  address={beneShowData?.data?.address}
                  age={beneShowData?.data?.age}
                  dateOfBirth={beneShowData?.data?.dob}
                  firstName={beneShowData?.data?.firstName}
                  phone={beneShowData?.data?.mobile}
                />
              </Grid>
            </>
          )}
        </Grid>
      </div>
    )
  );
}

IndividualTrustBeneficiaryOverview.propTypes = {
  client: PropTypes.shape({
    id: PropTypes.number.isRequired,
    slug: PropTypes.string.isRequired,
  }),
};
