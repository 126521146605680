import React from 'react';
import SignupFlowMobileFooter from './signup_flow_mobile_footer';
import SignupFlowMobileHeader from './signup_flow_mobile_header';
import childrenShape from 'react/shared/shapes/children';

function SignupFlowMobileTemplate({ children, ...rest }) {
  return (
    <div>
      <SignupFlowMobileHeader {...rest} />
      {children}
      <SignupFlowMobileFooter {...rest} />
    </div>
  );
}

export default SignupFlowMobileTemplate;

SignupFlowMobileTemplate.propTypes = {
  children: childrenShape.isRequired,
};
