import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

function ApproverInfoSection(props) {
  const { personText, approvalTime, approvalLabel, action } = props;
  let approverSection;
  if (personText) {
    approverSection = (
      <div>
        {personText || 'N/A'}
        {approvalTime ? (
          <div style={{ fontSize: 14, color: '#666' }}>
            {moment(approvalTime).format('MM-DD-YYYY h:mm a')}
          </div>
        ) : undefined}
      </div>
    );
  } else {
    approverSection =
      action === 'requested' || action === 'approved' ? (
        <span className="italic">{`Not yet ${action}`}</span>
      ) : undefined;
  }

  return (
    <div className="new-form-field">
      <div className="new-form__label">{approvalLabel}</div>
      <div className="new-form__data">{approverSection}</div>
    </div>
  );
}

ApproverInfoSection.propTypes = {
  personText: PropTypes.string,
  approvalTime: PropTypes.string,
  action: PropTypes.string,
  approvalLabel: PropTypes.string,
};

export default ApproverInfoSection;
