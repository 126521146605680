import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ClientDisconnectButton from './ClientDisconnectButton';
import ClientDisconnectModal from './ClientDisconnectModal';
import api from 'react/member/card/components/account_tab/api.js';
import { accountShape } from 'react/member/card/shapes/AccountShape';
import SubSectionHeader from 'react/shared/components/SubSectionHeader';

export default function ClientDisconnectSection(props) {
  const [cardDisconnectModalOpen, setCardDisconnectModalOpen] = useState(false);

  const handleOpenDisconnectModal = () => setCardDisconnectModalOpen(true);
  const handleCloseDisconnectModal = () => setCardDisconnectModalOpen(false);

  const handleDisconnectClientCard = () => {
    api
      .postDisconnectClientCard(props.account.slug)
      .then(() => Truelink.flash('success', 'Card has been disconnected!'))
      .finally(() => handleCloseDisconnectModal());
  };

  return (
    <>
      <SubSectionHeader>Card linked to trust account</SubSectionHeader>
      <div className="account_info_section clearfix">
        <p>This card is currently connected to {`${props.personName}'s`} trust account.</p>
        <ClientDisconnectButton onClick={handleOpenDisconnectModal} />
        {cardDisconnectModalOpen && (
          <ClientDisconnectModal
            onClose={handleCloseDisconnectModal}
            onSubmit={handleDisconnectClientCard}
          />
        )}
      </div>
    </>
  );
}

ClientDisconnectSection.propTypes = {
  account: accountShape.isRequired,
  personName: PropTypes.string.isRequired,
};
