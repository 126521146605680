import React, { useState } from 'react';
import PopUp from 'react/shared/components/popups/PopUp';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';

export default function ExpediteShippingModal() {
  const [charged, setCharged] = useState(true);

  const onChangeCharge = (ev) => {
    setCharged(ev.target.value === 'true');
  };

  const onSubmit = (ev) => {
    ev.preventDefault();
    $('input#expedite_charged').attr('value', charged);
    $('#expedite_shipping_modal').hide();
    window.submitCardForm();
  };

  const body = (
    <div>
      <b>Do you want this account to be charged for expediting?</b>
      <div style={{ marginRight: 5 }}>
        <input
          checked={charged}
          id="yes_charge_expedited"
          onChange={onChangeCharge}
          type="radio"
          value
        />
        {' Yes'}
      </div>
      <div style={{ marginRight: 5 }}>
        <input
          checked={!charged}
          id="no_charge_expedited"
          onChange={onChangeCharge}
          type="radio"
          value={false}
        />
        {' No'}
      </div>
    </div>
  );
  const footer = (
    <TrueLinkButton
      className="btn normal"
      id="submit_expedite_charged"
      onClick={onSubmit}
      variant="none"
    >
      Submit
    </TrueLinkButton>
  );

  return (
    <PopUp footer={footer} header="Charge for Expedited Shipping?" maxWidth="750px" openModal>
      {body}
    </PopUp>
  );
}
