import { Field } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

function RadioField({ id, name, value, disabled, className, onChangeCallback, dataAnalyticsKey }) {
  return (
    <Field name={name}>
      {({ field, form }) => (
        <input
          checked={field.value === value}
          className={className}
          data-analytics-key={dataAnalyticsKey}
          disabled={disabled}
          id={id}
          name={field.name}
          onChange={() => {
            form.setFieldValue(field.name, value);
            onChangeCallback && onChangeCallback(value, field.name, form);
          }}
          type="radio"
          value={value}
        />
      )}
    </Field>
  );
}

RadioField.propTypes = {
  className: PropTypes.string,
  dataAnalyticsKey: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChangeCallback: PropTypes.func,
  value: PropTypes.any.isRequired,
};

RadioField.defaultProps = {
  className: '',
  disabled: false,
};

export default RadioField;
