import PropTypes from 'prop-types';

const addressShape = PropTypes.shape({
  street1: PropTypes.string,
  street2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
});

export default addressShape;
