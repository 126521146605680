import PropTypes from 'prop-types';
import React from 'react';
import StyledLabel from 'react/shared/components/forms/StyledLabel';
import childrenShape from 'react/shared/shapes/children';

export default function ConnectionModalLabel(props) {
  return (
    <StyledLabel
      {...props}
      styles={{
        container: 'connection__modal-input-container',
        label: 'connection__modal-input-label bold',
        data: 'connection__modal-input-data',
      }}
    />
  );
}

ConnectionModalLabel.propTypes = {
  labelContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  inputId: PropTypes.string.isRequired,
  children: childrenShape.isRequired,
};
