import { bool, number, string, shape, oneOf } from 'prop-types';
import { bankAccountShapeLite } from 'react/member/card/shapes/BankAccountShape';
import { transferShape } from 'react/member/card/shapes/TransferShape';
import { RECURRING_TYPES } from 'react/member/utils/AutoTransferConstants';

export const autoTransferShape = shape({
  amount: string.isRequired,
  bankAccount: bankAccountShapeLite.isRequired,
  dayOfMonth: number,
  dayOfMonth2: number,
  dayOfWeek: number,
  description: string.isRequired,
  editable: bool.isRequired,
  id: number.isRequired,
  recurringType: oneOf(RECURRING_TYPES).isRequired,
  thresholdAmount: string,
  upcomingHolidayImpactedTransfer: transferShape,
  updatedRecurrenceInformation: bool,
});
