import axios from 'axios';
import BeneficiaryDashboardActions from 'react/member/actions/beneficiary_dashboard_actions';

const BeneficiaryDashboardPayeesStore = flux.createStore({
  actions: [BeneficiaryDashboardActions.fetchPayees, BeneficiaryDashboardActions.createPayee],

  fetchPayees(clientId) {
    axios.get(RailsRoutes.client_payees_path(clientId, { format: 'json' })).then((response) => {
      this.emit('payees.fetch', response.data);
    });
  },

  createPayee(data) {
    const url = RailsRoutes.client_payees_path(data.trust_beneficiary_slug, {
      format: 'json',
    });
    const payeeData = {
      name: data.name,
      needs_1099_form: data.needs1099Form || false,
      client_account_number: data.clientAccountNumber,
      payment_method: data.payment_method,
      memo: data.client_account_number,
      trust_beneficiary_slug: data.trust_beneficiary_slug,
      trust_beneficiary_id: data.trustBeneficiaryId,
      disposition: data.disposition,
      special_instructions: data.specialInstructions,
      bank_account_number: data.bankAccountNumber,
      bank_routing_number: data.bankRoutingNumber,
      swift_code: data.swiftCode,
      address_attributes: data.address_attributes,
    };
    axios.post(url, { payee: payeeData }).then((response) => {
      Truelink.flash('success', 'New payee created successfully');
      this.emit('payees.create', response.data);
    });
  },
});

export default BeneficiaryDashboardPayeesStore;
