import React from 'react';
import TrueLinkNotification from './TrueLinkNotification';

export default {
  title: 'Standard/Notification',
  component: TrueLinkNotification,
  argTypes: {},
};

function Template(args) {
  return <TrueLinkNotification {...args} />;
}

export const WarningNotification = Template.bind({});
WarningNotification.args = {
  callToAction: () => {
    null;
  },
  callToActionText: 'Activate Card',
  subTitle: 'The card should have arrived by 10/11/2023',
  title: 'Card Not Activated',
};
