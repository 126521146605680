import PropTypes from 'prop-types';
import React from 'react';
import DashboardTrustBeneficiaryProfilePrimaryNoteForm from './DashboardTrustBeneficiaryProfilePrimaryNoteForm';
import DashboardActions from 'react/member/actions/dashboard_actions';
import DashboardNoteStore from 'react/member/stores/beneficiary_dashboard/DashboardNoteStore';
import ToggleSectionHeader from 'react/shared/components/ToggleSectionHeader';
import bindAll from 'react/shared/utils/bind_all';

export default class DashboardTrustBeneficiaryProfileQuickAccessNote extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      primaryNote: {},
      currentNoteContent: '',
    };

    bindAll(this);
  }

  componentDidMount() {
    DashboardNoteStore.on('dashboardPrimaryNote.fetch', this.onChangeDashboardPrimaryNote);
    DashboardNoteStore.on('dashboardPrimaryNote.add', this.onChangeDashboardPrimaryNote);
    DashboardNoteStore.on('dashboardPrimaryNote.update', this.onChangeDashboardPrimaryNote);

    DashboardActions.fetchPrimaryNote(this.props.trustBeneficiary.slug);
  }

  componentWillUnmount() {
    DashboardNoteStore.off('dashboardPrimaryNote.fetch', this.onChangeDashboardPrimaryNote);
    DashboardNoteStore.off('dashboardPrimaryNote.add', this.onChangeDashboardPrimaryNote);
    DashboardNoteStore.off('dashboardPrimaryNote.update', this.onChangeDashboardPrimaryNote);
  }

  onChangeDashboardPrimaryNote(primaryNote) {
    const currentNoteContent = primaryNote.note_content;
    this.setState({ primaryNote, currentNoteContent });
  }

  onChangeDashboardPrimaryNoteContent(ev) {
    this.setState({ currentNoteContent: ev.target.value });
  }

  render() {
    const { expanded, toggleExpanded } = this.props;
    return (
      <div className="toggle-section" id="bene-profile-quick-access-note-section">
        <ToggleSectionHeader title="Quick Access Notes" {...{ expanded, toggleExpanded }} />
        {this.props.expanded && (
          <div className="toggle-section-content">
            <div className="subsection-header">
              Use this space to type important notes about the account. You can edit them at any
              time.
            </div>
            <DashboardTrustBeneficiaryProfilePrimaryNoteForm
              noteContent={this.state.currentNoteContent}
              noteId={this.state.primaryNote.id}
              trustBeneficiary={this.props.trustBeneficiary}
            />
          </div>
        )}
      </div>
    );
  }
}

DashboardTrustBeneficiaryProfileQuickAccessNote.propTypes = {
  trustBeneficiary: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    trust: PropTypes.shape({
      organization: PropTypes.shape({}),
    }).isRequired,
  }).isRequired,
  expanded: PropTypes.bool.isRequired,
  toggleExpanded: PropTypes.func.isRequired,
};
