import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { cardShape, accountShape } from './Shape';
import TrueLinkIcon from 'react/shared/components/true_link/main/TrueLinkIcon';
import TrueLinkLink from 'react/shared/components/true_link/main/TrueLinkLink';
import theme from 'react/shared/theme/Theme';
import { asMoney } from 'react/shared/utils/Money';

const useStyles = makeStyles(() => ({
  viewLink: {
    fontWeight: 400,
  },
  viewLinkIcon: {
    marginLeft: theme.spacing(2),
    fontSize: '16px',
  },
  viewCell: {
    paddingRight: 0,
  },
}));

export default function AccountRow({ account, currentCard, beneId }) {
  const classes = useStyles();
  const onViewLink = () =>
    (window.location.href = RailsRoutes.dashboard_client_card_path(beneId, currentCard.id));

  return account.relationships?.currentCard ? (
    <TableRow>
      <TableCell component="th" scope="row">
        <Typography>{currentCard.nickname}'s card</Typography>
      </TableCell>
      <TableCell>
        <Typography>{`Ending in ...${currentCard.lastFour}`}</Typography>
      </TableCell>
      <TableCell align="right">
        <Typography>{currentCard.balance && asMoney(currentCard.balance.amount)}</Typography>
      </TableCell>
      <TableCell align="right" className={classes.viewCell}>
        <Typography className={classes.viewLink} component="span" variant="body2">
          <TrueLinkLink onClick={onViewLink}>
            {'View'}
            <TrueLinkIcon className={classes.viewLinkIcon} icon="chevron-right" />
          </TrueLinkLink>
        </Typography>
      </TableCell>
    </TableRow>
  ) : (
    <TableRow>
      <TableCell component="th" scope="row">
        <Typography>No active card</Typography>
      </TableCell>
    </TableRow>
  );
}

AccountRow.propTypes = {
  currentCard: cardShape,
  account: accountShape,
  beneId: PropTypes.string.isRequired,
};
