import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import childrenShape from 'react/shared/shapes/children';

const useStyles = makeStyles({
  content: (showFooter) => ({
    border: '1px solid #BDBDBD',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    borderBottomLeftRadius: showFooter ? '0' : '10px',
    borderBottomRightRadius: showFooter ? '0' : '10px',
    padding: '24px',
    marginBottom: showFooter ? '0' : '15px',
  }),
  footer: {
    border: '1px solid #BDBDBD',
    borderTop: 'none',
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
    padding: '24px',
    marginBottom: '15px',
  },
});

export default function Section({ children, footer }) {
  const showFooter = !!footer;
  const classes = useStyles(showFooter);

  return (
    <div>
      <div className={classes.content}>{children}</div>
      {showFooter && <div className={classes.footer}>{footer}</div>}
    </div>
  );
}

Section.propTypes = {
  children: childrenShape.isRequired,
  footer: PropTypes.node,
};
