import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import TrueLinkChip from 'react/shared/components/true_link/main/TrueLinkChip';
// Displays a group of radio buttons that can display a Typeahead dropdown list for the active value

const useStyles = makeStyles({
  label: {
    fontSize: '15px',
  },
  chip: {
    marginBottom: '12px',
  },
  root: {
    border: 'none',
  },
});

export default function TrueLinkRadiosWithDropdowns({
  ariaLabel,
  name,
  onRadioChange,
  onDropdownChange,
  value,
  options,
}) {
  const classes = useStyles();
  const radioValues = options.map((option) => option.value);
  const selectedRadioValue = radioValues.includes(value) ? value : '';

  return (
    <FormControl>
      <RadioGroup
        aria-label={ariaLabel}
        name={name}
        onChange={onRadioChange}
        value={selectedRadioValue}
      >
        {options.map((option) => {
          const shouldShowTypeahead = option.value === selectedRadioValue && option.collection;
          return (
            <div key={option.value}>
              <FormControlLabel
                classes={{ root: classes.root, label: classes.label }}
                control={<Radio color={'primary'} />}
                disabled={option.disabled}
                label={option.label}
                value={option.value}
              />
              {option.chipLabel && (
                <TrueLinkChip className={classes.chip} color="primary" label={option.chipLabel} />
              )}
              {shouldShowTypeahead && (
                <Typeahead
                  align="left"
                  id="activeObjectTypeahead"
                  onChange={onDropdownChange}
                  options={option.collection}
                  placeholder={option.placeholder || `Search for a ${selectedRadioValue}`}
                />
              )}
            </div>
          );
        })}
      </RadioGroup>
    </FormControl>
  );
}

TrueLinkRadiosWithDropdowns.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onDropdownChange: PropTypes.func.isRequired,
  onRadioChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      chipLabel: PropTypes.string,
      collection: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
          type: PropTypes.string,
        }),
      ),
      disabled: PropTypes.bool,
      label: PropTypes.string.isRequired,
      placeholder: PropTypes.string,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  value: PropTypes.string.isRequired,
};
