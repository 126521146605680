import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import CardDetails from 'react/member/card/components/account_tab/card_status/CardDetails';
import CardShipment from 'react/member/card/components/account_tab/card_status/CardShipment';
import { CARD_STATUSES } from 'react/member/utils/CardStatus';

export default function Released({
  cardBalance,
  cardBreached,
  cardFboName,
  cardLast4,
  cardName,
  cardShipmentTrackingNumber,
  estimatedArrivalDate,
  expedited,
  expiryDate,
  oldCardLast4,
  shippingAddress,
  status,
}) {
  const formattedDate = moment(estimatedArrivalDate, 'MM/DD/YYYY').format('MMMM Do, YYYY');
  const note = `A replacement card with a new 16-digit number is on its way. It will arrive at your preferred address by ${formattedDate}.

  The card ending in ${oldCardLast4} is permanently closed and cannot be used.`;

  return (
    <>
      <CardDetails
        cardBalance={cardBalance}
        cardBreached={cardBreached}
        cardLast4={cardLast4}
        expiryDate={expiryDate}
        note={note}
        status={status}
      />
      <CardShipment
        cardFboName={cardFboName}
        cardName={cardName}
        cardShipmentTrackingNumber={cardShipmentTrackingNumber}
        expedited={expedited}
        shippingAddress={shippingAddress}
      />
    </>
  );
}

Released.propTypes = {
  cardBalance: PropTypes.number,
  cardBreached: PropTypes.bool,
  cardFboName: PropTypes.string,
  cardLast4: PropTypes.string.isRequired,
  cardName: PropTypes.string.isRequired,
  cardShipmentTrackingNumber: PropTypes.string,
  estimatedArrivalDate: PropTypes.string.isRequired,
  expedited: PropTypes.bool.isRequired,
  expiryDate: PropTypes.string,
  oldCardLast4: PropTypes.string.isRequired,
  shippingAddress: PropTypes.string,
  status: PropTypes.oneOf([CARD_STATUSES.RELEASED_REPLACEMENT_CARD_ORDER_STATUS]).isRequired,
};
