import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import DashboardCard from 'react/member/components/dashboard/clients/dashboard_client/overview/dashboard_card/DashboardCard';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import TrueLinkTooltip from 'react/shared/components/true_link/main/TrueLinkTooltip';
import theme from 'react/shared/theme/Theme';
import PALETTE from 'react/shared/theme/palette';
import { asMoney } from 'react/shared/utils/Money';

const useStyles = makeStyles(() => ({
  spacer: {
    flexGrow: 9999, // Slightly hacky, but it forces content as far to the right as possible
  },
  tooltipLink: {
    borderBottom: `1px dashed ${PALETTE.grey3}`,
  },
  amount: {
    marginRight: theme.spacing(2),
  },
  title: {
    fontSize: '1.25rem',
  },
  card: {
    padding: '24px',
  },
}));

export default function UniqueAssetsCard({ amount, beneficiarySlug }) {
  const classes = useStyles();

  const handleViewLink = () =>
    (window.location.href = RailsRoutes.dashboard_client_unique_assets_path(beneficiarySlug));

  const handleAddAssets = () => {
    window.sessionStorage.setItem('add-asset', true);
    handleViewLink();
  };
  return amount > 0 ? (
    <DashboardCard
      className={classes.card}
      icon="gem fa-regular"
      onViewLink={handleViewLink}
      title={
        <>
          <Typography className={classes.title} component="span" variant="h3">
            Unique Assets
          </Typography>
          <div className={classes.spacer} />
          <div style={{ marginBottom: '3px' }}>
            <TrueLinkTooltip
              tooltipContent={<Typography>Valuation provided by the client</Typography>}
            >
              <Typography
                className={`${classes.amount} ${classes.tooltipLink}`}
                component="span"
                variant="body1"
              >
                {asMoney(amount)}
              </Typography>
            </TrueLinkTooltip>
          </div>
        </>
      }
    />
  ) : (
    <DashboardCard
      className={classes.card}
      icon="gem fa-regular"
      title={
        <>
          <Typography className={classes.title} component="span" variant="h3">
            Unique Assets
          </Typography>
          <div className={classes.spacer} />
          <TrueLinkButton onClick={handleAddAssets} size="small" variant="primaryOutline">
            Add Unique Assets
          </TrueLinkButton>
        </>
      }
    />
  );
}

UniqueAssetsCard.propTypes = {
  amount: PropTypes.number.isRequired,
  beneficiarySlug: PropTypes.string.isRequired,
};
