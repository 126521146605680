import _find from 'underscore/modules/find';
import DashboardActions from 'react/member/actions/dashboard_actions';

const DashboardOrganizationStore = flux.createStore({
  organizations: [],
  actions: [DashboardActions.fetchOrganization],

  fetchOrganization(organization_id) {
    $.get(
      RailsRoutes.dashboard_organization_path(organization_id, { format: 'json' }),
      (organization) => {
        this.organizations.push(organization);
        this.emit('organization.fetch', organization);
      },
    );
  },

  exports: {
    get(slug) {
      return _find(this.organizations, (organization) => organization.slug === slug);
    },
  },
});

export default DashboardOrganizationStore;
