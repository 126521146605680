import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

export default function QuarterlyStatementReports({
  client,
  onSubmitReportRequest,
  quarterlyStatementDates,
}) {
  const [quarterlyStatementDate, setQuarterlyStatementDate] = useState();

  useEffect(() => {
    if (quarterlyStatementDates?.length > 0) {
      setQuarterlyStatementDate(quarterlyStatementDates[0]);
    }
  }, [quarterlyStatementDates]);

  const optionText = (dateString) => {
    const latestStatementEndDate = client.trust.latest_statement_end_date
      ? new Date(client.trust.latest_statement_end_date)
      : null;
    const optionDate = new Date(dateString);
    if (!latestStatementEndDate || latestStatementEndDate < optionDate) {
      return `${dateString} [DRAFT]`;
    }
    return dateString;
  };

  const renderOption = (date, idx) => (
    <option key={idx} value={date}>
      {optionText(date)}
    </option>
  );

  const onQuarterlyStatementDateChange = (event) => {
    setQuarterlyStatementDate(event.target.value);
  };

  const onSubmit = () => {
    const params = {
      report_type: 'Dashboard::GenerateTrustBeneficiariesQuarterlyStatement',
      trust_beneficiary_id: client.id,
      quarterly_statement_date: moment(quarterlyStatementDate).format('YYYY-MM-DD'),
    };
    onSubmitReportRequest(params);
  };

  const renderQuarterlyStatementsSelect = () => {
    let disabled, options;
    if (quarterlyStatementDates && quarterlyStatementDates.length > 0) {
      options = quarterlyStatementDates.map((date, idx) => renderOption(date, idx));
    } else {
      disabled = true;
      options = (
        <option key="N/A" value="N/A">
          No Quarterly Statements
        </option>
      );
    }

    return (
      <table>
        <tbody>
          <tr>
            <td className="bold" style={{ paddingRight: 15 }}>
              Quarterly Statements:{' '}
            </td>
            <td style={{ paddingRight: 15 }}>
              <select
                disabled={disabled}
                name="quarterly_statement_date"
                onChange={onQuarterlyStatementDateChange}
                style={{ marginBottom: 0 }}
              >
                {options}
              </select>
            </td>
            <td>
              <button className="btn normal" onClick={onSubmit} type="button">
                Open
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  return (
    <div>
      <div className="sub_section_header first"> Statements </div>
      {renderQuarterlyStatementsSelect()}
    </div>
  );
}

QuarterlyStatementReports.propTypes = {
  client: PropTypes.shape({
    id: PropTypes.number.isRequired,
    latest_closeout_date: PropTypes.string,
    latest_statement_end_date: PropTypes.string,
    trust: PropTypes.shape({
      latest_statement_end_date: PropTypes.string,
      is_pooled: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
  onSubmitReportRequest: PropTypes.func.isRequired,
  quarterlyStatementDates: PropTypes.arrayOf(PropTypes.string),
};
