import PropTypes from 'prop-types';
import React from 'react';
import FamilyFunderForm from './FamilyFunderForm';
import FamilyFunderInvite from './FamilyFunderInvite';
import LoadingIndicator from 'react/shared/components/LoadingIndicator';
import PopUp from 'react/shared/components/popups/PopUp';
import TrueLinkLink from 'react/shared/components/true_link/main/TrueLinkLink';
import bindAll from 'react/shared/utils/bind_all';

export default class FamilyFunders extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      familyFunderInvites: [],
      isLoading: true,
      isModalOpen: false,
    };

    bindAll(this);
  }

  componentDidMount() {
    this.loadFamilyFunderInvites();
  }

  toggleModal() {
    this.setState((prevState) => ({ isModalOpen: !prevState.isModalOpen }));
  }

  closeModal() {
    this.setState({ isModalOpen: false });
  }

  loadFamilyFunderInvites() {
    $.get(RailsRoutes.dashboard_family_funder_invites_path(), (data) => {
      this.setState({
        familyFunderInvites: data.family_funder_invites,
        isLoading: false,
      });
    });
  }

  refreshInvites() {
    this.loadFamilyFunderInvites();
  }

  renderContent() {
    if (this.state.isLoading) {
      return <LoadingIndicator />;
    }

    const { cardholderName } = this.props;
    const { familyFunderInvites } = this.state;
    if (cardholderName == 'Valued Customer') {
      return (
        <div className="info-box">
          In order to invite a family member to fund the card, please activate this card.
        </div>
      );
    } else if (familyFunderInvites.length > 0) {
      return familyFunderInvites.map((invite) => (
        <FamilyFunderInvite invite={invite} key={invite.id} onInviteDelete={this.refreshInvites} />
      ));
    }
    return (
      <div className="ff-no-funders">
        <span>
          No Family Funders.{' '}
          <TrueLinkLink onClick={this.toggleModal}>Add a Family Funder</TrueLinkLink>
        </span>
      </div>
    );
  }

  render() {
    const { cardholderName } = this.props;
    const showForm = cardholderName != 'Valued Customer';
    return (
      <div>
        <div className="sub_section_header" style={{ marginBottom: 0 }}>
          Family Funder
          {showForm && (
            <div className="pull-right" style={{ fontSize: '18px' }}>
              <TrueLinkLink onClick={this.toggleModal}>+ Add Family Funder</TrueLinkLink>
            </div>
          )}
        </div>
        <div className="account_info_section">
          {this.renderContent()}
          <div className="ff-about">
            A card's Family Funder can log in, connect a bank account and add funds to this True
            Link Card.
          </div>
        </div>
        {showForm && (
          <PopUp
            header="Add Family Funder"
            hideFooter
            maxWidth="450px"
            onClose={this.closeModal}
            openModal={this.state.isModalOpen}
          >
            <FamilyFunderForm
              refreshFamilyFunders={this.loadFamilyFunderInvites}
              toggleModal={this.toggleModal}
            />
          </PopUp>
        )}
      </div>
    );
  }
}

FamilyFunders.propTypes = {
  cardholderName: PropTypes.string,
};
