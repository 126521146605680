import PropTypes from 'prop-types';
import React from 'react';
import DashboardActions from 'react/member/actions/dashboard_actions';
import DashboardDepositsTabContent from 'react/member/components/dashboard/deposits/DashboardDepositsTabContent';
import DisbursementTabsWrapper from 'react/member/components/dashboard/disbursements/tab_content/DisbursementTabsWrapper';
import DashboardHoldingEventIndex from 'react/member/components/dashboard/holding_events/DashboardHoldingEventIndex';
import DashboardPortfolioPooledTrustAssets from 'react/member/components/dashboard/portfolios/DashboardPortfolioPooledTrustAssets';
import { originTypes } from 'react/member/components/dashboard/reports/Constants';
import Reports from 'react/member/components/dashboard/reports/Reports';
import userRoleShape from 'react/member/shapes/UserRoleShape';
import DashboardOrganizationStore from 'react/member/stores/DashboardOrganizationStore';
import TabContent from 'react/shared/components/TabContent';
import TabNavigation from 'react/shared/components/TabNavigation';
import bindAll from 'react/shared/utils/bind_all';

export default class DashboardPortfolio extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentClient: {},
      organization: {},
    };

    bindAll(this);
  }

  getChildContext() {
    return {
      portfolio: this.props.portfolio,
      organization: this.state.organization,
    };
  }

  componentDidMount() {
    DashboardOrganizationStore.on('organization.fetch', this.onChangeOrganization);

    DashboardActions.fetchOrganization(this.props.portfolio.organization.slug);
  }

  componentWillUnmount() {
    DashboardOrganizationStore.on('organization.fetch', this.onChangeOrganization);
  }

  isClientSelected() {
    return !!this.state.currentClient.id;
  }

  onChangeOrganization(organization) {
    return this.setState({ organization });
  }

  portfolioTrustAssetsTab() {
    return (
      <TabContent id="portfolio-trust-assets-tab" key="portfolio-trust-assets-tab">
        <DashboardPortfolioPooledTrustAssets />
      </TabContent>
    );
  }

  portfolioUnitTransactionsTab() {
    return (
      <TabContent id="portfolio-unit-transactions-tab" key="portfolio-unit-transactions-tab">
        <DashboardHoldingEventIndex portfolio={this.props.portfolio} />
      </TabContent>
    );
  }

  portfolioDisbursementsTab() {
    return (
      <TabContent id="portfolio-disbursements-tab" key="portfolio-disbursements-tab">
        <DisbursementTabsWrapper
          disbursementsFor={'Portfolio'}
          displayedPaymentMethods={this.props.portfolio.trust.displayed_payment_types}
          organizationSlug={this.props.portfolio.organization.slug}
          portfolioId={this.props.portfolio.id}
          renderOnHoldDisbursements={this.props.renderOnHoldDisbursements}
          userRoles={this.props.userRoles}
        />
      </TabContent>
    );
  }

  portfolioDepositsTab() {
    return (
      <TabContent id="portfolio-deposits-tab" key="portfolio-deposits-tab">
        <DashboardDepositsTabContent
          portfolio={this.props.portfolio}
          userRoles={this.props.userRoles}
        />
      </TabContent>
    );
  }

  portfolioReportsTab() {
    const { availableCustomStatementDates, portfolio, quarterlyStatementDates } = this.props;
    const origin = {
      id: portfolio.slug,
      name: portfolio.name,
      type: originTypes.portfolio,
      customStatementDates: availableCustomStatementDates,
      latestTrustStatementEndDate: portfolio.trust.latest_statement_end_date,
      quarterlyStatementDates,
    };
    return (
      <TabContent id="client-reports-tab" key="client-reports-tab">
        <Reports origin={origin} />
      </TabContent>
    );
  }

  tabContentList() {
    return [
      this.portfolioTrustAssetsTab(),
      this.portfolioUnitTransactionsTab(),
      this.portfolioDisbursementsTab(),
      this.portfolioDepositsTab(),
      this.portfolioReportsTab(),
    ];
  }

  tabList() {
    return [
      {
        id: 'portfolio-trust-assets-tab',
        title: 'Trust Assets',
        type: 'choseTrustAssets',
      },
      {
        id: 'portfolio-unit-transactions-tab',
        title: 'Unit Transactions',
        type: 'choseUnitTransactions',
      },
      {
        id: 'portfolio-disbursements-tab',
        title: 'Disbursements',
        type: 'choseDisbursements',
      },
      {
        id: 'portfolio-deposits-tab',
        title: 'Deposits',
        type: 'choseDeposits',
      },
      { id: 'portfolio-reports-tab', title: 'Reports', type: 'choseReports' },
    ];
  }

  render() {
    return (
      <div className="widescreen-inner-container">
        <TabNavigation showBackground tabs={this.tabList()}>
          {this.tabContentList()}
        </TabNavigation>
      </div>
    );
  }
}

DashboardPortfolio.propTypes = {
  availableCustomStatementDates: PropTypes.shape({
    minDate: PropTypes.string,
    maxDate: PropTypes.string,
    startDate: PropTypes.string,
  }).isRequired,
  portfolio: PropTypes.object.isRequired,
  renderOnHoldDisbursements: PropTypes.bool,
  quarterlyStatementDates: PropTypes.array.isRequired,
  userRoles: userRoleShape.isRequired,
};

DashboardPortfolio.defaultProps = {
  renderOnHoldDisbursements: false,
};

DashboardPortfolio.childContextTypes = {
  portfolio: PropTypes.object,
  organization: PropTypes.object,
};
