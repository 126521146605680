import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import React from 'react';
import UserAccessCodeValidator from 'react/jaffe/components/users/UserAccessCodeValidator';
import UserProfile from 'react/member/components/dashboard/UserProfile';
import theme from 'react/shared/theme/Theme';

const rootComponentMapping = {
  UserProfile,
  UserAccessCodeValidator,
};

export default function RootComponentWrapper({ childClass, childProps }) {
  const queryClient = new QueryClient();
  const ChildClass = rootComponentMapping[childClass];

  return (
    <QueryClientProvider client={queryClient}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <ChildClass {...childProps} />
        </ThemeProvider>
      </StyledEngineProvider>
    </QueryClientProvider>
  );
}

RootComponentWrapper.propTypes = {
  childClass: PropTypes.string.isRequired,
  childProps: PropTypes.object.isRequired,
};
