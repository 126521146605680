import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import DashboardActions from 'react/member/actions/dashboard_actions';
import api from 'react/member/card/components/funding_tab/api';
import TransferDescriptionLite from 'react/member/card/components/funding_tab/transfers_table/TransferDescriptionLite';
import TransferIconCell from 'react/member/card/components/funding_tab/transfers_table/TransferIconCell';
import { transferShape } from 'react/member/card/shapes/TransferShape';
import PopUp from 'react/shared/components/popups/PopUp';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';

const useStyles = makeStyles({
  subHeader: {
    fontSize: '16px',
    paddingLeft: '10px',
  },
  cell: {
    display: 'flex',
  },
  icon: {
    width: '20px',
    paddingTop: '20px',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  description: {
    paddingTop: '20px',
    paddingLeft: '30px',
  },
});

export default function RevertToOriginalModal({ transfer, onClose }) {
  const classes = useStyles();

  const header = 'Revert this transfer';
  const subheader = 'Are you sure you want to revert this transfer to the original date?';

  const handleRevertTransferDate = () => {
    api
      .patchApplyTransferGuidance([{ guidance_accepted: 'false', transfer_ids: [transfer.id] }])
      .then(() => {
        onClose();
        const formattedDate = moment(transfer.revertDate).format('dddd, MMMM D');
        const msg = `The $${transfer.amount} transfer has been reverted to ${formattedDate}`;
        DashboardActions.fetchScheduledTransfers();
        DashboardActions.reloadHolidayBanner();
        Truelink.flash('success', msg);
      });
  };

  const footer = (
    <TrueLinkButton className="btn normal" onClick={handleRevertTransferDate} variant="none">
      Confirm
    </TrueLinkButton>
  );

  return (
    <PopUp footer={footer} header={header} maxWidth="580px" modalOpen onClose={onClose}>
      <div>
        <div className={classes.subHeader}>{subheader}</div>
        <div className={classes.cell}>
          <div className={classes.icon}>
            <TransferIconCell
              className={'fa-triangle-exclamation'}
              color={'#FC824E'}
              tooltipOn={false}
            />
          </div>
          <div className={classes.description}>
            <TransferDescriptionLite alternateDate={transfer.revertDate} item={transfer} />
          </div>
        </div>
      </div>
    </PopUp>
  );
}

RevertToOriginalModal.propTypes = {
  transfer: transferShape.isRequired,
  onClose: PropTypes.func.isRequired,
};
