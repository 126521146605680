import PropTypes from 'prop-types';
import React from 'react';
import { asMoney } from 'react/shared/utils/Money';

// Note: when the `available` prop in this component is 0.00, this infers that it is being used to display
// the balance for the "Other" category. The string "$0.00" still gets rendered. This is in contrast to when
// `undefined` is supplied as the value, where a dash is rendered instead.
export default function CategorizedBudgetDollarsUsed({ used, available }) {
  const formatAmount = (amount) => {
    if (amount || amount === 0) {
      return asMoney(amount);
    }
    return '-';
  };

  const formatUsedClass = () => {
    if (typeof used === 'undefined' || typeof available === 'undefined' || available === 0) {
      return '';
    }

    return used < available ? 'text-success' : 'text-error';
  };

  const formatAvailableClass = () => (available === 0 ? 'gray-text' : '');

  return (
    <div>
      <span className={formatUsedClass()}>{formatAmount(used)}</span>
      <span>&nbsp;&nbsp;/&nbsp;&nbsp;</span>
      <span className={formatAvailableClass()}>{formatAmount(available)}</span>
    </div>
  );
}

CategorizedBudgetDollarsUsed.propTypes = {
  used: PropTypes.number,
  available: PropTypes.number,
};
