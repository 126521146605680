import PropTypes from 'prop-types';
import React from 'react';
import CardDetails from 'react/member/card/components/account_tab/card_status/CardDetails';
import { CARD_STATUSES } from 'react/member/utils/CardStatus';

export default function Closed({
  cardBalance,
  cardBreached,
  cardExpiryDate,
  cardLast4,
  hasOpenCardCloseRequest,
  status,
}) {
  const note = hasOpenCardCloseRequest ? 'A request to close this card is being processed.' : null;
  return (
    <CardDetails
      cardBalance={cardBalance}
      cardBreached={cardBreached}
      cardLast4={cardLast4}
      expiryDate={cardExpiryDate}
      note={note}
      status={status}
    />
  );
}

Closed.propTypes = {
  cardBalance: PropTypes.number,
  cardBreached: PropTypes.bool,
  cardExpiryDate: PropTypes.string,
  cardLast4: PropTypes.string.isRequired,
  hasOpenCardCloseRequest: PropTypes.bool.isRequired,
  status: PropTypes.oneOf([CARD_STATUSES.CLOSED_CARD_STATUS]).isRequired,
};
