import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import UpdateNicknameModal from './UpdateNicknameModal';
import { cardholderShape } from 'react/member/card/shapes/CardholderShape';
import TrueLinkLink from 'react/shared/components/true_link/main/TrueLinkLink';

const useStyles = makeStyles({
  editNickname: {
    paddingRight: '15px',
  },
});

export default function CardholderNickname({ accountId, cardholder }) {
  const classes = useStyles();
  const [isModalOpen, setModalOpen] = useState(false);
  const [nickname, setNickname] = useState(cardholder.nickname);

  const handleToggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  return (
    <>
      <p>
        <span className={classNames('nickname', { [classes.editNickname]: nickname })}>
          {nickname}
        </span>
        <TrueLinkLink onClick={handleToggleModal}>
          {nickname ? 'Edit' : 'Add an account id'}
        </TrueLinkLink>
      </p>
      {isModalOpen && (
        <UpdateNicknameModal
          accountId={accountId}
          cardholderName={cardholder.name}
          cardholderNickname={nickname}
          setNickname={setNickname}
          toggleModal={handleToggleModal}
        />
      )}
    </>
  );
}

CardholderNickname.propTypes = {
  accountId: PropTypes.string.isRequired,
  cardholder: cardholderShape,
};
