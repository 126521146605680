import PropTypes from 'prop-types';
import { CardInfoV2Shape } from './CardShape';

export default PropTypes.shape({
  type: PropTypes.string.isRequired,
  id: PropTypes.string,
  attributes: PropTypes.shape({
    amount: PropTypes.shape({
      amount: PropTypes.number,
      currency: PropTypes.string,
    }),
    timestamp: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    category: PropTypes.string,
    block: PropTypes.shape({ explanation: PropTypes.string }),
    isReversal: PropTypes.bool,
    location: PropTypes.string,
    settled: PropTypes.bool.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    permissions: PropTypes.shape({
      edit: PropTypes.bool.isRequired,
    }),
    card: CardInfoV2Shape,
  }).isRequired,
});
