import axios from 'axios';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import ConnectPlaidBankAccountModal from 'react/member/components/plaid/ConnectPlaidBankAccountModal';
import DashboardBankAccountStore from 'react/member/stores/DashboardBankAccountStore';
import { noWeekendsOrHolidays } from 'react/member/utils/Signup';

export default class FamilyFunderSignupConnectBankAccount extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      buttonActive: true,
      modalActive: false,
      soonestTransferDate: null,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.completeConnectBankAccount = this.completeConnectBankAccount.bind(this);
  }

  componentDidMount() {
    DashboardBankAccountStore.on('bankAccount.create', this.completeConnectBankAccount);
    this.soonestTransferDate();
  }

  componentWillUnmount() {
    DashboardBankAccountStore.off('bankAccount.create', this.completeConnectBankAccount);
  }

  soonestTransferDate() {
    axios
      .get(RailsRoutes.soonest_transfer_date_dashboard_transfers_path({ format: 'json' }))
      .then(({ data }) => {
        this.setState({
          soonestTransferDate: data.boday,
        });
      });
  }

  openModal(e) {
    e.preventDefault();
    this.setState({ modalActive: true });
  }

  closeModal() {
    this.setState({ modalActive: false });
    this.soonestTransferDate();
  }

  completeConnectBankAccount(bankAccount) {
    Truelink.flash('success', 'Your bank account has been saved.');
    this.setState({ buttonActive: false, modalActive: false });

    // Falling back to jQuery land and manually updating the summary section
    const formSelector = $('form.card--form__right__body');
    formSelector.find('input#bank_account_id_field').val(bankAccount.account_id);
    formSelector.find('span#transfer-bank-name').text(bankAccount.institution_name);
    formSelector.find('span#transfer-account-name').text(bankAccount.name);
    formSelector.find('span#transfer-account-number').text(bankAccount.number);
    $('.card--form__section.summary').show();

    // Triggering the next step in the sign up flow
    $('.card--form__left__body.hide-on-complete').hide();
    $('#create-transfer-section').removeClass('section-unstarted');
    const transferDatePicker = $('.transfer_datepicker');
    transferDatePicker.datepicker('destroy');
    transferDatePicker.datepicker({
      dateFormat: 'yy-mm-dd',
      beforeShowDay: noWeekendsOrHolidays,
      minDate: moment(this.state.soonestTransferDate).toDate(),
      maxDate: '+1Y',
    });
  }

  render() {
    return (
      <div>
        {this.state.buttonActive && (
          <button
            className="btn-new btn-new--large btn-new--green"
            id="connect_bank_account"
            onClick={this.openModal}
            type="button"
          >
            Connect a bank account
          </button>
        )}
        {this.state.modalActive && (
          <ConnectPlaidBankAccountModal
            mockPublicToken={this.props.mockPublicToken}
            onCancel={this.closeModal}
          />
        )}
      </div>
    );
  }
}

FamilyFunderSignupConnectBankAccount.propTypes = {
  mockPublicToken: PropTypes.string,
};
