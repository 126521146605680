import moment from 'moment-timezone';
import React from 'react';
import PreRelease from './display/backup_card/PreRelease';
import Section from 'react/member/card/components/account_tab/card_status/Section';
import { accountShape } from 'react/member/card/shapes/AccountShape';
import { cardOrderShape } from 'react/member/card/shapes/CardOrderShape';
import { cardShape } from 'react/member/card/shapes/CardShape';
import { CARD_STATUSES } from 'react/member/utils/CardStatus';
import { programNameFromType } from 'react/shared/utils/account_program_type';

export default function BackupCardReissueStatus({ account, card, cardReissue }) {
  const cardType = programNameFromType(account.program_type);
  const { estimatedArrival } = cardReissue;
  const range = estimatedArrival.range;
  const formattedArrivalDate = moment(estimatedArrival.arrivalDate, 'MM/DD/YYYY').format(
    'MMMM Do, YYYY',
  );

  return (
    <Section>
      <PreRelease
        cardType={cardType}
        estimatedArrivalRange={range}
        formattedArrivalDate={formattedArrivalDate}
        oldCardLast4={card.card_last_4}
        status={CARD_STATUSES.PRE_RELEASE_BACKUP_CARD_ORDER_STATUS}
      />
    </Section>
  );
}

BackupCardReissueStatus.propTypes = {
  account: accountShape.isRequired,
  card: cardShape,
  cardReissue: cardOrderShape.isRequired,
};
