import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import TransferOptionsCell from './TransferOptionsCell';
import DashboardActions from 'react/member/actions/dashboard_actions';
import api from 'react/member/card/components/funding_tab/api';
import RecurringTransferEditOptionsContainer from 'react/member/card/components/funding_tab/recurring_transfer_edit_wizard/RecurringTransferEditOptionsContainer';
import RevertToOriginalModal from 'react/member/card/components/funding_tab/transfers/RevertToOriginalModal';
import TransferEmergencyFundModal from 'react/member/card/components/funding_tab/transfers/TransferEmergencyFundModal';
import TransferFundsModal from 'react/member/card/components/funding_tab/transfers/TransferFundsModal';
import TransferSkipCancelUnskipModal from 'react/member/card/components/funding_tab/transfers/TransferSkipCancelUnskipModal';
import TransferUpsertModal from 'react/member/card/components/funding_tab/transfers/TransferUpsertModal';
import SingleTransferHolidayContainer from 'react/member/card/components/holidays/SingleTransferHolidayContainer';
import { transferShape } from 'react/member/card/shapes/TransferShape';
import { isRecurring } from 'react/member/utils/Transfer';

export default function TransferOptionsContainer({
  cardLast4,
  cardStatus,
  cardholderName,
  emergencyFundingEnabled,
  fundingType,
  isEffectivelyFullyFunded,
  isFullyFunded,
  isViewOnly,
  transfer,
}) {
  // Hooks
  // -----------------------------------------------------
  const [showEmergencyFundModal, setEmergencyFundModal] = useState(false);
  const [showSkipCancelModal, setSkipCancelModal] = useState(false);
  const [showUnskipModal, setUnskipModal] = useState(false);
  const [showTransferFundsModal, setShowTransferFundsModal] = useState(false);
  const [showOneTimeTransferUpsertModal, setOneTimeTransferUpsertModal] = useState(false);
  const [showRecurringTransferEditModal, setRecurringTransferEditModal] = useState(false);
  const [submittingEmergencyFund, setSubmittingEmergencyFund] = useState(false);
  const [showImpactedTransferModal, setImpactedTransferModal] = useState(false);
  const [showRevertToOriginalModal, setRevertToOriginalModal] = useState(false);
  const editTransfersFlag = $tlf?.flipperEnableEditTransfersFlag;
  // Guards
  // -----------------------------------------------------
  if (isViewOnly) return null;

  // Handlers
  // -----------------------------------------------------
  // TransferEmergencyFundModal
  const handleOpenEmergencyFundModal = () => setEmergencyFundModal(true);
  const handleCloseEmergencyFundModal = () => setEmergencyFundModal(false);
  const handleEmergencyFundTransfer = () => {
    setSubmittingEmergencyFund(true);
    api
      .instantFundTransfer(transfer.id)
      .then((response) => {
        handleCloseEmergencyFundModal();
        DashboardActions.fetchScheduledTransfers();
        DashboardActions.reloadHolidayBanner();
        Truelink.flash('success', response.data.message, true, true);
      })
      .catch((_err) => handleCloseEmergencyFundModal())
      .finally(() => setSubmittingEmergencyFund(false));
  };

  // TransferSkipCancelUnskipModal
  const handleOpenSkipCancelModal = () => setSkipCancelModal(true);
  const handleCloseSkipCancelModal = () => setSkipCancelModal(false);
  const handleSkipCancelTransfer = () => {
    api.toggleTransferCancellation(transfer.id).then(() => {
      handleCloseSkipCancelModal();
      DashboardActions.fetchScheduledTransfers();
      DashboardActions.reloadHolidayBanner();
      const formattedDate = moment(transfer.dateUnformatted).format('dddd, MMMM D');
      const action = isRecurring(transfer) ? 'skipped' : 'cancelled';
      const msg = `The $${transfer.amount} transfer on ${formattedDate} has been ${action}`;
      Truelink.flash('success', msg, true, true);
    });
  };

  // TransferSkipCancelUnskipModal
  const handleOpenUnskipModal = () => setUnskipModal(true);
  const handleCloseUnskipModal = () => setUnskipModal(false);
  const handleUnskipTransfer = () => {
    api.toggleTransferCancellation(transfer.id).then(() => {
      handleCloseUnskipModal();
      DashboardActions.fetchScheduledTransfers();
      DashboardActions.reloadHolidayBanner();
      const formattedDate = moment(transfer.dateUnformatted).format('dddd, MMMM D');
      const msg = `The $${transfer.amount} transfer on ${formattedDate} will be resumed`;
      Truelink.flash('success', msg, true, true);
    });
  };

  // TransferUpsertModal -> submit handler located inside modal
  const handleOpenOneTimeTransferUpsertModal = () => setOneTimeTransferUpsertModal(true);
  const handleCloseOneTimeTransferUpsertModal = () => setOneTimeTransferUpsertModal(false);
  const handleOpenTransferFundsModal = () => setShowTransferFundsModal(true);
  const handleCloseTransferFundsModal = () => setShowTransferFundsModal(false);

  // RecurringTransferEditOptionsContainer -> submit handler located inside modal
  const handleOpenRecurringTransferEditModal = () => setRecurringTransferEditModal(true);
  const handleCloseRecurringTransferEditModal = () => setRecurringTransferEditModal(false);

  // SingleTransferHolidayContainer
  const handleOpenImpactedTransferModal = () => setImpactedTransferModal(true);
  const handleCloseImpactedTransferModal = () => {
    setImpactedTransferModal(false);
    DashboardActions.fetchScheduledTransfers();
    DashboardActions.reloadHolidayBanner();
  };

  // RevertToOriginalModal
  const handleOpenRevertToOriginalModal = () => setRevertToOriginalModal(true);
  const handleCloseRevertToOriginalModal = () => setRevertToOriginalModal(false);

  const handleOpenOneTimeTransfer = () => {
    editTransfersFlag ? handleOpenTransferFundsModal() : handleOpenOneTimeTransferUpsertModal();
  };

  return (
    <>
      <TransferOptionsCell
        isFullyFunded={isFullyFunded}
        onEditOneTime={handleOpenOneTimeTransfer}
        onEditRecurring={handleOpenRecurringTransferEditModal}
        onEmergencyFund={handleOpenEmergencyFundModal}
        onFixHolidayImpact={handleOpenImpactedTransferModal}
        onRevert={handleOpenRevertToOriginalModal}
        onSkipCancel={handleOpenSkipCancelModal}
        onUnskip={handleOpenUnskipModal}
        transfer={transfer}
      />

      {showTransferFundsModal && (
        <TransferFundsModal
          bankAccount={transfer.bankAccount}
          cardStatus={cardStatus}
          cardholderName={cardholderName}
          emergencyFundingEnabled={emergencyFundingEnabled}
          fundingType={fundingType}
          isEffectivelyFullyFunded={isEffectivelyFullyFunded}
          onClose={handleCloseTransferFundsModal}
          onSuccess={handleCloseTransferFundsModal}
          transfer={transfer}
        />
      )}
      {showOneTimeTransferUpsertModal && !isRecurring(transfer) && (
        <TransferUpsertModal
          bankAccount={transfer.bankAccount}
          edit
          isEffectivelyFullyFunded={isEffectivelyFullyFunded}
          isFullyFunded={isFullyFunded}
          onClose={handleCloseOneTimeTransferUpsertModal}
          transfer={transfer}
        />
      )}
      {showRecurringTransferEditModal && isRecurring(transfer) && (
        <RecurringTransferEditOptionsContainer
          cardStatus={cardStatus}
          cardholderName={cardholderName}
          emergencyFundingEnabled={emergencyFundingEnabled}
          fundingType={fundingType}
          onClose={handleCloseRecurringTransferEditModal}
          transfer={transfer}
        />
      )}
      {showEmergencyFundModal && (
        <TransferEmergencyFundModal
          cardLast4={cardLast4}
          onClose={handleCloseEmergencyFundModal}
          onSubmit={handleEmergencyFundTransfer}
          submitting={submittingEmergencyFund}
          transfer={transfer}
        />
      )}
      {showSkipCancelModal && (
        <TransferSkipCancelUnskipModal
          onClose={handleCloseSkipCancelModal}
          onConfirm={handleSkipCancelTransfer}
          transfer={transfer}
        />
      )}
      {showUnskipModal && isRecurring(transfer) && (
        <TransferSkipCancelUnskipModal
          onClose={handleCloseUnskipModal}
          onConfirm={handleUnskipTransfer}
          transfer={transfer}
        />
      )}
      {showImpactedTransferModal && (
        <SingleTransferHolidayContainer
          holidayImpactedTransfer={transfer}
          onClose={handleCloseImpactedTransferModal}
        />
      )}
      {showRevertToOriginalModal && (
        <RevertToOriginalModal onClose={handleCloseRevertToOriginalModal} transfer={transfer} />
      )}
    </>
  );
}

TransferOptionsContainer.propTypes = {
  cardLast4: PropTypes.string,
  cardStatus: PropTypes.string,
  cardholderName: PropTypes.string,
  emergencyFundingEnabled: PropTypes.bool,
  fundingType: PropTypes.string,
  isEffectivelyFullyFunded: PropTypes.bool.isRequired,
  isFullyFunded: PropTypes.bool.isRequired,
  isViewOnly: PropTypes.bool.isRequired,
  transfer: transferShape.isRequired,
};
