import axios from 'axios';
import _extend from 'underscore/modules/extend';
import _keys from 'underscore/modules/keys';
import DashboardActions from 'react/member/actions/dashboard_actions';

const DashboardRulesetStore = flux.createStore({
  notificationPrefs: {},
  contactInfo: {},
  additionalEmails: [],
  userEmails: [],
  programName: '',
  allowsFamilyFunder: false,
  showClosedCardCheckbox: false,
  warnRules: {},

  actions: [
    DashboardActions.fetchAccountInfo,
    DashboardActions.fetchRulesetWarnRules,
    DashboardActions.fetchOrganizationInfo,
    DashboardActions.addAdditionalEmail,
    DashboardActions.removeAdditionalEmail,
    DashboardActions.updateNotificationPreference,
    DashboardActions.updateMobileForNotification,
  ],

  fetchAccountInfo(account_id) {
    const url = RailsRoutes.dashboard_account_path({
      id: account_id,
      format: 'json',
    });

    $.get(url, (data) => {
      this.notificationPrefs = data.data.attributes.notificationPreferences;
      this.contactInfo = data.data.meta.contactInfo || {};
      this.additionalEmails = data.data.attributes.additionalEmails;
      this.programName = data.data.meta.locale.programName;
      this.allowsFamilyFunder = data.data.meta.allowsFamilyFunder;
      this.showClosedCardCheckbox = data.data.meta.showClosedCardCheckbox;
      this.emit('accountInfo.fetch');
    });
  },

  fetchRulesetWarnRules(account_id) {
    const url = RailsRoutes.dashboard_account_ruleset_warn_rules_path({
      account_id,
      format: 'json',
    });

    $.get(url, (data) => {
      this.warnRules = data.warnRules;
      this.emit('rulesetWarnRules.fetch');
    });
  },

  fetchOrganizationInfo(account_id) {
    const url = RailsRoutes.dashboard_account_find_organization_path({
      account_id,
      format: 'json',
    });

    $.get(url, (data) => {
      this.userEmails = data.data.meta.userEmails;
      this.emit('organizationInfo.fetch');
    });
  },

  updateNotificationPreference(accountId, changedPref) {
    const url = RailsRoutes.dashboard_account_notification_preferences_path(accountId, {
      format: 'json',
    });

    $.ajax({
      data: changedPref,
      url,
      method: 'POST',
    }).done((response) => {
      this.notificationPrefs = response.notification_preferences;
      const pref = _keys(changedPref)[0];
      this.emit(`account.notificationPreferences.${pref}.updated`);
      this.emit('account.notificationPreferences.updated');
      Truelink.flash('success', 'Notification Preferences updated!', false, true);
    });
  },

  addAdditionalEmail(accountId, newAdditionalEmail) {
    const url = RailsRoutes.dashboard_additional_emails_path(accountId, {
      format: 'json',
    });
    const formData = [
      {
        name: 'account[email]',
        value: newAdditionalEmail,
      },
      {
        name: 'change',
        value: 'add',
      },
    ];

    $.ajax({
      data: formData,
      url,
      method: 'PUT',
    })
      .done((response) => {
        Truelink.flash('success', 'Email address added!', false, true);
        this.additionalEmails = response.result;
        this.emit('account.additionalEmails.updated');
      })
      .fail(() => {
        this.emit('account.additionalEmails.error');
      });
  },

  removeAdditionalEmail(accountId, existingAdditionalEmail) {
    const url = RailsRoutes.dashboard_additional_emails_path(accountId, {
      format: 'json',
    });

    const formData = [
      {
        name: 'account[email]',
        value: existingAdditionalEmail,
      },
      {
        name: 'change',
        value: 'remove',
      },
    ];

    $.ajax({
      data: formData,
      url,
      method: 'PUT',
    }).done((response) => {
      Truelink.flash('success', 'Email address removed!', false, true);
      this.additionalEmails = response.result;
      this.emit('account.additionalEmails.updated');
    });
  },

  updateMobileForNotification(accountSlug, newMobile) {
    const url = RailsRoutes.dashboard_update_mobile_path(accountSlug, {
      format: 'json',
    });
    const params = {
      person: {
        mobile: newMobile,
      },
    };

    axios
      .put(url, params)
      .then((arg) => {
        const data = arg.data;
        this.contactInfo = _extend(this.contactInfo, {
          mobile: data.mobile,
        });
        this.emit('person.mobile.updated');
        Truelink.flash('success', 'Update successful!');
      })
      .catch(() => {
        this.emit('person.mobile.updated.error');
      });
  },

  exports: {
    getNotificationPrefs() {
      return this.notificationPrefs;
    },
    getContactInfo() {
      return this.contactInfo;
    },
    updateWarnRules(warnRules) {
      this.warnRules = warnRules;
      return this.emit('warnRules.updated');
    },
    getAdditionalEmails() {
      return this.additionalEmails;
    },
    getUserEmails() {
      return this.userEmails;
    },
    getProgramName() {
      return this.programName;
    },
    getAllowsFamilyFunder() {
      return this.allowsFamilyFunder;
    },
    getShowClosedCardCheckbox() {
      return this.showClosedCardCheckbox;
    },
    getWarnRules() {
      return this.warnRules;
    },
  },
});

export default DashboardRulesetStore;
