import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import DashboardActions from 'react/member/actions/dashboard_actions';
import trustRemainderBeneficiaryShape from 'react/member/shapes/trust_remainder_beneficiary_shape';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';

export default function DashboardTrustBeneficiaryProfileTrustRemainderBeneficiariesDestroyForm({
  trustRemainderBeneficiary,
  trustBeneficiary,
}) {
  const [isSubmitting, updateSubmitting] = useState(false);
  const onSubmit = (ev) => {
    ev.preventDefault();
    updateSubmitting(true);
    const onRequestCompleted = () => {
      updateSubmitting(false);
    };
    DashboardActions.destroyTrustRemainderBeneficiary(
      trustBeneficiary.slug,
      trustRemainderBeneficiary,
      onRequestCompleted,
    );
  };

  return (
    <div>
      <div>
        Are you sure you wish to remove {trustRemainderBeneficiary.name} as a remainder beneficiary?
      </div>
      <div>
        <form onSubmit={onSubmit}>
          <label className="tl-field-label" htmlFor="professional">
            Professional Relationship
          </label>
          <div className="tl-form-actions-row">
            <TrueLinkButton
              className={classNames('btn btn-danger', {
                disabled: isSubmitting,
              })}
              disabled={isSubmitting}
              type="submit"
              variant="none"
            >
              Remove Remainder Beneficiary
            </TrueLinkButton>
          </div>
        </form>
      </div>
    </div>
  );
}

DashboardTrustBeneficiaryProfileTrustRemainderBeneficiariesDestroyForm.propTypes = {
  trustBeneficiary: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
  trustRemainderBeneficiary: trustRemainderBeneficiaryShape.isRequired,
};
