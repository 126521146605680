import React, { useEffect, useState } from 'react';
import { originTypes } from './Constants';
import { fetchDropdownDataCollections, fetchReportsList } from './api';
import { originShape } from './reports_section/OriginShape';
import ReportSection from './reports_section/ReportSection';

export default function Reports({ origin }) {
  const categorizedReports = {};

  const [entityCollections, setEntityCollections] = useState({});
  const [reportsList, setReportsList] = useState([]);
  useEffect(() => {
    fetchReportsList({ originType: origin.type, originId: origin.id }).then((reportsResponse) => {
      setReportsList(reportsResponse.data);
    });
    if (origin.type === originTypes.organization) {
      fetchDropdownDataCollections(origin.id).then((aggregatedResponses) => {
        setEntityCollections({
          [originTypes.trust]: aggregatedResponses['trusts'].map((trust) => ({
            id: trust.slug,
            label: trust.name,
            type: trust.type,
          })),
          [originTypes.portfolio]: aggregatedResponses['portfolios'].map((portfolio) => ({
            id: portfolio.slug,
            label: portfolio.name,
          })),
          [originTypes.bene]: aggregatedResponses['benes'].map((bene) => ({
            id: bene.slug,
            label: bene.person.displayName,
            trustType: bene.trust.trustType,
          })),
        });
      });
    }
  }, [origin]);

  reportsList.forEach((report) => {
    if (report.category in categorizedReports) {
      categorizedReports[report.category].push(report);
    } else {
      categorizedReports[report.category] = [report];
    }
  });

  return Object.entries(categorizedReports).map(([category, reports], key) => (
    <ReportSection
      data={reports}
      entityCollections={entityCollections}
      key={key}
      origin={origin}
      title={category}
    />
  ));
}

Reports.propTypes = {
  origin: originShape,
};
