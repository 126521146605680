import PropTypes from 'prop-types';
import React from 'react';
import bindAll from 'react/shared/utils/bind_all';

export default class CheckboxTextfield extends React.Component {
  constructor(props) {
    super(props);

    bindAll(this);
  }

  handleKeyDown(e) {
    if (e.key === 'Enter' && !this.props.disabled) {
      this.props.onEnter();
    }
  }

  render() {
    return (
      <input
        className={'checkbox_textfield'}
        defaultValue={this.props.textfieldValue}
        disabled={this.props.disabled}
        maxLength="5"
        onBlur={this.props.onBlur}
        onKeyDown={this.handleKeyDown}
        type="text"
      />
    );
  }
}

CheckboxTextfield.propTypes = {
  disabled: PropTypes.bool,
  onBlur: PropTypes.func.isRequired,
  onEnter: PropTypes.func.isRequired,
  textfieldValue: PropTypes.string,
};
