import tlFieldTransformers from 'react/shared/utils/tl_field_transformers';

const { toYesNo, formatMoney } = tlFieldTransformers;
function boolToMarketable(bool) {
  return bool === true ? 'Marketable' : 'Non-marketable';
}

function boolToTax(bool) {
  return bool === true ? 'Pre-Tax' : 'Post-Tax';
}

export const businessInterestView = (asset) => [
  { label: 'Type', value: asset.asset_type },
  { label: 'Name', value: asset.name },
  { label: 'Owned By Trust', value: toYesNo(asset.owned_by_trust) },
  {
    label: 'Current Market Value',
    value: formatMoney(asset.current_market_value),
    date: asset.market_value_date,
  },
  { date: asset.cost_basis_date, label: 'Cost Basis', value: formatMoney(asset.cost_basis) },
  { label: 'Income Producing', value: toYesNo(asset.income_or_royalty_producing) },
  { label: 'Valuation Required', value: toYesNo(asset.valuation_required) },
  { label: 'Date of Next Valuation', value: asset.next_valuation_date },
  { label: 'Manager Information', value: asset.manager },
  { label: 'Date of Next Capital Call', value: asset.next_capital_call_date },
  { label: 'Notes', value: asset.notes },
];

export const autoView = (asset) => [
  { label: 'Type', value: asset.asset_type },
  { label: 'Name', value: asset.name },
  { label: 'Owned By Trust', value: toYesNo(asset.owned_by_trust) },
  {
    label: 'Market Value',
    value: formatMoney(asset.current_market_value),
    date: asset.market_value_date,
  },
  { date: asset.cost_basis_date, label: 'Cost Basis', value: formatMoney(asset.cost_basis) },
  { label: 'Purchase Date', value: asset.purchase_date },
  { label: 'Make', value: asset.make },
  { label: 'Model', value: asset.model },
  { label: 'Model Year', value: asset.model_year },
  { label: 'Vin', value: asset.vin },
  { label: 'Auto Insurance Provider', value: asset.insurance_provider },
  { label: 'Policy Number', value: asset.insurance_policy_number },
  { label: 'Lien #', value: asset.lien_number },
  { label: 'Lien County', value: asset.lien_county },
  { label: 'Notes', value: asset.notes },
];

export const vehicleView = (asset) => [
  { label: 'Type', value: asset.asset_type },
  { label: 'Name', value: asset.name },
  { label: 'Owned By Trust', value: toYesNo(asset.owned_by_trust) },
  {
    label: 'Market Value',
    value: formatMoney(asset.current_market_value),
    date: asset.market_value_date,
  },
  { date: asset.cost_basis_date, label: 'Cost Basis', value: formatMoney(asset.cost_basis) },
  { label: 'Purchase Date', value: asset.purchase_date },
  { label: 'Make', value: asset.make },
  { label: 'Model', value: asset.model },
  { label: 'Model Year', value: asset.model_year },
  { label: 'Vin', value: asset.vin },
  { label: 'Insurance Provider', value: asset.insurance_provider },
  { label: 'Policy Number', value: asset.insurance_policy_number },
  { label: 'Lien #', value: asset.lien_number },
  { label: 'Lien Holder', value: asset.lien_holder },
  { label: 'Notes', value: asset.notes },
];

export const outsideAccountsView = (asset) => [
  { label: 'Type', value: asset.asset_type },
  { label: 'Name', value: asset.name },
  { label: 'Owned By Trust', value: toYesNo(asset.owned_by_trust) },
  {
    label: 'Current Market Value',
    value: formatMoney(asset.current_market_value),
    date: asset.market_value_date,
  },
  { label: 'Cost Basis', value: formatMoney(asset.cost_basis), date: asset.cost_basis_date },
  { label: 'Where Held', value: asset.where_held },
  { label: 'Account #', value: asset.account_or_certificate_number },
  { label: 'Manager', value: asset.manager },
  { label: 'Notes', value: asset.notes },
];

export const liabilityView = (asset) => [
  { label: 'Type', value: asset.asset_type },
  { label: 'Name', value: asset.name },
  { label: 'Owned By Trust', value: toYesNo(asset.owned_by_trust) },
  {
    label: 'Current Market Value',
    value: formatMoney(asset.current_market_value),

    date: asset.market_value_date,
  },
  { label: 'Cost Basis', value: formatMoney(asset.cost_basis), date: asset.cost_basis_date },
  { label: 'Payor', value: asset.payor },
  { label: 'Last Payment Date', date: asset.last_payment_date },
  { label: 'Amortization Schedule', value: toYesNo(asset.amortization_schedule) },
  { label: 'Payments Required', value: toYesNo(asset.payments_required) },
  { label: 'Payments Current', value: toYesNo(asset.payments_current) },
  { label: 'Payment Schedule', value: asset.payment_schedule },
  { label: 'Secured', value: toYesNo(asset.secured) },
  { label: 'Collateral Information', value: asset.collateral_information },
  { label: 'Notes', value: asset.notes },
];

export const individualHeldSecurityView = (asset) => [
  { label: 'Type', value: asset.asset_type },
  { label: 'Name', value: asset.name },
  { label: 'Owned By Trust', value: toYesNo(asset.owned_by_trust) },
  {
    label: 'Current Market Value',
    value: formatMoney(asset.current_market_value),
    date: asset.market_value_date,
  },
  { label: 'Cost Basis', value: formatMoney(asset.cost_basis), date: asset.cost_basis_date },
  { label: 'Marketable', value: boolToMarketable(asset.marketable) },
  { label: 'Where Held', value: asset.where_held },
  { label: 'Account #', value: asset.account_or_certificate_number },
  { label: 'Maturity Date', value: asset.maturity_date },
  { label: 'Valuation Required', value: toYesNo(asset.valuation_required) },
  { label: 'Date of Next Valuation', value: asset.next_valuation_date },
  { label: 'Notes', value: asset.notes },
];

export const tangiblePropertyView = (asset) => [
  { label: 'Type', value: asset.asset_type },
  { label: 'Name', value: asset.name },
  { label: 'Owned By Trust', value: toYesNo(asset.owned_by_trust) },
  {
    label: 'Current Market Value',
    value: formatMoney(asset.current_market_value),
    date: asset.market_value_date,
  },
  { label: 'Cost Basis', value: formatMoney(asset.cost_basis), date: asset.cost_basis_date },
  { label: 'Marketable', value: boolToMarketable(asset.marketable) },
  { label: 'Where Held', value: asset.where_held },
  { label: 'Appraisals Required', value: toYesNo(asset.appraisals_required) },
  { label: 'Date of Next Appraisal', value: asset.next_appraisal_date },
  { label: 'Notes', value: asset.notes },
];

export const realEstateView = (asset) => [
  { label: 'Type', value: asset.asset_type },
  { label: 'Name', value: asset.name },
  { label: 'Owned By Trust', value: toYesNo(asset.owned_by_trust) },
  {
    label: 'Current Market Value',
    value: formatMoney(asset.current_market_value),
    date: asset.market_value_date,
  },
  { label: 'Cost Basis', value: formatMoney(asset.cost_basis), date: asset.cost_basis_date },
  { label: 'Date of Purchase', value: asset.purchase_date },
  { label: 'Property Type', value: asset.property_type },
  { label: 'Homeowners Insurance Provider', value: asset.insurance_provider },
  { label: 'Policy Number', value: asset.policy_number },
  { label: 'Street 1', value: asset.street_1 },
  { label: 'Street 2', value: asset.street_2 },
  { label: 'City', value: asset.city },
  { label: 'State', value: asset.state },
  { label: 'Zip Code', value: asset.zip_code },
  { label: 'Appraisals Required', value: toYesNo(asset.appraisals_required) },
  { label: 'Date of Next Appraisal', value: asset.next_appraisal_date },
  { label: 'Inspections Required', value: toYesNo(asset.inspections_required) },
  { label: 'Date of Next Inspection', value: asset.next_inspection_date },
  { label: 'Property Manager', value: asset.property_manager },
  { label: 'Notes', value: asset.notes },
];

export const mineralInterestView = (asset) => [
  { label: 'Type', value: asset.asset_type },
  { label: 'Name', value: asset.name },
  { label: 'Owned By Trust', value: toYesNo(asset.owned_by_trust) },
  {
    label: 'Current Market Value',
    value: formatMoney(asset.current_market_value),
    date: asset.market_value_date,
  },
  { label: 'Cost Basis', value: formatMoney(asset.cost_basis), date: asset.cost_basis_date },
  { label: 'Income/Royalty Producing', value: toYesNo(asset.income_or_royalty_producing) },
  { label: 'Valuation Required', value: toYesNo(asset.valuation_required) },
  { label: 'Date of Next Valuation', value: asset.next_valuation_date },
  { label: 'Manager Information', value: asset.manager },
  { label: 'Notes', value: asset.notes },
];

export const annuitiesView = (asset) => [
  { label: 'Type', value: asset.asset_type },
  { label: 'Name', value: asset.name },
  { label: 'Owned By Trust', value: toYesNo(asset.owned_by_trust) },
  {
    label: 'Current Market Value',
    value: formatMoney(asset.current_market_value),
    date: asset.market_value_date,
  },
  { label: 'Cost Basis', value: formatMoney(asset.cost_basis), date: asset.cost_basis_date },
  {
    label: 'Purchase value',
    value: formatMoney(asset.purchase_value),
    date: asset.purchase_value_date,
  },
  { label: 'Pre-Tax/After-tax', value: boolToTax(asset.pre_tax) },
  { label: 'Issuer', value: asset.issuer },
  { label: 'Date of Next Contract Change', date: asset.next_contract_change_date },
  { label: 'Notes', value: asset.notes },
];

export const chooseAssetType = ({ assetType, asset }) => {
  if (assetType === 'Vehicle Lien') {
    return autoView(asset);
  }
  if (assetType === 'Vehicle') {
    return vehicleView(asset);
  }
  if (assetType === 'Business Interest') {
    return businessInterestView(asset);
  }
  if (assetType === 'Outside Accounts') {
    return outsideAccountsView(asset);
  }
  if (assetType === 'Liability') {
    return liabilityView(asset);
  }
  if (assetType === 'Individual Held Security') {
    return individualHeldSecurityView(asset);
  }
  if (assetType === 'Tangible Property') {
    return tangiblePropertyView(asset);
  }
  if (assetType === 'Real Estate') {
    return realEstateView(asset);
  }
  if (assetType === 'Mineral Interest') {
    return mineralInterestView(asset);
  }
  if (assetType === 'Annuities') {
    return annuitiesView(asset);
  }
};
