import PropTypes from 'prop-types';
import React from 'react';

export default function BasicTableBuilder(props) {
  return (
    <table className="table">
      <thead>
        <tr>
          {props.columnList.map((column_key) => {
            const attributes = props.tableFieldConfig[column_key].thAttributes || {};
            return (
              <th
                className={`table-cell ${attributes['className'] || ''}`}
                id={`th-${column_key}`}
                key={`th-${column_key}`}
                style={attributes['style']}
              >
                <span style={{ cursor: 'pointer' }}>
                  {props.tableFieldConfig[column_key].title}
                </span>
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {props.tableItems.map((tableItem, index) => (
          <tr id={`${props.itemTypeKey}-row-${tableItem.id}`} key={`row-${index}-${tableItem.id}`}>
            {props.columnList.map((attribute) => (
              <td key={`col-${attribute}-${tableItem.id}`}>
                {props.tableFieldConfig[attribute].value(tableItem)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

BasicTableBuilder.propTypes = {
  columnList: PropTypes.array.isRequired,
  tableFieldConfig: PropTypes.object.isRequired,
  tableItems: PropTypes.array.isRequired,
  itemTypeKey: PropTypes.string.isRequired,
};
