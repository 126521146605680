import PropTypes from 'prop-types';
import React from 'react';
import childrenShape from 'react/shared/shapes/children';

function StyledLabel({ labelContent, inputId, children, styles }) {
  return (
    <div className={styles ? styles.container : 'new-form-field'}>
      <label className={styles ? styles.label : 'new-form__label'} htmlFor={inputId}>
        {labelContent}
      </label>
      <div className={styles ? styles.data : 'new-form__data'}>{children}</div>
    </div>
  );
}

StyledLabel.propTypes = {
  labelContent: PropTypes.node,
  inputId: PropTypes.string,
  children: childrenShape,
  styles: PropTypes.shape({
    container: PropTypes.string,
    label: PropTypes.string,
    data: PropTypes.string,
  }),
};

export default StyledLabel;
