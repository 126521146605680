import PropTypes from 'prop-types';
import React from 'react';
import MonthlyStatements from 'react/member/card/components/reports_tab/monthly_statements/MonthlyStatements';
import TransactionReportSection from 'react/shared/card/components/reports_tab/transaction_report/TransactionReportSection';

export default function BeneficiaryCardReportsSection({ account }) {
  return (
    <div className="text-left">
      <div id="dashboard_reports">
        <TransactionReportSection accountId={account.slug} isCardholder />
        <MonthlyStatements isCardholder monthlyStatementDates={account.statements_to_show} />
      </div>
    </div>
  );
}

BeneficiaryCardReportsSection.propTypes = {
  account: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    statements_to_show: PropTypes.array.isRequired,
  }).isRequired,
};
