import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import TransferDescriptionCell from './TransferDescriptionCell';
import TransferIconCell from './TransferIconCell';
import { autoTransferShape } from 'react/member/card/shapes/AutoTransferShape';
import { transferShape } from 'react/member/card/shapes/TransferShape';
import {
  isSkipped,
  delayedByHoliday,
  isRequested,
  hasAcceptedGuidance,
} from 'react/member/utils/Transfer';
import PALETTE from 'react/shared/theme/palette';

const useStyles = makeStyles({
  actionCellContents: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  actionCell: {
    paddingLeft: 0,
  },
  cell: {
    paddingTop: '18px !important',
    paddingBottom: '18px !important',
  },
});

export default function TransferRow({ i, isViewOnly, item, renderActionCell }) {
  const classes = useStyles();

  return (
    <tr className={i % 2 == 0 ? 'odd' : ''} data-item-id={item.id}>
      <td className={classes.cell}>
        {isSkipped(item) ? <s>${item.amount}</s> : `$${item.amount}`}
      </td>
      <td className={classes.cell}>
        {delayedByHoliday(item) && isRequested(item) && (
          <TransferIconCell
            className={'fa-triangle-exclamation'}
            color={'#FC824E'}
            tooltipOn
            transfer={item}
          />
        )}
        {hasAcceptedGuidance(item) && isRequested(item) && (
          <TransferIconCell
            className={'fa-circle-info'}
            color={PALETTE.emerald}
            tooltipOn
            transfer={item}
          />
        )}
      </td>
      <td className={classes.cell}>
        <TransferDescriptionCell item={item} />
      </td>
      <td className={classes.cell}>{item.bankAccount.accountNumber}</td>
      <td className={`${classes.cell} ${classes.actionCell}`}>
        <span className={classes.actionCellContents}>{renderActionCell(item, isViewOnly)}</span>
      </td>
    </tr>
  );
}

TransferRow.propTypes = {
  i: PropTypes.number.isRequired,
  item: PropTypes.oneOfType([autoTransferShape, transferShape]).isRequired,
  isViewOnly: PropTypes.bool.isRequired,
  renderActionCell: PropTypes.func.isRequired,
};
