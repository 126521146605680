import classNames from 'classnames';
import React from 'react';
import ReactDOM from 'react-dom';
import DashboardActions from 'react/member/actions/dashboard_actions';
import DashboardDisbursementStore from 'react/member/stores/DashboardDisbursementStore';
import RecurringDisbursementStore from 'react/member/stores/RecurringDisbursementStore';
import PopUp from 'react/shared/components/popups/PopUp';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import bindAll from 'react/shared/utils/bind_all';

export default class DisbursementDenialModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.getDefaultState();
    bindAll(this);
  }

  componentDidMount() {
    DashboardDisbursementStore.on('disbursementDenialModal.open', this.openModal);
    DashboardDisbursementStore.on('disbursementDenialModal.close', this.closeModal);
    RecurringDisbursementStore.on('disbursementDenialModal.open', this.openModal);
    RecurringDisbursementStore.on('disbursementDenialModal.close', this.closeModal);
  }

  componentDidUpdate() {
    if (this.state.modalOpen) {
      return $(ReactDOM.findDOMNode(this)).find('textarea').focus();
    }
  }

  componentWillUnmount() {
    DashboardDisbursementStore.off('disbursementDenialModal.open', this.openModal);
    DashboardDisbursementStore.off('disbursementDenialModal.close', this.closeModal);
    RecurringDisbursementStore.off('disbursementDenialModal.open', this.openModal);
    RecurringDisbursementStore.off('disbursementDenialModal.close', this.closeModal);
  }

  getDefaultState() {
    return {
      reason: '',
      modalOpen: false,
      disbursementID: null,
      disbursementType: null,
      isSubmitting: false,
    };
  }

  openModal(id, type) {
    this.setState({
      reason: '',
      modalOpen: true,
      disbursementID: id,
      disbursementType: type,
    });
  }

  closeModal() {
    this.setState(this.getDefaultState());
  }

  updateReason(evt) {
    this.setState({ reason: evt.target.value });
  }

  denyDisbursement() {
    this.setState({ isSubmitting: true });
    const { reason, disbursementID } = this.state;
    switch (this.state.disbursementType) {
      case 'OneTime':
        DashboardActions.denyOneTimeDisbursement(disbursementID, reason);
        break;
      case 'Recurring':
        DashboardActions.denyRecurringDisbursement(disbursementID, reason);
        break;
    }
  }

  render() {
    if (!this.state.modalOpen) {
      return null;
    }

    return (
      <PopUp
        cancel={this.closeModal}
        footer={
          <TrueLinkButton
            className={classNames('btn btn-success', {
              disabled: this.state.isSubmitting,
            })}
            disabled={this.state.isSubmitting}
            onClick={this.denyDisbursement}
            variant="none"
          >
            {this.state.isSubmitting ? 'Submitting...' : 'Deny Disbursement'}
          </TrueLinkButton>
        }
        header="Why do you want to decline this transaction?"
        maxWidth="640px"
        modalOpen
        paddingTop="160px"
      >
        <textarea
          onChange={this.updateReason}
          placeholder="Optional"
          style={{ width: '100%' }}
          value={this.state.reason}
        />
      </PopUp>
    );
  }
}
