import axios from 'axios';
import DashboardActions from 'react/member/actions/dashboard_actions';

const DashboardCardStore = flux.createStore({
  cards: [],
  actions: [
    DashboardActions.fetchUnlinkedCardsByOrganization,
    DashboardActions.linkTrustBeneficiaryToCard,
    DashboardActions.refreshCurrentCardStatus,
    DashboardActions.reloadOnboardingFlow,
  ],

  fetchUnlinkedCardsByOrganization(organization_slug) {
    $.get(
      RailsRoutes.dashboard_organization_cards_path(organization_slug, {
        format: 'json',
        unlinked_only: true,
      }),
      (cards) => {
        this.cards = cards;
        this.emit('unlinked_cards.fetch', this.cards);
      },
    );
  },

  linkTrustBeneficiaryToCard(card_id, client_slug) {
    $.ajax({
      data: {
        client_slug,
      },
      url: RailsRoutes.dashboard_card_link_trust_beneficiary_path(card_id, {
        format: 'json',
      }),
      method: 'PUT',
    }).done((response) => {
      if (response) {
        Truelink.flash('success', 'This trust beneficiary has been linked!');
        this.emit('cards.linkTrustBeneficiary');
      } else {
        Truelink.flash(
          'error',
          'Error linking this card. Please contact True Link customer support!',
        );
      }
    });
  },

  refreshCurrentCardStatus(accountSlug) {
    if (this._fetchingStatus == true) return;

    this._fetchingStatus = true;
    axios
      .get(
        RailsRoutes.refresh_status_dashboard_cards_path({
          account_id: accountSlug,
          format: 'json',
        }),
      )
      .then((response) => {
        this.emit('cards.refreshedCurrentCardStatus', accountSlug, response.data);
        this._fetchingStatus = false;
      });
  },

  reloadOnboardingFlow() {
    this.emit('cards.reloadOnboardingFlow');
  },
});

export default DashboardCardStore;
