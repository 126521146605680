import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import childrenShape from 'react/shared/shapes/children';

export default function CardHeader({ children, className, gutterBottom = true }) {
  return (
    <Typography className={className} component="h1" gutterBottom={gutterBottom} variant="h2">
      {children}
    </Typography>
  );
}

CardHeader.propTypes = {
  className: PropTypes.string,
  children: childrenShape.isRequired,
  gutterBottom: PropTypes.bool,
};
