import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import _isEmpty from 'underscore/modules/isEmpty';
import SubSectionHeader from 'react/shared/components/SubSectionHeader';

function BeneficiaryStatements({
  onSubmitReportRequest,
  quarterlyStatementDates,
  trustBeneficiary,
}) {
  const [quarterlyStatementDate, setQuarterlyStatementDate] = useState();

  useEffect(() => {
    if (quarterlyStatementDates?.length > 0) {
      setQuarterlyStatementDate(quarterlyStatementDates[0]);
    }
  }, [quarterlyStatementDates]);

  if (_isEmpty(quarterlyStatementDates)) {
    return <div />;
  }

  const options = quarterlyStatementDates.map((date, idx) => (
    <option key={idx} value={date}>
      {date}
    </option>
  ));

  const onQuarterlyStatementDateChange = (event) => {
    setQuarterlyStatementDate(event.target.value);
  };

  const onSubmit = () => {
    const params = {
      report_type: 'Dashboard::GenerateTrustBeneficiariesQuarterlyStatement',
      trust_beneficiary_id: trustBeneficiary.id,
      quarterly_statement_date: moment(quarterlyStatementDate).format('YYYY-MM-DD'),
    };
    onSubmitReportRequest(params);
  };

  return (
    <div style={{ marginTop: 40 }}>
      <SubSectionHeader>Download Statement</SubSectionHeader>
      <table style={{ borderCollapse: 'separate', borderSpacing: '10px 10px' }}>
        <tbody>
          <tr>
            <td> Quarterly Statements: </td>
            <td>
              <select
                name="quarterly_statement_date"
                onChange={onQuarterlyStatementDateChange}
                style={{ marginBottom: 0 }}
              >
                {options}
              </select>
            </td>
            <td>
              <input className="btn normal" onClick={onSubmit} type="submit" value="Open" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

BeneficiaryStatements.propTypes = {
  onSubmitReportRequest: PropTypes.func.isRequired,
  quarterlyStatementDates: PropTypes.array,
  trustBeneficiary: PropTypes.object.isRequired,
};

BeneficiaryStatements.defaultProps = {
  quarterlyStatementDates: [],
};

export default BeneficiaryStatements;
