import PropTypes from 'prop-types';
import React from 'react';
import PopUp from 'react/shared/components/popups/PopUp';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import bindAll from 'react/shared/utils/bind_all';

export default class FeeSettingsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: true,
      paymentMethod: props.paymentMethod,
      isDefault: false,
    };

    bindAll(this);
  }

  onClose(_ev) {
    this.setState({ modalOpen: false });
  }

  setPaymentMethod(ev) {
    this.setState({ paymentMethod: ev.target.value });
  }

  setIsDefault(ev) {
    this.setState({ isDefault: ev.target.checked });
  }

  submitFee(ev) {
    const { accountFeeSettingsId } = this.props;
    const { paymentMethod, isDefault } = this.state;

    ev.preventDefault();

    const data = {
      payment_method: paymentMethod,
      is_default: isDefault,
    };

    $.ajax({
      url: `/member/dashboard/account_fee_settings/${accountFeeSettingsId}`,
      type: 'PUT',
      dataType: 'json',
      data,
    })
      .done((_response) => {
        this.setState({ modalOpen: false });
        Truelink.flash('success', 'Your new card order is now complete.');
      })
      .fail(() => {
        Truelink.flash(
          'error',
          'Please email support@truelinkfinancial.com to use this fee method for all future True Link Cards.',
        );
      });
  }

  renderFooter() {
    return (
      <div>
        <TrueLinkButton
          className="btn btn-success btn-small"
          onClick={this.submitFee}
          variant="none"
        >
          Submit
        </TrueLinkButton>
        <TrueLinkButton className="btn btn-link" onClick={this.onClose} variant="none">
          Cancel
        </TrueLinkButton>
      </div>
    );
  }

  render() {
    const { modalOpen, paymentMethod, isDefault } = this.state;

    if (!modalOpen) return null;

    return (
      <PopUp
        footer={this.renderFooter()}
        header="Thank you for ordering a new True Link Card! Your card order is being processed by our team."
        hideDismissButton
        modalOpen
        onClose={this.onClose}
      >
        <p style={{ fontSize: '16px', lineHeight: '20px' }}>
          Please select how the monthly fee will be charged for this True Link Card by choosing from
          the options below:
        </p>

        <label htmlFor="pay_by_card">
          <input
            checked={paymentMethod == 'CARD'}
            id="pay_by_card"
            name="paymentMethod"
            onChange={this.setPaymentMethod}
            style={{ paddingRight: '35px', marginRight: '10px' }}
            type="radio"
            value="CARD"
          />
          Withdraw fee directly from the True Link Card
        </label>
        <br />
        <label htmlFor="pay_by_bank">
          <input
            checked={paymentMethod != 'CARD'}
            id="pay_by_bank"
            name="paymentMethod"
            onChange={this.setPaymentMethod}
            style={{ marginRight: '10px' }}
            type="radio"
            value="ACH"
          />
          Withdraw fee from the bank account associated with the card
        </label>
        <br />
        <br />
        <label htmlFor="is_default">
          <input
            checked={isDefault}
            id="is_default"
            name="isDefault"
            onChange={this.setIsDefault}
            style={{ marginRight: '10px' }}
            type="checkbox"
            value="true"
          />
          Please use this fee method for all future True Link Card orders
        </label>
      </PopUp>
    );
  }
}

FeeSettingsModal.propTypes = {
  accountFeeSettingsId: PropTypes.number.isRequired,
  paymentMethod: PropTypes.string.isRequired,
};
