import PropTypes from 'prop-types';
import React from 'react';
import DashboardActions from 'react/member/actions/dashboard_actions';
import TransfersTable from 'react/member/card/components/funding_tab/transfers_table/TransfersTable';
import TransfersTableHeader from 'react/member/card/components/funding_tab/transfers_table/TransfersTableHeader';
import TransferEditCell from 'react/member/card/components/funding_tab/transfers_table/action_cells/TransferEditCell';
import { dashboardAccountShape } from 'react/member/card/shapes/AccountShape';
import DashboardBankAccountStore from 'react/member/stores/DashboardBankAccountStore';
import DashboardTransferStore from 'react/member/stores/DashboardTransferStore';
import bindAll from 'react/shared/utils/bind_all';

export default class ScheduledTransfersSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submittingEmergencyFund: false,
      transfers: [],
    };

    bindAll(this);
  }

  componentDidMount() {
    DashboardTransferStore.on('transfers.scheduled.fetch', this.onChangeTransfers);
    DashboardBankAccountStore.on('bankAccount.delete', this.refreshTransfers);
    this.refreshTransfers();
  }

  componentWillUnmount() {
    DashboardTransferStore.off('transfers.scheduled.fetch', this.onChangeTransfers);
    DashboardBankAccountStore.off('bankAccount.delete', this.refreshTransfers);
  }

  refreshTransfers() {
    DashboardActions.fetchScheduledTransfers();
  }

  onChangeTransfers() {
    const transfers = DashboardTransferStore.futureOneTimeTransfers();
    this.setState({ transfers });
  }

  makeEditButton(transfer) {
    const { account, isViewOnly } = this.props;
    return (
      <TransferEditCell
        cardStatus={this.props.cardStatus}
        emergencyFundingEnabled={account.meta.customerInstantFunding}
        fundingType={account.meta.fundingType}
        isEffectivelyFullyFunded={account.meta.effectivelyFullyFunded}
        isFullyFunded={account.meta.fullyFunded}
        isViewOnly={isViewOnly}
        transfer={transfer}
      />
    );
  }

  render() {
    const { account, isViewOnly } = this.props;
    const { transfers } = this.state;

    return (
      <div id="upcoming_transfers_table">
        <TransfersTableHeader
          account={account}
          enableFundsAvailableModal
          title="Future one-time transfers"
        />
        <TransfersTable
          id="scheduled_transfers_table"
          isViewOnly={!!isViewOnly}
          items={transfers}
          renderActionCell={this.makeEditButton}
          zeroStateText="You have no future one-time transfers"
        />
      </div>
    );
  }
}

ScheduledTransfersSection.propTypes = {
  account: dashboardAccountShape.isRequired,
  cardStatus: PropTypes.string,
  isViewOnly: PropTypes.bool.isRequired,
};
