import Typography from '@mui/material/Typography';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import React from 'react';
import WizardCard from './WizardCard';
import theme from 'react/shared/theme/Theme';

const title = 'When your referral starts using the True Link Visa Card';
const longText = ` Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in,
        egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Praesent
        commodo cursus magna, vel scelerisque nisl consectetur et.`;

export default {
  title: 'Standard/Lab/WizardCard with Typography',
  component: WizardCard,
};

function Template(args) {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <WizardCard {...args} />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export const CardWithTypography = Template.bind({});
CardWithTypography.args = {
  children: (
    <>
      <Typography variant="h1">{title} </Typography>
      <div style={{ color: 'gray' }}>(h1, fontSize: 2.75rem (44px), lineHeight: 1.2)</div>
      <br />
      <Typography variant="h2">{title} </Typography>
      <div style={{ color: 'gray' }}>(h2, fontSize: 2.25rem (36px), lineHeight: 1.2)</div>
      <br />
      <Typography variant="h3">{title} </Typography>
      <div style={{ color: 'gray' }}>(h3, fontSize: 1.625rem (26px), lineHeight: 1.3)</div>
      <br />
      <Typography variant="h4">{title} </Typography>
      <div style={{ color: 'gray' }}>(h4, fontSize: 1.5rem (24px), lineHeight: 13)</div>
      <br />
      <Typography variant="h5">{title} </Typography>
      <div style={{ color: 'gray' }}>(h5, fontSize: 1.25rem (20px), lineHeight: 1.3)</div>
      <br />
      <br />
      <br />
      <Typography variant="body1">{longText}</Typography>
      <div style={{ color: 'gray' }}>(body1, fontSize: 1.25rem (20px), lineHeight: 1.3)</div>
      <br />
      <br />
      <Typography variant="body2">{longText}</Typography>
      <div style={{ color: 'gray' }}>(body2, fontSize: 1.125rem (18px), lineHeight: 1.3)</div>
      <br />
      <br />
      <Typography variant="caption">{longText}</Typography>
      <div style={{ color: 'gray' }}>(caption, fontSize: 1rem (16px), lineHeight: 1.4)</div>
    </>
  ),
  showBackButton: false,
  showCancelButton: false,
};

export const CardWithButtons = Template.bind({});
CardWithButtons.args = {
  children: (
    <>
      <Typography variant="h4">Let's verify your identity</Typography>
      <br />
      <br />
      <Typography variant="body2">We just need additional confirmation that it's you.</Typography>
      <Typography variant="body2">
        We'll send a 6-digit code via text message to your phone number:
      </Typography>
      <br />
      <Typography variant="body2">333-222-4444</Typography>
    </>
  ),
  showBackButton: true,
  showCancelButton: true,
};
