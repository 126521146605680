import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import LoadingIndicator from 'react/shared/components/LoadingIndicator';
import PopUp from 'react/shared/components/popups/PopUp';

const useStyles = makeStyles({
  section: {
    textAlign: 'center',
  },
  warningIcon: {
    display: 'block',
    color: '#ed4455',
    marginBottom: 10,
    fontSize: 64,
  },
  spinner: {
    width: '60px',
    height: '60px',
  },
});

export default function ReportGenerationModal({ onClose, modalOpen, reportError }) {
  const classes = useStyles();

  return (
    <PopUp
      header={''}
      hideFooter
      maxWidth="450px"
      modalHeaderStyle={{ borderBottom: 0 }}
      modalOpen={modalOpen}
      onClose={onClose}
    >
      {reportError ? (
        <div className={classes.section}>
          <i className={`fa fa-triangle-exclamation ${classes.warningIcon}`} />
          <strong>Whoops! Something went wrong</strong>
          <p>Our team has been notified and is looking into it, please try again later.</p>
        </div>
      ) : (
        <div className={classes.section}>
          <LoadingIndicator
            containerStyle={{ marginBottom: 20, width: 'auto' }}
            spinnerClass={classes.spinner}
          />

          <strong>Generating Report...</strong>
          <p>
            Please do not leave the page! The report will automatically download when completed.
          </p>
        </div>
      )}
    </PopUp>
  );
}

ReportGenerationModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  reportError: PropTypes.bool,
};
