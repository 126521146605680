import axios from 'axios';
import deepmerge from 'deepmerge';
import DashboardActions from 'react/member/actions/dashboard_actions';

const DashboardTrustBeneficiaryStore = flux.createStore({
  trustBeneficiaries: {},

  actions: [
    DashboardActions.fetchTrustBeneficiaryData,
    DashboardActions.updateTrustBeneficiaryData,
  ],

  fetchTrustBeneficiaryData(slug, forceRefresh = false) {
    this.trustBeneficiaries[slug] = this.trustBeneficiaries[slug] || {};
    const beneficiary = this.trustBeneficiaries[slug];

    if (forceRefresh) {
      this.trustBeneficiaries[slug]['fetching'] = true;
      this.requestTrustBeneficiaryData(slug);
    } else if (beneficiary['data']) {
      this.emit(`trustBeneficiary.fetch.${slug}`, this.trustBeneficiaries[slug]['data']);
    } else if (!beneficiary['fetching']) {
      this.trustBeneficiaries[slug]['fetching'] = true;
      this.requestTrustBeneficiaryData(slug);
    }
  },

  requestTrustBeneficiaryData(slug) {
    axios
      .get(RailsRoutes.dashboard_trust_beneficiary_path(slug, { format: 'json' }))
      .then((response) => {
        this.updateStoreData(slug, response.data);
        this.emit(`trustBeneficiary.fetch.${slug}`, this.trustBeneficiaries[slug]['data']);
      });
  },

  updateTrustBeneficiaryData(slug, data, onRequestCompleted) {
    axios
      .patch(RailsRoutes.dashboard_trust_beneficiary_path(slug, { format: 'json' }), {
        trustBeneficiary: data,
      })
      .then((response) => {
        this.updateStoreData(slug, response.data);
        Truelink.flash('success', 'Trust Beneficiary has been updated!');
        this.emit(`trustBeneficiary.update.${slug}`, this.trustBeneficiaries[slug]['data']);
      })
      .catch(() => {
        this.emit(`trustBeneficiary.update.${slug}.error`);
      })
      .finally(onRequestCompleted);
  },

  updateStoreData(slug, newData) {
    this.trustBeneficiaries[slug] = this.trustBeneficiaries[slug] || {};
    this.trustBeneficiaries[slug]['data'] = this.trustBeneficiaries[slug]['data'] || {};

    this.trustBeneficiaries[slug]['data'] = deepmerge(
      this.trustBeneficiaries[slug]['data'],
      newData,
    );
    this.trustBeneficiaries[slug]['fetching'] = false;
  },
});
export default DashboardTrustBeneficiaryStore;
