import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import React from 'react';
import GiftSvg from 'images/referrals/gift.svg';
import TrueLinkBanner from 'react/shared/components/true_link/lab/TrueLinkBanner';
import TrueLinkAppStoreButton from 'react/shared/components/true_link/main/TrueLinkAppStoreButton';
import TrueLinkLink from 'react/shared/components/true_link/main/TrueLinkLink';

const PROMOTIONS = {
  FAM3: {
    callToActionText: 'Share now',
    svg: GiftSvg,
    text: "Do you know someone who could benefit from True Link? We'll waive* 3 months of True Link’s monthly fees for both of you when they activate their True Link Visa® Prepaid Card.",
    title: 'Share True Link and receive 3 months of monthly fees on us*',
    variant: 'promo',
  },
  PRO3: {
    callToActionText: 'Share now',
    svg: GiftSvg,
    text: "Do you know someone who could benefit from True Link? We'll waive* 3 months of True Link monthly fees for the person you refer when they activate their True Link Visa® Prepaid Card.",
    title: 'Share True Link and they’ll receive 3 months of monthly fees on us*',
    variant: 'promo',
  },
  MOBILE_APP: {
    icon: 'mobile-screen',
    text: 'Check your balance, transactions, and upcoming transfers on the go!',
    title: 'Download the True Link mobile app today',
    variant: 'info',
  },
};

const mobileCTAButtons = (
  <div
    style={{
      display: 'flex',
      width: '260px',
      height: '50px',
      paddingTop: '35px',
      alignItems: 'center',
    }}
  >
    <TrueLinkAppStoreButton
      linkProps={{ title: 'Apple Store Button' }}
      store="ios"
      style={{ height: '75px', width: '200px' }}
    />
    <TrueLinkAppStoreButton
      linkProps={{ title: 'Google Play Store Button' }}
      store="android"
      style={{ height: '75px', width: '200px', marginLeft: '5px' }}
    />
  </div>
);

const getPromotionBanner = (promo, index) => {
  const promoCode = promo.attributes.code.toUpperCase();
  const currentPromo = PROMOTIONS[promoCode];
  if (promoCode === 'FAM3' || promoCode === 'PRO3') {
    const callToActionElement = (
      <TrueLinkLink className="primary" href={promo.attributes.url} id={`cta${promoCode}`}>
        {currentPromo.callToActionText}
      </TrueLinkLink>
    );
    return <TrueLinkBanner callToAction={callToActionElement} key={index} {...currentPromo} />;
  }
  if (promoCode === 'MOBILE_APP') {
    return <TrueLinkBanner callToAction={mobileCTAButtons} key={index} {...currentPromo} />;
  }
};

export default function Promotions() {
  const { data: promotions } = useQuery({
    queryKey: ['referral_promos'],
    queryFn: async () => {
      const response = await axios.get(RailsRoutes.api_v2_promotions_path());
      const filteredPromotions = response?.data?.data.filter(
        (promo) => promo.attributes.code.toUpperCase() in PROMOTIONS,
      );
      return filteredPromotions;
    },
  });

  return (
    <div>
      {promotions && (
        <div style={{ marginBottom: 15 }}>
          {promotions.map((promo, index) => getPromotionBanner(promo, index))}
        </div>
      )}
    </div>
  );
}
