import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import PopUp from 'react/shared/components/popups/PopUp';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import theme from 'react/shared/theme/Theme';

const useStyles = makeStyles(() => ({
  modalContainer: {
    '& .modal-header': {
      display: 'none',
    },

    '& button': {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      '& .modal-footer': {
        border: 'none',
      },
      '& button': {
        width: '100%',
        padding: '10px',
        marginBottom: '10px',
      },
    },
  },
  header: {
    textAlign: 'left',
    fontSize: '24px',
    marginBottom: '20px',
  },
}));

export default function FirstTransferPromptModal({ modalOpen, onClose }) {
  const classes = useStyles();

  const openTransferFundstModal = (ev) => {
    ev.preventDefault();
    $('#transfer-funds-cta:last').trigger('click');
    onClose();
  };

  const footer = () => (
    <TrueLinkButton className="btn cancel" onClick={onClose} variant="none">
      Maybe later
    </TrueLinkButton>
  );

  const body = () => (
    <>
      <div className={classes.header}>
        <b>Great, your bank is verified! Let’s schedule a transfer</b>
      </div>
      <p style={{ textAlign: 'left' }}>
        We'll initiate the transfer after the card is activated. Doing this now will help ensure the
        card is ready to use as soon as possible.
      </p>
      <div style={{ textAlign: 'center', margin: '30px 0 0' }}>
        <TrueLinkButton
          className="btn normal"
          id="schedule-transfer-modal-cta"
          onClick={openTransferFundstModal}
          style={{ padding: 10 }}
          variant="none"
        >
          Transfer Funds
        </TrueLinkButton>
      </div>
    </>
  );

  return (
    <div className={classes.modalContainer}>
      <PopUp footer={footer()} maxWidth="500px" modalOpen={modalOpen}>
        {body()}
      </PopUp>
    </div>
  );
}

FirstTransferPromptModal.propTypes = {
  modalOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};
