import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import _map from 'underscore/modules/map';
import DashboardActions from 'react/member/actions/dashboard_actions';
import { bankAccountShapeSnake } from 'react/member/card/shapes/BankAccountShape';
import DashboardBankAccountStore from 'react/member/stores/DashboardBankAccountStore';
import PopUp from 'react/shared/components/popups/PopUp';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import theme from 'react/shared/theme/Theme';

const useStyles = makeStyles(() => ({
  copyBankAccountModal: {
    maxWidth: '550px',

    [theme.breakpoints.down('md')]: {
      width: '100%',
      '& .form-group': {
        display: 'flex',
        flexDirection: 'column',
      },
    },
  },
}));
export default function CopyVerifiedBankAccountModal({ bankAccounts, modalOpen, onClose }) {
  const [errored, setErrored] = useState(false);
  const [selectedAccountId, setSelectedAccountId] = useState('select');
  const classes = useStyles();

  useEffect(() => {
    DashboardBankAccountStore.on('bankAccount.copy', onClose);

    return () => {
      DashboardBankAccountStore.off('bankAccount.copy', onClose);
    };
  }, [onClose]);

  const handleChange = (ev) => {
    setSelectedAccountId(ev.target.value);
  };

  const setError = () => {
    setErrored(true);
  };

  const removeError = () => {
    setErrored(false);
  };

  const submitForm = () => {
    const form = $('#copy-bank-account-form');
    const data = form.serializeArray();
    DashboardActions.copyBankAccount(data[0].value);
  };

  const validate = (ev) => {
    ev.preventDefault();
    if (selectedAccountId === 'select') {
      setError();
    } else {
      removeError();
      submitForm();
    }
  };

  const header = () => <div style={{ textAlign: 'left' }}>Copy verified bank account</div>;

  const footer = () => (
    <TrueLinkButton className="btn normal" onClick={validate} variant="none">
      Submit
    </TrueLinkButton>
  );

  const body = () => {
    const blank = [
      <option key="0" value="select">
        Select
      </option>,
    ];
    const options = blank.concat(
      _map(bankAccounts, (bankAccount, i) => {
        const description = `${bankAccount.nickname} Account#: ${bankAccount.account_number}`;
        return (
          <option key={i + 1} value={bankAccount.id}>
            {description}
          </option>
        );
      }),
    );

    const error = errored && (
      <div className="new-form__error" style={{ marginLeft: 195 }}>
        <p>Select a bank account</p>
      </div>
    );

    return (
      <div className={classes.copyBankAccountModal}>
        <form id="copy-bank-account-form">
          <p>
            Select a bank account attached to another True Link account and click 'Submit' to
            connect it to this account as well. Bank accounts that have been previously verified to
            not need to be verified again.
          </p>
          <hr />
          <div className="form-group">
            <div className="new-form__label nowrap">Bank account:</div>
            <div className="new-form__data">
              <select
                name="bank_account_id"
                onChange={handleChange}
                style={{ width: 270 }}
                value={selectedAccountId}
              >
                {options}
              </select>
            </div>
            {error}
          </div>
        </form>
      </div>
    );
  };

  if (!modalOpen) return null;

  return (
    <PopUp
      bannerMessage="Please correct the form fields highlighted below and submit again"
      footer={footer()}
      header={header()}
      maxWidth="550px"
      modalOpen={modalOpen}
      onClose={onClose}
      showBanner={errored}
    >
      {body()}
    </PopUp>
  );
}

CopyVerifiedBankAccountModal.propTypes = {
  bankAccounts: PropTypes.arrayOf(bankAccountShapeSnake).isRequired,
  modalOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};
