import { ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import TextField from 'react/member/components/signups/signup_form/form_partials/fields/TextField';
import FieldLabel from 'react/member/components/signups/signup_form/form_partials/labels/field_label';
import fieldLabelShape from 'react/shared/shapes/field_label_shape';

function SubTextField({
  id,
  name,
  fieldLabelProps,
  subSectionClass,
  rowClass,
  colClass,
  dataAnalyticsKey,
}) {
  return (
    <div className={subSectionClass}>
      <div className={rowClass}>
        <div className={colClass}>
          <FieldLabel {...fieldLabelProps} />
          <TextField dataAnalyticsKey={dataAnalyticsKey} id={id} name={name} />
          <ErrorMessage className="error" component="label" name={name} />
        </div>
      </div>
    </div>
  );
}

SubTextField.propTypes = {
  dataAnalyticsKey: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  fieldLabelProps: fieldLabelShape,
  subSectionClass: PropTypes.string,
  rowClass: PropTypes.string,
  colClass: PropTypes.string,
};

SubTextField.defaultProps = {
  fieldLabelProps: {},
  subSectionClass: 'card--form__sub-section noborder',
  rowClass: 'card--form__row',
  colClass: 'card--form__col',
};

export default SubTextField;
