import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import AccountRow from './AccountRow';
import { cardShape, accountShape } from './Shape';
import DashboardCard from 'react/member/components/dashboard/clients/dashboard_client/overview/dashboard_card/DashboardCard';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import theme from 'react/shared/theme/Theme';
import PALETTE from 'react/shared/theme/palette';

const useStyles = makeStyles(() => ({
  icon: {
    color: PALETTE.emerald,
    marginRight: theme.spacing(3),
  },
  title: {
    fontSize: '1.25rem',
  },
  card: {
    boxShadow: 'none',
    border: `1px solid ${PALETTE.grey4}`,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(3),
  },
  spacer: {
    flexGrow: 9999, // Slightly hacky, but it forces content as far to the right as possible
  },
}));

export default function Accounts({ accounts, beneId, canAddCardForBene, cards }) {
  const showAccountsTable = accounts?.length;
  const classes = useStyles();
  const onAddCardClick = () =>
    (window.location.href = RailsRoutes.new_dashboard_client_card_path(beneId));

  const currentCardForAccount = (account) => {
    const currentCardId = account.relationships.currentCard?.id;
    let currentCard;

    if (currentCardId) {
      currentCard = cards?.find((card) => card.id === currentCardId);
    }

    return currentCard;
  };

  return showAccountsTable ? (
    <DashboardCard
      content={
        <>
          <Divider />
          {showAccountsTable && (
            <Table aria-label="list of cards">
              <TableBody>
                {accounts &&
                  accounts?.map((account) => (
                    <AccountRow
                      account={account}
                      beneId={beneId}
                      currentCard={currentCardForAccount(account)}
                      key={account.id}
                    />
                  ))}
              </TableBody>
            </Table>
          )}
        </>
      }
      icon="credit-card fa-regular"
      title={
        <Typography className={classes.title} component="span" variant="h3">
          True Link Visa Card
        </Typography>
      }
    />
  ) : (
    <DashboardCard
      className={classes.card}
      icon="credit-card fa-regular"
      title={
        <>
          <Typography className={classes.title} component="span" variant="h3">
            True Link Visa Card
          </Typography>
          {canAddCardForBene && (
            <>
              <div className={classes.spacer} />
              <div>
                <TrueLinkButton onClick={onAddCardClick} size="small" variant="primaryOutline">
                  Add Card
                </TrueLinkButton>
              </div>
            </>
          )}
        </>
      }
    />
  );
}

Accounts.propTypes = {
  accounts: PropTypes.arrayOf(accountShape),
  beneId: PropTypes.string.isRequired,
  canAddCardForBene: PropTypes.bool.isRequired,
  cards: PropTypes.arrayOf(cardShape),
};
