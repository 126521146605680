import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import InfoTooltip from 'react/shared/components/tooltips/InfoTooltip';
import { addressShape } from 'react/shared/components/true_link/lab/form/address_input/Shapes';
import TrueLinkAddressInput from 'react/shared/components/true_link/lab/form/address_input/TrueLinkAddressInput';

const useStyles = makeStyles(() => ({
  poBox: {
    flexGrow: 1,
  },
  poBoxText: {
    fontStyle: 'italic',
  },
}));

export default function AddressFields({
  labelPrefix,
  setValues,
  setFieldTouched,
  showPoBoxWarning,
  values,
}) {
  const classes = useStyles();
  const handleChange = useCallback(
    (newValues) => {
      setValues({ ...values, ...newValues });
    },
    [setValues, values],
  );

  const tooltipText = `
    Because this address is used for identity verification,
    a street address is required. We will not send anything
    to the street address unless you request that we do so.
  `;

  const poBoxWarningAdornment = (
    <>
      <div className={classes.poBox} />
      <span className={classes.poBoxText}>No P.O. boxes please</span>
      <InfoTooltip tooltipText={tooltipText} />
    </>
  );

  return (
    <Grid container direction="column">
      <TrueLinkAddressInput
        labelPrefix={labelPrefix}
        onChange={handleChange}
        required
        setFieldTouched={setFieldTouched}
        street1Adornment={showPoBoxWarning ? poBoxWarningAdornment : undefined}
        values={values}
      />
    </Grid>
  );
}

AddressFields.propTypes = {
  labelPrefix: PropTypes.string,
  setFieldTouched: PropTypes.func.isRequired,
  showPoBoxWarning: PropTypes.bool,
  setValues: PropTypes.func.isRequired,
  values: PropTypes.shape({
    address: addressShape.isRequired,
  }).isRequired,
};
