import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles({
  amountCol: {
    textAlign: 'right',
  },
});

export default function AmountCell({ value }) {
  const classes = useStyles();

  return <div className={classes.amountCol}>{value}</div>;
}

AmountCell.propTypes = {
  value: PropTypes.string,
};
