import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { dashboardAccountShape } from 'react/member/card/shapes/AccountShape';

const useStyles = makeStyles({
  root: {
    marginBottom: 20,
  },
});

export default function BankAccountMessage({ account, card }) {
  const classes = useStyles();

  if ($tlf.permissions.userRoles.includes('family_funding')) {
    return null;
  }

  let message;

  if (card && account.meta.cardCountNonClosed === 0) {
    message =
      'If you would like open a new account for this cardholder, please contact Customer Support at support@truelinkfinancial.com or 1-877-264-8783 between the hours of 7:00 am - 5:00 pm, Pacific Time. ';
  } else {
    message = 'It’s easy to load money securely on to the True Link Card. ';

    if (!account.meta.hasVerifiedBankAccount) {
      message +=
        'To get started, connect a bank account. Once your bank account information is verified, you can set up a recurring transfer. ';
    }

    message += `You can log on at any time to transfer a specific amount of money to ${account.meta.cardholderName}’s card. `;

    if (!account.meta.hasOpenCard) {
      if (!card || card?.status === 'ISSUED_INACTIVE') {
        message +=
          'Any requested transfer(s) will be initiated once this True Link Card is activated. ';
      } else if (card.status !== 'ISSUED_INACTIVE') {
        message +=
          'Any requested transfer(s) will be initiated once this True Link Card is re-activated. ';
      }
    }
  }

  return (
    <div className={classes.root}>
      <p>{message}</p>
    </div>
  );
}

BankAccountMessage.propTypes = {
  account: dashboardAccountShape.isRequired,
  card: PropTypes.object,
};
