import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import TrueLinkIcon from 'react/shared/components/true_link/main/TrueLinkIcon';
import TrueLinkLink from 'react/shared/components/true_link/main/TrueLinkLink';
import childrenShape from 'react/shared/shapes/children';
import bindAll from 'react/shared/utils/bind_all';

export default class TrueLinkModal extends React.Component {
  constructor(props) {
    super(props);
    bindAll(this);
  }

  render() {
    const { handleBack, handleClose, scrollable, modalProps, iconCLoseBtn } = this.props;
    const className = scrollable ? 'tl-modal scrollable' : 'tl-modal';

    const modalPropsClassName = modalProps.className || '';
    delete modalProps.className;

    const titleStyle = {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    };

    return (
      <Dialog
        className={`modal-dialog ${className} ${modalPropsClassName}`}
        maxWidth={this.props.maxWidth || 'sm'}
        onClose={handleClose}
        open
        style={{ background: 'rgba(0, 0, 0, 0.4)' }}
        {...modalProps}
      >
        <div className="modal-content">
          <div className="modal-header">
            {iconCLoseBtn ? (
              <div style={titleStyle}>
                <Typography variant="h6">{this.props.title}</Typography>
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            ) : (
              <>
                {handleBack && (
                  <TrueLinkLink onClick={handleBack}>
                    <TrueLinkIcon icon="chevron-left" /> Back
                  </TrueLinkLink>
                )}
                <Typography variant="h6">{this.props.title}</Typography>
                {handleClose && (
                  <TrueLinkLink className="cancel btn btn-default" onClick={handleClose}>
                    Cancel
                  </TrueLinkLink>
                )}
              </>
            )}
          </div>
          <DialogContent className="modal-body" dividers>
            {this.props.children}
          </DialogContent>
        </div>
      </Dialog>
    );
  }
}

TrueLinkModal.propTypes = {
  children: childrenShape.isRequired,
  handleBack: PropTypes.func,
  handleClose: PropTypes.func,
  maxWidth: PropTypes.string,
  modalProps: PropTypes.object,
  scrollable: PropTypes.bool,
  iconCLoseBtn: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
};

TrueLinkModal.defaultProps = {
  modalProps: {},
  scrollable: true,
};
