import PropTypes from 'prop-types';
import React from 'react';
import ViewText from './unique_assets_view_utilities/ViewText';
import { chooseAssetType } from './unique_assets_view_utilities/unique_asset_utilities';
import ASSET_TYPES from 'react/member/utils/AssetTypes';

export default function UniqueAssetsView({ openViewModalAsset: asset }) {
  const assetType = ASSET_TYPES[asset.asset_type];

  return <ViewText elements={chooseAssetType({ assetType, asset })} />;
}

UniqueAssetsView.propTypes = {
  openViewModalAsset: PropTypes.object.isRequired,
};
