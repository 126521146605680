import PropTypes from 'prop-types';
import React, { useState } from 'react';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';

export default function AgreementAndFooter({ isStableSite, msaLink }) {
  const [checkedAgreements, setCheckedAgreements] = useState([]);

  const handleAgreementCheck = (event) => {
    if (event.target.checked) {
      setCheckedAgreements(checkedAgreements.concat(event.target.name));
    } else if (event.target.name === 'i_agree_to_the_esign_agreement') {
      // The esign agreement must be agreed to first due to compliance reasons from Sunrise bank
      setCheckedAgreements([]);
    } else {
      setCheckedAgreements(checkedAgreements.filter((name) => name !== event.target.name));
    }
  };

  return (
    <div>
      <div className="card--form__section">
        <div className="card--form__row" style={{ marginBottom: '0px' }}>
          <div className="card--form__col width-100">
            <label
              className="multiline card--form__checkbox-label"
              htmlFor="i_agree_to_the_esign_agreement"
            >
              <input
                checked={checkedAgreements.includes('i_agree_to_the_esign_agreement')}
                className="required"
                id="i_agree_to_the_esign_agreement"
                name="i_agree_to_the_esign_agreement"
                onChange={handleAgreementCheck}
                type="checkbox"
              />
              <p>
                I acknowledge that I have received, reviewed, and agree to the terms of the{' '}
                <a href="/legal/esign-consent" target="_blank">
                  E-sign Agreement
                </a>
                .
              </p>
            </label>
            <label
              className="multiline card--form__checkbox-label"
              htmlFor="i_agree_to_the_master_services_agreement"
            >
              <input
                checked={checkedAgreements.includes('i_agree_to_the_master_services_agreement')}
                className="required"
                disabled={
                  !checkedAgreements.includes('i_agree_to_the_esign_agreement') ? true : undefined
                }
                id="i_agree_to_the_master_services_agreement"
                name="i_agree_to_the_master_services_agreement"
                onChange={handleAgreementCheck}
                type="checkbox"
              />
              <p>
                I acknowledge that I have received, reviewed, and agree to the terms of the{' '}
                <a href={msaLink} rel="noreferrer" target="_blank">
                  Master Services Agreement
                </a>{' '}
                .
              </p>
            </label>
            {isStableSite && (
              <label
                className="multiline card--form__checkbox-label"
                htmlFor="i_am_authorized_by_cardholder"
              >
                <input
                  checked={checkedAgreements.includes('i_am_authorized_by_cardholder')}
                  className="required"
                  disabled={
                    !checkedAgreements.includes('i_agree_to_the_esign_agreement') ? true : undefined
                  }
                  id="i_am_authorized_by_cardholder"
                  name="i_am_authorized_by_cardholder"
                  onChange={handleAgreementCheck}
                  type="checkbox"
                />
                <p>
                  The intended Cardholder has knowingly and voluntarily authorized me to manage the
                  True Link service settings in association with their card.
                </p>
              </label>
            )}
          </div>
        </div>
      </div>
      <div className="card--form__right__footer">
        <TrueLinkButton size="large" type="submit" variant="primary">
          Submit
        </TrueLinkButton>
      </div>
    </div>
  );
}

AgreementAndFooter.propTypes = {
  isStableSite: PropTypes.bool.isRequired,
  msaLink: PropTypes.string.isRequired,
};
