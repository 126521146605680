import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import PropTypes from 'prop-types';
import React from 'react';
import childrenShape from 'react/shared/shapes/children';

export default function TrueLinkCheckboxes({
  ariaLabel,
  hideBorderStyle,
  name,
  onChange,
  value,
  options,
  required,
}) {
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.target.click();
    }
  };

  return (
    <FormControl component="fieldset" required={required}>
      <FormGroup aria-label={ariaLabel} name={name}>
        {options.map((option) => (
          <FormControlLabel
            className={hideBorderStyle && 'no-border'}
            control={
              <Checkbox
                checked={value.includes(option.value)}
                disabled={option.disabled}
                name={option.value}
                onChange={onChange}
                onKeyDown={handleKeyDown}
              />
            }
            id={option.id}
            key={option.value}
            label={option.label}
            value={value}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
}

TrueLinkCheckboxes.propTypes = {
  hideBorderStyle: PropTypes.bool,
  name: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: childrenShape.isRequired,
      value: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
    }),
  ).isRequired,
  required: PropTypes.bool,
};
