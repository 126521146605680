import PropTypes from 'prop-types';
import React from 'react';
import childrenShape from 'react/shared/shapes/children';

export default function GenericSelect(props) {
  return (
    <select id={props.id} name={props.name} onChange={props.handleChange} value={props.value}>
      {props.children}
    </select>
  );
}

GenericSelect.propTypes = {
  name: PropTypes.string,
  handleChange: PropTypes.func,
  id: PropTypes.string,
  value: PropTypes.string,
  children: childrenShape,
};
