import PropTypes from 'prop-types';
import React from 'react';
import CategorizedBudgetBreakdown from './CategorizedBudgetBreakdown';
import CategorizedBudgetDollarsUsed from './CategorizedBudgetDollarsUsed';
import CategorizedBudgetOnboarding from './CategorizedBudgetOnboarding';
import CategorizedBudgetProgressBar from './CategorizedBudgetProgressBar';
import CategorizedBudgetTimeline from './CategorizedBudgetTimeline';
import CategorizedBudgetTimelineModal from './CategorizedBudgetTimelineModal';
import CategorizedBudgetStore from 'react/member/stores/CategorizedBudgetStore';
import Actions from 'react/shared/actions/actions';
import LoadingIndicator from 'react/shared/components/LoadingIndicator';

export default class CategorizedBudget extends React.Component {
  constructor(props) {
    super(props);

    this.defaults = {
      monthlySummary: {
        start: null,
        end: null,
        percentComplete: null,
        available: null,
        used: null,
      },
      annualSummary: {
        start: null,
        end: null,
        percentComplete: null,
        available: null,
        used: null,
      },
      categories: {
        monthly: [],
        monthlyOtherSpend: null,
        annual: [],
        annualOtherSpend: null,
        monthlyX12: {},
      },
      onboarding: {
        timelineComplete: false,
        monthlyComplete: false,
        annualComplete: false,
      },
      timeline: {
        start: '',
        renew: '',
        monthlyStart: '',
        expired: false,
      },
      fetched: false,
    };

    this.state = {
      monthlySummary: this.defaults.monthlySummary,
      annualSummary: this.defaults.annualSummary,
      categories: this.defaults.categories,
      onboarding: this.defaults.onboarding,
      timeline: this.defaults.timeline,
      fetched: this.defaults.fetched,
    };
    this.categoryAdded = this.categoryAdded.bind(this);
    this.categoryDeleted = this.categoryDeleted.bind(this);
    this.categoryUpdated = this.categoryUpdated.bind(this);
  }

  componentDidMount() {
    const { trustBeneficiary } = this.props;
    if (trustBeneficiary) {
      Actions.fetchCategorizedBudgets(trustBeneficiary.id);
      CategorizedBudgetStore.on('categorizedBudgets.fetch', (budget) =>
        this.onBudgetCategoriesFetched(budget),
      );
      CategorizedBudgetStore.on('categorizedBudgets.createCategory', this.categoryAdded);
      CategorizedBudgetStore.on('categorizedBudgets.deleteCategory', this.categoryDeleted);
      CategorizedBudgetStore.on('categorizedBudgets.updateCategory', this.categoryUpdated);
    }
  }

  componentWillUnmount() {
    CategorizedBudgetStore.off('categorizedBudgets.fetch', this.onBudgetCategoriesFetched);
    CategorizedBudgetStore.off('categorizedBudgets.createCategory', this.categoryAdded);
    CategorizedBudgetStore.off('categorizedBudgets.deleteCategory', this.categoryDeleted);
    CategorizedBudgetStore.off('categorizedBudgets.updateCategory', this.categoryUpdated);
  }

  onBudgetCategoriesFetched(budget) {
    this.setState({ ...this.defaults, ...budget, fetched: true });
  }

  categoryAdded() {
    Actions.fetchCategorizedBudgets(this.props.trustBeneficiary.id);
    window.Truelink.flash('success', 'Budget category saved');
  }

  categoryDeleted() {
    Actions.fetchCategorizedBudgets(this.props.trustBeneficiary.id);
    window.Truelink.flash('success', 'Budget category deleted.');
  }

  categoryUpdated() {
    Actions.fetchCategorizedBudgets(this.props.trustBeneficiary.id);
    window.Truelink.flash('success', 'Budget category updated.');
  }

  renderTimelineExpiredModal() {
    if (this.state.fetched && this.state.timeline.expired) {
      return (
        <CategorizedBudgetTimelineModal
          budgetTimelineExpired
          trustBeneficiary={this.props.trustBeneficiary}
        />
      );
    }
  }

  renderBudget() {
    const { monthlySummary, annualSummary, onboarding, timeline, categories } = this.state;
    const { trustBeneficiary } = this.props;

    return (
      <div className="budget">
        <CategorizedBudgetOnboarding
          annualComplete={onboarding.annualComplete}
          monthlyComplete={onboarding.monthlyComplete}
          timelineComplete={onboarding.timelineComplete}
          timelineId={timeline.id}
          trustBeneficiary={trustBeneficiary}
        />
        <div className="budget__column budget__column--left">
          <div className="budget__column--summary">
            <div className="budget__header">Monthly Budget</div>
            <div className="budget__column-date-range">
              {monthlySummary.start} - {monthlySummary.end}
            </div>
          </div>
          <CategorizedBudgetProgressBar largeBar percentComplete={monthlySummary.percentComplete} />
          <CategorizedBudgetDollarsUsed
            available={monthlySummary.available}
            used={monthlySummary.used}
          />
        </div>
        <div className="budget__column budget__column--right">
          <div className="budget__column--summary">
            <div className="budget__header">Annual Budget</div>
            <div className="budget__column-date-range">
              {annualSummary.start} - {annualSummary.end}
            </div>
          </div>
          <CategorizedBudgetProgressBar largeBar percentComplete={annualSummary.percentComplete} />
          <CategorizedBudgetDollarsUsed
            available={Number(annualSummary.available)}
            used={annualSummary.used}
          />
        </div>
        <CategorizedBudgetBreakdown
          categories={categories}
          timelineId={timeline.id}
          trustBeneficiary={trustBeneficiary}
        />
        <CategorizedBudgetTimeline
          id={String(timeline.id)}
          monthlyStart={timeline.monthlyStart}
          renew={timeline.renew}
          start={timeline.start}
          trustBeneficiary={trustBeneficiary}
        />
        <div className="budget__footer">
          <div className="budget__footer-message">
            The "Other" category includes disbursements made with no category specified, and those
            with a category that isn't listed above
          </div>
        </div>
        {this.renderTimelineExpiredModal()}
      </div>
    );
  }

  render() {
    if (this.state.fetched) {
      return this.renderBudget();
    }
    return <LoadingIndicator />;
  }
}

CategorizedBudget.propTypes = {
  trustBeneficiary: PropTypes.shape({
    id: PropTypes.number,
    person: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
};
