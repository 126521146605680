import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { transferShape } from 'react/member/card/shapes/TransferShape';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';

const useStyles = makeStyles({
  statusLabel: {
    backgroundColor: '#E6E6E6',
    borderRadius: '4px',
    color: '#828282',
    fontSize: '12px',
    fontWeight: 700,
    padding: '0 15px',
  },
});

export default function SkippedLabel({ onUnskip, transfer }) {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.statusLabel}>SKIPPED</div>
      {transfer.allowUnskip && onUnskip && (
        <TrueLinkButton className="btn btn-link" onClick={onUnskip} variant="none">
          Unskip
        </TrueLinkButton>
      )}
    </div>
  );
}

SkippedLabel.propTypes = {
  onUnskip: PropTypes.func,
  transfer: transferShape.isRequired,
};

SkippedLabel.defaultProps = {
  onUnskip: null,
};
