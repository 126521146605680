import PropTypes from 'prop-types';
import React from 'react';
import BeneficiaryDashboardStatementsTab from './BeneficiaryDashboardStatementsTab';
import BeneficiaryDepositsThisQuarter from './BeneficiaryDepositsThisQuarter';
import BeneficiaryDashboardDisbursementsTab from './beneficiary_disbursements/BeneficiaryDashboardDisbursementsTab';
import BeneficiaryDashboardCardTab from './card_tab/BeneficiaryDashboardCardTab';
import userRoleShape from 'react/member/shapes/UserRoleShape';
import TabContent from 'react/shared/components/TabContent';
import TabNavigation from 'react/shared/components/TabNavigation';
import bindAll from 'react/shared/utils/bind_all';

export default class BeneficiaryDashboardHome extends React.Component {
  constructor(props) {
    super(props);

    bindAll(this);
  }

  getChildContext() {
    return {
      trustBeneficiary: this.props.trustBeneficiary,
    };
  }

  displayCardTab() {
    return !!this.props.account;
  }

  tabList() {
    return [
      { id: 'statements', title: 'Statements', className: '' },
      ...(this.displayCardTab() ? [{ id: 'card', title: 'True Link Card', className: '' }] : []),
      { id: 'disbursements', title: 'Disbursements', className: '' },
    ];
  }

  onTabChange(tab) {
    if (tab === 'card') {
      window.refreshCardStatus(0, this.props.account.card_slug);
    }
  }

  render() {
    const {
      trustBeneficiary,
      showCustomTimePeriodStatements,
      accountBalance,
      depositsThisQuarter,
      allowDisbursementRequest,
      quarterlyStatementDates,
    } = this.props;

    return (
      <TabNavigation onTabChange={this.onTabChange} showBackground tabs={this.tabList()}>
        <TabContent id="statements-tab-table">
          <BeneficiaryDashboardStatementsTab
            accountBalance={accountBalance}
            quarterlyStatementDates={quarterlyStatementDates}
            showCustomTimePeriodStatements={showCustomTimePeriodStatements}
            trustBeneficiary={trustBeneficiary}
          />
          <br />
          <BeneficiaryDepositsThisQuarter deposits={depositsThisQuarter} />
        </TabContent>
        {this.displayCardTab() && (
          <TabContent id="card-tab-table">
            <BeneficiaryDashboardCardTab
              account={this.props.account}
              displayCardSettings={this.props.displayCardSettings}
              pageSize={this.props.pageSize}
              transactions={this.props.transactions}
              trustBeneficiary={this.props.trustBeneficiary}
            />
          </TabContent>
        )}
        <TabContent id="disbursement-tab-table">
          <BeneficiaryDashboardDisbursementsTab
            allowDisbursementRequest={allowDisbursementRequest}
            trustBeneficiary={trustBeneficiary}
            userRoles={this.props.userRoleProps}
          />
        </TabContent>
      </TabNavigation>
    );
  }
}

BeneficiaryDashboardHome.propTypes = {
  account: PropTypes.object,
  accountBalance: PropTypes.number,
  allowDisbursementRequest: PropTypes.bool,
  displayCardSettings: PropTypes.bool,
  depositsThisQuarter: PropTypes.array.isRequired,
  pageSize: PropTypes.number,
  quarterlyStatementDates: PropTypes.array,
  showCustomTimePeriodStatements: PropTypes.bool,
  transactions: PropTypes.array,
  trustBeneficiary: PropTypes.object.isRequired,
  userRoleProps: userRoleShape.isRequired,
};

BeneficiaryDashboardHome.childContextTypes = {
  trustBeneficiary: PropTypes.object,
};
