import { Formik } from 'formik';
import React from 'react';
import CardPaymentForm from './CardPaymentForm';

export default {
  title: 'Features/CardPaymentForm',
  component: CardPaymentForm,
  argTypes: {
    onChange: { action: 'clicked' },
  },
};

function Template(args) {
  return <Formik initialValues={args.initialValue}>{() => <CardPaymentForm {...args} />}</Formik>;
}

export const noAutomatedPayment = Template.bind({});
noAutomatedPayment.args = {
  regularShipping: '4 - 6',
  expeditedShipping: '2 - 3',
  shippingFee: 30,
  adminMobile: '3332224444',
  cardholderMobile: '1112223333',
  allowAutomatedPayment: false,
  paymentMethods: {},
};

export const allowAutomatedPayment = Template.bind({});
allowAutomatedPayment.args = {
  regularShipping: '4 - 6',
  expeditedShipping: '2 - 3',
  shippingFee: 30,
  adminMobile: '3332224444',
  cardholderMobile: '1112223333',
  allowAutomatedPayment: true,
  paymentMethods: {},
};

export const noPaymentMethods = Template.bind({});
noPaymentMethods.args = {
  regularShipping: '4 - 6',
  expeditedShipping: '2 - 3',
  shippingFee: 30,
  adminMobile: '3332224444',
  cardholderMobile: '1112223333',
  allowAutomatedPayment: true,
  paymentMethods: {
    available_bank_accounts: [],
    available_payment_methods: [],
  },
};

export const preferredPaymentMethodsAch = Template.bind({});
preferredPaymentMethodsAch.args = {
  regularShipping: '4 - 6',
  expeditedShipping: '2 - 3',
  shippingFee: 30,
  adminMobile: '3332224444',
  cardholderMobile: '1112223333',
  allowAutomatedPayment: true,
  paymentMethods: {
    available_bank_accounts: [
      {
        id: 19,
        label: 'SAN MATEO CREDIT UNION: Account# ******2312',
      },
      {
        id: 28,
        label: 'CITIZENS BANK NA: Account# ******0000',
      },
    ],
    available_payment_methods: ['card', 'ach'],
    preferred_bank_account: '',
    preferred_payment_method: 'ach',
  },
};
export const preferredBankAccount = Template.bind({});
preferredBankAccount.args = {
  regularShipping: '4 - 6',
  expeditedShipping: '2 - 3',
  shippingFee: 30,
  adminMobile: '3332224444',
  cardholderMobile: '1112223333',
  allowAutomatedPayment: true,
  paymentMethods: {
    available_bank_accounts: [
      {
        id: 19,
        label: 'SAN MATEO CREDIT UNION: Account# ******2312',
      },
      {
        id: 28,
        label: 'CITIZENS BANK NA: Account# ******0000',
      },
    ],
    available_payment_methods: ['card', 'ach'],
    preferred_bank_account: '28',
    preferred_payment_method: 'ach',
  },
};

export const noCardholderMobile = Template.bind({});
noCardholderMobile.args = {
  regularShipping: '4 - 6',
  expeditedShipping: '2 - 3',
  shippingFee: 30,
  adminMobile: '3332224444',
  cardholderMobile: null,
  allowAutomatedPayment: true,
  paymentMethods: {
    available_bank_accounts: [],
    available_payment_methods: ['card', 'ach'],
  },
};

export const customDefaultFee = Template.bind({});
customDefaultFee.args = {
  regularShipping: '4 - 6',
  expeditedShipping: '2 - 3',
  shippingFee: 18,
  adminMobile: '3332224444',
  cardholderMobile: '1112223333',
  allowAutomatedPayment: true,
  paymentMethods: {
    available_bank_accounts: [],
    available_payment_methods: ['card', 'ach'],
  },
};

export const noDefaultFee = Template.bind({});
noDefaultFee.args = {
  regularShipping: '4 - 6',
  expeditedShipping: '2 - 3',
  shippingFee: null,
  adminMobile: '3332224444',
  cardholderMobile: '1112223333',
  allowAutomatedPayment: true,
  paymentMethods: {
    available_bank_accounts: [],
    available_payment_methods: ['card', 'ach'],
  },
};

export const waiveFeePreOrderForm = Template.bind({});
waiveFeePreOrderForm.args = {
  regularShipping: '4 - 6',
  expeditedShipping: '2 - 3',
  shippingFee: 30,
  adminMobile: '3332224444',
  cardholderMobile: '1112223333',
  allowAutomatedPayment: true,
  waiveFeeField: true,
  paymentMethods: {
    available_bank_accounts: [],
    available_payment_methods: ['card', 'ach'],
  },
};
