import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import debounce from 'underscore/modules/debounce';
import SearchInput from './SearchInput';
import SearchOption from './SearchOption';

export default function ClientSelectAutocomplete({
  organization,
  redirectToClientURLFunc,
  selected,
}) {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const styleOverrides = {
    width: '220px',
    '& .MuiInputBase-root': {
      height: 32,
      padding: '0px 8px', // Control padding inside the input
    },
    '& .MuiOutlinedInput-input': {
      padding: '4px 0px', // Reduce padding inside the input
      fontSize: '0.875rem', // Optionally make the text smaller
    },
    '& .MuiAutocomplete-option': {
      minHeight: '32px', // Control the height of each option
    },
    '& .MuiInputLabel-root': {
      lineHeight: '18px', // Align the label vertically with input height
      fontSize: '18px', // Match the label's font size with input
      transform: 'translate(14px, 8px) scale(1)', // Adjust label position
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)', // Adjust shrunk label position
    },
  };

  const searchForClient = (e) => {
    const routeParams = {
      format: 'json',
      id_select_only: 1,
      search: e.target.value,
      limit: 50,
    };

    const route = organization
      ? RailsRoutes.dashboard_organization_clients_path(organization.slug, routeParams)
      : RailsRoutes.dashboard_clients_path(routeParams);

    setLoading(true);
    axios.get(route).then((clientsResponse) => {
      setOptions(clientsResponse.data);
      setLoading(false);
    });
  };

  const redirectToClient = (_e, client) => {
    const targetUrl = redirectToClientURLFunc(client.slug);

    return (window.location.href = targetUrl);
  };

  return (
    <Autocomplete
      filterOptions={(x) => x}
      getOptionLabel={() => ''}
      id="client-select-autocomplete"
      loading={loading}
      noOptionsText="Start typing the beneficiary's name to see matching results."
      onChange={redirectToClient}
      onInputChange={debounce(searchForClient, 1000)}
      options={options}
      renderInput={(params) => (
        <SearchInput loading={loading} params={params} selected={selected} />
      )}
      renderOption={(props, client) => (
        <SearchOption client={client} key={client.slug} props={props} />
      )}
      sx={styleOverrides}
    />
  );
}

ClientSelectAutocomplete.propTypes = {
  organization: PropTypes.object,
  redirectToClientURLFunc: PropTypes.func.isRequired,
  selected: PropTypes.object,
};
