import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Activity from './completed_transactions/Activity';
import { ALLOCATION_OPTIONS } from 'react/jaffe/utils/InvestStrategyAllocationOptions';
import {
  getTrustBeneficiary,
  getTrustBeneficiaryAnalysis,
} from 'react/member/components/dashboard/clients/dashboard_client/overview/api';
import Securities from 'react/member/components/dashboard/clients/dashboard_client/securities/Securities';
import TrueLinkIcon from 'react/shared/components/true_link/main/TrueLinkIcon';
import TrueLinkLink from 'react/shared/components/true_link/main/TrueLinkLink';
import TrueLinkTooltip from 'react/shared/components/true_link/main/TrueLinkTooltip';
import theme from 'react/shared/theme/Theme';
import PALETTE from 'react/shared/theme/palette';
import { asMoney } from 'react/shared/utils/Money';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: theme.spacing(3),
    th: {
      marginLeft: 0,
    },
  },
  backLinkWrapper: {
    fontWeight: 400,
    color: PALETTE.grey2,
    marginBottom: theme.spacing(4),
  },
  backLinkIcon: {
    marginRight: theme.spacing(1),
    fontSize: '16px',
  },
  backLinkLink: {
    color: PALETTE.grey2,
  },
  assetLabel: {
    color: PALETTE.grey2,
  },
  tooltipLink: {
    borderBottom: `1px dashed ${PALETTE.grey3}`,
  },
  table: {
    marginBottom: theme.spacing(2),
  },
  titleStrategy: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  securitiesContainer: {
    marginTop: theme.spacing(5),
  },
  investmentContent: {
    justifyContent: 'center',
  },
  tableHeader: {
    fontSize: '24px',
    paddingLeft: 0,
    paddingBottom: 0,
  },
  tableData: {
    paddingRight: 0,
    paddingBottom: theme.spacing(1),
  },
  investmentStrategy: {
    color: PALETTE.grey2,
    borderBottom: `1px dashed ${PALETTE.grey3}`,
    display: 'inline-block',
  },
}));

export default function InvestmentDetails({ beneficiarySlug, client, showInvestmentStrategy }) {
  const [loading, setLoading] = useState(true);
  const [totalTableState, setTotalTableState] = useState(null);
  const [beneName, setBeneName] = useState('');
  const classes = useStyles();
  const handleBackToOverview = () =>
    (window.location.href = RailsRoutes.dashboard_client_path(beneficiarySlug));

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const analysisRes = await getTrustBeneficiaryAnalysis(beneficiarySlug, [
          'investmentSum',
          'availableCashSum',
          'approvedDisbursementsSum',
          'processedDisbursementsSum',
          'investmentStrategy',
        ]);
        const beneData = await getTrustBeneficiary(beneficiarySlug);
        setBeneName(beneData.firstName);
        setTotalTableState({
          investmentSum: analysisRes.investmentSum,
          availableCashSum: analysisRes.availableCashSum,
          approvedDisbursementsSum: analysisRes.approvedDisbursementsSum,
          processedDisbursementsSum: analysisRes.processedDisbursementsSum,
          investmentStrategy: analysisRes.investmentStrategy,
        });
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [beneficiarySlug]);
  const total =
    totalTableState?.availableCashSum?.amount +
      totalTableState?.approvedDisbursementsSum?.amount +
      totalTableState?.processedDisbursementsSum?.amount +
      totalTableState?.investmentSum?.amount || 0;
  const approvedAndProcessedDisbursements =
    totalTableState?.approvedDisbursementsSum?.amount +
      totalTableState?.processedDisbursementsSum?.amount || 0;
  const availableCash = totalTableState?.availableCashSum?.amount || 0;
  const totalCash = availableCash + approvedAndProcessedDisbursements || 0;
  const investmentStrategyText = ALLOCATION_OPTIONS[totalTableState?.investmentStrategy];

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
          {loading ? (
            <Grid container justifyContent="center" spacing={4}>
              <CircularProgress />
            </Grid>
          ) : (
            <>
              <Typography className={classes.backLinkWrapper}>
                <TrueLinkLink className={classes.backLinkLink} onClick={handleBackToOverview}>
                  <TrueLinkIcon className={classes.backLinkIcon} icon="chevron-left" />
                  Back to {beneName}'s Dashboard
                </TrueLinkLink>
              </Typography>
              <div className={classes.titleStrategy}>
                <Typography style={{ fontSize: '30px' }} title="beneficiary name" variant="h1">
                  {beneName && `${beneName}'s Investment Account`}
                </Typography>
                {showInvestmentStrategy && (
                  <TrueLinkTooltip
                    tooltipContent={
                      <Typography>
                        Actual asset allocation may vary and is subject to rebalancing and daily
                        valuation changes.
                      </Typography>
                    }
                  >
                    <Typography className={classes.investmentStrategy} variant="body2">
                      Target asset allocation: {investmentStrategyText}
                    </Typography>
                  </TrueLinkTooltip>
                )}
              </div>
              <div className={classes.investmentContent}>
                <Table aria-label="list of managed assets" className={classes.table}>
                  <TableBody>
                    <TableRow>
                      <TableCell className={classes.tableHeader} component="th" scope="row">
                        <Typography variant="h3">Total Balance</Typography>
                      </TableCell>
                      <TableCell align="right" className={classes.tableData}>
                        <Typography variant="h3">{asMoney(total)}</Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableHeader} component="th" scope="row">
                        <Typography variant="h3">Cash</Typography>
                      </TableCell>
                      <TableCell align="right" className={classes.tableData}>
                        <Typography variant="h3">{asMoney(totalCash)}</Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableHeader} component="th" scope="row">
                        <Typography className={classes.assetLabel} display="inline">
                          In-Progress Disbursements
                        </Typography>
                      </TableCell>
                      <TableCell align="right" className={classes.tableData}>
                        <Typography>{asMoney(approvedAndProcessedDisbursements)}</Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableHeader} component="th" scope="row">
                        <Typography className={classes.assetLabel} display="inline">
                          Available Cash
                        </Typography>
                      </TableCell>
                      <TableCell align="right" className={classes.tableData}>
                        <Typography>{asMoney(availableCash)}</Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <div className={classes.securitiesContainer}>
                  <Securities beneficiarySlug={beneficiarySlug} />
                </div>
                <Activity
                  beneficiarySlug={beneficiarySlug}
                  clientName={client.person.name}
                  organization={client.organization}
                />
              </div>
            </>
          )}
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

InvestmentDetails.propTypes = {
  showInvestmentStrategy: PropTypes.bool,
  beneficiarySlug: PropTypes.string.isRequired,
  client: PropTypes.object,
};
