import PropTypes from 'prop-types';
import React from 'react';
import DashboardPaginatedDisbursementList from 'react/member/components/dashboard/disbursements/DashboardPaginatedDisbursementList';
import DisbursementDenialModal from 'react/member/components/dashboard/disbursements/DisbursementDenialModal';
import userRoleShape from 'react/member/shapes/UserRoleShape';
import TabContent from 'react/shared/components/TabContent';
import TabNavigation from 'react/shared/components/TabNavigation';
export default function DisbursementsIndex({
  orgSlug,
  onResumeDraft,
  showSendDisbursementBackToPending,
  renderOnHoldDisbursements,
  showAdditionalApprovalTable,
  showDisbursementNotificationBubble,
  showRecurringDisbursementNotificationBubble,
  showOnHoldDisbursementNotificationBubble,
  showDraftDisbursementNotificationBubble,
  userRoles,
}) {
  const getTabList = () => {
    const tabList = [
      {
        id: 'one-time-disbursements',
        title: 'One-Time',
        className: 'btn btn-secondary',
        hideHref: true,
        generic: showDisbursementNotificationBubble,
      },
      {
        id: 'recurring-disbursements',
        title: 'Recurring',
        className: 'btn btn-secondary',
        hideHref: true,
        generic: showRecurringDisbursementNotificationBubble,
      },
    ];

    if (renderOnHoldDisbursements) {
      tabList.push({
        id: 'on-hold-disbursements',
        title: 'On Hold',
        className: 'btn btn-secondary',
        hideHref: true,
        generic: showOnHoldDisbursementNotificationBubble,
      });
    }

    tabList.push({
      id: 'draft-disbursements',
      title: 'Draft',
      className: 'btn btn-secondary',
      hideHref: true,
      generic: showDraftDisbursementNotificationBubble,
    });
    return tabList;
  };

  return (
    <div>
      <div style={{ marginTop: '25px' }}>
        <DisbursementDenialModal />
        <TabNavigation classOptions={'btn-group'} initialTabIndex={0} tabs={getTabList()}>
          <TabContent id="one-time-disbursements-table">
            <DashboardPaginatedDisbursementList
              organizationSlug={orgSlug}
              renderOnHoldDisbursements={renderOnHoldDisbursements}
              statusFilter="proposed_beneficiary_requested"
              userRoles={userRoles}
            />
            <DashboardPaginatedDisbursementList
              organizationSlug={orgSlug}
              renderOnHoldDisbursements={renderOnHoldDisbursements}
              statusFilter="proposed"
              userRoles={userRoles}
            />
            {showAdditionalApprovalTable && (
              <DashboardPaginatedDisbursementList
                defaultSort={[[0, 'asc']]}
                organizationSlug={orgSlug}
                renderOnHoldDisbursements={renderOnHoldDisbursements}
                showSendDisbursementBackToPending={showSendDisbursementBackToPending}
                statusFilter="additional_approval"
                userRoles={userRoles}
              />
            )}
            <DashboardPaginatedDisbursementList
              defaultSort={[[0, 'asc']]}
              organizationSlug={orgSlug}
              renderOnHoldDisbursements={renderOnHoldDisbursements}
              statusFilter="pending"
              userRoles={userRoles}
            />
          </TabContent>
          <TabContent id="recurring-disbursements-table">
            <DashboardPaginatedDisbursementList
              isRecurring
              organizationSlug={orgSlug}
              renderOnHoldDisbursements={renderOnHoldDisbursements}
              statusFilter="proposed"
              userRoles={userRoles}
            />
            {showAdditionalApprovalTable && (
              <DashboardPaginatedDisbursementList
                isRecurring
                organizationSlug={orgSlug}
                renderOnHoldDisbursements={renderOnHoldDisbursements}
                showSendDisbursementBackToPending={showSendDisbursementBackToPending}
                statusFilter="additional_approval"
                userRoles={userRoles}
              />
            )}
            <DashboardPaginatedDisbursementList
              isRecurring
              organizationSlug={orgSlug}
              renderOnHoldDisbursements={renderOnHoldDisbursements}
              statusFilter="pending"
              userRoles={userRoles}
            />
          </TabContent>
          {renderOnHoldDisbursements && (
            <TabContent id="on-hold-disbursements-table">
              <DashboardPaginatedDisbursementList
                key="pending_on_hold"
                organizationSlug={orgSlug}
                renderOnHoldDisbursements={renderOnHoldDisbursements}
                statusFilter="pending_on_hold"
                userRoles={userRoles}
              />
              <DashboardPaginatedDisbursementList
                key="additional_approval_on_hold"
                organizationSlug={orgSlug}
                renderOnHoldDisbursements={renderOnHoldDisbursements}
                statusFilter="additional_approval_on_hold"
                userRoles={userRoles}
              />
              <DashboardPaginatedDisbursementList
                key="proposed_on_hold"
                organizationSlug={orgSlug}
                renderOnHoldDisbursements={renderOnHoldDisbursements}
                statusFilter="proposed_on_hold"
                userRoles={userRoles}
              />
            </TabContent>
          )}
          <TabContent id="draft-disbursements-table">
            <DashboardPaginatedDisbursementList
              key="draft"
              onResumeDraft={onResumeDraft}
              organizationSlug={orgSlug}
              renderOnHoldDisbursements={renderOnHoldDisbursements}
              statusFilter="draft"
              userRoles={userRoles}
            />
          </TabContent>
        </TabNavigation>
      </div>
    </div>
  );
}

DisbursementsIndex.propTypes = {
  orgSlug: PropTypes.string,
  showAdditionalApprovalTable: PropTypes.bool.isRequired,
  onResumeDraft: PropTypes.func.isRequired,
  renderOnHoldDisbursements: PropTypes.bool,
  showSendDisbursementBackToPending: PropTypes.bool,
  userRoles: userRoleShape.isRequired,
  showDisbursementNotificationBubble: PropTypes.bool,
  showRecurringDisbursementNotificationBubble: PropTypes.bool,
  showOnHoldDisbursementNotificationBubble: PropTypes.bool,
  showDraftDisbursementNotificationBubble: PropTypes.bool,
};
