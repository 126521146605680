import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import HolidayImpactBanner from './HolidayImpactBanner';
import HolidayImpactedTransfersModal from './HolidayImpactedTransfersModal';
import HolidayNotificationBanner from './HolidayNotificationBanner';
import api from './api';
import DashboardActions from 'react/member/actions/dashboard_actions';
import { holidayShape } from 'react/member/card/shapes/HolidayShape';
import DashboardTransferStore from 'react/member/stores/DashboardTransferStore';

export default function DashboardHolidayContainer({ canEdit, holiday }) {
  // Hooks
  // -----------------------------------------------------
  // Array of all impacted transfers across multiple tables
  const [impactedTransfers, setImpactedTransfers] = useState([]);

  // Object containing subsets of impacted transfers and guidance, keyed by date
  // {
  //    [date]: {
  //      guidance: { [transfer1.id]: true, [transfer2.id]: false, [transfer3.id]: nil }
  //      transfers: [transfer1, transfer2, transfer3],
  //    },
  //    [date]: {
  //      guidance: { [transfer4.id]: true, [transfer5.id]: false, [transfer6.id]: nil }
  //      transfers: [transfer4, transfer5, transfer6],
  //    },
  // }
  const [impactedTransfersByDate, setImpactedTransfersByDate] = useState({});

  const [showImpactModal, setImpactModal] = useState(false);
  const [refreshedAt, setRefreshedAt] = useState(Date.now());
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    setLoading(true);
    let requestsComplete = 0;

    holiday.impactedDates.map((holidayImpactedDate) => {
      const guidanceDeliverDate = holidayImpactedDate.guidanceDeliverDate;
      const supposedDeliverDate = holidayImpactedDate.supposedDeliverDate;

      api
        .getImpactedTransfersByDate(holidayImpactedDate)
        .then(({ data: transfers }) => {
          // make transfer data available in several formats and append HID deliver dates
          const guidanceByTransferId = {};
          const transfersPlusDates = [];

          if (transfers.length > 0) {
            transfers.forEach((transfer) => {
              guidanceByTransferId[transfer.id] = transfer.guidanceAccepted;
              transfersPlusDates.push({ ...transfer, guidanceDeliverDate, supposedDeliverDate });
            });

            setImpactedTransfersByDate((prevTransfersByDate) => ({
              ...prevTransfersByDate,
              [holidayImpactedDate.date]: {
                guidance: guidanceByTransferId,
                transfers: transfersPlusDates,
              },
            }));
            setImpactedTransfers((prevTransfers) => prevTransfers.concat(transfersPlusDates));
          }

          requestsComplete += 1;
          if (requestsComplete === holiday.impactedDates.length) {
            setLoading(false);
            if (Cookies.get('HIT') === '1') {
              window.setTimeout(() => setImpactModal(true), 1000); // allow impact banner to display before modal
            }
          }
        })
        .catch(() => {
          // Do nothing
        });
    });
  }, [canEdit, holiday, refreshedAt]);

  const handleRefresh = () => {
    setImpactedTransfers([]);
    setImpactedTransfersByDate({});
    setRefreshedAt(Date.now());
  };

  useEffect(() => {
    DashboardTransferStore.on('transfers.reload.holidayBanner', handleRefresh);
    return function cleanup() {
      DashboardTransferStore.off('transfers.reload.holidayBanner', handleRefresh);
    };
  }, []);

  // Guards
  // -----------------------------------------------------
  if (loading) return null;

  // Handlers
  // -----------------------------------------------------
  const handleOpenImpactModal = () => setImpactModal(true);
  const handleCloseImpactModal = () => setImpactModal(false);
  const handleModalSubmit = (guidance) => {
    setSubmitting(true);
    api
      .patchApplyTransferGuidance(guidance)
      .then(() => {
        Truelink.flash('success', 'Your transfers have been updated.', false, true);
        handleCloseImpactModal();
        handleRefresh();
        // If funding tab is loaded and contains transfers, refresh its records too
        if (DashboardTransferStore.scheduledTransfers().length > 0) {
          DashboardActions.fetchScheduledTransfers();
        }
      })
      .catch((e) => {
        Truelink.flash(
          'error',
          e?.response?.data?.message || 'There was an issue with your submission',
        );
      })
      .finally(() => setSubmitting(false));
  };

  const impactedCount = impactedTransfers.length;
  const delayedCount = impactedTransfers.filter((transfer) => !transfer.guidanceAccepted).length;

  return (
    <>
      {impactedCount === 0 ? (
        <HolidayNotificationBanner holiday={holiday} />
      ) : (
        <>
          <HolidayImpactBanner
            canEdit={canEdit}
            delayedCount={delayedCount}
            holiday={holiday}
            onOpenModal={handleOpenImpactModal}
          />
          {showImpactModal && (
            <HolidayImpactedTransfersModal
              canEdit={canEdit}
              delayedCount={delayedCount}
              holiday={holiday}
              impactedTransfersByDate={impactedTransfersByDate}
              onClose={handleCloseImpactModal}
              onSubmit={handleModalSubmit}
              submitting={submitting}
            />
          )}
        </>
      )}
    </>
  );
}

DashboardHolidayContainer.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  holiday: holidayShape.isRequired,
};
