import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import TrueLinkTooltip from 'react/shared/components/true_link/main/TrueLinkTooltip';
import TrueLinkRadios from 'react/shared/components/true_link/main/form/TrueLinkRadios';
import TrueLinkSelect from 'react/shared/components/true_link/main/form/TrueLinkSelect';
import ErrorText from 'react/signups/card/common/ErrorText';
import LabelTooltipAdornment from 'react/signups/card/common/LabelTooltipAdornment';

const useStyles = makeStyles(() => ({
  countryOfCitizenshipForm: {
    marginBottom: '12px',
    '& .MuiFormControlLabel-label.MuiTypography-body1': {
      fontSize: '18px !important',
    },
    '& label': {
      border: 'none',
    },
  },
  compactRadioButtons: {
    marginTop: '20px',
    '& .MuiFormControlLabel-label.MuiTypography-body1': {
      fontSize: '18px !important',
    },
    '& label': {
      border: 'none',
    },
  },
  labelWrap: {
    alignItems: 'center',
    display: 'flex',
    gap: '5px',
  },
  wrapTop: {
    marginTop: '12px',
  },
}));

export default function CountryOfCitizenship({
  boldLabel,
  compactUI,
  countryOfCitizenshipLabel,
  onChange,
  hideHeader,
  placeholder,
  nonResidentAlienLabel,
  setFieldTouched,
  values,
}) {
  const classes = useStyles();
  const countryOptions = $tlf.domains.countries;
  const options = [
    {
      label: 'Yes',
      value: 'yes',
      id: 'nonResidentAlienYesOption',
    },
    {
      label: 'No',
      value: 'no',
      id: 'nonResidentAlienNoOption',
    },
  ];
  const tooltipContent = (
    <p>
      A "non-resident alien" is an individual who is neither a U.S. citizen nor a resident of the
      United States
    </p>
  );

  useEffect(() => {
    if (values.countryOfCitizenship === 'US') {
      values.nonResidentAlien = 'no';
    } else {
      values.nonResidentAlien = '';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.countryOfCitizenship]);

  const nonResidentAlienTooltip = (
    <TrueLinkTooltip tooltipContent={tooltipContent}>
      <i
        className="fa-light fa-circle-question"
        data-testid="tooltip-non-resident-alien"
        id="tooltip-non-resident-alien"
        style={{ marginLeft: 5 }}
      />
    </TrueLinkTooltip>
  );

  return (
    <div className={classes.countryOfCitizenshipForm}>
      <Grid container direction="column">
        {!hideHeader && (
          <div className={classes.labelWrap}>
            <label htmlFor={'country-of-citizenship'} style={boldLabel ? { marginBottom: 10 } : {}}>
              <Typography variant={boldLabel ? 'h5' : 'body1'}>
                {countryOfCitizenshipLabel}
              </Typography>
            </label>
            <LabelTooltipAdornment />
          </div>
        )}
        <TrueLinkSelect
          id="country-of-citizenship"
          label={countryOfCitizenshipLabel}
          name="countryOfCitizenship"
          onChange={onChange}
          options={countryOptions}
          placeholder={placeholder || 'Select Country'}
          required
          setFieldTouched={setFieldTouched}
          value={values.countryOfCitizenship}
        />
      </Grid>
      {values.countryOfCitizenship && values.countryOfCitizenship !== 'US' && (
        <div
          className={compactUI ? classes.compactRadioButtons : classes.wrapTop}
          id="non-resident-alien"
        >
          <Typography variant={boldLabel ? 'h5' : 'body1'}>
            {nonResidentAlienLabel}
            {nonResidentAlienTooltip}
          </Typography>
          <TrueLinkRadios
            ariaLabel={nonResidentAlienLabel}
            name="nonResidentAlien"
            onChange={onChange}
            options={options}
            size={compactUI ? 'small' : ''}
            value={values.nonResidentAlien}
          />
          <ErrorText name="nonResidentAlien" />
        </div>
      )}
    </div>
  );
}

CountryOfCitizenship.propTypes = {
  boldLabel: PropTypes.bool,
  compactUI: PropTypes.bool,
  hideHeader: PropTypes.bool,
  countryOfCitizenshipLabel: PropTypes.string,
  nonResidentAlienLabel: PropTypes.string,
  placeholder: PropTypes.string,
  setFieldTouched: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  values: PropTypes.shape({
    countryOfCitizenship: PropTypes.string.isRequired,
    nonResidentAlien: PropTypes.string,
  }).isRequired,
};
