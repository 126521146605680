import axios from 'axios';
import { AXIOS_XHR_CONFIG } from 'react/shared/utils/Axios';

export default {
  getWealthAccountEvents(clientSlug) {
    return axios.get(
      RailsRoutes.dashboard_client_wealth_account_events_path(clientSlug, 'all', 'none', {
        deposits_only: true,
        format: 'json',
      }),
      AXIOS_XHR_CONFIG,
    );
  },
  putWealthAccountEvent(clientSlug, wealthAccountEvent) {
    return axios.put(
      RailsRoutes.dashboard_client_wealth_account_event_path(clientSlug, wealthAccountEvent.id, {
        format: 'json',
      }),
      {
        wealth_account_event: {
          memo: wealthAccountEvent.memo,
        },
      },
      AXIOS_XHR_CONFIG,
    );
  },
};
