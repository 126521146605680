import PropTypes from 'prop-types';
import React from 'react';
import PopUp from 'react/shared/components/popups/PopUp';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import { asMoney } from 'react/shared/utils/Money';
import bindAll from 'react/shared/utils/bind_all';

const supportEmailAddress = 'support@truelinkfinancial.com';

const newAddressAttrs = {
  street1: 'Address Line 1',
  street2: 'Address Line 2',
  city: 'City',
  state: 'State',
  zip: 'Zip Code',
};

export default class DashboardCardCloseRequestForm extends React.Component {
  constructor(props) {
    super(props);

    let delivery;
    if (this.hasBankAccounts()) {
      delivery = { method: 'BankAccount', id: this.props.bank_accounts[0].id };
    } else if (this.hasAddresses()) {
      delivery = { method: 'Address', id: this.props.addresses[0].id };
    } else {
      delivery = { method: 'Address', id: null };
    }
    delivery.new_address = {};
    Object.keys(newAddressAttrs).forEach((key) => {
      delivery.new_address[key] = '';
    });

    this.state = {
      card: this.props.card,
      isButtonDisabled: false,
      notifications: {
        class: '',
        hidden: true,
        messages: [],
      },
      form: {
        reason: '',
        delivery,
      },
    };

    bindAll(this);
  }

  componentDidMount() {
    $.validator.addMethod(
      'requireNoteForReasonOther',
      (value) => value.trim() || this.state.form.reason !== 'Other',
      'Notes is required when reason is "Other"',
    );

    $.validator.addMethod(
      'requireDeliveryMethod',
      () => {
        const { delivery } = this.state.form;
        // Ideally this would be tighter controlled, but it is legacy
        // eslint-disable-next-line no-eq-null
        if (delivery.method != null) {
          // eslint-disable-next-line no-eq-null
          return delivery.id != null || delivery.method === 'Address';
        }
        return false;
      },
      'Please select a way to return funds',
    );

    $.validator.addMethod(
      'requireNewAddressForAddressOther',
      (value) => {
        const { delivery } = this.state.form;
        if (delivery.method === 'Address' && delivery.id === null) {
          return !!value.trim();
        }
        return true;
      },
      'This field is required.',
    );
  }

  hasBankAccounts() {
    return this.props.bank_accounts.length > 0;
  }

  hasAddresses() {
    return this.props.addresses.length > 0;
  }

  enableButton() {
    this.setState({ isButtonDisabled: false });
  }

  disableButton() {
    this.setState({ isButtonDisabled: true });
  }

  showNotifications(klass, messages) {
    this.setState({
      notifications: {
        class: klass,
        hidden: false,
        messages,
      },
    });
  }

  hideNotifications() {
    this.setState({
      notifications: {
        class: '',
        hidden: true,
        messages: [],
      },
    });
  }

  updateForm(evt) {
    const newFormState = $.extend({}, this.state.form);
    newFormState[evt.target.name] = evt.target.value;
    this.setState({ form: newFormState });
  }

  updateBankAccountOrAddressChoice(method, id) {
    const newFormState = $.extend({}, this.state.form);
    newFormState.delivery.method = method;
    newFormState.delivery.id = id ? Number.parseInt(id, 10) : id;
    this.setState({ form: newFormState });
  }

  updateBankAccountChoice(evt) {
    this.updateBankAccountOrAddressChoice('BankAccount', evt.target.value);
  }

  updateAddressChoice(evt) {
    this.updateBankAccountOrAddressChoice('Address', evt.target.value);
  }

  updateAddressChoiceToOther() {
    this.updateBankAccountOrAddressChoice('Address', null);
  }

  updateNewAddress(evt) {
    const newFormState = $.extend({}, this.state.form);
    newFormState.delivery.new_address[evt.target.name] = evt.target.value;
    this.setState({ form: newFormState });
  }

  shouldCheckBankAccountRadio(id) {
    if (this.state.form.delivery.method !== 'BankAccount') {
      return false;
    }
    return this.state.form.delivery.id === id;
  }

  shouldCheckAddressRadio(id) {
    if (this.state.form.delivery.method !== 'Address') {
      return false;
    }
    return this.state.form.delivery.id === id;
  }

  shouldHideNewAddressRadio() {
    if (this.hasBankAccounts()) {
      return false;
    }
    if (this.hasAddresses()) {
      return false;
    }
    return true;
  }

  shouldHideNewAddressSection() {
    const { delivery } = this.state.form;
    // Ideally this would be tighter controlled, but it is legacy
    // eslint-disable-next-line no-eq-null
    return delivery.id != null || delivery.method === 'BankAccount';
  }

  validateAndSubmitForm(evt) {
    evt.preventDefault();
    this.disableButton();

    const rules = {
      reason: {
        required: true,
      },
      customer_service_note: {
        requireNoteForReasonOther: true,
      },
      bank_account_id: {
        requireDeliveryMethod: true,
      },
      address_id: {
        requireDeliveryMethod: true,
      },
    };
    for (const key in newAddressAttrs) {
      if (Object.prototype.hasOwnProperty.call(newAddressAttrs, key) && key !== 'street2') {
        rules[key] = { requireNewAddressForAddressOther: true };
      }
    }

    const $form = $('#dashboardCardCloseRequestForm');
    $form.validate({ rules });

    if ($form.valid()) {
      this.showNotifications('info', 'Processing...');
      const path = RailsRoutes.close_card_dashboard_card_path(this.state.card.id);
      const data = { card_close_request: this.state.form };
      return $.post(path, data)
        .done((response) => {
          Truelink.flash('success', response.message);
          this.props.closeModal();
          this.props.onSuccess();
        })
        .fail((error) => {
          this.showNotifications('danger', error.responseJSON);
          this.enableButton();
        });
    }
    this.enableButton();
  }

  renderModalHeader() {
    return (
      <b
        className="red"
        style={{
          fontSize: 18,
        }}
      >
        By clicking "Close Card", you agree to permanently close this True Link Card for{' '}
        {this.state.card.cardholder.name}
        {this.renderNotifications()}
      </b>
    );
  }

  renderModalFooter() {
    return (
      <div className="btn-group">
        <TrueLinkButton
          className="btn btn-danger"
          disabled={this.state.isButtonDisabled}
          onClick={this.validateAndSubmitForm}
          variant="none"
        >
          Close Card
        </TrueLinkButton>
      </div>
    );
  }

  renderNotifications() {
    let messages;
    const { notifications } = this.state;
    if (typeof notifications.messages === 'string') {
      messages = <div>{notifications.messages}</div>;
    } else {
      const messages_list = notifications.messages.map((message, index) => (
        <li key={index}>{message}</li>
      ));
      messages = <ul>{messages_list}</ul>;
    }
    const klass = `alert alert-${notifications.class}`;
    return (
      <div className={klass} hidden={notifications.hidden}>
        <TrueLinkButton className="close" onClick={this.hideNotifications} variant="none">
          &times;
        </TrueLinkButton>
        {messages}
      </div>
    );
  }

  renderReasonSelectOptions() {
    return this.props.reasons.map((reason, index) => (
      <option key={index} value={reason}>
        {reason}
      </option>
    ));
  }

  renderRadios() {
    const radios = [];
    for (let index = 0; index < this.props.bank_accounts.length; index++) {
      const ba = this.props.bank_accounts[index];
      const nickname = ba.nickname ? `${ba.nickname} - ` : '';
      const inputName = 'bank_account_id';
      radios.push(
        <label htmlFor={inputName} key={index}>
          <input
            checked={this.shouldCheckBankAccountRadio(ba.id)}
            name={inputName}
            onChange={this.updateBankAccountChoice}
            type="radio"
            value={ba.id}
          />
          &nbsp;&nbsp;
          {`${nickname + ba.name} ending in ${ba.last_4_digits}`}
        </label>,
      );
    }
    return radios;
  }

  renderBankAccountChoices() {
    if (!this.hasBankAccounts()) {
      return null;
    }

    return (
      <div>
        <p>
          The remaining balance on the True Link Card will be returned to the funding account within
          two weeks. Questions? Contact&nbsp;
          <a href={`mailto:${supportEmailAddress}`}>{supportEmailAddress}</a>
        </p>
        {this.renderRadios()}
      </div>
    );
  }

  renderAddressChoices() {
    if (this.hasBankAccounts()) {
      return null;
    }
    const radios = this.props.addresses.map((addr, index) => (
      <label htmlFor="address_id" key={index}>
        <input
          checked={this.shouldCheckAddressRadio(addr.id)}
          name="address_id"
          onChange={this.updateAddressChoice}
          type="radio"
          value={addr.id}
        />
        &nbsp;&nbsp;
        {`${addr.type} : ${addr.address}`}
      </label>
    ));
    const newAddressFields = Object.keys(newAddressAttrs).map((name) => (
      <div className="form-group" key={name} style={{ margin: 4 }}>
        <label htmlFor={name}>{newAddressAttrs[name]}</label>
        <input
          name={name}
          onChange={this.updateNewAddress}
          type="text"
          value={this.state.form.delivery.new_address[name]}
        />
      </div>
    ));

    return (
      <div>
        <p>
          A check for the remaining balance on the True Link Card will be sent to the address
          selected. If you would like to receive the funds via a different method, please
          email&nbsp;
          <a href={`mailto:${supportEmailAddress}`}>{supportEmailAddress}</a>
        </p>
        {radios}
        <label hidden={this.shouldHideNewAddressRadio()} htmlFor="address_id">
          <input
            checked={this.shouldCheckAddressRadio(null)}
            name="address_id"
            onChange={this.updateAddressChoiceToOther}
            type="radio"
          />
          &nbsp;&nbsp;New Address
        </label>
        <div hidden={this.shouldHideNewAddressSection()}>
          <div className="flex">{newAddressFields.slice(0, 2)}</div>
          <div className="flex">{newAddressFields.slice(2, 5)}</div>
        </div>
      </div>
    );
  }

  renderRemainingBalanceSection() {
    const balance = this.state.card.balance || 0;
    if (balance <= 0) {
      return (
        <p>
          The balance of this card is {asMoney(balance)}. No funds will be returned when the card is
          closed.
        </p>
      );
    }
    return (
      <div hidden={false}>
        {this.renderBankAccountChoices()}
        {this.renderAddressChoices()}
      </div>
    );
  }

  render() {
    const hrStyle = {
      margin: 4,
    };

    return (
      <div>
        <PopUp
          footer={this.renderModalFooter()}
          header={this.renderModalHeader()}
          maxBodyHeight="500px"
          maxHeight="900px"
          maxWidth="1100px"
          onClose={this.props.closeModal}
          openModal
        >
          <form id="dashboardCardCloseRequestForm">
            <div>
              <b>Please select a reason why you would like to close this card:</b>
              <br />
              <select name="reason" onChange={this.updateForm} value={this.state.form.reason}>
                <option className="red" value="" />
                {this.renderReasonSelectOptions()}
              </select>
            </div>
            <hr style={hrStyle} />
            <div>
              <b>Status: {this.state.card.status}</b>
              <br />
              <b>Balance: ${this.state.card.balance}</b>
            </div>
            <hr style={hrStyle} />
            {this.renderRemainingBalanceSection()}
          </form>
        </PopUp>
      </div>
    );
  }
}

DashboardCardCloseRequestForm.propTypes = {
  card: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  reasons: PropTypes.array.isRequired,
  addresses: PropTypes.array.isRequired,
  bank_accounts: PropTypes.array.isRequired,
  onSuccess: PropTypes.func.isRequired,
};
