import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React from 'react';
import { holidayShape } from 'react/member/card/shapes/HolidayShape';
import Banner from 'react/member/components/common/Banner';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';

const useStyles = makeStyles({
  contentWrap: {
    fontWeight: '700',
    alignItems: 'center',
    display: 'flex',
    flex: '1 1 auto',
    justifyContent: 'space-between',
  },
  fixItButton: {
    marginRight: '10px',
  },
});

const onClickLearnMore = () => {
  window.open('https://help.truelinkfinancial.com/article/340-funding-timelines');
};

export default function HolidayImpactBanner({ canEdit, delayedCount, holiday, onOpenModal }) {
  const classes = useStyles();

  const flip = moment(holiday.flipDate, 'YYYY-MM-DD');
  const isBeforeFlipDay = moment().isBefore(flip, 'day');
  const isEditable = canEdit && isBeforeFlipDay;

  const fixItButtonText = isEditable ? 'See how to fix it' : 'See transfers';
  return (
    <Banner variant="warning">
      <div className={classes.contentWrap}>
        <div>
          {`${delayedCount} ${pluralize('transfer', delayedCount)} will be delayed by the ${
            holiday.name
          } holiday`}
        </div>
        <div>
          <TrueLinkButton className={classes.fixItButton} onClick={onOpenModal} variant="warning">
            {fixItButtonText}
          </TrueLinkButton>
          <TrueLinkButton onClick={onClickLearnMore} variant="warningOutline">
            Learn more
          </TrueLinkButton>
        </div>
      </div>
    </Banner>
  );
}

HolidayImpactBanner.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  delayedCount: PropTypes.number.isRequired,
  holiday: holidayShape.isRequired,
  onOpenModal: PropTypes.func.isRequired,
};
