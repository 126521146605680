import React from 'react';
import programNameShape from 'react/shared/shapes/account_program_name_shape';

export default function AccountNumberInput({ programName }) {
  const isAble = programName === 'ABLE';

  return (
    <div>
      {isAble && (
        <div className="card--form__row">
          <div
            className="card--form__col width-100 info-box"
            style={{ textAlign: 'left', padding: '15px', marginBottom: 0 }}
          >
            You can find your ABLE Account Number by logging into the{' '}
            <a href="https://www.sumday.com/login" rel="noreferrer" target="_blank">
              Sumday website
            </a>
            , navigating to settings, and clicking "show" next to the account number
          </div>
        </div>
      )}
      <div className="card--form__row">
        <div className="card--form__col width-100">
          <label htmlFor="able_account_number">
            {programName} Account Number
            <input
              className="required"
              id="able_account_number"
              name="able_account_number"
              type="text"
            />
          </label>
        </div>
      </div>
    </div>
  );
}

AccountNumberInput.propTypes = {
  programName: programNameShape.isRequired,
};
