import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import TrueLinkIcon from 'react/shared/components/true_link/main/TrueLinkIcon';
import TrueLinkTooltip from 'react/shared/components/true_link/main/TrueLinkTooltip';

const useStyles = makeStyles({
  icon: ({ color }) => ({
    color: `${color}`,
    fontSize: '18px',
  }),
  content: {
    fontSize: '16px',
  },
});

function HolidayImpactedTransferTooltip({
  onClick,
  holidayName,
  transferId,
  beforeFlipDate,
  color,
}) {
  const classes = useStyles({ color });

  const content = (
    <div className={classes.content} id={`holiday-impacted-transfer-popover-${transferId}`}>
      <b>{`Delayed by the ${holidayName} holiday`}</b>
      <br />
      <br />
      {'Funds will arrive later than normal'}
      {beforeFlipDate ? (
        <>
          <br />
          <br />
          <span
            id={`impacted-transfer-accept-guidance-link-${transferId}`}
            onClick={onClick}
            onKeyUp={onClick}
            role="link"
            style={{ display: 'block', textDecoration: 'underline', cursor: 'pointer' }}
            tabIndex="0"
          >
            See how to fix
          </span>
        </>
      ) : (
        <>
          <br />
          <br />
          <a
            href="https://help.truelinkfinancial.com/article/340-funding-timelines"
            id={`help-desk-link-${transferId}`}
            rel="noreferrer"
            target="_blank"
          >
            Learn how to avoid delays next time
          </a>
        </>
      )}
    </div>
  );

  return (
    <TrueLinkTooltip placement="right" tooltipContent={content}>
      <span id={`holiday-impacted-transfer-tooltip-${transferId}`}>
        <TrueLinkIcon className={classes.icon} icon="exclamation-triangle" />
      </span>
    </TrueLinkTooltip>
  );
}

HolidayImpactedTransferTooltip.propTypes = {
  onClick: PropTypes.func.isRequired,
  holidayName: PropTypes.string.isRequired,
  transferId: PropTypes.number.isRequired,
  beforeFlipDate: PropTypes.bool.isRequired,
  color: PropTypes.string,
};

export default HolidayImpactedTransferTooltip;
