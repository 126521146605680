import axios from 'axios';
import BeneficiaryDashboardActions from 'react/member/actions/beneficiary_dashboard_actions';

const BeneficiarySignupStore = flux.createStore({
  actions: [
    BeneficiaryDashboardActions.createSignupUser,
    BeneficiaryDashboardActions.createSignupPersonDetails,
  ],

  createSignupUser({ data, resourceSlug, tbis }) {
    const url = RailsRoutes.beneficiary_create_user_path({
      format: 'json',
      resource_slug: resourceSlug,
      tbis,
    });
    axios
      .post(url, data)
      .then((response) => {
        this.emit('beneficiarySignups.user.create', response.data);
      })
      .catch((error) => {
        this.emit('beneficiarySignups.user.create.error', error.data);
      });
  },

  createSignupPersonDetails({ data, resourceSlug, tbis }) {
    const url = RailsRoutes.beneficiary_create_person_details_path({
      format: 'json',
      resource_slug: resourceSlug,
      tbis,
    });
    axios
      .post(url, data)
      .then((response) => {
        this.emit('beneficiarySignups.personDetails.create', response.data);
      })
      .catch((error) => {
        this.emit('beneficiarySignups.personDetails.create.error', error.data);
      });
  },
});

export default BeneficiarySignupStore;
