import { useQuery } from '@tanstack/react-query';
import { PropTypes } from 'prop-types';
import { getTrustBeneficiary } from 'react/member/components/dashboard/clients/dashboard_client/overview/api';

export default function useTrustBeneficiaryShowQuery({ clientSlug }) {
  const result = useQuery(['trustBeneficiaryShow', clientSlug], {
    queryFn: () => getTrustBeneficiary(clientSlug),
  });

  return { ...result };
}

useTrustBeneficiaryShowQuery.propTypes = {
  clientSlug: PropTypes.string.isRequired,
};
