import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import _contains from 'underscore/modules/contains';
import DashboardActions from 'react/member/actions/dashboard_actions';
import { dashboardAccountShape } from 'react/member/card/shapes/AccountShape';
import { bankAccountShapeSnake } from 'react/member/card/shapes/BankAccountShape';
import DashboardBankAccountStore from 'react/member/stores/DashboardBankAccountStore';
import PopUp from 'react/shared/components/popups/PopUp';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';

export default function ConnectFeeNewBankAccountModal({
  account,
  bankAccount,
  modalOpen,
  onClose,
}) {
  useEffect(() => {
    DashboardBankAccountStore.on('bankAccount.fee', onClose);

    return () => {
      DashboardBankAccountStore.off('bankAccount.fee', onClose);
    };
  }, [onClose]);

  const onSubmit = (ev) => {
    ev.preventDefault();
    DashboardActions.connectFeeNewBankAccount(account.id, bankAccount.id);
  };

  const header = () => {
    const feeAccount = account.meta.feeBankAccountMasked || 'the True Link Card';
    return (
      <div style={{ textAlign: 'left' }}>
        <p>Your monthly fee is currently charged to {feeAccount}</p>
      </div>
    );
  };

  const footer = () => {
    const accountNumber = bankAccount.account_number;
    return (
      <TrueLinkButton className="btn normal" onClick={onSubmit} variant="none">
        Yes, move the monthly fee to {accountNumber}
      </TrueLinkButton>
    );
  };

  const body = () => (
    <div>
      <p>Would you like to move the monthly fee to the new account instead?</p>
    </div>
  );

  if (!bankAccount || _contains($tlf.permissions.userRoles, 'family_funding')) {
    return null;
  }

  return (
    <PopUp
      dismissBtnText="No, keep current settings"
      footer={footer()}
      header={header()}
      modalOpen={modalOpen}
      onClose={onClose}
    >
      {body()}
    </PopUp>
  );
}

ConnectFeeNewBankAccountModal.propTypes = {
  account: dashboardAccountShape.isRequired,
  bankAccount: bankAccountShapeSnake,
  modalOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};
