import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import userRoleShape from 'react/member/shapes/UserRoleShape';
import { asMoney } from 'react/shared/utils/Money';

export default function UniqueAssetsRow({ showModal, asset, userRoles, showViewModal }) {
  function showModalWithAsset(e) {
    showModal(e, asset);
  }

  function showViewModalWithAsset(e) {
    showViewModal(e, asset);
  }

  return (
    <tr>
      <td colSpan={2}>{asset.name}</td>
      <td>{asset.asset_type}</td>
      <td>{asset.owned_by_trust ? 'Yes' : 'No'}</td>
      <td>
        {asset.cost_basis && asset.cost_basis !== '0.0' ? asMoney(Number(asset.cost_basis)) : ' '}
      </td>
      <td>
        {asset.current_market_value && asset.current_market_value !== '0.0'
          ? asMoney(Number(asset.current_market_value))
          : ' '}
      </td>
      <td>{moment(asset.updated_at).format('M/D/YYYY')}</td>
      <td>
        <a
          onClick={showViewModalWithAsset}
          onKeyUp={showViewModalWithAsset}
          role="link"
          style={{ float: 'right', paddingLeft: 10, cursor: 'pointer' }}
          tabIndex="0"
        >
          View
        </a>

        {userRoles.viewOnly || userRoles.restrictedView ? null : (
          <a href="#" onClick={showModalWithAsset} style={{ float: 'right' }}>
            Edit
          </a>
        )}
      </td>
    </tr>
  );
}

UniqueAssetsRow.propTypes = {
  asset: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  showViewModal: PropTypes.func.isRequired,
  userRoles: userRoleShape.isRequired,
};
