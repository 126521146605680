import PropTypes from 'prop-types';
import React from 'react';

function CharacterCountWarning(props) {
  const { lengths } = props;
  const calculateCharsAllowed = () => {
    const lengthsVar = lengths || {};
    // Ideally this would be tighter controlled, but it is legacy
    // eslint-disable-next-line no-eq-null
    if (lengthsVar.currentLength != null && lengthsVar.maximumLength != null) {
      return Math.max(lengthsVar.maximumLength - lengthsVar.currentLength, 0);
    }

    return null;
  };
  const charsAllowed = calculateCharsAllowed(lengths);
  const charsClass = charsAllowed < 10 ? 'red' : '';

  return charsAllowed !== null ? (
    <span className={charsClass}>
      <i>{charsAllowed} characters left</i>
    </span>
  ) : null;
}

CharacterCountWarning.propTypes = {
  lengths: PropTypes.object.isRequired,
};

export default CharacterCountWarning;
