import PropTypes from 'prop-types';
import React from 'react';
import DashboardActions from 'react/member/actions/dashboard_actions';
import DashboardUserStore from 'react/member/stores/DashboardUserStore';
import PopUp from 'react/shared/components/popups/PopUp';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import bindAll from 'react/shared/utils/bind_all';

export default class DashboardDisbursementAdminApprovalModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { authorizedUsers: [] };
    bindAll(this);
  }

  componentDidMount() {
    DashboardUserStore.on('authorizedUsers.fetch', this.onChangeAuthorizedUsers);

    const isRecurring = !!this.props.recurringDisbursement;
    DashboardActions.fetchAuthorizedUsers(this.entity(), isRecurring);
  }

  componentWillUnmount() {
    DashboardUserStore.off('authorizedUsers.fetch', this.onChangeAuthorizedUsers);
  }

  entity() {
    return this.props.disbursement || this.props.recurringDisbursement;
  }

  onChangeAuthorizedUsers(authorizedUsers) {
    this.setState({ authorizedUsers });
  }

  userSelect(users) {
    return users.map((user, idx) => (
      <option key={idx} value={user.slug}>
        {user.full_name}
      </option>
    ));
  }

  form() {
    const org = this.entity().beneficiary.organization;
    const selfApprovalAllowed = org.self_approval_allowed;
    const isSelfApprovalAllowed = selfApprovalAllowed ? 'can' : "can't";
    const users = this.userSelect(this.state.authorizedUsers);
    const dropdown = <select id="requested_by_id">{users}</select>;
    return (
      <form id="disbursement_approval_form">
        <p style={{ marginBottom: 20 }}>
          Only authorized users can approve this disbursement. <br />
          For this organizer, users {isSelfApprovalAllowed} approve their own disbursements.
        </p>
        {dropdown}
      </form>
    );
  }

  approveDisbursement(ev) {
    ev.preventDefault();
    const userId = $('#requested_by_id').val();
    this.props.approveDisbursement(userId);
  }

  render() {
    const header = 'Who requested this approval?';
    const footer = (
      <TrueLinkButton
        className="btn btn-success"
        id="admin_disbursement_approval"
        onClick={this.approveDisbursement}
        variant="none"
      >
        Approve
      </TrueLinkButton>
    );
    return (
      <PopUp
        footer={footer}
        header={header}
        maxWidth="500px"
        onClose={this.props.onClose}
        openModal
      >
        {this.form()}
      </PopUp>
    );
  }
}

DashboardDisbursementAdminApprovalModal.propTypes = {
  approveDisbursement: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  disbursement: PropTypes.object,
  recurringDisbursement: PropTypes.object,
};
