import PropTypes from 'prop-types';
import React from 'react';
import _isEmpty from 'underscore/modules/isEmpty';
import CategorizedBudgetCategoryModal from './CategorizedBudgetCategoryModal';
import CategorizedBudgetDollarsUsed from './CategorizedBudgetDollarsUsed';
import CategorizedBudgetProgressBar from './CategorizedBudgetProgressBar';
import bindAll from 'react/shared/utils/bind_all';

export default class CategorizedBudgetCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = { modalOpen: false };

    bindAll(this);
  }

  renderEditButton() {
    if (this.props.isMonthly12x) {
      return false;
    }
    return (
      <a
        className="budget__category-header--action"
        href="#"
        onClick={(e) => this.toggleEditModal(e)}
      >
        Edit
      </a>
    );
  }

  toggleEditModal(e) {
    e && e.preventDefault();
    this.setState((prevState) => ({ modalOpen: !prevState.modalOpen }));
  }

  closeEditModal() {
    this.setState({ modalOpen: false });
  }

  renderEditModal() {
    if (!this.state.modalOpen) {
      return false;
    }

    const { isMonthly, budgetCategory, trustBeneficiary, timelineId } = this.props;
    return (
      <CategorizedBudgetCategoryModal
        budgetCategory={budgetCategory}
        isMonthly={isMonthly}
        onClose={this.closeEditModal}
        timelineId={timelineId}
        trustBeneficiary={trustBeneficiary}
      />
    );
  }

  render() {
    if (_isEmpty(this.props.budgetCategory)) {
      return false;
    }

    const { available, name, percentComplete, used } = this.props.budgetCategory;
    let className = 'budget__category';
    if (this.props.isMonthly12x) {
      className += ' budget__category--monthly-x-12';
    }
    return (
      <div className={className}>
        <div className="budget__category-header">
          <div>
            <span className="budget__category-header--name">{name}</span>
            {this.renderEditButton()}
            {this.renderEditModal()}
          </div>
          <div>
            <CategorizedBudgetDollarsUsed available={available} used={used} />
          </div>
        </div>
        <CategorizedBudgetProgressBar percentComplete={percentComplete} />
      </div>
    );
  }
}

CategorizedBudgetCategory.propTypes = {
  isMonthly12x: PropTypes.bool,
  isMonthly: PropTypes.bool,
  timelineId: PropTypes.number,
  trustBeneficiary: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  budgetCategory: PropTypes.shape({
    available: PropTypes.number,
    id: PropTypes.number,
    name: PropTypes.string,
    percentComplete: PropTypes.number,
    used: PropTypes.number,
  }),
};
