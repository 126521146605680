import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDatePicker from 'react-datepicker';
import _debounce from 'underscore/modules/debounce';
import bindAll from 'react/shared/utils/bind_all';

export default class FormDatepickerField extends React.Component {
  constructor(props) {
    super(props);
    bindAll(this);

    if (props.debouncedOnChange) {
      this.debouncedHandleChange = _debounce(props.debouncedOnChange, 500);
    }
  }

  handleChange(val) {
    const { name } = this.props.field;
    const { setFieldValue } = this.props.form;

    const formattedValue = val ? moment(val).format('YYYY-MM-DD') : '';

    setFieldValue(name, formattedValue);

    this.debouncedHandleChange && this.debouncedHandleChange(formattedValue);
    this.props.onChange && this.props.onChange(formattedValue);
  }

  render() {
    const {
      field: { value },
      datepickerProps,
    } = this.props;
    const selectedDate = value == '' ? new Date() : new Date(`${value}T00:00:00`);
    return (
      <ReactDatePicker
        dateFormat={'yyyy-MM-dd'}
        onChange={this.handleChange}
        selected={selectedDate}
        {...datepickerProps}
      />
    );
  }
}

FormDatepickerField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
  form: PropTypes.shape({
    setFieldValue: PropTypes.func,
  }),
  datepickerProps: PropTypes.object,
  onChange: PropTypes.func,
  debouncedOnChange: PropTypes.func,
};

FormDatepickerField.defaultProps = {
  datepickerProps: {},
};
