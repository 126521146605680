(function () {
  'use strict';

  // Grabs params from the url, used for UTM tracking
  function getQueryParams(qs) {
    qs = qs.split('+').join(' ');

    var params = {},
      tokens,
      re = /[?&]?([^=]+)=([^&]*)/g;

    while ((tokens = re.exec(qs))) {
      params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
    }

    return params;
  }

  // Handles ajax calls to the server
  // method is optional and defaults to POST
  function ajaxCall(url, data, method) {
    method = typeof method !== 'undefined' ? method : 'POST';

    return $.ajax({
      url: url,
      type: method,
      data: data,
    }).fail(function (jqXHR, textStatus, errorThrown) {
      $('.btn-new').removeAttr('disabled'); // Re-enable submit button
    });
  }

  // All the things that happen after ajax call successfully gets response from server
  function callbacksOnFormSuccess(sectionId) {
    var $section, $section_next, $section_complete_button;

    $section = $(sectionId);
    $section_next = $section.next(); // Go to Select Card section
    $section_complete_button = $section.find('.btn-new');

    $section.find('.hide-on-complete').slideUp();
    $section.find('.show-on-complete').slideDown();
    $section.addClass('section-completed');

    if ($section_complete_button.attr('data-editing') != 'true') {
      $section_next.removeClass('section-unstarted').removeClass('section-completed').slideDown(); // Deploy the next section
    } else {
      $section_complete_button.attr('data-editing', 'false');
    }
    $section.find('.btn-new').removeAttr('disabled'); // Re-enable submit button

    // Check if user selected a family relationship or fiduciary. If family, open Select Card section
    if (sectionId === '#get-started-section-next-step') {
      $section_next = $section.next(); // There's no `Select Card` section for P4
    } else if (sectionId === '#get-started-section' && isFamily()) {
      $section_next = $section.next(); // Go to Select Card section
    } else if (sectionId === '#get-started-section') {
      $section_next = $section.next().next(); // Skip Select Card section and go straight to edit_administrator
    } else {
      $section_next = $section.next(); // Proceed as normal
    }

    $section.addClass('section-completed'); // Mark section as "completed" for future reference
    $section_next.removeClass('section-completed').removeClass('section-unstarted').slideDown(); // Deploy the next section
    $('.btn-new').removeAttr('disabled'); // Re-enable submit button

    if ($section_next.find('.transfer_datepicker')) {
      var transferDatePicker = $section_next.find('.transfer_datepicker');
      transferDatePicker.datepicker('destroy');
      transferDatePicker.datepicker({
        dateFormat: 'yy-mm-dd',
        beforeShowDay: noWeekendsOrHolidays,
        minDate: 0,
        maxDate: '+1Y',
      });
    }
  }

  function noWeekendsOrHolidays(date) {
    var noWeekends = $.datepicker.noWeekends(date);
    date = $.datepicker.formatDate('yy-mm-dd', date);
    var noHolidays = [!$tlf.bank_holidays.includes(date)];
    return noWeekends[0] ? noHolidays : noWeekends;
  }

  // Updates the area displaying the info the user has already entered
  function updateSummaryArea(params) {
    for (var el in params) {
      var elementName = params[el].name;
      var elementValue = params[el].value;
      var $element = $('#' + elementName.replace(/\[/g, '-').replace(/\]/g, ''));
      $element.text(elementValue);

      if (!elementValue) {
        $element.parents('.summary-area__data-item').hide();
      } else {
        $element.parents('.summary-area__data-item').show();
      }
    }
  }

  // Handles submit button clicks
  function initSignupContinueButtons() {
    var url, params, paramsAsString, paramsPlusUrlParams, stripped;
    var urlParams = { url_params: getQueryParams(document.location.search) }; // Get params from url that brought user to the page

    var custom_validation = {
      rules: {
        'administrator[first_name]': { charsOnly: true },
        'administrator[last_name]': { charsOnly: true },
        'cardholder[first_name]': { charsOnly: true },
        'cardholder[last_name]': { charsOnly: true },
        'cardholder[address][street1]': { requireNonPOBox: true },
        'cardholder[address][street2]': { requireNonPOBox: true },
      },
    };

    // Get started section
    $(document).on('click', '#get-started-continue-btn-next-step', function () {
      $(this).closest('form').validate(custom_validation);
      if (!$(this).closest('form').valid()) {
        Truelink.flash(
          'error',
          "You haven't provided all the information we need for this card order just yet. Please provide the missing information.",
        );
        return; // Kicks out of function if validations fail
      }
      $(this).attr('disabled', 'disabled');
      url = $(this).attr('data-href'); // Should be create_user_and_account_path
      params = $(this).parents('form').serializeArray();
      paramsAsString = $(this).parents('form').serialize();
      paramsPlusUrlParams = paramsAsString + '&' + $.param(urlParams); // Add url_params to other params

      ajaxCall(url, paramsPlusUrlParams).done(function () {
        callbacksOnFormSuccess('#get-started-section-next-step');
        updateSummaryArea(params);
      });
    });

    // Submit administrator information
    $(document).on('click', '#edit-administrator-continue-btn', function () {
      if (!$(this).closest('form').valid()) {
        Truelink.flash(
          'error',
          "You haven't provided all the information we need for this card order just yet. Please provide the missing information.",
        );
        return;
      }
      $(this).attr('disabled', 'disabled');
      url = $(this).attr('data-href'); // Should be update_administrator_path
      params = $(this).parents('form').serializeArray();

      ajaxCall(url, params).done(function () {
        callbacksOnFormSuccess('#edit-administrator-section');
        updateSummaryArea(params);

        if ($('#administrator_is').val() == 'self') populateEditCardholderSection(params);
      });
    });

    var transfer_validator = {
      ignore: '',
      rules: {
        bank_account_id: {
          required: true,
        },
        amount: { required: true },
        date: {
          required: true,
          futureDate: true,
        },
      },
      messages: {
        bank_account_id: 'Please connect a bank account to proceed',
        date: {
          required: 'Date is required',
          futureDate: 'The date must be in the future',
        },
        amount: {
          required: 'Amount is required',
        },
      },
      showErrors: function (errorMap, errorList) {
        $.each(errorList, function (i, error) {
          Truelink.flash('error', error.message, true);
        });
      },
    };

    // Submit transfer information in family funder flow
    $(document).on('click', '#create-transfer-continue-btn', function () {
      var transfer_id, method;

      $(this).closest('form').validate(transfer_validator);
      if (!$(this).closest('form').valid()) {
        return;
      }
      $(this).attr('disabled', 'disabled');
      $(this).parents('form').find('input, select').attr('disabled', false); // Remove any disabling so serializeArray will work
      url = RailsRoutes.dashboard_transfers_path();
      params = $(this).parents('form').serializeArray();

      transfer_id = $('#transfer_id').val();
      if (transfer_id) {
        method = 'PUT';
        url = RailsRoutes.dashboard_transfer_path(transfer_id);
      } else {
        method = 'POST';
      }

      ajaxCall(url, params, method).done(function (response) {
        document.location.href = '/member';
      });
    });

    $(document).on('click', '.skip-transfer-continue-btn', function () {
      document.location.href = '/member';
    });
  }

  // Handle edit button clicks
  function initSignupEditButtons() {
    $(document).on('click', '.signup-edit-btn', function () {
      var $editButton = $(this);
      var $section = $editButton.parents('.card--form');
      $section.find('.hide-on-complete').slideDown();
      $section.find('.show-on-complete').slideUp();

      $section.removeClass('section-completed');
      $section.find('.btn-success').attr('data-editing', 'true');
    });
  }

  function isFamily() {
    var family = ['family member', 'friend', 'guardian (family)', 'rep payee (family)']; // See Account::ADMINISTRATOR_RELATIONSHIP_TYPES

    if ($.inArray($('#administrator_is').val(), family) >= 0) {
      return true;
    } else {
      return false;
    }
  }

  function populateEditCardholderSection(params) {
    // Populate about cardholder fields with the administrator fields
    for (var el in params) {
      var elementName = params[el].name;
      var elementValue = params[el].value;
      var elementNameSwapped = elementName.replace('administrator', 'cardholder');
      $('[name="' + elementNameSwapped + '"]').val(elementValue);
    }

    // Disable the form fields
    $('#edit-cardholder-form :input').attr('disabled', true);
    $('#edit-cardholder-continue-btn').attr('disabled', false);
  }

  // Main
  function init() {
    if ($('#signup_header').length === 0) return; // Return if not on signups page

    initSignupContinueButtons();
    initSignupEditButtons();

    $.validator.addMethod('futureDate', function (value, element) {
      if (value == '') return true;
      if (moment(value).isBefore(moment().startOf('day'))) return false;
      return true;
    });
  }

  $(document).ready(init);
})();
