import PropTypes from 'prop-types';
import React, { useState } from 'react';
import VccActivation from './VccActivation';
import CardActivationModal from 'react/member/card/components/onboarding_widget/modals/card_activation/CardActivationModal';
import IdvModal from 'react/member/card/components/onboarding_widget/modals/card_idv/IdvModal';
import { cardholderShape } from 'react/member/card/shapes/CardholderShape';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import localeShape from 'react/shared/shapes/locale_shape';
import { CARD_ORDER_TYPES } from 'react/shared/utils/CardOrderType';

export default function ActivateCardButton({
  accountSlug,
  adminId,
  cardSlug,
  cardholder,
  cardOrderType,
  last4CardDigits,
  localeProps,
  onCardActivationSuccess,
  vcc,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [idvPassed, setIdvPassed] = useState(cardholder.idvPassed);
  const [idvInGoodStanding, setIdvInGoodStanding] = useState(cardholder.idvInGoodStanding);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const handleRefresh = () => {
    onCardActivationSuccess();
    window.setTimeout(onCardActivationSuccess, 2000);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleActivationSuccess = () => {
    window.Truelink.flash('success', 'Activation successful!');
    $('#spinner, #overlay').hide();
  };

  const handleSuccess = () => {
    handleActivationSuccess();
    handleRefresh();
  };

  const handleIdvSuccess = () => {
    setIdvPassed(true);
    setIdvInGoodStanding(true);
  };

  return (
    <div>
      <TrueLinkButton id="activate-card-button" onClick={openModal} variant="primary">
        Activate card
      </TrueLinkButton>

      {isModalOpen && !vcc && !idvPassed && (
        <IdvModal
          firstName={cardholder.first_name}
          idvFailed={cardholder.idvFailed}
          lastName={cardholder.last_name}
          onClose={handleCloseModal}
          onSuccess={handleIdvSuccess}
          personId={cardholder.id}
          userSlug={adminId}
        />
      )}

      {isModalOpen && !vcc && idvPassed && !idvInGoodStanding && (
        <IdvModal
          firstName={cardholder.first_name}
          idvFailed={cardholder.idvFailed}
          lastName={cardholder.last_name}
          onClose={handleCloseModal}
          onSuccess={() => setIdvInGoodStanding(true)}
          personId={cardholder.id}
          userSlug={adminId}
        />
      )}
      {isModalOpen && !vcc && idvPassed && idvInGoodStanding && (
        <CardActivationModal
          accountId={accountSlug}
          cardSlug={cardSlug}
          firstName={cardholder.first_name}
          last4digits={last4CardDigits}
          lastName={cardholder.last_name}
          nickname={cardholder.nickname}
          onClose={handleCloseModal}
          onSuccess={handleSuccess}
        />
      )}

      {isModalOpen && vcc && cardOrderType === CARD_ORDER_TYPES.ORIGINAL_CARD && (
        <VccActivation
          accountSlug={accountSlug}
          cardSlug={cardSlug}
          cardholderNickname={cardholder.nickname}
          localeProps={localeProps}
          onClose={handleCloseModal}
          onRefresh={handleRefresh}
          onSuccess={handleActivationSuccess}
        />
      )}

      {isModalOpen && vcc && cardOrderType !== CARD_ORDER_TYPES.ORIGINAL_CARD && !idvPassed && (
        <IdvModal
          firstName={cardholder.first_name}
          idvFailed={cardholder.idvFailed}
          lastName={cardholder.last_name}
          onClose={handleCloseModal}
          onSuccess={() => setIdvPassed(true)}
          personId={cardholder.id}
          userSlug={adminId}
        />
      )}

      {isModalOpen && vcc && cardOrderType !== CARD_ORDER_TYPES.ORIGINAL_CARD && idvPassed && (
        <CardActivationModal
          accountId={accountSlug}
          cardSlug={cardSlug}
          firstName={cardholder.first_name}
          last4digits={last4CardDigits}
          lastName={cardholder.last_name}
          nickname={cardholder.nickname}
          onClose={handleCloseModal}
          onSuccess={handleSuccess}
        />
      )}
    </div>
  );
}

ActivateCardButton.propTypes = {
  accountSlug: PropTypes.string.isRequired,
  adminId: PropTypes.string,
  cardSlug: PropTypes.string,
  cardholder: cardholderShape.isRequired,
  cardOrderType: PropTypes.string,
  last4CardDigits: PropTypes.string,
  localeProps: localeShape.isRequired,
  onCardActivationSuccess: PropTypes.func.isRequired,
  vcc: PropTypes.bool.isRequired,
};
