import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import React from 'react';

export default function SearchInput({ loading, params, selected }) {
  return (
    <TextField
      {...params}
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <React.Fragment>
            {loading ? <CircularProgress color="inherit" size={20} /> : null}
            {params.InputProps.endAdornment}
          </React.Fragment>
        ),
      }}
      label={selected ? selected.person.name : 'Jump to client...'}
    />
  );
}

SearchInput.propTypes = {
  loading: PropTypes.bool.isRequired,
  params: PropTypes.object.isRequired,
  selected: PropTypes.object,
};
