import axios from 'axios';
import DashboardActions from 'react/member/actions/dashboard_actions';

const RecurringDisbursementStore = flux.createStore({
  recurringDisbursements: [],
  actions: [
    DashboardActions.fetchRecurringDisbursements,
    DashboardActions.approveRecurringDisbursement,
    DashboardActions.openRecurringDisbursementDenialModal,
    DashboardActions.denyRecurringDisbursement,
    DashboardActions.updateRecurringDisbursement,
    DashboardActions.cancelRecurringDisbursement,
  ],

  fetchRecurringDisbursements(client_id) {
    return $.get(
      RailsRoutes.dashboard_client_recurring_disbursements_path(client_id, {
        format: 'json',
      }),
      (recurringDisbursements) => {
        this.recurringDisbursements = recurringDisbursements;
        return this.emit('recurringDisbursements.fetch', this.recurringDisbursements);
      },
    );
  },

  approveRecurringDisbursement(recurring_disbursement_id, user_id, callback) {
    const url = RailsRoutes.dashboard_recurring_disbursement_approve_path(
      recurring_disbursement_id,
      {
        format: 'json',
      },
    );

    return axios.put(url, { user_id }).then(({ data: recurring_disbursement }) => {
      Truelink.flash('success', 'Recurring Disbursement approved!');
      this.emit('recurringDisbursement.update', recurring_disbursement);
      callback && callback();
    });
  },

  openRecurringDisbursementDenialModal(id, type) {
    return this.emit('disbursementDenialModal.open', id, type);
  },

  denyRecurringDisbursement(id, reason) {
    const url = RailsRoutes.dashboard_recurring_disbursement_deny_path(id, {
      format: 'json',
    });
    return $.ajax({
      url,
      type: 'PUT',
      data: { reason },
      success: (recurringDisbursement) => {
        Truelink.flash('notice', 'Recurring disbursement denied!');
        this.emit('recurringDisbursement.update', recurringDisbursement);
        this.emit('disbursementDenialModal.close');
      },
    });
  },

  updateRecurringDisbursement(recurring_disbursement_id, data) {
    const url = RailsRoutes.dashboard_recurring_disbursement_path(recurring_disbursement_id, {
      format: 'json',
    });
    return $.ajax({
      type: 'PUT',
      url,
      data,
      success: (recurringDisbursement) => {
        Truelink.flash('success', 'Recurring Disbursement updated!');
        this.emit('recurringDisbursement.update', recurringDisbursement);
      },
      fail: () => {
        Truelink.flash('error', 'There was a problem updating this recurring disbursement.');
      },
    });
  },

  cancelRecurringDisbursement(recurring_disbursement_id, lastDisbursementDate) {
    const url =
      RailsRoutes.dashboard_recurring_disbursement_request_cancellation_path(
        recurring_disbursement_id,
      );
    return axios
      .post(url, { last_disbursement_date: lastDisbursementDate })
      .then(() => this.emit('recurringDisbursement.cancellationRequest'))
      .catch(() => this.emit('recurringDisbursement.cancellationRequest.error'));
  },
});

export default RecurringDisbursementStore;
