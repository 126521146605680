import React, { useEffect, useState, useCallback } from 'react';
import DashboardActions from 'react/member/actions/dashboard_actions';
import TransfersTable from 'react/member/card/components/funding_tab/transfers_table/TransfersTable';
import TransfersTableHeader from 'react/member/card/components/funding_tab/transfers_table/TransfersTableHeader';
import TransferStatusLabel from 'react/member/card/components/funding_tab/transfers_table/action_cells/transfer_status_labels/TransferStatusLabel';
import DashboardTransferStore from 'react/member/stores/DashboardTransferStore';
import { isPartiallyLanded, isEmergencyFunded } from 'react/member/utils/Transfer';

export default function TransferHistorySection() {
  const [transfers, setTransfers] = useState([]);

  const makeStatusLabel = useCallback(
    (transfer) =>
      isPartiallyLanded(transfer) || isEmergencyFunded(transfer) ? (
        <TransferStatusLabel transfer={transfer} />
      ) : null,
    [],
  );

  useEffect(() => {
    const handleSetTransfers = () => {
      setTransfers(DashboardTransferStore.recentTransfers());
    };

    DashboardActions.fetchRecentTransfers();
    DashboardTransferStore.on('transfers.recent.fetch', handleSetTransfers);
    return function cleanup() {
      DashboardTransferStore.off('transfers.recent.fetch', handleSetTransfers);
    };
  }, []);

  return (
    <div>
      <TransfersTableHeader title="Transfer history" />
      <TransfersTable
        columnTitles={['Amount', '', 'Date', 'Account', '']}
        id="transfer_history_table"
        isViewOnly={false}
        items={transfers}
        renderActionCell={makeStatusLabel}
        zeroStateText="You have no recent transfers"
      />
    </div>
  );
}
