import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import PopUp from 'react/shared/components/popups/PopUp';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';

function DisbursementDeleteConfirmationModal({ onConfirm, onCancel }) {
  return (
    <PopUp
      cancel={onCancel}
      footer={
        <div>
          <span>
            <TrueLinkButton
              className={classNames('btn btn-default')}
              onClick={onCancel}
              style={{ paddingTop: 6, paddingBottom: 5, marginRight: 9 }}
              variant="none"
            >
              Keep Disbursement
            </TrueLinkButton>
          </span>
          <span>
            <TrueLinkButton
              className={classNames('btn btn-danger')}
              onClick={onConfirm}
              variant="none"
            >
              Delete Disbursement
            </TrueLinkButton>
          </span>
        </div>
      }
      header="Delete Disbursement Confirmation"
      maxWidth="640px"
      modalOpen
      paddingTop="160px"
    >
      <div>
        Select below to delete this disbursement. This action is permanent and cannot be undone. If
        you’d like a record of this disbursement moving forward, please deny the disbursement
        instead of deleting it.
      </div>
    </PopUp>
  );
}

DisbursementDeleteConfirmationModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default DisbursementDeleteConfirmationModal;
