import { ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import _isEmpty from 'underscore/modules/isEmpty';
import TextField from 'react/member/components/signups/signup_form/form_partials/fields/TextField';
import FieldLabel from 'react/member/components/signups/signup_form/form_partials/labels/field_label';

function PhoneField({
  entity,
  labelPrefix,
  optional,
  prefill,
  rowClass,
  colClass,
  dataAnalyticsPrefix,
  viewport,
}) {
  const dataAnalyticsKey = (fieldName) =>
    _isEmpty(dataAnalyticsPrefix) ? fieldName : `${dataAnalyticsPrefix}_${fieldName}`;

  const getPhoneLabelContent = () => {
    if (viewport === 'mobile') {
      return 'Phone';
    }
    if (labelPrefix) {
      return `${labelPrefix} phone number`;
    }

    return 'Phone number';
  };

  return (
    <div className={rowClass}>
      <div className={colClass}>
        <FieldLabel
          htmlFor={`${entity}_mobile`}
          labelClass="width-100 flex flex--space-between"
          labelContent={getPhoneLabelContent()}
          optional={optional}
        />
        <TextField
          dataAnalyticsKey={dataAnalyticsKey('phone')}
          fast={!prefill}
          id={`${entity}_mobile`}
          mask="(999) 999-9999"
          name={`${entity}.mobile`}
          readOnly={prefill}
        />
        <ErrorMessage className="error" component="label" name={`${entity}.mobile`} />
      </div>
    </div>
  );
}

PhoneField.propTypes = {
  dataAnalyticsPrefix: PropTypes.string,
  entity: PropTypes.oneOf(['administrator', 'cardholder']).isRequired,
  labelPrefix: PropTypes.string,
  optional: PropTypes.bool,
  prefill: PropTypes.bool,
  rowClass: PropTypes.string,
  colClass: PropTypes.string,
  viewport: PropTypes.string,
};

PhoneField.defaultProps = {
  optional: false,
  prefill: false,
  rowClass: 'card--form__row',
  colClass: 'card--form__col',
};

export default PhoneField;
