import PropTypes from 'prop-types';
import React from 'react';
import _omit from 'underscore/modules/omit';
import bindAll from 'react/shared/utils/bind_all';

export default class TemporaryMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hidden: true };
    bindAll(this);
  }

  show() {
    this.setState({ hidden: false });
    window.setTimeout(this.reset, 2000);
  }

  reset() {
    this.setState({ hidden: true });
  }

  render() {
    const { hidden } = this.state;
    const { message } = this.props;
    return (
      <span
        {..._omit(this.props, 'message')}
        hidden={hidden}
        style={{ color: 'green', marginLeft: 10 }}
      >
        {message}
      </span>
    );
  }
}

TemporaryMessage.propTypes = {
  message: PropTypes.string,
};
