import PropTypes from 'prop-types';
import React from 'react';
import _where from 'underscore/modules/where';
import LatestCloseoutTable from './LatestCloseoutTable';
import DashboardActions from 'react/member/actions/dashboard_actions';
import DeprecatedDashboardWealthAccountEventsIndex from 'react/member/components/dashboard/wealth_account_events/DeprecatedDashboardWealthAccountEventsIndex';
import DashboardWealthAccountEventStore from 'react/member/stores/DashboardWealthAccountEventStore';
import TabContent from 'react/shared/components/TabContent';
import TabNavigation from 'react/shared/components/TabNavigation';
import bindAll from 'react/shared/utils/bind_all';

export default class DashboardPortfolioPooledTrustAssets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transactions: [],
    };
    bindAll(this);
  }

  componentDidMount() {
    DashboardWealthAccountEventStore.on('wealthAccountEvents.fetch', this.onChangeTransactions);

    DashboardActions.fetchWealthAccountEventsByPortfolio(this.context.portfolio.id);
  }

  componentWillUnmount() {
    DashboardWealthAccountEventStore.off('wealthAccountEvents.fetch', this.onChangeTransactions);
  }

  onChangeTransactions(transactions) {
    this.setState({ transactions });
  }

  tabList() {
    return [
      {
        id: 'portfolio-trust-summary-tab',
        title: 'Trust Summary',
        type: 'choseTrustSummary',
        className: 'btn btn-secondary',
        hideHref: true,
      },
      {
        id: 'portfolio-investment-transactions-tab',
        title: 'Investment Transactions',
        type: 'choseInvestmentTransactions',
        className: 'btn btn-secondary',
        hideHref: true,
      },
    ];
  }

  render() {
    const tabList = this.tabList();
    const investmentTransactions = _where(this.state.transactions, { use: 'investment' });

    return (
      <div style={{ textAlign: 'center', marginTop: '25px' }}>
        <TabNavigation classOptions={'btn-group'} tabs={tabList}>
          <TabContent id="portfolio-trust-summary-tab">
            <LatestCloseoutTable />
          </TabContent>
          <TabContent id="portfolio-investment-transactions-tab">
            <DeprecatedDashboardWealthAccountEventsIndex transactions={investmentTransactions} />
          </TabContent>
        </TabNavigation>
      </div>
    );
  }
}

DashboardPortfolioPooledTrustAssets.contextTypes = {
  portfolio: PropTypes.object,
};
