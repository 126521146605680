import PropTypes from 'prop-types';
import React from 'react';

export default function ViewText({ elements }) {
  return elements.map(({ date, label, value }) => (
    <div key={label}>
      <div className="new-form__label">{label}</div>
      <div className="new-form__data">
        <span>{value}</span>
        <span>{date && ` - ${date}`}</span>
      </div>
    </div>
  ));
}

ViewText.propTypes = {
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      date: PropTypes.string,
    }),
  ),
};
