import PropTypes from 'prop-types';
import React from 'react';
import { asMoney } from 'react/shared/utils/Money';

export default function DashboardBalances({ balances, customClass, customStyle }) {
  const orderedAccounts = (balances.investment || [])
    .concat(balances.cash || [])
    .concat(balances.clearing || []);
  const total = orderedAccounts.reduce(
    (sum, account) => sum + Number(Object.values(account)[0]) || 0,
    0,
  );

  return (
    <table className={customClass || 'table'} style={customStyle || { width: '34%' }}>
      <thead>
        <tr>
          <th> Account </th>
          <th className="table-cell-right"> Balance </th>
        </tr>
      </thead>
      <tbody style={{ borderTop: '3px solid #B7B7B7' }}>
        {orderedAccounts.map((account, idx) => {
          const name = Object.keys(account)[0];

          return (
            <tr key={idx}>
              <td>{name}</td>
              <td className="table-cell-right"> {asMoney(Number(account[name]))} </td>
            </tr>
          );
        })}
        <tr>
          <td>
            <b>Total</b>
          </td>
          <td className="table-cell-right">
            <b>{asMoney(total)}</b>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

DashboardBalances.propTypes = {
  balances: PropTypes.shape({
    investment: PropTypes.array.isRequired,
    cash: PropTypes.array,
    clearing: PropTypes.array,
  }).isRequired,
  customClass: PropTypes.string,
  customStyle: PropTypes.object,
};
