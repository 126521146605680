import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { impactedTransferShape } from 'react/member/card/shapes/TransferShape';

export default function HolidayImpactedTransfersArrivesCell({ value }) {
  const { transfer, action } = value;
  let date;

  if (action === 'acceptGuidance') {
    date = transfer.guidanceDeliverDate;
  } else {
    date = transfer.supposedDeliverDate;
  }

  const formattedDate = moment(date).format('dddd, MMMM Do');

  return <>Arrives {formattedDate}</>;
}

HolidayImpactedTransfersArrivesCell.propTypes = {
  value: PropTypes.shape({
    transfer: impactedTransferShape.isRequired,
    action: PropTypes.oneOf(['acceptGuidance', 'rejectGuidance']).isRequired,
  }).isRequired,
};
