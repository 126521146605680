import PropTypes from 'prop-types';

const { oneOf } = PropTypes;

const shippingDestinationShape = oneOf([
  'cardholder_home',
  'office',
  'other',
  'current_shipping_address',
]);

export default shippingDestinationShape;
