import PropTypes from 'prop-types';
import React from 'react';
import _contains from 'underscore/modules/contains';
import { asMoney } from 'react/shared/utils/Money';

export default function MultipleParsed({
  records = [],
  invalidRecords = [],
  selected,
  resetParsed,
  checkRecord,
}) {
  return (
    <div>
      <div className="well">
        <p>
          The deposits from your template upload are listed below. If any of these records are not
          correct, click{' '}
          <a href="#" onClick={resetParsed}>
            back
          </a>
          {' to return to the previous screen and re-upload your template.'}
        </p>
        <p>When ready, click the big green button.</p>
      </div>
      {invalidRecords.length && (
        <div className="alert alert-danger">
          <p>
            One or more errors occurred when the CSV upload was read. Please review these errors,
            adjust the CSV file, and upload it again.
          </p>
          <ul>
            {invalidRecords.map((record) => (
              <li key={record.index}>{record.error}</li>
            ))}
          </ul>
        </div>
      )}
      <table className="table">
        <thead>
          <tr>
            <th className="table-cell">Add</th>
            <th className="table-cell">Beneficiary first name</th>
            <th className="table-cell">Beneficiary last name</th>
            <th className="table-cell">Expected delivery date</th>
            <th className="table-cell">Type</th>
            <th className="table-cell">Amount</th>
            <th className="table-cell">Payment ID</th>
            <th className="table-cell">Description</th>
            <th className="table-cell">Is Taxable</th>
          </tr>
        </thead>
        <tbody>
          {records.map((record) => (
            <tr key={record.index}>
              <td>
                <input
                  checked={_contains(selected, record.index)}
                  onChange={checkRecord}
                  type="checkbox"
                  value={record.index}
                />
              </td>
              <td>{record.firstName}</td>
              <td>{record.lastName}</td>
              <td>{record.deliveryDate}</td>
              <td>{record.paymentType}</td>
              <td>{asMoney(record.amount)}</td>
              <td>{record.paymentId || '-'}</td>
              <td>{record.description || '-'}</td>
              <td>{record.isTaxable.toString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

MultipleParsed.propTypes = {
  records: PropTypes.array,
  invalidRecords: PropTypes.array,
  selected: PropTypes.array.isRequired,
  resetParsed: PropTypes.func.isRequired,
  checkRecord: PropTypes.func.isRequired,
};
