import PropTypes from 'prop-types';

const RadioDropdownCollectionsShape = PropTypes.objectOf(
  PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
);

export default RadioDropdownCollectionsShape;
