import PropTypes from 'prop-types';
import React from 'react';
import _clone from 'underscore/modules/clone';
import _isEmpty from 'underscore/modules/isEmpty';
import _map from 'underscore/modules/map';
import dropdownOptionsShape from 'react/shared/shapes/dropdown_options_shape';

export default class ReactDropdown extends React.Component {
  static get propTypes() {
    const { bool, func, node, number, oneOfType, string, object } = PropTypes;
    return {
      options: dropdownOptionsShape.isRequired,
      onChange: func.isRequired,
      label: node,
      inputId: string,
      inputName: string,
      includeBlankOption: bool,
      disabled: bool,
      defaultValue: oneOfType([number, string]),
      useChosen: bool,
      onChosenSearchEnter: func,
      chosenSettings: object,
    };
  }

  static get defaultProps() {
    return {
      label: null,
      inputId: 'react-select',
      inputName: '',
      includeBlankOption: false,
      disabled: false,
      defaultValue: '',
      useChosen: false,
      onChosenSearchEnter: null,
      chosenSettings: { width: '250px' },
    };
  }

  componentDidMount() {
    if (this.props.useChosen) {
      $(this.refs.select).chosen(this.props.chosenSettings).change(this.props.onChange);

      if (this.props.onChosenSearchEnter) {
        const dropSelector = `#${this.props.inputId}`;
        const dropChosenSelector = `${dropSelector}_chosen`;

        $(dropChosenSelector).keyup((ev) => {
          if (ev.which !== 13 || _isEmpty(ev.target.value)) return;

          const event = _clone(ev);
          this.props.onChosenSearchEnter(event);
          $(this.refs.select).trigger('click');
        });
      }
    }
  }

  resetChosen() {
    if (this.props.useChosen) {
      $(this.refs.select).val('').trigger('chosen:updated');
    }
  }

  disableSelect() {
    return this.props.disabled || this.props.options.length < 1;
  }

  formatOption(object) {
    return (
      <option key={object.id} value={object.id}>
        {object.name}
      </option>
    );
  }

  label() {
    if (!this.props.label) return;

    return <label htmlFor={this.props.inputId}>{this.props.label}</label>;
  }

  optionsList() {
    // prettier-ignore
    const { options, includeBlankOption, useChosen, onChange, inputId, inputName, defaultValue } = this.props;
    const list = _map(options, (object) => this.formatOption(object));

    return (
      <div
        style={{
          width: '50%',
          display: 'inline',
          paddingBottom: useChosen && 10,
        }}
      >
        {' '}
        {this.label()}
        <select
          defaultValue={defaultValue}
          disabled={this.disableSelect()}
          id={inputId}
          name={inputName}
          onChange={onChange}
          ref="select"
        >
          {includeBlankOption && <option key="" value="" />}
          {list}
        </select>
      </div>
    );
  }

  render() {
    const list = this.optionsList();

    return (
      <div>
        <div style={{ width: '50%', display: 'inline' }}>{list}</div>
      </div>
    );
  }
}
