import PropTypes from 'prop-types';
import React from 'react';
import CardDetails from 'react/member/card/components/account_tab/card_status/CardDetails';
import { CARD_STATUSES } from 'react/member/utils/CardStatus';

export default function Open({ cardBalance, cardBreached, cardLast4, cardExpiryDate, status }) {
  const note = 'Active and can be used for purchases.';
  return (
    <CardDetails
      cardBalance={cardBalance}
      cardBreached={cardBreached}
      cardLast4={cardLast4}
      expiryDate={cardExpiryDate}
      note={note}
      status={status}
    />
  );
}

Open.propTypes = {
  cardBalance: PropTypes.number,
  cardBreached: PropTypes.bool,
  cardExpiryDate: PropTypes.string,
  cardLast4: PropTypes.string.isRequired,
  status: PropTypes.oneOf([CARD_STATUSES.OPEN_CARD_STATUS]).isRequired,
};
