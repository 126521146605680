import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import HolidayImpactedTransfersAmountCell from './HolidayImpactedTransfersAmountCell';
import HolidayImpactedTransfersArrivesCell from './HolidayImpactedTransfersArrivesCell';
import { impactedTransferShape } from 'react/member/card/shapes/TransferShape';
import TrueLinkTable from 'react/shared/components/true_link/main/table/TrueLinkTable';

const borderStyles = '1px solid #E7E7E7';
const useStyles = makeStyles({
  wrap: {
    fontSize: '16px',
    margin: '10px 0 15px',
    width: '950px',
  },
  subHeader: {
    fontWeight: '700',
  },
  singleHolidayImpactedTransferTable: {
    border: 'none',
    margin: '20px 0',
    '& table': {
      overflowX: 'hidden',
    },
    '& thead': {
      boxShadow: 'none',
      borderBottom: borderStyles,
    },
    '& thead th': {
      borderBottom: borderStyles,
    },
    '& td': {
      whiteSpace: 'normal',
    },
  },
  note: {
    marginBottom: '15px',
  },
});

export default function SingleHolidayImpactedTransferTable({ date, impactedTransfer }) {
  const classes = useStyles();

  // Array of single impacted transfer
  const tableData = useMemo(() => [impactedTransfer], [impactedTransfer]);

  const columns = useMemo(
    () => [
      {
        accessor: 'memo',
        id: 'transferType',
        Header: 'Transfer Type',
      },
      {
        Cell: HolidayImpactedTransfersAmountCell,
        accessor: 'amount',
        id: 'amount',
        Header: 'Amount',
      },
      {
        accessor: 'cardholder.name',
        id: 'cardholderName',
        Header: 'Cardholder',
      },
      {
        Cell: HolidayImpactedTransfersArrivesCell,
        accessor: (transfer) => ({ transfer, action: 'rejectGuidance' }),
        id: 'rejectGuidance',
        Header: 'If You Do Nothing',
      },
      {
        Cell: HolidayImpactedTransfersArrivesCell,
        accessor: (transfer) => ({ transfer, action: 'acceptGuidance' }),
        id: 'acceptGuidance',
        Header: 'If You Adjust',
      },
    ],
    [],
  );

  return (
    <div className={classes.wrap}>
      <div className={classes.subHeader}>
        Adjust the transfer scheduled for {moment(date).format('dddd, MMMM Do')} to avoid the delay
      </div>
      <TrueLinkTable
        className={classes.singleHolidayImpactedTransferTable}
        columns={columns}
        data={tableData}
        rowProps={() => ({ style: { borderBottom: borderStyles, padding: '5px' } })}
      />
      <div className={classes.note}>
        Note: Adjusted transfers will initiate 1-2 days earlier than scheduled
      </div>
    </div>
  );
}

SingleHolidayImpactedTransferTable.propTypes = {
  date: PropTypes.string.isRequired, // HolidayImpactedDate.date
  impactedTransfer: impactedTransferShape.isRequired,
};
