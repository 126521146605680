import PropTypes from 'prop-types';
import React from 'react';
import DashboardTrustBeneficiaryProfileContactInfoForm from './DashboardTrustBeneficiaryProfileContactInfoForm';
import DashboardTrustBeneficiaryStore from 'react/member/stores/beneficiary_dashboard/DashboardTrustBeneficiaryStore';
import ToggleSectionHeader from 'react/shared/components/ToggleSectionHeader';
import TrueLinkModal from 'react/shared/components/true_link/lab/TrueLinkModal';
import bindAll from 'react/shared/utils/bind_all';
import TlFieldTransformers from 'react/shared/utils/tl_field_transformers';

export default class DashboardTrustBeneficiaryProfileContactInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editing: false,
    };

    bindAll(this);
  }

  componentDidMount() {
    const {
      trustBeneficiary: { slug },
    } = this.props;
    DashboardTrustBeneficiaryStore.on(`trustBeneficiary.update.${slug}`, this.setEditingFalse);
  }

  componentWillUnmount() {
    const {
      trustBeneficiary: { slug },
    } = this.props;
    DashboardTrustBeneficiaryStore.off(`trustBeneficiary.update.${slug}`, this.setEditingFalse);
  }

  toggleEdit(e) {
    e.preventDefault();
    this.setState((prevState) => ({ editing: !prevState.editing }));
  }

  setEditingFalse() {
    this.setState({ editing: false });
  }

  setEditingTrue() {
    this.setState({ editing: true });
  }

  renderEditModal() {
    const { editing } = this.state;
    if (!editing) {
      return;
    }

    return (
      <TrueLinkModal handleClose={this.setEditingFalse} title={'Update Contact Information'}>
        <DashboardTrustBeneficiaryProfileContactInfoForm
          trustBeneficiary={{ ...this.props.trustBeneficiary }}
        />
      </TrueLinkModal>
    );
  }

  renderContent() {
    if (!this.props.expanded) {
      return null;
    }
    const { trustBeneficiary } = this.props;
    const { toYesNo, formatPhoneNumber } = TlFieldTransformers;

    return (
      <div className="toggle-section-content flex">
        <div className="info-column">
          <div className="info-item">
            <strong>Address Line 1: </strong>
            <span className="padding-10l">{trustBeneficiary.person.address?.street1}</span>
          </div>
          <div className="info-item">
            <strong>Address Line 2: </strong>
            <span className="padding-10l">{trustBeneficiary.person.address?.street2}</span>
          </div>
          <div className="info-item">
            <strong>City: </strong>
            <span className="padding-10l">
              <span className="padding-10l">{trustBeneficiary.person.address?.city}</span>
            </span>
          </div>
          <div className="info-item">
            <strong>State: </strong>
            <span className="padding-10l">{trustBeneficiary.person.address?.state}</span>
          </div>
          <div className="info-item">
            <strong>Zip Code: </strong>
            <span className="padding-10l">{trustBeneficiary.person.address?.zip}</span>
          </div>
        </div>
        <div className="info-column">
          <div className="info-item">
            <strong>Mail Statement: </strong>
            <span className="padding-10l">{toYesNo(trustBeneficiary.mailStatement)}</span>
          </div>
          <div className="info-item">
            <strong>Email: </strong>
            <span className="padding-10l">{trustBeneficiary.email}</span>
          </div>
          <div className="info-item">
            <strong>Primary Phone Number: </strong>
            <span className="padding-10l">{formatPhoneNumber(trustBeneficiary.person.mobile)}</span>
          </div>
          <div className="info-item">
            <strong>Secondary Phone Number: </strong>
            <span className="padding-10l">
              {formatPhoneNumber(trustBeneficiary.person.alternatePhone[0])}
            </span>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { expanded, canEdit, toggleExpanded } = this.props;

    return (
      <div className="toggle-section contact-info">
        {this.renderEditModal()}
        <ToggleSectionHeader title="Contact Info" {...{ expanded, toggleExpanded }}>
          {canEdit ? (
            <div className="action-item">
              <button
                className="edit-link btn btn-link"
                id="edit-contact-info"
                onClick={this.toggleEdit}
                type="button"
              >
                <i className="fa fa-edit" />
                <span className="padding-5l">Edit</span>
              </button>
            </div>
          ) : null}
        </ToggleSectionHeader>
        {this.renderContent()}
      </div>
    );
  }
}

DashboardTrustBeneficiaryProfileContactInfo.propTypes = {
  trustBeneficiary: PropTypes.object,
  expanded: PropTypes.bool.isRequired,
  toggleExpanded: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
};
