import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';
import FieldMixin from './FieldMixin';
import NumberField from 'react/shared/components/forms/NumberField';
import { asMoney } from 'react/shared/utils/Money';

const CurrencyField = createReactClass({
  mixins: [FieldMixin],

  componentDidMount() {
    this.initField();
  },

  render() {
    const format = (v) => v && asMoney(v);

    return <NumberField {...this.props} format={format} step={0.01} />;
  },
});

CurrencyField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.number,
};

export default CurrencyField;
